import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import ListText from '../../pages/ListsText';
import Notes from '../../pages/Notes';
import Table from '../../pages/Table';
import CodeMirrorContent from '../../../tryit/CodeContent/CodeMirrorContent';
import viewport1 from '../../../images/img_viewport1.png';
import viewport2 from '../../../images/img_viewport2.png';



function Head() {
    return (
        <article className='info-wrapper'>
                <W3Path path={"https://www.w3schools.com/html/html_head.asp"} />
            <h2 className='header-tutorial'>Head elementi</h2>
            <NavButton back='/html/html_file_path' next='/html/html_layout' />
            <hr />
            <TextContent
                text1={"<head> elementi quyidagi elementlar uchun konteyner hisoblanadi: <title>, <style>, <meta>, <link>, <script> va <base>."}
                redWords1={["<head>", "<title>", "<style>", "<meta>", "<link>", "<script>","<base>."]}/>
            <hr />
            <TextContent header={"Html head elementi"}
                text1={"<head> elementi meta-ma'lumotlar(ma'lumotlar haqidagi ma'lumot) uchun konteyner bo'lib, <html> va <body> teglari orasida joylashgan bo'ladi."}
                redWords1={["<head>", "<html>", "<body>"]}
                text2={"HTML meta-ma'lumotlar - bu HTML hujjati haqidagi ma'lumotlar. Meta-ma'lumotlar ekranda ko'rsatilmaydi."}
                text3={"Meta-ma'lumotlar odatda hujjat nomini, belgilar to'plamini, stillarni, skriptlarni va boshqa meta-ma'lumotlarni tavsiflaydi."}/>
                <hr/>
            <TextContent header={"HTML <title> elementi"}
                text1={"<title> elementi hujjatning sarlavhasini belgilaydi. Sarlavha faqat matndan iborat bo'lishi kerak va u brauzerning sarlavha satrida yoki sahifa yorlig'ida ko'rsatiladi."}
                redWords1={["<title>"]}
                text2={"<title> elementi HTML hujjatlarida bo'lishi shart!"}
                redWords2={["<title>"]}
               text3={"Sahifa sarlavhasining mazmuni qidiruv tizimini optimallashtirish (SEO) uchun juda muhim! Sahifalar sarlavhasi qidiruv tizimi algoritmlari tomonidan qidiruv natijalarida sahifalarni ro'yxatga olish tartibini aniqlash uchun ishlatiladi."}
                text4={"<title> elementi:"}
                redWords4={["title"]}/>
                     <ListText list={[{
                text: "brauzer asboblar panelidagi sarlavhani belgilaydi"
            },
            {
                text: "Sevimli veb sahifalarga qo'shilganda sahifa uchun sarlavha beradi"
            },
            {
                text: "qidiruv tizimi natijalarida sahifa sarlavhasini ko'rsatadi"
            }]} />
             <TextContent
                text1={"Shunday ekan, sarlavhani iloji boricha aniq va mazmunli qilishga harakat qiling!"}
                text2={"Oddiy HTML hujjati:"}
                code2={`<head>
  <title>Ma'noli sarlavha</title>
</head>
<body>

Hujjat kontenti...

</body>`}
                path2={"try/248"}/>
                <hr/>
                <TextContent header={"HTML <style> elementi"}
                text1={"<style> elementi bitta HTML sahifasi uchun stil ma'lumotlarini aniqlash uchun ishlatiladi:"}
                redWords1={["<style>"]}
                code1={`<style>
    body {background-color: powderblue;}
    h1 {color: red;}
    p {color: blue;}
</style>`} path1={"try/249"}/>
                <hr/>
            <TextContent header={"HTML <link> elementi"}
                text1={"<link> elementi joriy veb-sahifa va tashqi manbani birlashtiradi."}
                redWords1={["<link>"]}
                text2={"<link> tegi ko'pincha tashqi stillarga ulanish uchun ishlatiladi:"}
                redWords2={["<link>"]}
                code2={`<link rel="stylesheet" href="mystyle.css">`}
                path2={"try/250"}/>
                  <Notes text={"CSS haqida toʻliq maʼlumot olish uchun bizning"} path="/css" text1={" CSS ma'lumotnomamizga"} text2={"tashrif buyuring."} />
                <hr/>
                <TextContent header={"HTML <meta> elementi"}
                text1={"<meta> elementi odatda belgilar to'plamini, sahifa tavsifini, kalit so'zlarni, hujjat muallifini va ko'rish oynasi sozlamalarini belgilash uchun ishlatiladi."}
                redWords1={["<meta>"]}
                text2={"Meta-ma'lumotlar sahifada ko'rsatilmaydi, lekin brauzerlar (tarkibni qanday ko'rsatish yoki sahifani qayta yuklash), qidiruv tizimlari (kalit so'zlar) va boshqa veb-xizmatlar tomonidan ishlatiladi."}/>
                   <TextContent header={"Misollar"}/>
                   <p><b>Ishlatilgan belgilar to'plamini aniqlash:</b></p>
                   <div style={{border: "1px solid #ccc", marginBottom: "25px"}}>
                    <CodeMirrorContent value={`<meta charset="UTF-8">`}/>
                   </div>
                   <p><b>Qidiruv tizimlari uchun kalit so'zlarni aniqlash:</b></p>
                   <div style={{border: "1px solid #ccc", marginBottom: "25px"}}>
                    <CodeMirrorContent value={`<meta name="keywords" content="HTML, CSS, JavaScript">`}/>
                   </div>
                   <p><b>Veb-sahifa tavsifini belgilash:</b></p>
                   <div style={{border: "1px solid #ccc", marginBottom: "25px"}}>
                    <CodeMirrorContent value={`<meta name="description" content="Bepul darsliklar">`}/>
                   </div>
                   <p><b>Sahifaning muallifini aniqlash:</b></p>
                   <div style={{border: "1px solid #ccc", marginBottom: "25px"}}>
                    <CodeMirrorContent value={`<meta name="author" content="John Doe">`}/>
                   </div>
                   <p><b>Hujjatni har 30 soniyada yangilash:</b></p>
                   <div style={{border: "1px solid #ccc", marginBottom: "25px"}}>
                    <CodeMirrorContent value={`<meta http-equiv="refresh" content="30">`}/>
                   </div>
                   <p><b>Veb-saytingiz barcha qurilmalarda yaxshi ko'rinishi uchun ko'rish oynasini sozlash:</b></p>
                   <div style={{border: "1px solid #ccc", marginBottom: "25px"}}>
                    <CodeMirrorContent value={`<meta name="viewport" content="width=device-width, initial-scale=1.0">`}/>
                   </div>
                   <TextContent
                   text1={"<meta> tegiga misol:"}
                   redWords1={["<meta>"]}
                   code1={`<meta charset="UTF-8">
<meta name="description" content="Bepul veb dasrliklar">
<meta name="keywords" content="HTML, CSS, JavaScript">
<meta name="author" content="John Doe">`}
                   path1={"try/251"}
                   />
                   <TextContent header={"Ko'rish oynasini sozlash"}
                text1={"Ko'rish oynasi veb-sahifaning foydalanuvchining ko'rinadigan qismidir. Ko'rish oynasi foydalanilayotgan qurilmaga qarab farq qiladi. Ko'rish oynasi mobil telefonda kompyuter ekraniga qaraganda kichikroq bo'ladi."}
                text2={"Barcha veb-sahifalaringizga quyidagi <meta> elementini kiritishingiz kerak:"}
                redWords2={["<meta>"]}/>
                   <div style={{border: "1px solid #ccc", marginBottom: "25px"}}>
                    <CodeMirrorContent value={`<meta name="viewport" content="width=device-width, initial-scale=1.0">`}/>
                   </div>
                   <TextContent
                   text1={"Bu brauzerga sahifa o'lchamlari va masshtabini boshqarish bo'yicha ko'rsatmalar beradi."}
                   text2={"width=device-width qismi sahifaning kengligini qurilmaning ekran kengligiga moslab beradi(bu qurilmaga qarab o'zgaradi)."}
                   redWords2={["width=device-width"]}
                   text3={"initial-scale=1.0 qismi esa brauzer tomonidan sahifa birinchi marta yuklanganda boshlang'ich kattalashtirish darajasini o'rnatadi."}
                   redWords3={["initial-scale=1.0"]}
                   />
                   <p>Mana viewport meta-tegi bo'lmagan veb-sahifa va viewport meta-tegi ega bir xil veb-sahifaga misol:</p>
         <div style={{display: 'flex', flexWrap:'wrap', alignItems: 'center', justifyContent: 'space-around'}}>
         <div style={{textAlign: 'center'}}>
                    <a className='text-tutorial text-tutorial__link'  href='/viewport1.html' target={'_blank'}>
                        <img className='img_card' src={viewport1} alt={"Witout viewport"} width={200} height={375}/>
                        <br/>
                        <br/>
                        <b>viewport meta-tegiga ega bo'lmaganda </b>
                    </a>
                   </div>
                   <div style={{textAlign: 'center'}}>
                    <a className='text-tutorial text-tutorial__link'  href='/viewport2.html' target={'_blank'}>
                        <img className='img_card' src={viewport2} alt={"Witout viewport"} width={200} height={375}/>
                        <br/>
                        <br/>
                        <b>viewport meta-tegiga ega bo'lganda </b>
                    </a>
                   </div>
         </div>
         <Notes type={"Maslahat"} text={"Agar siz ushbu sahifani telefon yoki planshet orqali ko'rayotgan bo'lsangiz, farqni ko'rish uchun quyidagi ikkita havolani bosishingiz mumkin."}/>
         <hr/>
         <TextContent header={'HTML <script> elementi'}
         text1={'<script> elementi JavaScript kodlarni yozish uchun ishlatiladi.'}
         redWords1={["<script>"]}
         text2={'Quyidagi misolda id="demo" ga teng elementga  JavaScript "Salom JavaScript!" deb yozadi.'}
         code2={`<script>
  function myFunction() {
    document.getElementById("demo").innerHTML = "Salom JavaScript!";
  }
  </script>`} path2={"try/252"}/>
                <hr/>
                <TextContent header={'HTML <base> elementi'}
         text1={'<base> elementi sahifadagi barcha nisbiy URL manzillar uchun asosiy URLni belgilaydi.'}
         redWords1={["<base>"]}
         text2={"<base> tegida href yoki target atributi yoki ikkalasi ham bo'lishi kerak."}
         redWords2={["<base>"]}
         text3={"Hujjatda faqat bitta <base> elementi bo'lishi mumkin!"}
         redWords3={["<base>"]}
         code2={`<head>
<base href="https://www.w3schools.com/" target="_blank">
</head>

<body>
<img src="images/stickman.gif" width="24" height="39" alt="Stickman">
<a href="tags/tag_base.asp">HTML base tegi</a>
</body>`} path2={"try/253"}/>
                <hr/>
            <TextContent header={"Bo'lim xulosasi"} />
            <ListText list={[{
                text: "<head> element meta-ma'lumotlar uchun konteynerdir",
                redWords: ["<head>"]
            },
            {
                text: "<head> elementi <html> tegi va <body> tegi orasiga joylashtiriladi",
                redWords: ["<head>", "<html>","<body>"]
            },
            {
                text: "<title> elementini majburiy va u hujjatning sarlavhasini belgilaydi",
                redWords: ["<title>"]
            },
            {
                text: "<style> elementi bitta hujjatga stillar berish uchun ishlatiladi.",
                redWords: ["<style>"]
            },
            {
                text: "Ko'pincha tashqi stillarga ulanish uchun <link> tegi ishlatiladi",
                redWords: ["<link>"]
            },
            {
                text: "Odatda belgilar to'plamini, sahifa tavsifini, kalit so'zlarni, hujjat muallifini va ko'rish oynasi sozlamalarini belgilash uchun <meta> tegi ishlatiladi.",
                redWords: ["<meta>"]
            },
            {
                text: "JavaScriptlarni aniqlash uchun <script> elementi ishlatiladi",
                redWords: ["<script>"]
            },
            {
                text: "<base> elementi sahifadagi barcha nisbiy URL manzillar uchun asosiy URL va targetni belgilaydi",
                redWords: [""]
            },]} />
            <hr />

            <Table header={"Html head elementi"} th={["Teg", "Tavsif"]} values={[
        { id: 0, first: "<head>", second: "Hujjat haqidagi ma'lumotlarni belgilaydi.", path: "/tags/tag_head" },
        { id: 1, first: "<title>", second: "Hujjatning sarlavhasini belgilaydi", path: "/tags/tag_title" },
        { id: 2, first: "<base>", second: "Sahifadagi barcha havolalar uchun standart manzil yoki standart targetni belgilaydi", path: "/tags/tag_base" },
        { id: 3, first: "<link>", second: "Hujjat va tashqi manba o'rtasidagi munosabatni belgilaydi", path: "/tags/tag_link" },
        { id: 4, first: "<meta>", second: "HTML hujjati haqidagi metama'lumotlarni belgilaydi", path: "/tags/tag_meta" },
        { id: 5, first: "<script>", second: "Skriptlarni belgilaydi", path: "/tags/tag_script" },
        { id: 6, first: "<style>", second: "Hujjat uchun stillarni belgilaydi", path: "/tags/tag_style" }]} />
             <Notes text={"Barcha mavjud HTML teglarining to'liq ro'yxatini ko'rish uchun bizning"} path="/tags" text1={" HTML teg ma'lumotnomamizga"} text2={"tashrif buyuring."} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="T2C9pOJnggY" />
            <NavButton back='/html/html_file_path' next='/html/html_layout' />
        </article>)
}

export default Head;