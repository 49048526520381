import React from 'react';
import { Link } from 'react-router-dom';
import NavButton from '../NavButton';
import '../Info.css';
import CodeContent from '../../../tryit/CodeContent';
import CodeMirrorContentCss from '../../../tryit/CodeContent/CodeMirrorContentCss';
import TagTable from './AttrTable';
import W3Path from '../../W3Path';
import BrowserTable from '../../pages/BrowserTable';


function TagLink() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_link.asp"} />
      <h2 className='header-tutorial'>HTML &lt;link&gt; tegi</h2>
      <NavButton back='/tags/tag_li' next='/tags/tag_main' />
      <hr />
      <CodeContent props={`<head>
  <link rel="stylesheet" href="styles.css">
</head>`} pathname={`try/77`} text="Tashqi stillarni hujjatga biriktirish:" />
      <h3 className='header-content'>Tavsifi va qo'llanilishi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;link&gt;</span> tegi yordamida Html hujjatiga tashqi stillari(CSS) biriktiriladi.</p>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;link&gt;</span>tegi ko'pincha tashqi stillarni ulash yoki <Link className='table-link' to={"/html/html_favicon"}>&lt;favicon&gt;</Link> qo'shish uchun qo'llaniladi.</p>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;link&gt;</span> bo'sh element va unda faqat atributlar joylashadi.</p>
      <hr />
      <BrowserTable element='<link>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />
      <h3 className='header-content'>Atributlari</h3>
      <TagTable arr={["crossorigin", "href", "hreflang", "media", "referrerpolicy", "rel", "sizes", "title", "type"]} />
      <hr />
      <h3 className='header-content'>Global atributlar</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;link&gt;</span>  tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <h3 className='header-content'>Hodisa atributlari</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;link&gt;</span>  tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <h3 className='header-content'>Tegishli sahifalar</h3>
      <p className='text-tutorial'>HTML mavzulari: <Link className='table-link' to={"/html/html_css"}>HTML stillari</Link></p>
      <p className='text-tutorial'>CSS mavzulari: <Link className='table-link' to={"/css"}>CSS mavzulari</Link></p>
      <p className='text-tutorial'>HTML DOM ma'lumotnomasi: <Link className='table-link' to={"/jsref/dom_obj_link"}>link Obyekti</Link></p>
      <hr />
      <h3 className='header-content'>Standart CSS sozlamalari</h3>
      <p className='text-tutorial'>Ko'p brauzerlarda &lt;link&gt; elementida quyidagi standart stillar berilgan bo'ladi:</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContentCss value={`link {
  display: none;
}`} />
        </div>
      </div>
      <hr />
      <NavButton back='/tags/tag_li' next='/tags/tag_main' />
    </article>
  )
}

export default TagLink;
