import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../../pages/ScrollToTop";
import SideBar from "../SideBar";

const colorTutorial = [{
  title: "Ranglar",
  id: 0,
  subTutorial: [
    { id: 1, title: "Ranglar", path: "colors" },
    { id: 2, title: "Rang nomlari", path: "colors_names" },
    { id: 3, title: "Rang qiymatlari", path: "colors_hex" },
    { id: 4, title: "Rang guruhlari", path: "colors_groups" },
    { id: 5, title: "Soya ranglar", path: "colors_shades" },
    { id: 6, title: "Rang tanlovchi", path: "colors_pickers" },
    { id: 7, title: "Ranglar aralashmasi", path: "colors_mixer" },
    { id: 8, title: "Rang konvertori", path: "colors_converter" },
    { id: 9, title: "RGB ranglari", path: "colors_rgb" },
    { id: 10, title: "O'n oltilik rang qiymatlari", path: "colors_hexadecimal" },
    { id: 11, title: "HSL ranglari", path: "colors_hsl" },
    { id: 12, title: "HWB ranglari", path: "colors_hwb" },
    { id: 13, title: "CMYK ranglar", path: "colors_cmyk" },
    { id: 14, title: "NCO ranglari", path: "colors_nco" },
    { id: 15, title: "Gradient ranglar", path: "colors_gradient" },
    { id: 16, title: "Rng nazariyasi", path: "colors_theory" },
    { id: 17, title: "Ranglar aylanasi", path: "colors_wheels" },
    { id: 18, title: "Joriy rang", path: "colors_currentcolor" },
    { id: 19, title: "Rang yorqinligi", path: "colors_hues" },
    { id: 20, title: "Ranglar sxemasi", path: "colors_schemes" },
    { id: 21, title: "Ranglar palitrasi", path: "colors_palettes" },
    { id: 22, title: "Ranglar brendi", path: "colors_brands" },
    { id: 23, title: "W3.CSS ning ranglari", path: "colors_w3css" },
    { id: 24, title: "Metro UI ranglari", path: "colors_metro" },
    { id: 25, title: "Win8 ranglari", path: "colors_win8" },
    { id: 26, title: "Flat UI Ranglari", path: "colors_flatui" },
    { id: 27, title: "Psixologik ranglar", path: "colors_psychology" },
  ]
},
{
  title: "Rang Sxemasi",
  id: 28,
  subTutorial: [
    { id: 29, title: "Monoxronik ranglar", path: "colors_monochromatic" },
    { id: 30, title: "Ranlar anologi", path: "colors_analogous" },
    { id: 31, title: "Qo'shimcha ranglar", path: "colors_complementary" },
    { id: 32, title: "Triadik ranglar", path: "colors_triadic" },
    { id: 33, title: "Ranglar birikmasi", path: "colors_compound" },
  ]
},
{
  title: "Rang tendensiyalari",
  id: 34,
  subTutorial: [
    { id: 35, title: "Yil ranglari", path: "colors_trend" },
    { id: 36, title: "2021 yil ranglari", path: "colors_2021" },
    { id: 37, title: "2020 yil ranglari", path: "colors_2020" },
    { id: 38, title: "2019 yil ranglari", path: "colors_2019" },
    { id: 39, title: "2018 yil ranglari", path: "colors_2018" },
    { id: 40, title: "2017 yil ranglari", path: "colors_2017" },
    { id: 41, title: "2016 yil ranglari", path: "colors_2016" }
  ]
},
{
  title: "Rang standartlari",
  id: 42,
  subTutorial: [
    { id: 43, title: "AQSH ranglari", path: "colors_fs595" },
    { id: 44, title: "Buyuk britaniya ranglari", path: "colors_british" },
    { id: 45, title: "Australiya ranglari", path: "colors_australia" },
    { id: 46, title: "RAL ranglari", path: "colors_ral" },
    { id: 47, title: "NBS ranglari", path: "colors_nbs" },
    { id: 48, title: "NCS ranglari", path: "colors_ncs" },
    { id: 49, title: "X11 ranglari", path: "colors_x11" },
    { id: 50, title: "Crayola ranglari", path: "colors_crayola" },
    { id: 51, title: "Resense ranglari", path: "colors_resene" },
    { id: 52, title: "XKCD ranglari", path: "colors_xkcd" }
  ]
}
];

function Color() {
  return (
    <div className="tutorial container">
      <SideBar datas={colorTutorial} />
      <ScrollToTop />
      <Outlet />
    </div>
  )
}

export default Color;