import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import { Link } from 'react-router-dom';
import Table from '../../pages/Table';
import CodeMirrorContent from '../../../tryit/CodeContent/CodeMirrorContent';
import NoteWhite from '../../pages/NoteWhite';


function Symbols() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_symbols.asp"} />
            <h2 className='header-tutorial'>HTML belgilari</h2>
            <NavButton back='/html/html_obects' next='tml/html_emoji' />
            <hr />
            <p>Klaviaturada mavjud bo'lmagan belgilar ham obyektlar yordamida qo'shilishi mumkin.</p>
            <hr />
            <TextContent header={"HTML belgi obyektlari"}
                text1={"HTML obyektlari oldingi bobda yoritib berilgan edi."}
                text2={"Ko'pgina matematik, texnik va valyuta belgilari oddiy klaviaturada mavjud emas."}
                text3={"Bunday belgilarni HTML sahifasiga qo'shish uchun obyekt nomini yoki belgi uchun obyekt nomerini(o'nlik yoki o'n oltilik raqam) ishlatishingiz mumkin."} />
            <div className='code-content'>
                <h4 className='header-code'>Misol:</h4>
                <p>Evro belgisi(€)ni obyekt nomi, o'nlik va o'n oltilik qiymat bilan ko'rsatish:</p>
                <div className='code-wrapper'>
                    <CodeMirrorContent value={`<p>&euro; obyekt nomi bilan ko'rsatish</p>
<p>&#8364; o'nlik qiymat bilan ko'rsatish</p>
<p>&#x20AC; oltilik qiymat bilan ko'rsatish</p>`} />
                </div>
                <h4 className='header-code'>Natija:</h4>
                <div className='code-wrapper'>
                    <p>&euro; obyekt nomi bilan ko'rsatish</p>
                    <p>&#8364; o'nlik qiymat bilan ko'rsatish</p>
                    <p>&#x20AC; oltilik qiymat bilan ko'rsatish</p>
                </div>
                <Link className='button btn btn--large green' to={"try/294"} target={"_blank"}>Sinab ko'rish</Link>
            </div>
            <hr />
            <Table header={"HTML tomonidan qo'llab-quvvatlanadigan ba'zi matematik belgilar"}  th={["Belgi", "Obyekt Nomeri", "Obyekt nomi", "Tavsifi", "Sinab ko'rish"]} values={[
                { id: 0, first: "∀", second: "&#8704;", third: "&forall;", four: "Umumiylik kvantori", tryPath: "try/305" },
                { id: 1, first: "∂", second: "&#8706;", third: "&part;", four: "qisman differensiallik", tryPath: "try/306" },
                { id: 2, first: "∃", second: "	&#8707;", third: "&exist;", four: "Mavjudlik kvantori", tryPath: "try/307" },
                { id: 3, first: "∅", second: "&#8709;", third: "&empty;", four: "Bo'sh to'plam", tryPath: "try/308" },
                { id: 4, first: '∇', second: "&#8711;", third: "&nabla;", four: "nabla yoki 'curl' belgisi", tryPath: "try/309" },
                { id: 5, first: "∈", second: "&#8712;", third: "&isin;", four: "Tegishlilik belgisi", tryPath: "try/310" },
                { id: 6, first: "∉", second: "&#8713;", third: "&notin;", four: "Tegishli emaslik belgisi", tryPath: "try/311" },
                { id: 7, first: "∋", second: "&#8715;", third: "&ni;", four: "Kamida bittasi bor", tryPath: "try/312" },
                { id: 8, first: "∏", second: "&#8719;", third: "&prod;", four: "Ko'paytirish belgisi – faktorial", tryPath: "try/313" },
                { id: 9, first: "∑", second: "&#8721;", third: "&sum;", four: "Yig'indi belgisi - summa", tryPath: "try/314" },]} />
            <NoteWhite text1={"Hamma matematik belgilar bilan tanishish"} path={"/charsets/ref_utf_math"} />
            <hr />
            <Table header={"HTML tomonidan qo'llab-quvvatlanadigan ba'zi yunon harflari"}  th={["Belgi", "Obyekt Nomeri", "Obyekt nomi", "Tavsifi", "Sinab ko'rish"]} values={[
                { id: 0, first: "Α", second: "&#913;", third: "&Alpha;", four: "YUNONCHA KATTA ALPHA HARFI", tryPath: "try/325" },
                { id: 1, first: "Β", second: "&#914;", third: "&Beta;", four: "YUNONCHA KATTA BETA HARFI", tryPath: "try/326" },
                { id: 2, first: "Γ", second: "&#915;", third: "&Gamma;", four: "YUNONCHA KATTA GAMMA HARFI", tryPath: "try/327" },
                { id: 3, first: "Δ", second: "&#916;", third: "	&Delta;", four: "YUNONCHA KATTA DELTA HARFI", tryPath: "try/328" },
                { id: 4, first: 'Ε', second: "&#917;", third: "	&Epsilon;", four: "YUNONCHA KATTA EPSILON HARFI", tryPath: "try/329" },
                { id: 5, first: "Ζ", second: "&#918;", third: "&Zeta;", four: "YUNONCHA KATTA ZETA HARFI", tryPath: "try/330" },]} />
            <NoteWhite text1={"Hamma Yunon belgilari bilan tanishish"} path={"/charsets/ref_utf_greek"} />
            <hr />
            <Table header={"HTML tomonidan qo'llab-quvvatlanadigan ba'zi boshqa obyektlar"} th={["Belgi", "Obyekt Nomeri", "Obyekt nomi", "Tavsifi", "Sinab ko'rish"]} values={[
                { id: 0, first: "©", second: "&#169;", third: "&copy;", four: "Mualliflik huquqi belgisi", tryPath: "try/295" },
                { id: 1, first: "®", second: "&#174;", third: "&reg;", four: "ro'yxatga olingan tovar belgisi", tryPath: "try/296" },
                { id: 2, first: "€", second: "&#8364;", third: "&euro;", four: "	yevro belgisi", tryPath: "try/294" },
                { id: 3, first: "™", second: "&#8482;", third: "&trade;", four: "savdo belgisi", tryPath: "try/315" },
                { id: 4, first: '←', second: "&#8592;", third: "&larr;", four: "Yo'nalish chapga", tryPath: "try/316" },
                { id: 5, first: "↑", second: "&#8594;", third: "&uarr;", four: "Yo'nalish yuqoriga", tryPath: "try/317" },
                { id: 6, first: "→", second: "&#8713;", third: "&rarr;", four: "Yo'nalish o'ngga", tryPath: "try/318" },
                { id: 7, first: "↓", second: "&#8595;", third: "&darr;", four: "Yo'nalish quyiga", tryPath: "try/319" },
                { id: 8, first: "♠", second: "&#9824;", third: "&spades;", four: "Cho'qqilar yoki “qarg`a”", tryPath: "try/320" },
                { id: 9, first: "♣", second: "&#9827;", third: "&clubs;", four: "“Chillak”", tryPath: "try/321" },
                { id: 10, first: "♥", second: "	&#9829;", third: "&hearts;", four: "“Yurak”", tryPath: "try/322" },
                { id: 11, first: "♦", second: "&#9830;", third: "&diams;", four: "“G`ishtin”", tryPath: "try/323" },]} />
            <NoteWhite text1={"To'liq valyuta belgilar bilan tanishish"} path={"/charsets/ref_utf_currency"} />
            <NoteWhite text1={"To'liq yo'nalish belgilar bilan tanishish"} path={"/charsets/ref_utf_arrows"} />
            <NoteWhite text1={"Turli xil belgilar"} path={"/charsets/ref_utf_symbols"} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="3zVpFCmtm20" />
            <NavButton back='/html/html_obects' next='tml/html_emoji' />
        </div>)
}

export default Symbols;