import React from 'react';
import { Link } from 'react-router-dom';
import Collapsible from '../../pages/Collapsible';
import HslChange from '../../pages/ColorHsl';
import W3Path from '../../W3Path';
import '../Info.css';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';

function HtmlHsl() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_colors_hsl.asp"} />
      <h2 className='header-tutorial'>HTML HSL va HSLA ranglari</h2>
      <NavButton back='/html/html_colors_hex' next='/html/html_css' />
      <hr />
      <p className='text-tutorial'><b>HSL</b> <span className='text-tutorial__red'>rang, to'yinganlik va yorug'likni</span>  anglatadi.</p>
      <p className='text-tutorial'><b>HSLA</b> rang qiymatlari <i>Alfa(shaffoflik)</i> va <i>HSL</i> kengaytmasidir.</p>
      <hr />
      <h3 className='header-content'>HSL rang qiymatlari</h3>
      <p className='text-tutorial'>HTMLda HSL rangni quyidagi shaklda <span className='text-tutorial__red'>rang, to'yinganlik va yorug'lik (Hue, Saturation, Lightness)</span>  parametrlariga qiymat berish orqali ifodalash mumkin:</p>
      <p className='text-tutorial'><b><i>hsl (rang, to'yinganlik va yorug'lik)</i></b></p>
      <p className='text-tutorial'><b>Hue</b> - rang doirasidagi 0 dan 360 gacha bo'lgan darajani ifodalaydi. <span style={{ color: "red" }}>0 qizil</span>, <span style={{ color: "green" }}>120 yashil</span> va <span style={{ color: "blue" }}>240 ko'k</span>.</p>
      <p className='text-tutorial'>To'yinganlik - bu foizda beriladi. 0% kulrang soyani, 100% esa to'liq rangni anglatadi.</p>
      <p className='text-tutorial'>Yorug'lik ham foizda beriladi. 0% qora, 100% oqni bildiradi.</p>
      <p className='text-tutorial'>Quyidagi HSL qiymatlarini aralashtirish orqali tajriba o'tkazishingiz mumkin:</p>

      <HslChange />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper' style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", marginBottom: "30px", fontSize: "20px", }}>
          <div style={{ backgroundColor: "hsl(0, 100%, 50%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 100%, 50%)</p></div>
          <div style={{ backgroundColor: "hsl(240, 100%, 50%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(240, 100%, 50%)</p></div>
          <div style={{ backgroundColor: "hsl(147, 50%, 47%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(147, 50%, 47%)</p></div>
          <div style={{ backgroundColor: "hsl(300, 76%, 72%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(300, 76%, 72%)</p></div>
          <div style={{ backgroundColor: "hsl(39, 100%, 50%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(39, 100%, 50%)</p></div>
          <div style={{ backgroundColor: "hsl(248, 53%, 58%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(248, 53%, 58%)</p></div>
        </div>
        <Link className='button btn btn--large green' to={`try/62`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <h3 className='header-content'>To'yinganlik</h3>
      <p className='text-tutorial'><b>To'yinganlik</b>ni rangning intensivligi deb ta'riflash mumkin.</p>
      <p className='text-tutorial'><i>100%</i> sof rang, kulrang soyalar yo'q.</p>
      <p className='text-tutorial'><i>50%</i> kulrang, lekin siz hali ham rangni ko'rishingiz mumkin.</p>
      <p className='text-tutorial'><i>0%</i> butunlay kulrang, shuning uchun rangni ko'ra olmaysiz.</p>
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper' style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", marginBottom: "30px", fontSize: "20px", }}>
          <div style={{ backgroundColor: "hsl(0, 100%, 50%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 100%, 50%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 80%, 50%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 80%, 50%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 60%, 50%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 60%, 50%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 40%, 50%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 40%, 50%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 20%, 50%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>hsl(0, 20%, 50%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 0%, 50%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>hsl(0, 0%, 50%)</p></div>
        </div>
        <Link className='button btn btn--large green' to={`try/63`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <h3 className='header-content'>Yorug'lik</h3>
      <p className='text-tutorial'>Rangning ochligini rangga qancha yorug'lik berilgani bilan tasvirlash mumkin, bu erda 0% yorug'lik yo'q (qora), 50% yorug'lik (na qorong'i, na yorug'lik) va 100% to'liq ochlik (oq) degan ma'noni anglatadi. ).</p>
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper' style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", marginBottom: "30px", fontSize: "20px", }}>
          <div style={{ backgroundColor: "hsl(0, 100%, 0%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 100%, 0%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 100%, 25%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 100%, 25%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 100%, 50%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 100%, 50%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 100%, 75%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 100%, 75%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 100%, 90%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>hsl(0, 100%, 90%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 100%, 100%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>hsl(0, 100%, 100%)</p></div>
        </div>
        <Link className='button btn btn--large green' to={`try/64`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />

      <h3 className='header-content'>Kulrang soyalar</h3>
      <p className='text-tutorial'>Kulrang soyalar ko'pincha rang va to'yinganlikni 0 ga o'rnatish va quyuqroq/ochroq soyalarni olish uchun yorug'likni 0% dan 100% gacha sozlash orqali aniqlanadi:</p>
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper' style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", marginBottom: "30px", fontSize: "20px", }}>
          <div style={{ backgroundColor: "hsl(0, 0%, 20%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 0%, 20%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 0%, 30%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 0%, 30%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 0%, 40%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 0%, 40%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 0%, 60%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsl(0, 0%, 60%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 0%, 70%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>hsl(0, 0%, 70%)</p></div>
          <div style={{ backgroundColor: "hsl(0, 0%, 90%)", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>hsl(0, 0%, 90%)</p></div>
        </div>
        <Link className='button btn btn--large green' to={`try/65`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />

      <h3 className='header-content'>HSLA rang qiymatlari</h3>
      <p className='text-tutorial'><b>HSLA </b>rang qiymatlari HSL va Alfa(shaffofligi) qiymatlarining kengaytmasi bo'lib, rang uchun shaffoflikni belgilaydigan Alfa kanali mavjud.</p>
      <p className='text-tutorial'><b>HSLA</b> rang qiymati quyidagicha belgilanadi:</p>
      <p className='text-tutorial'><b><i>hsla ( rang, to'yinganlik , yorug'lik, alfa )</i></b></p>

      <p className='text-tutorial'>Alfa parametri <u>0,0 (to'liq shaffof)</u> va <u>1,0 (umuman shaffof emas)</u> orasidagi raqamdir.</p>
      <p className='text-tutorial' id='color_id2'>Quyidagi HSLA qiymatlarini aralashtirish orqali tajriba qilishingiz mumkin:</p>
      <HslChange alpha="0.5" />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper' style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", marginBottom: "30px", fontSize: "20px", }}>
          <div style={{ backgroundColor: "hsla(9, 100%, 64%, 0)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsla(9, 100%, 64%, 0)</p></div>
          <div style={{ backgroundColor: "hsla(9, 100%, 64%, 0.2)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsla(9, 100%, 64%, 0.2)</p></div>
          <div style={{ backgroundColor: "hsla(9, 100%, 64%, 0.4)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsla(9, 100%, 64%, 0.4)</p></div>
          <div style={{ backgroundColor: "hsla(9, 100%, 64%, 0.6)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsla(9, 100%, 64%, 0.6)</p></div>
          <div style={{ backgroundColor: "hsla(9, 100%, 64%, 0.8)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsla(9, 100%, 64%, 0.8)</p></div>
          <div style={{ backgroundColor: "hsla(9, 100%, 64%, 1)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>hsla(9, 100%, 64%, 1)</p></div>
        </div>
        <Link className='button btn btn--large green' to={`try/66`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <h3 className='header-content'>Dars videosi</h3>
      <YoutubeEmbed embedId="IYsppQIm7SE" />
      <Collapsible>
        <p className='text-tutorial'>Ranglarni aralashtirib maket orqa-foni uchun rang tanlang va undan foydalaning:</p>
        <a href='#color_id2' rel="noreferrer"> rang tanlash</a>
      </Collapsible>
      <NavButton back='/html/html_colors_hex' next='/html/html_css' />
    </article>
  )
}

export default HtmlHsl;