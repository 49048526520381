import React, { useState } from 'react';
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import Tutorials from './Tutorials';
import './Tutorials.css';



function SideBar({ datas }) {
  const [click, setclick] = useState(false);
  const handleClick = () => setclick(!click);
  const closeMobileMenu = () => setclick(false);

  return (
    <aside className='tutorial__content'>
      <div className='tutorials__wrapper'>
        <div className='tutorial-list' onClick={handleClick}>
          <h2 className='tutorial-header'>
            <span style={{ marginRight: 'auto' }}> Mavzular</span>
            {click && window.innerWidth < 960 ? < BsCaretUpFill /> : <BsCaretDownFill />}
          </h2>
        </div>
        <nav className={click && window.innerWidth < 960 ? 'tutorials__wrap active' : 'tutorials__wrap'} onClick={closeMobileMenu}>
          <Tutorials datas={datas} />
        </nav>
      </div>
    </aside>
  )
}

export default SideBar;