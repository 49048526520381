import React from 'react';
import SideBar from './SideBar';

const jsTutorial = [{
  title: "HTML HOME",
  id: 40,
  subTutorial: [
    { id: 1, title: "HTML bilan tanishish", path: "html_intro" },
    { id: 2, title: "HTML muharrirlari", path: "html_editor" },
    { id: 3, title: "HTML asosiy misollar", path: "/html" },
    { id: 4, title: "HTML elementlari", path: "/html" },
    { id: 5, title: "HTML atributlari", path: "/html" },
    { id: 6, title: "HTML sarlavhalari", path: "/html" },
    { id: 7, title: "HTML paragraflari", path: "/html" },
    { id: 8, title: "HTML stillari", path: "/html" },
    { id: 9, title: "HTML formatlash", path: "/html" },
    { id: 10, title: "HTML iqtiboslari", path: "/html" },
    { id: 11, title: "HTML sharhlari", path: "/html" },
    { id: 12, title: "HTML ranglari", path: "/html" },
    { id: 13, title: "HTML CSS", path: "/html" },
    { id: 14, title: "HTML havolalar", path: "/html" },
    { id: 15, title: "HTML tasvirlar", path: "/html" },
    { id: 16, title: "HTML Favicon", path: "/html" },
    { id: 17, title: "HTML jadvallari", path: "/html" },
    { id: 18, title: "HTML ro'yhatlari", path: "/html" },
    { id: 19, title: "HTML Block va Inline", path: "/html" },
    { id: 20, title: "HTML sinflari", path: "/html" },
    { id: 21, title: "HTML identifikatori", path: "/html" },
    { id: 22, title: "HTML Iframe", path: "/html" },
    { id: 23, title: "HTML JavaScript", path: "/html" },
    { id: 24, title: "HTML File Paths", path: "/html" },
    { id: 25, title: "HTML Head", path: "/html" },
    { id: 26, title: "HTML Layout", path: "/html" },
    { id: 27, title: "HTML Responsive", path: "/html" },
    { id: 28, title: "HTML Komputer kodi", path: "/html" },
    { id: 29, title: "HTML semantikasi", path: "/html" },
    { id: 30, title: "HTML Style qo'llanmasi", path: "/html" },
    { id: 31, title: "HTML Obyektlari", path: "/html" },
    { id: 32, title: "HTML simbollari", path: "/html" },
    { id: 33, title: "HTML Emojis", path: "/html" },
    { id: 34, title: "HTML belgilar to'plami", path: "/html" },
    { id: 35, title: "HTML URL kodlash", path: "/html" },
    { id: 36, title: "HTML va XHTML", path: "/html" },]
},
{
  title: "HTML shakllari",
  id: 41,
  subTutorial: [
    { id: 37, title: "HTML semantikasi", path: "/html" },
    { id: 38, title: "HTML semantikasi", path: "/html" },
    { id: 39, title: "HTML semantikasi", path: "/html" }
  ]
}
];
function JavaScript() {
  return (
    <div className="tutorial container">
      <SideBar datas={jsTutorial} />
    </div>
  )
}

export default JavaScript;