import React, { useState } from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import Table from '../../pages/Table';
import Notes from '../../pages/Notes';
import { Link } from 'react-router-dom';



function JsHtml() {
  const [textP, setTextP] = useState('');

  function currentDate() {
    var today = new Date();

    return today.toLocaleString('en-US', { weekday: 'short', month: 'short', year: 'numeric', day: '2-digit', hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'longOffset' }).split(',');
  }


  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_scripts.asp"} />
      <h2 className='header-tutorial'>JavaScript</h2>
      <NavButton back='/html/html_iframe' next='/html/html_filepaths' />
      <hr />
      <p>JavaScript HTML sahifalarini yanada dinamik va interaktiv qiladi.</p>
      <TextContent code1={`
<button type="button"
onclick="document.getElementById('demo').innerHTML = Date()">
Sana va vaqtni bilish uchun bosing.</button>`} path1={"try/238"} />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <p className='text-tutorial'>{ }</p>
        <div className='code-wrapper'>
          <h2>Mening birinchi JavaScriptda yozgan kodim</h2>
          <button style={{padding: '10px', fontSize: '18px', marginBottom:'10px'}} onClick={()=>setTextP(currentDate())} type='button'>Sana va vaqtni ko'rish uchun bosilsin</button>
          <p>{textP}</p>
        </div>
        <Link className='button btn btn--large green' to={"try/238"} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />

      <TextContent header={"HTML <script> tegi"}
        text1={"HTML <script> tegi yordamida foydalanuvchi tomonidan skriptlarni(ko'rish senariylarini) boshqarish uchun muayyan dasturlash tili(JavaScript)dagi kod va matnlar kiritiladi."}
        redWords1={["<script>"]}
        text2={"<script> elementi ichida veb sahifa script qismini, ya'ni muayyan til(JavaScript)dagi kodlarni joylashtirish mumkin yoki src atributi yordamida script faylini veb sahifamizga ulashimiz mumkin."}
        redWords2={["<script>", "src"]}
        text3={`JavaScript asosan tasvirlarga ishlov berish, formalarni to'ldirish va tekshirish hamda kontentlarni dinamik o'zgartish uchun ishlatiladi.`}
        redWords3={["JavaScript"]}
        text4={"HTML elementini tanlash uchun JavaScriptda ko'pincha document.getElementById() metodidan foydalaniladi."}
        redWords4={["document.getElementById()",]} />
      <p>Quyidagi misolda id="demo" ga ega element ichiga JavaScript yordamida "Salom JavaScript" deb yozamiz.</p>
      <TextContent code1={`<script>
document.getElementById("demo").innerHTML = "Salom JavaScript!";
</script>`}
        path1={"try/239"} />
      <Notes type={"Maslahat:"} text={"JavaScript haqida ko'proq"} text1={"JavaScript mavzulari"} path="/js" text2={"bo'limida bilib olishingiz mumkin."} />
      <hr />
      <TextContent header={"JavaScriptda amaliyot"}
        text1={"JavaScript bilan nimalar qila olishimizga doir misollar:"}
        code1={`
<script>
  function myFunction() {
  document.getElementById("demo").innerHTML = "Salom JavaScript!";
}
</script>`} path1={"try/240"} code1Text={"JavaScript yordamida kontentni o'zgartirishi mumkin:"}
        code2={`<script>
function myFunction() {
  document.getElementById("demo").style.fontSize = "25px";
  document.getElementById("demo").style.color = "red";
  document.getElementById("demo").style.backgroundColor = "yellow";
}
</script>`} path2={"try/241"} code2Text={"JavaScript yordamida element stilini o'zgartirish:"}
        code3={`
<script>
function light(sw) {
  var pic;
  if (sw == 0) {
    pic = "pic_bulboff.gif"
  } else {
    pic = "pic_bulbon.gif"
  }
  document.getElementById('myImage').src = pic;
}
</script>`} path3={"try/242"} code3Text={"JavaScript yordamida img elementi src qiymatini o'zgartirish"} />
      <hr />
      <TextContent header={"HTML <noscript> tegi"}
        text1={"HTML <noscript> elementi  skriptlari o'chirib qo'yilgan brauzer yoki skriptlarni qo'llab-quvvatlamaydigan brauzerga ega foydalanuvchilarga ko'rsatiladigan muqobil tarkibni belgilaydi:"}
        redWords1={["<noscript>"]}
        code1={`<script>
document.getElementById("demo").innerHTML = "Salom JavaScript!";
</script>
<noscript>Kechirasiz, brauzer JavaScriptni qo'llab-quvvatlamaydi!</noscript>`}
        path1={`try/243`} />
      <hr />
      <Table header={"Script teglari"} th={["Teg", "Tavsif"]} values={[
        { id: 0, first: "<script>", second: "Foydalanuvchi tomonidan skriptlarni(ko'rish senariylarini) boshqarish uchun muayyan dasturlash tili(JavaScript)dagi kod va matnlar kiritishda ishlatiladi.", path: "/tags/tag_script" },
        { id: 2, first: "<noscript>", second: "Skriptlarni qo'llab-quvvatlamaydigan brauzerga ega foydalanuvchilarga ko'rsatiladigan muqobil tarkibni belgilaydi", path: "/tags/tag_noscript" }]} />
      <Notes text={"Barcha mavjud HTML teglarining to'liq ro'yxatini ko'rish uchun bizning"} path="/tags" text1={" HTML teg ma'lumotnomamizga"} text2={"tashrif buyuring."} />
      <h3 className='header-content'>Dars videosi:</h3>
      <YoutubeEmbed embedId="CW2mquSk1l4" />
      <NavButton back='/html/html_iframe' next='/html/html_filepaths' />
    </article>)
}

export default JsHtml;