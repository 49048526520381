import React from 'react';
import NavButton from '../NavButton';
import '../Tutorials.css';
import YoutubeEmbed from '../YoutubeEmbed';
import CodeContent from '../../../tryit/CodeContent';
import { Link } from 'react-router-dom';
import CodeMirrorContentCss from '../../../tryit/CodeContent/CodeMirrorContentCss';
import Table from '../../pages/Table';
import W3Path from '../../W3Path';
import ListText from '../../pages/ListsText';
import Collapsible from '../../pages/Collapsible';

function HtmlCss() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_css.asp"} />
      <h2 className='header-tutorial text-tutorial-margin'>HTML stillari - CSS</h2>
      <NavButton back='/html/html_colors_hsl' next='/html/html_link' />
      <hr />
      <p className='text-tutorial'>CSS(Cascading Style Sheets ) pag'onali stillar jadvali degan ma'noni anglatadi.</p>
      <p className='text-tutorial text-tutorial-margin'>CSS yordamida ko'p ishlarni oson bajarolamiz va bir vaqtning o'zida bir nechta veb-sahifalarga stillar berish mumkin.</p>
      <hr />
      <div style={{ position: "relative", height: "220px", marginTop: "50px", marginBottom: "200px" }}>
        <div className='blue-box'></div>
        <div className='green-box'></div>
        <div className='border-box'>
          <h1 style={{ margin: "0" }}>CSS - stillar va ranglar</h1>
          <p style={{ letterSpacing: "12px", fontSize: "15px", position: "relative", left: "25px", top: "10px", margin: "0" }}>Matnni manipulyatsiya qilish</p>
          <div style={{ color: "#40B3DF", letterSpacing: "12px", fontSize: "15px", position: "relative", left: "25px", top: "20px" }}>
            <p >Ranglar,</p>
            <span style={{ backgroundColor: "#B4009E", color: "#ffffff", textAlign: "center" }}>qutilar</span>
          </div>
        </div>
      </div>

      <hr />
      <h3 className='header-content'>CSS nima?</h3>
      <p className='text-tutorial'>Cascading Style Sheets (CSS) veb-sahifaga stillar berish uchun ishlatiladi.</p>
      <p className='text-tutorial text-tutorial-margin'>CSS yordamida siz rangni, shriftni, matn hajmini, elementlar orasidagi masofani, elementlar qanday joylashishini va joylashish tartibini, qanday fon rasmlari yoki fon ranglarini ishlatishni, turli qurilmalar va ekran o'lchamlari o'zgarganda veb-sahifa qanday ko'rinishga ega bo'lishini nazorat qilishingiz mumkin. </p>
      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong>  <b>Cascading</b> so'zi asosiy elementga berilgan stillar element ichidagi barcha kichik elementlarga ham tegishli ekanini anglatadi. Ayatylik siz body elementi uchun matn rangini "ko'k" rangga o'rnatsangiz, body ichidagi barcha sarlavhalar, paragraflar va  boshqa elementlarning matnlari ham bir xil "ko'k" rangga ega bo'ladi (agar siz ularda boshqa rangni belgilamasangiz)!</p>
      </div>
      <hr />
      <h3 className='header-content'>CSS-dan foydalanish</h3>
      <p className='text-tutorial'>CSS-ni HTML hujjatlariga 3 xil usulda qo'shish mumkin:</p>
      <ul className='lists'>
        <li className='item'><p><b>Inline</b> - HTML elementlari ichidagi <span className='text-tutorial__red'>style</span> atribut yordamida</p></li>
        <li className='item'><p><b>Internal </b>-&lt;head&gt; elementi ichida &lt;style&gt;elementi orqali</p></li>
        <li className='item'><p><b>External</b>- Tashqi CSS fayli yordamida</p></li>
      </ul>
      <p className='text-tutorial'>CSSdan foydalanishning eng keng tarqalgan usuli bu  tashqi CSS fayllari yordamida stillar berish hisoblanadi.</p>
      <hr />
      <h3 className='header-content'>Inline CSS</h3>
      <p className='text-tutorial'>Html bittagina elementiga still berish uchun Inline CSSdan foydalaniladi. </p>
      <p className='text-tutorial'>Inline still berish uchun <span className='text-tutorial__red'>style</span> atributidan foydalaniladi</p>
      <p className='text-tutorial text-tutorial-margin'>Quyidagi misolda <span className='text-tutorial__red'>&lt;h1&gt; </span>elementi matn rangini ko'k va <span className='text-tutorial__red'>&lt;p&gt;</span> elementi matn rangini qizil rangga o'rnatadi:</p>
      <CodeContent props={`<h1 style="color:blue;">Ko'k sarlavha</h1>

<p style="color:red;">Qizil rangli matn.</p>`} pathname={`try/67`} />
      <hr />
      <h3 className='header-content'>Internal CSS</h3>
      <p className='text-tutorial'>Internal CSS bitta veb-sahifaga still berish uchun ishlatiladi.</p>
      <p className='text-tutorial'>Internal still berish uchun <span className='text-tutorial__red'>&lt;head&gt;</span> elementi ichida <span className='text-tutorial__red'>&lt;style&gt;</span> elementidan foydalaniladi.</p>
      <p className='text-tutorial text-tutorial-margin'>Quyidagi misolda hamma &lt;h1&gt; elementlarning(ushbu sahifadagi) matn rangini ko‘k rang va barcha &lt;p&gt; elementlarning matn rangini esa qizil rangga o'zgartiriladi. Bundan tashqari, sahifa "powderblue" fon rangi bilan ko'rsatiladi: </p>
      <CodeContent props={`<!DOCTYPE html>
<html>
<head>
<style>
body {background-color: powderblue;}
h1   {color: blue;}
p    {color: red;}
</style>
</head>
<body>

<h1>Bu sarlavha</h1>
<p>Bu esa matn.</p>

</body>
</html>`} pathname={`try/68`} />
      <hr />
      <h3 className='header-content'>External CSS</h3>
      <p className='text-tutorial'>Bir vaqtning o'zida ko'plab veb-sahifalarga stillar berish uchun external still berish usulidan foydalaniladi.</p>
      <p className='text-tutorial text-tutorial-margin'>Tashqi stillar faylidan foydanish uchun <span className='text-tutorial__red'>&lt;head&gt;</span> ichida link yordamida faylimizni e'lon qilib qo'yishimiz kerak.</p>
      <CodeContent props={`<!DOCTYPE html>
<html>
<head>
  <link rel="stylesheet" href="styles.css">
</head>
<body>

<h1>Bu sarlavha</h1>
<p>Bu es matn.</p>

</body>
</html>`} pathname={`try/69`} />
      <p className='text-tutorial'>Css fayli istalgan matn muharririda yozilishi mumkin. Fayl hech qanday HTML kodini o'z ichiga olmasligi va <b>.css</b> kengaytmasi bilan saqlanishi kerak.</p>
      <p className='text-tutorial text-tutorial-margin'>"styles.css" deya nom berilgan css fayli quyidagicha ko'rinishga ega bo'lishi mumkin:</p>
      <div className='code-content'>
        <h4 className='header-code'>"styles.css"</h4>
        <div className='code-wrapper'>
          <CodeMirrorContentCss value={`body {
  background-color: powderblue;
}
h1 {
  color: blue;
}
p {
  color: red;
}`} />
        </div>
      </div>

      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong>Css faylini o'zgartirish orqali butun veb sayt ko'rinishini o'zgartirish mumkin. </p>
      </div>

      <hr />
      <h3 className='header-content'>CSS ranglari, shriftlari va o'lchamlari</h3>
      <p className='text-tutorial'>Ko'p ishlatiladigan CSS xususiyatlari bilan tanishib olamiz.</p>
      <p className='text-tutorial'>Matn rangi CSS <span className='text-tutorial__red'>&lt;color&gt;</span> xususiyati yordamida o'zgartiriladi.</p>
      <p className='text-tutorial'>CSS<span className='text-tutorial__red'>&lt;font-family&gt;</span> xususiyatidan foydalanib matn shriftini o'zgartirish mumkin.</p>
      <p className='text-tutorial text-tutorial-margin'>CSS<span className='text-tutorial__red'>&lt;font-size&gt;</span> xususiyatidan foydalanib matn o'lchamini o'zgartirish mumkin.</p>
      <CodeContent props={`<!DOCTYPE html>
<html>
<head>
<style>
h1 {
  color: blue;
  font-family: verdana;
  font-size: 300%;
}
p {
  color: red;
  font-family: courier;
  font-size: 160%;
}
</style>
</head>
<body>

<h1>This is a heading</h1>
<p>This is a paragraph.</p>

</body>
</html>`} pathname={`try/70`} text={"CSS color, font-family va font-size xususiyatlaridan foydalanish:"} />
      <hr />
      <h3 className='header-content'>CSS border</h3>
      <p className='text-tutorial'>CSS <span className='text-tutorial__red'>&lt;border&gt;</span> xususiyati HTML elementi atrofidagi chegara stillarini berish uchun ishlatiladi.</p>
      <p className='text-tutorial text-tutorial-margin'><b>Maslahat:</b> Deyarli barcha HTML elementlari uchun chegarastillarini berish mumkin.</p>
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper'>
          <CodeMirrorContentCss value={`p {
  border: 2px solid powderblue;
}`} />
        </div>
        <Link className='button btn btn--large green' to={`try/71`} target={"_blank"}>Sinab ko'rish</Link>
      </div>

      <hr />
      <h3 className='header-content'>CSS padding</h3>
      <p className='text-tutorial text-tutorial-margin'>Css padding xususiyati bu element va uning chegarasi orasidagi bo'sh joy.</p>
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <p className='text-tutorial'>Css padding va border xususiyatlari ishlatilishi:</p>
        <div className='code-wrapper'>
          <CodeMirrorContentCss value={`p {
  border: 2px solid powderblue;
  padding: 30px;
}`} />
        </div>
        <Link className='button btn btn--large green' to={`try/72`} target={"_blank"}>Sinab ko'rish</Link>
      </div>


      <hr />
      <h3 className='header-content'>CSS Margin</h3>
      <p className='text-tutorial text-tutorial-margin'>CSS margin xususiyati bu element chegarasi(border)dan tashqaridagi bo'sh joy.</p>
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <p className='text-tutorial'>Css padding va border xususiyatlari ishlatilishi:</p>
        <div className='code-wrapper'>
          <CodeMirrorContentCss value={`p {
  border: 2px solid powderblue;
  margin: 50px;
}`} />
        </div>
        <Link className='button btn btn--large green' to={`try/73`} target={"_blank"}>Sinab ko'rish</Link>
      </div>

      <hr />
      <h3 className='header-content'>Tashqi(External) CSS linki.</h3>
      <p className='text-tutorial text-tutorial-margin'>Tashqi stillar fayliga to'liq URL yoki joriy veb-sahifaga nisbatan manzili orqali murojaat qilish mumkin.</p>
      <CodeContent props={`<link rel="stylesheet" href=" <link rel="stylesheet" href="https://self-study.uz/html/styles.css">`} pathname={`try/74`} text={"Ushbu misol css fayliga murojaat qilish uchun to'liq URL manzilidan foydalanadi:"} />
      <CodeContent props={` <link rel="stylesheet" href="/html/styles.css">`} pathname={`try/75`} text={"Ushbu misol joriy veb-saytdagi html papkasida joylashgan css fayliga havola qiladi: "} />
      <CodeContent props={`<link rel="stylesheet" href="styles.css">`} pathname={`try/76`} text={"Ushbu misol joriy sahifa bilan bir xil papkada joylashgan Css fayliga havola qiladi:"} />
      <div className='note-content'>
        <p className='text-tutorial'> Fayllar manzili haqida   <Link className='text-tutorial text-tutorial__link' to={'/html/html_filepaths'}>ko'proq bilib olish</Link> </p>
      </div>
      <hr />
      <ListText list={[{
        text: "Inline still berish uchun style atributidan foydalaniladi",
        redWords: ["style"]
      },
      {
        text: "Internal still berish uchun style elementidan foydalaniladi.",
        redWords: ["style"]
      },
      {
        text: "Tashqi stillar faylidan foydanish uchun link  yordamida faylimizni e'lon qilib qo'yishimiz kerak.",
        redWords: ["link"]
      },
      {
        text: "link va style elementlari head elementi ichida yoziladi.",
        redWords: ["link", "style", "head"]
      },
      {
        text: "Matn rangini o'zgartirish uchun color xusuiyatidan foydalanamiz",
        redWords: ["color"]
      },
      {
        text: "CSS font-family xususiyatidan foydalanib matn shriftini o'zgartirish mumkin.",
        redWords: ["font-family"]
      },
      {
        text: "font-size xususiyatidan foydalanib matn o'lchamini o'zgartirish mumkin.",
        redWords: ["font-size"]
      },
      {
        text: "border xususiyati HTML elementi atrofidagi chegara stillarini berish uchun ishlatiladi",
        redWords: ["border"]
      },
      {
        text: "CSS padding xususiyati bu element va uning chegarasi orasidagi bo'sh joy.",
        redWords: ["padding"]
      },
      {
        text: "CSS margin xususiyati bu element chegarasi(border)dan tashqaridagi bo'sh joy.",
        redWords: ["margin"]
      },

      ]} />

      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong> Stillar haqida <Link className='table-link' to={"/css"}>CSS</Link> bo'limida ko'proq bilib olasiz.</p>
      </div>
      <hr />
      <Table th={["Teg", "Tavsif",]} values={[
        { id: 0, first: "<style>", second: "HTML hujjati uchun stillar yoziladi.", path: "/tags/tag_style" },
        { id: 0, first: "<link>", second: "Hujjatning tashqi manbaga  murojaatini ta'minlaydi.", path: "/tags/tag_link" },
      ]} />
      <div className='note-content'>
        <p className='text-tutorial'>Barcha mavjud HTML teglarining to'liq ro'yxatini <Link className='table-link' to={"#"}>HTML teg ma'lumotnomamiz</Link>dan  ko'rishingiz mumkin. </p>
      </div>
      <hr />
      <YoutubeEmbed embedId="Rw4gpfgSAIc" />
      <Collapsible>
        <p className='text-tutorial'>Tashqi Css dan foydalanib, yashil rangli orqa-fonga ega, qizil chegarali, tomoni 200px bo'lgan kvadrat yaratilsin:</p>
      </Collapsible>
      <NavButton back='/html/html_colors_hsl' next='/html/html_link' />
    </article>
  )
}

export default HtmlCss;
