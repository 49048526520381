import React from 'react';
import Notes from '../../../pages/Notes';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function ColspanRowspan() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_table_colspan_rowspan.asp"} />
            <h2 className='header-tutorial'>Colspan va Rowspan</h2>
            <NavButton back='/html/html_table_padding_spacing' next='/html/html_table_styling' />
            <hr />
            <TextContent text1={"HTML jadvallari bir nechta qator yoki kataklar birlashishidan hosil bo'luvchi kataklarga ega bo'lishi mumkin."} />
            <hr />
            <div>
                <table className="table2" style={{ width: "30%", float: "left" }}>
                    <tbody><tr>
                        <th colspan="2">NAME</th>
                        <td>&nbsp;</td>
                    </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
                <table class="table2" style={{ width: "30%", float: "left", marginLeft: "5%" }}>
                    <tbody><tr>
                        <th rowspan="3">APREL</th>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
                <table class="table2" style={{ width: "30%", float: "right" }}>
                    <tbody><tr>
                        <th colspan="3">2022</th>
                    </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th colspan="2" rowspan="2">FIESTA</th>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
            </div>
            <TextContent header={"Colspan"}
                text1={"Bir nechta kataklarni birlashtirish uchun colspan atributdan foydalaniladi:"}
                redWords1={["colspan"]}
                code1={`<table>
<tr>
  <th colspan="2">Ism</th>
  <th>Yosh</th>
</tr>
<tr>
  <td>Jill</td>
  <td>Smith</td>
  <td>43</td>
</tr>
<tr>
  <td>Eve</td>
  <td>Jackson</td>
  <td>57</td>
</tr>
</table>`}
                path1={"try/176"}
            />
            <Notes type={"Eslatma"} text={"colspan atributining qiymati birlashtirilishi kerak kataklar sonini bildiradi."} />
            <hr />
            <TextContent header={"Rowspan"}
                text1={"Bir nechta qatorlar bo'ylab kataklarni birlashtirish uchun rowspan atributdan foydalaniladi:"}
                redWords1={["rowspan"]}
                code1={`<table>
<tr>
  <th>Name</th>
  <td>Jill</td>
</tr>
<tr>
  <th rowspan="2">Phone</th>
  <td>555-1234</td>
</tr>
<tr>
  <td>555-8745</td>
</tr>
</table>`}
                path1={"try/177"}
            />
            <Notes type={"Eslatma"} text={"rowspan atributining qiymati birlashtirilgan qatorlar sonini ifodalaydi."} />
            <hr />
            <TextContent header={"Dars videosi:"} />
            <YoutubeEmbed embedId="HVAROzpJ_g4" />
            <NavButton back='/html/html_table_padding_spacing' next='/html/html_table_styling' />
        </article>)
}
export default ColspanRowspan;
