import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import ListText from '../../../pages/ListsText';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';
import NoteWhite from '../../../pages/NoteWhite';


function FormElements() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_form_elements.asp"} />
            <h2 className='header-tutorial'>HTML forma elementlari</h2>
            <NavButton back='/html/html_form_atribut' next='/html/html_input' />
            <hr />
            <p>Ushbu bobda HTML forma elementlari bilan tanishasiz.</p>
            <hr />
            <TextContent header={"HTML <form> elementlari"}
                text1={"HTML <form> elementi quyidagi forma elementlaridan bir yoki bir nechtasini o'z ichiga olishi mumkin:"}
                redWords1={["<form>"]} />
            <ListText list={[{
                text: "<input>",
                redWords: ["<input>"]
            },
            {
                text: "<label>",
                redWords: ["<label>"]
            },
            {
                text: "<select>",
                redWords: ["<select>"]
            },
            {
                text: "<textarea>",
                redWords: ["<textarea>"]
            },
            {
                text: "<button>",
                redWords: ["<button>"]
            },
            {
                text: "<fieldset>",
                redWords: ["<fieldset>"]
            },
            {
                text: "<legend>",
                redWords: ["<legend>"]
            },
            {
                text: "<datalist>",
                redWords: ["<datalist>"]
            },
            {
                text: "<output>",
                redWords: ["<output>"]
            },
            {
                text: "<option>",
                redWords: ["<option>"]
            },
            {
                text: "<optgroup>",
                redWords: ["<optgroup>"]
            },]} />
            <hr />
            <TextContent header={"<input> elementi"}
                text1={"Eng ko'p ishlatiladigan forma elementlaridan biri <input> elementidir."}
                redWords1={["<input>"]}
                text2={"<input> elementi type atributiga qarab bir necha turda ko'rsatilishi mumkin."}
                redWords2={["type", "<input>"]}
                code2={`<label for="fname">Ism:</label>
<input type="text" id="fname" name="fname">`}
                path2={"try/355"}/>
                <NoteWhite text={"Atributning barcha turli type qiymatlari  keyingi bobda yoritilgan:"} path={"/html/html_form_input_types"} text1={"HTML input turlari"}/>
            <hr />
            <TextContent header={"<label> elementi"}
                text1={"<label> elementi bir nechta forma elementlari uchun qisqacha ma'lumot ilova qilish uchun ishlatiladi."}
                redWords1={["<label>"]}
                text2={"<label> elementi ekranni o'qish qurilmalaridan foydalanuvchilar e'tiborini input elementiga qaratganda ekran o'quvchisi labelni baland ovozda o'qiydi."}
                redWords2={["<label>"]}
                text3={"<label> elementi, shuningdek, juda kichik maydonlarni (masalan, radio buttonlar yoki checkboxlar) bosishda qiynalayotgan foydalanuvchilarga yordam beradi - chunki foydalanuvchi radio button yoki checkboxning label matnini bosganda ham ular belgilanadi."}
                redWords3={["<label>"]}
                text4={"<input> tegining id atributi ularni bir-biriga bog'lash uchun <label> elementning for atributi bilan bir xil bo'lishi kerak."}
                redWords4={["<input>", "for", "<label>"]} />
            <hr />
            <TextContent header={"<select> elementi"}
                text1={"<select> elementi ochiladigan ro'yxatni belgilaydi:"}
                redWords1={["<select>"]}
                code1={`<label for="cars">Mashina tanlang:</label>
<select id="cars" name="cars">
  <option value="volvo">Volvo</option>
  <option value="saab">Saab</option>
  <option value="fiat">Fiat</option>
  <option value="audi">Audi</option>
</select>`}path1={"try/356"}
                text2={"<option> elementlari tanlanishi mumkin bo'lgan variantlarni belgilaydi."}
                redWords2={["<option>"]}
                text3={"Default holatda ochiladigan ro'yxatdagi birinchi element tanlangan bo'ladi."}
                text4={"Variantni o'zimiz tanlab qo'yishimiz uchun belgilash uchun atributni selected parametr qo'shiladi:"}
                redWords4={["selected"]}
                code4={`<option value="fiat" selected>Fiat</option>`}
                path4={"try/357"} />
                  <TextContent header={"Ko'rinib turadigan qiymatlar:"}
                text1={"size atributidan ko'rinib turadigan qiymatlar sonini belgilash uchun foydalaniladi :"}
                redWords1={["size"]}
                code1={`<label for="cars">Mashinani tanlang:</label>
<select id="cars" name="cars" size="3">
  <option value="volvo">Volvo</option>
  <option value="saab">Saab</option>
  <option value="fiat">Fiat</option>
  <option value="audi">Audi</option>
</select>`}path1={"try/359"}/>
     <TextContent header={"Bir nechta tanlovga ruxsat berish:"}
                text1={"Foydalanuvchiga bir nechta qiymatlarni tanlashga ruxsat berish uchun multiple atributidan foydalaniladi:"}
                redWords1={["multiple"]}
                code1={`<label for="cars">Mashina tanlang:</label>
<select id="cars" name="cars" size="4" multiple>
  <option value="volvo">Volvo</option>
  <option value="saab">Saab</option>
  <option value="fiat">Fiat</option>
  <option value="audi">Audi</option>
</select>`}path1={"try/359"}/>
            <hr />
            <TextContent header={"<textarea> elementi"}
                text1={"<textarea> elementi ko'p qatorli ma'lumot kiritish maydonini(matn maydoni) belgilaydi:"}
                redWords1={["multiple"]}
                code1={`<textarea name="message" rows="10" cols="30">
Bu joyga yozish mumkin.
</textarea>`}path1={"try/360"}
text2={"rows atributi matn maydonidagi ko'rinib turadigan satrlarning sonini belgilaydi."}
redWords2={["rows"]}
text3={"cols atributi matn maydonining ko'rinadigan matn kengligidagi maksimal belgilar sonini belgilaydi.(Hisoblashda o'rtacha belgilar kengligidan foydalaniladi.)"}
redWords3={["cols"]}
               text4={"Yuqoridagi HTML kodi brauzerda shunday ko'rsatiladi:"}/>
             <div>
             <textarea rows="10" cols="30">Bu joyga yozsa bo'ladi...</textarea>
             </div>
               <TextContent
                text1={"CSSdan foydalanib ham textareaning o'lchamini belgilashingiz mumkin:"}
                code1={`<textarea name="message" style="width:200px; height:600px;">
Bu joyga matn yozsa bo'ladi.
</textarea>`}path1={"try/361"}/>
      <hr />
            <TextContent header={"<button> elementi"}
                text1={"<button> elementi bosiladigan tugmani belgilaydi:"}
                redWords1={["<button>"]}
                code1={`<button type="button" onclick="alert('Salom dunyo!')">Bosish!</button>`}
                path1={"try/362"}
                text2={"Yuqoridagi HTML kodi brauzerda shunday ko'rsatiladi:"}/>
            <div>
            <button type="button" onClick={() => {
    alert("Salom dunyo");
  }}>Bosish!</button>
            </div>
            <Notes type={"Eslatma:"} text={"Har doim button elementi uchun type atributni belgilang. Turli xil brauzerlarda button elementi default type qiymati har xil bo'lishi mumkin."}/>
            <hr />
            <TextContent header={"<fieldset> va <legend> elementlari"}
                text1={"<fieldset> elementi formadagi bir-biriga bog'liq ma'lumotlarni guruhlash uchun ishlatiladi."}
                redWords1={["<fieldset>"]}
                text2={"<legend> elementi <fieldset> elementi  uchun sarlavhani belgilaydi."}
 redWords2={["<legend>", "<fieldset>"]}
 code2={`<form action="/action_page.php">
 <fieldset>
   <legend>Personalia:</legend>
   <label for="fname">Ism:</label><br>
   <input type="text" id="fname" name="fname" value="John"><br>
   <label for="lname">Familiya:</label><br>
   <input type="text" id="lname" name="lname" value="Doe"><br><br>
   <input type="submit" value="Yuborish">
 </fieldset>
</form>`}
path2={"try/363"}
 text3={"Yuqoridagi HTML kodi brauzerda shunday ko'rsatiladi:"}
/>
<form action="https://www.w3schools.com/action_page.php" target="_blank">
<fieldset>
<legend>Shaxsiy xususiyatlar:</legend>
Ism:<br/>
<input type="text" name="firstname" value="John"/><br/>
Familiya:<br/>
<input type="text" name="lastname" value="Doe"/><br/><br/>
<input type="submit" value="Yuborish"/>
</fieldset>
</form>
                <hr/>
            <TextContent header={"<datalist> elementi"}
                text1={"<datalist> element <input> elementi uchun oldindan belgilangan variantlar ro'yxatini belgilaydi."}
                redWords1={["datalist>", "input"]}
                text2={"Foydalanuvchilar inputga ma'lumotlarni kiritishdan oldindan belgilangan variantlarning ochiladigan ro'yxatini ko'radilar."}

 text3={"<input> elementning list atributi <datalist> elementning id atributi bir xil bo'lishi kerak ."}
 redWords3={["list", "<input>", "id", "<datalist>"]}
                code3={`<form action="/action_page.php">
  <input list="browsers">
  <datalist id="browsers">
    <option value="Internet Explorer">
    <option value="Firefox">
    <option value="Chrome">
    <option value="Opera">
    <option value="Safari">
  </datalist>
</form>`}
path3={"try/364"}/>
                <hr/>
            <TextContent header={"<output> elementi"}
                text1={"<output> elementi hisoblash natijasini ifodalaydi(masalan, javascript kodida bajarilgan)."}
                redWords1={["<output>"]}
                code1={`<form action="/action_page.php"
  oninput="x.value=parseInt(a.value)+parseInt(b.value)">
  0
  <input type="range"  id="a" name="a" value="50">
  100 +
  <input type="number" id="b" name="b" value="50">
  =
  <output name="x" for="a b"></output>
  <br><br>
  <input type="submit">
</form>`}
path1={"try/365"}/>
                <hr/>

            <Table th={["Teg", "Tavsif"]} values={[
                { id: 0, first: "<form>", second: "Foydalanuvchi ma'lumot kiritishi uchun HTML formasini yaratishda ishlatiladi", path: "/tags/tag_form" },
                { id: 1, first: "<input>", second: "Ma'lumot kiritish maydonini belgilaydi", path: "/tags/tag_input" },
                { id: 2, first: "<textarea>", second: "Ko'p qatorli ma'lumotlarni kiritish maydonini belgilaydi (matn maydoni).", path: "/tags/tag_textarea" },
                { id: 3, first: "<label>", second: "Forma elementlari uchun qisqacha ma'lumot beradi", path: "/tags/tag_label" },
                { id: 4, first: "<fieldset>", second: "Formadagi bir-biriga bog'liq elementlarni guruhlarga ajratadi", path: "/tags/tag_fieldset" },
                { id: 5, first: "<legend>", second: "<fieldset> elementi uchun sarlavhani belgilaydi.", path: "/tags/tag_legend" },
                { id: 6, first: "<select>", second: "Ochiladigan ro'yxatni belgilaydi", path: "/tags/tag_select" },
                { id: 7, first: "<optgroup>", second: "Ochiladigan ro'yxatda tegishli variantlar guruhini belgilaydi", path: "/tags/tag_optgroup" },
                { id: 8, first: "<option>", second: "Ochiladigan ro'yxatdagi variantni belgilaydi", path: "/tags/tag_option" },
                { id: 9, first: "<button>", second: "Bosiladigan tugmani belgilaydi", path: "/tags/tag_button" },
                { id: 10, first: "<datalist>", second: "inputga kiritish uchun oldindan belgilangan variantlar ro'yxatini belgilaydi", path: "/tags/tag_datalist" },
                { id: 11, first: "<output>", second: "Hisoblash natijasini belgilaydi", path: "/tags/tag_output" },]} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="7V5gEe9UcR4" />
            <NavButton back='/html/html_form_atribut' next='/html/html_input' />
        </div>)
}

export default FormElements;