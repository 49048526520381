const ColorLists = [
    { title: "AliceBlue", hex: "#F0F8FF", rgb: "240,248,255" },
    { title: "AntiqueWhite", hex: "#FAEBD7", rgb: "250,235,215" },
    { title: "Aqua", hex: "#00FFFF", rgb: "0,255,255" },
    { title: "Aquamarine", hex: "#7FFFD4", rgb: "127,255,212" },
    { title: "Azure", hex: "#F0FFFF", rgb: "240,255,255" },
    { title: "Beige", hex: "#F5F5DC", rgb: "245,245,220" },
    { title: "Bisque", hex: "#FFE4C4", rgb: "255,228,196" },
    { title: "Black", hex: "#000000", rgb: "0,0,0" },
    { title: "BlanchedAlmond", hex: "#FFEBCD", rgb: "255,235,205" },
    { title: "Blue", hex: "#0000FF", rgb: "0,0,255" },
    { title: "BlueViolet", hex: "#8A2BE2", rgb: "138,43,226" },
    { title: "Brown", hex: "#A52A2A", rgb: "165,42,42" },
    { title: "BurlyWood", hex: "#8A2BE2", rgb: "222,184,135" },
    { title: "CadetBlue", hex: "#5F9EA0", rgb: "95,158,160" },
    { title: "Chartreuse", hex: "#7FFF00", rgb: "127,255,0" },
    { title: "Chocolate", hex: "#D2691E", rgb: "210,105,30" },
    { title: "Coral", hex: "#FF7F50", rgb: "255,127,80" },
    { title: "CornflowerBlue", hex: "#6495ED", rgb: "100,149,237" },
    { title: "Cornsilk", hex: "#FFF8DC", rgb: "	255,248,220" },
    { title: "Crimson", hex: "#DC143C", rgb: "220,20,60" },
    { title: "Cyan", hex: "#00FFFF", rgb: "0,255,255" },
    { title: "DarkBlue", hex: "#00008B", rgb: "0,0,139" },
    { title: "DarkCyan", hex: "#008B8B", rgb: "0,139,139" },
    { title: "DarkGoldenRod", hex: "#B8860B", rgb: "184,134,11" },
    { title: "DarkGray", hex: "#A9A9A9", rgb: "169,169,169" },
    { title: "DarkGrey", hex: "#A9A9A9", rgb: "169,169,169" },
    { title: "DarkGreen", hex: "#006400", rgb: "0,100,0" },
    { title: "DarkKhaki", hex: "#BDB76B", rgb: "189,183,107" },
    { title: "DarkMagenta", hex: "#8B008B", rgb: "139,0,139" },
    { title: "DarkOliveGreen", hex: "#556B2F", rgb: "85,107,47" },
    { title: "DarkOrange", hex: "#FF8C00", rgb: "255,140,0" },
    { title: "DarkOrchid", hex: "#9932CC", rgb: "153,50,204" },
    { title: "DarkRed", hex: "#8B0000", rgb: "139,0,0" },
    { title: "DarkSalmon", hex: "#E9967A", rgb: "233,150,122" },
    { title: "DarkSeaGreen", hex: "#8FBC8F", rgb: "143,188,143" },
    { title: "DarkSlateBlue", hex: "#483D8B", rgb: "72,61,139" },
    { title: "DarkSlateGray", hex: "#2F4F4F", rgb: "47,79,79" },
    { title: "DarkSlateGrey", hex: "#2F4F4F", rgb: "47,79,79" },
    { title: "DarkTurquoise", hex: "#00CED1", rgb: "0,206,209" },
    { title: "DarkViolet", hex: "#9400D3", rgb: "148,0,211" },
    { title: "DeepPink", hex: "#FF1493", rgb: "255,20,147" },
    { title: "DeepSkyBlue", hex: "#00BFFF", rgb: "0,191,255" },
    { title: "DimGray", hex: "#696969", rgb: "105,105,105" },
    { title: "DimGrey", hex: "#696969", rgb: "105,105,105" },
    { title: "DodgerBlue", hex: "#1E90FF", rgb: "30,144,255" },
    { title: "FireBrick", hex: "#B22222", rgb: "178,34,34" },
    { title: "FloralWhite", hex: "#FFFAF0", rgb: "55,250,240" },
    { title: "ForestGreen", hex: "#228B22", rgb: "	34,139,34" },
    { title: "Fuchsia", hex: "#FF00FF", rgb: "255,0,255" },
    { title: "Gainsboro", hex: "#DCDCDC", rgb: "220,220,220" },
    { title: "GhostWhite", hex: "#F8F8FF", rgb: "248,248,255" },
    { title: "Gold", hex: "#FFD700", rgb: "255,215,0" },
    { title: "GoldenRod", hex: "#DAA520", rgb: "218,165,32" },
    { title: "Gray", hex: "#808080", rgb: "128,128,128" },
    { title: "Grey", hex: "#808080", rgb: "128,128,128" },
    { title: "Green", hex: "#008000", rgb: "0,128,0" },
    { title: "GreenYellow", hex: "#ADFF2F", rgb: "173,255,47" },
    { title: "HoneyDew", hex: "#F0FFF0", rgb: "240,255,240" },
    { title: "HotPink", hex: "#FF69B4", rgb: "255,105,180" },
    { title: "IndianRed", hex: "#CD5C5C", rgb: "205,92,92" },
    { title: "Indigo", hex: "#4B0082", rgb: "75,0,130" },
    { title: "Ivory", hex: "#FFFFF0", rgb: "	255,255,240" },
    { title: "Khaki", hex: "#F0E68C", rgb: "240,230,140" },
    { title: "Lavender", hex: "#E6E6FA", rgb: "	230,230,250" },
    { title: "LavenderBlush", hex: "#FFF0F5", rgb: "255,240,245" },
    { title: "LawnGreen", hex: "#7CFC00", rgb: "124,252,0" },
    { title: "LemonChiffon", hex: "#FFFACD", rgb: "55,250,205" },
    { title: "LightBlue", hex: "#ADD8E6", rgb: "173,216,230" },
    { title: "LightCoral", hex: "#F08080", rgb: "240,128,128" },
    { title: "LightCyan", hex: "#E0FFFF", rgb: "224,255,255" },
    { title: "LightGoldenRodYellow", hex: "#FAFAD2", rgb: "250,250,210" },
    { title: "LightGray", hex: "#D3D3D3", rgb: "211,211,211" },
    { title: "LightGrey", hex: "#D3D3D3", rgb: "211,211,211" },
    { title: "LightGreen", hex: "#90EE90", rgb: "144,238,144" },
    { title: "LightPink", hex: "#FFB6C1", rgb: "255,182,193" },
    { title: "LightSalmon", hex: "#FFA07A", rgb: "255,160,122" },
    { title: "LightSeaGreen", hex: "#20B2AA", rgb: "32,178,170" },
    { title: "LightSkyBlue", hex: "#87CEFA", rgb: "135,206,250" },
    { title: "LightSlateGray", hex: "#778899", rgb: "119,136,153" },
    { title: "LightSlateGrey", hex: "#778899", rgb: "119,136,153" },
    { title: "LightSteelBlue", hex: "#B0C4DE", rgb: "176,196,222" },
    { title: "LightYellow", hex: "#FFFFE0", rgb: "255,255,224" },
    { title: "Lime", hex: "#00FF00", rgb: "0,255,0" },
    { title: "LimeGreen", hex: "#32CD32", rgb: "50,205,50" },
    { title: "Linen", hex: "#FAF0E6", rgb: "250,240,230" },
    { title: "Magenta", hex: "#FF00FF", rgb: "255,0,255" },
    { title: "Maroon", hex: "#800000", rgb: "128,0,0" },
    { title: "MediumAquaMarine", hex: "#66CDAA", rgb: "102,205,170" },
    { title: "MediumBlue", hex: "#0000CD", rgb: "0,0,205" },
    { title: "MediumOrchid", hex: "#BA55D3", rgb: "186,85,211" },
    { title: "MediumPurple", hex: "#9370DB", rgb: "147,112,219" },
    { title: "MediumSeaGreen", hex: "#3CB371", rgb: "60,179,113" },
    { title: "MediumSlateBlue", hex: "#7B68EE", rgb: "123,104,238" },
    { title: "MediumSpringGreen", hex: "#00FA9A", rgb: "0,250,154" },
    { title: "MediumTurquoise", hex: "#48D1CC", rgb: "72,209,204" },
    { title: "MediumVioletRed", hex: "#C71585", rgb: "199,21,133" },
    { title: "MidnightBlue", hex: "#191970", rgb: "25,25,112" },
    { title: "MintCream", hex: "#F5FFFA", rgb: "245,255,250" },
    { title: "MistyRose", hex: "#FFE4E1", rgb: "255,228,225" },
    { title: "Moccasin", hex: "#FFE4B5", rgb: "255,228,181" },
    { title: "NavajoWhite", hex: "#FFDEAD", rgb: "255,222,173" },
    { title: "Navy", hex: "#000080", rgb: "0,0,128" },
    { title: "OldLace", hex: "#FDF5E6", rgb: "253,245,230" },
    { title: "Olive", hex: "#808000", rgb: "128,128,0" },
    { title: "OliveDrab", hex: "#6B8E23", rgb: "107,142,35" },
    { title: "Orange", hex: "#FFA500", rgb: "255,165,0" },
    { title: "OrangeRed", hex: "#FF4500", rgb: "255,69,0" },
    { title: "Orchid", hex: "#DA70D6", rgb: "218,112,214" },
    { title: "PaleGoldenRod", hex: "#EEE8AA", rgb: "238,232,170" },
    { title: "PaleGreen", hex: "#98FB98", rgb: "152,251,152" },
    { title: "PaleTurquoise", hex: "#AFEEEE", rgb: "175,238,238" },
    { title: "PaleVioletRed", hex: "#DB7093", rgb: "219,112,147" },
    { title: "PapayaWhip", hex: "#FFEFD5", rgb: "255,239,213" },
    { title: "PeachPuff", hex: "#FFDAB9", rgb: "255,218,185" },
    { title: "Peru", hex: "#CD853F", rgb: "205,133,63" },
    { title: "Pink", hex: "#FFC0CB", rgb: "255,192,203" },
    { title: "Plum", hex: "#DDA0DD", rgb: "221,160,221" },
    { title: "PowderBlue", hex: "#B0E0E6", rgb: "176,224,230" },
    { title: "Purple", hex: "#800080", rgb: "128,0,128" },
    { title: "RebeccaPurple", hex: "#663399", rgb: "102,51,153" },
    { title: "Red", hex: "#FF0000", rgb: "255,0,0" },
    { title: "RosyBrown", hex: "#BC8F8F", rgb: "188,143,143" },
    { title: "RoyalBlue", hex: "#4169E1", rgb: "65,105,225" },
    { title: "SaddleBrown", hex: "#8B4513", rgb: "139,69,19" },
    { title: "Salmon", hex: "#FA8072", rgb: "250,128,114" },
    { title: "SandyBrown", hex: "#F4A460", rgb: "244,164,96" },
    { title: "SeaGreen", hex: "#2E8B57", rgb: "46,139,87" },
    { title: "SeaShell", hex: "#FFF5EE", rgb: "255,245,238" },
    { title: "Sienna", hex: "#A0522D", rgb: "160,82,45" },
    { title: "Silver", hex: "#C0C0C0", rgb: "192,192,192" },
    { title: "SkyBlue", hex: "#87CEEB", rgb: "135,206,235" },
    { title: "SlateBlue", hex: "#6A5ACD", rgb: "106,90,205" },
    { title: "SlateGray", hex: "#708090", rgb: "112,128,144" },
    { title: "SlateGrey", hex: "#708090", rgb: "112,128,144" },
    { title: "Snow", hex: "#FFFAFA", rgb: "255,250,250" },
    { title: "SpringGreen", hex: "#00FF7F", rgb: "0,255,127" },
    { title: "SteelBlue", hex: "#4682B4", rgb: "70,130,180" },
    { title: "Tan", hex: "#D2B48C", rgb: "210,180,140" },
    { title: "Teal", hex: "#008080", rgb: "0,128,128" },
    { title: "Thistle", hex: "#D8BFD8", rgb: "216,191,216" },
    { title: "Tomato", hex: "#FF6347", rgb: "255,99,71" },
    { title: "Turquoise", hex: "#40E0D0", rgb: "64,224,208" },
    { title: "Violet", hex: "#EE82EE", rgb: "238,130,238" },
    { title: "Wheat", hex: "#F5DEB3", rgb: "245,222,179" },
    { title: "White", hex: "#FFFFFF", rgb: "255,255,255" },
    { title: "WhiteSmoke", hex: "#F5F5F5", rgb: "245,245,245" },
    { title: "Yellow", hex: "#FFFF00", rgb: "255,255,0" },
    { title: "YellowGreen", hex: "#9ACD32", rgb: "154,205,50" },
]

export default ColorLists;