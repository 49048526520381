import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../../pages/ScrollToTop";
import SideBar from "../SideBar";

const tagLists = [
  {
    title: "HTML MA'LUMOTNOMASI",
    id: 0,
    subTutorial: [
      { id: 1, title: "HTML alifbo tartibida", path: "tags" },
      { id: 2, title: "HTML vazifasiga ko'ra", path: "ref_byfunc" },
      { id: 3, title: "HTMLni brauzer qo'llab-quvvatlashi", path: "ref_html_browsersupport" },
      { id: 4, title: "HTML atributlari", path: "ref_attributes" },
      { id: 5, title: "HTML global atributlari", path: "ref_standardattributes" },
      { id: 6, title: "HTML events", path: "ref_eventattributes" },
      { id: 7, title: "HTML ranglari", path: "ref_colornames" },
      { id: 8, title: "HTML Canvas", path: "ref_canvas" },
      { id: 9, title: "HTML audio va video", path: "ref_av_dom" },
      { id: 10, title: "HTML belgilar to'plami", path: "ref_charactersets" },
      { id: 11, title: "HTML Doctypes", path: "ref_html_dtd" },
      { id: 12, title: "HTMLda URL Encode", path: "ref_urlencode" },
      { id: 13, title: "HTMLda Tilni belgilash", path: "ref_language_codes" },
      { id: 14, title: "HTMLda davlatni belgilash", path: "ref_country_codes" },
      { id: 15, title: "HTTP xabarlari", path: "ref_httpmessages" },
      { id: 16, title: "HTTP metodlari", path: "ref_httpmethods" },
      { id: 17, title: "Pikselni Emga aylantirish", path: "ref_pxtoemconversion" },
      { id: 18, title: "Klaviyatura yorliqlari", path: "ref_keyboardshortcuts" },
    ]
  },
  {
    title: "HTML TEGLARI",
    id: 19,
    subTutorial: [
      { id: 20, title: "<!--...-->", path: "tag_comment" },
      { id: 21, title: "<a>", path: "tag_a" },
      { id: 22, title: "<abbr>", path: "tag_abbr" },
      { id: 23, title: "<acronym>", path: "tag_acronym" },
      { id: 24, title: "<address>", path: "tag_address" },
      { id: 25, title: "<applet>", path: "tag_applet" },
      { id: 26, title: "<area>", path: "tag_area" },
      { id: 27, title: "<article>", path: "tag_article" },
      { id: 28, title: "<aside>", path: "tag_aside" },
      { id: 29, title: "<audio>", path: "tag_audio" },
      { id: 30, title: "<b>", path: "tag_b" },
      { id: 31, title: "<base>", path: "tag_base" },
      { id: 32, title: "basefont>", path: "tag_basefont" },
      { id: 33, title: "<bdi>", path: "tag_bdi" },
      { id: 34, title: "<bdo>", path: "tag_bdo" },
      { id: 35, title: "<big>", path: "tag_big" },
      { id: 36, title: "<blockquote>", path: "tag_blockquote" },
      { id: 37, title: "<body>", path: "tag_body" },
      { id: 38, title: "<br>", path: "tag_br" },
      { id: 39, title: "<button>", path: "tag_button" },
      { id: 40, title: "<canvas>", path: "tag_canvas" },
      { id: 41, title: "<caption>", path: "tag_caption" },
      { id: 42, title: "<center>", path: "tag_center" },
      { id: 43, title: "<cite>", path: "tag_cite" },
      { id: 44, title: "<code>", path: "tag_code" },
      { id: 45, title: "<col>", path: "tag_col" },
      { id: 46, title: "<colgroup>", path: "tag_colgroup" },
      { id: 47, title: "<data>", path: "tag_data" },
      { id: 48, title: "<datalist>", path: "tag_datalist" },
      { id: 49, title: "<dd>", path: "tag_dd" },
      { id: 50, title: "<del>", path: "tag_del" },
      { id: 51, title: "<details>", path: "tag_details" },
      { id: 52, title: "<dfn>", path: "tag_dfn" },
      { id: 53, title: "<dialog>", path: "tag_dialog" },
      { id: 54, title: "<dir>", path: "tag_dir" },
      { id: 55, title: "<div>", path: "tag_div" },
      { id: 56, title: "<dl>", path: "tag_dl" },
      { id: 57, title: "<dt>", path: "tag_dt" },
      { id: 58, title: "<em>", path: "tag_em" },
      { id: 59, title: "<embed>", path: "tag_embed" },
      { id: 60, title: "<fieldset>", path: "tag_fieldset" },
      { id: 61, title: "<figcaption>", path: "tag_figcaption" },
      { id: 62, title: "<figure>", path: "tag_figure" },
      { id: 63, title: "<font>", path: "tag_font" },
      { id: 64, title: "<footer>", path: "tag_footer" },
      { id: 65, title: "<form>", path: "tag_form" },
      { id: 66, title: "<frame>", path: "tag_frame" },
      { id: 67, title: "<frameset>", path: "tag_frameset" },
      { id: 68, title: "<h1> ... <h6>", path: "tag_hn" },
      { id: 69, title: "<head>", path: "tag_head" },
      { id: 70, title: "<header>", path: "tag_header" },
      { id: 71, title: "<hr>", path: "tag_hr" },
      { id: 72, title: "<html>", path: "tag_html" },
      { id: 73, title: "<i>", path: "tag_i" },
      { id: 74, title: "<iframe>", path: "tag_iframe" },
      { id: 75, title: "<img>", path: "tag_img" },
      { id: 76, title: "<input>", path: "tag_input" },
      { id: 77, title: "<ins>", path: "tag_ins" },
      { id: 78, title: "<kbd>", path: "tag_kbd" },
      { id: 79, title: "<label>", path: "tag_label" },
      { id: 80, title: "<legend>", path: "tag_legend" },
      { id: 81, title: "<li>", path: "tag_li" },
      { id: 82, title: "<link>", path: "tag_link" },
      { id: 83, title: "<main>", path: "tag_main" },
      { id: 84, title: "<map>", path: "tag_map" },
      { id: 85, title: "<mark>", path: "tag_mark" },
      { id: 86, title: "<meta>", path: "tag_meta" },
      { id: 87, title: "<meter>", path: "tag_meter" },
      { id: 88, title: "<nav>", path: "tag_nav" },
      { id: 89, title: "<noframes>", path: "tag_noframes" },
      { id: 90, title: "<noscript>", path: "tag_noscript" },
      { id: 91, title: "<object>", path: "tag_object" },
      { id: 92, title: "<ol>", path: "tag_ol" },
      { id: 93, title: "<optgroup>", path: "tag_optgroup" },
      { id: 94, title: "<option>", path: "tag_option" },
      { id: 95, title: "<output>", path: "tag_output" },
      { id: 96, title: "<p>", path: "tag_p" },
      { id: 97, title: "<param>", path: "tag_param" },
      { id: 98, title: "<picture>", path: "tag_picture" },
      { id: 99, title: "<pre>", path: "tag_pre" },
      { id: 100, title: "<progress>", path: "tag_progress" },
      { id: 101, title: "<q>", path: "tag_q" },
      { id: 102, title: "<rp>", path: "tag_rp" },
      { id: 103, title: "<rt>", path: "tag_rt" },
      { id: 104, title: "<ruby>", path: "tag_ruby" },
      { id: 105, title: "<s>", path: "tag_s" },
      { id: 106, title: "<samp>", path: "<samp>" },
      { id: 107, title: "<script>", path: "tag_script" },
      { id: 108, title: "<section>", path: "tag_section" },
      { id: 109, title: "<select>", path: "tag_select" },
      { id: 110, title: "<small>", path: "tag_small" },
      { id: 111, title: "<source>", path: "tag_source" },
      { id: 112, title: "<span>", path: "tag_span" },
      { id: 113, title: "<strike>", path: "tag_strike" },
      { id: 114, title: "<strong>", path: "tag_strong" },
      { id: 115, title: "<style>", path: "tag_style" },
      { id: 116, title: "<sub>", path: "tag_sub" },
      { id: 117, title: "<summary>", path: "tag_summary" },
      { id: 118, title: "<sup>", path: "tag_sup" },
      { id: 119, title: "<svg>", path: "tag_svg" },
      { id: 120, title: "<table>", path: "tag_table" },
      { id: 121, title: "<tbody>", path: "tag_tbody" },
      { id: 122, title: "<td>", path: "tag_td" },
      { id: 123, title: "<template>", path: "tag_template" },
      { id: 124, title: "<textarea>", path: "tag_textarea" },
      { id: 125, title: "<tfoot>", path: "tag_tfoot" },
      { id: 126, title: "<th>", path: "tag_th" },
      { id: 127, title: "<thead>", path: "tag_thead" },
      { id: 128, title: "<time>", path: "tag_time" },
      { id: 129, title: "<title>", path: "tag_title" },
      { id: 130, title: "<tr>", path: "tag_tr" },
      { id: 131, title: "<track>", path: "tag_track" },
      { id: 132, title: "<tt>", path: "tag_tt" },
      { id: 133, title: "<u>", path: "tag_u" },
      { id: 134, title: "<ul>", path: "tag_ul" },
      { id: 135, title: "<var>", path: "tag_var" },
      { id: 136, title: "<video>", path: "tag_video" },
      { id: 137, title: "<wbr>", path: "tag_wbr" },
    ]
  }
]

function Tags() {
  return (
    <div className="tutorial container">
      <SideBar datas={tagLists} />
      <ScrollToTop />
      <Outlet />
    </div>
  )
}


export default Tags;