import React from 'react';
import { Link } from 'react-router-dom';
import NavButton from '../NavButton';
import '../Info.css';
import CodeContent from '../../../tryit/CodeContent';
import CodeMirrorContentCss from '../../../tryit/CodeContent/CodeMirrorContentCss';
import W3Path from '../../W3Path';
import BrowserTable from '../../pages/BrowserTable';


function TagHtm() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_html.asp"} />
      <h2 className='header-tutorial'>&lt;html&gt; tegi</h2>
      <NavButton back='/tags/tag_hr' next='/tags/tag_i' />
      <hr />
      <CodeContent props={` <!DOCTYPE html>
    <html>
    <head>
    <title>Sahifa sarlavhasi</title>
    </head>
    <body>
    <h1>Bu sarlavha</h1>
    <p>Bu matn.</p>

    </body>
    </html>`} pathname={`try/1`} text="Oddiy HTML hujjati" />
      <h3 className='header-content'>Tavsifi va qo'llanilishi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;html&gt;</span> tegi Html hujjatining asosi hisoblanadi.</p>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;html&gt;</span> tegi boshqa HTML elementlari uchun konteyner hisoblanadi.(&lt;!DOCTYPE&gt; dan tashqari)</p>
      <p className='text-tutorial'><b>Eslatma:</b> <span className='text-tutorial__red'>&lt;html&gt;</span> tegi ichida har doim  lang atributi yordamida  veb-sahifa tili e'lon qilinishi  kerak. Bu qidiruv tizimlari va brauzerlarga yordam berish uchun mo'ljallangan.</p>
      <hr />
      <BrowserTable element='<html>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />
      <h3 className='header-content'>Atributlari</h3>
      <table className='table-content'>
        <tbody>
          <tr>
            <th scope="col">Atribut</th>
            <th scope="col">Qiymati</th>
            <th scope="col">Tavsif</th>
          </tr>

          <tr>
            <td><Link className='table-link' to={"/tags/att_html_xmlns"}>&lt;xmlns&gt;</Link></td>
            <td>	http://www.w3.org/1999/xhtml</td>
            <td>	XML maydoni atributi (agar kontentingiz XHTMLga mos kelishi kerak bo'lsa)</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <h3 className='header-content'>Global atributlar</h3>
      <p className='text-tutorial'>&lt;html&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <h3 className='header-content'>Tegishli sahifalar</h3>
      <p className='text-tutorial'>HTML mavzulari: <Link className='table-link' to={"/tags/ref_standardattributes"}>HTML bilan tanishish</Link></p>
      <p className='text-tutorial'>HTML DOM ma'lumotnomasi: <Link className='table-link' to={"/jsref/dom_obj_html"}>HTML Obyekti</Link></p>
      <hr />
      <h3 className='header-content'>Standart CSS sozlamalari</h3>
      <p className='text-tutorial'>Ko'p brauzerlarda quyidagi standart stillar berilgan bo'ladi:</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContentCss value={`html {
  display: block;
}

html:focus {
  outline: none;
}`} />
        </div>
      </div>
      <hr />
      <NavButton back='/tags/tag_hr' next='/tags/tag_i' />
    </article>
  )
}

export default TagHtm;
