import React from 'react';
import ListText from '../../../pages/ListsText';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function UnorderedLists() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_lists.asp"} />
      <h2 className='header-tutorial'>HTML tartiblanmagan ro'yxatlari</h2>
      <NavButton back='/html/html_lists' next='/html/html_lists_ordered' />
      <hr />
      <TextContent text1={"HTML <ul> tegi tartiblanmagan ro'yxatni belgilaydi."} />
      <hr />
      <TextContent header={"Tartiblanmagan HTML ro'yxati"}
        text1={"Tartiblanmagan ro'yxat <ul> tegi bilan boshlanadi. Har bir ro'yxat elementi <li> tegi bilan boshlanadi."}
        redWords1={["<ul>", "<li>"]}
        text2={"Ro'yxat elementlari default holatda kichik qora doirali marker bilan ajratib ko'rsatiladi:"}
        code2={`<ul>
  <li>Coffee</li>
  <li>Tea</li>
  <li>Milk</li>
</ul>`} path2={"try/204"}
      />
      <hr />
      <TextContent header={"Tartiblanmagan ro'yxat elementi belgisini tanlash"}
        text1={"CSS list-style-type xususiyati ro'yxat elementi markeriga stil berish uchun ishlatiladi. U quyidagi qiymatlardan biriga ega bo'lishi mumkin:"}
        redWords1={["list-style-type"]} />
      <Table th={["Qiymat", "Tavsifi"]} values={[
        { first: "disc", second: "Qora doiracha (default holat)" },
        { first: "circle", second: "Aylana" },
        { first: "square", second: "Kvadrat" },
        { first: "none", second: "Markersiz" },]} />
      <TextContent
        code1={`
<ul style="list-style-type:disc;">
    <li>Kofe</li>
    <li>Choy</li>
    <li>Sut</li>
</ul>  `} path1={"try/207"} code1Text={"list-style-type:disc;"}
        code2={`<ul style="list-style-type:circle;">
    <li>Kofe</li>
    <li>Choy</li>
    <li>Sut</li>
</ul>`} path2={"try/208"} code2Text={"list-style-type:circle;"}
        code3={`  <ul style="list-style-type:square;">
     <li>Kofe</li>
     <li>Choy</li>
     <li>Sut</li>
 </ul>`} path3={"try/209"} code3Text={"list-style-type:square;"}
        code4={`<ul style="list-style-type:none;">
   <li>Kofe</li>
   <li>Choy</li>
   <li>Sut</li>
</ul>  `} path4={"try/210"} code4Text={"list-style-type:none;"}
      />
      <hr />
      <TextContent header={"Ichki HTML ro'yxatlari"}
        text1={"Ro'yxatlarni ichma-ich joylashtirish mumkin (ro'yxat ichidagi ro'yxat):"}
        code1={`  <ul>
   <li>Kofe</li>
   <li>Choy
       <ul>
         <li>Qora choy</li>
         <li>Ko'k choy</li>
       </ul>
   </li>
   <li>Sut</li>
</ul>`} path1={"try/211"}
      />
      <Notes type={"Eslatma"} text={"Ro'yxat elementi (<li>) yangi ro'yxatni hamda rasm va havolalar kabi boshqa HTML elementlarini o'z ichiga olishi mumkin."} />
      <hr />
      <TextContent header={"CSS bilan gorizontal ro'yxat"}
        text1={"HTML ro'yxatlariga CSS yordamida turli xil stillar berish mumkin."}
        text2={"Gorizontal ro'yxatni shakllantirish ya'ni navigatsiya menyusini yaratish mashhur stil berish usullaridan biridir:"}
        code2={`<!DOCTYPE html>
<html>
<head>
<style>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333333;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #111111;
}
</style>
</head>
<body>

<ul>
<li><a href="#home">Bosh sahifa</a></li>
<li><a href="#news">Yangiliklar</a></li>
<li><a href="#contact">Kontakt</a></li>
<li><a href="#about">Biz haqimizda</a></li>
</ul>

</body>
</html>`} path2={"try/212"}
      />
      <Notes type={"Maslahat"} text={"CSS haqida ko'proq ma'lumotni"} text1={"CSS qo'llanmamizdan "} text2={"olishingiz mumkin."} path={"/css"} />
      <hr />
      <TextContent header={"Bo'lim xulosasi"} />
      <ListText list={[{
        text: "<ul> - tartiblanmagan ro'yxat uchun ishlatiladi.",
        redWords: ["<ul>"]
      },
      {
        text: "CSS list-style-type xususiyati ro'yxat elementi markeriga stil berish uchun ishlatiladi.",
        redWords: ["list-style-type"]
      },
      {
        text: "<li> tegi ro'yxat elementini belgilaydi.",
        redWords: ["<li>"]
      },
      {
        text: "Ro'yxatlarni ichma-ich joylashtirish mumkin",
      },
      {
        text: "Ro'yxat elementlari boshqa HTML elementlarini o'z ichiga olishi mumkin",
      },
      {
        text: "Ro'yxat elementlarini gorizontal joylashtirish uchun CSS float:left  xususiyatidan foydalaniladi.",
        redWords: ["float:left"]
      },
      ]} />
      <Table header={"Html teglari"} th={["Teg", "tavsifi"]} values={[
        { first: "<ul>", second: "Tartiblanmagan ro'yxatni belgilaydi", path: '/tags/tag_ul' },
        { first: "<ol>", second: "Tartiblangan ro'yxat belgilaydi", path: '/tags/tag_ol' },
        { first: "<li>", second: "Ro'yxat elementini bildiradi", path: '/tags/tag_li' },
        { first: "<dl>", second: "Tavsif ro'yxatini belgilaydi", path: '/tags/tag_dl' },
        { first: "<dt>", second: "Tavsif ro'yxatidagi atamani belgilaydi", path: '/tags/tag_dt' },
        { first: "<dd>", second: "Tavsif ro'yxatidagi atamani tavsiflaydi", path: '/tags/tag_dd' },]} />
      <Notes text={"Barcha mavjud HTML teglarining to'liq ro'yxatini ko'rish uchun bizning"} path="/tags" text1={" HTML teg ma'lumotnomamizga"} text2={"tashrif buyuring."} />
      <hr />
      <TextContent header={"Dars videosi:"} />
      <YoutubeEmbed embedId="hOJSGqkqoj8" />
      <NavButton back='/html/html_lists' next='/html/html_lists_ordered' />
    </article>)
}
export default UnorderedLists;
