import React from 'react';
import { Link } from 'react-router-dom';
import CodeContent from '../../../../tryit/CodeContent';
import Tegs from '../../../pages/tegs';
import NavButton from '../../NavButton';
import '../../Info.css';
import CodeMirrorContent from '../../../../tryit/CodeContent/CodeMirrorContent';
import Collapsible from '../../../pages/Collapsible';
import YoutubeEmbed from '../../YoutubeEmbed';
import W3Path from '../../../W3Path';


function HtmlAttributes() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_attributes.asp"} />
      <h2 className='header-tutorial'>HTML atributlari</h2>
      <NavButton back='/html/html_elements' next='/html/html_headings' />
      <hr />
      <p className='text-tutorial text-tutorial-margin'>HTML atributlari HTML elementlari haqida qo'shimcha ma'lumot beradi.</p>
      <hr />
      <h3 className='header-content'>HTML atributlari</h3>
      <ul className='lists'>
        <li className='item'>
          <p className='text-tutorial'>Barcha HTML elementlari <strong>atributlarga ega bo'lishi mumkin </strong></p>
        </li>
        <li className='item'>
          <p className='text-tutorial'>Atributlar elementlar haqida <strong>qo'shimcha ma'lumot beradi</strong></p>
        </li>
        <li className='item'>
          <p className='text-tutorial'>Atributlar har doim <strong>ochilgan tegda ko'rsatiladi.</strong></p>
        </li>
        <li className='item'>
          <p className='text-tutorial'>Atributlar odatda nomi/qiymati juftliklarida bo'ladi: <strong>nomi="qiymati"</strong> </p>
        </li>
      </ul>
      <h3 className='header-content'>href atributi</h3>
      <p className='text-tutorial'><Tegs tegname="a " />tegi giperhavolani ifodalaydi. <span className='text-tutorial__red'>href</span> atributi yordamida havola boradigan sahifaning URL manzili ko'rsatiladi: </p>
      <CodeContent props={`<a href="https://self-study.uz">Self-Study.uz saytiga o'tish</a>`} pathname={`try/10`} />
      <p className='text-tutorial'>
        <Link className='text-tutorial text-tutorial__link' to={'/html/html_links'}>Havolalar haqida ko'proq bizning HTML havolalari bo'limida  </Link>bilib olasiz
      </p>
      <h3 className='header-content'>src atributi</h3>
      <p className='text-tutorial'><Tegs tegname="img " />tegi tasvir(rasm)ni HTML sahifasiga joylashtirish uchun ishlatiladi. <span className='text-tutorial__red'>src</span> atributi orqali rasm manzili ko'rsatiladi: </p>
      <CodeContent props={`<img src="img_girl.jpg">`} pathname={`try/11`} />
      <p className='text-tutorial'> <span className='text-tutorial__red'>src</span> atributda URL manzilini ko'rsatishning ikki xil yo'li mavjud : </p>
      <p className='text-tutorial'><strong>1. Absolyut URL</strong> - boshqa veb-saytda joylashtirilgan tashqi rasmga havola. Misol: src="https://www.self-study.com/images/img_girl.jpg" .</p>
      <p className='text-tutorial'><strong>Eslatma:</strong> Tashqi tasvirlar mualliflik huquqi ostida bo'lishi mumkin. Agar siz undan foydalanishga ruxsat olmagan bo'lsangiz, mualliflik huquqi qonunlarini buzgan bo'lishingiz mumkin. Bundan tashqari, siz tashqi tasvirlarni nazorat qila olmaysiz; u to'satdan olib tashlanishi yoki o'zgartirilishi mumkin.</p>
      <p className='text-tutorial'><strong>2. Relyativ(nisbiy) URL </strong> - veb-sayt ichida joylashtirilgan rasmga havola. Bu yerda URL domen nomisiz yoziladi. Agar URL qiyshiq chiziqsiz(sleshsiz) boshlansa, u joriy sahifaga nisbatan bo'ladi. Misol: src="img_girl.jpg". Agar URL qiyshiq chiziq(slesh) bilan boshlansa, u domenga nisbatan bo'ladi. Misol: src="/images/img_girl.jpg".</p>
      <p className='text-tutorial'><strong>Maslahat:</strong> Deyarli har doim relativ(nisbiy) URL manzillaridan foydalanish yaxshidir. Domenni o'zgartirsangiz, ular buzilmaydi.</p>
      <hr />
      <h3 className='header-content'>Kenglik va balandlik atributlari</h3>
      <p className='text-tutorial'><Tegs tegname="img " /> tegi shuningdek , tasvirning kengligi va balandligini (piksellarda) ko'rsatadigan  <span className='text-tutorial__red'>width</span> va <span className='text-tutorial__red'>height</span> atributlarni o'z ichiga oladi:</p>
      <CodeContent props={`<img src="img_girl.jpg" width="500" height="600">`} pathname={`try/11`} />
      <h3 className='header-content'>Alt atributi</h3>
      <p className='text-tutorial'> Agar rasm biron sababga ko'ra ko'rinmasa, <Tegs tegname="img " /> tegidagi <span className='text-tutorial__red'>alt</span> atributi ya'ni tasvir uchun muqobil matn aynan shu joyda tasvir borligidan xabar beradi. Buning sabablari ulanishning sekinligi, <span className='text-tutorial__red'>src</span> atributdagi xatolik yoki foydalanuvchi ekranni o'qish vositasidan foydalanganligi bo'lishi mumkin.</p>
      <CodeContent props={`<img src="img_girl.jpg" alt="Girl with a jacket">`} pathname={`try/12`} />
      <CodeContent props={`<img src="img_typo.jpg" alt="Girl with a jacket">`} pathname={`try/13`} />
      <p className='text-tutorial'>
        <Link className='text-tutorial text-tutorial__link' to={'html_images'}>Tasvirlar haqida ko'proq bizning HTML tasvirlari bo'limida  </Link>bilib olasiz.
      </p>
      <hr />
      <h3 className='header-content'>style atributi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>style</span>  atributi elementga rang, shrift, oʻlcham va boshqa shu kabi stillar berish uchun ishlatiladi.</p>
      <CodeContent props={`<p style="color:red;">Bu qizil rangli matn.</p>`} pathname={`try/14`} />
      <hr />
      <h3 className='header-content'>Lang atributi</h3>
      <p className='text-tutorial'> Veb-sahifa tilini e'lon qilish uchun har doim <span className='text-tutorial__red'>lang</span>  atributini <Tegs tegname="html" />  tegi ichiga kiritishingiz kerak. Bu qidiruv tizimlari va brauzerlarga yordam berish uchun mo'ljallangan.</p>
      <p className='text-tutorial'> Quyidagi misol ingliz tilini til sifatida belgilaydi:</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`
<!DOCTYPE html>
<html lang="en">
<body>
...
</body>
</html>`} />
        </div>
      </div>

      <p className='text-tutorial'> <span className='text-tutorial__red'>lang</span> atributidagi til kodiga mamlakat kodlari ham qo'shilishi mumkin. Ya'ni, birinchi ikkita belgi HTML sahifasining tilini, oxirgi ikkita belgi esa mamlakatni anglatadi.</p>
      <p className='text-tutorial'> Quyidagi misolda en(ingliz tili) til sifatida, US (Amerika Qo'shma Shtatlari) esa mamlakat sifatida ko'rsatilgan:</p>

      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`
<!DOCTYPE html>
<html lang="en-US">
<body>
...
</body>
</html>`} />
        </div>
      </div>
      <p className='text-tutorial'>
        Barcha til kodlarini  <Link className='text-tutorial text-tutorial__link' to={'ref_language_codes'}>HTML til kodi maʼlumotnomamizda </Link> koʻrishingiz mumkin .
      </p>
      <hr />
      <h3 className='header-content'>title atributi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>title</span> atributi element haqida ba'zi qo'shimcha ma'lumotlarni belgilaydi.</p>
      <p className='text-tutorial'> Element ustiga sichqonchani bosganingizda title atributining qiymati maslahat sifatida ko'rsatiladi:</p>
      <CodeContent props={`<h2 title="Men sarlavhaman">title atributi</h2>`} pathname={`try/15`} />
      <hr />
      <h3 className='header-content'>Har doim atributlarni kichik harflarda yozish tavsiya etiladi</h3>
      <p className='text-tutorial'>HTML standarti atribut nomlarini kichik harfda yozishni talab qilmaydi.</p>
      <p className='text-tutorial'>title atributi (va barcha boshqa atributlar) title yoki TITLE kabi kichik yoki katta harflar bilan yozilishi mumkin .</p>
      <p className='text-tutorial'> HTMLda atributlarni kichik harfda yozish tavsiya qilinadi va XHTML kabi qatiy hujjat turlari uchun atributlarini kichik harfda yozish talab qilinadi </p>
      <hr />

      <h3 className='header-content'>Har doim atribut qiymatlarini qo'shtirnoq ichida bering
      </h3>
      <p className='text-tutorial'>HTML standarti atribut qiymatlari atrofida qo'shtirnoqlarni talab qilmaydi.</p>
      <p className='text-tutorial'>Biroq, qo'shtirnoqlarni tavsiya qilinadi va XHTML kabi qatiy hujjat turlari uchun qo'shtirnoq talab qilinadi .</p>
      <div className='code-content'>
        <h4 className='header-code'>Yaxshi</h4>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`
<a href="https://self-study.uz/html/">Bizning Html darslarimizga tashrif buyuring</a>`} />
        </div>
      </div>
      <div className='code-content'>
        <h4 className='header-code'>Yomon</h4>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`
<a href=https://self-study.uz/html/>Bizning Html darslarimizga tashrif buyuring</a>`} />
        </div>
      </div>
      <p className='text-tutorial'>Ba'zan siz qo'shtirnoqlardan foydalanishingiz kerak. Ushbu misol title atributini to'g'ri ko'rsatmaydi, chunki unda bo'sh joy mavjud:</p>
      <CodeContent props={`<p title=Self-study sayti haqida>`} pathname={`try/16`} />
      <hr />

      <h3 className='header-content'>Bittali yoki ikkitali qo'shtirnoq?
      </h3>
      <p className='text-tutorial'>Atribut qiymatlari atrofidagi ikkitali qoʻshtirnoqlar HTMLda eng koʻp uchraydi, lekin bittali qoʻshtirnoq ham ishlatilishi mumkin.</p>
      <p className='text-tutorial'>Ba'zi hollarda, atribut qiymatining o'zi qo'sh tirnoqlarni o'z ichiga olgan bo'lsa, bitta tirnoqdan foydalanish kerak:</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`<p title='John "ShotGun" Nelson'>`} />
        </div>
      </div>
      <p className='text-tutorial'>Yok aksincha:</p>
      <CodeContent props={`<p title="John 'ShotGun' Nelson">`} pathname={`try/17`} />
      <hr />
      <h3 className='header-content'>Bo'lim xulosasi
      </h3>
      <ul className='lists'>
        <li className='item'>
          <p className='text-tutorial'>Barcha HTML elementlari  <strong>atributlarga ega bo'lishi mumkin</strong></p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;a&gt;</span> tegi <span className='text-tutorial__red'>href</span>  atributi  havola boradigan sahifaning URL manzilini ko'rsatadi</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;img&gt;</span> tegi <span className='text-tutorial__red'>src</span> atributi ko'rsatilishi kerak bo'lgan rasm manzilni bildiradi</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'> <span className='text-tutorial__red'>&lt;img&gt;</span> tegi <span className='text-tutorial__red'>width</span> va <span className='text-tutorial__red'>height</span> atributlari rasm o'lchamlarini bildiradi. </p>
        </li>
        <li className='item'>
          <p className='text-tutorial'> <span className='text-tutorial__red'>&lt;img&gt;</span> tegi <span className='text-tutorial__red'>alt</span> atributi rasm uchun muqobil matn bo'lib nimaning rasmi ekanligi yoziladi. </p>
        </li>
        <li className='item'>
          <p className='text-tutorial'> <span className='text-tutorial__red'>style</span> atributi elementlar stillarini berish uchun ishlatiladi. Misol uchun rangi, shrifti, o'lchamlari va boshqalar </p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;html&gt;</span> tegi <span className='text-tutorial__red'>lang</span> atributi web sahifaning tilini ko'rsatish uchun ishlatiladi.</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>title</span> atributi element haqida ba'zi ma'lumotlarni berish uchun ishlatiladi.</p>
        </li>
      </ul>
      <hr />
      <h3 className='header-content'>Dars videosi:</h3>
      <YoutubeEmbed embedId="zogg5kygliQ" />
      <a className='text-tutorial' href='https://www.figma.com/file/DMjtHvndAfwQkCcUgXijOK/romsem?node-id=0%3A1' target={'_blank'} rel="noreferrer">Romsem maketi</a>
      <Collapsible>
        <p className='text-tutorial'><strong>Quyida maket asosida veb sahifa yaratilsin:</strong> https://www.figma.com/file/mqVzEZoa8pax8vysAbUII8/beautyness?node-id=0%3A1</p>
      </Collapsible>
      <NavButton back='/html/html_elements' next='/html/html_html_headings' />
    </article>
  )
}

export default HtmlAttributes;