import React from 'react';
import { Link } from 'react-router-dom';
import BrowserTable from '../../pages/BrowserTable';
import Tegs from '../../pages/tegs';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';

function Tagtable() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_table.asp"} />
      <h2 className='header-tutorial'>HTML <Tegs tegname={"table"} /> tegi</h2>
      <NavButton back='/tags/tag_svg' next='/tags/tag_tbody' />
      <TextContent
        code1={`<table>
    <tr>
        <th>Oylar</th>
        <th>Jamg'arma</th>
    </tr>
    <tr>
        <td>Yanvar</td>
        <td>$100</td>
    </tr>
</table>`}
        path1={"try/131"}
        code1Text={"Ikkita ustun va ikkita qatordan iborat jadval"}
      />
      <hr />
      <TextContent header={"Ta'rifi va qo'llanilishi"}
        text1={"<table> tegi HTML jadvalini belgilaydi."}
        redWords1={['<table>']} />
      <p className='text-tutorial'>HTML jadvali bitta <Tegs tegname={"table"} /> elementi va bir yoki bir nechta <Link className='table-link' to={"/tags/tag_tr"}><Tegs tegname={"tr"} /></Link>, <Link className='table-link' to={"/tags/tag_th"}><Tegs tegname={"th"} /></Link> va <Link className='table-link' to={"/tags/tag_td"}><Tegs tegname={"td"} /></Link> elementlardan iborat.</p>
      <TextContent
        text1={"<tr> elementi jadval qatorini, <th> elementi jadval sarlavhasini va <td> elementi jadval ustunini belgilaydi."} />
      <p className='text-tutorial'>HTML jadvali shuningdek, <Link className='table-link' to={"/tags/tag_caption"}><Tegs tegname={"caption"} /></Link>, <Link className='table-link' to={"/tags/tag_colgroup"}><Tegs tegname={"colgroup"} /></Link>, <Link className='table-link' to={"/tags/tag_thead"}><Tegs tegname={"thead"} /></Link>, <Link className='table-link' to={"/tags/tag_tfoot"}><Tegs tegname={"tfoot"} /></Link> va <Link className='table-link' to={"/tags/tag_tbody"}><Tegs tegname={"tbody"} /></Link> elementlarini ham o'z ichiga olishi mumkin.</p>
      <hr />
      <BrowserTable element='<table>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />

      <TextContent header={"Global atributlar"} />
      <p className='text-tutorial'>&lt;table&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <TextContent header={"Hodisa atributlari"} />
      <p className='text-tutorial'>&lt;table&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>

      <TextContent header={"Misollar"}
        code1={`<html>
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<table>
  <tr>
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>
</html> `}
        path1={"try/133"}
        code1Text={"Collapse borderga ega jadval"}
        code2={`<table style="float:right">
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
  <td>Yanvar</td>
  <td>$100</td>
</tr>
<tr>
  <td>Fevral</td>
  <td>$80</td>
</tr>
</table>`}
        path2={"try/134"}
        code2Text={"Jadvalni o'ng tomonga joylashtirish(CSS bilan):"}
        code3={`<html>
<head>
<style>
table, th, td {
  border: 1px solid black;
}
table.center {
  margin-left: auto;
  margin-right: auto;
}
</style>
</head>
<body>

<table class="center">
  <tr>
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>`}
        path3={"try/135"}
        code3Text={"Jadvalni markazga joylashtirish(CSS bilan):"}
        code4={`<table style="background-color:#00FF00">
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
  <td>Yanvar</td>
  <td>$100</td>
</tr>
<tr>
  <td>Fevral</td>
  <td>$80</td>
</tr>
</table>`}
        path4={"try/136"}
        code4Text={"Jadval orqa-foniga rang berish:"} />

      <TextContent code1={`<html>
<head>
<style>
table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 10px;
}
</style>
</head>
<body>

<table>
  <tr>
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>
</html>`}
        path1={"try/137"}
        code1Text={"Jadvalga padding berish:"}
        code2={`<table style="width:400px">
<tr>
   <th>Oylar</th>
   <th>Jamg'arma</th>
</tr>
<tr>
    <td>Yanvar</td>
    <td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>`}
        path2={"try/138"}
        code2Text={"Jadval widthini belgilash(CSS bilan):"}
        code3={`<table>
<tr>
  <th>Ism:</th>
  <td>John Doe</td>
</tr>
<tr>
  <th>Email:</th>
  <td>john.doe@example.com</td>
</tr>
<tr>
  <th>Telefon:</th>
  <td>123-45-678</td>
</tr>
</table>`}
        path3={"try/139"}
        code3Text={"Jadval sarlavhasi:"}
        code4={`<table>
<caption>Oylik jamg'armalar</caption>
<tr>
<th>Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>`}
        path4={"try/140"}
        code4Text={"Sarlavhali jadval:"} />
      <TextContent code1={`<table>
  <tr>
    <th>Ism:</th>
    <td>John Doe</td>
  </tr>
  <tr>
    <th>Email:</th>
    <td>john.doe@example.com</td>
  </tr>
  <tr>
    <th rowspan="2">Telefon:</th>
    <td>123-45-678</td>
  </tr>
  <tr>
    <td>212-00-546</td>
  </tr>
</table>`}
        path1={"try/141"}
        code1Text={"Bir qator yoki bir ustundan ortiq bo'lgan jadval kataklarini qanday aniqlash mumkin:"} />
      <TextContent header={"Tegishli sahifalar"} />
      <p className='text-tutorial'>HTML darslari: <Link className='table-link' to={"/html/html_tables"}>HTML jadvali</Link></p>
      <p className='text-tutorial'>HTML DOM: <Link className='table-link' to={"/jsref/dom_obj_table"}>Jadval obyekti</Link></p>
      <p className='text-tutorial'>CSS darslari: <Link className='table-link' to={"/css/css_table"}>Jadvali stilli</Link></p>
      <TextContent header={"Default CSS sozlamalari"}
        text1={"Ko'p brauzerlarda <table> elementida quyidagi default stillar berilgan bo'ladi:"}
        redWords1={['<map>']}
        css1={`table {
display: table;
border-collapse: separate;
border-spacing: 2px;
border-color: gray;
}`}
        path1={"try/132"} />
      <NavButton back='/tags/tag_main' next='/tags/tag_mark' />
    </article>)
}

export default Tagtable;
