import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';


const cssTutorial = [{
  title: "CSS HOME",
  id: 1,
  subTutorial: [
    { id: 2, title: "CSS Home", path: "css_home" },
    { id: 3, title: "CSS bilan tanishish", path: "css_intro" },
    { id: 4, title: "CSS sintaksisi", path: "css_syntak" },
    { id: 5, title: "CSS selektorlari", path: "css_selector" },
    { id: 6, title: "CSS turlari", path: "css_howto" },
    { id: 7, title: "CSS kommentlar", path: "css_comment" },
    { id: 8, title: "CSS ranglar", path: "css_color" },
    { id: 9, title: "CSS fonlar", path: "css_bacground" },
    { id: 10, title: "CSS border", path: "css_border" },
    { id: 11, title: "CSS margin", path: "css_margin" },
    { id: 12, title: "CSS padding", path: "css_padding" },
    { id: 13, title: "CSS height/width", path: "css_height" },
    { id: 14, title: "CSS Box Model", path: "css_box" },
    { id: 15, title: "CSS Outline", path: "css_outline" },
    { id: 16, title: "CSS Text", path: "css_text" },
    { id: 17, title: "CSS Fonts", path: "css_fonts" },
    { id: 18, title: "CSS icons", path: "css_icons" },
    { id: 19, title: "CSS Links", path: "css_links" },
    { id: 20, title: "CSS Lists", path: "css_lists" },
    { id: 21, title: "CSS Tables", path: "css_tables" },
    { id: 22, title: "CSS Display", path: "css_display" },
    { id: 23, title: "CSS Max-Width", path: "css_max_width" }
  ]
}
];

function Css() {
  return (
    <div className="tutorial container">
      <SideBar datas={cssTutorial} />
      <Outlet />
    </div>
  )
}

export default Css;