import React from 'react';
import Tutorial from './Tutorial';


function Tutorials({ datas }) {
  return (
    <>
      <ul className='tutorials__items'>
        {
          datas.map((value) => {
            return (
              <Tutorial data={value} key={value.id} />
            )
          })
        }
      </ul>
    </>
  )
}

export default Tutorials;