import React from 'react';
import { Link } from 'react-router-dom';
import ColorChange from '../../ColorChange';
import W3Path from '../../W3Path';
import '../Info.css';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import Collapsible from '../../pages/Collapsible';

function HtmlRgb() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_colors_rgb.asp"} />
      <h2 className='header-tutorial'>HTML RGB va RGBA ranglari</h2>
      <NavButton back='/html/html_colors' next='/html/html_colors_hex' />
      <hr />
      <p className='text-tutorial'><b>RGB</b> rang qiymati <span style={{ color: "red" }}>RED(QIZIL)</span>, <span style={{ color: "green" }}>GREEN(YASHIL)</span> va <span style={{ color: "blue" }}>BLUE(KO'K)</span>  ranglarini ifodalaydi.</p>
      <p className='text-tutorial text-tutorial-margin'><b>RGBA</b> rang qiymati <b>RGB</b> va  <b>Alfa (shaffoflik)</b> kengaytmasidir.</p>
      <hr />
      <h3 className='header-content'>RGB rang qiymatlari</h3>
      <p className='text-tutorial'>HTMLda RGB rang qiymati quyidagi formula yordamida beriladi:</p>
      <p className='text-tutorial'><b><i>rgb ( qizil, yashil , ko'k )</i></b></p>
      <p className='text-tutorial'>Har bir parametr (qizil, yashil va ko'k)da rangning intensivligi 0 dan 255 gacha qiymat bilan ifodalanadi.</p>
      <p className='text-tutorial'>Bu <u>256 x 256 x 256 = 16777216</u> xil rang mavjudligini anglatadi!</p>
      <p className='text-tutorial'>Masalan, <i>rgb(255, 0, 0)</i> qizil rangni ifodalaydi, chunki qizil rangga eng yuqori qiymat(255) berilgan, qolgan ikkitasi (yashil va ko'k) 0 ga teng.</p>
      <p className='text-tutorial'>Yana bir misol, <i>rgb(0, 255, 0)</i> yashil rangni ifodalaydi, chunki yashil rangga eng yuqori qiymati(255) berilgan , qolgan ikkitasi (qizil va ko'k) 0 ga teng.</p>
      <p className='text-tutorial'>Qora rangni ko'rsatish uchun barcha rang parametrlariga 0 qiymati berish kerak, masalan: <i>rgb(0, 0, 0)</i>.</p>
      <p className='text-tutorial'>Oq rangni ko'rsatish uchun barcha rang parametrlariga 255 qiymati beriladi, masalan: <i>rgb(255, 255, 255)</i>. </p>
      <p className='text-tutorial'>Quyidagi RGB qiymatlarini aralashtirish orqali tajriba qilishingiz mumkin:</p>
      <ColorChange />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper' style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", marginBottom: "30px", fontSize: "20px", }}>
          <div style={{ backgroundColor: "rgb(255, 0, 0)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgb(255, 0, 0)</p></div>
          <div style={{ backgroundColor: "rgb(0, 0, 255)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgb(0, 0, 255)</p></div>
          <div style={{ backgroundColor: "rgb(60, 179, 113)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgb(60, 179, 113)</p></div>
          <div style={{ backgroundColor: "rgb(238, 130, 238)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgb(238, 130, 238)</p></div>
          <div style={{ backgroundColor: "rgb(255, 165, 0)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgb(255, 165, 0)</p></div>
          <div style={{ backgroundColor: "rgb(106, 90, 205)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgb(106, 90, 205)</p></div>
        </div>
        <Link className='button btn btn--large green' to={`try/57`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <h3 className='header-content'>Kulrang</h3>
      <p className='text-tutorial'>Kulrangni hosil qilish uchun odatda uchala parametr teng qiymatda bo'lishi kerak :</p>
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper' style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", marginBottom: "30px", fontSize: "20px", }}>
          <div style={{ backgroundColor: "rgb(60, 60, 60)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgb(60, 60, 60)</p></div>
          <div style={{ backgroundColor: "rgb(100, 100, 100)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgb(100, 100, 100)</p></div>
          <div style={{ backgroundColor: "rgb(140, 140, 140)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgb(140, 140, 140)</p></div>
          <div style={{ backgroundColor: "rgb(180, 180, 180)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgb(180, 180, 180)</p></div>
          <div style={{ backgroundColor: "rgb(200, 200, 200)", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>rgb(200, 200, 200)</p></div>
          <div style={{ backgroundColor: "rgb(240, 240, 240)", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>rgb(240, 240, 240)</p></div>
        </div>
        <Link className='button btn btn--large green' to={`try/58`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <h3 className='header-content'>RGBA rang qiymatlari</h3>
      <p className='text-tutorial'><b>RGBA</b> rang qiymati <b>RGB </b> va  <b>Alfa (shaffoflik)</b> kengaytmasidir.</p>
      <p className='text-tutorial'>RGBA rang qiymati quyidagicha belgilanadi:</p>
      <p className='text-tutorial'><b><i>rgb ( qizil, yashil , ko'k, alfa )</i></b></p>
      <p className='text-tutorial' id='color_id'>Alfa parametri <u>0,0 (to'liq shaffof)</u> va <u>1,0 (umuman shaffof emas)</u> orasidagi raqamdir.</p>
      <p className='text-tutorial'>Quyidagi RGBA qiymatlarini aralashtirish orqali tajriba o'tkazishingiz mumkin:</p>
      <ColorChange alpha="0.5" />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper' style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", marginBottom: "30px", fontSize: "20px", }}>
          <div style={{ backgroundColor: "rgba(255, 99, 71, 0)", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>rgba(255, 99, 71, 0)</p></div>
          <div style={{ backgroundColor: "rgba(255, 99, 71, 0.2)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgba(255, 99, 71, 0.2)</p></div>
          <div style={{ backgroundColor: "rgba(255, 99, 71, 0.4)", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>rgba(255, 99, 71, 0.4)</p></div>
          <div style={{ backgroundColor: "rgba(255, 99, 71, 0.6)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgba(255, 99, 71, 0.6)</p></div>
          <div style={{ backgroundColor: "rgba(255, 99, 71, 0.8)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgba(255, 99, 71, 0.8)</p></div>
          <div style={{ backgroundColor: "rgba(255, 99, 71, 1)", minWidth: "45%", margin: "10px", padding: "20px" }}><p>rgba(255, 99, 71, 1)</p></div>
        </div>
        <Link className='button btn btn--large green' to={`try/59`} target={"_blank"}>Sinab ko'rish</Link>
      </div>

      <h3 className='header-content'>Dars videosi</h3>
      <YoutubeEmbed embedId="7E8-jtZvZ8U" />
      <Collapsible>
        <p className='text-tutorial'>Ranglarni aralashtirib maket orqa-foni uchun rang tanlang va undan foydalaning:</p>
        <a href='#color_id' rel="noreferrer"> rang tanlash</a>
      </Collapsible>
      <NavButton back='/html/html_colors' next='/html/html_colors_hex' />
    </article>
  )
}

export default HtmlRgb;