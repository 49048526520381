import React from "react";
import img from "../../images/sun.gif";
function Sun() {
    return (
        <>

            <img src={img} alt="Computer" width={100} height={100} />

        </>)
}

export default Sun;