import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../pages/Button';

function NavButton(props) {

  const navigate = useNavigate();
  const goBack = () => navigate(props.back, { replace: true });
  const goNext = () => navigate(props.next, { replace: true });
  return (
    <div className='button-wrapper'>
      <Button
        type='button'
        className='btn icon-btn'
        buttonSize='btn--large'
        buttonColor='green'
        onClick={goBack}
      >Oldingi</Button>
      <Button
        type='button'
        className='btn'
        buttonSize='btn--large'
        buttonColor='green'
        onClick={goNext}
      >Keyingi</Button>
    </div>
  )
}

export default NavButton;