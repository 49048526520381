import React from 'react';

function ColorTable(props) {
    return (
        <table className='table-color' style={{ backgroundColor: props.bgColor }}>
            <tbody>
                <tr>
                    <th scope="col">Rang nomi</th>
                    <th scope="col">HEX</th>
                    <th scope="col">RGB</th>
                </tr>
                {
                    props.values.map((value) => {
                        return (
                            <tr key={value.id} style={{
                                color: value.title
                            }}>
                                <td>{value.title}</td>
                                <td>{value.hex}</td>
                                <td>{value.rgb}</td>
                            </tr>
                        )
                    })

                }
            </tbody>
        </table>

    )
}

export default ColorTable;