import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import { Link } from 'react-router-dom';
import NoteWhite from '../../../pages/NoteWhite';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';



function HtmlForm() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_forms.asp"} />
            <h2 className='header-tutorial'>HTML formalari</h2>
            <NavButton back='/html/html_xtml' next='/html/html_form_atribut' />
            <hr />

            <p>HTML formasi foydalanuvchi ma'lumotlarini yig'ish uchun ishlatiladi. Foydalanuvchi ma'lumotlari ko'pincha qayta ishlash uchun serverga yuboriladi.</p>
            <div className='code-content'>
                <h4 className='header-code'>Misol</h4>
                <p className='text-tutorial'></p>
                <div className='code-wrapper'>
                    <form action="https://www.w3schools.com/action_page.php" target="_blank">
                        <label htmlFor="fname">Ism:</label><br />
                        <input type="text" id="fname" name="fname" defaultValue={"John"} /><br />
                        <label htmlFor="lname">Familiya:</label><br />
                        <input type="text" id="lname" name="lname" defaultValue={"Doe"} /><br /><br />
                        <input type="submit" value="Submit" />
                    </form>
                </div>
                <Link className='button btn btn--large green' to={"try/345"} target={"_blank"}>Sinab ko'rish</Link>
            </div>
            <hr />
            <TextContent header={"<form> elementi"}
                text1={"HTML <form> elementi foydalanuvchi ma'lumot kiritishi uchun HTML formasini yaratishda ishlatiladi:"}
                redWords1={["<form>"]}
                code1={`<form>
.
forma elementlari
.
</form>`}
                text2={"<form> elementi har xil turdagi ma'lumot kiritish elementlari uchun konteynerdir, masalan: matn uchun maydon(text field), tasdiqlash qismi(checkbox), belgilanuvchi tugmalar(radio button), yuborish tugmalari(submit button) va boshqalar."}
                redWords2={["<form>"]} />
            <NoteWhite text={"Barcha forma elementlari quyidagi bobda yoritilgan:"} text1={" HTML forma elementlari."} path={"/html/html_form_elements"} />
            <hr />
            <TextContent header={"<input> elementi"}
                text1={"HTML <input> elementi eng ko'p ishlatiladigan forma elementi hisoblanadi."}
                redWords1={["<input>"]}
                text2={"<input> elementi type atributiga qarab ko'p turli xil ko'rinishda ko'rsatilishi mumkin."}
                redWords2={["<input>"]}
                text3={`Mana bir nechta misollar:`} />
            <Table th={["Type", "Tavsif"]} values={[
                { id: 0, first: '<input type="text">', second: "Bir qatorli matn kiritish maydonini ko'rsatadi" },
                { id: 1, first: '<input type="radio">', second: "Radio tugmachasini ko'rsatadi (ko'p variantlardan birini tanlash uchun)" },
                { id: 2, first: '<input type="checkbox">', second: "Belgilash katakchasini ko'rsatadi (ko'p variantlardan tanlamaslik yoki bir nechtasini tanlash uchun)" },
                { id: 3, first: '<input type="submit">', second: "Yuborish tugmachasini ko'rsatadi (forma ma'lumotlarini yuborish uchun)" },
                { id: 4, first: '<input type="button">', second: "Bosiladigan tugmani ko'rsatadi" }]} />
            <NoteWhite text={"Barcha turli xil input turlari ushbu bobda yoritilgan:"} text1={"HTML ma'lumot kiritish turlari."} path={"/html/html_form_input_types"} />
            <hr />
            <TextContent header={"Matn uchun input"}
                text1={"<input type='text'> matn kiritish uchun bitta qatorli kiritish maydonini(inputni) belgilaydi."}
                redWords1={["<input", "type='text'>"]}
                text2={"Yuqoridagi HTML kodi brauzerda quyidagicha ko'rsatiladi:"} code1={`<form>
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname">
</form>`} path1={"try/346"} code1Text={"Matn uchun input maydoni"} />
            <form>
                <label htmlFor="fname">Ism:</label><br />
                <input type="text" id="fname" name="fname" defaultValue={"John"} /><br />
                <label htmlFor="lname">Familiya:</label>    <br />
                <input type="text" id="lname" name="lname" defaultValue={"Doe"} /><br /><br />
            </form>
            <Notes type={"Eslatma:"} text={"Formaning o'zi ko'rinmaydi. Shuni ham yodda tutingki, kiritish maydonining default kengligi 20 belgidan iborat."} />
            <hr />
            <TextContent header={"<label> elementi"}
                text1={"Yuqoridagi misoldagi <label> elementini ko'rib chiqamiz. <label> tegi forma elementlari uchun bu elementga qanday ma'lumot kiritilishi kerakligi haqida matn yoki belgini kiritish uchun ishlatiladi."}
                redWords1={["<label>"]}
                text2={"<label> elementi ekranni o'qish qurilmalaridan foydalanuvchilar uchun foydalidir, chunki foydalanuvchi input elementiga e'tibor qaratganda ekran o'qish qurilmasi labelni baland ovozda o'qiydi."}
                redWords2={["<label>"]}
                text3={"<label> elementi, shuningdek, juda kichik elementlarni (masalan, radio tugmalar yoki belgilash katakchalari) bosishda qiynalayotgan foydalanuvchilarga yordam beradi, chunki foydalanuvchi <label> elementi ichidagi matnni bosganida, radio button yoki checkbox belgilanadi."}
                redWords3={["<label>"]}
                text4={"Input va label elementlarini bir-biriga bog'lash uchun <label> tegining for atributi input elementning id atributiga teng bo'lishi kerak."}
                redWords4={["<label>"]} />
            <hr />
            <TextContent header={"Radio Button"}
                text1={"<input type='radio'> radio buttonni belgilaydi ."}
                redWords1={["<input", "type='radio'>"]}
                text2={"Radio button foydalanuvchiga berilgan tanlovdan birini tanlash imkonini beradi."}
                code2={`<p>Siz uchun sevimli veb dasturlash tilini tanlang:</p>

<form>
  <input type="radio" id="html" name="fav_language" value="HTML">
  <label for="html">HTML</label><br>
  <input type="radio" id="css" name="fav_language" value="CSS">
  <label for="css">CSS</label><br>
  <input type="radio" id="javascript" name="fav_language" value="JavaScript">
  <label for="javascript">JavaScript</label>
</form>`}
                path2={"try/347"}
                code2Text={"Radio button"}
                text3={"Yuqoridagi HTML kodi brauzerda shunday ko'rsatiladi:"}
                text4={"Siz uchun sevimli veb dasturlash tilini tanlang:"} />
            <form>
                <input type="radio" id="html" name="fav_language" defaultValue={"HTML"} defaultChecked={"checked"} />
                <label htmlFor="html">HTML</label><br />
                <input type="radio" id="css" name="fav_language" defaultValue={"CSS"} />
                <label htmlFor="css">CSS</label><br />
                <input type="radio" id="javascript" name="fav_language" defaultValue={"JavaScript"} />
                <label htmlFor="javascript">JavaScript</label>
            </form>

            <hr />
            <TextContent header={"Checkbox"}
                text1={"<input type='checkbox'> belgilash katakchasini(checkboxni) belgilaydi."}
                redWords1={["<input", "type='checkbox'>"]}
                text2={"Belgilash katakchalari foydalanuvchiga berilgan tanlovlardan umuman tanlamaslik yoki KO'PROQ variantlarni tanlash imkonini beradi."}
                code2={`<form>
  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
  <label for="vehicle1">Mening velosipedim bor</label><br>
  <input type="checkbox" id="vehicle2" name="vehicle2" value="Car">
  <label for="vehicle2">Mening mashinam bor</label><br>
  <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat">
  <label for="vehicle3">Mening qayig'im bor.</label>
</form>`}
                path2={"try/348"}
                code2Text={"checkbox"}
                text3={"Yuqoridagi HTML kodi brauzerda shunday ko'rsatiladi:"} />
            <form action="https://www.w3schools.com/action_page.php">
                <input type="checkbox" id="vehicle1" name="vehicle1" defaultValue={"Bike"} />
                <label htmlFor="vehicle1"> Mening velosipedim bor</label><br />
                <input type="checkbox" id="vehicle2" name="vehicle2" defaultValue={"Car"} />
                <label htmlFor="vehicle2"> Mening mashinam bor</label><br />
                <input type="checkbox" id="vehicle3" name="vehicle3" defaultValue={"Boat"} />
                <label htmlFor="vehicle3"> Mening qayig'im bor.</label><br /><br />
            </form>
            <hr />
            <TextContent header={"Submit Button"}
                text1={"<input type='submit'> forma ma'lumotlarini forma ishlov beruvchisi(form-handler)ga yuborish tugmachasini belgilaydi."}
                redWords1={["<input", "type='submit'>"]}
                text2={"Forma ishlov beruvchisi(form-handler) odatda kiritilgan ma'lumotlarini qanday qayta ishlash kerakligini belgilovchi serverdagi fayldir."}
                text3={"Forma ishlov beruvchisi(form-handler) form elementi action atributida ko'rsatiladi. "}
                code3={`<form action="https://www.w3schools.com/action_page.php">
   <label for="fname">Ism:</label><br>
   <input type="text" id="fname" name="fname" value="John"><br>
   <label for="lname">Familiya:</label><br>
   <input type="text" id="lname" name="lname" value="Doe"><br><br>
   <input type="submit" value="Submit">
 </form>`}
                path3={"try/345"}
                code3Text={"submit buttonli forma"}
                text4={"Yuqoridagi HTML kodi brauzerda bunday ko'rsatiladi:"} />
            <form action="https://www.w3schools.com/action_page.php" target="_blank">
                <label htmlFor="fname">Ism:</label><br />
                <input type="text" id="fname" name="fname" defaultValue={"John"} /><br />
                <label htmlFor="lname">Familiya:</label><br />
                <input type="text" id="lname" name="lname" defaultValue={"Doe"} /><br /><br />
                <input type="submit" defaultValue={"Submit"} />
            </form>
            <hr />
            <TextContent header={"<input> uchun name atributi"}
                text1={"Har bir inputda yuboriladigan name atributi bo'lishi kerak."}
                redWords1={["name"]}
                text2={"name atributi tushirib qoldirilsa, input qiymati umuman yuborilmaydi."}
                code2={`<form action="https://www.w3schools.com/action_page.php">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" value="John"><br><br>
  <input type="submit" value="Submit">
</form>`}
                path2={"try/349"} code12Text={"Quyidagi misolda birinchi input ma'lumoti yuborilmaydi:"} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="4ijpERQBOZE" />
            <NavButton back='/html/html_xtml' next='/html/html_form_atribut' />
        </div>)
}

export default HtmlForm;