import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import ListText from '../../pages/ListsText';
import Notes from '../../pages/Notes';
import Table from '../../pages/Table';
import img from '../../../images/img_sem_elements.gif';


function Semantic() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html5_semantic_elements.asp"} />
            <h2 className='header-tutorial'>Html semantik elementlari</h2>
            <NavButton back='/html/html_computercode' next='/html/html_syntax' />
            <hr />

            <p>Ma'noga ega elementlar semantik elementlar hisoblanadi.</p>
            <hr />
            <TextContent header={"Semantik elementlar nima?"}
                text1={"Semantik elementlar ma'nosini brauzerlar ham, dasturchilar ham oson tushinib oladi."}
                text2={"Semantik bo'lmagan elementlar, masalan <div> va <span> elementlari, kontent haqida hech qanday ma'lumot bermaydi."}
                redWords2={["<div>", "<span>"]}
                text3={"Semantik elementlar, misol uchun <form>, <table> va <article>, kontentni aniq tasvirlaydi."}
                redWords3={["<form>", "<table>", "<article>"]} />
            <hr />
            <TextContent header={"HTML semantik elementlari"}
                text1={"Ko'plab veb saytlarda navigatsiya menyusi, header, footer qismlari quyidagicha bo'ladi: <div id='nav'> <div class='header'> <div id='footer'></div>."}
                text2={"Quyida veb sahifaning turli xil qismlarini tavsiflovchi semantik elementlar keltirilgan:"} />
            <div style={{ marginTop: '15px', display: "flex", flexWrap: "wrap" }}>
                <div style={{ width: "374px" }}>
                    <ListText list={[{
                        text: "<article>",
                    }, {
                        text: "<aside>",
                    },
                    {
                        text: "<details> ",
                    },
                    {
                        text: "<figcaption> ",
                    },
                    {
                        text: "<figure> ",
                    },
                    {
                        text: "<footer> ",
                    },
                    {
                        text: "<header> ",
                    },
                    {
                        text: "<main> ",
                    },
                    {
                        text: "<mark> ",
                    },
                    {
                        text: "<nav> ",
                    },
                    {
                        text: "<section> ",
                    },
                    {
                        text: "<summary> ",
                    },
                    {
                        text: "<time> ",
                    },
                    ]} />
                </div>
                <img style={{ marginRight: '10px' }} src={img} alt="Layout" width={219} height={258} />
            </div>

            <hr />
            <TextContent header={"<section> elementi"}
                text1={"<section> elementi hujjat bo'limini ifodalaydi."}
                redWords1={["<section>"]}
                text2={"W3C hujjatiga ko'ra: bo'lim - odatda sarlavhaga ega bo'lgan tematik kontent guruhi."}
                text3={"<section> elementi odatda quyidagilarni o'z ichiga oladi:"}
                redWords3={["<section>"]} />
            <ListText list={[
                {
                    text: "Boblar",
                },
                {
                    text: "Muqaddima",
                },
                {
                    text: "Yangiliklar",
                },
                {
                    text: "Bog'lanish uchun ma'lumotlar",
                },
            ]} />
            <p>Veb-sahifa odatda muqaddima, kontent va aloqa ma'lumotlari uchun bo'limlarga bo'linishi mumkin.</p>
            <TextContent code1={`<section>
  <h1>WWF</h1>
  <p>Butunjahon tabiatni muhofaza qilish jamg'armasi (WWF) atrof-muhitni muhofaza qilish, tadqiq qilish va qayta tiklash masalalari bilan shug'ullanuvchi xalqaro tashkilot bo'lib, ilgari Butunjahon yovvoyi tabiat fondi deb nomlangan. WWF 1961 yilda tashkil etilgan.</p>
</section>

<section>
  <h1>WWFning Panda belgisi</h1>
  <p>Panda WWF ramziga aylandi. WWFning mashhur panda logotipi WWF tashkil etilgan yili Pekin hayvonot bog'idan London hayvonot bog'iga ko'chirilgan Chi Chi ismli pandadan kelib chiqqan</p>
</section>`} path1={"try/272"} code1Text={"Hujjatdagi ikkita bo'lim:"} />
            <hr />
            <TextContent header={"<article>"}
                text1={"<article> elementi mustaqil va alohida kontent uchun ishatiladi."}
                redWords1={["<article>"]}
                text2={"<article> o'z ma'nosiga ega bo'lishi kerak va uni veb-saytning boshqa qismiga joylashtirilganda ham asosiy mazmuni o'zgarmasligi kerak."}
                text3={"<article> elementi odatda quyidagilarni o'z ichiga oladi"}
                redWords3={["<article>"]} />
            <ListText list={[
                {
                    text: "Forum xabarlari",
                },
                {
                    text: "Blog postlari",
                },
                {
                    text: "Foydalanuvchi sharhlari",
                },
                {
                    text: "Mahsulot kartalari",
                },
                {
                    text: "Gazeta maqolalari",
                },
            ]} />
            <p></p>
            <TextContent code1={`<article>
  <h2>Google Chrome</h2>
  <p> Google Chrome — Google tomonidan ishlab chiqilgan, 2008 yilda chiqarilgan veb-brauzer. Chrome — bugungi kunda dunyodagi eng mashhur veb-brauzer!</p>
</article>

<article>
  <h2>Mozilla Firefox</h2>
  <p>Mozilla Firefox — Mozilla tomonidan ishlab chiqilgan ochiq manbali veb-brauzer. Firefox 2018 yil yanvar oyidan beri eng mashhur ikkinchi veb-brauzer bo'ldi.</p>
</article>

<article>
  <h2>Microsoft Edge</h2>
  <p>Microsoft Edge - bu Microsoft tomonidan ishlab chiqilgan veb-brauzer, 2015 yilda chiqarilgan. Microsoft Edge Internet Explorer o'rnini egalladi.</p>
</article>`} path1={"try/273"} code1Text={"Mustaqil va alohida kontentga ega uchta maqola:"}
                code2={`
<html>
  <head>
<style>
.all-browsers {
  margin: 0;
  padding: 5px;
  background-color: lightgray;
}

.all-browsers > h1, .browser {
  margin: 10px;
  padding: 5px;
}

.browser {
  background: white;
}

.browser > h2, p {
  margin: 4px;
  font-size: 90%;
}
</style>
</head>
<body>

<article class="all-browsers">
  <h1>Mashhur brauzerlar</h1>
  <article class="browser">
    <h2>Google Chrome</h2>
    <p> Google Chrome — Google tomonidan ishlab chiqilgan, 2008 yilda chiqarilgan veb-brauzer. Chrome — bugungi kunda dunyodagi eng mashhur veb-brauzer!</p>
  </article>
  <article class="browser">
    <h2>Mozilla Firefox</h2>
    <p>Mozilla Firefox — Mozilla tomonidan ishlab chiqilgan ochiq manbali veb-brauzer. Firefox 2018 yil yanvar oyidan beri eng mashhur ikkinchi veb-brauzer bo'ldi.</p>
  </article>
  <article class="browser">
    <h2>Microsoft Edge</h2>
    <p>Microsoft Edge - bu Microsoft tomonidan ishlab chiqilgan veb-brauzer, 2015 yilda chiqarilgan. Microsoft Edge Internet Explorer o'rnini egalladi.</p>
  </article>
</article>

</body>
</html>`} path2={"try/274"} code2Text={"<article> elementiga stil berish uchun CSSdan foydalanildi:"} />
            <hr />
            <TextContent header={"<article> elementi <section> elementi ichida joylashadimi yoki aksincha?"}
                text1={"<article> elementi mustaqil va alohida kontent hisoblanadi."}
                redWords1={["<article>"]}
                text2={"<section> elementi hujjat bo'limini ifodalaydi"}
                redWords2={["<section>"]}
                text3={"HTML sahifalarida <section>  elementlarini o'z ichiga olgan <article> elementlari va <article> elementlarini o'z ichiga olgan <section> elementlarini uchratish mumkin."}
                redWords3={["<section>", "<article>"]} />
            <hr />
            <TextContent header={"<header> elementi"}
                text1={"<header> elementi sahifa boshlanish qismi yoki navigatsiya havolalari to'plami uchun konteynerni ifodalaydi."}
                redWords1={["<header>"]}
                text2={"<header> elementi odatda quyidagilarni o'z ichiga oladi:"}
                redWords2={["<header>"]} />
            <ListText list={[
                {
                    text: "bir yoki bir nechta sarlavha elementlari(<h1> - <h6>)",
                },
                {
                    text: "logotip yoki ikonka",
                },
                {
                    text: "mualliflik ma'lumotlari",
                },
            ]} />
            <TextContent text1={"HTML hujjatida bir nechta <header> elementi bo'lishi mumkin, ammo <header> elementi <footer>, <address> yoki boshqa <header> elementlari ichida joylashishi mumkin  emas."} redWords1={["<header>", "<footer>", "<address>"]}
                code1={`<article>
  <header>
    <h1>WWF nima qiladi?</h1>
    <p>WWF missiyasi:</p>
  </header>
  <p>WWF missiyasi sayyoramizning tabiiy muhiti degradatsiyasini to'xtatish va insonlar tabiat bilan uyg'unlikda yashaydigan kelajakni qurishdan iborat.</p>
</article>`} path1={"try/275"} code1Text={"<article> elementi uchun <header>"} />
            <hr />
            <TextContent header={"<footer> elementi"}
                text1={"<footer> hujjat yoki bo'lim uchun pastki qismni belgilaydi."}
                redWords1={["<footer>"]}
                text2={"<footer> elementi odatda quyidagilarni o'z ichiga oladi:"}
                redWords2={["<footer>"]} />
            <ListText list={[
                {
                    text: "mualliflik ma'lumotlari",
                },
                {
                    text: "mualliflik huquqi haqida ma'lumot",
                },
                {
                    text: "bog'lanish uchun ma'lumot",
                },
                {
                    text: "sayt xaritasi",
                },
                {
                    text: "yuqori havolalarga qaytish",
                },
                {
                    text: "tegishli hujjatlar",
                },
            ]} />
            <TextContent text1={"Bitta hujjatda bir nechta <footer> elementlar bo'lishi mumkin."} redWords1={["<footer>"]}
                code1={`<footer>
  <p>Muallif: Xege Refsnes</p>
  <p><a href="mailto:hege@example.com">hege@example.com</a></p>
</footer>`} path1={"try/276"} code1Text={"Hujjatdagi pastki bo'limi:"} />
                <hr/>
                <TextContent header={"<nav> elementi"}
                text1={"<nav> elementi navigatsiya havolalari o'z ichiga oladi."}
                redWords1={["<nav>"]}/>
                <Notes text={"E'tibor bering, hujjatning barcha havolalari <nav> elementi ichida bo'lmaydi. <nav> elementi faqat navigatsiya havolalari uchun mo'ljallangan. Masalan, imkoniyati cheklangan foydalanuvchilar ekranni o'qish qurilmalaridan foydalanganda, brauzer ushbu <nav> elementidagi kontentni o'qilishini o'tkazib yuboradi."}/>
                <TextContent code1={`<nav>
  <a href="/html/">HTML</a> |
  <a href="/css/">CSS</a> |
  <a href="/js/">JavaScript</a> |
  <a href="/jquery/">jQuery</a>
</nav>`} path1={"try/277"} code1Text={"Navigatsiya havolalari:"} />
                <hr/>
                <TextContent header={"<aside> elementi"} text1={"<aside> elementi o'zi joylashtirilgan kontentdan tashqari yana kontent yozishda ishlatiladi(masalan, yon panel)."} redWords1={["<aside>"]}
                text2={"Bu kontent <aside> atrofidagi kontent bilan bilvosita bog'liq bo'lishi kerak."} redWords2={["<aside>"]}
                code2={`<p>Men va oilam shu yozda Epcot markaziga tashrif buyurdik. Ob-havo yaxshi edi va Epcot ajoyib edi! Men oilam bilan yozni ajoyib o'tkazdim!</p>

<aside>
  <h4>Epcot markazi</h4>
  <p>Epcot - bu Walt Disney World Resortda qiziqarli attraksionlar, xalqaro pavilonlar, mukofotga sazovor bo'lgan otashinlar va mavsumiy maxsus tadbirlarni o'z ichiga olgan maxsus park.</p>
</aside>`} path2={"try/278"} code2Text={"Kontentni u joylashtirilgan kontentdan tashqarida ko'rsatish:"}
                code3={`<html>
 <head>
<style>
aside {
  width: 30%;
  padding-left: 15px;
  margin-left: 15px;
  float: right;
  font-style: italic;
  background-color: lightgray;
}
</style>
</head>
<body>

<p>Men va oilam shu yozda Epcot markaziga tashrif buyurdik. Ob-havo yaxshi edi va Epcot ajoyib edi! Men oilam bilan yozni ajoyib o'tkazdim!</p>

<aside>
  <h4>Epcot markazi</h4>
  <p>Epcot - bu Walt Disney World Resortda qiziqarli attraksionlar, xalqaro pavilonlar, mukofotga sazovor bo'lgan otashinlar va mavsumiy maxsus tadbirlarni o'z ichiga olgan maxsus park.</p>
</aside>

<p>Men va oilam shu yozda Epcot markaziga tashrif buyurdik. Ob-havo yaxshi edi va Epcot ajoyib edi! Men oilam bilan yozni ajoyib o'tkazdim!</p>
<p>Men va oilam shu yozda Epcot markaziga tashrif buyurdik. Ob-havo yaxshi edi va Epcot ajoyib edi! Men oilam bilan yozni ajoyib o'tkazdim!</p>

</body>
</html>`} path3={"try/279"} code3Text={"<aside> elementiga stil berish uchun CSS dan foydalanish."} />
                    <hr/>
                <TextContent header={"<figure> va <figcaption> elementlari"} text1={"<figure> tegi rasmlar, diagrammalar, fotosuratlar, kodlar ro'yxati va boshqa shu kabi mustaqil tarkiblar uchun ishlatiladi."} redWords1={["<figure>"]}
                text2={"<figcaption> tegi <figure> elementi uchun sarlavhani belgilaydi. <figcaption> elementi <figure> elementning birinchi yoki oxirgi elementi sifatida joylashtirilishi mumkin."} redWords2={["<figcaption>", "<figure>"]}
                text3={"<img> joriy rasmni/illyustratsiyani belgilaydi."} redWords3={["<img>"]}
                code3={`<figure>
  <img src="pic_trulli.jpg" alt="Trulli" style="width:100%">
  <figcaption>1-rasm - Trulli, Puglia, Italiya.</figcaption>
</figure>`} path3={"try/280"} />
                <hr/>
<TextContent header={"Nima uchun semantik elementlar?"} text1={`W3C ga ko'ra: "Semantik veb ma'lumotlarni ilovalar, korxonalar va jamoalar bo'ylab almashish va qayta foydalanish imkonini beradi."`}/>
<hr/>

            <Table  header={"HTMLdagi semantik elementlar"} text={"Quyidagi jadvalda HTML tilidagi ba'zi semantik elementlarning ro'yxati keltirilgan."} th={["Teg", "Tavsif"]} values={[
                { id: 0, first: "<article>", second: "Mustaqil va alohida kontent uchun ishatiladi..", path: "/tags/tag_article" },
                { id: 1, first: "<aside>", second: "O'zi joylashtirilgan kontentdan tashqari yana kontent yozishda ishlatiladi", path: "/tags/tag_aside" },
                { id: 2, first: "<details>", second: "Foydalanuvchi ko'rishi yoki yashirishi mumkin bo'lgan qo'shimcha ma'lumotlarni belgilaydi", path: "/tags/tag_details" },
                { id: 3, first: "<figcaption>", second: "<figure> elementi uchun sarlavhani belgilaydi.", path: "/tags/tag_figcaption" },
                { id: 4, first: "<figure>", second: "Rasmlar, diagrammalar, fotosuratlar, kodlar ro'yxati va boshqa shu kabi mustaqil tarkiblar uchun ishlatiladi.", path: "/tags/tag_figure" },
                { id: 5, first: "<footer>", second: "Hujjat yoki bo'lim uchun pastki qismni belgilaydi.", path: "/tags/tag_footer" },
                { id: 6, first: "<header>", second: "Hujjat yoki bo'lim uchun sarlavhani belgilaydi.", path: "/tags/tag_header" },
                { id: 7, first: "<main>", second: "Hujjatning asosiy qismini belgilaydi.", path: "/tags/tag_main" },
                { id: 8, first: "<mark>", second: "Belgilangan/ta'kidlangan matnni belgilaydi.", path: "/tags/tag_mark" },
                { id: 9, first: "<nav>", second: "Navigatsiya havolalarini belgilaydi.", path: "/tags/tag_nav" },
                { id: 10, first: "<section>", second: "Hujjatdagi bo'limni belgilaydi.", path: "/tags/tag_section" },
                { id: 11, first: "<summary>", second: "<details> elementi uchun ko'rinadigan sarlavhani belgilaydi.", path: "/tags/tag_summary" },
                { id: 12, first: "<time>", second: "Sana/vaqtni belgilaydi.", path: "/tags/tag_time" }
                ]} />
                <Notes text={"Barcha mavjud HTML teglarining to'liq ro'yxatini ko'rish uchun bizning"} path="/tags" text1={" HTML teg ma'lumotnomamizga"} text2={"tashrif buyuring."} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="IcTaBmMA3x8" />
            <NavButton back='/html/html_computercode' next='/html/html_syntax' />
        </article>)
}

export default Semantic;