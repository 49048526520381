import React from 'react';
import CodeContent from '../../../tryit/CodeContent';
import CodeMirrorContent from '../../../tryit/CodeContent/CodeMirrorContent';
import Collapsible from '../../pages/Collapsible';
import W3Path from '../../W3Path';
import '../Info.css';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';

function HtmlComments() {
  return (
    <article className='info-wrapper'>
          <W3Path path={"https://www.w3schools.com/html/html_comments.asp"}/>
      <h2 className='header-tutorial'>HTML Izohlari</h2>
      <NavButton back='/html/html_quotation_elements' next='/html/html_colors' />
      <hr />
      <p className='text-tutorial'>HTML sharhlari brauzerda ko'rsatilmaydi, lekin ular HTML manba kodingizni hujjatlashtirishga yordam beradi.</p>
      <hr/>
      <h3 className='header-content'>HTML izoh tegi</h3>
      <p className='text-tutorial'>Quyidagi sintaksisdan foydalanib, HTML kodiga izohlar qo'shish mumkin:</p>
      <div className='code-content'>
        <div className='code-wrapper'>
      <CodeMirrorContent value={'<!-- Write your comments here -->'}/>
      </div>
      </div>
      <p className='text-tutorial'>E'tibor bering, boshlang'ich tegda undov belgisi (!) bor, lekin oxirgi tegda emas.</p>
      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong> Sharhlar brauzer tomonidan ko'rsatilmaydi, lekin ular HTML manba kodini tahrirlashga yordam beradi.</p>
      </div>
      <hr />
      <h3 className='header-content'>Fikrlar qo'shish</h3>
      <p className='text-tutorial'>Izohlar yordamida siz HTML kodiga bildirishnomalar va eslatmalarni joylashtirish mumkin:</p>
      <CodeContent props={`<!-- Bu izoh -->
<p>Bu matn.</p>
<!-- Bu yerga qo'shimcha ma'lumotlar kiritishni unutmang. -->`} pathname={`try/48`}/>
      <hr/>
      <h3 className='header-content'>Kontentni yashirish</h3>
      <p className='text-tutorial'>Kod qismlari olib tashlanganida, keyinchalik ulardan foydalanish uchun ularni izoh ichida qoldirish mumkin.</p>
      <CodeContent props={`<p>Bu xatboshi.</p>

<!-- <p>Bu boshqa xatboshi. </p> -->

<p>Bu ham xatboshi.</p>`} pathname={`try/49`}/>
      <p className='text-tutorial'>Bundan tashqari, bir nechta qatorni izohga olish mumkin. &lt;!--Izoh  --&gt; Izoh tegi orasidagi hamma narsa ko'rinmaydi.</p>
      <CodeContent props={`<p>Bu xatboshi.</p>
<!--
<p>Bu rasmga qarang:</p>
<img border="0" src="pic_trulli.jpg" alt="Trulli">
-->
<p>Bu ham xatboshi.</p>`} pathname={`try/50`}/>
      <p className='text-tutorial'>Izohlar kod ichidagi xatolarni topishga yordam beradi, chunki HTML kod satrini birma-bir izohga olib xatolarni topish mumkin.</p>
      <hr/>
      <h3 className='header-content'>Ichki kontent izohi</h3>
      <p className='text-tutorial'>HTML kodining ichki qismlarini ham izoh ichiga olish mumkin.</p>
      <CodeContent text={'Xatboshining bir qismini yashirish:'} props={`<p>Bu <!-- ajoyib --> xatboshi.</p>`} pathname={`try/51`}/>
      <hr/>
      <h3 className='header-content'>Dars videosi:</h3>
      <YoutubeEmbed embedId="sNYI9DUs5G0" />
      <Collapsible>
      <p className='text-tutorial'>Web sahifamizga "Add css style" vazifasini TODO yordamida kiritamiz. </p>
      </Collapsible>
      <hr/>
      <NavButton back='/html/html_quotation_elements' next='/html/html_colors' />
      </article>
      )}

      export default HtmlComments;