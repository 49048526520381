import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import NavButton from '../NavButton';
import CardColor from './CardColor';
import ColorLists from './colorList';

function ColorValue() {
  const sortedDate = [...ColorLists].sort((a, b) => {
    return a.hex > b.hex ? 1 : -1
  })

  if (window.location.pathname !== '/colors/colors_hex') {
    return (
      <Outlet />
    )
  }
  else return (
    <article className='info-wrapper'>
      <p className='text-tutorial article'> Maqola <a className='table-link' href="https://www.w3schools.com/colors/colors_hex.asp" target='_blank' area-label='vscode' rel="noreferrer"> W3School</a>  veb saytidan tarjima qilingan.</p>
      <h2 className='header-tutorial'>HTML rang qiymatlari </h2>
      <NavButton back='/colors/colors_names' next='/colors/colors_groups' />
      <hr />
      <h3 className='header-content'>HEX qiymati bo'yicha saralangan ranglar</h3>
      <p className='text-tutorial'><Link className='text-tutorial text-tutorial__link' to={'/colors/colors_namess'}>Nomi bo'yicha saralangan ranglarni ko'rish uchun shu yerni bosing.  </Link>
      </p>
      <hr />
      <CardColor datas={sortedDate} />
      <NavButton back='/colors/colors_names' next='/colors/colors_groups' />
    </article>
  )

}

export default ColorValue;