import React from 'react';
import { Link } from 'react-router-dom';
import CodeContent from '../../../tryit/CodeContent';
import W3Path from '../../W3Path';
import '../Info.css';
import NavButton from '../NavButton';

function HtmlHome() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/default.asp"}/>
      <h2 className='header-tutorial'>HTML HOME</h2>
      <NavButton back='/' next='/html/html_intro' />
      <hr />
      <div className='green-content'>
        <p className='text-tutorial'>HTML veb-sahifalarni yaratish uchun standart belgilash tilidir.</p>
        <p className='text-tutorial'>HTML yordamida siz o'z veb-saytingizni yaratishingiz mumkin.</p>
        <p className='text-tutorial'>HTMLni o'rganish juda oson - bu sizga yoqadi!</p>
        <Link to={'/html/html_intro'} className='btn btn--large green'>Bepul o'rganing</Link>
      </div>
      <h3 className='header-content'>HTML bilan oson o'rganish "O'zingiz sinab ko'ring"</h3>
      <p className='text-tutorial'>"O'zingiz sinab ko'ring" muharririmiz yordamida siz HTML kodni tahrirlashingiz va natijani ko'rishingiz mumkin:</p>

      <CodeContent props={` <!DOCTYPE html>
    <html>
    <head>
    <title>Sahifa sarlavhasi</title>
    </head>
    <body>
    <h1>Bu sarlavha</h1>
    <p>Bu matn.</p>

    </body>
    </html>`} pathname={`try/1`}/>
    </article>
  )
}

export default HtmlHome;