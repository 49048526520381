import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import Notes from '../../../pages/Notes';
import NoteWhite from '../../../pages/NoteWhite';
import BrowserTable from '../../../pages/BrowserTable';
import CodeMirrorContent from '../../../../tryit/CodeContent/CodeMirrorContent';


function Canvas() {
    React.useEffect(() => {
        var c = document.getElementById("myCanvas");
        var canvOK = 1;
        try { c.getContext("2d"); }
        catch (er) { canvOK = 0; }
        if (canvOK === 1) {
            var ctx = c.getContext("2d");
            ctx.fillStyle = "#FF0000";
            ctx.fillRect(20, 20, 100, 50);

            var grd = ctx.createLinearGradient(140, 20, 240, 70);
            grd.addColorStop(0, "black");
            grd.addColorStop(1, "white");
            ctx.fillStyle = grd;
            ctx.fillRect(140, 20, 100, 50);

            var grd2 = ctx.createLinearGradient(20, 90, 120, 90);
            grd2.addColorStop(0, "black");
            grd2.addColorStop("0.3", "magenta");
            grd2.addColorStop("0.5", "blue");
            grd2.addColorStop("0.6", "green");
            grd2.addColorStop("0.8", "yellow");
            grd2.addColorStop(1, "red");
            ctx.fillStyle = grd2;
            ctx.fillRect(20, 90, 100, 50);

            ctx.font = "30px Verdana";
            var grd3 = ctx.createLinearGradient(140, 20, 240, 90);
            grd3.addColorStop(0, "black");
            grd3.addColorStop("0.3", "magenta");
            grd3.addColorStop("0.6", "blue");
            grd3.addColorStop("0.8", "green");
            grd3.addColorStop(1, "red");
            ctx.strokeStyle = grd3;
            ctx.strokeText("Smile!", 140, 120);
        }

        var d = document.getElementById("myCanvas2");
        var canvOK2 = 1;
        try { d.getContext("2d"); }
        catch (er) { canvOK2 = 0; }
        if (canvOK2 === 1) {
            var ctx2 = d.getContext("2d");
            ctx2.moveTo(0, 0);
            ctx2.lineTo(200, 100);
            ctx2.stroke();
        }
        var a = document.getElementById("myCanvas3");
        var canvOK3 = 1;
        try { a.getContext("2d"); }
        catch (er) { canvOK3 = 0; }
        if (canvOK3 === 1) {
            var ctx3 = a.getContext("2d");
            ctx3.beginPath();
            ctx3.arc(95, 50, 40, 0, 2 * Math.PI);
            ctx3.stroke();
        }
        var b = document.getElementById("myCanvas4");
        var canvOK4 = 1;
        try { b.getContext("2d"); }
        catch (er) { canvOK4 = 0; }
        if (canvOK4 === 1) {
            var ctx4 = b.getContext("2d");
            ctx4.font = "30px Arial";
            ctx4.fillText("Hello World", 10, 50);
        }

        var e = document.getElementById("myCanvas5");
        var canvOK5 = 1;
        try { e.getContext("2d"); }
        catch (er) { canvOK5 = 0; }
        if (canvOK5 === 1) {
            var ctx5 = e.getContext("2d");
            ctx5.font = "30px Arial";
            ctx5.strokeText("Hello World", 10, 50);
        }

        var f = document.getElementById("myCanvas6");
        var canvOK6 = 1;
        try { f.getContext("2d"); }
        catch (er) { canvOK6 = 0; }
        if (canvOK6 === 1) {
            var ctx6 = f.getContext("2d");// Create gradient
            var grd1 = ctx6.createLinearGradient(0, 0, 200, 0);
            grd1.addColorStop(0, "red");
            grd1.addColorStop(1, "white");

            // Fill with gradient
            ctx6.fillStyle = grd1;
            ctx6.fillRect(10, 10, 150, 80);
        }


        var g = document.getElementById("myCanvas7");
        var canvOK7 = 1;
        try { g.getContext("2d"); }
        catch (er) { canvOK7 = 0; }
        if (canvOK7 === 1) {
            var ctx7 = g.getContext("2d");
            // Create gradient
            var grd7 = ctx7.createRadialGradient(75, 50, 5, 90, 60, 100);
            grd7.addColorStop(0, "red");
            grd7.addColorStop(1, "white");

            // Fill with gradient
            ctx7.fillStyle = grd7;
            ctx7.fillRect(10, 10, 150, 80);
        }
    }, []);

    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html5_canvas.asp"} />
            <h2 className='header-tutorial'>HTML Canvas Grafikasi</h2>
            <NavButton back='/html/html_input_form' next='/html/html_svg' />
            <hr />
            <div>
                <div>
                    <canvas id="myCanvas" width="270" height="160" style={{ border: "1px solid #c3c3c3", float: "left", marginRight: "20px", marginBottom: "15px" }}>
                        Sizning brauzeringiz &lt;canvas&gt; elementini qo'llab-quvvatlamaydi.
                    </canvas>
                </div>
                <div>
                    <TextContent header={""}
                        text1={"HTML <canvas> elementi veb sahifada grafika chizish uchun ishlatiladi."}
                        redWords1={["<canvas>"]}
                        text2={"Chapda tarafdagi grafika <canvas> bilan yaratilgan. Unda to'rtta element ko'rsatilgan: qizil to'rtburchak, gradient to'rtburchak, rang-barang to'rtburchak va rang-barang matn."}
                        redWords2={["<canvas>"]} />
                </div>
            </div>
            <hr />
            <TextContent header={"HTML Canvas nima?"}
                text1={"HTML <canvas> elementi JavaScript orqali grafikani tezda chizish uchun ishlatiladi."}
                redWords1={["<canvas>"]}
                text2={"<canvas> elementi faqat grafika uchun konteynerdir. Grafika chizish uchun JavaScriptdan foydalanishingiz kerak."}
                redWords2={["<canvas>"]}
                text3={"Canvasda yo'llar, qutilar, doiralar, matn kabilarni chizish va tasvirlar qo'shishning bir necha usullari mavjud."}
                redWords3={[""]} />
            <hr />
            <BrowserTable text={"Jadvaldagi raqamlar elementni to'liq qo'llab-quvvatlaydigan birinchi brauzer versiyasini ko'rsatadi <canvas>."} element={"<canvas>"} chrome={"4.0"} edge={"9.0"} firefox={"2.0"} safari={"3.1"} opera={"9.0"} />
            <hr />
            <TextContent header={"Canvas misollari"}
                text1={"Canvas - HTML sahifasining to'rtburchak maydoni hisoblanadi. Default holatda, Canvasning chegarasi va kontenti yo'q."}
                text2={"Yozilishi quyidagicha:"} />
            <div style={{ marginTop: "16px", marginBottom: "16px", border: "1px solid #ccc" }}>
                <CodeMirrorContent value={`<canvas id="myCanvas" width="200" height="100"></canvas>`} />
            </div>
            <Notes type={"Eslatma:"} text={"Canvasda har doim id atributini belgilang(JavaSkriptda foydalanish uchun) va width va height atributi yordamida o'lchamlarini kiriting. Border uchun style atributdan foydalaning."} />
            <br />
            <p>Mana oddiy, bo'sh canvas namunasi:</p>
            <div>
                <canvas id="myCanvas" width="200" height="100" style={{ border: "1px solid #000000" }}>
                </canvas>
            </div><br />
            <TextContent code1={`<canvas id="myCanvas" width="200" height="100" style="border:1px solid #000000;">
</canvas>`} path1={"try/413"} />
            <hr />
            <TextContent header={"JavaScript qo'shish"}
                text1={"To'rtburchak canvas maydonini yaratgandan keyin, unga chizish uchun JavaScript qo'shish kerak."}
                redWords1={["JavaScript"]}
                text2={"Mana bir nechta misollar:"} />
            <h3 className='header-content header-h3'>Chiziq chizish</h3>
            <div>
                <canvas id="myCanvas2" width="200" height="100" style={{ border: "1px solid #d3d3d3" }}>
                    Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.
                </canvas>
            </div>
            <br />
            <TextContent code1={`<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
ctx.moveTo(0, 0);
ctx.lineTo(200, 100);
ctx.stroke();
</script>`} path1={"try/414"} />
            <hr />
            <h3 className='header-content header-h3'>Doira chizish</h3>
            <div>
                <canvas id="myCanvas3" width="200" height="100" style={{ border: "1px solid #d3d3d3" }}>
                    Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.
                </canvas>
            </div>
            <br />
            <TextContent code1={`<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
ctx.beginPath();
ctx.arc(95, 50, 40, 0, 2 * Math.PI);
ctx.stroke();
</script>`} path1={"try/415"} />
            <hr />
            <h3 className='header-content header-h3'>Matn chizish</h3>
            <div>
                <canvas id="myCanvas4" width="200" height="100" style={{ border: "1px solid #d3d3d3" }}>
                    Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.
                </canvas>
            </div>
            <br />
            <TextContent code1={`<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
ctx.font = "30px Arial";
ctx.fillText("Hello World", 10, 50);
</script>`} path1={"try/416"} />
            <hr />
            <h3 className='header-content header-h3'>Hoshiyali matn</h3>
            <div>
                <canvas id="myCanvas5" width="200" height="100" style={{ border: "1px solid #d3d3d3" }}>
                    Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.
                </canvas>

            </div><br />
            <TextContent code1={`<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
ctx.font = "30px Arial";
ctx.strokeText("Hello World", 10, 50);
</script>`} path1={"try/417"} />
            <hr />
            <h3 className='header-content header-h3'>Chiziqli gradient chizish</h3>
            <div>
                <canvas id="myCanvas6" width="200" height="100" style={{ border: "1px solid #d3d3d3" }}>
                    Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.
                </canvas>
            </div>
            <br />
            <TextContent code1={`<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");

// Create gradient
var grd = ctx.createLinearGradient(0, 0, 200, 0);
grd.addColorStop(0, "red");
grd.addColorStop(1, "white");

// Fill with gradient
ctx.fillStyle = grd;
ctx.fillRect(10, 10, 150, 80);
</script>`} path1={"try/418"} />
            <hr />
            <h3 className='header-content header-h3'>Doiraviy gradientni chizish</h3>
            <div>
                <canvas id="myCanvas7" width="200" height="100" style={{ border: "1px solid #d3d3d3" }}>
                    Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.
                </canvas>
            </div>
            <br />
            <TextContent code1={`
        <script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");

// Create gradient
var grd = ctx.createRadialGradient(75, 50, 5, 90, 60, 100);
grd.addColorStop(0, "red");
grd.addColorStop(1, "white");

// Fill with gradient
ctx.fillStyle = grd;
ctx.fillRect(10, 10, 150, 80);
</script>`} path1={"try/419"} code2={`<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
var img = document.getElementById("scream");
ctx.drawImage(img, 10, 10);
</script>`} path2={"try/420"} />
            <hr />
            <TextContent header={"HTML Canvas darslik"} />
            <NoteWhite text={"Canvas haqida ko'proq bilish uchun"} text1={"Canvas darslarini"} text2={"o'qing."} path={"/graphics/canvas_intro"} />
            <hr />

            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="X9jpHOcuXOU" />
            <NavButton back='/html/html_input_form' next='/html/html_svg' />
        </div>)
}

export default Canvas;