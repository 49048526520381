import React from 'react';
import { Link } from 'react-router-dom';
import CodeContent from '../../../tryit/CodeContent';
import Collapsible from '../../pages/Collapsible';
import W3Path from '../../W3Path';
import '../Info.css';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
function HtmlColor() {
      return (
            <article className='info-wrapper'>
                  <W3Path path={"https://www.w3schools.com/html/html_colors.asp"} />
                  <h2 className='header-tutorial'>Ranglar</h2>
                  <NavButton back='/html/html_comments' next='/html/html_colors_rgb' />
                  <hr />
                  <p className='text-tutorial'>HTML da ranglarni 2 xil usulda ifodalash mumkin: </p>
                  <ul className='lists'>
                        <li className='item'><p>ranglarning inglizcha nomi orqali;</p></li>
                        <li className='item'><p>ranglarni maxsus kodlarda ifodalash orqali.</p></li>
                  </ul>
                  <p className='text-tutorial'>Masalan, siz tugmani qizil rangda ko`rsatmoqchisiz. Buning uchun style attributida rangni <b><i>"background-color:red;"</i></b> yoki  <b><i>"background-color:#FF0000;"</i></b> deb ko`rsatish mumkin. Birinchida rang nomidan foydalandik, ikkinchisida rangni maxsus kod orqali ifodaladik. </p>
                  <p className='text-tutorial'>Ranglarni kod shaklida ko`rsatishning ham bir necha usullari bor. Buning uchun ranglarni kodlash tizimlari ishlab chiqilgan.
                        Ulardan keng tarqalganlari <span className='text-tutorial__red'>RGB, HEX, HSL, RGBA</span> yoki <span className='text-tutorial__red'>HSLA</span>  rangni kodlash tizimlaridir.</p>
                  <p className='text-tutorial'>Quyida biz shu usullar bilan bafasil tanishamiz. </p>
                  <hr />
                  <h3 className='header-content'>Rang nomlari</h3>
                  <p className='text-tutorial'>HTML rang qiymati ingliz tilidagi rang nomi yordamida berish mumkin:</p>
                  <div style={{ marginBottom: "30px", }}>
                        <div style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", fontSize: "20px", }}>
                              <div style={{ backgroundColor: "Tomato", minWidth: "20%", margin: "10px", padding: "20px" }}><p>Tomato</p></div>
                              <div style={{ backgroundColor: "Orange", minWidth: "20%", margin: "10px", padding: "20px" }}><p>Orange</p></div>
                              <div style={{ backgroundColor: "DodgerBlue", minWidth: "20%", margin: "10px", padding: "20px" }}><p>DodgerBlue</p></div>
                              <div style={{ backgroundColor: "MediumSeaGreen", minWidth: "20%", margin: "10px", padding: "20px" }}><p>MediumSeaGreen</p></div>
                              <div style={{ backgroundColor: "Gray", minWidth: "20%", margin: "10px", padding: "20px" }}><p>Gray</p></div>
                              <div style={{ backgroundColor: "SlateBlue", minWidth: "20%", margin: "10px", padding: "20px" }}><p>SlateBlue</p></div>
                              <div style={{ backgroundColor: "Violet", minWidth: "20%", margin: "10px", padding: "20px" }}><p>Violet</p></div>
                              <div style={{ backgroundColor: "LightGray", minWidth: "20%", margin: "10px", padding: "20px" }}><p style={{ color: "#000" }}>LightGray</p></div>
                        </div>
                        <Link className='button btn btn--large green' to={`try/52`} target={"_blank"}>Sinab ko'rish</Link>
                  </div>
                  <p className='text-tutorial'> Html
                        <Link className='text-tutorial text-tutorial__link' to={'/colors/colors_names'}>140 xil rang nomlarini  </Link> qo'llab-quvvatlaydi .
                  </p>
                  <hr />
                  <h3 className='header-content'>Fon rangi</h3>
                  <p className='text-tutorial'>HTML elementlari orqa fon rangini o'zgartirish mumkin:</p>
                  <div style={{ color: "#fff", marginBottom: "20px" }}>
                        <h2 style={{ backgroundColor: "DodgerBlue", textAlign: "center" }}>Salom Dunyo</h2>

                        <p style={{ backgroundColor: "Tomato", padding: "20px" }}>
                              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                              Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                        </p>
                  </div>
                  <CodeContent props={`<h1 style="background-color:DodgerBlue;">Hello World</h1>

<p style="background-color:Tomato;">Lorem ipsum...</p>`} pathname={`try/53`} />
                  <hr />
                  <h3 className='header-content'>Matn rangi</h3>
                  <p className='text-tutorial'>Html elementlariga strillar berish orqali matn rangini ham o'zgartirish mumkin.</p>
                  <div>
                        <h3 className='header-content' style={{ color: "Tomato" }}>Hello World</h3>

                        <p className='text-tutorial' style={{ color: "DodgerBlue" }}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>

                        <p className='text-tutorial' style={{ color: "MediumSeaGreen" }}>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>
                  </div>
                  <CodeContent props={`<h1 style="color:Tomato;">Hello World</h1>
<p style="color:DodgerBlue;">Lorem ipsum...</p>
<p style="color:MediumSeaGreen;">Ut wisi enim...</p>`} pathname={`try/54`} />
                  <hr />
                  <h3 className='header-content'>Chegara rangi</h3>
                  <p className='text-tutorial'>Html elementlar chegara rangini quyidagicha o'zgartirish mumkin:</p>
                  <div>
                        <h1 style={{ border: "2px solid Tomato" }}>Hello World</h1>
                        <h1 style={{ border: "2px solid DodgerBlue" }}>Hello World</h1>
                        <h1 style={{ border: "2px solid Violet" }}>Hello World</h1>
                  </div>
                  <CodeContent props={`<h1 style="border: 2px solid Tomato;">Hello World</h1>
<h1 style="border: 2px solid DodgerBlue;">Hello World</h1>
<h1 style="border: 2px solid Violet;">Hello World</h1>`} pathname={`try/55`} />
                  <hr />
                  <h3 className='header-content'>Rang qiymatlari</h3>
                  <p className='text-tutorial'>HTMLda ranglar RGB qiymatlari, HEX qiymatlari, HSL qiymatlari, RGBA qiymatlari va HSLA qiymatlari yordamida ham belgilanishi mumkin.</p>
                  <p className='text-tutorial'>Quyidagi uchta &lt;div&gt; elementlariga RGB, HEX va HSL qiymatlari yordamida orqa fon berilgan:</p>
                  <div style={{ textAlign: "center", color: "#fff" }}>
                        <div style={{ backgroundColor: "rgb(255, 99, 71)" }}><h1>rgb(255, 99, 71)</h1></div>
                        <div style={{ backgroundColor: "#ff6347" }}><h1 >#ff6347</h1></div>
                        <div style={{ backgroundColor: "hsl(9, 100%, 64%)" }}><h1 >hsl(9, 100%, 64%)</h1></div>
                        <p className='text-tutorial' style={{ textAlign: "left" }}>Quyidagi ikkita &lt;div&gt; elementlari orqa fon rangi RGBA va HSLA qiymatlari bilan berilgan boʻlib, Rgb va Hsl qiymatiga Alfa kanalini qoʻshadi (bu yerda shaffofligi 50%):</p>
                        <div style={{ backgroundColor: "rgba(255, 99, 71, 0.5)" }}><h1 >rgba(255, 99, 71, 0.5)</h1></div>
                        <div style={{ backgroundColor: "hsla(9, 100%, 64%, 0.5) " }}><h1 >hsla(9, 100%, 64%, 0.5)</h1></div>
                  </div>
                  <CodeContent props={`<h1 style="background-color:rgb(255, 99, 71);">...</h1>
<h1 style="background-color:#ff6347;">...</h1>
<h1 style="background-color:hsl(9, 100%, 64%);">...</h1>

<h1 style="background-color:rgba(255, 99, 71, 0.5);">...</h1>
<h1 style="background-color:hsla(9, 100%, 64%, 0.5);">...</h1>`} pathname={`try/56`} />

                  <div className='note-content'>
                        <p className='text-tutorial'>Keyingi boblarda <Link className='table-link' to={'/html/html_colors_rgb'}>RGB</Link>,<Link className='table-link' to={'/html/html_colors_hex'}>HEX </Link> va <Link className='table-link' to={'/html/html_colors_hsl'}>HSL </Link> haqida ko'proq bilib olasiz .</p>
                  </div>
                  <hr />
                  <h3 className='header-content'>Dars videosi:</h3>
                  <YoutubeEmbed embedId="yeSgP7EAmSA" />
                  <Collapsible>
                        <p className='text-tutorial'>Quyidagi maket web sahifasi yaratilsin:</p>
                        <a href='https://www.figma.com/file/RXZw1jLs7WLmv8YAj32w1U/colors?node-id=0%3A1&t=P954K9RFeemSJrZf-0' target={'_blank'} rel="noreferrer"> https://www.figma.com/file/RXZw1jLs7WLmv8YAj32w1U/colors?node-id=0%3A1&t=P954K9RFeemSJrZf-0</a>
                  </Collapsible>
                  <NavButton back='/html/html_comments' next='/html/html_colors_rgb' />
            </article>)
}

export default HtmlColor;