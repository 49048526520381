import React from "react";
import PropTypes from "prop-types";
import '../../css/components/header.css';
import Download from "../Download";
import { TiHtml5 } from 'react-icons/ti';
import { VscColorMode, VscTypeHierarchy } from 'react-icons/vsc';
import { MdSettingsBackupRestore } from 'react-icons/md';



function Header(props) {


  return (
    <header className="code-header">
      <h1 className="code-logo">
        Self<span>Study</span>
      </h1>
      <nav className="code-nav">
        <ul className="code-lists">
          <li className="code-item">
            <Download handleDownload={props.onDownload} />
          </li>
          <li className="code-item code-choose">
            <button type="button" onClick={props.handleClick} className='code-btn'>
              {props.click ? <VscTypeHierarchy /> : <TiHtml5 />}
            </button>
          </li>
          <li className="code-item">
            <button type="button" onClick={props.handleThemeChange} className='code-btn'>
              <VscColorMode />
            </button>
          </li>
          <li className="code-item">
            <button type="button" onClick={props.update} className='code-btn'>
              <MdSettingsBackupRestore />
            </button>
          </li>
        </ul>
      </nav>


    </header>
  );
}

Header.propTypes = {
  onDownload: PropTypes.func,
};

Header.defaultProps = {
  onDownload: () => { },
};

export default Header;