import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import { Link } from 'react-router-dom';
import ListText from '../../pages/ListsText';
import Table from '../../pages/Table';
import CodeMirrorContent from '../../../tryit/CodeContent/CodeMirrorContent';


function ComputerCode() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_computercode_elements.asp"} />
            <h2 className='header-tutorial'>Kompyuter kodi elementlari</h2>
            <NavButton back='/html/html_responsive' next='/html/html5_semantic_elements' />
            <hr />

            <p>HTML veb-sahifasida klaviatura belgilarini va kompyuter kodini kiritish uchun bir nechta elementlar mavjud.</p>
            <TextContent code1={`<code>
x = 5;
y = 6;
z = x + y;
</code>`} path1={"try/266"} />
            <hr />
            <TextContent header={"Klaviatura uchun <kbd> elementi"}
                text1={"HTML <kbd> elementi klaviatura belgilarini kiritish uchun ishlatiladi. Element ichidagi tarkib brauzerning standart monospace shriftida ko'rsatiladi."}
                redWords1={["<kbd>"]} />
            <div className='code-content'>
                <h4 className='header-code'>Misol:</h4>
                <p>Hujjatdagi matn ichida klaviatura belgilarini kiritish:</p>
                <div className='code-wrapper'>
                    <CodeMirrorContent value={`
<p>Hujjatni saqlash uchun  <kbd>Ctrl + S</kbd> ni bosing</p>`} />
                </div>
                <h4 className='header-code'>Natija:</h4>
                <div className='code-wrapper'>
                    <p style={{ margin: "0" }}>Hujjatni saqlash uchun  <kbd>Ctrl + S</kbd> ni bosing</p>
                </div>
                <Link className='button btn btn--large green' to={"try/267"} target={"_blank"}>Sinab ko'rish</Link>
            </div>
            <hr />
            <TextContent header={"HTML dastur natijasi uchun <samp> elementi"}
                text1={"HTML <samp> elementi kompyuter dasturi natijasini ajratib ko'rsatish uchun ishlatiladi. Element ichidagi tarkib brauzerning standart monospace shriftida ko'rsatiladi."}
                redWords1={["<samp>"]} />
            <div className='code-content'>
                <h4 className='header-code'>Misol:</h4>
                <p>Veb-sahifada kompyuter programmasining natijasini ko'rsatish:</p>
                <div className='code-wrapper'>
                    <CodeMirrorContent value={`
<p>Mening kompyuterimdagi xabar:</p>
<p><samp>File not found.<br>Press F1 to continue</samp></p> `} />
                </div>
                <h4 className='header-code'>Natija:</h4>
                <div className='code-wrapper'>
                    <p>Mening kompyuterimdagi xabar:</p>
                    <p><samp>File not found.<br />Press F1 to continue</samp></p>
                </div>
                <Link className='button btn btn--large green' to={"try/268"} target={"_blank"}>Sinab ko'rish</Link>
            </div>
            <hr />
            <TextContent header={"Dasturlash kodi uchun <code> elementi"}
                text1={"HTML <code> elementi kompyuter kodini kiritish uchun ishlatiladi. Element ichidagi tarkib brauzerning standart monospace shriftida ko'rsatiladi."}
                redWords1={["<code>"]} />
            <div className='code-content'>
                <h4 className='header-code'>Misol:</h4>
                <p>Hujjatdagi matnni kompyuter kodi sifatida kiritish:</p>
                <div className='code-wrapper'>
                    <CodeMirrorContent value={`
<code>
x = 5;
y = 6;
z = x + y;
</code>`} />
                </div>
                <h4 className='header-code'>Natija:</h4>
                <div className='code-wrapper'>
                    <code>
                        x = 5;
                        y = 6;
                        z = x + y;
                    </code>
                </div>
                <Link className='button btn btn--large green' to={"try/269"} target={"_blank"}>Sinab ko'rish</Link>
            </div>
            <TextContent
                text1={"E'tibor bering, <code> elementi qo'shimcha bo'sh joylarni o'chiradi va yangi qatordan yozilgan bo'lsa ham hamma kodlarni ketma-ket yozib ketadi."}
                redWords1={["<code>"]}
                text2={"Buni tuzatish uchun <code> elementini <pre> element ichiga yozish kerak:"}
                redWords2={["<code>", "<pre>"]} />
            <div className='code-content'>
                <h4 className='header-code'>Misol:</h4>
                <div className='code-wrapper'>
                    <CodeMirrorContent value={`
<pre>
<code>
x = 5;
y = 6;
z = x + y;
</code>
</pre>`} />
                </div>
                <h4 className='header-code'>Natija:</h4>
                <div className='code-wrapper'>
                        <code>
                            x = 5;<br/>
                            y = 6;<br/>
                            z = x + y;
                        </code>
                </div>
                <Link className='button btn btn--large green' to={"try/271"} target={"_blank"}>Sinab ko'rish</Link>
            </div>
            <hr />
            <TextContent header={"HTML <var> elementi"}
                text1={"HTML <var> elementi dasturlashda yoki matematik ifodadagi o'zgaruvchini ifodalash uchun ishlatiladi. Element ichidagi tarkib odatda kursiv bilan ko'rsatiladi."}
                redWords1={["<var>"]} />
            <div className='code-content'>
                <h4 className='header-code'>Misol:</h4>
                <p>Hujjatdagi matnda o'zgaruvchilarni ajratib ko'rsatish:</p>
                <div className='code-wrapper'>
                    <CodeMirrorContent value={`
<p>Uchburchakning maydoni: 1/2 x <var>b</var> x <var>h</var>, bu yerda <var>b</var> - asos, and <var>h</var> - vertikal balandlik.</p>`} />
                </div>
                <h4 className='header-code'>Natija:</h4>
                <div className='code-wrapper'>
                    <p>Uchburchakning maydoni: 1/2 x <var>b</var> x <var>h</var>, bu yerda <var>b</var> - asos, and <var>h</var> - vertikal balandlik.</p>
                </div>
                <Link className='button btn btn--large green' to={"try/270"} target={"_blank"}>Sinab ko'rish</Link>
            </div>
            <hr />

            <TextContent header={"Bo'lim xulosasi"} />
            <ListText list={[{
                text: "<kbd> elementi  klaviatura kiritilishi kerak bo'lgan klavishlarni ifodalaydi.",
                redWords: ["<kbd>"]
            },
            {
                text: "<samp> elementi kompyuter dasturi natijasini ko'rsatish uchun ishlatiladi.",
                redWords: ["<samp>"]
            },
            {
                text: "<code> elementi kompyuter kodini kiritish uchun ishlatiladi.",
                redWords: ["<code>"]
            },
            {
                text: "<var> elementi dasturlashda yoki matematik ifodadagi o'zgaruvchilar uchun ishlatiladi.",
                redWords: ["<var>"]
            },
            {
                text: "<pre> elementi  oldindan formatlangan matnni belgilaydi.",
                redWords: ["<pre>"]
            },]} />
            <hr />
            <Table th={["Teg", "Tavsif"]} values={[
                { id: 0, first: "<code>", second: "Dasturlash kodini belgilaydi.", path: "/tags/tag_code" },
                { id: 1, first: "<kbd>", second: "Klaviatura klavishlarini belgilaydi.", path: "/tags/tag_kbd" },
                { id: 2, first: "<samp>", second: "Dastur natijasini belgilaydi.", path: "/tags/tag_samp" },
                { id: 3, first: "<var>", second: "O'zgaruvchini belgilaydi.", path: "/tags/tag_var" },
                { id: 4, first: "<pre>", second: "Oldindan formatlangan matnni belgilaydi.", path: "/tags/tag_pre" },
                ]} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="88NF1toZYpw" />
            <NavButton back='/html/html_responsive' next='/html/html5_semantic_elements' />
        </article>)
}

export default ComputerCode;