import React from 'react';
import Notes from '../../../pages/Notes';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function TableHeader() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_table_headers.asp"} />
            <h2 className='header-tutorial'>HTML jadval sarlavhalari</h2>
            <NavButton back='/html/html_table_sizes' next='/html/html_table_padding_spacing' />
            <hr />
            <TextContent text1={"HTML jadvallarda har bir qator yoki har bir ustun uchun sarlavhalar (headerlar) belgilash mumkin. Bundan tashqari, bir nechta qator uchun bitta header, xuddi shuningdek, bir nechta ustun uchun bitta header ham korsatishimiz mumkin."} />
            <hr />
            <div style={{ display: "flex", justifyContent: 'space-around' }}>
                <table className="table2" style={{ width: "20%" }}>
                    <tbody><tr>
                        <th>EMIL</th>
                        <th>TOBIAS</th>
                        <th>LINUS</th>
                    </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
                <table class="table2" style={{ width: "20%" }}>
                    <tbody><tr>
                        <th>8:00</th>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                        <tr>
                            <th>9:00</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>10:00</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>11:00</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>12:00</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>13:00</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
                <table class="table2" style={{ width: "30%" }}>
                    <tbody><tr>
                        <th style={{ width: "16%" }}></th>
                        <th style={{ width: "16%" }}>MON</th>
                        <th style={{ width: "16%" }}>TUE</th>
                        <th style={{ width: "16%" }}>WED</th>
                        <th style={{ width: "16%" }}>SHU</th>
                        <th style={{ width: "16%" }}>FRI</th>
                    </tr>
                        <tr>
                            <th>8:00</th>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>9:00</th>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>10:00</th>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>11:00</th>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>12:00</th>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                            <td style={{ width: "16%" }}>&nbsp;</td>
                        </tr>
                    </tbody></table>
                <table class="table2" style={{ width: "20%" }}>
                    <tbody><tr>
                        <th colspan="3">DEKABR</th>
                    </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
            </div>
            <hr />
            <TextContent header={"HTML jadval sarlavhalari"}
                text1={"Jadval sarlavhalari th elementlar bilan belgilanadi. Har bir th element jadval katakchasini ifodalaydi."}
                code1={`<table>
<tr>
  <th>Familiya</th>
  <th>Ism</th>
  <th>Yosh</th>
</tr>
<tr>
  <td>Jill</td>
  <td>Smith</td>
  <td>50</td>
</tr>
<tr>
  <td>Eve</td>
  <td>Jackson</td>
  <td>94</td>
</tr>
</table>`} path1={"try/178"} />
            <TextContent header={"Jadvalning vertikal sarlavhasi"} text1={"Birinchi ustunni jadval sarlavhalari sifatida ishlatish uchun har bir qatordagi birinchi katakcha <th> elementi bilan belgilanadi:"}
                redWords1={["<th>"]}
                code1={`<table>
<tr>
  <th>Firstname</th>
  <td>Jill</td>
  <td>Eve</td>
</tr>
<tr>
  <th>Lastname</th>
  <td>Smith</td>
  <td>Jackson</td>
</tr>
<tr>
  <th>Age</th>
  <td>94</td>
  <td>50</td>
</tr>
</table>`} path1={"try/179"} />
            <hr />
            <TextContent header={"Jadval sarlavhalarini tekislash"}
                text1={"Default holatda jadval sarlavhalari qalin va markazlashtirilgan bo'ladi:"} />
            <table class="table9" style={{ width: "50%", marginBottom: "20px" }}>
                <tbody><tr>
                    <th>Ism</th>
                    <th>Familiya</th>
                    <th>Yosh</th>
                </tr>
                    <tr>
                        <td>Jill</td>
                        <td>Smit</td>
                        <td>50</td>
                    </tr>
                    <tr>
                        <td>Momo Havo</td>
                        <td>Jekson</td>
                        <td>94</td>
                    </tr>
                </tbody></table>
            <TextContent text1={"Jadval sarlavhalarini chap tomonga tekislash uchun CSS text-align xususiyatidan foydalaniladi:"}
                redWords1={["text-align"]}
                css1={`th {
  text-align: left;
}`} path1={"try/180"} />
            <hr />
            <TextContent header={"Bir nechta ustunlar uchun sarlavha"}
                text1={"Ikki yoki undan ortiq ustundan iborat sarlavha ham yaratish mumkin."} />
            <table class="table9" style={{ width: "50%", marginBottom: "20px" }}>
                <tbody><tr>
                    <th colspan="2">Ism</th>
                    <th>Yosh</th>
                </tr>
                    <tr>
                        <td>Jill</td>
                        <td>Smit</td>
                        <td>50</td>
                    </tr>
                    <tr>
                        <td>Momo Havo</td>
                        <td>Jekson</td>
                        <td>94</td>
                    </tr>
                </tbody></table>
            <TextContent text1={"Buning uchun <th> elementidagi colspan atributidan foydalaniladi:"}
                redWords1={["<th>", "colspan"]}
                code1={`<table>
<tr>
  <th colspan="2">Ism</th>
  <th>Yosh</th>
</tr>
<tr>
  <td>Jill</td>
  <td>Smith</td>
  <td>50</td>
</tr>
<tr>
  <td>Eve</td>
  <td>Jackson</td>
  <td>94</td>
</tr>
</table>`} path1={"try/181"} />
            <Notes text="Colspan va rowspan haqida ko'proq " path={"/html/html_table_colspan_rowspan"} text1="Colspan va Rowspan" text2={" bo'limida bilib olasiz."} />
            <hr />
            <TextContent header={"Caption"} />
            <TextContent text1={"Butun jadval uchun umumiy sarlavha berish mumkin."} />
            <table class="table9" style={{ width: "50%", marginBottom: "20px" }}>
                <caption>Oylik tejash</caption>
                <tbody><tr>
                    <th>Oy</th>
                    <th>Tejamkorlik</th>
                </tr>
                    <tr>
                        <td>Yanvar</td>
                        <td>100 dollar</td>
                    </tr>
                    <tr>
                        <td>fevral</td>
                        <td>50 dollar</td>
                    </tr>
                </tbody></table>
            <TextContent text1={"Jadvalga umumiy sarlavha qo'shish uchun <caption> tegidan foydalaniladi:"} redWords1={["<caption>"]}
                code1={`<table style="width:100%">
  <caption>Oylik Jamg'arma</caption>
  <tr>
    <th>Oy</th>
    <th>Savings</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td>$50</td>
  </tr>
</table>`} path1={"try/182"} />
            <Notes type={"Eslatma"} text={"<caption> tegi <table> tegidan keyin darhol kiritilishi kerak."} />
            <hr />
            <TextContent header={"Dars videosi:"} />
            <YoutubeEmbed embedId="0xPGAZmuYB8" />
            <NavButton back='/html/html_table_sizes' next='/html/html_table_padding_spacing' />
        </article>)
}

export default TableHeader;