import React from 'react';
import { Link } from 'react-router-dom';
import CodeContent from '../../../../tryit/CodeContent';
import NavButton from '../../NavButton';
import '../../Info.css';
import CodeMirrorContent from '../../../../tryit/CodeContent/CodeMirrorContent';
import YoutubeEmbed from '../../YoutubeEmbed';
import Table from '../../../pages/Table';
import W3Path from '../../../W3Path';

function Links() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_links.asp"} />
      <h2 className='header-tutorial'>HTML havolalari</h2>
      <NavButton back='/html/html_css' next='/html/html_links_colors' />
      <hr />
      <p className='text-tutorial text-tutorial-margin'>Deyarli hamma veb-sahifalarda havolalar ishlatiladi. Havola sahifadan sahifaga o'tishga yordam beradi.</p>
      <hr />
      <h3 className='header-content'>Giperhavola</h3>
      <p className='text-tutorial'>HTML havolalari giperhavolalardir(Giperhavola - bu Internetdagi boshqa manbalarga havola).</p>
      <p className='text-tutorial'>Havolani bosish orqali boshqa hujjatga o'tish mumkin. </p>
      <p className='text-tutorial'>Sichqonchani havola ustiga olib borganingizda, strelka shaklidagi sichqoncha belgisi qo'lga shakliga aylanadi. </p>
      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong>Havola matn bo'lishi shart emas. Havola rasm yoki boshqa HTML elementi bo'lishi mumkin!</p></div>
      <hr />

      <h3 className='header-content'>HTML havolalari sintaksisi</h3>
      <p className='text-tutorial'>HTML <span className='text-tutorial__red'>&lt;a&gt;</span> tegi yordamida giperhavola yaratiladi. U quyidagi sintaksisga ega:</p>

      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`<a href="url">Havola matni</a>`} />
        </div>
      </div>
      <p className='text-tutorial'> <span className='text-tutorial__red'>&lt;a&gt;</span> elementning eng muhim atributi <span className='text-tutorial__red'>href</span> hisoblanadi, u havolaning manzilini ko'rsatadi.</p>
      <p className='text-tutorial'> <i>Havola matni</i> foydalanuvchiga ko'rinadigan qismdir .</p>
      <p className='text-tutorial'>Havola matnini bosish orqali belgilangan URL manziliga o'tish mumkin. </p>
      <CodeContent props={`<a href="https://self-study.uz">self-study.uz saytiga o'tish</a>`} pathname={`try/10`} text={"Ushbu misol self-study.uz saytiga qanday qilib havola yaratilishi ko'rsatilgan:"} />
      <p className='text-tutorial'> Barcha brauzerlarda havolalar quyidagicha default holatida quyidagi ko'rinishga ega:</p>
      <ul className='lists'>
        <li className='item'><p>Ko'rilmagan havola tagiga chizilgan va ko'k rangda bo'ladi.</p></li>
        <li className='item'><p>Tashrif buyurilgan havolaning tagiga chizilgan va binafsha rang bo'ladi.</p></li>
        <li className='item'><p>Faol havolaning tagiga chizilgan va qizil rangda bo'ladi.</p></li>
      </ul>
      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong> Ko'rinishini o'zgartirish uchun havolalarga  CSS stillari berish mumkin!</p></div>
      <hr />
      <h3 className='header-content'>Target atributi</h3>
      <p className='text-tutorial'>Biriktirilgan sahifa default holatda joriy brauzer oynasida ko'rsatiladi. Buni o'zgartirish uchun havola  <span className='text-tutorial__red'>target</span>  atributidan foydaniladi.</p>
      <p className='text-tutorial'>  <span className='text-tutorial__red'>target</span>  atributi biriktirilgan hujjatni qayerda ochishni belgilaydi. </p>
      <p className='text-tutorial'> <span className='text-tutorial__red'>target</span> atributi quyidagi qiymatlarga ega bo'lishi mumkin:</p>
      <ul className='lists'>
        <li className='item'><p><span className='text-tutorial__red'>_self</span>- default holat hisoblanadi. Hujjatni joriy oyna/tab da ochadi.</p></li>
        <li className='item'> <p><span className='text-tutorial__red'>_blank</span>- Hujjatni yangi oyna yoki yorliqda ochadi</p></li>
        <li className='item'><p><span className='text-tutorial__red'>_parent</span>- Hujjatni asosiy ramkada ochadi</p></li>
        <li className='item'><p><span className='text-tutorial__red'>_top</span>- Hujjatni oynaning to'liq qismida ochadi</p></li>
      </ul>
      <CodeContent props={`<a href="https://self-study.uz/" target="_blank">self-study.uzga o'tish!</a>`} pathname={`try/78`} text={"biriktirilgan hujjatni yangi brauzer oynasida yoki tabda ochish uchun target='_blank' dan foydalaniladi:"} />
      <hr />
      <h3 className='header-content'>Absolyut URL va Relyativ URL</h3>
      <p className='text-tutorial'> Quyidagi misolda ikkala <span className='text-tutorial__red'>href</span> atributiga <b>Absolyut URL</b> (to'liq veb-manzil) qiymati berildi.</p>
      <p className='text-tutorial'>Lokal havola (xuddi shu veb-saytdagi sahifaga havola) <b>relyativ URL</b> bilan ko'rsatiladi ("https://www" qismisiz):</p>
      <CodeContent props={`<h2>Absolyut URL</h2>
<p><a href="https://self-study.uz/">Self-study sayti</a></p>
<p><a href="https://www.google.com/">Google</a></p>

<h2>Relyativ URL</h2>
<p><a href="/html/html_css">Css mavzusi</a></p>
<p><a href="/html/html_images">Rasmlar mavzusi</a></p>`} pathname={`try/79`} text={""} />
      <hr />
      <h3 className='header-content'>Rasmdan havola sifatida foydalanish</h3>
      <p className='text-tutorial'> Rasmni havola sifatida ishlatish uchun  <span className='text-tutorial__red'>&lt;img&gt;</span> tegini <span className='text-tutorial__red'>&lt;a&gt;</span> teg ichiga qo'yish kifoya:</p>
      <CodeContent props={`<a href="/html"><img src="smile.png" alt="HTML mavzulari" style="width:42px;height:42px;"></a>`} pathname={`try/80`} />
      <hr />
      <h3 className='header-content'>Elektron pochta manziliga havola</h3>
      <p className='text-tutorial'> Foydalanuvchining elektron pochta dasturini ochadigan(yangi elektron pochta xabarini yuborish uchun) havola yaratish uchun <span className='text-tutorial__red'>href</span> atributi ichida <span className='text-tutorial__red'>mailto:</span> foydalaniladi </p>
      <CodeContent props={`<a href="mailto:someone@example.com">Send email</a>`} pathname={`try/81`} />
      <hr />
      <h3 className='header-content'>Havola tugmasi(button)</h3>
      <p className='text-tutorial'>HTML havola tugmasidan(button) foydalanish uchun ba'zi JavaScript kodlarini qo'shish kerak.</p>
      <p className='text-tutorial'> JavaScript tugma bosilganda nima sodir bo'lishini belgilash imkonini beradi:</p>
      <CodeContent props={`<button onclick="document.location='/html'">HTML mavzulari</button>`} pathname={`try/82`} />
      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong>JavaScript haqida koʻproq maʼlumotni   <Link className='text-tutorial text-tutorial__link' to={'/js'}>JavaScript qoʻllanmamizdan</Link> oʻrganishingiz mumkin .</p></div>
      <h3 className='header-content'>Havola title atributi</h3>
      <p className='text-tutorial'> <span className='text-tutorial__red'>title</span> atribut element haqida qo'shimcha ma'lumot beradi. Ma'lumot ko'pincha sichqonchani element ustida harakatlantirganda ko'rinadi.</p>
      <CodeContent props={`<a href="https://self-study.uz/html" title="Self-study sayti HTML bo'limiga o'tish">Self-study saytiga o'tish</a>`} pathname={`try/83`} />

      <h3 className='header-content'>Absolyut URL va Relyativ URL misollar</h3>
      <CodeContent props={`<p><a href="https://self-study.uz/html/">HTML mavzulari</a></p>`} pathname={`try/84`} text={""} />
      <CodeContent props={`<a href="/html/html_intro">HTML mavzulari</a>`} pathname={`try/85`} text={"Joriy veb-saytdagi html papkasida joylashgan sahifaga havola:"} />
      <CodeContent props={`<a href="/html">Html mavzulari</a>`} pathname={`try/86`} text={"Joriy sahifa bilan bir xil papkada joylashgan sahifaga havola: "} />
      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong>
          Fayl yo'llari haqida ko'proq <Link className='text-tutorial text-tutorial__link' to={'/html/html_filepaths'}> HTML fayl yo'llari</Link>bo'limida o'qishingiz mumkin .</p></div>

      <h3 className='header-content'>Xulosa</h3>
      <ul className='lists'>
        <li className='item'>   <p className='text-tutorial'>Havola uchun <span className='text-tutorial__red'>&lt;a&gt;</span> tegidan foydalaniladi.</p></li>
        <li className='item'>    <p className='text-tutorial'>   <span className='text-tutorial__red'>href</span> atributi havolaning manzilini ko'rsatadi.</p></li>
        <li className='item'><p className='text-tutorial'>Biriktirilgan sahifa qayerda ochishni belgilash uchun <span className='text-tutorial__red'>target</span>  atributidan foydaniladi.</p></li>
        <li className='item'>   <p className='text-tutorial'> Rasmni havola sifatida ishlatish uchun  <span className='text-tutorial__red'>&lt;img&gt;</span> tegini <span className='text-tutorial__red'>&lt;a&gt;</span> tegi ichida yoziladi.</p></li>
        <li className='item'>   <p className='text-tutorial'> Foydalanuvchining elektron pochta dasturini ochadigan(yangi elektron pochta xabarini yuborish uchun) havola yaratish uchun <span className='text-tutorial__red'>href</span> atributi ichida <span className='text-tutorial__red'>"mailto:" dan</span> foydalaniladi. </p></li>
      </ul>
      <Table header={"HTML havola tegi"} th={["Teg", "Tavsif"]} values={[
        { id: 0, first: "a", second: "Giperhavolalar uchun foydalaniladi.", path: "/tags/tag_a" },
      ]} />
      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong>Barcha mavjud HTML teglarining toʻliq roʻyxatini  <Link className='text-tutorial text-tutorial__link' to={'/tags'}>HTML teg maʼlumotnoma</Link> bo'limidan olishingiz mumkin.
        </p></div>
      <hr />
      <h3 className='header-content'>Dars videosi</h3>
      <YoutubeEmbed embedId="RbfqZh5MJbs" />
      <NavButton back='/html/html_css' next='/html/html_links_colors' />
    </article>
  )
}

export default Links;