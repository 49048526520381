import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import ListText from '../../../pages/ListsText';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';


function FormAtribute() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_forms_attributes.asp"} />
            <h2 className='header-tutorial'>HTML formasi atributlari</h2>
            <NavButton back='/html/html_forms' next='/html/html_input' />
            <hr />
            <TextContent text1={"Ushbu bobda HTML <form> elementi atributlari bilan tanishamiz."} redWords1={["<form>"]} />
            <hr />
            <TextContent header={"Action atributi"}
                text1={"action attibuti o`zida forma submit qilinganda ma'lumotlar qayta ishlanadigan URL-manzilni saqlaydi."}
                redWords1={["action"]}
                text2={"Foydalanuvchi buttonni bosganida forma ma'lumotlari serverdagi faylga yuboriladi."}
                text3={"Quyidagi misolda forma ma'lumotlari 'action_page.php' nomli faylga yuboriladi. Ushbu faylda server tomonidan forma ma'lumotlarini boshqaradigan(qayta ishlaydigan) kod yozilgan bo'ladi:"}
                code3={`<form action="/action_page.php">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Submit">
</form>`} code3Text={"Button tugmasi bosilgandan keyin, forma ma'lumotlari “action_page.php” manziliga yuboriladi:"}
                path3={"try/345"}
                text4={"Maslahat: Agar action atributi tushirib qoldirilgan bo'lsa, bajarilishi kerak bo'lgan amallar joriy sahifaga o'rnatiladi."}
                redWords4={["action"]} />
            <hr />
            <TextContent header={"target atributi"}
                text1={"target atributi forma ma'lumotlari yuborilgandan keyin olingan natija(respose)ni qayerda ko'rsatish kerakligini belgilaydi."}
                redWords1={["target"]}
                text2={"target atributi quyidagi qiymatlardan biriga ega bo'lishi mumkin:"}
                redWords2={["target"]} />
            <Table th={["Qiymati", "Tavsif"]} values={[
                { id: 0, first: '_blank', second: "Natija(respose) yangi brauzer oynasi yoki yorlig'ida ko'rsatiladi" },
                { id: 1, first: '_self', second: "Natija(respose) joriy brauzer oynasida ko'rsatiladi" },
                { id: 2, first: '_parent', second: "Natija(respose) ota freymda ko'rsatiladi" },
                { id: 3, first: '_top', second: "Natija(respose) brauzer oynaning to'liq qismida ko'rsatiladi" },
                { id: 4, first: 'framename', second: "Natija(respose) nomi berilgan freymda ko'rsatiladi" }]} />
            <TextContent text1={"Default holatda  _self qiymatiga ega bo'lib, natija(respose) joriy oynada ochilishini bildiradi."}
                redWords1={["_self"]}
                code1={`<form action="/action_page.php" target="_blank">`}
                code1Text={"Bu yerda natija yangi brauzer yorlig'ida ochiladi:"}
                path1={"try/350"} />
            <hr />
            <TextContent header={"method atributi"}
                text1={"method atributi forma ma'lumotlarini yuborishda ishlatiladigan HTTP metodini belgilaydi."}
                redWords1={["method"]}
                text2={"Forma ma'lumotlari URL o'zgaruvchilari sifatida(method='get' yordamida) yoki HTTP post tranzaksiyasi sifatida(method='post' yordamida) yuborilishi mumkin."}
                text3={"GET default HTTP metodi hisoblanadi."}
                code3={`<form action="/action_page.php" method="get">`}
                path3={"try/351"}
                code3Text={"Ushbu misol forma ma'lumotlarini yuborishda GET usulidan foydalanadi:"}
                code4={`<form action="/action_page.php" method="post">`}
                path4={`try/352`} code4Text={"Ushbu misol forma ma'lumotlarini yuborishda POST usulidan foydalanadi:"} />
            <p><b>GET haqida:</b></p>
            <ListText list={[{
                text: "Forma ma'lumotlari URL manziliga nom/qiymat juftliklari ko'rinishida qo'shiladi",
            },
            {
                text: "Maxfiy ma'lumotlarni yuborish uchun HECH QACHON GET metodidan foydalanmang! (chunki yuborilgan shakl ma'lumotlari URL manzilida ko'rinib turadi!)",
            },
            {
                text: "URL uzunligi cheklanganligi uchun(2048 belgi) forma ma'lumotlarini yuborish ham shunga mos ravishda cheklangan.",
            },
            {
                text: "Foydalanuvchi forma natijalarini xatcho'p qilib qo'yishga qulay",
            },
            {
                text: "GET maxfiy bo'lmagan ma'lumotlar uchun yaxshi, masalan, Google so'rovlariga o'xshab",
            },]} />
            <p><b>POST haqida:</b></p>
            <ListText list={[{
                text: "Forma ma'lumotlarini HTTP requestning body qismida yuboradi. (yuborilgan forma ma'lumotlari URLda ko'rsatilmaydi)",
            },
            {
                text: "POST metodda o'lcham cheklovlari yo'q va katta hajmdagi ma'lumotlarni yuborish uchun ishlatilishi mumkin.",
            },
            {
                text: "POST bilan forma murojaatnomalarini xatcho'p qilib bo'lmaydi",
            },]} />
            <Notes type={"Maslahat:"} text={"Agar forma ma'lumotlarida maxfiy yoki shaxsiy ma'lumotlar bo'lsa, har doim POST dan foydalaning!"} />
            <hr />
            <TextContent header={"Avtomatik to'ldirish atributi"}
                text1={"autocomplete atributi shaklda avtomatik to'ldirishni yoqish yoki o'chirish kerakligini belgilaydi."}
                redWords1={["autocomplete"]}
                text2={"Avtomatik to'ldirish yoqilgan(autocomplete='on') bo'lsa, brauzer foydalanuvchi avval kiritgan qiymatlar asosida avtomatik ravishda qiymatlarni to'ldiradi."}
                code2={`<form action="/action_page.php" autocomplete="on">`}
                path2={"try/353"} />
            <hr />
            <TextContent header={"novalidate atributi"}
                text1={"novalidate atributi mantiqiy atributdir."}
                redWords1={["novalidate"]}
                text2={"Yuborish tugmasi bosilganda forma ma'lumotlari(inputdagi) to'g'ri kiritilganligi tekshirilmasligini belgilaydi."}
                code2={`<form action="/action_page.php" novalidate>`}
                path2={"try/354"} />
            <hr />

            <Table th={["Teg", "Tavsif"]} values={[
                { id: 0, first: "accept-charset", second: "Forma ma'lumotlarini yuborish uchun ishlatiladigan belgilar to`plamini ifodalaydi.", path: "/tags/att_form_accept_charset" },
                { id: 1, first: "action", second: "Forma ma'lumotlarini qayerga yuborish kerakligini belgilaydi", path: "/tags/att_form_action" },
                { id: 2, first: "autocomplete", second: "Shaklda avtomatik to'ldirishni yoqish yoki o'chirish kerakligini belgilaydi", path: "/tags/att_form_autocomplete" },
                { id: 3, first: "enctype", second: "Forma ma'lumotlarini serverga yuborishda qanday kodlash kerakligini belgilaydi (faqat method='post' uchun)", path: "/tags/att_form_enctype" },
                { id: 4, first: "method", second: "Forma ma'lumotlarini yuborishda ishlatiladigan HTTP metodini belgilaydi", path: "/tags/att_form_method" },
                { id: 5, first: "name", second: "Forma nomini belgilaydi", path: "/tags/att_form_name" },
                { id: 6, first: "novalidate", second: "Forma ma'lumotlari yuborilganda tekshirilmasligini bildiradi", path: "/tags/att_form_novalidate" },
                { id: 7, first: "rel", second: "Bog'langan manba va joriy hujjat o'rtasidagi munosabatni belgilaydi", path: "/tags/att_form_rel" },
                { id: 8, first: "target", second: "Forma yuborilgandan so'ng olingan natijani qayerda ko'rsatishni belgilaydi", path: "/tags/att_form_target" },]} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="hVafgJ66lac" />
            <NavButton back='/html/html_forms' next='/html/html_input' />
        </div>)
}

export default FormAtribute;