import React from 'react';
import { Link } from 'react-router-dom';

function Table(props) {
    return (
        <>
            <h3 className='header-content'>{props.header}</h3>
            {props.text ? <p className='text-tutorial-margin'>{props.text}</p> : ""}
            <table className='table-content'>
                <tbody>
                    <tr>
                        {props.th[0] ? <th scope="col">{props.th[0]}</th> : ""}
                        {props.th[1] ? <th scope="col">{props.th[1]}</th> : ""}
                        {props.th[2] ? <th scope="col">{props.th[2]}</th> : ""}
                        {props.th[3] ? <th scope="col">{props.th[3]}</th> : ""}
                        {props.th[4] ? <th scope="col">{props.th[4]}</th> : ""}
                        {props.th[5] ? <th scope="col">{props.th[5]}</th> : ""}
                    </tr>
                    {
                        props.values.map((value, index) => {
                            return (
                                <tr key={index}>
                                    <td>{value.path ? <Link className='table-link' to={value.path}>{value.first}</Link> : value.first}</td>
                                    {value.second ? <td>{value.second}</td> : ""}
                                    {value.third ? <td>{value.third}</td> : ""}
                                    {value.four ? <td>{value.four}</td> : ""}
                                    {value.five ? <td>{value.five}</td> : ""}
                                    {value.six ? <td>{value.six}</td> : ""}
                                    {value.tryPath ? <td>
                                        <Link className='btn btn--mobile green' to={value.tryPath} target={"_blank"}>Sinab ko'rish</Link></td> : <></>}
                                </tr>
                            )
                        })

                    }
                </tbody>
            </table>
        </>
    )
}

export default Table;