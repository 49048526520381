import React from 'react';
import Notes from '../../../pages/Notes';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';



function ImagesBg() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_images_background.asp"} />
      <h2 className='header-tutorial'>HTML orqa-fon rasmlari</h2>
      <NavButton back='/html/html_images_imagemap' next='/html/html_images_picture' />
      <TextContent
        text1={'Deyarli barcha HTML elementi orqa-foniga rasm berish mumkin.'} />
      <hr />
      <TextContent header={"HTML elementlari orqa-fon rasmi"}
        text1={"HTML elementi orqa-foniga rasm berish uchun HTML style atributidan va CSS background-image xususiyatidan foydalaniladi:"}
        redWords1={['style', 'background-image']}
        code1={`<p style="background-image: url('img_girl.jpg');">`}
        path1={"try/110"}
        code1Text={"HTML elementi orqa-foniga rasm berish:"}
        text2={" Shuningdek, fon rasmini <style> elementi yordamida  <head> bo'limida ham belgilashingiz  mumkin:"}
        redWords2={['<style>', '<head>']}
        code2={`<style>
  p {
      background-image: url('img_girl.jpg');
    }
</style>`}
        path2={"try/111"}
        code2Text={"<style> element yordamida orqa-fonga rasm berish:"}
      />
      <hr />
      <TextContent header={"Sahifa orqa-fon rasmi"}
        text1={"Butun sahifa orqa-foniga rasm berish uchun <body> elementiga orqa-fon rasmini berish kerak:"}
        redWords1={['<body>']}
        code1={`<style>
 body {
        background-image: url('img_girl.jpg');
      }
</style>`}
        path1={"try/112"}
        code1Text={"Butun sahifa uchun fon rasmini qo'shing:"}
      />

      <hr />

      <TextContent header={"Orqa-fon rasmining takrorlanishi"}
        text1={"Agar orqa-fon rasmi elementdan kichikroq bo'lsa, rasm elementning oxirigacha gorizontal va vertikal ravishda takrorlanadi:"}
        code1={`<style>
        body {
          background-image: url('example_img_girl.jpg');
        }
</style>`}
        path1={"try/112"}
        text2={"Orqa-fon rasmi takrorlanmasligi uchun background-repeat xususiyatiga no-repeat qiymati beriladi."}
        redWords2={['background-repeat', 'no-repeat']}
        code2={`<style>
        body {
          background-image: url('img_girl.jpg');
          background-repeat: no-repeat;
        }
</style>`}
        path2={"try/113"}
      />

      <hr />
      <TextContent header={"Orqa-fon rasm bilan qoplanishi"}
        text1={"Orqa-fon rasmi butun elementni qoplab olishi uchun  background-size xususiyatiga cover qiymati berish kerak."}
        redWords1={['background-size', 'cover']}
        text2={"Bundan tashqari, rasm butun elementni har doim qoplab turishi uchun(skroll qilinganda ham o'zgarmasligi uchun) background-attachment xususiyatiga fixed qiymati beriladi:"}
        redWords2={['background-attachment', 'fixed']}
        text3={"Endi, orqa-fon rasmi cho'zilmasdan butun elementni qamrab oladi (tasvir asl nisbatlarini saqlab qoladi):"}
        code3={`<style>
        body {
          background-image: url('img_girl.jpg');
          background-repeat: no-repeat;
          background-attachment: fixed;
          background-size: cover;
        }
</style>`}
        path3={"try/114"}
      />
      <hr />
      <TextContent header={"Orqa-fon rasmi cho'zilishi"}
        text1={"Orqa-fon rasmi butun elementga mos kelishi uchun background-size xususiyatiga 100% 100% qiymati beriladi"}
        redWords1={['background-size', '100%']} />

      <TextContent
        text1={"Brauzer oynasining o'lchamini o'zgartirib ko'ring va siz tasvirning cho'zilishini ko'rasiz, lekin har doim butun elementni qamrab oladi."}
        code1={`<style>
        body {
          background-image: url('img_girl.jpg');
          background-repeat: no-repeat;
          background-attachment: fixed;
          background-size: 100% 100%;
        }
        </style>`}
        path1={"try/116"}
      />
      <hr />
      <TextContent header={"CSS"}
        text1={"Yuqoridagi misollardan orqa-fon rasmlarini CSS fon xususiyatlaridan foydalanib stillar berish mumkinligini bilib oldingiz."} />
      <Notes text={"CSS orqa-fon xususiyatlari haqida ko'proq ma'lumot olish uchun bizning"} path="/css/css_background" text1={" Css yordamida orqa-fon stillari"} text2={" bo'limiga tashrif buyuring."} />
      <hr />

      <TextContent header={"Dars videosi:"} />
      <YoutubeEmbed embedId="WvIpuIZyiQ8" />
      <NavButton back='/html/html_images_imagemap' next='/html/html_images_picture' />
    </article >
  )
}

export default ImagesBg;
