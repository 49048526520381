import React from "react";
import mac from "../../images/mac.jpg";
function Computer() {
    return (
        <div className="container ">
            <div className="page">
                <h2 className='header-tutorial'>Komputer</h2>
                <img src={mac} alt="Computer" width={600} />
                <p className='text-tutorial'>
                    Wikipedia </p>
                <blockquote cite="https://en.wikipedia.org/wiki/Computer">
                    <i>Kompyuter - bu arifmetik yoki mantiqiy amallarning ixtiyoriy ketma-ketligini avtomatik ravishda bajarishga buyruq beradigan qurilma.
                    </i>
                </blockquote>
                <p className='text-tutorial' target={'_blank'} rel="noreferrer">Ko'proq o'qish <a className='text-tutorial text-tutorial__link' href="https://en.wikipedia.org/wiki/Computer">https://en.wikipedia.org/wiki/Computer</a></p>
            </div>
        </div>
    )
}

export default Computer;