import React from 'react';
import Notes from '../../../pages/Notes';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function TableStyle() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_table_styling.asp"} />
      <h2 className='header-tutorial'>HTML jadval stillari</h2>
      <NavButton back='/html/html_table_colspan_rowspan' next='/html/html_table_colgroup' />
      <hr />
      <TextContent text1={"Jadval ko'rinishini yaxshiroq qilish uchun CSS-dan foydalaniladi."} />
      <hr />
      <TextContent header={"Jadvalning zebra stilli"}
        text1={"Agar bitta qator tashlab keyingisiga orqa-fon rangi berilsa, zebra chiziqlari effektini beradi."} />
      <table className="table10" style={{ width: "60%", marginBottom: "20px" }}>
        <tbody><tr>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
        </tr>
          <tr>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
          </tr>
          <tr>
            <td>9</td>
            <td>10</td>
            <td>11</td>
            <td>12</td>
          </tr>
          <tr>
            <td>13</td>
            <td>14</td>
            <td>15</td>
            <td>16</td>
          </tr>
          <tr>
            <td>17</td>
            <td>18</td>
            <td>19</td>
            <td>20</td>
          </tr>
        </tbody></table>
      <TextContent text1={"Jadval juft qatorlariga still berish uchun :nth-child(even) selektoridan foydalaniladi:"}
        redWords1={[":nth-child(even)"]}
        css1={`tr:nth-child(even) {
  background-color: #D6EEEE;
}`} path1={"try/183"} />
      <Notes type="Eslatma:" text={"Toq qatorlarga still berish uchun even o'rniga odd yoziladi."} />
      <hr />
      <TextContent header={"Vertikal zebra stilli"}
        text1={"Vertikal zebra stilli uchun qator o‘rniga juft yoki toq ustunga still beriladi."} />
      <table class="table11" style={{ width: "60%" }}>
        <tbody><tr>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
        </tr>
          <tr>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
          </tr>
          <tr>
            <td>9</td>
            <td>10</td>
            <td>11</td>
            <td>12</td>
          </tr>
          <tr>
            <td>13</td>
            <td>14</td>
            <td>15</td>
            <td>16</td>
          </tr>
          <tr>
            <td>17</td>
            <td>18</td>
            <td>19</td>
            <td>20</td>
          </tr>
        </tbody></table>
      <TextContent text1={":nth-child(even) ga quyidagicha still beriladi:"}
        redWords1={[":nth-child(even)"]}
        css1={`td:nth-child(even), th:nth-child(even) {
  background-color: #D6EEEE;
}`}
        path1={"try/184"}
      />
      <Notes type={"Eslatma:"} text={" Sarlavhalar va oddiy jadval kataklariga still berish uchun ikkala th va td elementlar :nth-child() selektoriga still beriladi."} />
      <hr />
      <TextContent header={"Vertikal va gorizontal zebra chiziqlarini birlashtirish"}
        text1={"Yuqoridagi ikkita misoldagi stillarini birlashtirib, juft qator va ustunda zebra chiziqlarini hosil qilish mumkin."}
        text2={"Agar shaffof rangdan foydalanilsa, bir-birining ustiga chiqadigan effektga ega bo'linadi."} />
      <table className="table10 table11" style={{ width: "60%" }}>
        <tbody><tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
      <TextContent text1={"Rangning shaffofligini belgilash uchun rgba() rangdan foydalaniladi:"}
        redWords1={["rgba()"]}
        css1={`tr:nth-child(even) {
  background-color: rgba(150, 212, 212, 0.4);
}

th:nth-child(even),td:nth-child(even) {
  background-color: rgba(150, 212, 212, 0.4);
}`}
        path1={"try/185"}
      />
      <hr />
      <TextContent header={"Gorizontal chiziqlar"} />
      <table className="tabletest2" style={{ marginBottom: "20px" }}>
        <thead>
          <tr>
            <th>Ism</th>
            <th>Familiya</th>
            <th>Tejamkorlik</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Piter</td>
            <td>Griffin</td>
            <td>100 dollar</td>
          </tr>
          <tr>
            <td>Lois</td>
            <td>Griffin</td>
            <td>150 dollar</td>
          </tr>
          <tr>
            <td>Jo</td>
            <td>Swanson</td>
            <td>300 dollar</td>
          </tr>
        </tbody>
      </table>
      <TextContent text1={"Gorizontal chiziqli jadvalga ega bo'lish uchun har bir jadval qatorining pastki qism borderi belgilanadi."}
        text2={"Gorizontal chiziqli jadval uchun tr elementiga border-bottom xususiyati beriladi:"}
        redWords2={["border-bottom"]}
        css1={`tr {
  border-bottom: 1px solid #ddd;
}`}
        path1={"try/186"}
      />
      <hr />
      <TextContent header={"Jadvaldagi hover effekti"} text1={"Jadval qatorlari ustiga sichqoncha olib borilganda mana shu qatorni ajratib ko'rsatish uchun tr:hover selektordan foydalaniladi:"} />
      <table className="tabletest4" style={{ marginBottom: "20px" }}>
        <tbody><tr>
          <th>Ism</th>
          <th>Familiya</th>
          <th>Tejamkorlik</th>
        </tr>
          <tr>
            <td>Piter</td>
            <td>Griffin</td>
            <td>100 dollar</td>
          </tr>
          <tr>
            <td>Lois</td>
            <td>Griffin</td>
            <td>150 dollar</td>
          </tr>
          <tr>
            <td>Jo</td>
            <td>Swanson</td>
            <td>300 dollar</td>
          </tr>
        </tbody></table>
      <TextContent css1={`tr:hover {background-color: #D6EEEE;}`}
        path1={"try/187"}
      />
      <hr />
      <TextContent header={"Dars videosi:"} />
      <YoutubeEmbed embedId="rRZU1X2CcBU" />
      <NavButton back='/html/html_table_colspan_rowspan' next='/html/html_table_colgroup' />
    </article >)
}

export default TableStyle;