import React from 'react';
import CodeContent from '../../tryit/CodeContent';
import RedText from './RedText';

function TextContent(props) {

    return (
        <>
           {props.header?<h3 className='header-content'>{props.header}</h3>:<></>}
           {props.text1?<RedText text={props.text1} redWords={props.redWords1}/>:<></>}

            {props.code1?<CodeContent props={props.code1} pathname={props.path1} text={props.code1Text}/>:<></>}

            {props.css1?<CodeContent css={props.css1} pathname={props.path1} text={props.code1Text}/>:<></>}

            {props.text2? <RedText text={props.text2} redWords={props.redWords2}/>:<></>}

            {props.code2?<CodeContent props={props.code2} pathname={props.path2} text={props.code2Text} />:<></>}
            {props.css2?<CodeContent css={props.css2} pathname={props.path2} text={props.code2Text}/>:<></>}

            {props.text3? <RedText text={props.text3} redWords={props.redWords3}/>:<></>}

            {props.code3?<CodeContent props={props.code3} pathname={props.path3} text={props.code3Text}/>:<></>}

            {props.css3?<CodeContent css={props.css3} pathname={props.path3} text={props.code3Text}/>:<></>}

            {props.text4? <RedText text={props.text4} redWords={props.redWords4}/>:<></>}

            {props.code4?<CodeContent props={props.code4} pathname={props.path4} text={props.code4Text}/>:<></>}

            {props.css4?<CodeContent css={props.css4} pathname={props.path4} text={props.code4Text}/>:<></>}

        </>
    )
}

export default TextContent;