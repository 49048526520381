import React from 'react';
import Notes from '../../../pages/Notes';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function Colgroup() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_table_colgroup.asp"} />
            <h2 className='header-tutorial'>Colgroup</h2>
            <NavButton back='/html/html_table_styling' next='/html/html_lists' />
            <hr />
            <TextContent text1={"<colgroup> elementi jadvalning bir nechta ustunlariga stillar berish uchun ishlatiladi."} />
            <hr />
            <TextContent header={"Colgroup"}
                text1={"Jadvalning ikkita ustuniga stil berish uchun <colgroup> va <col> elementlaridan foydalaniladi."}
                redWords1={["<colgroup>", "<col>"]}
            />
            <table className="table9" style={{ width: "60%", marginBottom: "20px" }}>
                <colgroup>
                    <col span="2" style={{ backgroundColor: "rgba(150, 212, 212,0.4)" }} />
                </colgroup>
                <tbody><tr>
                    <th>MON</th>
                    <th>TUE</th>
                    <th>WED</th>
                    <th>SHU</th>
                    <th>FRI</th>
                    <th>SAT</th>
                    <th>SUN</th>
                </tr>
                    <tr>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                        <td>4</td>
                        <td>5</td>
                        <td>6</td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>9</td>
                        <td>10</td>
                        <td>11</td>
                        <td>12</td>
                        <td>13</td>
                        <td>14</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>16</td>
                        <td>17</td>
                        <td>18</td>
                        <td>19</td>
                        <td>20</td>
                        <td>21</td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>23</td>
                        <td>24</td>
                        <td>25</td>
                        <td>26</td>
                        <td>27</td>
                        <td>28</td>
                    </tr>
                </tbody></table>
            <TextContent text1={"<colgroup> elementi ajralib turuvchi ustunlar uchun konteyner sifatida ishlatilishi kerak."}
                redWords1={["<colgroup>"]}
                text2={"Har bir guruh <col> element bilan belgilanadi"}
                redWords2={["<col>"]}
                text3={"span atributi stil berish kerak bo'lgan ustunlar sonini belgilaydi."}
                redWords3={["span"]}
                text4={"style atributi ustunlar stilini belgilaydi."}
                redWords4={["style"]}
            />
            <div className='note-content'>
                <p className='text-tutorial'><strong>Eslatma:</strong> Colgroup uchun <a className='text-tutorial text-tutorial__link' href='#css'>CSS xususiyatilari</a> cheklangan.</p>
            </div>
            <TextContent code1={`<table>
  <colgroup>
    <col span="2" style="background-color: #D6EEEE">
  </colgroup>
  <tr>
    <th>DU</th>
    <th>SE</th>
    <th>CHOR</th>
    <th>PAY</th>
...`} path1={"try/188"}
            />
            <Notes type={"Eslatma:"} text={"<colgroup> tegi uchun <table> ota element bo'lishi lozim. <colgroup> elementi <thead>, <tr>, <td> va boshqa elementlardan oldin yoziladi. Ammo <caption> elmentidan keyin yoziladi."} />
            <hr />
            <h3 id="css" className='header-content'>CSS xususiyatlari</h3>
            <TextContent text1={"Colgroup uchun qoida bo'yicha CSS xususiyatlarining juda cheklangan tanlovi mavjud:"}
                text2={"width xususiyati"}
                redWords2={["width"]}
                text3={"background xususiyati"}
                redWords3={["background"]}
                text4={"border xususiyati"}
                redWords4={["border"]} />
            <TextContent text1={"visibility xususiyati"}
                redWords1={["visibility"]}
                text2={"Boshqa barcha CSS xususiyatlari jadvalga ta'sir qilmaydi."}
            />
            <hr />
            <TextContent header={"Bir nechta Col elementi"}
                text1={"Ustunlarga turli xil stil berish uchun <colgroup> ichida bir nechta <col> elementidan foydalaniladi."}
                redWords1={["<colgroup>", "<col>"]}
                code1={`<table>
<colgroup>
  <col span="2" style="background-color: #D6EEEE">
  <col span="3" style="background-color: pink">
</colgroup>
<tr>
  <th>DU</th>
  <th>SE</th>
  <th>CHOR</th>
  <th>PAY</th>
 ...`} path1={"try/189"} />
            <hr />
            <TextContent header={"Bo'sh colgroup"}
                text1={"Jadvalning o'rtasidagi ustunlarga stil berish uchun oldingi ustunlar uchun bo'sh(uslublarsiz) <col> elementini  kiritiladi:"}
                redWords1={["<col>"]}
                code1={`<table>
<colgroup>
  <col span="3">
  <col span="2" style="background-color: pink">
</colgroup>
<tr>
  <th>DU</th>
  <th>SE</th>
  <th>CHOR</th>
  <th>PAY</th>
...`} path1={"try/190"} />
            <hr />
            <TextContent header={"Ustunlarni ko'rinmas qilish"}
                text1={"visibility: collapse xususiyati bilan ustunlarni  ko'rinmas qilish mumkin."}
                redWords1={["visibility:", "collapse"]}
                code1={`<table>
<colgroup>
  <col span="2">
  <col span="3" style="visibility: collapse">
</colgroup>
<tr>
  <th>DU</th>
  <th>SE</th>
  <th>CHOR</th>
  <th>PAY</th>
...`} path1={"try/191"} />
            <hr />
            <TextContent header={"Dars videosi:"} />
            <YoutubeEmbed embedId="S-tJ2F2Lx0o" />
            <NavButton back='/html/html_table_styling' next='/html/html_lists' />
        </article>)
}
export default Colgroup;
