import React from 'react';
import { Link } from 'react-router-dom';
import AttrLists from './AttrLists';

function TagTable(props) {

    return (
        <>
            <h3 className='header-content'>Atributlari</h3>
            <table className='table-content'>
                <tbody>
                    <tr>
                        <th scope="col">Atribut</th>
                        <th scope="col">Qiymati</th>
                        <th scope="col">Tavsif</th>
                    </tr>
                    {
                        AttrLists.map((value) => {
                            if (props.arr.indexOf(value.title) !== -1) {
                                return (
                                    <tr key={value.id}>
                                        <td><Link className='table-link' to={value.path}>{value.title}</Link></td>
                                        <td style={{ maxWidth: '220px' }}>{Array.from(value.value).join(', ')}</td>
                                        <td>{value.meaning}</td>
                                    </tr>
                                )
                            }
                        })

                    }
                </tbody>
            </table>
        </>
    )
}

export default TagTable;