import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function PaddingSpacing() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_table_padding_spacing.asp"} />
            <h2 className='header-tutorial'>HTML Table Padding&Spacing</h2>
            <NavButton back='/html/html_table_headers' next='/html/html_table_colspan_rowspan' />
            <hr />
            <TextContent text1={"HTML jadvallarida kataklar ichidagi, shuningdek ular orasidagi bo'shliqni o'zgartirish mumkin."} />
            <hr />
            <div style={{ display: "flex", justifyContent: 'space-around' }}>
                <table className="table9">
                    <caption>Padding bilan</caption>
                    <tbody><tr>
                        <td>Salom</td>
                        <td>Salom</td>
                        <td>Salom</td>
                    </tr>
                        <tr>
                            <td>Salom</td>
                            <td>Salom</td>
                            <td>Salom</td>
                        </tr>
                        <tr>
                            <td>Salom</td>
                            <td>Salom</td>
                            <td>Salom</td>
                        </tr>
                    </tbody>
                </table>
                <table className="table3">
                    <caption>Bo'shliq bilan</caption>
                    <tbody>
                        <tr>
                            <td>Salom</td>
                            <td>Salom</td>
                            <td>Salom</td>
                        </tr>
                        <tr>
                            <td>Salom</td>
                            <td>Salom</td>
                            <td>Salom</td>
                        </tr>
                        <tr>
                            <td>Salom</td>
                            <td>Salom</td>
                            <td>Salom</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr />
            <TextContent header={"Katak paddingi"}
                text1={"Katak paddingi - katak chekkasi va katak ichidagi kontent orasidagi bo'shliq."}
                text2={"Default holatda padding 0 ga teng."}
                text3={"Katakga padding berish uchun CSS padding xususiyatidan foydalaniladi."}
                redWords3={["padding"]}
                css3={`th, td {
padding: 15px;
}`} path3={"try/173"}
            />
            <TextContent text1={"Faqat kontent tepasiga padding berish uchun padding-top xususiyatdan foydalaniladi."}
                redWords1={["padding-top"]}
                text2={"Yana shuningdek padding-bottom, padding-left va padding-right xususiyatlari ham bor:"}
                redWords2={["padding-bottom", "padding-left", "padding-right"]}
                css2={`th, td {
padding-top: 10px;
padding-bottom: 20px;
padding-left: 30px;
padding-right: 40px;
}`}
                path2={"try/174"}
            />
            <hr />
            <TextContent header={"Kataklar orasidagi bo'shliq"}
                text1={"Html jadvalida har bir katak orasida bo'shliq bor bo'ladi."}
                text2={"Default holatda bo'shliq 2px ga teng bo'ladi"}
                text3={"Jadval kataklari orasidagi bo'shliqni o'zgartirish uchun table elementidagi CSS border-spacing xususiyatidan foydalaniladi:"}
                redWords3={["border-spacing"]}
                css3={`table {
border-spacing: 30px;
}`} path3={"try/175"} />
            <hr />
            <TextContent header={"Dars videosi:"} />
            <YoutubeEmbed embedId="UaeD5JEOKk0" />
            <NavButton back='/html/html_table_headers' next='/html/html_table_colspan_rowspan' />
        </article>)
}
export default PaddingSpacing;
