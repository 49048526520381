import React from 'react';
import chromeIcon from '../../images/chrome-logo.png'
import firefoxIcon from '../../images/firefox-logo.png';
import operaIcon from '../../images/opera.png';
import safariIcon from '../../images/safari.png';
import edgeIcon from '../../images/edge.svg';

function BrowserTable(props) {
  let tableElement = props.tabEl?props.tabEl:"Element";
  return (
    <section>
      <h3 className='header-content'>Brauzerlar qo'llab-quvvatlashi</h3>
      <p className='text-tutorial'>{props.text}</p>
      <table className='table-content table-border'>
        <tbody>
          <tr>
            <th scope="col">{tableElement}</th>
            <th title='Chrome' scope="col"><img className='table-img' src={chromeIcon} alt="Chrome" width={30} height={30} /></th>
            <th title='Internet Explorer/Edge' scope="col"><img className='table-img' src={edgeIcon} alt="Edge" width={30} height={30} /></th>
            <th title='Firefox' scope="col"><img className='table-img' src={firefoxIcon} alt="Firefox" width={30} height={30} /></th>
            <th title='Safari' scope="col"><img className='table-img' src={safariIcon} alt="Safari" width={30} height={30} /></th>
            <th title='Opera' scope="col"><img className='table-img' src={operaIcon} alt="Opera" width={30} height={30} /></th>

          </tr>

          <tr>
            <td>{props.element}</td>
            <td><p className='table-text'>{props.chrome}</p></td>
            <td><p className='table-text'>{props.edge}</p></td>
            <td><p className='table-text'>{props.firefox}</p></td>
            <td><p className='table-text'>{props.safari}</p></td>
            <td><p className='table-text'>{props.opera}</p></td>
          </tr>
        </tbody>
      </table>
      <hr />
    </section>
  )
}

export default BrowserTable;