import React from 'react';
import { Link } from 'react-router-dom';
import BrowserTable from '../../pages/BrowserTable';
import Tegs from '../../pages/tegs';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import TagTable from './AttrTable';

function Tagcol() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_col.asp"} />
      <h2 className='header-tutorial'>HTML <Tegs tegname={"col"} /> tegi</h2>
      <NavButton back='/tags/tag_code' next='/tags/tag_colgroup' />
      <TextContent
        code1={`<table>
<colgroup>
  <col span="2" style="background-color:red">
  <col style="background-color:yellow">
</colgroup>
<tr>
<th>Nomer</th>
<th>Mavzu</th>
<th>Narxi</th>
</tr>
<tr>
<td>3476896</td>
<td>Mening birinchi HTML kodim</td>
<td>$53</td>
</tr>
</table>`}
        path1={"try/194"}
        code1Text={"Uchta ustunning fon rangini <colgroup> va <col> teglari yordamida o'zgartirish:"}
      />
      <hr />
      <TextContent header={"Ta'rifi va qo'llanilishi"}
        text1={"Ustunlarga turli xil still berish uchun <colgroup> ichida bir nechta <col> elementidan foydalaniladi."} redWords1={["<colgroup>", "<col>"]}
        text2={"<col> tegi har bir katak yoki har bir satr uchun still berganda takrorlashni oldini olib, ustunlar guruhiga still berishda qo'llaniladi."}
        redWords2={['<col>']} />
      <hr />
      <BrowserTable element='<col>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />
      <TagTable arr={['span']} />
      <TextContent header={"Global atributlar"} />
      <p className='text-tutorial'>&lt;col&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <TextContent header={"Hodisa atributlari"} />
      <p className='text-tutorial'>&lt;col&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>

      <TextContent header={"Misollar"}
        code1={`<table style="width:100%">
<tr>
<th>Nomer</th>
<th>Mavzu</th>
<th>Narxi</th>
</tr>
<tr>
  <td>3476896</td>
  <td>Mening birinchi Html kodim</td>
  <td style="text-align:right">$53</td>
</tr>
<tr>
  <td>2489604</td>
  <td>Mening birinchi Css kodim</td>
  <td style="text-align:right">$47</td>
</tr>
</table>`} path1={"try/196"}
        code1Text={"Jadval ustunlaridagi matnni tekislash(CSS bilan):"}
        code2={`<table style="height:200px">
<tr>
  <th>Oylar</th>
  <th style="vertical-align:bottom">Jamg'arma</th>
</tr>
<tr>
  <td>Yanvar</td>
  <td style="vertical-align:bottom">$100</td>
</tr>
</table>`} path2={"try/197"}
        code2Text={"Jadval ustunlaridagi matnni vertikal tekislash(CSS bilan):"}
        code3={`<table>
<tr>
<th style="width:200px">Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>`} path3={"try/198"}
        code3Text={"Jadval ustunining kengligini belgilash(CSS bilan):"} />
      <TextContent header={"Tegishli sahifalar"} />
      <p className='text-tutorial'>HTML DOM: <Link className='table-link' to={"/jsref/dom_obj_col"}>Col obyekti</Link></p>
      <TextContent header={"Default CSS sozlamalari"}
        text1={"Ko'p brauzerlarda <col> elementida quyidagi default stillar berilgan bo'ladi:"}
        redWords1={['<col>']}
        css1={`col {
 display: table-column;
}`}
        path1={"try/199"} />
      <NavButton back='/tags/tag_code' next='/tags/tag_colgroup' />
    </article>)
}

export default Tagcol;
