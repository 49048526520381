import React from "react";
import PropTypes from "prop-types";
import './YoutubeEmbed.css';
// TODO
const YoutubeEmbed = ({ embedId }) => (
  <div className="video">
    <div className="video-responsive">
      <iframe className="iframe"
        width="799"
        height="450"
        src={`https://www.youtube-nocookie.com/embed/${embedId}?rel=0&controls=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;