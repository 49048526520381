import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import { Link } from 'react-router-dom';
import Notes from '../../../pages/Notes';
import img from '../../../../images/img_temp_band.jpg';
import viewport1 from '../../../../images/img_viewport1.png';
import viewport2 from '../../../../images/img_viewport2.png';
import girl_img from '../../../../images/img_girl.jpg';
import img_flowers from '../../../../images/img_flowers.jpg';
import img_smallflower from '../../../../images/img_smallflower.jpg';
import flowers from '../../../../images/flowers.jpg';
import './responsive.css';
import NoteWhite from '../../../pages/NoteWhite';



function Responsive() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_responsive.asp"} />
            <h2 className='header-tutorial'>Moslashuvchan(Responsiv) veb sahifa</h2>
            <NavButton back='/html/html_layout' next='/html/html_computercode' />
            <hr />

            <p>Responsiv veb-sahifa barcha qurilmalarda yaxshi ko'rinadigan veb-sahifalarni yaratishdir!</p>
            <p>Responsiv veb-sahifa avtomatik ravishda turli ekran o'lchamlari va ko'rish oynalariga moslashadi.</p>
            <hr />
            <img src={img} alt={'responsive img'} style={{ width: '100%' }} />

            <TextContent header={"Responsiv veb-sahifa nima?"}
                text1={"Responsive veb-sahifa barcha qurilmalarda (ish stollari, planshetlar va telefonlar) yaxshi ko'rinishi uchun veb-sayt hajmini avtomatik ravishda o'zgartirish, yashirish, kichraytirish yoki kattalashtirish uchun HTML va CSS-dan foydalanishga qaratilgan:"} />
            <div> <Link className='button btn btn--large green' to={"try/256"} target={"_blank"}>Sinab ko'rish</Link></div>
            <hr />
            <TextContent header={"Ko'rish oynasini sozlash"}
                text1={"Responsiv veb-sayt yaratish uchun barcha veb-sahifalarga <meta> tegi quyidagicha qo'shish kerak :"}
                code1={`<meta name="viewport" content="width=device-width, initial-scale=1.0">`}
                path1={"try/257"}
                text2={"Bu sahifaga viewportni o'rnatadi, viewport brauzerga sahifa o'lchamlari va masshtabini boshqarish bo'yicha ko'rsatmalar beradi."}
                text3={"Mana viewport meta-tegi bo'lmagan va viewport meta-tegiga ega bir xil veb-sahifalargaga misol :"} />
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-around' }}>
                <div style={{ textAlign: 'center' }}>
                    <a className='text-tutorial text-tutorial__link' href='/viewport1.html' target={'_blank'}>
                        <img className='img_card' src={viewport1} alt={"Witout viewport"} width={200} height={375} />
                        <br />
                        <br />
                        <b>viewport meta-tegiga ega bo'lmaganda </b>
                    </a>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <a className='text-tutorial text-tutorial__link' href='/viewport2.html' target={'_blank'}>
                        <img className='img_card' src={viewport2} alt={"Witout viewport"} width={200} height={375} />
                        <br />
                        <br />
                        <b>viewport meta-tegiga ega bo'lganda </b>
                    </a>
                </div>
            </div>
            <Notes type={"Maslahat:"} text={"Agar siz ushbu sahifani telefon yoki planshetda ko'rayotgan bo'lsangiz, farqni ko'rish uchun yuqoridagi ikkita havolani bosing."} />
            <hr />
            <TextContent header={"Responsiv rasmlar"}
                text1={"Responsiv rasmlar bu masshtabi har qanday brauzerga moslasha oladigan rasmlardir."} />
            <TextContent header={"width xususiyatidan foydalanish"}
                text1={"Css width xususiyatining qiymati 100% berilsa rasm masshtabi ko'rish oynasiga qarab kattalashishi va kichiklashishi mumkin."}
                redWords1={["width"]} />
            <img src={girl_img} alt={"girl with jacket"} style={{ width: '100%', marginBottom: '20px', }} />
            <TextContent
                code1={`<img src="img_girl.jpg" style="width:100%;">`}
                path1={'try/258'}
                text2={"E'tibor bering, yuqoridagi misolda rasmni asl o'lchamidan kattalashtirish mumkin. Ko'p hollarda width o'rniga max-width xususiyatidan foydalanish yaxshiroq yechim hisoblanadi."}
                redWords2={["max-width",]} />
            <TextContent header={"max-width xususiyatidan foydalanish"}
                text1={"Css max-width xususiyatining qiymatiga 100% berilsa rasm masshtabi ko'rish oynasiga qarab kattalashishi mumkin lekin rasm asl o'lchamidan kattalashib ketmaydi."}
                redWords1={["width"]} />
            <img src={girl_img} alt={"girl with jacket"} style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px', }} width={500} height={600} />
            <TextContent
                code1={`<img src="img_girl.jpg" style="max-width:100%;height:auto;">`}
                path1={'try/259'}
                text2={"E'tibor bering, yuqoridagi misolda rasmni asl o'lchamidan kattalashtirish mumkin. Ko'p hollarda width o'rniga max-width xususiyatidan foydalanish yaxshiroq yechim hisoblanadi."}
                redWords2={["max-width ",]} />
            <hr />
            <TextContent header={"Brauzer kengligiga qarab turli rasmlarni ko'rsatish"}
                text1={"HTML <picture> elementi turli xil brauzer oynalari uchun turli xil rasmlarni ko'rsatish imkonini beradi."}
                redWords1={["<picture>"]}
                text2={"Quyidagi rasm brauzer oynasining kengligiga qarab qanday o'zgarishini ko'rish uchun brauzer oynasining o'lchamini o'zgartiring:"} />
            <picture style={{ marginBottom: '20px' }}>
                <source srcSet={img_smallflower} media="(max-width: 600px)" />
                <source srcSet={img_flowers} media="(max-width: 1500px)" />
                <source srcSet={flowers} />
                <img src={img_smallflower} alt="Gullar" />
            </picture>
            <TextContent
                code1={`<picture>
  <source srcset="img_smallflower.jpg" media="(max-width: 600px)">
  <source srcset="img_flowers.jpg" media="(max-width: 1500px)">
  <source srcset="flowers.jpg">
  <img src="img_smallflower.jpg" alt="Flowers">
</picture>`} path1={"try/260"} />
            <hr />
            <TextContent header={"Responsiv matn o'lchami"}
                text1={"Matn o'lchamini 'vw' birligida berish mumkin, bu 'ko'rish maydoni kengligi(viewport width)' degan ma'noni anglatadi."}
                redWords1={[""]}
                text2={"Matn hajmi brauzer oynasining o'lchamiga mos keladi:"} />
            <div style={{ backgroundColor: '#f1f1f1', padding: "8px 16px", marginBottom: '20px' }}>
                <h1 style={{ fontSize: '8vw' }}>Salom Dunyo</h1>
                <p style={{ fontSize: '2vw' }}>Matn o'lchami qanday ekanini ko'rish uchun brauzer oynasining o'lchamini o'zgartiring.</p>
            </div>
            <TextContent code1={`<h1 style="font-size:10vw">Salom dunyo</h1>`}
                path1={"try/261"} />
            <Notes text={"Viewport - bu brauzer oynasining o'lchamidir. 1vw = ko'rish maydoni kengligi 1%. Agar ko'rish maydoni 50 sm kengligida bo'lsa, 1vw 0,5 smga teng."} />
            <hr />
            <TextContent header={"Media so'rovlari"}
                text1={"Matn va rasmlar o'lchamini o'zgartirishdan tashqari, responsiv veb-sahifalarda media so'rovlaridan foydalanish ham keng tarqalgan usuldir."}
                text2={"Media so'rovlari bilan turli xil brauzer o'lchamlari uchun butunlay boshqa stillar berishingiz mumkin."}
                text3={"Misol: brauzer oynasining o'lchamini o'zgartirib va quyidagi uchta div elementi katta ekranlarda gorizontal va kichik ekranlarda vertikal holda joylashishini ko'rish mumkin:"} />
            <div style={{ marginBottom: "20px" }}>
                <div className='menu'>
                    <div className='menu_left'>Chap menyu<br /><br /><br /></div>
                </div>
                <div className='main1'>
                    <div className='main_center'>Asosiy kontent<br /><br /><br /></div>
                </div>
                <div className='right'>
                    <div className='right_content'>O'ng tomondagi kontent<br /><br /><br /></div>
                </div>
            </div>
            <TextContent code1={`
<style>
.left, .right {
  float: left;
  width: 20%; /* Default holatda width 20% ga teng */
}

.main {
  float: left;
  width: 60%; /* Default holatda width 60% ga teng*/
}

/*  O'zgarish nuqtasi 800px qilish uchun media querydan foydalaniladi: */
@media screen and (max-width: 800px) {
  .left, .main, .right {
    width: 100%; /* Ko'rish oynasi 800px va undan kichik bo'lganda width 100%ga teng. */
  }
}
</style>`} path1={"try/262"} />
            <Notes type={"Maslahat:"} text={"Media so'rovlari va responsive veb-sahifa haqida ko'proq ma'lumot olish uchun"} text1={"RWD qo'llanmasini"} path={"/css/css_rwd_intro"} text2={" o'qing."} />
            <hr />
            <TextContent header={"To'liq responsiv veb-sahifa misoli"}
                text1={"Responsiv veb-sahifa hatto katta ish stoli ekranlarida va kichik mobil telefonlarda yaxshi ko'rinishi kerak."} />
            <div style={{ marginBottom: "20px" }}>
                <div className="bg_content" style={{ marginBottom: "8px", height: "60px" }}></div>
                <div className="left bg_content" style={{ height: "170px" }}></div>
                <div className='center bg_content' style={{ height: "170px" }}></div>
                <div className='right1 bg_content' style={{ height: "170px" }}></div>
                <div className="bg_content" style={{ marginTop: "8px", height: "31px", float: "left", width: "100%", }}></div>
            </div>
            <div> <Link className='button btn btn--large green' to={"try/263"} target={"_blank"}>Sinab ko'rish</Link></div>
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="pDIt2vOu1vo" />
            <hr />
            <TextContent header={"Responsiv Frameworklar"}
                text1={"Barcha mashhur CSS Frameworklari responsiv dizaynni taklif qiladi."}
                text2={"Ular bepul va ulardan foydalanish oson."} />
            <TextContent header={"W3.CSS"}
                text1={"W3.CSS kompyuter ish stoli, planshet va mobil telefonlarga default holatida veb-sahifani responsiv qiloladigan zamonaviy CSS frameworkidir."}
                text2={"W3.CSS shunga o'xshash CSS frameworklariga qaraganda kichikroq va tezroq ishlaydi."}
                text3={"W3.CSS jQuery yoki boshqa JavaScript kutubxonasisiz yaratilgan."} />
            <div class="right_content">
                <h1>W3.CSS demo</h1>
                <p>Ta'sirchanlikni ko'rish uchun sahifa o'lchamini o'zgartiring!</p>
            </div>
            <div class="main_center" style={{ marginBottom: "20px" }}>
                <div class="city_content">
                    <h2>London</h2>
                    <p>London - Angliyaning poytaxti.</p>
                    <p>Bu Buyuk Britaniyadagi eng gavjum shahar bo'lib, uning poytaxti 13 milliondan ortiq aholiga ega.</p>
                </div>
                <div class="city_content">
                    <h2>Parij</h2>
                    <p>Parij Fransiyaning poytaxti.</p>
                    <p>Parij hududi Evropadagi eng yirik aholi markazlaridan biri bo'lib, 12 milliondan ortiq aholiga ega.</p>
                </div>
                <div class="city_content">
                    <h2>Tokio</h2>
                    <p>Tokio - Yaponiya poytaxti.</p>
                    <p>Bu Katta Tokio hududining markazi va dunyodagi eng gavjum metropolitandir.</p>
                </div>
            </div>

            <TextContent code1={`<!DOCTYPE html>
<html>
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<body>

<div class="w3-container w3-green">
  <h1>W3School Demosi</h1>
  <p>Ushbu responsiv sahifa o'lchamini o'zgartiring!</p>
</div>

<div class="w3-row-padding">
  <div class="w3-third">
    <h2>London</h2>
    <p>London - Angliya poytaxti.</p>
    <p>Bu Buyuk Britaniyadagi eng gavjum shahar bo'lib, uning poytaxti 13 milliondan ortiq aholiga ega.</p>
  </div>

  <div class="w3-third">
    <h2>Parij</h2>
    <p>Paris is the capital of France.</p>
    <p>Parij hududi Yevropadagi eng yirik aholi markazlaridan biri, 12 milliondan ortiq aholiga ega.</p>
  </div>

  <div class="w3-third">
    <h2>Tokio</h2>
    <p>Tokio - Yaponiya poytaxti.</p>
    <p>Shahar Katta Tokio hududida joylashgan
    va dunyodagi eng gavjum metropoliten hudud.</p>
  </div>
</div>

</body>
</html>`} path1={"try/264"} />
            <NoteWhite text={"W3.CSS haqida ko'proq bilish uchun"} text1={"W3.CSS qo'llanmamizni"} path={"/w3css"} text2={"o'qing"} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="w3TvECmGawk" />
            <hr />
            <TextContent header={"Bootstrap"}
                text1={"Yana bir mashhur CSS frameworki Bootstrapdir. Bootstrap responsive veb-sahifalarni yaratish uchun HTML, CSS va jQuery-dan foydalanadi."}
                code1={`<!DOCTYPE html>
<html lang="en">
<head>
<title>Bootstrap Misoli</title>
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
</head>
<body>

<div class="container">
  <div class="jumbotron">
    <h1>Mening birinchi Bootstrap sahifam</h1>
  </div>
  <div class="row">
    <div class="col-sm-4">
      ...
    </div>
    <div class="col-sm-4">
      ...
    </div>
    <div class="col-sm-4">
    ...
    </div>
  </div>
</div>

</body>
</html>`} path1={"try/265"} />
            <NoteWhite text={"Bootstrap haqida ko'proq ma'lumot olish uchun "} text1={"Bootstrap qo'llanmasiga"} path={"/bootstrap/bootstrap_ver"} text2={"o'ting."} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="vCb-JhOoUjg" />
            <NavButton back='/html/html_layout' next='/html/html_computercode' />
        </article>)
}

export default Responsive;