import React from 'react';
import CodeContent from '../../../tryit/CodeContent';
import CodeMirrorContent from '../../../tryit/CodeContent/CodeMirrorContent';
import Collapsible from '../../pages/Collapsible';
import Table from '../../pages/Table';
import Tegs from '../../pages/tegs';
import W3Path from '../../W3Path';
import '../Info.css';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';

function HtmlElements() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_elements.asp"} />
      <h2 className='header-tutorial'>HTML elementlari</h2>
      <NavButton back='/html/html_basic' next='/html/html_atributes' />
      <hr />
      <p className='text-tutorial text-tutorial-margin'>HTML elementi ochilgan teg, content va yopilgan tegdan iborat.</p>
      <hr />
      <h3 className='header-content'>HTML elementlari</h3>
      <p className='text-tutorial'>Ochilgan tegdan yopilgan teggacha hammasi <strong>HTML elementi</strong> hisoblanadi.</p>
      <p className='text-tutorial'><Tegs tegname="teg nomi " /> <strong>Kontent...</strong>  <Tegs tegname="/teg nomi " /> </p>
      <p className='text-tutorial'>Ba'zi HTML elementlariga misollar:</p>    <p className='text-tutorial'><Tegs tegname="h1 " /> <strong> Mening birinchi sarlavham</strong>  <Tegs tegname="/h1 " /> </p>
      <p className='text-tutorial'><Tegs tegname="p " /> <strong>Mening birinchi xatboshim.</strong>  <Tegs tegname="/p " /> </p>

      <Table th={["Ochilgan teg", "Kontent", "Yopilgan teg"]} values={[
        { id: 0, first: "<h1>", second: "Mening birinchi sarlavham", third: "</h1>" },
        { id: 1, first: "<p>", second: "Mening birinchi xatboshim", third: "</p>" },
        { id: 2, first: "<br>", second: "yo'q", third: "yo'q" },
      ]} />

      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong>Ba'zi HTML elementlarida kontent yo'q (masalan, &lt;br&gt; elementi).  Bu elementlar bo'sh elementlar deb ataladi. Bo'sh elementlarning yopilgan tegi yo'q!</p>
      </div>
      <hr />
      <h3 className='header-content'>Ichki HTML elementlari</h3>
      <p className='text-tutorial'>HTML elementlari ichma-ich joylashtirilishi mumkin (bu elementlar boshqa elementlarni o'z ichiga olishi mumkinligini anglatadi).</p>
      <p className='text-tutorial'>Barcha HTML hujjatlari ichma-ich joylashgan HTML elementlaridan iborat.</p>
      <p className='text-tutorial'>Quyidagi misolda to'rtta HTML elementi ( <span className='text-tutorial__red'>&lt;html&gt;</span>, <span className='text-tutorial__red'>&lt;body&gt;</span>,  <span className='text-tutorial__red'>&lt;h1&gt;</span>, <span className='text-tutorial__red'>&lt;p&gt;</span> ) mavjud:</p>
      <CodeContent props={`<!DOCTYPE html>
<html>
<body>

<h1>Mening birinchi sarlavham</h1>
<p>Mening birinchi xatboshim</p>

</body>
</html>`} pathname={`try/7`} />

      <h3 className='header-content'>Misol tavsifi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;html&gt;</span> ildiz element va u yozilgan kodlar HTML hujjati ekanligini anglatadi.</p>
      <p className='text-tutorial'>Uning <span className='text-tutorial__red'>&lt;html&gt;</span> ochilgan teg va <span className='text-tutorial__red'>&lt;/html&gt;</span> yopilgan tegi bor</p>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;html&gt;</span>  elementi ichida <span className='text-tutorial__red'>&lt;body&gt;</span> elementi bor:</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`<body>

<h1>Mening birinchi sarlavham</h1>
<p>Mening birinchi xatboshim</p>

</body>`} />
        </div>
      </div>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;body&gt;</span> elementi hujjat tanasi hioblanadi.</p>
      <p className='text-tutorial'>Uning <span className='text-tutorial__red'>&lt;body&gt;</span> ochilgan teg va <span className='text-tutorial__red'>&lt;/body&gt;</span> yopilgan tegi bor</p>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;body&gt;</span>  elementi ichida <span className='text-tutorial__red'>&lt;h1&gt;</span> va  <span className='text-tutorial__red'>&lt;p&gt;</span>elementlari bor:</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`<h1>Mening birinchi sarlavham</h1>
<p>Mening birinchi xatboshim</p>`} />
        </div>
      </div>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;h1&gt;</span> elementi sarlavhani yozish uchun ishlatiladi.</p>
      <p className='text-tutorial'>Uning <span className='text-tutorial__red'>&lt;h1&gt;</span> ochilgan teg va <span className='text-tutorial__red'>&lt;/h1&gt;</span> yopilgan tegi bor</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`<h1>Mening birinchi sarlavham</h1>`} />
        </div>
      </div>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;p&gt;</span> elementi xat boshini yozish uchun ishlatiladi.</p>
      <p className='text-tutorial'>Uning <span className='text-tutorial__red'>&lt;p&gt;</span> ochilgan teg va <span className='text-tutorial__red'>&lt;/p&gt;</span> yopilgan tegi bor</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`<p>Mening birinchi xatboshim</p>`} />
        </div>
      </div>
      <hr />
      <h3 className='header-content'>Hech qachon yopilgan tegni tushirib qoldirmang</h3>
      <p className='text-tutorial'>Ba'zi HTML elementlari hatto yopilgan tegni unutgan bo'lsangiz ham to'g'ri ko'rsatiladi:</p>
      <CodeContent props={`<!DOCTYPE html>
<html>
<body>

<p>Bu xatboshi
<p>Bu ham xatboshi

</body>
</html>`} pathname={`try/8`} />
      <p className='text-tutorial'><strong>Biroq, hech qachon bunday qilmang! Yopilgan tegni unutib qo'ysangiz, kutilmagan natijalar va xatolar yuzaga kelishi mumkin!</strong></p>
      <hr />
      <h3 className='header-content'>HTML bo'sh elementlari</h3>
      <p className='text-tutorial'>Kontentga ega bo'lmagan elementlar bo'sh elementlar deb ataladi.</p>
      <p className='text-tutorial'> <span className='text-tutorial__red'>&lt;br&gt;</span> elementi matnni keyingi satrga tushirib beradi va yopilgan tegi yo'q.</p>
      <CodeContent props={`<p>Mana bu  <br> matn keyingi satrga tushirildi.</p>`} pathname={`try/9`} />
      <hr />
      <h3 className='header-content'>HTML katta/kichik harflarga sezgir emas</h3>
      <p className='text-tutorial'>HTML teglari katta-kichik harflarga sezgir emas: <span style={{ color: 'red', }}>&lt;P&gt;</span> va <span className='text-tutorial__red'>&lt;p&gt;</span> bir xil degan ma'noni anglatadi.</p>
      <p className='text-tutorial'>HTML standarti kichik harf teglarini talab qilmaydi, lekin kichik harflarni tavsiya qilinadi va XHTML kabi qat'iy hujjat turlari uchun kichik harflarni talab qilinadi .</p>
      <hr />
      <Table header={"HTML teglari ma'lumotnomasi"} text={"Teglar ma'lumotnomasida ushbu teglar va ularning atributlari haqida qo'shimcha ma'lumotlar berilgan."} th={["Teg", "Tavsif"]} values={[
        { id: 0, first: "<html>", second: "HTML hujjatining ildiz elementi", path: "/tags/tag_html" },
        { id: 1, first: "<body>", second: "HTML hujjatining tanasi", path: "/tags/tag_body" },
        { id: 2, first: "<h1> dan <h6> gacha", second: "HTML sarlavhasini bildiradi", path: "/tags/tag_hn" },
      ]} />
      <hr />
      <h3 className='header-content'>Dars videosi:</h3>
      <YoutubeEmbed embedId="WrbF8hgjsxA" />
      <p className='text-tutorial'>Quyidagi maket asosida web sahifasi yaratildi:</p>
      <a style={{ color: 'var(--green)' }} href='https://www.figma.com/file/9etVJTdtRrb5MpcHopt8g5/Autumn' target={'_blank'} rel="noreferrer"> Web sahifa maketi</a>
      <hr />
      <Collapsible>
        <p className='text-tutorial'>Quyidagi maket web sahifasi yaratilsin:</p>
        <a href='https://www.figma.com/file/gvIdLibUGaOjpercnYOTi9/Alisher-Navoiy?node-id=0%3A1' target={'_blank'} rel="noreferrer"> https://www.figma.com/file/gvIdLibUGaOjpercnYOTi9/Alisher-Navoiy?node-id=0%3A1</a>
      </Collapsible>
      <NavButton back='/html/html_basic' next='/html/html_atributes' />
    </article>
  )
}

export default HtmlElements;