import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import ListText from '../../../pages/ListsText';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';


function InputTypes() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_form_input_types.asp"} />
            <h2 className='header-tutorial'>HTML input turlari</h2>
            <NavButton back='/html/html_form_elements' next='/html/html_form_attributes' />
            <hr />

            <p>Ushbu bobda HTML &lt;input&gt; elementining har xil turlari tasvirlangan.</p>
            <hr />
            <TextContent header={"HTML input turlari"}
                text1={"HTMLda foydalanishingiz mumkin bo'lgan turli xil input turlari quyida keltirilgan:"} />
            <ListText list={[{
                text: `<input type="button">`,
                redWords: [`<input`, `type="button">`]
            },
            {
                text: `<input type="checkbox">`,
                redWords: [`<input`, `type="checkbox">`]
            },
            {
                text: `<input type="color">`,
                redWords: [`<input`, `type="color">`]
            },
            {
                text: `<input type="date">`,
                redWords: [`<input`, `type="date">`]
            },
            {
                text: `<input type="datetime-local">`,
                redWords: [`<input`, `type="datetime-local">`]
            },
            {
                text: `<input type="email">`,
                redWords: [`<input`, `type="email">`]
            },
            {
                text: `<input type="file">`,
                redWords: [`<input`, `type="file">`]
            },
            {
                text: `<input type="hidden">`,
                redWords: [`<input`, `type="hidden">`]
            },
            {
                text: `<input type="image">`,
                redWords: [`<input`, `type="image">`]
            },
            {
                text: `<input type="month"`,
                redWords: [`<input`, `type="month"`]
            },
            {
                text: `<input type="number">`,
                redWords: [`<input`, `type="number">`]
            },
            {
                text: `<input type="password">`,
                redWords: [`<input`, `type="password">`]
            },
            {
                text: `<input type="radio">`,
                redWords: [`<input`, `type="radio">`]
            },
            {
                text: `<input type="range">`,
                redWords: [`<input`, `type="range">`]
            },
            {
                text: `<input type="reset">`,
                redWords: [`<input`, `type="reset">`]
            },
            {
                text: `<input type="search">`,
                redWords: [`<input`, `type="search">`]
            },
            {
                text: `<input type="submit">`,
                redWords: [`<input`, `type="submit">`]
            },
            {
                text: `<input type="tel">`,
                redWords: [`<input`, `type="tel">`]
            },
            {
                text: `<input type="text">`,
                redWords: [`<input`, `type="text">`]
            },
            {
                text: `<input type="time">`,
                redWords: [`<input`, `type="time">`]
            },
            {
                text: `<input type="url">`,
                redWords: [`<input`, `type="url">`]
            },
            {
                text: `<input type="week">`,
                redWords: [`<input`, `type="week">`]
            },]} />
            <Notes type={"Maslahat:"} text={"Default holatda type atributining qiymati 'text' bo'ladi."} />
            <hr />
            <TextContent header={"inputning matn turi"}
                text1={`<input type="text"> bir qatorli matn kiritish maydonini belgilaydi:`}
                redWords1={[`<input`, `type="text">`]}
                code1={`<form>
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname">
</form>`} path1={"try/366"}
                text2={"Yuqoridagi HTML kodi brauzerda quyidagicha ko'rsatiladi:"} />
            <form>
                <label htmlFor="fname">Ism:</label><br />
                <input type="text" id="fname" name="fname" /><br />
                <label htmlFor="lname">Familiya:</label><br />
                <input type="text" id="lname" name="lname" />
            </form>
            <hr />

            <TextContent header={"Input Parol kiritish turi"}
                text1={`<input type="password"> parol maydonini belgilaydi:`}
                redWords1={[`<input`, `type="password">`]}
                code1={`<form>
  <label for="username">Foydalanuvchi:</label><br>
  <input type="text" id="username" name="username"><br>
  <label for="pwd">Parol:</label><br>
  <input type="password" id="pwd" name="pwd">
</form>`} path1={"try/367"}
                text2={"Yuqoridagi HTML kodi brauzerda quyidagicha ko'rsatiladi:"} />
            <form>
                <label htmlFor="username">Foydalanuvchi:</label><br />
                <input type={"text"} id="username" name="username" /><br />
                <label htmlFor="pwd">Parol:</label><br />
                <input type={"password"} id="pwd" name="pwd" />
            </form>
            <Notes text={"Parol maydonidagi belgilar yashirin ko'rinishda bo'ladi(yulduzcha yoki doira shaklida ko'rsatiladi)."} />
            <hr />
            <TextContent header={"Inputning Submit turi"}
                text1={`<input type="submit"> forma ma'lumotlarini forma ishlov beruvchisi(form-handler)ga yuborish tugmachasini belgilaydi.`}
                redWords1={[`<input`, `type="submit">`]}
                text2={`Forma ishlov beruvchisi(form-handler) odatda kiritilgan ma'lumotlarni qayta ishlash kodiga ega bo'lgan server sahifasidir.`}
                text3={`Forma ishlov beruvchisi(form-handler) action atributida ko'rsatiladi:`}
                redWords3={[`action`]}
                code3={`<form action="/action_page.php">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
</form>`} path3={"try/368"}
                text4={"Yuqoridagi HTML kodi brauzerda quyidagicha ko'rsatiladi:"} />
            <form action="https://www.w3schools.com/action_page.php" target="_blank">
                <label htmlFor="fname">Ism:</label><br />
                <input type="text" id="fname" name="fname" defaultValue="John" /><br />
                <label htmlFor="lname">Familiya:</label><br />
                <input type="text" id="lname" name="lname" defaultValue="Doe" /><br /><br />
                <input type="submit" value="Yuborish" />
            </form><br />
            <TextContent text1={"Yuborish tugmasining value atributini tushirib qoldirilsa tugma default matnni oladi:"}
                code1={`<form action="/action_page.php">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit">
</form>`}
                path1={"try/369"} />
            <hr />
            <TextContent header={"Input reset turi"}
                text1={`<input type="reset"> barcha forma qiymatlarini default qiymatlariga qaytaradigan qayta yuklash tugmachasini belgilaydi:`}
                redWords1={[`<input`, `type="reset">`]}
                code1={`<form action="/action_page.php">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
  <input type="reset" value="Qayta yuklash">
</form>`} path1={"try/370"}
                text2={"Yuqoridagi HTML kodi brauzerda quyidagicha ko'rsatiladi:"} />
            <form action="https://www.w3schools.com/action_page.php" target="_blank">
                <label htmlFor="fname">Ism:</label><br />
                <input type="text" id="fname" name="fname" defaultValue="John" /><br />
                <label htmlFor="lname">Familiya:</label><br />
                <input type="text" id="lname" name="lname" defaultValue="Doe" /><br /><br />
                <input style={{ marginRight: "10px" }} type="submit" value="Yuborish" />
                <input type="reset" value="Qayta yuklash" />
            </form>
            <Notes text={`Kiritilgan qiymatlar o'zgartirilgandan keyin "Qayta yuklash" tugmasi bosilsa, forma-ma'lumotlari default qiymatlarga qaytariladi.`} />
            <hr />
            <TextContent header={"Inputning radio turi"}
                text1={`<input type="radio"> radio(tanlanuvchi) tugmani belgilaydi.`}
                redWords1={[`<input`, `type="radio">`]}
                text2={"Radio tugmalari foydalanuvchiga berilgan tanlovlardan FAQAT BITTASINI tanlash imkonini beradi:"}
                code2={`<p>Yoqtirgan veb dasturlash tilini tanlang:</p>
<form>
  <input type="radio" id="html" name="fav_language" value="HTML">
  <label for="html">HTML</label><br>
  <input type="radio" id="css" name="fav_language" value="CSS">
  <label for="css">CSS</label><br>
  <input type="radio" id="javascript" name="fav_language" value="JavaScript">
  <label for="javascript">JavaScript</label>
</form>`} path2={"try/371"}
                text3={"Yuqoridagi HTML kodi brauzerda quyidagicha ko'rsatiladi:"} />
            <form>
                <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                <label htmlFor="html">HTML</label><br />
                <input type="radio" id="css" name="fav_language" defaultValue="CSS" />
                <label htmlFor="css">CSS</label><br />
                <input type="radio" id="javascript" name="fav_language" defaultValue="JavaScript" />
                <label htmlFor="javascript">JavaScript</label>
            </form>            <hr />
            <TextContent header={"Inputning Checkbox turi"}
                text1={`<input type="checkbox"> belgilash katakchasi(Checkbox)ni belgilaydi.`}
                redWords1={[`<input`, `type="checkbox">`]}
                text2={"Checkbox tugmalari foydalanuvchiga berilgan tanlovlardan umuman tanlamaslik yoki bir nechtasini tanlash imkonini beradi:"}
                code2={`<form>
  <input type="checkbox" id="vehicle1" name="vehicle1" value="Velosiped">
  <label for="vehicle1">Mening velosipedim bor</label><br>
  <input type="checkbox" id="vehicle2" name="vehicle2" value="Mashina">
  <label for="vehicle2"> Mening mashinam bor</label><br>
  <input type="checkbox" id="vehicle3" name="vehicle3" value="Qayiq">
  <label for="vehicle3"> Mening qayig'im bor</label>
</form>`} path2={"try/372"}
                text3={"Yuqoridagi HTML kodi brauzerda quyidagicha ko'rsatiladi:"} />
            <form>
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Velosiped" />
                <label htmlFor="vehicle1">Mening velosipedim bor</label><br />
                <input type="checkbox" id="vehicle2" name="vehicle2" value="Mashina" />
                <label htmlFor="vehicle2"> Mening mashinam bor</label><br />
                <input type="checkbox" id="vehicle3" name="vehicle3" value="Qayiq" />
                <label htmlFor="vehicle3"> Mening qayig'im bor</label>
            </form>            <hr />
            <TextContent header={"Inputning Button turi"}
                text1={`<input type="button"> button tugmasini belgilaydi.`}
                redWords1={[`<input`, `type="button">`]}
                code2={`<input type="button" onclick="alert('Salom dunyo!')" value="Button tugmasini bosish!">`} path2={"try/374"}
                text3={"Yuqoridagi HTML kodi brauzerda quyidagicha ko'rsatiladi:"} />
            <form>
                <input type="button" onClick={() => { alert("Salom dunyo!"); }} value="Button tugmasini bosish!" />
            </form> <hr />
            <TextContent header={"Inputning color turi"}
                text1={`<input type="color"> rang tanlash maydoni uchun ishlatiladi.`}
                redWords1={[`<input`, `type="color">`]}
                text2={"Brauzer qo'llab-quvvatlashiga qarab, input maydonida rang tanlash vositasi paydo bo'lishi mumkin."}
                code2={`<form>
  <label for="favcolor">Yoqtirgan rangingizni tanlang:</label>
  <input type="color" id="favcolor" name="favcolor">
</form>`} path2={"try/375"} /> <hr />
            <TextContent header={"Input Sana kiritish turi"}
                text1={`<input type="date"> sanani kiritish maydonlari uchun ishlatiladi.`}
                redWords1={[`<input`, `type="date">`]}
                text2={"Brauzer qo'llab-quvvatlashiga qarab, sanani tanlash uchun kalendar input maydonida paydo bo'lishi mumkin."}
                code2={`<form>
  <label for="birthday">Tug'ilgan kun:</label>
  <input type="date" id="birthday" name="birthday">
</form>`}
                path2={"try/376"}
                text3={"Shuningdek , sanalarga cheklovlar qo'shish uchun min va max atributlaridan foydalanishingiz mumkin:"}
                redWords3={["min", "max"]}
                code3={`<form>
  <label for="datemax">1980-01-01 dan oldingi sanani kiriting:</label>
  <input type="date" id="datemax" name="datemax" max="1979-12-31"><br><br>
  <label for="datemin">2000-01-01 dan keyingi sanani kiriting:</label>
  <input type="date" id="datemin" name="datemin" min="2000-01-02">
</form>`}
                path3={`try/377`} />
            <hr />
            <TextContent header={"Inputga Mahalliy sana vaqtini kiritish"}
                text1={`<input type="datetime-local"> vaqt mintaqasi bo'lmagan sana va vaqtni kiritish maydonini belgilaydi.`}
                redWords1={[`<input`, `type="datetime-local">`]}
                text2={"Brauzer qo'llab-quvvatlashiga qarab, sanani tanlash uchun kalendar va soat input maydonida paydo bo'lishi mumkin."}
                code2={`<form>
  <label for="birthdaytime">Tug'ilgan kun(sana va vaqt):</label>
  <input type="datetime-local" id="birthdaytime" name="birthdaytime">
</form>`} path2={"try/378"} />
            <hr />
            <TextContent header={"Inputga Emailni kiritish"}
                text1={`<input type="email"> elektron pochta manzili kiritish maydonlari uchun ishlatiladi.`}
                redWords1={[`<input`, `type="email">`]}
                text2={"Brauzer qo'llab-quvvatlashiga qarab, elektron pochta manzili yuborilganda avtomatik ravishda email to'g'ri kiritilganligi tekshirilishi mumkin."}
                text3={"Ba'zi smartfonlar elektron pochta turini taniydi va elektron pochta kiritishiga mos kelish uchun klaviaturaga '.com' ni qo'shadi."}
                code3={`<form>
  <label for="email">Sizning elektron pochta manzilingiz:</label>
  <input type="email" id="email" name="email">
</form>`} path3={"try/379"} />
            <hr />
            <TextContent header={"Rasmli tugma turi"}
                text1={`<input type="image"> rasmni yuborish tugmasi sifatida belgilaydi.`}
                redWords1={[`<input`, `type="image">`]}
                text2={"Rasm manzili src atributida ko'rsatiladi."}
                redWords2={[`src`]}
                code3={`<form>
<input type="image" src="img_submit.gif" alt="Submit" width="48" height="48">
</form>`} path3={"try/380"} />
            <hr />
            <TextContent header={"Faylni yuklash turi"}
                text1={`<input type="file"> faylni tanlash maydonini va fayl yuklash uchun "Browse" tugmasini belgilaydi.`}
                redWords1={[`<input`, `type="file">`]}
                code1={`<form>
  <label for="myfile">Faylni tanlash:</label>
  <input type="file" id="myfile" name="myfile">
</form>`} path1={"try/381"} />
            <hr />
            <TextContent header={"Input yashirin turi"}
                text1={`<input type="hidden"> yashirin kiritish maydonini belgilaydi(foydalanuvchiga ko'rinmaydi).`}
                redWords1={[`<input`, `type="hidden">`]}
                text2={`Yashirin maydon dasturchilarga forma to'ldirilganda foydalanuvchilar tomonidan ko'rilmaydigan yoki o'zgartirilmaydigan ma'lumotlarni kiritish imkonini beradi.`}
                text3={"Yashirin maydon ko'pincha forma ma'lumotlari jo'natilganda yangilanishi kerak bo'lgan ma'lumotlar bazasi yozuvini saqlaydi."}
                text4={`Eslatma: Yashirin maydon qiymati foydalanuvchiga sahifada ko'rsatilmasa ham, u istalgan brauzerning ishlab chiquvchi vositalari yoki "Manbani ko'rish"("View Source") funksiyasidan foydalangan holda ko'rinadi (va tahrirlanishi mumkin). Yashirin ma'lumotlardan xavfsizlik shakli sifatida foydalanmang!`}
                code4={`<form>
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <input type="hidden" id="custId" name="custId" value="3487">
  <input type="submit" value="Submit">
</form>`} path4={"try/382"} />
            <hr />
            <TextContent header={"Input Oy tanlash turi"}
                text1={`<input type="month"> foydalanuvchiga oy va yilni tanlash imkonini beradi.`}
                redWords1={[`<input`, `type="month">`]}
                text2={`Brauzer qo'llab-quvvatlashiga qarab, sanani tanlagich kiritish maydonida paydo bo'lishi mumkin.`}
                code2={`<form>
  <label for="bdaymonth">Tug'ilgan kun (oy va yil):</label>
  <input type="month" id="bdaymonth" name="bdaymonth">
</form>`} path2={"try/383"} />
            <hr />
            <TextContent header={"Inputning raqam kiritish turi"}
                text1={`<input type="number"> raqam kiritish maydonini belgilaydi.`}
                redWords1={[`<input`, `type="number">`]}
                text2={`Shuningdek, qanday raqamlar qabul qilinishiga cheklovlar o'rnatishingiz mumkin.`}
                text3={"Quyidagi misolda 1 dan 5 gacha raqamlarni kiritish maydoni ko'rsatilgan:"}
                code3={`<form>
  <label for="quantity">Qiymat(1 va 5 oralig'ida):</label>
  <input type="number" id="quantity" name="quantity" min="1" max="5">
</form>`} path3={"try/384"} />
            <hr />
            <TextContent header={"Input cheklovlari"}
                text1={`Quyida ba'zi umumiy input cheklovlari ro'yxati keltirilgan:`} />
            <Table th={["Atribut", "Tavsif"]} values={[
                { id: 0, first: "checked", second: `Sahifani yuklaganda kiritish maydonini oldindan tanlanganligini bildiradi(type ="checkbox" yoki type="radio" uchun)` },
                { id: 1, first: "disabled", second: "Input maydonini o'chirib qo'yilganligini bildiradi" },
                { id: 2, first: "max", second: "Input maydoni uchun maksimal qiymatni belgilaydi" },
                { id: 3, first: "maxlength", second: "Input maydoni uchun maksimal belgilar sonini belgilaydi" },
                { id: 4, first: "min", second: "Input maydoni uchun minimal qiymatni belgilaydi" },
                { id: 5, first: "pattern", second: "Input qiymatini tekshirish uchun qatiy ifodani belgilaydi" },
                { id: 6, first: "readonly", second: "Input maydoni faqat o'qilishini bildiradi (o'zgartirib bo'lmaydi)" },
                { id: 7, first: "required", second: "Input maydoni muhimligini bildiradi(to'ldirilishi shart)" },
                { id: 8, first: "size", second: "Input maydonining kengligini(belgilarda) belgilaydi" },
                { id: 9, first: "step", second: "Input maydoni uchun  oldingi va keyingi raqamlar oralig'ini belgilaydi" },
                { id: 10, first: "value", second: "Input maydoni uchun default qiymatni belgilaydi" },
            ]} />
            <TextContent
                text1={`Keyingi bobda input cheklovlari haqida ko'proq bilib olasiz.`}
                text2={`Quyidagi misolda siz 0 dan 100 gacha qiymatni 10 raqam oralig'ida kiritish mumkin bo'lgan raqamli input maydonini ko'rsatilgan. Default qiymati 30 ga teng:`}
                code2={`<form>
  <label for="quantity">Qiymat:</label>
  <input type="number" id="quantity" name="quantity" min="0" max="100" step="10" value="30">
</form>`} path2={"try/385"} />
            <hr />
            <TextContent header={"Input range turi"}
                text1={`<input type="range"> aniq qiymati muhim bo'lmagan raqamni kiritish uchun boshqaruvni belgilaydi(slayder boshqaruvi kabi). Default holatda diapazon 0 dan 100 gacha. Biroq, min, max va step atributlari bilan qanday raqamlar qabul qilinishini o'rnatishingiz mumkin:`}
                redWords1={[`<input`, `type="range">`, `min`, `max`, `step`]}
               code3={`<form>
  <label for="vol">Miqdori(0 va 50 oralig'ida):</label>
  <input type="range" id="vol" name="vol" min="0" max="50">
</form>`} path3={"try/386"} />
            <hr />
            <TextContent header={"Kirish turini qidirish"}
                text1={`<input type="search"> qidiruv maydonlari uchun ishlatiladi(qidiruv maydoni odatdagi matn maydoni kabi ishlaydi).`}
                redWords1={[`<input`, `type="search">`]}
               code2={`<form>
  <label for="gsearch">Googledan qidirish:</label>
  <input type="search" id="gsearch" name="gsearch">
</form>`} path2={"try/387"} />
            <hr />
            <TextContent header={"Kirish turi Tel"}
                text1={`<input type="tel"> telefon raqami input maydoni uchun ishlatiladi.`}
                redWords1={[`<input`, `type="tel">`]}
               code2={`<form>
  <label for="phone">Telefon nomerini kiriting:</label>
  <input type="tel" id="phone" name="phone" pattern="[0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{2}">
</form>`} path2={"try/388"} />
            <hr />
            <TextContent header={"Inputning vaqt kiritish turi"}
                text1={`<input type="time"> foydalanuvchiga vaqtni tanlash imkonini beradi (vaqt zonasi yo'q).`}
                text2={`Brauzer qo'llab-quvvatlashiga qarab, kiritish maydonida vaqt tanlagichi paydo bo'lishi mumkin.`}
                redWords1={[`<input`, `type="time">`]}
               code2={`<form>
  <label for="appt">Vaqtni belgilang:</label>
  <input type="time" id="appt" name="appt">
</form>`} path2={"try/389"} />
            <hr />
            <TextContent header={"Inputning Url turi"}
                text1={`<input type="url"> URL manzilini kiritish maydonlari uchun ishlatiladi.`}
                redWords1={[`<input`, `type="url">`]}
                text2={`Brauzer qo'llab-quvvatlashiga qarab, url maydoni yuborilganda avtomatik ravishda to'g'ri kiritilganligi tekshirilishi mumkin.`}
                text3={`Ba'zi smartfonlar url turini taniydi va url inputiga mos kelish uchun klaviaturaga ".com" ni qo'shadi.`}
                code3={`<form>
  <label for="homepage">Bosh sahifani qo'shish:</label>
  <input type="url" id="homepage" name="homepage">
</form>`} path3={"try/390"} />
            <hr />
            <TextContent header={"Inputning hafta kiritish turi"}
                text1={`<input type="week"> foydalanuvchiga hafta va yilni tanlash imkonini beradi.                `}
                redWords1={[`<input`, `type="week">`]}
                text2={`Brauzer qo'llab-quvvatlashiga qarab, sanani tanlagich input maydonida paydo bo'lishi mumkin.`}
                code3={`<form>
  <label for="week">Haftani tanlang:</label>
  <input type="week" id="week" name="week">
</form>`} path3={"try/391"} />
            <hr />
            <Table th={["Teg", "Tavsif"]} values={[
                { id: 0, first: `<input type="">`, second: "Input turini belgilaydi", path: "/tags/att_input_type" }]} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="r71vDdO9f7U" />
            <NavButton back='/html/html_form_elements' next='/html/html_form_attributes' />
        </div>)
}

export default InputTypes;