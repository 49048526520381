import React from 'react';
import { Link } from 'react-router-dom';
import CodeContent from '../../../tryit/CodeContent';
import Tegs from '../../pages/tegs';
import NavButton from '../NavButton';
import '../Info.css';
import Collapsible from '../../pages/Collapsible';
import YoutubeEmbed from '../YoutubeEmbed';
import Table from '../../pages/Table';
import W3Path from '../../W3Path';


function HtmlHeadings() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_headings.asp"}/>
      <h2 className='header-tutorial'>HTML sarlavhalari </h2>
      <NavButton back='/html/html_atributes' next='/html/html_paragraphs' />
      <hr />
      <p className='text-tutorial text-tutorial-margin'>Veb-sahifa sarlavhalari yoki qo'shimcha sarlavhalar HTML sarlavhalari deyiladi.</p>
      <hr />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper'>
          <h1>Bu sarlavha 1</h1>
          <h2>Bu sarlavha 2</h2>
          <h3>Bu sarlavha 3</h3>
          <h4>Bu sarlavha 4</h4>
          <h5>Bu sarlavha 5</h5>
          <h6>Bu sarlavha 6</h6>
        </div>
        <Link className='button btn btn--large green' to={`try/3`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <h3 className='header-content'>HTML sarlavhalari</h3>
      <p className='text-tutorial'>HTML sarlavhalari <Tegs tegname="h1 " />dan <Tegs tegname="h6 " />gacha teglar bilan belgilanadi .</p>
      <p className='text-tutorial'><Tegs tegname="h1 " />mantiqiy ma'nosi yuqoriroq sarlavhani belgilaydi. <Tegs tegname="h6 " />mantiqiy ma'nosi kamroq sarlavhani belgilaydi: </p>
      <CodeContent props={`<h1>Bu sarlavha 1</h1>
<h2>Bu sarlavha 2</h2>
<h3>Bu sarlavha 3</h3>
<h4>Bu sarlavha 4</h4>
<h5>Bu sarlavha 5</h5>
<h6>Bu sarlavha 6</h6>
`} pathname={`try/3`} />
      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong> Brauzerlar sarlavhadan oldin va keyin avtomatik ravishda boʻsh joy qoʻshadi.</p>
      </div>
      <hr />
      <h3 className='header-content'>Muhim sarlavhalar</h3>
      <p className='text-tutorial'>Qidiruv tizimi sahifaning tuzilishi va mazmunini aniqlash uchun sarlavhalardan foydalanadi.</p>
      <p className='text-tutorial'>Foydalanuvchilar ko'pincha sahifani sarlavhalari bo'yicha ko'rib chiqishadi. Hujjat tuzilishini ko'rsatish uchun sarlavhalardan foydalanish muhim hisoblanadi.</p>
      <p className='text-tutorial'><Tegs tegname="h1 " />asosiy sarlavha uchun ishlatish kerak, undan keyin <Tegs tegname="h2 " />  va undan keyin muhimligi kamroq <Tegs tegname="h3 " /> va hakazo </p>
      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong> HTML sarlavhalaridan faqat sarlavhalar uchun foydalaning. Matnni <strong>KATTA</strong> yoki <strong>qalin</strong> qilish uchun sarlavhalardan foydalanmang .</p>
      </div>
      <hr />
      <h3 className='header-content'>Katta sarlavhalar</h3>
      <p className='text-tutorial'>Har bir HTML sarlavhasi standart o'lchamga ega. Biroq, siz <span className='text-tutorial__red'>style</span> atributidan foydalanib, har qanday sarlavha uchun CSS <span className='text-tutorial__red'>font-size</span> o'lchamini o'zgartirishingiz mumkin:</p>
      <CodeContent props={`<h1 style="font-size:60px;">Sarlavha 1</h1>`} pathname={`try/18`} />
      <hr />
      <Table header={"HTML teglari ma'lumotnomasi"} text={"Teglar ma'lumotnomasida ushbu teglar va ularning atributlari haqida qo'shimcha ma'lumotlar berilgan."} th={["Teg", "Tavsif"]} values={[
        { id: 0, first: "<html>", second: "HTML hujjatining ildiz elementi", path: "/tags/tag_html" },
        { id: 1, first: "<body>", second: "HTML hujjatining tanasi", path: "/tags/tag_body" },
        { id: 2, first: "<h1> dan <h6> gacha", second: "HTML sarlavhasini bildiradi", path: "/tags/tag_hn" },
      ]} />
      <hr />
      <h3 className='header-content'>Dars videosi:</h3>
      <YoutubeEmbed embedId="TSF8kJvwBj0" />
      <a style={{ color: 'var(--green)' }} href='https://www.figma.com/file/qnZwLxF2c6GToUDrp5MoS7/Educa' target={'_blank'} rel="noreferrer"> Web sahifa maketi</a>
      <hr />
      <Collapsible>
        <p className='text-tutorial'>Quyidagi maket web sahifasi yaratilsin:</p>
        <a href='https://www.figma.com/file/lrr8xpEwCLqEP3UHw7H6gO/bkr' target={'_blank'} rel="noreferrer"> https://www.figma.com/file/lrr8xpEwCLqEP3UHw7H6gO/bkr</a>
      </Collapsible>
      <NavButton back='/html/html_attributes' next='/html/html_paragraphs' />
    </article>
  )
}

export default HtmlHeadings;