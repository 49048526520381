import React from 'react';
import { Link } from 'react-router-dom';
import BrowserTable from '../../pages/BrowserTable';
import Tegs from '../../pages/tegs';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';

function Tagcaption() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_caption.asp"} />
      <h2 className='header-tutorial'>HTML <Tegs tegname={"caption"} /> tegi</h2>
      <NavButton back='/tags/tag_canvas' next='/tags/tag_center' />
      <TextContent
        code1={`<table>
    <caption>Oylik jamg'armalar</caption>
    <tr>
        <th>Oylar</th>
        <th>Jamg'arma</th>
    </tr>
    <tr>
        <td>Yanvar</td>
        <td>$100</td>
    </tr>
    <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>`}
        path1={"try/140"}
        code1Text={"Sarlavhali jadval:"}
      />
      <hr />
      <TextContent header={"Ta'rifi va qo'llanilishi"}
        text1={"<caption> tegi HTML jadvalidagi sarlavha belgilaydi."}
        redWords1={['<caption>']} />
      <p className='text-tutorial'>&lt;caption&gt; tegi <Link className='table-link' to={"/tags/tag_table"}>&lt;table&gt;</Link> tegidan keyin kiritilishi kerak.</p>
      <p className='text-tutorial'><b>Maslahat: </b>&lt;caption&gt; elementi default holatida jadval tepasida markazda joylashgan bo'ladi. CSS xususiyatlari  <Link className='table-link' to={"/cssref/pr_text_text-align"}>text-align</Link> va  <Link className='table-link' to={"/cssref/pr_tab_caption-side"}>caption-side</Link> yordamida sarlavha tekisligi va joylashishini o'zgartirish mumkin.</p>
      <hr />
      <BrowserTable element='<caption>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />
      <TextContent header={"Global atributlar"} />
      <p className='text-tutorial'>&lt;caption&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <TextContent header={"Hodisa atributlari"} />
      <p className='text-tutorial'>&lt;caption&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>

      <TextContent header={"Misollar"}
        code1={`<table>
<caption style="text-align:right">Mening jamg'armam</caption>
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
  <td>Yanvar</td>
  <td>$100</td>
</tr>
</table>
<br>

<table>
<caption style="caption-side:bottom">Mening jamg'armam</caption>
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
  <td>Yanvar</td>
  <td>$100</td>
</tr>
</table>`}
        path1={"try/192"}
        code1Text={"Sarlavha joylashuvini o'zgartirish(CSS bilan):"} />
      <TextContent header={"Tegishli sahifalar"} />
      <p className='text-tutorial'>HTML DOM: <Link className='table-link' to={"/jsref/dom_obj_caption"}>Sarlavha obyekti</Link></p>
      <TextContent header={"Default CSS sozlamalari"}
        text1={"Ko'p brauzerlarda <caption> elementida quyidagi default stillar berilgan bo'ladi:"}
        redWords1={['<caption>']}
        css1={`caption {
 display: table-caption;
 text-align: center;
}`}
        path1={"try/193"} />
      <NavButton back='/tags/tag_canvas' next='/tags/tag_center' />
    </article>)
}

export default Tagcaption;
