import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Css from './component/Tutorials/Css';
import Home from './component/pages/Home';
import Html from './component/Tutorials/Html';
import Navbar from './component/pages/Navbar';
import JavaScript from './component/Tutorials/JavaScript';
import Videos from './component/Tutorials/Videos';
import HtmlIntro from './component/Tutorials/HtmlPages/HtmlIntro';
import HtmlEditor from './component/Tutorials/HtmlPages/HtmlEditor';
import HtmlHome from './component/Tutorials/HtmlPages/HtmlHome';
import ErrorPage from './component/Tutorials/ErrorPage';
import TryIt from './tryit';
import HtmlElements from './component/Tutorials/HtmlPages/htmlElements';
import HtmlAttributes from './component/Tutorials/HtmlPages/HtmlAtributes/htmlAttributes';
import HtmlHeadings from './component/Tutorials/HtmlPages/htmlHeadings';
import HtmlParagraphs from './component/Tutorials/HtmlPages/htmlParagraphs';
import HtmlBasic from './component/Tutorials/HtmlPages/htmlbasic';
import HtmlStyles from './component/Tutorials/HtmlPages/HtmlStyles';
import HtmlFormatting from './component/Tutorials/HtmlPages/HtmlFormatting';
import HtmlQuatation from './component/Tutorials/HtmlPages/htmlQuotation';
import HtmlComments from './component/Tutorials/HtmlPages/htmlComments';
import HtmlColor from './component/Tutorials/HtmlPages/HtmlColor';
import HtmlRgb from './component/Tutorials/HtmlPages/HtmlRgb';
import HtmlHex from './component/Tutorials/HtmlPages/HtmlHex';
import HtmlHsl from './component/Tutorials/HtmlPages/HtmlHsl';
import HtmlCss from './component/Tutorials/HtmlPages/HtmlCss';
import Color from './component/Tutorials/Colors/ColorHome';
import ColorNames from './component/Tutorials/Colors/ColorNames';
import TryItColor from './component/Tutorials/Colors/TryItColor';
import ColorValue from './component/Tutorials/Colors/ColorValue';
import ColorGroups from './component/Tutorials/Colors/ColorGroups';
import Tags from './component/Tutorials/Tags/TagsHome';
import TagHtm from './component/Tutorials/Tags/TagHtm';
import TagStyle from './component/Tutorials/Tags/TagStyle';
import TagLink from './component/Tutorials/Tags/TagLink';
import Links from './component/Tutorials/HtmlPages/Links/Links';
import Taga from './component/Tutorials/Tags/Taga';
import LinkColor from './component/Tutorials/HtmlPages/Links/LinkColor';
import LinkBookmark from './component/Tutorials/HtmlPages/Links/LinkBookmark';
import Tagb from './component/Tutorials/Tags/Tagb';
import Images from './component/Tutorials/HtmlPages/Images/Images';
import ImagesMap from './component/Tutorials/HtmlPages/Images/ImagesMap';
import Computer from './component/Tutorials/Computer';
import Phone from './component/Tutorials/CellPhone';
import Coffee from './component/Tutorials/Cofee';
import Croissant from './component/Tutorials/Croission';
import ImagesBg from './component/Tutorials/HtmlPages/Images/ImagesBackGr';
import Picture from './component/Tutorials/HtmlPages/Images/Picture';
import Tagarea from './component/Tutorials/Tags/Tagarea';
import Tagmap from './component/Tutorials/Tags/Tagmap';
import Sun from './component/Tutorials/Sun';
import Mercury from './component/Tutorials/Mercury';
import Venus from './component/Tutorials/Venus';
import Tagimg from './component/Tutorials/Tags/Tagimg';
import Tagpicture from './component/Tutorials/Tags/Tagpicture';
import Favicon from './component/Tutorials/HtmlPages/FaviconHtml';
import HtmlTables from './component/Tutorials/HtmlPages/HtmlTables/HtmlTables';
import Tagtable from './component/Tutorials/Tags/Tagtable';
import Tagth from './component/Tutorials/Tags/Tagth';
import Tagtr from './component/Tutorials/Tags/Tagtr';
import Tagtd from './component/Tutorials/Tags/Tagtd';
import HtmlBorder from './component/Tutorials/HtmlPages/HtmlTables/HtmlBorder';
import TableSizes from './component/Tutorials/HtmlPages/HtmlTables/TableSizes';
import PaddingSpacing from './component/Tutorials/HtmlPages/HtmlTables/PaddingSpacing';
import ColspanRowspan from './component/Tutorials/HtmlPages/HtmlTables/ColspanRowspan';
import TableHeader from './component/Tutorials/HtmlPages/HtmlTables/TableHeader';
import TableStyle from './component/Tutorials/HtmlPages/HtmlTables/TableStyle';
import Colgroup from './component/Tutorials/HtmlPages/HtmlTables/Colgroup';
import Tagcaption from './component/Tutorials/Tags/Tagcaption';
import Tagcolgroup from './component/Tutorials/Tags/Tagcolgroup';
import Tagcol from './component/Tutorials/Tags/Tagcol';
import Tagthead from './component/Tutorials/Tags/Tagthead';
import Tagtbody from './component/Tutorials/Tags/Tagtbody';
import Tagtfoot from './component/Tutorials/Tags/Tagtfoot';
import Lists from './component/Tutorials/HtmlPages/HtmlLists/List';
import UnorderedLists from './component/Tutorials/HtmlPages/HtmlLists/UnorderedLists';
import OrderedLists from './component/Tutorials/HtmlPages/HtmlLists/OrderedLists';
import OtherLists from './component/Tutorials/HtmlPages/HtmlLists/OtherLists';
import BlockInline from './component/Tutorials/HtmlPages/Block';
import ClassHtml from './component/Tutorials/HtmlPages/ClassHtml';
import IdHtml from './component/Tutorials/HtmlPages/IdHtml';
import IFrame from './component/Tutorials/HtmlPages/IFrame';
import Demo from './component/Tutorials/Demo';
import JsHtml from './component/Tutorials/HtmlPages/JsHtml';
import FilePath from './component/Tutorials/HtmlPages/FilePath';
import Head from './component/Tutorials/HtmlPages/Head';
import Layout from './component/Tutorials/HtmlPages/Layout/Layout';
import Responsive from './component/Tutorials/HtmlPages/Responsive/Responsive';
import ComputerCode from './component/Tutorials/HtmlPages/ComputerCode';
import Semantic from './component/Tutorials/HtmlPages/Semantic';
import StyleGuide from './component/Tutorials/HtmlPages/StyleGuide';
import Enteties from './component/Tutorials/HtmlPages/Enteties';
import Symbols from './component/Tutorials/HtmlPages/Symbols';
import Emojis from './component/Tutorials/HtmlPages/Emojis';
import Charset from './component/Tutorials/HtmlPages/Charset';
import UrlEncode from './component/Tutorials/HtmlPages/UrlEncode';
import XtmlPage from './component/Tutorials/HtmlPages/XtmlPage';
import HtmlForm from './component/Tutorials/HtmlPages/Forms/HtmlForms';
import FormAtribute from './component/Tutorials/HtmlPages/Forms/FormAtributes';
import FormElements from './component/Tutorials/HtmlPages/Forms/FormElements';
import InputTypes from './component/Tutorials/HtmlPages/Forms/InputTypes';
import InputAttr from './component/Tutorials/HtmlPages/Forms/InputAttributs';
import FormAttr from './component/Tutorials/HtmlPages/Forms/InputFormAtributes';
import Canvas from './component/Tutorials/HtmlPages/HtmlGraphics/HtmlCanvas';
import SvgHtml from './component/Tutorials/HtmlPages/HtmlGraphics/HtmlSvg';
import HtmlMedia from './component/Tutorials/HtmlPages/HtmlMedia/HtmlMedia';
import HtmlVideo from './component/Tutorials/HtmlPages/HtmlMedia/HtmlVideo';
import HtmlAudio from './component/Tutorials/HtmlPages/HtmlMedia/HtmlAudio';
import Plagins from './component/Tutorials/HtmlPages/HtmlMedia/Plagins';
import YouTube from './component/Tutorials/HtmlPages/HtmlMedia/Youtube';
import HtmlGeolocation from './component/Tutorials/HtmlPages/Api/HtmlGeolocation';
import Dragdrop from './component/Tutorials/HtmlPages/Api/DragDrop';
// import WebStorage from './component/Tutorials/HtmlPages/Api/WebStorage';
// import Webworker from './component/Tutorials/HtmlPages/Api/Webworker';
// import Htmlsse from './component/Tutorials/HtmlPages/Api/Htmlsse';



// Html.js-Html mavzular ro'yhati

function Path() {
  const pathnameId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
  return (
    <BrowserRouter>
      <Routes>
        <Route path={window.location.pathname} element={<TryIt props={pathnameId} />} />
      </Routes>
    </BrowserRouter>
  )
}

function pathSlice() {
  return window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1);
}

function App() {

  if (window.location.pathname.indexOf('/try') === -1) {
    // TODO HOC
    return (
      <>
        <BrowserRouter>
          <Navbar />
          <div className='main-content'>
            <Routes>
              {/* HTML Routes start */}
              <Route path='/' element={<Home />} />
              <Route path='/html' element={<Html />} >
                <Route path='' element={<HtmlHome />} />
                <Route path='html_intro' element={<HtmlIntro />} />
                <Route path='html_editor' element={<HtmlEditor />} />
                <Route path='html_basic' element={<HtmlBasic />} />
                <Route path='html_elements' element={<HtmlElements />} />
                <Route path='html_atributes' element={<HtmlAttributes />} />
                <Route path='html_headings' element={<HtmlHeadings />} />
                <Route path='html_paragraphs' element={<HtmlParagraphs />} />
                <Route path='html_styles' element={<HtmlStyles />} />
                <Route path='html_formatting' element={<HtmlFormatting />} />
                <Route path='html_quotation_elements' element={<HtmlQuatation />} />
                <Route path='html_comments' element={<HtmlComments />} />
                <Route path='html_colors' element={<HtmlColor />} />
                <Route path='html_colors_rgb' element={<HtmlRgb />} />
                <Route path='html_colors_hex' element={<HtmlHex />} />
                <Route path='html_colors_hsl' element={<HtmlHsl />} />
                <Route path='html_css' element={<HtmlCss />}
                />
                <Route path='html_links' element={<Links />} />
                <Route path='html_links_colors' element={<LinkColor />} />
                <Route path='html_links_bookmarks' element={<LinkBookmark />} />
                <Route path='html_images' element={<Images />} />
                <Route path='html_images_imagemap' element={<ImagesMap />} />
                <Route path='computer.htm' element={<Computer />} />
                <Route path='phone.htm' element={<Phone />} />
                <Route path='coffee.htm' element={<Coffee />} />
                <Route path='croissant.htm' element={<Croissant />} />
                <Route path='sun.htm' element={<Sun />} />
                <Route path='mercur.htm' element={<Mercury />} />
                <Route path='venus.htm' element={<Venus />} />
                <Route path='demo.htm' element={<Demo />} />
                <Route path='html_images_background' element={<ImagesBg />} />
                <Route path='html_images_picture' element={<Picture />} />
                <Route path='html_favicon' element={<Favicon />} />
                <Route path='html_tables' element={<HtmlTables />} />
                <Route path='html_table_borders' element={<HtmlBorder />} />
                <Route path='html_table_sizes' element={<TableSizes />} />
                <Route path='html_table_padding_spacing' element={<PaddingSpacing />} />
                <Route path='html_table_colspan_rowspan' element={<ColspanRowspan />} />
                <Route path='html_table_headers' element={<TableHeader />} />
                <Route path='html_table_styling' element={<TableStyle />} />
                <Route path='html_table_colgroup' element={<Colgroup />} />
                <Route path='html_lists' element={<Lists/>} />
                <Route path='html_lists_unordered' element={<UnorderedLists/>} />
                <Route path='html_lists_ordered' element={<OrderedLists/>} />
                <Route path='/html/html_lists_other' element={<OtherLists/>} />
                <Route path='/html/html_blocks' element={<BlockInline/>} />
                <Route path='/html/html_id' element={<IdHtml/>} />
                <Route path='/html/html_iframe' element={<IFrame/>} />
                <Route path='/html/html_classes' element={<ClassHtml/>} />
                <Route path='/html/html_js' element={<JsHtml/>} />
                <Route path='/html/html_file_path' element={<FilePath/>} />
                <Route path='/html/html_head' element={<Head/>} />
                <Route path='/html/html_layout' element={<Layout/>} />
                <Route path='/html/html_responsive' element={<Responsive/>} />
                <Route path='/html/html_computercode' element={<ComputerCode/>} />
                <Route path='/html/html_semantic' element={<Semantic/>} />
                <Route path='/html/html_syntax' element={<StyleGuide/>} />
                <Route path='/html/html_obects' element={<Enteties/>} />
                <Route path='/html/html_symbols' element={<Symbols/>} />
                <Route path='/html/html_emoji' element={<Emojis/>} />
                <Route path='/html/html_charset' element={<Charset/>} />
                <Route path='/html/html_urlen_code' element={<UrlEncode/>} />
                <Route path='/html/html_xtml' element={<XtmlPage/>} />
                <Route path='/html/html_forms' element={<HtmlForm/>} />
                <Route path='/html/html_form_atribut' element={<FormAtribute/>} />
                <Route path='/html/html_form_elements' element={<FormElements/>} />
                <Route path='/html/html_input' element={<InputTypes/>} />
                <Route path='/html/html_input_atributes' element={<InputAttr/>} />
                <Route path='/html/html_input_form' element={<FormAttr/>} />
                <Route path='/html/html_svg' element={<SvgHtml/>} />
                <Route path='/html/html_canvas' element={<Canvas/>} />
                <Route path='/html/html_media' element={<HtmlMedia/>} />
                <Route path='/html/html5_video' element={<HtmlVideo/>} />
                <Route path='/html/html5_audio' element={<HtmlAudio/>} />
                <Route path='/html/html_object' element={<Plagins/>} />
                <Route path='/html/html_youtube' element={<YouTube/>} />
                <Route path='/html/html5_geolocation' element={<HtmlGeolocation/>} />
                <Route path='/html/html5_draganddrop' element={<Dragdrop/>} />
                {/* <Route path='/html/html5_webstorage' element={<WebStorage/>} />
                <Route path='/html/html5_webworkers' element={<Webworker/>} />
                <Route path='/html/html5_serversentevents' element={<Htmlsse/>} /> */}
                <Route path='*' element={<ErrorPage />} />
                {/* TODO route path do easy */}
              </Route>
              {/* HTML Routes End */}

              {/* CSS Routes start */}
              <Route path='/css' element={<Css />} >
                <Route path='*' element={<ErrorPage />} />
              </Route>
              {/* CSS Routes End */}

              {/* JS Routes start */}
              <Route path='/js' element={<JavaScript />}>
                <Route path='*' element={<ErrorPage />} />
              </Route>

              {/* JS Routes End */}
              <Route path='/videos' element={<Videos />}>
                <Route path='*' element={<ErrorPage />} />
              </Route>
              {/* COLORs Routes Start */}
              <Route path='/colors' element={<Color />} >
                <Route path='colors_names' element={<ColorNames />}>
                  <Route path='*' element={<TryItColor path={pathSlice()} />} />
                </Route>
                <Route path='colors_hex' element={<ColorValue />}>
                  <Route path='*' element={<TryItColor path={pathSlice()} />} />
                </Route>
                <Route path='colors_groups' element={<ColorGroups />}>
                  <Route path='*' element={<TryItColor path={pathSlice()} />} />
                </Route>
                <Route path='*' element={<ErrorPage />} />
              </Route>
              {/* COLORs Routes End */}

              {/* TAGs Routes Start */}
              <Route path='/tags' element={<Tags />} >
                <Route path='ref_colornames' element={<ColorNames />}>
                  <Route path='*' element={<TryItColor path={pathSlice()} />} />
                </Route>

                <Route path='tag_html' element={<TagHtm />} />
                <Route path='tag_style' element={<TagStyle />} />
                <Route path='tag_link' element={<TagLink />} />
                <Route path='tag_a' element={<Taga />} />
                <Route path='tag_b' element={<Tagb />} />
                <Route path='tag_area' element={<Tagarea />} />
                <Route path='tag_map' element={<Tagmap />} />
                <Route path='tag_img' element={<Tagimg />} />
                <Route path='tag_picture' element={<Tagpicture />} />
                <Route path='tag_table' element={<Tagtable />} />
                <Route path='tag_th' element={<Tagth />} />
                <Route path='tag_tr' element={<Tagtr />} />
                <Route path='tag_td' element={<Tagtd />} />
                <Route path='tag_caption' element={<Tagcaption />} />
                <Route path='tag_colgroup' element={<Tagcolgroup />} />
                <Route path='tag_col' element={<Tagcol />} />
                <Route path='tag_thead' element={<Tagthead />} />
                <Route path='tag_tbody' element={<Tagtbody />} />
                <Route path='tag_tfoot' element={<Tagtfoot />} />
                <Route path='*' element={<ErrorPage />} />
              </Route>
              {/* TAGs Routes End */}

              <Route path='*' element={<ErrorPage />} />
            </Routes>

          </div>
        </BrowserRouter>
      </>
    );
  }

  else {
    return (
      <>
        {Path()}
      </>
    )
  }
}

export default App;
