import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';
import logo from '../../images/selfstudylogo.svg';


function Navbar() {
  const [click, setclick] = useState(false);
  const handleClick = () => setclick(!click);
  const closeMobileMenu = () => setclick(false);
  // TODO qidiruv qismi account
  return (
    <header className="navbar">
      <div className="navbar-container container">
        <Link to='/' className='logo navbar-logo' onClick={closeMobileMenu}>
          <img src={logo} alt="logo" style={{ height: "60px", width: "140px" }} />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </div>
   <nav className='sitenav'>
   <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className="nav-item">
            <Link to='/' className="nav-links" onClick={closeMobileMenu}>Bosh sahifa</Link>
          </li>
          <li className="nav-item">
            <Link to='/html' className="nav-links" onClick={closeMobileMenu}>HTML</Link>
          </li>
          <li className="nav-item">
            <Link to='/css' className="nav-links" onClick={closeMobileMenu}>Css</Link>
          </li>
          <li className="nav-item">
            <Link to='/js' className="nav-links" onClick={closeMobileMenu}>JavaScript</Link>
          </li>
          <li className="nav-item">
            <Link to='/colors' className="nav-links" onClick={closeMobileMenu}>Ranglar</Link>
          </li>
        </ul>
   </nav>
      </div>
    </header>
  )
}

export default Navbar;