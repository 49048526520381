import React from 'react';
import { Link } from 'react-router-dom';
import BrowserTable from '../../pages/BrowserTable';
import ListText from '../../pages/ListsText';
import Tegs from '../../pages/tegs';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import TagTable from './AttrTable';

function Tagtd() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_td.asp"} />
      <h2 className='header-tutorial'>HTML <Tegs tegname={"td"} /> tegi</h2>
      <NavButton back='/tags/tag_tbody' next='/tags/tag_template' />
      <TextContent
        code1={`<table>
<tr>
  <td>Katak A</td>
  <td>Katak B</td>
</tr>
<tr>
  <td>Katak C</td>
  <td>Katak D</td>
</tr>
</table>`}
        path1={"try/156"}
        code1Text={"Ikki qator va to'rtta yecheykadan iborat oddiy HTML jadvali:"}
      />
      <hr />
      <TextContent header={"Ta'rifi va qo'llanilishi"}
        text1={"<td> tegi HTML jadvalidagi ustunni belgilaydi."}
        redWords1={['<td>']}
        text2={"Html jadvali 2 xil ustundan iborat:"} />
      <ListText list={[{
        text: "Sarlavha ustuni - sarlavha ma'lumotlarini o'z ichiga oladi( <th> element bilan yaratiladi)",
        redWords: ["<th>"]
      },
      {
        text: "Ma'lumotlar ustuni - ma'lumotlarni o'z ichiga oladi ( <td> elementi bilan yaratilgan)",
        redWords: ["<td>"]
      },]} />
      <TextContent
        text1={"<th> elementidagi matn default holatida qalin va markazlashtirilgan bo'ladi."}
        redWords1={['<th>']}
        text2={"<td> elementidagi matn default holatida odatiy va chap tomonga tekislangan bo'ladi."}
        redWords2={['<td>']} />
      <hr />
      <BrowserTable element='<td>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />
      <TagTable arr={['colspan', 'headers', 'rowspan']} />
      <TextContent header={"Global atributlar"} />
      <p className='text-tutorial'>&lt;td&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <TextContent header={"Hodisa atributlari"} />
      <p className='text-tutorial'>&lt;td&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>

      <TextContent header={"Misollar"}
        code1={`<table style="width:100%">
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
  <td>Yanvar</td>
  <td style="text-align:right">$100</td>
</tr>
<tr>
  <td>Fevral</td>
  <td style="text-align:right">$80</td>
</tr>
</table>`}
        path1={"try/157"}
        code1Text={"<td> ichidagi tarkibni tekislash(CSS bilan):"}
        code2={`<table>
 <tr>
   <th>Oylar</th>
   <th>Jamg'arma</th>
 </tr>
 <tr>
   <td style="background-color:#FF0000">Yanvar</td>
   <td style="background-color:#00FF00">$100</td>
 </tr>
</table>`}
        path2={"try/158"}
        code2Text={"<td> elementiga fon rangini berish(CSS bilan):"}
        code3={`<table>
 <tr>
   <th>Oylar</th>
   <th>Jamg'arma</th>
 </tr>
 <tr>
   <td style="height:100px">Yanvar</td>
   <td style="height:100px">$100</td>
 </tr>
</table>`}
        path3={"try/159"}
        code3Text={"Jadval ustunining balandligini o'rnatish(CSS bilan):"}
        code4={`<table>
<tr>
  <th>Matn</th>
</tr>
<tr>
  <td style="white-space:nowrap">Hech qachon biror narsani tushuntirish uchun zarur bo'lgan ob'ektlar sonini ko'paytirmang</td>
</tr>
</table>`}
        path4={"try/160"}
        code4Text={"Jadval katakchasidagi so'zlarni keyingi qatorga tushmaydigan qilish(CSS bilan):"} />

      <TextContent code1={`<table style="width:50%;">
  <tr>
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr style="height:100px">
    <td style="vertical-align:bottom">Yanvar</td>
    <td style="vertical-align:bottom">$100</td>
  </tr>
</table>`}
        path1={"try/161"}
        code1Text={"<td> ichidagi tarkibni vertikal tekislash (CSS bilan):"}
        code2={`<table style="width:100%">
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
  <td style="width:70%">Yanvar</td>
  <td style="width:30%">$100</td>
</tr>
</table>`}
        path2={"try/162"}
        code2Text={"Jadval katakchasining kengligini o'rnatish(CSS bilan):"}
        code3={`<table>
<tr>
  <th>Ism</th>
  <th>Email</th>
  <th>Telefon</th>
</tr>
<tr>
  <td>John Doe</td>
  <td>john.doe@example.com</td>
  <td>123-45-678</td>
</tr>
</table>`}
        path3={"try/149"}
        code3Text={"Jadval sarlavhalarini yaratish"}
        code4={`<table>
<caption>Oylik jamg'armalar</caption>
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>`}
        path4={"try/150"}
        code4Text={"Sarlavhali jadvalni yaratish"} />
      <TextContent code1={`<table>
  <tr>
    <th>Ism</th>
    <th>Email</th>
    <th colspan="2">Telefon</th>
  </tr>
  <tr>
    <td>John Doe</td>
    <td>john.doe@example.com</td>
    <td>123-45-678</td>
    <td>212-00-546</td>
  </tr>
</table>`}
        path1={"try/151"}
        code1Text={"Bir qator yoki bir ustundan ortiq bo'lgan jadval yecheykalarini yaratish"} />
      <TextContent header={"Tegishli sahifalar"} />
      <p className='text-tutorial'>HTML darslari: <Link className='table-link' to={"/html/html_tables"}>HTML jadvali</Link></p>
      <p className='text-tutorial'>HTML DOM: <Link className='table-link' to={"/jsref/dom_obj_table"}>Jadval obyekti</Link></p>
      <p className='text-tutorial'>CSS darslari: <Link className='table-link' to={"/css/css_table"}>Jadvali stilli</Link></p>
      <TextContent header={"Default CSS sozlamalari"}
        text1={"Ko'p brauzerlarda <td> elementida quyidagi default stillar berilgan bo'ladi:"}
        redWords1={['<td>']}
        css1={`td {
display: table-cell;
vertical-align: inherit;
}`} />
      <NavButton back='/tags/tag_tbody' next='/tags/tag_template' />
    </article>)
}

export default Tagtd;
