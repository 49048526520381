import React from 'react'

function Tegs(props) {
  return (
    <>
      <span style={{ color: "brown" }}><span style={{ color: "mediumblue" }}>&lt;</span>{props.tegname}<span style={{ color: "mediumblue" }}>&gt;</span></span>
    </>
  )
}

export default Tegs
