import React from 'react';
import { Link } from 'react-router-dom';
import BrowserTable from '../../pages/BrowserTable';
import NoteWhite from '../../pages/NoteWhite';
import Tegs from '../../pages/tegs';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';

function Tagthead() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_thead.asp"} />
      <h2 className='header-tutorial'>HTML <Tegs tegname={"thead"} /> tegi</h2>
      <NavButton back='/tags/tag_th' next='/tags/tag_time' />
      <TextContent
        code1={`<table>
 <thead>
   <tr>
     <th>Oylar</th>
     <th>Jamg'arma</th>
   </tr>
 </thead>
 <tbody>
   <tr>
     <td>Yanvar</td>
     <td>$100</td>
   </tr>
   <tr>
     <td>Fevral</td>
     <td>$80</td>
   </tr>
 </tbody>
 <tfoot>
   <tr>
     <td>Sum</td>
     <td>$180</td>
   </tr>
 </tfoot>
</table>`}
        path1={"try/200"}
        code1Text={"thead, tbody va tfoot elementlari:"}
      />
      <hr />
      <TextContent header={"Ta'rifi va qo'llanilishi"}
        text1={"<thead> tegi HTML jadvalidagi sarlavha tarkibini guruhlash uchun ishlatiladi."} redWords1={["<thead>"]} />
      <p className='text-tutorial'>&lt;thead&gt; elementi <Link className='table-link' to={"/tags/tag_tbody"}>&lt;tbody&gt;</Link> va <Link className='table-link' to={"/tags/tag_tfoot"}>&lt;tfoot&gt;</Link>  elementlari bilan birgalikda jadvalning  qism(sarlavha qism, asosiy qism, pastki qism)larini belgilash uchun ishlatiladi.</p>
      <TextContent text1={"Brauzerlar ushbu elementlardan jadval tanasini sarlavha va pastki qismdan mustaqil ravishda aylantirish uchun foydalanishi mumkin. Bundan tashqari, bir nechta sahifani o'z ichiga olgan katta jadvalni chop etishda ushbu thead va tfoot elementlari jadval har bir sahifaning yuqori va pastki qismida chop etish imkonini beradi."} />
      <p className='text-tutorial'> <b>Eslatma:</b>&lt;thead&gt; elementi bir yoki bir nechta <Link className='table-link' to={"/tags/tag_tr"}>&lt;tr&gt;</Link> tegini o'z ichiga oladi.</p>

      <p className='text-tutorial'>&lt;thead&gt; quyidagi tartibda ishlatiladi: <Link className='table-link' to={"/tags/tag_table"}>&lt;table&gt;</Link> tegi ichida, <Link className='table-link' to={"/tags/tag_caption"}>&lt;caption&gt;</Link> va <Link className='table-link' to={"/tags/tag_colgroup"}>&lt;colgroup&gt;</Link> elementlaridan keyin, shuningdek har qanday <Link className='table-link' to={"/tags/tag_tbody"}>&lt;tbody&gt;</Link>, <Link className='table-link' to={"/tags/tag_tfoot"}>&lt;tfoot&gt;</Link> va <Link className='table-link' to={"/tags/tag_tr"}>&lt;tr&gt;</Link> elementlaridan oldin.</p>
      <NoteWhite type={"Maslahat"} text={"<thead>, <tbody> va <tfoot> elementlari jadvalning default holatiga ta'sir qilmaydi. Biroq, ushbu elementlarga still berish uchun CSSdan foydalanish mumkin!"} />
      <hr />
      <BrowserTable element='<thead>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />
      <TextContent header={"Global atributlar"} />
      <p className='text-tutorial'>&lt;thead&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <TextContent header={"Hodisa atributlari"} />
      <p className='text-tutorial'>&lt;thead&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>

      <TextContent header={"Misollar"}
        code1={`<html>
<head>
<style>
thead {color:green;}
tbody {color:blue;}
tfoot {color:red;}

table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>thead, tbody va tfoot elementlariga still berish</h1>

<table>
  <thead>
    <tr>
      <th>Oylar</th>
      <th>Jamg'arma</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Yanvar</td>
      <td>$100</td>
    </tr>
    <tr>
      <td>Fevral</td>
      <td>$80</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td>Sum</td>
      <td>$180</td>
    </tr>
  </tfoot>
</table>
`} path1={"try/201"}
        code1Text={"thead, tbody va tfoot elementlariga still berish:"}
        code2={`  <table style="width:100%">
<thead style="text-align:left">
  <tr>
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
</thead>
  <tbody>
    <tr>
      <td>Yanvar</td>
      <td>$100</td>
    </tr>
    <tr>
      <td>Fevral</td>
      <td>$80</td>
    </tr>
  </tbody>
 </table>`} path2={"try/202"}
        code2Text={"thead ichidagi tarkibni chap tomondan tekislash:"}
        code3={` <table style="width:50%">
 <thead style="vertical-align:bottom">
   <tr style="height:100px">
     <th>Oylar</th>
     <th>Jamg'arma</th>
   </tr>
 </thead>
 <tbody>
   <tr>
     <td>Yanvar</td>
     <td>$100</td>
   </tr>
   <tr>
     <td>Fevral</td>
     <td>$80</td>
   </tr>
 </tbody>
</table>`} path3={"try/203"}
        code3Text={"thead ichidagi kontentni vertikal tekislash:"} />
      <TextContent header={"Default CSS sozlamalari"}
        text1={"Ko'p brauzerlarda <thead> elementida quyidagi default stillar berilgan bo'ladi:"}
        redWords1={['<thead>']}
        css1={`thead {
display: table-header-group;
vertical-align: middle;
border-color: inherit;
}`} />
      <NavButton back='/tags/tag_code' next='/tags/tag_colgroup' />
    </article>)
}

export default Tagthead;
