import React, { useEffect, useState } from "react";

function HslChange(props) {
    const [valueHue, SetvalueHue] = useState(0);
    const [ValueSatur, SetValueSatur] = useState(100);
    const [valueLight, SetvalueLight] = useState(50);
    const [valueAlpha, SetValueAlpha] = useState(props.alpha * 10)
    const [styleDiv, SetStyleDiv] = useState('');
    const getBackgroundSize = (value, max) => {
        return {
            backgroundSize: `${(value * 100) / max}% 100%`,
        };
    };

    useEffect(() => {
        const changeColor = () => {
            valueAlpha ? SetStyleDiv(`a(${valueHue},${ValueSatur}%,${valueLight}%,${valueAlpha / 10})`) : SetStyleDiv(`(${valueHue},${ValueSatur}%,${valueLight}%)`);
        };
        changeColor();
    }, [valueHue, ValueSatur, valueLight, valueAlpha]);

    return (
        <div style={{ textAlign: "center", width: "70%", marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>
            <div id="hslresult" style={{ lineHeight: "80px", maxWidth: "70%", textAlign: "center", marginLeft: "auto", marginRight: "auto", backgroundColor: `hsl${styleDiv}` }}>
                <p id="rgbresulttext" style={{ fontSize: "120%", fontWeight: "bold", color: "rgb(255, 255, 255)", }}>hsl{styleDiv}</p>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                <div>
                    <p style={{ color: "red", }}>HUE(rang)</p>
                    <input
                        type="range"
                        min="0"
                        max="360"
                        onChange={(e) => SetvalueHue(e.target.value)}
                        style={getBackgroundSize(valueHue, 360)}
                        value={valueHue}
                    />

                    <div id="valRed2" xstyle="background-color:red;border-radius:5px;color:white;opacity:0.8">{valueHue}</div>
                </div>
                <div>
                    <p style={{ color: "green", }}>SATURATION(to'yinganlik)</p>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={(e) => SetValueSatur(e.target.value)}
                        style={getBackgroundSize(ValueSatur, 100)}
                        value={ValueSatur}
                    />
                    <div id="valGreen2" xstyle="background-color:#00ff00;border-radius:5px;opacity:0.8">{ValueSatur}%</div>
                </div>
                <div>
                    <p style={{ color: "blue", }}>LIGHTNESS(yorug'lik)</p>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={(e) => SetvalueLight(e.target.value)}
                        style={getBackgroundSize(valueLight, 100)}
                        value={valueLight}
                    />
                    <div id="valBlue2" xstyle="background-color:blue;border-radius:5px;color:white;opacity:0.8">{valueLight}%</div>
                </div>
                {valueAlpha ? <div>
                    <p style={{ color: "blue", }}>Alpha(shaffoflik)</p>
                    <input
                        type="range"
                        min="0"
                        max="10"
                        onChange={(e) => SetValueAlpha(e.target.value)}
                        style={getBackgroundSize(valueAlpha, 10)}
                        value={valueAlpha}
                    />
                    <div id="valAlpha2" xstyle="background-color:blue;border-radius:5px;color:white;opacity:0.8">{valueAlpha / 10}</div>
                </div> : <></>}
            </div>
        </div>
    )
}
export default HslChange;