import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import Notes from '../../../pages/Notes';
import NoteWhite from '../../../pages/NoteWhite';
import Table from '../../../pages/Table';


function InputAttr() {
    return (
        <div className='info-wrapper'>
                <W3Path path={"https://www.w3schools.com/html/html_form_attributes.asp"} />
            <h2 className='header-tutorial'>HTML input atributlari</h2>
            <NavButton back='/html/html_input' next='/html/html_input_form' />
            <hr />
            <TextContent text1={"Ushbu bobda HTML <input> elementining atributlari bilan tanishamiz."}
                redWords1={["<input>"]}
               />
            <hr />
            <TextContent header={"Boshlang'ich qiymat atributi"}
                text1={"Input value atributi input maydoni uchun boshlang'ich qiymatni belgilaydi:"}
                redWords1={["value"]}
                code1={`<form>
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe">
</form>`} code1Text={"Boshlang'ich(default) qiymatli input maydonlari:"}
                path1={"try/392"}/>
                <hr/>
            <TextContent header={"readonly atributi"}
                text1={"Input readonly atributi input maydoni faqat o'qish uchun ekanligini bildiradi."}
                redWords1={["readonly"]}
                text2={"Faqat o'qish uchun berilgan input maydonini o'zgartirib bo'lmaydi (lekin foydalanuvchi unga tegishi, uni ajratib ko'rsatishi va undagi matnni nusxalashi mumkin)."}
                text3={"Forma ma'lumotlarini yuborishda faqat o'qish uchun berilgan input maydonining qiymati shu maydonning qiymati sifatida yuboriladi!"}
                code3={`<form>
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John" readonly><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe">
</form>`}
                path3={"try/393"}
                code3Text={"Faqat o'qish uchun berilgan input maydoni:"}/>
                <hr/>
                <TextContent header={"disabled atributi"}
                text1={"Input disabled atributi input maydonini o'chirib qo'yish kerakligini bildiradi."}
                redWords1={["disabled"]}
                text2={"O'chirilgan input maydonidan foydalanib ham, uni bosib ham bo'lmaydi."}
                text3={"Forma ma'lumotlarini yuborishda o'chirilgan input maydonining qiymati yuborilmaydi!"}
                code3={`<form>
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John" disabled><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe">
</form>`}
                path3={"try/394"}
                code3Text={"O'chirilgan input maydoni:"}/>
                <hr/>
                <TextContent header={"Size atributi"}
                text1={"Inputning size atributi input maydoni kengligini ya'ni, input maydoniga kiritilgan ma'lumot belgilarining nechtasi ko'rinishi kerakligini belgilaydi."}
                redWords1={["size"]}
                text2={"size atributining default qiymat  20 ga teng."}
                redWords2={["size"]}
                text3={"Eslatma: size atributi  quyidagi input turlarida ishlaydi: text, search, tel, url, email, va password."}redWords3={["size"]}
                code3={`<form>
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" size="50"><br>
  <label for="pin">PIN:</label><br>
  <input type="text" id="pin" name="pin" size="4">
</form>`}
                path3={"try/395"}
                code3Text={"Input maydoni size atributini o'zgartirib ko'ring:"}/>
                <hr/>
                <TextContent header={"maxlength atributi"}
                text1={"Input maxlength atributi input maydonida ruxsat etilgan belgilarning maksimal sonini belgilaydi."}
                redWords1={["maxlength"]}
                text2={"Eslatma: maxlength o'rnatilgan bo'lsa, input maydoni belgilangan sondan ko'p belgini qabul qilmaydi. Biroq, bu atribut hech qanday ogohlantirish bermaydi. JavaScript kodini yozish orqali foydalanuvchini belgilar soni cheklanganligi haqida ogohlantirish mumkin."}
                redWords2={["maxlength"]}
                code2={`<form>
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" size="50"><br>
  <label for="pin">PIN:</label><br>
  <input type="text" id="pin" name="pin" maxlength="4" size="4">
</form>`}
                path2={"try/396"}
                code2Text={"Input maydoni uchun maxlength qiymatini o'zgartirib ko'ring:"}/>
                <hr/>
                <TextContent header={"min va max atributlari"}
                text1={"Input min va max atributlari input maydoni uchun minimal va maksimal qiymatlarni belgilaydi."}
                redWords1={["min", "max"]}
                text2={"min va max atributlari quyidagi input turlarida ishlaydi: number, range, date, datetime-local, month, time va week."}
                redWords2={["min", "max"]}
                text3={"Maslahat: Ruxsat etilgan qiymatlar chegarasini yaratish uchun max va min atributlaridan birgalikda foydalanish kerak."}
                code3={`<form>
  <label for="datemax">1980-01-01 dan oldingi sana kiritilsin:</label>
  <input type="date" id="datemax" name="datemax" max="1979-12-31"><br><br>

  <label for="datemin">2000-01-01 dan keyingi sana kiritilsin:</label>
  <input type="date" id="datemin" name="datemin" min="2000-01-02"><br><br>

  <label for="quantity">Qiymati (1 va 5 oralig'ida):</label>
  <input type="number" id="quantity" name="quantity" min="1" max="5">
</form>`}
                path3={"try/397"}
                code3Text={"Maksimal sana, minimal sana va qiymatlar chegarasini o'zgartiring:"}/>
                <hr/>
                <TextContent header={"multiple atributi"}
                text1={"Input multiple atributi foydalanuvchiga input maydoniga bir nechta qiymat kiritish mumkinligini bildiradi."}
                redWords1={["multiple"]}
                text2={"multiple atributi quyidagi input turlarida ishlaydi: email va file."}
                redWords2={["multiple"]}
                code2={`<form>
  <label for="files">Fayllarni tanlang:</label>
  <input type="file" id="files" name="files" multiple>
</form>`}
                path2={"try/398"}
                code2Text={"Bir nechta qiymatlarni qabul qiladigan fayl yuklash maydoni:"}/>
                <hr/>
                <TextContent header={"pattern atributi"}
                text1={"Input pattern atributi forma yuborilganda input maydoni qiymati shunga ko'ra tekshiriladigan muntazam ifodani(qolipni) bildiradi."}
                redWords1={["pattern"]}
                text2={"pattern atributi  quyidagi input turlarida ishlaydi: text, search, url, tel, email, va password."}
                redWords2={["pattern"]}/>
                <NoteWhite type={"Eslatma:"} text={"Patternni tushunishlari uchun"} text1={"title"} text2={"atributidan foydalaning."} path={"/tags/att_global_title"}/>
                <NoteWhite type={"Eslatma:"} text={"Bizning JavaScript darslarimizdan "} text1={"muntazam ifodalarni"} text2={" chuqurroq o'rganing."} path={"/js/js_regexp"}/>
                   <TextContent
                code3={`<form>
  <label for="country_code">Mamlakat kodi:</label>
  <input type="text" id="country_code" name="country_code"
  pattern="[A-Za-z]{3}" title="Uch harfli mamlakat kodi">
</form>`}
                path3={"try/399"}
                code3Text={"Faqat uchta harfdan iborat bo'lgan input maydoni (raqamlar yoki maxsus belgilarsiz):"}/>
                <hr/>
                <TextContent header={"placeholder atributi"}
                text1={"Input placeholder atributi input maydonining kiritilishi kerak bo'lgan qiymatni tavsiflovchi qisqacha ko'rsatmani bildiradi(namuna qiymati yoki kutilgan formatning qisqa tavsifi)."}
                redWords1={["placeholder"]}
                text2={"Qisqacha ko'rsatma foydalanuvchi qiymat kiritishdan oldin input maydonida ko'rinib turadi."}
                text3={"placeholder atributi quyidagi input turlari bilan ishlaydi: text, search, url, tel, email, va password."}redWords3={["placeholder"]}
                code3={`<form>
  <label for="phone">Telefon raqamini kiriting:</label>
  <input type="tel" id="phone" name="phone"
  placeholder="123-45-678"
  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}">
</form>`}
                path3={"try/400"}
                code3Text={"Placeholder matnli input maydoni:"}/>
                <hr/>
                <TextContent header={"required atribut"}
                text1={"Input required atributi formani yuborishdan oldin input maydonini to'ldirish kerakligini bildiradi."}
                redWords1={["required"]}
                text2={"required atributi quyidagi input turlari bilan ishlaydi: text, search, url, tel, email, password, date pickers, number, checkbox, radio,va  file."}
                redWords2={["required"]}
                code3={`<form>
  <label for="username">Foydalanuvchi:</label>
  <input type="text" id="username" name="username" required>
</form>`}
                path3={"try/401"}
                code3Text={"Majburiy input maydoni: "}/>
                <hr/>
                <TextContent header={"step atributi"}
                text1={"Input step atributi input maydoni uchun raqamlar qadamini belgilaydi."}
                redWords1={["step"]}
                text2={"Misol: agar step='3' bo'lsa, ketma-ket raqamlar -3, 0, 3, 6 va hokazo bo'lishi mumkin"}
                text3={"Maslahat: Bu atributdan max va min atributlari bilan birgalikda belgilangan qadamda ketma-ket keluvchi qiymatlar chegarasini yaratish uchun foydalanish mumkin."}
                text4={"step atributi quyidagi input turlari bilan ishlaydi:  number, range, date, datetime-local, month, time va week."}
                redWords4={["step"]}
                code4={`<form>
  <label for="points">Hisob:</label>
  <input type="number" id="points" name="points" step="3">
</form>`}
                path4={"try/402"}
                code4Text={"Belgilangan qadamga ega input maydoni:"}/>
                <Notes type={"Eslatma:"} text={"Input cheklovlari ishonchli emas, JavaScriptda cheklovlarga qaramasdan ishonchli bo'lmagan ma'lumotlarni kiritishning ko'plab usullari mavjud. Cheklovga ega inputlarga ma'lumotlarni kiritish xavfsizligi uchun uni qabul qiluvchi(server) ham tekshirishi kerak!"}/>
                <hr/>
                <TextContent header={"autofocus atributi"}
                text1={"Input autofocus atributi sahifa yuklanganda input maydoni avtomatik ravishda fokuslanishi kerakligini bildiradi."}
                redWords1={["autofocus"]}
                code3={`<form>
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" autofocus><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname">
</form>`}
                path3={"try/403"}
                code3Text={"Sahifa yuklanganda 'Ism' input maydoni diqqat markazida bo'ladi:"}/>
                <hr/>
                <TextContent header={"height va width atributlari"}
                text1={"Input height va width atributlari <input type='image'> elementining balandligi va kengligini belgilaydi."}
                redWords1={["height", "width", "<input", "type='image'>"]}/>
                <Notes type={"Maslahat:"} text={"Har doim tasvirlar uchun height va width atributlarini belgilang. Agar height va width o'rnatilgan bo'lsa, sahifa yuklanganda rasm uchun zarur bo'sh joy ajratiladi. Ushbu atributlarsiz brauzer tasvir hajmini bilmaydi va unga tegishli joy ajrata olmaydi va yuklash paytida sahifa tartibi o'zgaradi(tasvirlar yuklanganda)."}/>
                <br/>
                <TextContent code1={`<form>
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="image" src="img_submit.gif" alt="Submit" width="48" height="48">
</form>`}
                path1={"try/404"}
                code1Text={"Tasvirni height va width atributlari bilan yuborish tugmasi sifatida belgilang:"}/>
                <hr/>
                <TextContent header={"list atributi"}
                text1={"Input list atributi <input> elementi uchun oldindan belgilangan variantlarni o'z ichiga olgan <datalist> elementiga  ishora qiladi."}
                redWords1={["<datalist>", "<input>", "list"]}
                code1={`<form>
  <input list="browsers">
  <datalist id="browsers">
    <option value="Internet Explorer">
    <option value="Firefox">
    <option value="Chrome">
    <option value="Opera">
    <option value="Safari">
  </datalist>
</form>`}
                path1={"try/405"}
                code1Text={"<input> ning <datalist> da oldindan belgilangan qiymatlari:"}/>
                <hr/>
                <TextContent header={"autocomplete atributi"}
                text1={"Input autocomplete atributi forma yoki input maydonida avtomatik to'ldirishni yoqish yoki o'chirish kerakligini belgilaydi."}
                redWords1={["autocomplete",]}
                text2={"autocomplete brauzerga qiymatni taxmin qilish imkonini beradi. Agar foydalanuvchi maydonga yozishni boshlasa, brauzer oldingi kiritilgan qiymatlar asosida maydonni to'ldirish variantlarini ko'rsatadi."}
                redWords2={["autocomplete",]}
                text3={"autocomplete atributi  <form> va quyidagi <input> turlarida ishlaydi: text, search, url, tel, email, password, datepickers, range, va color."}
                redWords3={["autocomplete",]}
                code3={`<form action="/action_page.php" autocomplete="on">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <label for="email">Email:</label>
  <input type="email" id="email" name="email" autocomplete="off"><br><br>
  <input type="submit" value="Yuborish">
</form>`}
                path3={"try/406"}
                code3Text={"Bitta input maydonida autocomplete o'chirilgan, HTML formasida esa yoqilgan:"}/>
                <NoteWhite type={"Maslahat:"} text={"Ba'zi brauzerlarda autocomplete ishlashi uchun siz avtomatik to'ldirish funksiyasini faollashtirishingiz kerak bo'lishi mumkin(brauzer menyusidagi 'Sozlamalar' bo'limiga qarang)."}/>
            <hr />
            <Table th={["Teg", "Tavsif"]} values={[
        { id: 0, first: "<form>", second: "Foydalanuvchi ma'lumot kiritishi uchun HTML formasini belgilaydi", path: "/tags/tag_form" },
        { id: 1, first: "<input>", second: "Kirish boshqaruvini belgilaydi", path: "/tags/tag_input" }]} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="eAPMKAdcEWw" />
            <NavButton back='/html/html_input' next='/html/html_input_form' />
        </div>)
}

export default InputAttr;