import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import NavButton from '../NavButton';
import CardColor from './CardColor';
import ColorLists from './colorList';

function ColorNames() {

  if (window.location.pathname !== '/colors/colors_names' && window.location.pathname !== '/tags/ref_colornames') {
    return (
      <Outlet />
    )
  }
  else return (
    <article className='info-wrapper'>
      <p className='text-tutorial article'> Maqola <a className='table-link' href="https://www.w3schools.com/colors/colors_names.asp" target='_blank' area-label='vscode' rel="noreferrer"> W3School</a>  veb saytidan tarjima qilingan.</p>
      <h2 className='header-tutorial'>HTML rang nomlari </h2>
      <NavButton back='/colors' next='/colors/colors_hex' />
      <hr />
      <h3 className='header-content'>Rang nomlari barcha brauzerlar tomonidan qo'llab-quvvatlanadi</h3>
      <p className='text-tutorial text-tutorial-margin'>Barcha zamonaviy brauzerlar quyidagi 140 ta rang nomlarini qo'llab-quvvatlaydi</p>
      <p className='text-tutorial'> Ranglarning
        <Link className='text-tutorial text-tutorial__link' to={'/colors/colors_hex'}>140 xil HEX qiymatini ko'rish uchun shu yerni bosing.  </Link>
      </p>
      <hr />
      <CardColor datas={ColorLists} />
      <NavButton back='/colors' next='/colors/colors_hex' />
    </article>
  )

}

export default ColorNames;