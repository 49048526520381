import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import { Link } from 'react-router-dom';
import ListText from '../../../pages/ListsText';
import './layout.css';
import img from '../../../../images/img_sem_elements.gif';
import NoteWhite from '../../../pages/NoteWhite';


function Layout() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_layout.asp"} />
      <h2 className='header-tutorial'>HTML maket elementlari va texnikasi</h2>
      <NavButton back='/html/html_head' next='/html/html_responsive' />
      <hr />

      <p>Veb-saytlar ko'pincha kontentni bir nechta ustunlarda (jurnal yoki gazeta kabi) aks ettiradi.</p>
      <hr />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper'>
          <header>
            <h2>Cities</h2>
          </header>

          <section>
            <nav className='navClass'>
              <ul>
                <li><button style={{ border: "none", backgroundColor: "transparent" }} type='button'>London</button></li>
                <li><button style={{ border: "none", backgroundColor: "transparent" }} type='button'>Paris</button></li>
                <li><button style={{ border: "none", backgroundColor: "transparent" }} type='button'>Tokyo</button></li>
              </ul>
            </nav>

            <article className='articleLayout'>
              <h1>London</h1>
              <p>London - Angliyaning poytaxti. Bu Buyuk Britaniyadagi eng gavjum shahar bo'lib, uning poytaxti 13 milliondan ortiq aholiga ega.</p>
              <p>Temza daryosi bo'yida joylashgan London ikki ming yil davomida yirik aholi punkti bo'lib kelgan, uning tarixi rimliklar tomonidan asos solingan va uni Londinium deb atagan.</p>
            </article>
          </section>

          <footer>
            <p>Footer</p>
          </footer>
        </div>
        <Link className='button btn btn--large green' to={"try/254"} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />

      <TextContent header={"HTML maketi elementlari"}
        text1={"HTML veb-sahifaning turli qismlarini belgilaydigan bir nechta semantik elementlarga ega:"} />
      <div style={{ display: "flex" }}>
        <img style={{ marginRight: '10px' }} src={img} alt="Layout" width={219} height={258} />
        <div style={{ marginTop: '15px' }}>
          <ListText list={[{
            text: "<header> - Hujjat yoki bo'lim uchun sarlavhani belgilaydi",
            redWords: ["<header>"]
          },
          {
            text: "<nav> - Navigatsiya havolalari to'plamini belgilaydi",
            redWords: ["<nav>"]
          },
          {
            text: "<section> - Hujjatdagi bo'limni belgilaydi",
            redWords: ["<section>"]
          },
          {
            text: "<article> - Mustaqil va alohida kontentni belgilaydi",
            redWords: ["<article>"]
          },
          {
            text: "<aside> - kontentdan tashqaridagi kontentni belgilaydi (yon panel kabi)",
            redWords: ["<aside>"]
          },
          {
            text: "<footer> - hujjat yoki bo'lim uchun pastki qismni belgilaydi",
            redWords: ["<footer>"]
          },
          {
            text: "<details> - foydalanuvchi talabiga binoan ochilishi va yopilishi mumkin bo'lgan qo'shimcha tafsilotlarni belgilaydi",
            redWords: ["<details>"]
          },
          {
            text: "<summary>- <details> elementi uchun sarlavhani belgilaydi",
            redWords: ["<summary>"]
          },]} />
          <NoteWhite text={"Semantik elementlar haqida to'liq ma'lumot olish uchun"} path="/html/html5_semantic_elements" text1={" HTML sementik element"} text2={"tashrif buyuring."} />
        </div>
      </div>
      <hr />
      <TextContent header={"HTML maket texnikasi"}
        text1={"Ko'p ustunli maketni yaratish uchun to'rt xil texnika mavjud. Har bir texnikaning ijobiy va salbiy tomonlari bor:"} />
      <ListText list={[{
        text: "CSS framework",
      },
      {
        text: "CSS float xususiyati",
      },
      {
        text: "CSS flexbox",
      },
      {
        text: "CSS grid",
      },]} />
      <hr />
      <TextContent header={"CSS framework"} />
      <NoteWhite text={"Maketni tezda yaratish uchun"} text1={"Bootstrap"} path={"/bootstrap"} text2={"va W3.CSS frameworklaridan foydalanish mumkin."} />
      <hr />
      <TextContent header={"CSS Float maketi"}
        text1={"CSS float xususiyatidan foydalangan holda butun veb sahifa maketini yaratish oddiyva floatni o'rganish oson - clear va float xususiyatlari qanday ishlashini eslab qolish kifoya. Kamchiliklari: float elementlari hujjat oqimiga bog'langan, bu esa moslashuvchanlikka zarar yetkazishi mumkin."}
        redWords1={["float", "clear"]} />
      <NoteWhite text={"Float haqida "} text1={"Css float va clear"} path={"/css/css_float"} text2={" mavzusida ko'proq ma'lumot olishingiz mumkin."} />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper'>
          <header>
            <h2>Cities</h2>
          </header>

          <section>
            <nav className='navClass'>
              <ul>
                <li><button style={{ border: "none", backgroundColor: "transparent" }} type='button'>London</button></li>
                <li><button style={{ border: "none", backgroundColor: "transparent" }} type='button'>Parij</button></li>
                <li><button style={{ border: "none", backgroundColor: "transparent" }} type='button'>Tokiyo</button></li>
              </ul>
            </nav>

            <article className='articleLayout'>
              <h1>London</h1>
              <p>London - Angliyaning poytaxti. Bu Buyuk Britaniyadagi eng gavjum shahar bo'lib, uning poytaxti 13 milliondan ortiq aholiga ega.</p>
              <p>Temza daryosi bo'yida joylashgan London ikki ming yil davomida yirik aholi punkti bo'lib kelgan, uning tarixi rimliklar tomonidan asos solingan va uni Londinium deb atagan.</p>
            </article>
          </section>

          <footer>
            <p>Footer</p>
          </footer>
        </div>
        <Link className='button btn btn--large green' to={"try/254"} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <TextContent header={"CSS Flexbox maketi"}
        text1={"Flexbox sahifa maketi turli ekran o'lchamlari va turli xil displey qurilmalariga mos kelishi kerak bo'lganda, elementlarning taxminiy tarzda harakat qilishini ta'minlaydi."}
        redWords1={["Flexbox"]} />
      <NoteWhite text={"Flexbox haqida "} text1={"Css Flexbox"} path={"/css/css3_flexbox"} text2={" mavzusida ko'proq ma'lumot olishingiz mumkin."} />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper'>
          <header>
            <h2>Cities</h2>
          </header>

          <section>
            <nav className='navClass'>
              <ul>
                <li><button style={{ border: "none", backgroundColor: "transparent" }} type='button'>London</button></li>
                <li><button style={{ border: "none", backgroundColor: "transparent" }} type='button'>Parij</button></li>
                <li><button style={{ border: "none", backgroundColor: "transparent" }} type='button'>Tokiyo</button></li>
              </ul>
            </nav>

            <article className='articleLayout'>
              <h1>London</h1>
              <p>London - Angliyaning poytaxti. Bu Buyuk Britaniyadagi eng gavjum shahar bo'lib, uning poytaxti 13 milliondan ortiq aholiga ega.</p>
              <p>Temza daryosi bo'yida joylashgan London ikki ming yil davomida yirik aholi punkti bo'lib kelgan, uning tarixi rimliklar tomonidan asos solingan va uni Londinium deb atagan.</p>
            </article>
          </section>

          <footer>
            <p>Footer</p>
          </footer>
        </div>
        <Link className='button btn btn--large green' to={"try/255"} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <TextContent header={"CSS Grid"}
        text1={"CSS Grid moduli satrlar va ustunlar bilan gridga asoslangan tartib tizimini taklif etadi, bu esa float va flexdan foydalanmasdan veb-sahifalarni loyihalashni osonlashtiradi."}
        redWords1={["Grid"]} />
      <NoteWhite text1={"CSS Grid Intro"} path={"/css/css_grid"} text2={" mavzusida CSS Grid haqida ko'proq ma'lumot olishingiz mumkin."} />
      <hr />
      <h3 className='header-content'>Dars videosi:</h3>
      <YoutubeEmbed embedId="FQZb3QETE3A" />
      <NavButton back='/html/html_head' next='/html/html_responsive' />
    </article>)
}

export default Layout;