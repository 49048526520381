import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import ListText from '../../pages/ListsText';
import Table from '../../pages/Table';
import NoteWhite from '../../pages/NoteWhite';


function UrlEncode() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_urlencode.asp"} />
            <h2 className='header-tutorial'>URL - Uniform Resource Locator</h2>
            <NavButton back='/html/html_charset' next='/html/html_xtml' />
            <hr />
            <p>URL veb-manzil(web address)ning boshqacha nomi hisoblanadi.</p>
            <p>URL so'zlardan(masalan, self-study.uz) yoki Internet protokoli(IP) manzilidan(masalan, 104.16.244.78) iborat bo'lishi mumkin.</p>
            <p>Ko'pchilik internetdan foydalanish paytida veb sayt nomini kiritadi, chunki so'zlar raqamlardan ko'ra eslab qolish uchun osonroq.</p>
            <hr />
            <TextContent header={"URL - Uniform Resource Locator"}
                text1={"Veb-brauzerlar URL manzilidan foydalanib veb-serverlardagi sahifalarga so'rov yuboradi."}
                text2={"URL Internetdagi hujjat(yoki boshqa ma'lumotlar)ga murojaat qilish uchun ishlatiladi."} />
            <NoteWhite text1={"https://self-study.uz/html/html_urlen_code"} text2={"kabi veb-manzil quyidagi sintaksis qoidalariga amal qiladi:"} path={"https://self-study.uz/html/html_urlen_code"} />
            <TextContent code1={`scheme://prefix.domain:port/path/filename  `}
                text2={"Tushuntirish:"} />
            <ListText list={[{
                text: "scheme - Internet xizmati turini belgilaydi(eng keng tarqalgan http yoki https )",
                redWords: ["scheme"]
            },
            {
                text: "prefix - domen prefiksini belgilaydi (http uchun birlamchi - www )",
                redWords: ["prefix"]
            },
            {
                text: "domain - Internet domen nomini belgilaydi (masalan, self-study.uz)",
                redWords: ["domain"]
            },
            {
                text: "port - hostdagi port raqamini belgilaydi (default holatda http uchun 80 )",
                redWords: ["port"]
            },
            {
                text: "path - serverdagi yo'lni belgilaydi (agar o'tkazib yuborilmagan bo'lsa: saytning asosiy katalogi)",
                redWords: ["path"]
            },
            {
                text: "filename - hujjat yoki manba nomini belgilaydi",
                redWords: ["filename"]
            },]} />
            <hr />
            <Table header={"Mashhur URL sxemalari"} text={"Quyidagi jadvalda bir nechta mashhur sxemalar keltirilgan:"} th={["Sxema", "Yoyilmasi", "Ishlatilishi"]} values={[
                { id: 0, first: "http", second: "HyperText Transfer Protocol", third: "Oddiy veb-sahifalar. Shifrlanmagan" },
                { id: 1, first: "https", second: "Secure HyperText Transfer Protocol", third: "Xavfsiz veb-sahifalar. Shifrlangan" },
                { id: 2, first: "ftp", second: "File Transfer Protocol", third: "Fayllarni yuklab olish yoki yuklash" },
                { id: 3, first: "file", second: "\xa0", third: "Kompyuteringizda fayl" }]} />
            <hr />
            <TextContent header={"URL kodlash"} />
            <NoteWhite text={"URL manzillarni faqat"} text1={"ASCII belgilar to'plami"} text2={"dan foydalangan holda Internet orqali yuborish mumkin. Agar URLda ASCII to'plamidan tashqari belgilar mavjud bo'lsa, URL o'zgartirilishi kerak."} path={"/charsets/ref_html_ascii"} />
            <p>URL kodlash ASCII bo'lmagan belgilarni Internet orqali uzatilishi mumkin bo'lgan formatga aylantiradi.</p>
            <p>URL kodlash ASCII bo'lmagan belgilarni "%" dan keyin keluvchi o'n oltilik raqamlar bilan almashtiradi.</p>
            <p>URL manzillarida bo'sh joy bo'lmasligi kerak. URL kodlash odatda bo'sh joyni ortiqcha (+) belgisi yoki %20 bilan almashtiradi.</p>
            <hr />
            <TextContent header={"O'zingiz sinab ko'ring"} />
            <form name="input" target="_blank" action="/action_page2.htm" method="get" style={{ marginBottom: "20px" }}>
                <input type="text" defaultValue={"Hello Günter"} name="text" size="30" />
                <input type="submit" value="Yuborish"/>
            </form>
            <p>Agar siz "Yuborish" tugmasini bossangiz, brauzer URL serverga yuborilishidan oldin kiritilgan ma'lumotlarni kodlaydi.</p>
            <p>Serverdagi sahifa qabul qilingan ma'lumotni ko'rsatadi.</p>
            <p>Boshqatdan kiritib ko'ring va “Yuborish” tugmasini yana bir marta bosing.</p>
            <hr />
            <Table header={"ASCII kodlash misollari"} text={"Brauzer sahifada ishlatiladigan belgilar to'plamiga qarab kiritilgan URLni kodlaydi. UTF-8 HTML5 uchun standart belgilar to'plami hisoblanadi."} th={["Belgi", "Windows-1252 dan", "UTF-8 dan",]} values={[
                { id: 0, first: "€", second: "%80", third: "%E2%82%AC" },
                { id: 1, first: "£", second: "%A3", third: "%C2%A3" },
                { id: 2, first: "©", second: "%A9", third: "%C2%A9" },
                { id: 3, first: "®", second: "%AE", third: "%C2%AE" },
                { id: 4, first: 'À', second: "%C0", third: "%C3%80" },
                { id: 5, first: "Á", second: "%C1", third: "%C3%81" },
                { id: 6, first: "Â", second: "%C2", third: "%C3%82" },
                { id: 7, first: "Ã", second: "%C3", third: "%C3%83" },
                { id: 8, first: "Ä", second: "%C4", third: "%C3%84" },
                { id: 9, first: "Å", second: "%C5;", third: "%C3%85" },]} />
            <NoteWhite text={"Barcha URL kodlari haqida to'liq ma'lumot olish uchun"} text1={"URL kodlash ma'lumotnomasi"} path={"/tags/ref_urlencode"} text2={"ga tashrif buyuring"} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="l7b4AZ0wyjU" />
            <NavButton back='/html/html_charset' next='/html/html_xtml' />
        </div>)
}

export default UrlEncode;