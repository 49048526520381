import React from 'react'
import { Link } from 'react-router-dom'

function Notes(props) {
  return (
    <>
      <aside className='note-content'>
        {props.text1 ? <p className='text-tutorial'><strong>{props.type}</strong> {props.text}<Link className='text-tutorial text-tutorial__link' to={props.path}>{props.text1} </Link>{props.text2}</p> : <p className='text-tutorial'><strong>{props.type}</strong> {props.text}</p>}
      </aside>
    </>
  )
}

export default Notes
