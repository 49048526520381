import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom'
import Tutorials from './Tutorials';

function Tutorial({ data }) {
  const [click, setclick] = useState(false);
  const handleClick = () => setclick(!click);

  const navLinkStyle = ({ isActive }) => {
    if (data.subTut === "true") setclick(true);
    return {
      backgroundColor: isActive ? 'rgb(4, 170, 109)' : 'transparent'
    }
  }
  if (data.subTut === "true") return (
    <>
      <li className={click ? 'tutorial__item tutorial__item-hover' : 'tutorial__item'} onClick={handleClick} >
        <Link className='tutorial__item__link ' to={data.subTutorial[0].path}>{data.title}</Link>
      </li>
      <div className={click ? 'tutorials__wrap1 active1' : 'tutorials__wrap1'}>
        {data.subTutorial ? <Tutorials datas={data.subTutorial} /> : ""}
      </div>
    </>
  )
  else return (
    <>
      <li className='tutorial__item' >
        {data.path ? <NavLink style={navLinkStyle} className="tutorial__item__link" to={data.path}>{data.title}</NavLink> : <h2 className='tutorial-header'>{data.title}</h2>}
        {data.subTutorial ? <Tutorials datas={data.subTutorial} /> : ""}
      </li>
    </>
  )
}

export default Tutorial;