import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import viewport1 from '../../../images/img_viewport1.png';
import viewport2 from '../../../images/img_viewport2.png';
import ListText from '../../pages/ListsText';
import Notes from '../../pages/Notes';
import CodeContent from '../../../tryit/CodeContent';
import CodeMirrorContent from '../../../tryit/CodeContent/CodeMirrorContent';


function StyleGuide() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html5_syntax.asp"} />
            <h2 className='header-tutorial'>HTMLda to'g'ri va toza kod yozish qo'llanmasi</h2>
            <NavButton back='/html/html_semantic' next='/html/html_obects' />
            <hr />
            <p>Izchil, toza va tartibli HTML kodi boshqalarning kodingizni o'qishi va tushunishini osonlashtiradi.</p>
            <p>Bu sahifada yaxshi HTML kodini yaratish bo'yicha ba'zi ko'rsatmalar va maslahatlar berilgan.</p>
            <hr />
            <TextContent header={"Har doim hujjat turini e'lon qiling"}
                text1={"Har doim hujjat turini hujjatning birinchi qatorida e'lon qilish kerak."}
                text2={"HTML uchun to'g'ri hujjat turi:"} code2={`<!DOCTYPE html>`} />
            <hr />
            <TextContent header={"Kichik harflardan foydalaning"}
                text1={"HTML elementlarini katta harfda ham kichik harfda ham yozish mumkin."}
                text2={"Biroq, elementlarni kichik harfda yozish tavsiya qilinadi, chunki:"} />
            <ListText list={[{ text: "Katta va kichik harflarni aralashtirib yozish xunuk ko'rinadi" },
            { text: "Dasturchilar odatda kichik harflardan foydalanadilar" },
            { text: "Kichik harfda yozilgan kod toza ko'rinadi" },
            { text: "Kichik harflarni yozish osonroq" },]} />
            <br />
            <CodeContent props={`<body>
<p>Bu matn.</p>
</body>`} text={"Yaxshi:"} />
            <CodeContent props={`<BODY>
<P>Bu matn.</P>
</BODY> `} text={"Yomon:"} />
            <hr />
            <TextContent header={"Barcha HTML elementlari yopilgan bo'lishi kerak"}
                text1={"HTMLda barcha elementlarni yopish shart emas, ya'ni yopilgan elementni yozmasa ham to'g'ri ishlayveradi(masalan, <p> elementi)."}
                redWords1={["<p>"]}
                text2={"Biroq, barcha HTML elementlarini yopish tavsiya qilinadi, masalan:"}
                code2={`<section>
  <p>Bu matn.</p>
  <p>Bu matn.</p>
</section>`} code2Text={"Yaxshi:"}
                code3={`<section>
  <p>Bu matn.
  <p>Bu matn.
</section>`} code3Text={"Yomon"} />
            <hr />
            <TextContent header={"Atributlarni kichik harflarda yozing"}
                text1={"HTML atributlarni katta va kichik harflar bilan yozish mumkin."}
                text2={"Biroq, atributlarni kichik harflar bilan ishlatishni tavsiya qilinadi, chunki:"} />
            <ListText list={[{ text: "Katta va kichik harflarni aralashtirib yozish xunuk ko'rinadi" },
            { text: "Dasturchilar odatda kichik harflardan foydalanadilar" },
            { text: "Kichik harfda yozilgan kod toza ko'rinadi" },
            { text: "Kichik harflarni yozish osonroq" },]} />
            <CodeContent props={`<a href="https://www.w3schools.com/html/">Visit our HTML tutorial</a>`} text={"Yaxshi:"} />
            <CodeContent props={`<a HREF="https://www.w3schools.com/html/">Visit our HTML tutorial</a>`} text={"Yomon:"} />
            <hr />
            <TextContent header={"Har doim atribut qiymatlarini qo'shtirnoq bilan berish kerak."}
                text1={"HTML atribut qiymatlarini qo'shtirnoqsiz bersa ham bo'ladi."}
                text2={"Biroq, atribut qiymatlarini qo'shtirnoq bilan berish tavsiya qilinadi, chunki:"} />
            <ListText list={[{ text: "Dasturchilar odatda atribut qiymatlarini qo'shtirnoq bilan beradi." },
            { text: "Qo'shtirnoq bilan berilgan qiymatlarni o'qish oson" },
            { text: "Agar qiymatlar orasida bo'shliqlar bo'lsa, qo'shtirnoqlardan foydalanish shart" },]} />
            <CodeContent props={`<table class="striped">`} text={"Yaxshi:"} />
            <CodeContent props={`<table class=striped>`} text={"Yomon:"} />
            <CodeContent props={`<table class=table striped>`} text={"Juda yomon: Bu ishlamaydi, chunki qiymatda bo'shliqlar mavjud:"} />
            <hr />
            <TextContent header={"Rasmlarning har doim alt, width va height atributlariga qiymat berilishi shart"}
                text1={"Har doim rasmlar uchun alt atributi berilishi kerak. Agar biron sababga ko'ra rasmni ko'rsatib bo'lmasa, bu atribut muhim ahamiyatga ega."}
                redWords1={["alt"]}
                text2={"Shuningdek, har doim rasmlar width va height atributiga qaiymat berib ketilishi kerak. Bu miltillashni kamaytiradi, chunki brauzer rasmni yuklashdan oldin rasm uchun joy ajratadi."}
                redWords2={["height", "weight"]} />
            <CodeContent props={`<img src="html5.gif" alt="HTML5" style="width:128px;height:128px">`} text={"Yaxshi:"} />
            <CodeContent props={`<img src="html5.gif">`} text={"Yomon:"} />
            <hr />
            <TextContent header={"Bo'shliq va tenglik belgisi"}
                text1={"HTML tenglik belgisi atrofida bo'shliqlarga ruxsat beradi. Biroq, kam bo'sh joy o'qish osonlashtiradi va obyektlarni birgalikda yaxshiroq guruhlaydi."}
                code1={`<link rel="stylesheet" href="styles.css">`}
                code1Text={"Yaxshi:"}
                code2={`<link rel = "stylesheet" href = "styles.css">`} code2Text={"Yomon:"} />
            <hr />

            <TextContent header={"Kod satrlari uzun bo'lmasligi kerak"}
                text1={"HTML muharriridan foydalanganda HTML kodini o'qish uchun o'ngga va chapga siljitish noqulaylik tug'diradi."}
                text2={"Juda uzun kod qatorlaridan qochishga harakat qiling."} />
            <hr />
            <TextContent header={"Ortiqcha qatorlar va abzats"}
                text1={"Sababsiz bo'sh qatorlar, bo'shliqlar yoki abzatslarni qo'shmaslik kerak."}
                text2={"O'qishni osonlashtirish uchun mantiqan bog'liq va katta kod bloklarini ajratish uchun qo'shimcha bo'sh qatorlarni qo'shiladi."}
                text3={"O'qishni osonlashtirish uchun abzas uchun ikkita bo'sh joy qo'shing. Tab tugmasidan foydalanmang."} />
            <CodeContent props={`<body>

<h1>Famous Cities</h1>

<h2>Tokyo</h2>
<p>Tokyo is the capital of Japan, the center of the Greater Tokyo Area, and the most populous metropolitan area in the world.</p>

<h2>London</h2>
<p>London is the capital city of England. It is the most populous city in the United Kingdom.</p>

<h2>Paris</h2>
<p>Paris is the capital of France. The Paris area is one of the largest population centers in Europe.</p>

</body>`} text={"Yaxshi:"} />
            <CodeContent props={`<body>
<h1>Famous Cities</h1>
<h2>Tokyo</h2><p>Tokyo is the capital of Japan, the center of the Greater Tokyo Area, and the most populous metropolitan area in the world.</p>
<h2>London</h2><p>London is the capital city of England. It is the most populous city in the United Kingdom.</p>
<h2>Paris</h2><p>Paris is the capital of France. The Paris area is one of the largest population centers in Europe.</p>
</body>`} text={"Yomon:"} />
            <CodeContent props={`<table>
  <tr>
    <th>Name</th>
    <th>Description</th>
  </tr>
  <tr>
    <td>A</td>
    <td>Description of A</td>
  </tr>
  <tr>
    <td>B</td>
    <td>Description of B</td>
  </tr>
</table>`} text={"Yaxshi jadval namunasi:"} />
            <CodeContent props={`<ul>
  <li>London</li>
  <li>Paris</li>
  <li>Tokyo</li>
</ul>`} text={"Yaxshi ro'yxatga misol:"} />
            <hr />
            <TextContent header={"<title> elementini tushirib qoldirmang"}
                text1={"HTMLda <title> elementini yozish talab qilinadi."}
                redWords1={["<title>"]}
                text2={"Sahifa sarlavhasining mazmuni qidiruv tizimini optimallashtirish (SEO) uchun juda muhim! Sahifalar sarlavhasi qidiruv tizimi algoritmlari tomonidan qidiruv natijalarida sahifalarni ro'yxatga olish tartibini aniqlash uchun ishlatiladi."}
                text3={"<title> elementi:"}
                redWords3={["<title>:"]} />
            <ListText list={[{ text: "brauzer asboblar panelidagi sarlavhani belgilaydi" },
            { text: "Sevimli sahifalarga qo'shilganda sahifa uchun sarlavha beradi" },
            { text: "qidiruv tizimi natijalarida sahifa sarlavhasini ko'rsatadi" },]} />
            <p>Shuning uchun, sarlavhani iloji boricha aniq va mazmunli qilishga harakat qiling: </p>
            <CodeContent props={`<title>HTML Style Guide and Coding Conventions</title>`} text={""} />
            <hr />
            <TextContent header={"<html> va <body> tushirib qoldirilsa bo'ladimi?"}
                text1={"HTML sahifasi <html> va <body> teglarisiz ham ishlayveradi:"}
                redWords1={["<html>", "<body>"]}
                code1={`<!DOCTYPE html>
<head>
  <title>Bu sahifa sarlavhasi</title>
</head>

<h1>Bu sarlavha</h1>
<p>Bu matn.</p>`}
                path1={"try/281"}
                text2={"Biroq, har doim <html> va <body> teglarini qo'shish tavsiya qilinadi!"}
                redWords2={["<html>", "<body>"]}
                text3={"<body> tegini tushirib qoldirish eski brauzerlarda xatoliklarga olib kelishi mumkin."}
                redWords3={["<body>"]}
                text4={"<html> va <body> teglarini tushirib qoldirish DOM va XML dasturlarini ham ishdan chiqarishi mumkin."}
                redWords4={["<html>", "<body>"]} />
            <hr />
            <TextContent header={"<head> tegini tushirib qoldirish"}
                text1={"<head> tegini ham tushirib qoldirish mumkin."}
                redWords1={["<head>"]}
                text2={"Brauzerlar <body> dan oldingi barcha elementlarni default <head> elementi ichiga yozib qo'yadi."}
                redWords2={["<head>", "<body>"]}
                code2={`<!DOCTYPE html>
<html>
<title>Bu sahifa sarlavhasi</title>
<body>

<h1>Bu sarlavha</h1>
<p>Bu matn.</p>

</body>
</html>`}
                path2={"try/282"}
                text3={"<head> elementini tushirib qoldirmaslik tavsiya qilinadi."}
                redWords3={["<head>"]} />
            <hr />
            <TextContent header={"Toq HTML elementlari"}
                text1={"HTMLda toq elementlarni yopish ixtiyoriy."}
                code1={`<meta charset="utf-8">`} code1Text={"Ruxsat berilgan:"}
                code2={`<meta charset="utf-8" />`} code2Text={"Shuningdek bunga ham ruxsat berilgan:"}
                text3={"Agar siz XML/XHTML dasturiy ta'minoti sizning sahifangizdan foydalansa, XML va XHTMLda talab qilinganligi sababli, yopilish chizig'ini (/) qo'yish shart."} />
            <hr />
            <TextContent header={"lang atributini qo'shish"}
                text1={"Veb-sahifa tilini e'lon qilish uchun har doim lang atributini <html> tegi ichiga kiritishingiz kerak. Bu qidiruv tizimlari va brauzerlarga yordam berish uchun mo'ljallangan."}
                redWords1={["lang", "<html>"]}
                code1={`<!DOCTYPE html>
<html lang="en-us">
<head>
  <title>Bu sahifa sarlavhasi</title>
</head>
<body>

<h1>Bu sarlavha</h1>
<p>Bu matn.</p>

</body>
</html`} path1={"try/283"} />
            <hr />
            <TextContent header={"Meta ma'lumotlar"}
                text1={"To'g'ri ko'rsatilishini va qidiruv tizimining to'g'ri indekslashini ta'minlash uchun HTML hujjatida til va belgilarni kodlash(character encoding) uchun <meta charset='charset'> teparoqda e'lon qilinishi kerak:"}
                redWords1={["<meta", "charset='charset'>"]}
                code1={`<!DOCTYPE html>
<html lang="en-us">
<head>
  <meta charset="UTF-8">
  <title>Page Title</title>
</head>`} />
            <hr />
            <TextContent header={"Ko'rish oynasini sozlash"}
                text1={"Ko'rish oynasi veb-sahifaning foydalanuvchiga ko'rinadigan qismidir. Bu qurilmaga qarab farq qiladi - ya'ni ko'rish oynasi mobil telefonda kompyuter ekraniga qaraganda kichikroq bo'ladi."}
                text2={"Barcha veb-sahifalaringizga quyidagi <meta> elementni kiritishingiz kerak:"}
                redWords2={["<meta>"]} />
                 <div style={{border: "1px solid #ccc", marginBottom: "25px"}}>
                    <CodeMirrorContent value={`<meta name="viewport" content="width=device-width, initial-scale=1.0">`}/>
                   </div>
            <TextContent
                text1={"Bu brauzerga sahifa o'lchamlari va masshtabini boshqarish bo'yicha ko'rsatmalar beradi."}
                text2={"width=device-width qismi sahifaning kengligini qurilmaning ekran kengligiga moslashtiradi(bu qurilmaga qarab o'zgaradi)."}
                redWords2={["width=device-width"]}
                text3={"Ushbu initial-scale=1.0 qismi brauzer tomonidan sahifa birinchi marta yuklanganda boshlang'ich kattalashtirish darajasini belgilaydi."}
                redWords3={["initial-scale=1.0"]}
                text4={"Mana ko'rish maydoni meta-tegi bo'lmagan veb-sahifa va ko'rish maydoni meta-tegi mavjud bir xil veb-sahifalarga misol:"} />
            <Notes type={"Maslahat:"} text={"Agar siz ushbu sahifani telefon yoki planshet orqali ko'rayotgan bo'lsangiz, farqni ko'rish uchun quyidagi ikkita havolani bosishingiz mumkin."} />
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-around' }}>
                <div style={{ textAlign: 'center' }}>
                    <a className='text-tutorial text-tutorial__link' href='/viewport1.html' target={'_blank'}>
                        <img className='img_card' src={viewport1} alt={"Witout viewport"} width={200} height={375} />
                        <br />
                        <br />
                        <b>viewport meta-tegiga ega bo'lmaganda </b>
                    </a>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <a className='text-tutorial text-tutorial__link' href='/viewport2.html' target={'_blank'}>
                        <img className='img_card' src={viewport2} alt={"Witout viewport"} width={200} height={375} />
                        <br />
                        <br />
                        <b>viewport meta-tegiga ega bo'lganda </b>
                    </a>
                </div>
            </div>
            <hr />
            <TextContent header={"HTML sharhlari"}
                text1={"Qisqa sharhlar bir qatorga yozilishi kerak, masalan:"}
                code1={`<!-- This is a comment -->`}
                text2={"Bir qatordan ortiq bo'lgan sharhlar quyidagicha yozilishi kerak:"}
                code2={`<!--
  This is a long comment example. This is a long comment example.
  This is a long comment example. This is a long comment example.
-->`}
                text3={"Ikkita bo'sh joyli abzats bilan yozilgan uzun sharhlarni tushunish va ajratib olish oson."} />
            <hr />
            <TextContent header={"Stillar berish"}
                text1={"Tashqi stillarni veb sahifaga ulash uchun oddiy usullardan foydalanish kerak(type atributi shart emas.)"}
                redWords1={["type"]}
                code1={`<link rel="stylesheet" href="styles.css">`}
                text2={"Kam CSS qoidalari siqilgan holda yozilishi mumkin, masalan:"}
                code2={`p.intro {font-family:Verdana;font-size:16em;}`}
                text3={"Uzun CSS qoidalari bir nechta satrlarga yozilishi kerak:"}
                code3={`body {
  background-color: lightgrey;
  font-family: "Arial Black", Helvetica, sans-serif;
  font-size: 16em;
  color: black;
}`} />
            <ListText list={[{ text: "Ochilgan qavs selektor bilan bir qatorda yoziladi" },
            { text: "Ochilgan qavsdan oldin bitta bo'sh joy qoldiriladi" },
            { text: "Abzats uchun ikki bo'shliqdan foydalaniladi" },
            { text: "Har bir xususiyat-qiymat juftligidan keyin nuqtali vergul qo'yiladi, shu jumladan oxirgisiga ham." },
            { text: "Qiymatda bo'shliq bor bo'lsa, qo'shtirnoqlardan foydalaniladi" },
            { text: "Yopiluvchi qavs oldida bo'shliqlarsiz yangi qatorga qo'yiladi" },]} />
            <hr />
            <TextContent header={"HTMLda JavaScript yuklash"}
                text1={`"Tartibsiz" HTML kodidan foydalanish JavaScript xatoliklariga olib kelishi mumkin.`}
                text2={"Ushbu ikkita JavaScript kodi turli natijalar beradi:"}
                code2={`getElementById("Demo").innerHTML = "Hello";

getElementById("demo").innerHTML = "Hello";`} path2={"try/284"} />
            <hr />
            <TextContent header={"Kichik harfli fayl nomlaridan foydalaning"}
                text1={`Ba'zi veb-serverlar(Apache, Unix) fayl nomlari katta-kichikligiga sezgir: "london.jpg" fayliga "London.jpg" sifatida murojaat qilish mumkin emas.`}
                text2={`Boshqa veb-serverlar(Microsoft, IIS) katta-kichik harflarga sezgir emas: "london.jpg" fayliga "London.jpg" sifatida murojaat qilish mumkin.`}
                text3={"Agar siz katta va kichik harflarni e'tiborsiz foydalansangiz, shuni bilib qo'yishingiz kerakki siz katta-kichik harflarga sezgir bo'lmagan serverdan katta-kichik harflarga sezgir serverga o'tsangiz, hatto kichik xatoliklar ham veb-saytingizni buzadi!"}
                text4={"Bunday muammolarning oldini olish uchun har doim kichik harfda yozilgan fayl nomlaridan foydalaning!"} />
            <hr />
            <TextContent header={"Fayl kengaytmalari"} />
            <p>HTML fayllari <b>.html</b> kengaytmasiga ega bo'lishi kerak ( <b>.htm</b> ruxsat berilgan).</p>
            <p>CSS fayllari <b>.css</b> kengaytmasiga ega bo'lishi kerak.</p>
            <p>JavaScript fayllari <b>.js</b> kengaytmasiga ega boʻlishi kerak.</p>
            <hr />
            <TextContent header={".htm va .html o'rtasida farq bormi?"}
                text1={".htm va .html fayl kengaytmalari o'rtasida hech qanday farq yo'q!"}
                text2={"Ikkalasi ham istalgan veb-brauzer va veb-server tomonidan HTML sifatida ko'rib chiqiladi."} />
            <hr />
            <TextContent header={"Default fayl nomlari"}
                text1={`Agar URL oxirida fayl nomi ko'rsatilmasa (masalan, "https://self-study.uz/"), server shunchaki default fayl nomini qo'shadi. Quyidagicha: "index.html", "index.htm", "default.html", yoki "default.htm".`}
                text2={`Agar serveringiz default fayl nomi sifatida faqat "index.html" nomi sozlangan bo'lsa, faylingiz "default.html" emas, balki "index.html" deb nomlanishi kerak.`}
                text3={`Biroq, serverlar bir nechta default fayl nomi bilan sozlanishi mumkin; odatda  bir nechta default fayl nomlarini o'rnatish mumkin.`} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="sh-EouZ21Ok" />
            <NavButton back='/html/html_semantic' next='/html/html_obects' />
        </article>)
}

export default StyleGuide;