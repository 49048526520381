import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import ListText from '../../pages/ListsText';
import NoteWhite from '../../pages/NoteWhite';


function XtmlPage() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_xhtml.asp"} />
            <h2 className='header-tutorial'>HTMLning XHTML versiyasi</h2>
            <NavButton back='/html/html_urlen_code' next='/html/html_forms' />
            <hr />
            <p>XHTML - qoidalari qat'iy bo'lgan, HTMLning XMLga asoslangan versiyasi.</p>
            <hr />
            <TextContent header={"XHTML nima?"} />
            <ListText list={[{
                text: "XHTML yosilmasi EXtensible HyperText Markup Language(Kengaytirilgan gipermatnli belgilash tili)",
            },
            {
                text: "XHTML qoidalari qat'iy bo'lgan, HTMLning XMLga asoslangan versiyasi hisoblanadi.",
            },
            {
                text: "XHTML - bu XML ilovasi sifatida belgilangan HTML",
            },
            {
                text: "XHTML barcha asosiy brauzerlar tomonidan qo'llab-quvvatlanadi",
            },]} />
            <hr />
            <TextContent header={"Nima uchun XHTML?"}
                text1={"XML barcha hujjatlar to'g'ri belgilanishi kerak bo'lgan('yaxshi shakllangan' bo'lishi kerak) belgilash tili hisoblanadi."}
                text2={"XHTML HTMLni yanada kengaytiriladigan va boshqa ma'lumotlar formatlari (masalan, XML) bilan ishlash uchun moslashuvchan qilish uchun ishlab chiqilgan. Bundan tashqari, brauzerlar HTML sahifalaridagi xatolarni e'tiborsiz qoldiradi va veb-saytni yaratishda ba'zi xatolar bo'lsa ham to'g'ri ko'rsatishga harakat qiladi. Shunday qilib, XHTML xatolar e'tiborsiz qoldirilmaydi, kod xatolarsiz yozish qat'iy hisoblanadi."} />
            <NoteWhite text={"XML haqida bilishni xohlasangiz"} path={"/xml"} text1={"XML mavzuslarini"} text2={"o'qing."} />
            <hr />
            <TextContent header={"HTML dan eng muhim farqlari"} />
            <ListText list={[{
                text: "<!DOCTYPE> majburiydir",
                redWords: ["<!DOCTYPE>"]
            },
            {
                text: "<html> da xmlns atributi majburiydir",
                redWords: ["<html>", "xmlns"]
            },
            {
                text: "<html>, <head>, <title> va <body> majburiydir",
                redWords: ["<html>", "<head>", "<title>", "<body>"]
            },
            {
                text: "Elementlar har doim ichma-ich to'g'ri joylashtirilgan bo'lishi kerak",
            },
            {
                text: "Elementlar har doim yopilishi kerak",
                redWords: [""]
            },
            {
                text: "Elementlar har doim kichik harflarda bo'lishi kerak",
            },
            {
                text: "Atribut nomlari har doim kichik harf bilan yozilishi kerak",
            },
            {
                text: "Atribut qiymatlari har qo'shtirnoq ichida bo'lishi kerak",
                redWords: ["qo'shtirnoq"]
            },
            {
                text: "Atributni minimallashtirish taqiqlanadi",
            },]} />
            <hr />
            <TextContent header={"XHTMLda <!DOCTYPE ....> Majburiy"}
                text1={"XHTML hujjatida XHTML <!DOCTYPE> deklaratsiyasi bo'lishi shart."}
                text2={"<html>, <head>, <title> va <body> elementlari ham mavjud bo'lishi va <html> dagi xmlns atributida hujjat uchun xml nom maydoni ko'rsatilishi kerak."}
                code2={`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN"
"http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
  <title>Hujjat Sarlavhasi</title>
</head>
<body>

  Bu yerda kontent...

</body>
</html>`} /> <hr />
            <TextContent header={"XHTML elementlari ichma-ich to'g'ri joylashtirilgan bo'lishi kerak"}
                text1={"XHTMLda elementlar har doim bir-birining ichiga to'g'ri joylashtirilishi kerak, masalan:"}
                code1={`<b><i>Bu yerda mant bor</i></b>`}
                code1Text={"To'g'ri:"}
                code2={`<b><i>Bu yerda mant bor</b></i>`}
                code2Text={"Noto'g'ri:"} />

            <TextContent header={"XHTML elementlari har doim yopilishi kerak"}
                text1={"XHTML elementlari har doim yopilishi kerak, masalan:"}
                code1={`Keyingi qator elementi: <br />
Gorizontal chiziq: <hr />
Rasm: <img src="happy.gif" alt="Happy face" />`}
                code1Text={"To'g'ri:"}
                code2={`Keyingi qator elementi: <br>
Gorizontal chiziq: <hr>
Rasm: <img src="happy.gif" alt="Happy face">`}
                code2Text={"Noto'g'ri:"} />
            <TextContent header={"XHTML elementlari kichik harflarda bo'lishi kerak"}
                text1={"XHTMLda element nomlari har doim kichik harflarda bo'lishi kerak, masalan:"}
                code1={`<body>
<p>Bu matn</p>
</body>`}
                code1Text={"To'g'ri:"}
                code2={`<BODY>
<P>Bu matn</P>
</BODY>`}
                code2Text={"Noto'g'ri:"} />
            <TextContent header={"XHTML atribut nomlari kichik harfda bo'lishi kerak"}
                text1={"XHTMLda atribut nomlari har doim kichik harfda bo'lishi kerak, masalan:"}
                code1={`<a href="https://self-study.uz/html/">Bizning HTML darsligimizga tashrif buyuring</a>`}
                code1Text={"To'g'ri:"}
                code2={`<a HREF="https://self-study.uz/html/">Bizning HTML darsligimizga tashrif buyuring</a>`}
                code2Text={"Noto'g'ri:"} />
            <TextContent header={"XHTML atribut qiymatlari qo'shtirnoq ichida yozilishi kerak"}
                text1={"XHTMLda atribut qiymatlari har doim quyidagicha qo'shtirnoq ichida yozilishi kerak:"}
                code1={`<a href="https://self-study.uz/html/">HTML darsligimizga tashrif buyuring</a>`}
                code1Text={"To'g'ri:"}
                code2={`<a href=https://self-study.uz/html/>HTML darsligimizga tashrif buyuring</a>`}
                code2Text={"Noto'g'ri:"} />
            <TextContent header={"XHTML atributini minimallashtirish taqiqlangan"}
                text1={"XHTMLda atributni minimallashtirish taqiqlanadi:"}
                code1={`<input type="checkbox" name="vehicle" value="car" checked="checked" />
<input type="text" name="lastname" disabled="disabled" />`}
                code1Text={"To'g'ri:"}
                code2={`<input type="checkbox" name="vehicle" value="car" checked />
<input type="text" name="lastname" disabled />`}
                code2Text={"Noto'g'ri:"} />
                <hr/>
                <TextContent header={"W3C Validator yordamida HTML hujjatingizni tekshiring"}/>
            <form method="get" action="https://validator.w3.org/check" target="_blank">
                <p>URL manzilingizni kiriting:</p>
                <p><input name="uri" size="60" defaultValue={"https://self-study.uz"} style={{maxWidth:"100%"}}/></p>
                <p><input type="submit" value="Sahifani tekshirish"/></p>
            </form>
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="I6ms22c3Dc8" />
            <NavButton back='/html/html_urlen_code' next='/html/html_forms' />
        </div>)
}

export default XtmlPage;