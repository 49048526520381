import React from 'react';
import Notes from '../../../pages/Notes';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import Table from '../../../pages/Table';
import { Link } from 'react-router-dom';
import YoutubeEmbed from '../../YoutubeEmbed';



function HtmlTables() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_tables.asp"} />
            <h2 className='header-tutorial'>HTML jadvali</h2>
            <NavButton back='/html/html_favicon' next='/html/html_table_borders' />
            <hr />
            <TextContent
                text1={"HTML jadvallari ma'lumotlarni qatorlar va ustunlarga ajratish imkonini beradi."} />
            <div className='code-content'>
                <h4 className='header-code'>Misol</h4>
                <div className='code-wrapper'>
                    <Table th={["Kompaniya", "Kontakt", "Mamlakat"]} values={[
                        { first: "Alfreds Futterkiste", second: "Maria Anders", third: "Germaniya" },
                        { first: "Centro comercial Moctezuma", second: "Francisco Chang", third: "Meksika" },
                        { first: "Ernst Handel", second: "Roland Mendel", third: "Avstriya" },
                        { first: "Island Trading", second: "Helen Bennett", third: "Buyuk Britaniya" },
                        { first: "Laughing Bacchus Winecellars", second: "	Yoshi Tannamuri", third: "	Kanada" },
                        { first: "Magazzini Alimentari Riuniti", second: "Giovanni Rovelli", third: "Italiya" },
                    ]} />
                </div>
                <Link className='button btn btn--large green' to={'try/126'} target={"_blank"}>Sinab ko'rish</Link>
            </div>
            <hr />
            <TextContent header={"HTML jadvali"}
                text1={"HTML jadvali  qator va ustunlar ichidagi jadval kataklaridan iborat."}
                code1={`<table>
 <tr>
   <th>Kompaniya</th>
   <th>Kontakt</th>
   <th>Mamlakat</th>
 </tr>
  <tr>
   <td>Alfreds Futterkiste</td>
   <td>Maria Anders</td>
   <td>Germaniya</td>
 </tr>
  <tr>
   <td>Centro comercial Moctezuma</td>
   <td>Francisco Chang</td>
   <td>Meksika</td>
 </tr>
</table>`}
                path1={'try/127'} />
            <hr />
            <TextContent header={"Jadval kataklari"}
                text1={"Har bir jadval katagi <td> va </td> teglari bilan belgilanadi."}
                redWords1={["<td>", "</td>"]} />
            <Notes text={"td ichida jadval ma'lumotlari joylashadi."} />
            <TextContent
                text1={"Jadval katagidagi hamma narsa <td> va </td> orasida joylashadi."}
                redWords1={["<td>", "</td>"]}
                code1={`<table>
    <tr>
        <td>Emil</td>
        <td>Tobias</td>
        <td>Linus</td>
    </tr>
</table>`}

                path1={"try/128"} />
            <Notes type={"Eslatma:"} text={"Jadval katagi barcha turdagi HTML elementlarini o'z ichiga olishi mumkin: matn, rasmlar, ro'yxatlar, havolalar, boshqa jadvallar va hokazo."} />
            <hr />
            <TextContent header={"Jadval qatorlari"}
                text1={"Har bir jadval qatori <tr> bilan boshlanadi va </tr> teg bilan tugaydi."}
                redWords1={["<tr>", "</tr>"]} />
            <Notes text={"tr jadval qatorini bildiradi."} />
            <TextContent
                code1={`<table>
    <tr>
        <td>Emil</td>
        <td>Tobias</td>
        <td>Linus</td>
    </tr>

    <tr>
        <td>16</td>
        <td>14</td>
        <td>10</td>
    </tr>
</table>`}

                path1={"try/129"}
                text2={"Jadvalda siz xohlagancha qatorga ega bo'lishingiz mumkin; faqat kataklar soni har bir qatorda bir xil ekanligiga ishonch hosil qiling."} />
            <Notes type={"Eslatma:"} text={"Ba'zida qatorda boshqasiga qaraganda kamroq yoki ko'proq kataklar bo'lishi mumkin. Bu haqda keyingi bobda bilib olasiz."} />
            <hr />
            <TextContent header={"Jadval sarlavhasi"}
                text1={"kataklarni jadval sarlavha katagi qilish uchun <td> tegi o'rniga <th> tegi ishlatiladi:"}
                redWords1={["<td>", "<th>"]} />
            <Notes text={"th jadval sarlavha katagini bildiradi."} />
            <TextContent
                code1={`<table>
    <tr>
        <th>Shaxs 1</th>
        <th>Shaxs 2</th>
        <th>Shaxs 3</th>
    </tr>
    <tr>
        <td>Emil</td>
        <td>Tobias</td>
        <td>Linus</td>
    </tr>

    <tr>
        <td>16</td>
        <td>14</td>
        <td>10</td>
    </tr>
</table>`
                }

                path1={"try/130"}
                code1Text={"Birinchi qator jadval sarlavha kataklari bo'lsin:"}
                text2={"Default holatda <th> elementidagi matn qalin va markazlashtirilgan bo'ladi. Shuningdek buni CSS yordamida o'zgartirish ham mumkin."}
                redWords1={["<th>"]} />
            <hr />
            <Table header={"Html teglari"} th={["Teg", "tavsifi"]} values={[
                { first: "<table>", second: "Jadvalni bildiradi", path: '/tags/tag_table' },
                { first: "<th>", second: "Jadvaldagi sarlavha katagini bildiradi", path: '/tags/tag_th' },
                { first: "<tr>", second: "Jadvaldagi qatorni bildiradi", path: '/tags/tag_tr' },
                { first: "<td>", second: "Jadvaldagi katakni bildiradi", path: '/tags/tag_td' },
                { first: "<caption>", second: "Jadval sarlavhasini belgilaydi", path: '/tags/tag_caption' },
                { first: "<colgroup>", second: "Formatlash uchun jadvaldagi bir yoki bir nechta kataklar guruhini belgilaydi", path: '/tags/tag_colgroup' },
                { first: "<col>", second: "<colgroup> elementidagi har bir katak xususiyatlarini belgilaydi", path: '/tags/tag_col' },
                { first: "<thead>", second: "Sarlavha tarkibini jadvalda guruhlarga ajratadi", path: '/tags/tag_thead' },
                { first: "<tbody>", second: "Jadvaldagi asosiy tarkibni guruhlarga ajratadi", path: '/tags/tag_tbody' },
                { first: "<tfoot>", second: "Jadvaldagi footer tarkibini guruhlaydi", path: '/tags/tag_tfoot' },
            ]} />

            <Notes text={"Barcha mavjud HTML teglarining toʻliq roʻyxatini koʻrish uchun bizning"} path="/tags" text1={" HTML teg maʼlumotnomamizga"} text2={"tashrif buyuring."} />
            <TextContent header={"Dars videosi:"} />
            <YoutubeEmbed embedId="ordrD3bFet0" />
            <NavButton back='/html/html_favicon' next='/html/html_table_borders' />
        </article>
    )
}

export default HtmlTables;