import React from 'react';
import ListText from '../../../pages/ListsText';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function OrderedLists() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_lists_ordered.asp"} />
      <h2 className='header-tutorial'>HTML tartiblangan ro'yxatlari</h2>
      <NavButton back='/html/html_lists_unordered' next='/html/html_lists_other' />
      <hr />
      <TextContent text1={"HTML <ol> tegi tartiblangan ro'yxatni belgilaydi. Tartiblangan ro'yxat raqamli yoki alifbo tartibida bo'lishi mumkin."} redWords1={["<ol>"]} />
      <hr />
      <TextContent header={"Tartiblangan HTML ro'yxati"}
        text1={"Tartiblangan ro'yxat <ol> tegi bilan boshlanadi. Har bir ro'yxat elementi <li> tegi bilan boshlanadi."}
        redWords1={["<ol>", "<li>"]}
        text2={"Default holatda ro'yxat elementlari raqamlangan bo'ladi:"}
        code2={`<ol>
  <li>Coffee</li>
  <li>Tea</li>
  <li>Milk</li>
</ol>`} path2={"try/205"}
      />
      <hr />
      <TextContent header={"Type atributi"}
        text1={" <ol> element type atributi ro'yxatni tartiblash turini belgilaydi:"}
        redWords1={["type"]} />
      <Table th={["Qiymat", "Tavsifi"]} values={[
        { first: 'type="1"', second: "Ro'yxat elementlari raqamlar bilan tartiblanadi." },
        { first: 'type="A"', second: "Ro'yxat elementlari katta harflar bilan tartiblanadi." },
        { first: 'type="a"', second: "Ro'yxat elementlari kichik harflar bilan tartiblanadi." },
        { first: 'type="I"', second: "Ro'yxat elementlari katta rim raqamlari bilan tartiblanadi." },
        { first: 'type="i"', second: "Ro'yxat elementlari kichik rim raqamlari bilan tartiblanadi." },]} />
      {/* TODO begining */}
      <TextContent
        code1={`<ol type="1">
        <li>Kofe</li>
        <li>Choy</li>
        <li>Sut</li>
</ol>`} path1={"try/213"} code1Text={"Raqamlar bilan tartiblangan ro'yxat"}
        code2={`  <ol type="A">
        <li>Kofe</li>
        <li>Choy</li>
        <li>Sut</li>
</ol>`} path2={"try/214"} code2Text={"Katta harflar bilan tartiblangan ro'yxat"}
        code3={`<ol type="a">
   <li>Kofe</li>
   <li>Choy</li>
   <li>Sut</li>
</ol>`} path3={"try/215"} code3Text={"Kichik harflar bilan tartiblangan ro'yxat"}
        code4={` <ol type="I">
   <li>Kofe</li>
   <li>Choy</li>
   <li>Sut</li>
</ol>`} path4={"try/216"} code4Text={"Katta rim raqamlari bilan tartiblangan ro'yxat"}
      />
      <TextContent
        code1={`<ol type="i">
   <li>Kofe</li>
   <li>Choy</li>
   <li>Sut</li>
</ol>`} path1={"try/217"} code1Text={"Kichik rim raqamlari bilan tartiblangan ro'yxat"} />
      <hr />
      <TextContent header={"Ro'yxat tartibini boshqarish"}
        text1={"Default holatda tartiblangan ro'yxat elementlari 1 dan raqamlab boshlaydi. start atributi yordamida ko'rsatilgan sondan boshlab raqamlash mumkin."}
        redWords1={["start"]}
        code1={`<ol start="50">
   <li>Kofe</li>
   <li>Choy</li>
   <li>Sut</li>
</ol>

<ol type="I" start="50">
    <li>Kofe</li>
    <li>Choy</li>
    <li>Sut</li>
</ol>`} path1={"try/218"}
      />
      <hr />
      <TextContent header={"Ichma-ich joylashgan ro'yxatlar"}
        text1={"Ro'yxatlarni ichma-ich joylashtirish mumkin(ro'yxat ichidagi ro'yxat)."}
        code1={`<ol>
  <li>Kofe</li>
  <li>Choy
    <ol>
      <li>Qora choy</li>
      <li>Ko'k choy</li>
    </ol>
  </li>
  <li>Sut</li>
</ol>`} path1={"try/219"}
      />
      <Notes type={"Eslatma"} text={"Ro'yxat elementi (<li>) yangi ro'yxatni hamda rasm va havolalar kabi boshqa HTML elementlarini o'z ichiga olishi mumkin."} />
      <hr />
      <TextContent header={"Bo'lim xulosasi"} />
      <ListText list={[{
        text: "<ol> - tartiblangan ro'yxat uchun ishlatiladi.",
        redWords: ["<ol>"]
      },
      {
        text: "CSS type xususiyati ro'yxatni tartiblash turini belgilaydi",
        redWords: ["type"]
      },
      {
        text: "<li> tegi ro'yxat elementini belgilaydi.",
        redWords: ["<li>"]
      },
      {
        text: "Ro'yxatlarni ichma-ich joylashtirish mumkin",
      },
      {
        text: "Ro'yxat elementlari boshqa HTML elementlarini o'z ichiga olishi mumkin",
      },]} />
      <Table header={"Html teglari"} th={["Teg", "tavsifi"]} values={[
        { first: "<ul>", second: "tartiblangan ro'yxatni belgilaydi", path: '/tags/tag_ul' },
        { first: "<ol>", second: "Tartiblangan ro'yxat belgilaydi", path: '/tags/tag_ol' },
        { first: "<li>", second: "Ro'yxat elementini bildiradi", path: '/tags/tag_li' },
        { first: "<dl>", second: "Tavsif ro'yxatini belgilaydi", path: '/tags/tag_dl' },
        { first: "<dt>", second: "Tavsif ro'yxatidagi atamani belgilaydi", path: '/tags/tag_dt' },
        { first: "<dd>", second: "Tavsif ro'yxatidagi atamani tavsiflaydi", path: '/tags/tag_dd' },]} />
      <Notes text={"Barcha mavjud HTML teglarining toʻliq roʻyxatini koʻrish uchun bizning"} path="/tags" text1={" HTML teg maʼlumotnomamizga"} text2={"tashrif buyuring."} />
      <hr />
      <TextContent header={"Dars videosi:"} />
      <YoutubeEmbed embedId="kYJb-ISY81I" />
      <NavButton back='/html/html_lists_unordered' next='/html/html_lists_other' />
    </article>)
}
export default OrderedLists;
