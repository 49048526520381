import React from "react";
import img from "../../images/merglobe.gif";
function Mercury() {
    return (
        <>

            <img src={img} alt="Computer" width={100} height={100} />

        </>)
}

export default Mercury;