import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import '../../css/components/preview.css';

const downLoadTriggerCode = `
document.addEventListener("keydown", function(event) {
  // Detect Cmd+s or Ctrl+s inside iframe
  if ( (event.ctrlKey || event.metaKey) && event.keyCode === 83) {
    event.preventDefault();
    window.parent.postMessage('triggerDownload', window.parent.location.origin);
  }
});
`;


function Preview(props) {
  const iframeRef = useRef(null);
  const [cssCode, setCssCode] = useState(props.cssCode);

  const changeImg = (doc, tagName, attr) => {
    const el = doc.getElementsByTagName(tagName);

    for (let i = 0; i < el.length; i++) {
      const elAttr = el[i].attributes;

      const elAttrValue = elAttr.getNamedItem(attr).value;
      var elValue;
      if(elAttrValue.indexOf('../image/') === 0){
        elValue = elAttrValue.slice(3);
      }
      else{
      elValue = elAttrValue.indexOf('/image/') === 0 ? elAttrValue.slice(1) : elAttrValue;}
      try{
        let image = require('../../../images/' + elValue);
        elAttr.getNamedItem(attr === 'srcset' ? 'srcSet' : attr).value = image;
      }
      catch(e){ }
    }
  }

// For <area> and iframe
  const changePath = (doc, tagname, attr) => {
    const el = doc.getElementsByTagName(tagname);
    for (let i = 0; i < el.length; i++) {
      const elAttr = el[i].attributes;

      const elAttrValue = elAttr.getNamedItem(attr).value;
      if(!elAttrValue.startsWith("http"))
      elAttr.getNamedItem(attr).value = '/html/' + elAttrValue;
    }
  }


  // for backgroundImage
  const changeUrlImg = (doc) => {
    var elms = doc.querySelectorAll("*");
    for (let i = 0; i < elms.length; i++) {
      if (getComputedStyle(elms[i]).backgroundImage !== 'none') {
        var s = getComputedStyle(elms[i]).backgroundImage;
        elms[i].style.backgroundImage = `url(${require('../../../images/' + s.slice(s.indexOf('try/') + 4, s.lastIndexOf(')') - 1))})`
      }
    }
  }



  useEffect(() => {
    if (props.htmlCode.indexOf(`<link rel="stylesheet" href="styles.css">`) === -1 && props.htmlCode.indexOf(`<link rel="stylesheet" href="/html/styles.css">`) === -1 && props.htmlCode.indexOf(`<link rel="stylesheet" href="mystyle.css">`)===-1) setCssCode(``);

    if (props.htmlCode.indexOf(`<link rel="stylesheet" href="https://self-study.uz/html/styles.css">`) !== -1) setCssCode(`body {background-color: powderblue;}
h1   {color: blue;}
p    {color: red;}`);

if(props.htmlCode.indexOf(`<link rel="stylesheet" href="mystyle.css">`)!==-1)setCssCode(`body {background-color:yellow;}
p {color:blue;}`)

    function updateIframe() {
      const iframe = iframeRef.current;
      var doc = iframe.contentDocument
        ? iframe.contentDocument
        : iframe.contentWindow.document;

      const iframeContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>${cssCode}</style>
        </head>
        <body>${props.htmlCode}
        <script>
        ${props.jsCode}
        ${downLoadTriggerCode}
        </script>
        </body>
      </html>
    `;
      doc.open();
      doc.write(iframeContent);
      doc.close();

      try {
        changeImg(doc, 'source', 'srcset');
        changeImg(doc, 'img', 'src');
        changePath(doc, 'area', 'href');
        changePath(doc, 'iframe', 'src');
        changeUrlImg(doc);
      }
      catch (e) {
      }

    }

    updateIframe();
  }, [props.htmlCode, cssCode, props.jsCode]);


  return <iframe title="Code" className="preview iframeStyle" ref={iframeRef}></iframe>;
}

Preview.propTypes = {
  cssCode: PropTypes.string,
  htmlCode: PropTypes.string,
  jsCode: PropTypes.string,
};

export default Preview;