import React from 'react';
import Notes from '../../../pages/Notes';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function TableSizes() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_table_sizes.asp"} />
            <h2 className='header-tutorial'>HTML jadval o'lchamlari</h2>
            <NavButton back='/html/html_table_borders' next='/html/html_table_headers' />
            <hr />
            <TextContent text1={"HTML jadvallari har bir ustun, satr yoki butun jadval uchun turli o'lchamlarga ega bo'lishi mumkin."} />
            <hr />
            <div>
                <table className='table2' style={{ width: "20%", float: "left" }}>
                    <tbody><tr>
                        <td>&nbsp;</td>
                        <td style={{ width: "80%" }}>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
                <table className='table2' style={{ width: "50%", float: "left", marginLeft: "5%" }}>
                    <tbody><tr>
                        <td style={{ width: "40%" }}>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td style={{ width: "40%" }}>&nbsp;</td>
                    </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
                <table className='table2' style={{ width: "20%", float: "right" }}>
                    <tbody><tr>
                        <td style={{ width: "70%" }}>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                        <tr style={{ height: "50px" }}>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
            </div>
            <hr />
            <TextContent text1={"Jadval, satr yoki ustun o'lchamini style atributidagi width va height xususiyatlari bilan o'zgartirish mumkin."}
                redWords1={["style", "width", "height"]} />
            <hr />
            <TextContent header={"Jadval kengligi(width)"}
                text1={"Jadvalning kengligi table elementi style  atibutining width xususiyatiga qiymat berish orqali o'zgartiriladi."}
                redWords1={["style", "table", "width"]}
                code1={`<table style="width:100%">
<tr>
<th>Familiya</th>
<th>Ism</th>
<th>Yosh</th>
</tr>
<tr style="height:200px">
  <td>Jill</td>
  <td>Smith</td>
  <td>50</td>
</tr>
<tr>
  <td>Eve</td>
  <td>Jackson</td>
  <td>94</td>
</tr>
</table>`}
                path1={"try/170"}
                text1Code={"Jadval kengligini 100%"}
            />
            <Notes type={"Eslatma"} text={"Kenglik o'lchami foiz qiymati ota element(mana shu elementimizni o'z ichiga olgan element)ga nisbatan hisoblanadi."} />
            <hr />
            <TextContent header={"HTML jadvali ustunining kengligi"}
                text1={""} />
            <table className='table2' style={{ width: "30%", marginBottom: "10px" }}>
                <tbody><tr>
                    <td>&nbsp;</td>
                    <td style={{ width: "60%", backgroundColor: "rgba(150, 212, 212,0.3)" }}>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td style={{ backgroundColor: "rgba(150, 212, 212,0.3)" }}>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td style={{ backgroundColor: "rgba(150, 212, 212,0.3)" }}>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody></table>
            <TextContent text1={"th yoki td elementlaridagi style atributining width xususiyati yordamida muayyan ustunning o'lchamini o'zgartirish mumkin."}
                redWords1={["th", "td", "width"]}
                code1={`<table style="width:100%">
<tr>
  <th style="width:70%">Familiya</th>
  <th>Ism</th>
  <th>Yosh</th>
</tr>
<tr>
  <td>Jill</td>
  <td>Smith</td>
  <td>50</td>
</tr>
<tr>
  <td>Eve</td>
  <td>Jackson</td>
  <td>94</td>
</tr>
</table>`}
                path1={"try/171"}
                code1Text={"Birinchi ustunning kengligi 70%"}
            />
            <hr />
            <TextContent header={"HTML jadvali satr balandligi"} />
            <table className='table2' style={{ width: "30%", marginBottom: "10px" }}>
                <tbody><tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                    <tr style={{ height: "100px", backgroundColor: "rgba(150, 212, 212,0.3)" }}>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody></table>
            <TextContent text1={"tr elementidagi style atributining height xususiyatiga qiymat berib muayyan satrning balandligini o'zgartirish mumkin."}
                redWords1={["tr", "height"]}
                code1={`<table style="width:100%">
<tr>
<th>Familiya</th>
<th>Ism</th>
<th>Yosh</th>
</tr>
<tr style="height:200px">
  <td>Jill</td>
  <td>Smith</td>
  <td>50</td>
</tr>
<tr>
  <td>Eva</td>
  <td>Jekson</td>
  <td>94</td>
</tr>
</table>`}
                path1={"try/172"}
                code1Text={"Ikkinchi qator balandligiga 200px qiymat berish"}
            />
            <hr />
            <TextContent header={"Dars videosi:"} />
            <YoutubeEmbed embedId="QD5B3KufLng" />
            <NavButton back='/html/html_table_borders' next='/html/html_table_headers' />
        </article>)
}
export default TableSizes;