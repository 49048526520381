import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import workplace from '../../../../images/workplace.jpg';
import frenchfood from '../../../../images/frenchfood.jpg';
import frenchfood4 from '../../../../images/frenchfood4.jpg';
import frenchfood3 from '../../../../images/frenchfood3.jpg';
import Notes from '../../../pages/Notes';
import ListText from '../../../pages/ListsText';
import Table from '../../../pages/Table';
import YoutubeEmbed from '../../YoutubeEmbed';


function ImagesMap() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_images_imagemap.asp"} />
      <h2 className='header-tutorial'>HTML rasm xaritasi</h2>
      <NavButton back='/html/html_images' next='/html/html_images_background' />
      <TextContent header={"Map"}
        text1={'HTML <map> tegi yordamida rasm xaritasi yaratiladi. Rasm xaritasi bosiladigan maydonlarga ega rasmdir. Bosiladigan maydonlar bir yoki bir nechta <area> teglar yordamida hosil qilinadi.'}
        redWords1={['<map>', '<area>']}
        text2={'Quyidagi rasmdagi kompyuter, telefon yoki qahvani bosish mumkin:'} />
      <img src={workplace} alt="Workplace" useMap="#workmap" width={400} height={379} />
      <map name="workmap">
        <area shape="rect" coords="34,44,270,350" alt="Sun" href="computer.htm" target="_blank" />
        <area shape="rect" coords="290,172,333,250" alt="Mercury" href="phone.htm" target="_blank" />
        <area shape="circle" coords="337,300,44" alt="Venus" href="coffee.htm" target="_blank" />
      </map>
      <TextContent
        code1={`<img src="workplace.jpg" alt="Workplace" usemap="#workmap">
        <map name="workmap">
          <area shape="rect" coords="34,44,270,350" alt="Computer" href="computer.htm">
          <area shape="rect" coords="290,172,333,250" alt="Phone" href="phone.htm">
          <area shape="circle" coords="337,300,44" alt="Coffee" href="coffee.htm">
        </map>`}
        path1={"try/105"}
      />
      <hr />
      <TextContent header={"Bu qanday ishlaydi?"}
        text1={'Rasm xaritasi yordamida rasmning qayeri bosilganiga qarab turli xil amallarni bajarish mumkin.'}
        text2={"Rasm xaritasini yaratish uchun rasm va bosiladigan maydonlarni tavsiflovchi ba'zi HTML kodlari kerak bo'ladi."} />
      <TextContent header={"Rasm"}
        text1={"Rasm <img> teg yordamida kiritiladi . Boshqa rasmlardan yagona farqi usemap atributi qo'shilishi kerak:"}
        redWords1={['<img>', 'usemap']}
        code1={`<img src="workplace.jpg" alt="Workplace" usemap="#workmap">`}
        text2={"Usemap qiymati xesh teg bilan boshlanadi, # dan keyin rasm xaritasi nomi kiritiladi. Usemap rasm va rasm xaritasi o'rtasida aloqa uchun ishlatiladi."}
        redWords2={['Usemap', '#']}
      />
      <Notes type={"Maslahat:"} text={"Rasm xaritasi sifatida istalgan tasvirdan foydalanish mumkin!"} />
      <hr />
      <TextContent header={"Rasmlar xaritasini yaratish"}
        text1={"Keyin <map> elementi qo'shiladi."}
        redWords1={['<map>']}

        text2={" <map> elementi rasm xaritasini yaratish uchun ishlatiladi va name atributi yordamida rasm bilan bog'lanadi:"}
        redWords2={['<map>', 'name']}
        code2={`<map name="workmap">`}

        text3={" Name atributi bilan <img> tegining usemap atributi bir xil qiymatga ega bo'lishi kerak "}
        redWords3={['<img>', 'Name', 'usemap']}
      />
      <hr />
      <TextContent header={"area elementi"}
        text1={"Keyin bosiladigan maydonlar qo'shiladi."}

        text2={"Bosiladigan maydon <area> element yordamida yaratiladi."}
        redWords2={['<area>']}
      />

      <TextContent header={"Shakl"}
        text1={"Bosiladigan maydonning shaklini belgilash uchun quyidagi qiymatlardan birini tanlash mumkin:"} />
      <ListText list={[{
        text: "rect- to'rtburchaklar shakldagi maydon yaratiladi",
        redWords: ["rect"]
      },
      {
        text: "circle- dumaloq shakldagi maydon yaratiladi",
        redWords: ["circle"]
      },
      {
        text: "poly- ko'pburchakli shakldagi maydon yaratiladi",
        redWords: ["poly"]
      },
      {
        text: "default- butun rasmni belgilaydi",
        redWords: ["default"]
      },]} />
      <TextContent text1={"Rasmga bosiladigan maydonlarni joylashtirish uchun ba'zi koordinatalar ham belgilanishi kerak. "} />
      <hr />
      <TextContent header={'Shape="rect"'}
        text1={"shape='rect' teng bo’lganda koordinatalar juft bo'lib, biri x o'qi va biri y o'qini anglatadi."}
        redWords1={["shape='rect'"]}
        text2={"Misol uchun, 34,44 koordinatalar chapdan 34 piksel va yuqoridan 44 piksel masofada joylashgan:"}
        redWords2={['34,44']}
      />
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', width: '2px', height: '379px', left: '34px', backgroundColor: 'GreenYellow', opacity: '0.8' }}></div>
        <div style={{ position: 'absolute', width: '400px', height: '2px', top: '44px', backgroundColor: "GreenYellow", opacity: "0.8" }}></div>
        <div style={{ border: "2px solid red", borderRadius: "50%", position: "absolute", width: "20px", height: "20px", left: "25px", top: "35px", backgroundColor: "transparent", xopacity: "0.8" }}></div>
      </div>
      <img src={workplace} alt="Workplace" width={400} height={379} />
      <TextContent text1={"270,350 koordinatalar chapdan 270 piksel va tepadan 350 piksel masofada joylashgan:"}
        redWords1={["270,350"]}
      />
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", width: "2px", height: "379px", left: "270px", backgroundColor: "GreenYellow", opacity: "0.8" }}></div>
        <div style={{ position: "absolute", width: "400px", height: "2px", top: "350px", backgroundColor: "GreenYellow", opacity: "0.8" }}></div>
        <div style={{ border: "2px solid red", borderRadius: "50%", position: "absolute", width: "20px", height: "20px", left: "261px", top: "341px", backgroundColor: "transparent", xopacity: "0.8" }}></div>
      </div>
      <img src={workplace} alt="Workplace" width={400} height={379} />
      <TextContent
        text1={"Endi bizda bosiladigan to'rtburchak maydonni yaratish uchun yetarli ma'lumotlar mavjud:"}
        code1={`<area shape="rect" coords="34, 44, 270, 350" href="computer.htm">`}
        path1={"try/106"}
        text2={"Bu maydon bosilganda foydalanuvchini 'computer.htm' sahifasiga yuboradi:"}
        redWords2={['34,44']}
      />
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", width: "236px", height: "306px", left: "34px", top: "44px", backgroundColor: "dodgerblue", opacity: "0.8" }}></div>
      </div>
      <img src={workplace} alt="Workplace" width={400} height={379} />
      <hr />
      <TextContent header={'Shape="circle"'}
        text1={"Doira shaklidagi bosiladigan maydonni qo'shish uchun avval doira markazi koordinatalari topiladi:"}
        text2={"337,300"}
        redWords2={['337,300']}
      />
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", width: "2px", height: "379px", left: "337px", backgroundColor: "GreenYellow", xopacity: "0.8" }}></div>
        <div style={{ position: "absolute", width: "400px", height: "2px", top: "300px", backgroundColor: "GreenYellow", xopacity: "0.8" }}></div>
        <div style={{ border: "2px solid red", borderRadius: "50%", position: "absolute", width: "20px", height: "20px", left: "328px", top: "291px", backgroundColor: "transparent", xopacity: "0.8" }}></div>
      </div>
      <img src={workplace} alt="Workplace" width={400} height={379} />
      <TextContent
        text1={"Keyin aylananing radiusi belgilanadi:"}
        text2={"44 piksel"}
        redWords2={['44']}
      />
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", width: "44px", height: "2px", left: "337px", top: "300px", backgroundColor: "GreenYellow", opacity: "0.8" }}></div>
        <div style={{ border: "2px solid red", borderRadius: "50%", position: "absolute", width: "88px", height: "88px", left: "293px", top: "256px", backgroundColor: "transparent", xopacity: "0.8" }}></div>
      </div>
      <img src={workplace} alt="Workplace" width={400} height={379} />
      <TextContent
        text1={"Endi doira shaklidagi bosiladigan maydonni yaratish uchun yetarli ma'lumotlar mavjud::"}
        code1={`<area shape="circle" coords="337, 300, 44" href="coffee.htm">`}
        path1={"try/107"}
        text2={"Bu maydon bosilganda foydalanuvchini 'coffee.htm' sahifasiga yuboradi:"}
      />
      <div style={{ position: "relative" }}>
        <div style={{ borderRadius: "50%", position: "absolute", width: "88px", height: "88px", left: "293px", top: "256px", backgroundColor: "dodgerblue", opacity: "0.8" }}></div>
      </div>
      <img src={workplace} alt="Workplace" width={400} height={379} />
      <hr />
      <TextContent header={'Shape="poly"'}
        text1={"shape='poly' to'g'ri  chiziqlar bilan hosil qilingan shakl(ko'pburchak)ni yaratadigan bir nechta koordinata nuqtalarini o'z ichiga oladi."}
        redWords1={["shape='poly'"]}
        text2={"Bu chiziqlar har qanday shaklni yaratish uchun ishlatilishi mumkin."}
        text3={"Misol uchun, Kruasan shakli kabi!"}
        text4={"Quyidagi rasmdagi kruassanni qanday qilib bosiladigan havolaga aylantirishimiz mumkin?"}
      />
      <img src={frenchfood} alt="Workplace" width={450} height={675} />
      <TextContent
        text1={"Kruasanning barcha qirralari uchun x va y koordinatalarini topishimiz kerak:"}
      />
      <img src={frenchfood4} alt="Workplace" width={450} height={675} />
      <TextContent
        text1={"Koordinatalar juft bo'lib, biri x o'qi , biri y o'qini bildiradi:"}
        code1={`<area shape="poly" coords="140,121,181,116,204,160,204,222,191,270,140,329,85,355,58,352,37,322,40,259,103,161,128,147" href="croissant.htm">`}
        path1={"try/108"}
        text2={"Bu maydon bosilganda foydalanuvchini 'croissant.htm' sahifasiga yuboradi:"}
      />
      <img src={frenchfood3} alt="Workplace" width={450} height={675} />
      <hr />
      <TextContent header={"Rasmlar xaritasi va JavaScript"}
        text1={"Bosiladigan maydon yordamida JavaScript funksiyasini ham ishga tushirishi mumkin."}
        text2={"JavaScript funktsiyasini bajarish uchun <area> elementiga click hodisa qo'shiladi :"}
        redWords2={['click', '<area>']}
        code2={`<map name="workmap">
        <area shape="circle" coords="337,300,44" href="coffee.htm" onclick="myFunction()">
      </map>

      <script>
      function myFunction() {
        alert("You clicked the coffee cup!");
      }
      </script>`}
        path2={"try/109"}
        code2Text={"Bu yerda biz maydon bosilganda JavaScript funksiyasini bajarish uchun onclick atributidan foydalanamiz:"}
      />
      <hr />
      <TextContent header={"Bo'lim xulosasi"} />
      <ListText list={[{
        text: "Rasm xaritasini yaratish uchun HTML <map> elementidan foydalaniladi",
        redWords: ["<map>"]
      },
      {
        text: "Rasm xaritasida bosiladigan maydonlarni aniqlash uchun HTML <area> elementidan foydalaniladi",
        redWords: ["<area>"]
      },
      {
        text: "Rasm xaritasi uchun HTML  <img> elementining  usemap atributidan foydalaniladi ",
        redWords: ["<img>", "usemap"]
      },
      ]} />
      <hr />
      <Table header={"Html teglari"} th={["Teg", "tavsifi"]} values={[
        { first: "<img>", second: "Rasm joylashtirish uchun ishlatiladi", path: '/tags/tag_img' },
        { first: "<map>", second: "Rasm xartiasi yaratish uchun ishlatiladi", path: '/tags/tag_map' },
        { first: "<area>", second: "Bosiladigan maydonni yaratish uchun ishlatiladi", path: '/tags/tag_area' },
        { first: "<picture>", second: "Bir nechta rasm resurslari uchun konteynerni belgilaydi", path: '/tags/tag_picture' },
      ]} />

      <Notes text={"Barcha mavjud HTML teglarining toʻliq roʻyxatini koʻrish uchun bizning"} path="/tags" text1={" HTML teg maʼlumotnomamizga"} text2={"tashrif buyuring ."} />
      <TextContent header={"Dars videosi:"} />
      <YoutubeEmbed embedId="KvDlE5aPcog" />
      <NavButton back='/html/html_images' next='/html/html_images_background' />
    </article >
  )
}

export default ImagesMap;