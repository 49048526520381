import React from 'react';
import { Link } from 'react-router-dom';
import BrowserTable from '../../pages/BrowserTable';
import ListText from '../../pages/ListsText';
import Notes from '../../pages/Notes';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import TagTable from './AttrTable';

function Tagimg() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/tags/tag_img.asp"} />
            <h2 className='header-tutorial'>HTML img tegi</h2>
            <NavButton back='/tags/tag_iframe' next='/tags/tag_input' />
            <TextContent
                code1={`<img src="img_girl.jpg" alt="Girl in a jacket" width="500" height="600">`}
                path1={"try/92"} />
            <hr />
            <TextContent header={"Ta'rifi va qo'llanilishi"}
                text1={"<img> tegi HTML sahifasiga rasm joylashtirish uchun ishlatiladi."}
                redWords1={['<img>']}
                text2={"Rasmlar texnik jihatdan veb-sahifaga kiritilmaydi; rasmlar veb-sahifalarga bog'langan bo'ladi.  <img> tegi bu rasmlar uchun sahifada joy ajratadi. "}
                redWords2={['<img>']}
                text3={" <img> tegi ikkita zarur atributga ega:"}
                redWords3={['<img>']}
            />
            <ListText list={[{
                text: "src - rasm manzilini belgilaydi",
                redWords: ["src"]
            },
            {
                text: "alt - Agar biron sababga ko'ra tasvirni ko'rsatib bo'lmasa, rasm uchun muqobil matnni belgilaydi",
                redWords: ["alt"]
            }]} />
            <Notes type={"Eslatma"} text={"Bundan tashqari, har doim rasmning kengligi va balandligini belgilang. Agar kenglik va balandlik ko'rsatilmagan bo'lsa, tasvir yuklanayotganda sahifa miltillashi mumkin."} />
            <Notes type={"Maslahat"} text={"Tasvirni boshqa hujjatga havola qilish uchun <img> tegini <a> tegi ichiga joylashtirish kifoya (quyidagi misolga qarang)."} />
            <hr />
            <BrowserTable element='<img>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />

            <TagTable arr={['alt', 'crossorigin', 'height', 'ismap', 'loading', 'longdesc', 'referrerpolicy', 'sizes', 'src', 'srcset', 'usemap', 'width']} />
            <TextContent header={"Global atributlar"} />
            <p className='text-tutorial'>&lt;img&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
            <hr />
            <TextContent header={"Hodisa atributlari"} />
            <p className='text-tutorial'>&lt;img&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>

            <TextContent header={"Misollar"}
                code1={`<img src="smiley.gif" alt="Smiley face" width="42" height="42" style="vertical-align:bottom">
                <img src="smiley.gif" alt="Smiley face" width="42" height="42" style="vertical-align:middle">
                <img src="smiley.gif" alt="Smiley face" width="42" height="42" style="vertical-align:top">
                <img src="smiley.gif" alt="Smiley face" width="42" height="42" style="float:right">
                <img src="smiley.gif" alt="Smiley face" width="42" height="42" style="float:left">`}
                path1={"try/120"}
                code1Text={"Rasmni tekislash (CSS bilan):"}
                code2={`<img src="smiley.gif" alt="Smiley face" width="42" height="42" style="vertical-align:middle;margin:0px 50px">`}
                path2={"try/121"}
                code2Text={"Rasm borderi"}
                code3={`<img src="smiley.gif" alt="Smiley face" width="42" height="42" style="vertical-align:middle;margin:0px 50px">`}
                path3={"try/122"}
                code3Text={"Chap va o'ngdan margin berilgan rasm"}
                code4={`<img src="smiley.gif" alt="Smiley face" width="42" height="42" style="vertical-align:middle;margin:50px 0px">`}
                path4={"try/123"}
                code4Text={"Tepadan va pastdan margin berilgan rasm"}
            />
            <TextContent
                code1={`<img src="/image/htmlIcon.png" alt="HTML5 Icon" style="width:128px;height:128px;"> `}
                path1={"try/100"}
                code1Text={"Boshqa papkadagi rasmlar"}
                code2={`<img src="https://www.w3schools.com/images/w3schools_green.jpg" alt="W3Schools.com" style="width:104px;height:142px;">`}
                path2={"try/101"}
                code2Text={"Boshqa serverdagi rasm"}
                code3={`<a href="/html">
                <img src="smile.png" alt="HTML tutorial" style="width:42px;height:42px;">`}
                path3={"try/103"}
                code3Text={"Rasmli havola"}
                code4={`<img src="workplace.jpg" alt="Workplace" usemap="#workmap" width="400" height="379">

  <map name="workmap">
     <area shape="rect" coords="34,44,270,350" alt="Computer" href="computer.htm">
    <area shape="rect" coords="290,172,333,250" alt="Phone" href="phone.htm">
     <area shape="circle" coords="337,300,44" alt="Cup of coffee" href="coffee.htm">
</map>`}
                path4={"try/105"}
                code4Text={"Bosiladigan maydonga ega rasm xaritasi"}
            />
            <TextContent header={"Tegishli sahifalar"} />
            <p className='text-tutorial'>HTML mavzulari: <Link className='table-link' to={"/html/html_images"}>HTML rasmlari</Link></p>
            <p className='text-tutorial'>HTML DOM ma'lumotnomasi: <Link className='table-link' to={"/jsref/dom_obj_image"}>Rasm Obyekti</Link></p>
            <p className='text-tutorial'>HTML CSS mavzulari: <Link className='table-link' to={"/css/css3_images"}>Rasm stillari</Link></p>
            <TextContent header={"Default CSS sozlamalari"}
                text1={"Ko'p brauzerlarda <img> elementida quyidagi default stillar berilgan bo'ladi:"}
                redWords1={['<img>']}
                css1={`img {
display: inline-block;
}`}
                path1={"try/125"} />
            <NavButton back='/tags/tag_iframe' next='/tags/tag_input' />
        </article>)
}

export default Tagimg;
