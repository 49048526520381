import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import NavButton from '../NavButton';
import ColorLists from './colorList';

const colorHeader = (header, arr) => {
    return (<>
        <thead>
            <tr>
                <th style={{ fontSize: "32px", lineHeight: "1.5", fontWeight: "400" }}>{header}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="col">Rang nomi</th>
                <th scope="col">HEX</th>
                <th scope="col">Rang</th>
                <th scope="col">Soya</th>
                <th scope="col">Mix</th>
            </tr>

            {colorSorted(arr)}

        </tbody>
    </>
    )
}

const colorSorted = (colorArray) => {
    let result = "";
    return colorArray.map(element => {
        result = ColorLists.find(item => item.title === element);
        return (
            <tr key={result.hex}>
                <td><Link className='table-link' to={result.title} target="_blank" rel="noopener noreferrer">{result.title}</Link></td>
                <td><Link className='table-link' to={result.title} target="_blank" rel="noopener noreferrer">{result.hex}</Link></td>
                <td style={{ backgroundColor: result.hex, width: '40%' }}></td>
                <td><Link className='table-link' to={result.title} target="_blank" rel="noopener noreferrer">Soyalar</Link></td>
                <td><Link className='table-link' to={result.title} target="_blank" rel="noopener noreferrer">Mix</Link></td>
            </tr>)
    }
    )
}

function ColorGroups() {


    if (window.location.pathname !== '/colors/colors_groups') {
        return (
            <Outlet />
        )
    }
    else return (
        <div className='info-wrapper'>
            <p className='text-tutorial article'> Maqola <a className='table-link' href="https://www.w3schools.com/colors/colors_groups.asp" target='_blank' area-label='vscode' rel="noreferrer"> W3School</a>  veb saytidan tarjima qilingan.</p>
            <h2 className='header-tutorial'>HTML rang guruhlari </h2>
            <NavButton back='/colors/colors_hex' next='/colors/colors_shades' />
            <hr />
            <h3 className='header-content'>Rang guruhlari bo'yicha ajratish</h3>
            <p className='text-tutorial'>Barcha zamonaviy brauzerlar quyidagi 140 ta rang nomlarini qo'llab-quvvatlaydi</p>
            <Link className='text-tutorial text-tutorial__link' to={'/colors/colors_hex'}>140 xil HEX qiymatini ko'rish uchun shu yerni bosing.  </Link>
            <Link className='text-tutorial text-tutorial__link' to={'/colors/colors_names'}>140 xil rang nomlarini ko'rish uchun shu yerni bosing.  </Link>
            <table className='table-content'>

                {colorHeader('Pushti', ['Pink', 'LightPink', 'HotPink', 'DeepPink', 'PaleVioletRed', 'MediumVioletRed'])}
                {colorHeader('Siyohrang', ['Lavender', 'Thistle', 'Plum', 'Orchid', 'Violet', 'Fuchsia', 'Magenta', 'MediumOrchid', 'DarkOrchid', 'DarkViolet', 'BlueViolet', 'DarkMagenta', 'Purple', 'MediumPurple', 'MediumSlateBlue', 'SlateBlue', 'DarkSlateBlue', 'RebeccaPurple', 'Indigo'])}
                {colorHeader('Qizil', ['LightSalmon', 'Salmon', 'DarkSalmon', 'LightCoral', 'IndianRed', 'Crimson', 'Red', 'FireBrick', 'DarkRed'])}
                {colorHeader("Orange", ['Orange', 'DarkOrange', 'Coral', 'Tomato', 'OrangeRed'])}
                {colorHeader("Sariq", ['Gold', 'Yellow', 'LightYellow', 'LemonChiffon', 'LightGoldenRodYellow', 'PapayaWhip', 'Moccasin', 'PeachPuff', 'PaleGoldenRod', 'Khaki', 'DarkKhaki'])}
                {colorHeader("Yashil", ['GreenYellow', 'Chartreuse', 'LawnGreen', 'Lime', 'LimeGreen', 'PaleGreen', 'LightGreen', 'MediumSpringGreen', 'SpringGreen', 'MediumSeaGreen', 'SeaGreen', 'ForestGreen', 'Green', 'DarkGreen', 'YellowGreen', 'OliveDrab', 'DarkOliveGreen', 'MediumAquaMarine', 'DarkSeaGreen', 'LightSeaGreen', 'DarkCyan', 'Teal'])}
                {colorHeader("Cyan", ['Aqua', 'Cyan', 'LightCyan', 'PaleTurquoise', 'Aquamarine', 'Turquoise', 'MediumTurquoise', 'DarkTurquoise'])}
                {colorHeader("Ko'k", ['CadetBlue', 'SteelBlue', 'LightSteelBlue', 'LightBlue', 'PowderBlue', 'LightSkyBlue', 'SkyBlue', 'CornflowerBlue', 'DeepSkyBlue', 'DodgerBlue', 'RoyalBlue', 'Blue', 'MediumBlue', 'DarkBlue', 'Navy', 'MidnightBlue'])}
                {colorHeader("Jigarrang", ['Cornsilk', 'BlanchedAlmond', 'Bisque', 'NavajoWhite', 'Wheat', 'BurlyWood', 'Tan', 'RosyBrown', 'SandyBrown', 'GoldenRod', 'DarkGoldenRod', 'Peru', 'Chocolate', 'Olive', 'SaddleBrown', 'Sienna', 'Brown', 'Maroon'])}
                {colorHeader("Oq", ['White', 'Snow', 'HoneyDew', 'MintCream', 'Azure', 'AliceBlue', 'GhostWhite', 'WhiteSmoke', 'SeaShell', 'Beige', 'OldLace', 'FloralWhite', 'Ivory', 'AntiqueWhite', 'Linen', 'LavenderBlush', 'MistyRose'])}
                {colorHeader("Kulrang", ['Gainsboro', 'LightGray', 'Silver', 'DarkGray', 'DimGray', 'Gray', 'LightSlateGray', 'SlateGray', 'DarkSlateGray', 'Black'])}
            </table>
            <hr />
            <NavButton back='/colors/colors_hex' next='/colors/colors_shades' />
        </div>
    )
}

export default ColorGroups;