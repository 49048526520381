import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import ListText from '../../../pages/ListsText';
import Table from '../../../pages/Table';


function FormAttr() {
    return (
        <div className='info-wrapper'>
                <W3Path path={"https://www.w3schools.com/html/html_form_attributes_form.asp"} />
            <h2 className='header-tutorial'>HTML Input form* atributlari</h2>
            <NavButton back='/html/html_form_attributes' next='/html/html_canvas' />
            <hr />
            <TextContent text1={"Ushbu bobda HTML <input> elementining turli xil form* atributlari tasvirlangan."}
                redWords1={["form", "<input>"]}/>
            <hr />
            <TextContent header={"form atributi"}
                text1={"Inputning form atributi <input> elementi tegishli bo'lgan formani belgilaydi."}
                redWords1={["form", "<input>"]}
                text2={"Bu atributning qiymati u tegishli bo'lgan <form> elementining id atributiga teng bo'lishi kerak."}
 redWords2={["<form>", "id"]}
 code2={`
 <form action="/action_page.php" id="form1">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <input type="submit" value="Yuborish">
</form>

<label for="lname">Familiya:</label>
<input type="text" id="lname" name="lname" form="form1">`}
path2={"try/407"}
code2Text={"HTML formasidan tashqarida joylashgan input maydoni(lekin formaning bir qismi):"}/>
                <hr/>
            <TextContent header={"formaction atributi"}
                text1={"Input formaction atributi forma yuborilganda ma'lumotlarni qayta ishlovchi faylning URL manzilini belgilaydi."}
                redWords1={["formaction"]}
                text2={"Eslatma: Bu atribut <form> elementining action atributini bekor qiladi."}
                redWords2={["action", "<form>"]}
                text3={"formaction atributi quyidagi input turlarida ishlaydi: submit va image."}
                redWords3={["formaction"]}
                code3={`
<form action="/action_page.php">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="submit" value="Yuborish">
  <input type="submit" formaction="/action_page2.php" value="Admin sifatida yuborish">
</form>`}
                path3={"try/408"}
                code3Text={"Turli xil action qiymatiga ega ikkita yuborish tugmasi bo'lgan HTML formasi:"}/>
                <hr/>
                <TextContent header={"formenctype atributi"}
                text1={"Input formenctype atributi forma ma'lumotlari yuborilganda qanday kodlanishi kerakligini belgilaydi (faqat forma method='post' uchun)."}
                redWords1={["formenctype"]}
                text2={"Eslatma: Bu atribut <form> elementining enctype atributini bekor qiladi."}
                redWords2={["<form>"]}
                text3={"formenctype atributi quyidagi input turlarida ishlaydi: submit va image."}
                redWords3={["formenctype"]}
                code3={`
<form action="/action_page.php">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="submit" value="Yuborish">
  <input type="submit" formaction="/action_page2.php" value="Admin sifatida yuborish">
</form>`}
                path3={"try/409"}
                code3Text={"Ikkita yuborish tugmasi bo'lgan forma. Birinchisi forma ma'lumotlarini default kodlash bilan yuboradi, ikkinchisi 'multipart/form-data' sifatida kodlangan forma ma'lumotlarini yuboradi:"}/>
                <hr/>
                <TextContent header={"Formmethod atributi"}
                text1={"Input formmethod atributi URL manziliga forma ma'lumotlarini yuborish uchun HTTP metodini belgilaydi."}
                redWords1={["formmethod"]}
                text2={"Eslatma: Bu atribut <form> elementning method atributini bekor qiladi."}
                redWords2={["<form>"]}
                text3={"Atribut formmethod quyidagi input turlarida ishlaydi: submit and image."}
                redWords3={["formmethod"]}
               text4={"Forma ma'lumotlari URL o'zgaruvchilari(method='get') yoki HTTP post tranzaksiyasi(metod='post') sifatida yuborilishi mumkin."}
               />
               <p><b>"Get"  metodi bo'yicha eslatmalar:</b></p>
            <ListText list={[{
                text: "Ushbu metodda forma ma'lumotlari URL manziliga nom/qiymat juftliklarida qo'shiladi"
            },
            {
                text: "Bu metod foydalanuvchi natijani xatcho'p sifatida belgilamoqchi bo'lgan forma ma'lumotlarini yuborish uchun foydalidir"
            },
            {
                text: "URL manziliga joylashtiriladigan ma'lumotlar chegarasi mavjud(brauzer turiga qarab har xil bo'lishi mumkin), shuning uchun barcha forma ma'lumotlari to'g'ri yetib bormasligi mumkin."
            },
            {
                text: "Nozik ma'lumotlarni uzatish uchun hech qachon 'get' metodidan foydalanmang! (parol yoki boshqa maxfiy ma'lumotlar brauzerning manzil satrida ko'rinadi)"
            },]} />
               <p><b>"Post" metodi bo'yicha eslatmalar:</b></p>
            <ListText list={[{
                text: "Ushbu metod forma ma'lumotlari HTTP post tranzaksiyasi sifatida yuboradi"
            },
            {
                text: "“Post” metodi bilan yuborilgan ma'lumotlar natijasini xatcho'p qilib bo'lmaydi"
            },
            {
                text: "'Post' metodi 'get' dan ko'ra mustahkamroq va xavfsizroqdir va 'post' o'lchamida cheklovlar mavjud emas."
            }]} />
            <TextContent
            code1={`<form action="/action_page.php" method="get">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="submit" value="GET dan foydalanib yuborish">
  <input type="submit" formmethod="post" value="POST dan foydalanib yuborish">
</form>`}
path1={"try/410"} code1Text={"Ikkita yuborish tugmasi bo'lgan forma berilgan. Birinchisi, forma ma'lumotlarini method='get' bilan yuboradi. Ikkinchisi forma ma'lumotlarini method='post' bilan yuboradi:"}
/>
                <hr/>
                <TextContent header={"Formtarget atributi"}
                text1={"Input formtarget atributi forma yuborilgandan so'ng olingan natija qayerda ko'rsatilishini o'zgartiradigan nom yoki kalit so'zni belgilaydi."}
                redWords1={["formtarget"]}
                text2={"Eslatma: Bu atribut <form> elementning target atributini bekor qiladi."}
                redWords2={["<form>"]}
                text3={"Atribut formtarget quyidagi input turlarida ishlaydi: submit va image."}
                redWords3={["formtarget"]}
                code3={`
<form action="/action_page.php">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="submit" value="Yuborish">
  <input type="submit" formtarget="_blank" value="Natijani yangi window/tab oynasida ko'rish">
</form>`}
                path3={"try/411"}
                code3Text={"Turli target qiymatli oynalarga ega bo'lgan ikkita yuborish tugmasi bo'lgan forma:"}/>
                <hr/>
                <TextContent header={"Formnovalidate atributi"}
                text1={"Input formnovalidate atributi <input> elementi yuborilganda ma'lumotlar tekshirilmasligini bildiradi."}
                redWords1={["formnovalidate"]}
                text2={"Eslatma: Bu atribut <form> elementining novalidate atributini bekor qiladi."}
                redWords2={["<form>"]}
                text3={"Atribut formnovalidate quyidagi input turida ishlaydi:  submit."}
                redWords3={["formnovalidate"]}
                code3={`
<form action="/action_page.php">
  <label for="email">Emailingizni kiriting:</label>
  <input type="email" id="email" name="email"><br><br>
  <input type="submit" value="Yuborish">
  <input type="submit" formnovalidate="formnovalidate"
  value="Validatsiyasiz yuborish">
</form>`}
                path3={"try/412"}
                code3Text={"Ikkita yuborish tugmasi bo'lgan forma(Validatsiyali va validatsiyasiz):"}/>
                <hr/>
                <TextContent header={"novalidate atribut"}
                text1={"novalidate atributi <form> atributidir."}
                redWords1={["novalidate"]}
                text2={"novalidate barcha forma ma'lumotlari yuborilganida validatsiya qilinmasligini bildiradi."}
                redWords2={["<form>"]}
                code3={`
<form action="/action_page.php" novalidate>
  <label for="email">Emailingizni kiriting:</label>
  <input type="email" id="email" name="email"><br><br>
  <input type="submit" value="Yuborish">
</form>`}
                path3={"try/354"}
                code3Text={"Taqdim etilganda hech qanday forma ma'lumotlari validatsiya qilinmasligini belgilash:"}/>
            <hr />
            <Table th={["Teg", "Tavsif"]} header={"Form va input elementlari"} values={[
        { id: 0, first: "<form>", second: "Foydalanuvchi ma'lumot kiritishi uchun HTML formasini belgilaydi", path: "/tags/tag_form" },
        { id: 1, first: "<input>", second: "Kirish boshqaruvini belgilaydi", path: "/tags/tag_input" }]} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="1wTKDzzenWk" />
            <NavButton back='/html/html_form_attributes' next='/html/html_canvas' />
        </div>)
}

export default FormAttr;