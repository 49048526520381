import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function HtmlBorder() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_table_borders.asp"} />
            <h2 className='header-tutorial'>HTML jadval borderi</h2>
            <NavButton back='/html/html_tables' next='/html/html_table_sizes' />
            <hr />
            <TextContent
                text1={"HTML jadval borderiga turli stil berish mumkin."} />
            <hr />
            <TextContent header={"Jadval borderi"}
                text1={"Jadvalga border berish uchun har bir jadval katakchasiga border beriladi:"} />
            <table className='table1' style={{ width: "30%", marginBottom: "20px" }}>
                <tbody><tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody></table>
            <TextContent text1={"Jadvalga table, th va td elementlari border xususiyati yordamida border stillari beriladi."}
                redWords1={["border", "table", "td", "th"]}
                css1={`table, th, td {
border: 1px solid black;
}`} path1={"try/163"}
            />
            <hr />
            <TextContent header={"Borderlar birlashishi"}
                text1={"Yuqoridagi misoldagi kabi ikki tomonlama borderlarga ega bo'lmaslik uchun CSS border-collapse xususiyatiga collapse qiymati beriladi."}
                redWords1={["border-collapse", "collapse"]}
                text2={"Bu borderlarni yagona borderga aylantiradi:"} />
            <table className='table2' style={{ width: "30%", marginBottom: "20px" }}>
                <tbody><tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody></table>
            <TextContent
                css1={`table, th, td {
border: 1px solid black;
border-collapse: collapse;
}`} path1={"try/164"} />
            <hr />
            <TextContent header={"Jadval borderi stillari"}
                text1={"Har bir katakga orqa-fon rangini berib, borderga oq rang berilsa(hujjat foni bilan bir xil), border ko'rinmas chegaraga o'xshab ko'rinadi:"} />
            <table className='table4' style={{ width: "30%", marginBottom: "20px" }}>
                <tbody><tr>
                    <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                    </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                    </tr>
                </tbody></table>
            <TextContent
                css1={`table, th, td {
   border: 1px solid white;
   border-collapse: collapse;
 }
 th, td {
   background-color: #96D4D4;
 }`} path1={"try/165"} />

            <hr />
            <TextContent header={"Aylanasimon border"}
                text1={"border-radius xususiyati yordamida border burchaklariga aylanasimon shakl berish mumkin:"}
                redWords1={["border-radius",]} />
            <table className='table5' style={{ width: "30%", marginBottom: "20px" }}>
                <tbody><tr>
                    <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                    </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                    </tr>
                </tbody></table>
            <TextContent
                css1={`table, th, td {
border: 1px solid black;
border-radius: 10px;
}`} path1={"try/166"}
                text2={"Jadval atrofidagi borderni olib tashlash mumkin."} />
            <table className='table6' style={{ width: "30%", marginBottom: "20px" }}>
                <tbody><tr>
                    <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                    </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                    </tr>
                </tbody></table>
            <TextContent
                css1={`th, td {
border: 1px solid black;
border-radius: 10px;
}`} path1={"try/167"} />
            <hr />
            <TextContent header={"Nuqtali jadval borderlari"}
                text1={"border-style xususiyati yordamida border stilini o'zgartirish mumkin:"}
                redWords1={["border-style",]} />
            <table className='table7' style={{ width: "30%", marginBottom: "20px" }}>
                <tbody><tr>
                    <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                    </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                    </tr>
                </tbody></table>
            <TextContent
                text1={"border-style quyidagi qiymatlarga ega:"} />
            <ul>
                <li><span style={{ display: "inline-block", width: "70px" }}><code className="codespan">dotted</code></span> <span style={{ xborder: "2px solid black", borderStyle: "dotted" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span></li>
                <li><span style={{ display: "inline-block", width: "70px" }}><code className="codespan">dashed</code></span> <span style={{ xborder: "2px solid black", borderStyle: "dashed" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span></li>
                <li><span style={{ display: "inline-block", width: "70px" }}><code className="codespan">solid</code></span> <span style={{ xborder: "2px solid black", borderStyle: "solid" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span></li>
                <li><span style={{ display: "inline-block", width: "70px" }}><code className="codespan">double</code></span> <span style={{ xborder: "2px solid black", borderStyle: "double" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span></li>
                <li><span style={{ display: "inline-block", width: "70px" }}><code className="codespan">groove</code></span> <span style={{ xborder: "2px solid black", borderStyle: "groove" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span> </li>
                <li><span style={{ display: "inline-block", width: "70px" }}><code className="codespan">ridge</code></span> <span style={{ xborder: "2px solid black", borderStyle: "ridge" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span> </li>
                <li><span style={{ display: "inline-block", width: "70px" }}><code className="codespan">inset</code></span> <span style={{ xborder: "2px solid black", borderStyle: "inset" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span> </li>
                <li><span style={{ display: "inline-block", width: "70px" }}><code className="codespan">outset</code></span> <span style={{ xborder: "2px solid black", borderStyle: "outset" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span> </li>
                <li><span style={{ display: "inline-block", width: "70px" }}><code className="codespan">none</code></span> <span style={{ xborder: "2px solid black", borderStyle: "none" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span></li>
                <li><span style={{ display: "inline-block", width: "70px" }}><code className="codespan">hidden</code></span> <span style={{ xborder: "2px solid black", borderStyle: "hidden" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span></li>
            </ul>
            <TextContent
                css1={` th, td {
 border-style: dotted;
}`} path1={"try/168"} />
            <hr />
            <TextContent header={"Border rangi"}
                text1={"border-color xususiyati yordamida border rangini o'zgartirish mumkin."}
                redWords1={["border-color"]} />
            <table className='table8' style={{ width: "30%", marginBottom: "20px" }}>
                <tbody><tr>
                    <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                    </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                    </tr>
                </tbody></table>
            <TextContent
                css1={`  th, td {
border-color: #96D4D4;
}`} path1={"try/169"} />
            <TextContent header={"Dars videosi:"} />
            <YoutubeEmbed embedId="DkjIZSBHch4" />
            <NavButton back='/html/html_tables' next='/html/html_table_sizes' />
        </article>
    )
}

export default HtmlBorder;