import React from 'react';
import NavButton from '../NavButton';
import '../Tutorials.css';
import YoutubeEmbed from '../YoutubeEmbed';
import Collapsible from '../../pages/Collapsible';
import Tegs from '../../pages/tegs';
import CodeContent from '../../../tryit/CodeContent';
import Table from '../../pages/Table';
import W3Path from '../../W3Path';

function HtmlParagraphs() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_paragraphs.asp"} />
      <h2 className='header-tutorial text-tutorial-margin'>HTML paragraflari</h2>
      <hr />
      <NavButton back='/html/html_headings' next='/html/html_styles' />
      <hr />
      <p className='text-tutorial'>Paragraf(xat boshi) har doim yangi satrdan boshlanadi va odatda matndan iborat bo'ladi.</p>
      <h3 className='header-content'>HTML paragraflari</h3>
      <p className='text-tutorial'>HTML paragraflari <Tegs tegname="p " />teg bilan belgilanadi.</p>
      <p className='text-tutorial'>Paragraf har doim yangi satrdan boshlanadi va brauzer avtomatik ravishda paragrafdan oldin va keyin biroz bo'sh bo'sh joy (margin) qo'shadi. </p>
      <CodeContent props={`<p>Bu xatboshi</p>
<p>Bu boshqa xatboshi</p>`} pathname={`try/4`} />
      <hr />
      <h3 className='header-content'>HTML ko'rinishi </h3>
      <p className='text-tutorial'>HTML kodi brauzerda qanday ko'rinishda hosil bo'lishini bilib bo'lmaydi.</p>
      <p className='text-tutorial'>Katta yoki kichik ekranlar va o'lchamlari o'zgartirilgan oynalar turli xil natijalarni ko'rsatadi.</p>
      <p className='text-tutorial'>HTML kodiga qo'shimcha bo'shliqlar yoki qo'shimcha qatorlar qo'shish orqali HTML ko'rinishini o'zgartirib bo'lmaydi.</p>
      <p className='text-tutorial'>Sahifa ko'rsatilganda brauzer avtomatik ravishda qo'shimcha bo'shliqlar va qatorlarni olib tashlaydi:</p>
      <CodeContent props={`<p>
Html kodi ichida
bu paragraf
bir necha qator
qilib yozilgan, ammo
brauzer boshqacha qilib
ko'rsatadi.
</p>

<p>
Html kodi ichida
bu paragraf
bir     necha bo'sh     joy qoldirilib
yozilgan, ammo
brauzer      boshqacha qilib
ko'rsatadi.
</p>
    `} pathname={`try/19`} />
      <hr />
      <h3 className='header-content'>HTML gorizontal chizig'i </h3>
      <p className='text-tutorial'> <Tegs tegname="hr " />tegi HTML sahifasida mazmun jihatdan tugallanganlikni ko'rsatadi va ko'pincha gorizontal chiziq sifatida ko'rsatiladi.</p>
      <p className='text-tutorial'><Tegs tegname="hr " /> elementi HTML sahifasida tarkibni ajratish (yoki o'zgarganligini ko'rsatish) uchun ishlatiladi:</p>
      <CodeContent props={`<h1>Bu birinchi darajali sarlavha</h1>
<p>Bu matn</p>
<hr>

<h2>Bu ikkinchi darajali sarlavha</h2>
<p>Bu boshqa matn</p>
<hr>
    `} pathname={`try/20`} />
      <p className='text-tutorial'><Tegs tegname="hr " /> tegi bo'sh teg bo'lgani uchun uning yopilgan tegi yo'q.</p>
      <hr />
      <h3 className='header-content'>She'r yozilishidagi muammo</h3>
      <p className='text-tutorial'> Ushbu she'r bitta satrda aks etadi:</p>
      <CodeContent props={`<p>

Yolg'izim, yagonam deymi,

Topingan koshonam deymi,

O'zing meni ulug'lardan,

Ulug'imsan vatanim...

</p>
    `} pathname={`try/21`} />
      <hr />
      <h3 className='header-content'>Yechim -HTML &lt;pre&gt; elementi</h3>
      <p className='text-tutorial'> HTML <Tegs tegname="pre " /> elementi matnni oldindan belgilangan shaklda yozishda ishlatiladi.</p>
      <p className='text-tutorial'><Tegs tegname="pre " /> elementi ichidagi matn ko'rsatilgan kenglik va shrift(odatda Courier)da hosil bo'ladi, qator va bo'sh joylar saqlab qolinadi.</p>
      <CodeContent props={`<pre>

Yolg'izim, yagonam deymi,

Topingan koshonam deymi,

O'zing meni ulug'lardan,

Ulug'imsan vatanim...

</pre>
    `} pathname={`try/22`} />
      <hr />
      <Table header={"HTML teglari ma'lumotnomasi"} text={"Teglar ma'lumotnomasida ushbu teglar va ularning atributlari haqida qo'shimcha ma'lumotlar berilgan."} th={["Teg", "Tavsif"]} values={[
        { id: 0, first: "<p>", second: "Matnlarni yozish uchun ishlatiladi", path: "tags/tag_p" },
        { id: 1, first: "<hr>", second: "Kontentning mazmunan o'zgarganligini bildiradi", path: "tags/tag_hr" },
        { id: 2, first: "<br>", second: "Yangi qatordan yozishni bildiradi", path: "tags/tag_br" },
        { id: 3, first: "<pre>", second: "Matnni oldindan belgilangan shaklda yozishda ishlatiladi", path: "tags/tag_pre" },
      ]} />
      <hr />
      <h3 className='header-content'>Dars videosi:</h3>
      <YoutubeEmbed embedId="VKAT_jQSkfc" />
      <a style={{ color: 'var(--green)' }} href="https://www.figma.com/file/e1mrvID3VUrj1ZybkVzYpI/She'r" target={'_blank'} rel="noreferrer"> Web sahifa maketi</a>
      <hr />
      <Collapsible>
        <p className='text-tutorial'>Quyidagi maket web sahifasi yaratilsin:</p>
        <a href="https://www.figma.com/file/nneLWeojTiDU8a5DNtUOKb/Reader's-Digest" target={'_blank'} rel="noreferrer"> https://www.figma.com/file/nneLWeojTiDU8a5DNtUOKb/Reader's-Digest</a>
      </Collapsible>

      <NavButton back='/html/html_headings' next='/html/html_styles' />
    </article>
  )
}

export default HtmlParagraphs;
