import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import ListText from '../../../pages/ListsText';
import BrowserTable from '../../../pages/BrowserTable';
import logo from '../../../../images/selfstudylogo.svg'
import { Link } from 'react-router-dom';


function Dragdrop() {
    function allowDrop(ev) {
        ev.preventDefault();
    }

    function drag(ev) {
        ev.dataTransfer.setData("text", ev.target.id);
    }

    function drop(ev) {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        ev.target.appendChild(document.getElementById(data));
    }
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html5_draganddrop.asp"} />
            <h2 className='header-tutorial'>HTML Drag va Drop API</h2>
            <NavButton back='/html/html5_geolocation' next='/html/html5_webstorage' />
            <hr />
            <p>HTMLda har qanday elementni tortib boshqa joyga olib o'tish mumkin.</p>
            <hr />
            <TextContent header={"Misol"} text1={"Self-study logotip rasmini ikkinchi to'rtburchak ichiga tortib olib o'ting."} />
            <div>
                <div id="div1" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)}>
                    <img src={logo} draggable="true" onDragStart={(ev) => drag(ev)} id="drag1" alt="Self-study" />
                </div>
                <div id="div2" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)}></div>
            </div>
            <hr />
            <TextContent header={"Drag va Drop"}
                text1={`Drag va drop juda keng tarqalgan xususiyat bo'lib, bunda obyektni "ushlab", uni boshqa joyga tortib olib borish mumkin.`} />
            <hr />
            <BrowserTable text={"Quyidagi jadvalda Drag va Dropni to'liq qo'llab-quvvatlaydigan birinchi brauzer versiyasi ko'rsatilgan."} element={"Drag va Drop"} chrome={"4.0"} edge={"9.0"} firefox={"3.5"} safari={"6.0"} opera={"12.0"} tabEl={"API"} />
            <hr />
            <TextContent header={"HTML Drag va Drop misoli"}
                text1={"Quyida oddiy tortib olib o'tish misoli keltirilgan:"}
                code1={`<!DOCTYPE HTML>
<html>
<head>
<script>
function allowDrop(ev) {
  ev.preventDefault();
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function drop(ev) {
  ev.preventDefault();
  var data = ev.dataTransfer.getData("text");
  ev.target.appendChild(document.getElementById(data));
}
</script>
</head>
<body>

<div id="div1" ondrop="drop(event)" ondragover="allowDrop(event)"></div>

<img id="drag1" src="img_logo.gif" draggable="true" ondragstart="drag(event)" width="336" height="69">

</body>
</html>`}
                path1={"try/445"}
                text3={"Bu murakkab tuyulishi mumkin, shuning uchun drag va drop hodisasining barcha turli qismlarini ko'rib chiqamiz."} />
            <hr />
            <TextContent header={"Elementni tortib olinadigan qilib qo'yish"}
                text1={"Avvalo: Elementni tortib olinadigan qilish uchun draggable atributiga true qiymati berib qo'yiladi:"}
                redWords1={["draggable"]}
                code1={`<img draggable="true">`} />
            <hr />
            <TextContent header={"ondragstart va setData()"}
                text1={"Endi, element sudralganda nima bo'lishi belgilanadi."}
                text2={"Yuqoridagi misolda ondragstart atributi drag(event) funksiyasini chaqiradi, bu funksiya qaysi ma'lumotlarni sudrab borishni belgilaydi."}
                redWords2={["ondragstart"]}
                text3={"dataTransfer.setData() metodi ma'lumotlar turini va tortilgan ma'lumotlarning qiymatini belgilaydi:"}
                redWords3={["dataTransfer.setData()"]}
                code3={`function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
}`}
                text4={`Bu yerda, ma'lumotlar turi "text" va qiymati esa sudrab olinadigan elementning identifikatoridir("drag1").`} />
            <hr />
            <TextContent header={"ondragover"}
                text1={"ondragover hodisasi sudrab olingan ma'lumotlarni qayerga tashlash mumkinligini belgilaydi."}
                redWords1={["ondragover"]}
                text2={"Default holatda, ma'lumotlarni/elementlarni boshqa elementlarga tashlab bo'lmaydi. Tushishga ruxsat berish uchun biz elementning default holatining oldini olishimiz kerak."}
                text3={"Bu esa ondragover hodisasi uchun event.preventDefault() metodini chaqirish orqali amalga oshiriladi:"}
                redWords3={["event.preventDefault()"]}
                code3={`event.preventDefault()`} />
            <hr />
            <TextContent header={"ondrop"}
                text1={"Surab olingan ma'lumotlar tushirilganda, ondrop hodisasi sodir bo'ladi."}
                text2={"Yuqoridagi misolda ondrop atributi drop(event) funksiyasini chaqiradi:"}
                text3={"Kod mazmuni:"}
                code2={`function drop(ev) {
  ev.preventDefault();
  var data = ev.dataTransfer.getData("text");
  ev.target.appendChild(document.getElementById(data));
}`} />
            <ListText list={[{
                text: "Brauzerda ma'lumotlarning default holatda ishlashining oldini olish uchun preventDefault() chaqiriladi",
            },
            {
                text: "DataTransfer.getData() metodi bilan sudrab olingan ma'lumotlar olinadi. Bu usul setData() metodida bir xil turga o'rnatilgan har qanday ma'lumotlarni qaytaradi",
            },
            {
                text: `Olingan ma'lumotlar sudrab olingan elementning identifikatoridir ("drag1")`,
            },
            {
                text: "Olib tashlangan element tushiriladigan elementga qo'shiladi(append yordamida)",
            },]} />
            <hr />
            <TextContent header={"Ko'proq misollar"} />
            <div className='code-content'>
                <h4 className='header-code'>Misol</h4>
                <p className='text-tutorial'>Ikkita div elementida rasmni biridan ikkinchisiga olib o'tish misoli</p>
                <div className='code-wrapper'>
                    <div style={{ height: "90px" }}>
                        <div id="div3" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)}>
                            <img src={logo} draggable="true" onDragStart={(ev) => drag(ev)} id="drag2" alt="Self-study" />
                        </div>
                        <div id="div4" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)}>
                        </div>
                    </div>
                </div>
                <Link className='button btn btn--large green' to={"try/451"} target={"_blank"}>Sinab ko'rish</Link>
            </div>

            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="cVMuLh9w3Do" />
            <NavButton back='/html/html5_geolocation' next='/html/html5_webstorage' />
        </div>)
}

export default Dragdrop;