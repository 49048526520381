import React from 'react'

function NoteWhite(props) {
    return (
        <>
            <p className='text-tutorial'><strong>{props.type}</strong> {props.text}<a className='text-tutorial text-tutorial__link' href={props.path} target='_blank' area-label='vscode' rel="noreferrer">{props.text1} </a>{props.text2}</p>
        </>
    )
}

export default NoteWhite;