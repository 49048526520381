import React from 'react';
import { Link } from 'react-router-dom';
import ColorChange from '../../ColorChange';
import Collapsible from '../../pages/Collapsible';
import W3Path from '../../W3Path';
import '../Info.css';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';

function HtmlHex() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_colors_hex.asp"} />
      <h2 className='header-tutorial'>HTML HEX ranglari</h2>
      <NavButton back='/html/html_colors_rgb' next='/html/html_colors_hsl' />
      <hr />
      <p className='text-tutorial'>O'n oltilik saboq sistemasi yordamida ranglar quyidagicha ifodalanadi: <b>#<span style={{ color: "red" }}>RR</span><span style={{ color: "green" }}>GG</span><span style={{ color: "blue" }}>BB</span></b>, bu yerda <span style={{ color: "red" }}>RR(qizil)</span>, <span style={{ color: "green" }}>GG(yashil)</span> va <span style={{ color: "blue" }}>BB(ko'k)</span> o'n oltilik butun sonlar rang komponentlarini ifodalaydi.</p>
      <hr />
      <h3 className='header-content'>HEX rang qiymatlari</h3>
      <p className='text-tutorial'>HTMLda rangni o'n oltilik qiymatdan foydalanib quyidagicha ifodalash mumkin:</p>
      <p className='text-tutorial'><b><i># rrggbb</i></b></p>
      <p className='text-tutorial'>Bu yerda rr (qizil), gg (yashil) va bb (ko'k) 00 va ff o'rtasidagi o'n oltilik qiymatlardir (o'nlik sanoq sistemasidagi 0-255 bilan bir xil).</p>
      <p className='text-tutorial'>Masalan, <i>#ff0000</i> qizil rangda ko'rsatiladi, chunki qizil rang eng yuqori qiymatga (ff), qolgan ikkitasi (yashil va ko'k) 00 ga teng.</p>
      <p className='text-tutorial'>Yana bir misol, <i>#00ff00</i> yashil rangda ko'rsatiladi, chunki yashil rang eng yuqori qiymatga (ff), qolgan ikkitasi (qizil va ko'k) 00 ga teng.</p>
      <p className='text-tutorial'>Qora rangni ko'rsatish uchun barcha rang parametrlarini 00 qiymati beriladi, masalan: <i>#000000</i>.</p>
      <p className='text-tutorial'>Oq rangni ko'rsatish uchun barcha rang parametrlariga ff qiymati beriladi, masalan: <i>#ffffff</i>.</p>
      <p className='text-tutorial' id='color_id1'>Quyidagi HEX qiymatlarini aralashtirish orqali tajriba o'tkazing:</p>
      <ColorChange hex="true" />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper' style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", marginBottom: "30px", fontSize: "20px", }}>
          <div style={{ backgroundColor: "#ff0000", minWidth: "45%", margin: "10px", padding: "20px" }}><p>#ff0000</p></div>
          <div style={{ backgroundColor: "#0000ff", minWidth: "45%", margin: "10px", padding: "20px" }}><p>#0000ff</p></div>
          <div style={{ backgroundColor: "#3cb371", minWidth: "45%", margin: "10px", padding: "20px" }}><p>#3cb371</p></div>
          <div style={{ backgroundColor: "#ee82ee", minWidth: "45%", margin: "10px", padding: "20px" }}><p>#ee82ee</p></div>
          <div style={{ backgroundColor: "#ffa500", minWidth: "45%", margin: "10px", padding: "20px" }}><p>#ffa500</p></div>
          <div style={{ backgroundColor: "#6a5acd", minWidth: "45%", margin: "10px", padding: "20px" }}><p>#6a5acd</p></div>
        </div>
        <Link className='button btn btn--large green' to={`try/60`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <h3 className='header-content'>Kulrang</h3>
      <p className='text-tutorial'>Kulrangni hosil qilish uchun odatda uchala parametr teng qiymatda bo'lishi kerak :</p>
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper' style={{ display: "flex", flexWrap: "wrap", color: "#fff", textAlign: "center", marginBottom: "30px", fontSize: "20px", }}>
          <div style={{ backgroundColor: "#404040", minWidth: "45%", margin: "10px", padding: "20px" }}><p>#404040</p></div>
          <div style={{ backgroundColor: "#686868", minWidth: "45%", margin: "10px", padding: "20px" }}><p>#686868</p></div>
          <div style={{ backgroundColor: "#a0a0a0", minWidth: "45%", margin: "10px", padding: "20px" }}><p>#a0a0a0</p></div>
          <div style={{ backgroundColor: "#bebebe", minWidth: "45%", margin: "10px", padding: "20px" }}><p>#bebebe</p></div>
          <div style={{ backgroundColor: "#dcdcdc", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>#dcdcdc</p></div>
          <div style={{ backgroundColor: "#f8f8f8", minWidth: "45%", margin: "10px", padding: "20px" }}><p style={{ color: "#222" }}>#f8f8f8</p></div>
        </div>
        <Link className='button btn btn--large green' to={`try/61`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <h3 className='header-content'>Dars videosi</h3>
      <YoutubeEmbed embedId="BZywRP6N-g4" />
      <Collapsible>
        <p className='text-tutorial'>Ranglarni aralashtirib maket orqa-foni uchun rang tanlang va undan foydalaning:</p>
        <a href='#color_id1' rel="noreferrer"> rang tanlash</a>
      </Collapsible>
      <NavButton back='/html/html_colors_rgb' next='/html/html_colors_hsl' />
    </article>
  )
}

export default HtmlHex;