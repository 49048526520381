import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import ListText from '../../pages/ListsText';
import Notes from '../../pages/Notes';
import Table from '../../pages/Table';
import NoteWhite from '../../pages/NoteWhite';


function Emojis() {
    return (
        <div className='info-wrapper'>
                <W3Path path={"https://www.w3schools.com/html/html_emojis.asp"} />
            <h2 className='header-tutorial'>HTMLda stikerlar</h2>
            <NavButton back='/html/html_symbols' next='/html/html_charset' />
            <hr />
            <p>Emojilar UTF-8 belgilar to'plamidagi belgilar hisoblanadi:😄 😍 💗</p>
            <hr />
            <TextContent header={"Emojilar nima?"}
                text1={"Emojilar tasvir yoki piktogrammaga o'xshab ko'rinadi, lekin ular ularday emas."}
                text2={"Ular UTF-8 (Unicode) belgilar to'plamidagi harflar(belgilar) hisoblanadi."}/>
                <Notes text={"UTF-8 dunyodagi deyarli barcha belgilar va simvollarni qamrab oladi."}/>
                <hr/>
            <TextContent header={"HTML belgilar to'plami atributi"}
                text1={"HTML sahifani to'g'ri ko'rsatish uchun veb-brauzer sahifada ishlatiladigan belgilar to'plamini tanishi kerak."}
                text2={"Bu esa <meta> teg yordamida ko'rsatiladi :"}
                redWords2={["<meta>"]}
                code2={`<meta charset="UTF-8">`}/>
                <Notes text={"Agar ko'rsatilmagan bo'lsa, UTF-8 HTMLdagi default belgilar to'plamidir."}/>
                <hr/>
                <TextContent header={"UTF-8 belgilar"}
                text1={"Ko'pgina UTF-8 belgilarini klaviaturada yozib bo'lmaydi, lekin ular har doim raqamlar yordamida ko'rsatilishi mumkin(obyekt raqamlari deb ataladi):"}/>
                         <ListText list={[{
                text: "A - 65"
            },
            {
                text: "B - 66"
            },
            {
                text: "C - 67"
            },]} />
            <TextContent code1={`<!DOCTYPE html>
<html>
<head>
<meta charset="UTF-8">
</head>
<body>

<p>Men bunday ko'rinaman: A B C</p>
<p>Men bunday ko'rinaman: &#65; &#66; &#67;</p>

</body>
</html>`} path1={"try/331"}/>
                <hr/>
            <TextContent header={"Misol tushuntirildi"}
                text1={"<meta charset='UTF-8'> elementi belgilar to'plamini belgilaydi."}
                redWords1={["<meta", "charset='UTF-8'>"]}
                text2={"A, B va C belgilar 65, 66 va 67 raqamlari bilan ko'rsatiladi."}
                text3={"Brauzerga belgi ko'rsatayotganini tushunishi uchun ob'ekt raqamini &# bilan boshlab, ; (nuqta vergul) bilan tugatish kerak."}/>
                <hr/>
                <TextContent header={"Emoji belgilari"}
                text1={"Emojilar ham UTF-8 alifbosidagi belgilardir:"}/>
                  <ListText list={[{
                text: "😄 128516"
            },
            {
                text: "😍 128525"
            },
            {
                text: "💗 128151"
            },]} />
            <TextContent code1={`<!DOCTYPE html>
<html>
<head>
<meta charset="UTF-8">
</head>
<body>

<h1>Mening birinchi Emoji belgim</h1>

<p>&#128512;</p>

</body>
</html>`} path1={"try/332"}
text2={"Emojilar belgilar bo'lgani uchun ularni HTMLdagi har qanday boshqa belgilar kabi nusxalash, ko'rsatish va o'lchamini o'zgartirish mumkin."}
code2={`<!DOCTYPE html>
<html>
<head>
<meta charset="UTF-8">
</head>
<body>

<h1>Emojis o'lchamini o'zgartirish</h1>

<p style="font-size:48px">
&#128512; &#128516; &#128525; &#128151;
</p>

</body>
</html>`} path2={"try/333"}/>
                <hr/>
                <Table header={"UTF-8-dagi ba'zi emoji belgilari"} th={["Belgi", "Obyekt Nomeri", "Sinab ko'rish"]} values={[
        { id: 0, first: "🗻", second: "	&#128507;", tryPath:"try/334" },
        { id: 1, first: "🗼", second: "&#128508;", tryPath:"try/335" },
        { id: 2, first: "🗽", second: "&#128509;", tryPath:"try/336" },
        { id: 3, first: "🗾", second: "	&#128510;", tryPath:"try/337" },
        { id: 4, first: '🗿', second: "&#128511;", tryPath:"try/338" },
        { id: 5, first: "😀", second: "&#128512;", tryPath:"try/339" },
        { id: 6, first: "😁", second: "	&#128513;", tryPath:"try/340" },
        { id: 7, first: "😂", second: "&#128514;", tryPath:"try/341" },
        { id: 8, first: "😃", second: "	&#128515;", tryPath:"try/342" },
        { id: 9, first: "😄", second: "&#128516;", tryPath:"try/343" },
        { id: 10, first: "😅", second: "&#128517;", tryPath:"try/344" },]} />
        <NoteWhite text1={"UTF-8 dagi ba'zi emoji belgilari"} path={"/charsets/ref_emoji"}/>
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="cOMnofzo1-Y" />
            <NavButton back='/html/html_symbols' next='/html/html_charset' />
        </div>)
}

export default Emojis;