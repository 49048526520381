import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
if(window.location.pathname !=='/html/try'){
root.render(
    <>
    <App />
    </>
);}
else {
    root.render(
        <>
        </>
    );
}
