import React from 'react';
import { Link } from 'react-router-dom';
import BrowserTable from '../../pages/BrowserTable';
import Tegs from '../../pages/tegs';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';

function Tagtr() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_tr.asp"} />
      <h2 className='header-tutorial'>HTML <Tegs tegname={"tr"} /> tegi</h2>
      <NavButton back='/tags/tag_title' next='/tags/tag_track' />
      <TextContent
        code1={`<table>
    <tr>
        <th>Oylar</th>
        <th>Jamg'arma</th>
    </tr>
    <tr>
        <td>Yanvar</td>
        <td>$100</td>
    </tr>
    <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>`}
        path1={"try/152"}
        code1Text={"Uch qator, ikkita sarlavha va ma'lumotlar uchun to'rtta yecheykadan iborat oddiy HTML jadvali:"}
      />
      <hr />
      <TextContent header={"Ta'rifi va qo'llanilishi"}
        text1={"<tr> tegi jadvaldagi qatorni belgilaydi."}
        redWords1={['<tr>']} />
      <p className='text-tutorial'>&lt;tr&gt; tegi bir yoki bir nechta <Link className='table-link' to={"/tags/tag_th"}>&lt;th&gt;</Link> va <Link className='table-link' to={"/tags/tag_td"}>&lt;td&gt;</Link> elementlarini o'z ichiga oladi.</p>
      <hr />
      <BrowserTable element='<tr>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />
      <TextContent header={"Global atributlar"} />
      <p className='text-tutorial'>&lt;tr&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <TextContent header={"Hodisa atributlari"} />
      <p className='text-tutorial'>&lt;tr&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>

      <TextContent header={"Misollar"}
        code1={`<table style="width:100%">
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr style="text-align:right">
  <td>Yanvar</td>
  <td>$100</td>
</tr>
</table>`}
        path1={"try/153"}
        code1Text={"<tr> ichidagi tarkibni tekislash(CSS bilan):"}
        code2={`<table>
 <tr style="background-color:#FF0000">
   <th>Oylar</th>
   <th>Jamg'arma</th>
 </tr>
 <tr>
   <td>Yanvar</td>
   <td>$100</td>
 </tr>
</table>`}
        path2={"try/154"}
        code2Text={"<tr> elementiga fon rangini berish(CSS bilan):"}
        code3={`<table style="height:200px">
 <tr  style="vertical-align:top">
   <th>Oylar</th>
   <th>Jamg'arma</th>
 </tr>
 <tr style="vertical-align:bottom">
   <td>Yanvar</td>
   <td>$100</td>
 </tr>
</table>`}
        path3={"try/155"}
        code3Text={"<tr> ichidagi tarkibni vertikal tekislash (CSS bilan):"}
        code4={`<table>
<tr>
  <th>Ism</th>
  <th>Email</th>
  <th>Telefon</th>
</tr>
<tr>
  <td>John Doe</td>
  <td>john.doe@example.com</td>
  <td>123-45-678</td>
</tr>
</table>`}
        path4={"try/149"}
        code4Text={"Jadval sarlavhalarini yaratish"}
      />

      <TextContent
        code1={`<table>
<caption>Oylik jamg'armalar</caption>
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>`}
        path1={"try/150"}
        code1Text={"Sarlavhali jadvalni yaratish"}
        code2={`<table>
  <tr>
    <th>Ism</th>
    <th>Email</th>
    <th colspan="2">Telefon</th>
  </tr>
  <tr>
    <td>John Doe</td>
    <td>john.doe@example.com</td>
    <td>123-45-678</td>
    <td>212-00-546</td>
  </tr>
</table>`}
        path2={"try/151"}
        code2Text={"Bir qator yoki bir ustundan ortiq bo'lgan jadval yecheykalarini yaratish"} />
      <TextContent header={"Tegishli sahifalar"} />
      <p className='text-tutorial'>HTML darslari: <Link className='table-link' to={"/html/html_tables"}>HTML jadvali</Link></p>
      <p className='text-tutorial'>HTML DOM: <Link className='table-link' to={"/jsref/dom_obj_table"}>Jadval obyekti</Link></p>
      <p className='text-tutorial'>CSS darslari: <Link className='table-link' to={"/css/css_table"}>Jadvali stilli</Link></p>
      <TextContent header={"Default CSS sozlamalari"}
        text1={"Ko'p brauzerlarda <tr> elementida quyidagi default stillar berilgan bo'ladi:"}
        redWords1={['<tr>']}
        css1={`tr {
display: table-row;
vertical-align: inherit;
border-color: inherit;
}`} />
      <NavButton back='/tags/tag_title' next='/tags/tag_track' />
    </article>)
}

export default Tagtr;
