import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import ListText from '../../../pages/ListsText';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';
import BrowserTable from '../../../pages/BrowserTable';


function HtmlGeolocation() {
  function getLocation(){
    navigator.geolocation.getCurrentPosition(position=>{
  const {latitude,longitude} =position.coords;
  document.getElementById("mapholder").innerHTML='<iframe width="100%" height="300" src="https://maps.google.com/maps?q='+latitude+','+longitude+'&amp;z=14&amp;output=embed" allowFullScreen></iframe>'
  })

  }

    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html5_geolocation.asp"} />
            <h2 className='header-tutorial'>HTML Html API</h2>
            <NavButton back='/html/html_youtube' next='/html/html5_draganddrop' />
            <hr />
            <p>HTML Geolocation API foydalanuvchining joylashuvini aniqlash uchun ishlatiladi.</p>
            <hr />
            <TextContent header={"Foydalanuvchi joylashuvini topish"}
                text1={"HTML Geolocation API foydalanuvchining geografik joylashuvini olish uchun ishlatiladi."}
                text2={"Bu maxfiylikni buzganligi uchun foydalanuvchi ma'qullamasa, geografik joylashuv ko'rsatilmaydi."} />
                <div><button onClick={getLocation}>Sinab ko'rish</button></div>
                <br/>
                <div id="mapholder"></div>
            <Notes type={"Eslatma:"} text={"Geolokatsiya GPSga ega qurilmalarda(smartfonlar kabi) aniq ko'rsatiladi."} />
            <hr />
            <BrowserTable text={"Jadvalda Geolocationni to'liq qo'llab-quvvatlaydigan brauzerlarning dastlabki versiyasi ko'rsatilgan."} element={"Geolocation"} chrome={"5.0 - 49.0 (http)   50.0 (https)"} edge={"9.0"} firefox={"3.5"} safari={"5.0"} opera={"16.0"} tabEl={"API"}/>
            <Notes type={"Eslatma:"} text={"Chrome 50 dan boshlab Geolocation API faqat HTTPS kabi xavfsiz kontekstlarda ishlaydi. Agar sizning saytingiz xavfsiz bo'lmagan manbada(masalan, HTTP) joylashtirilgan bo'lsa, u holda foydalanuvchilarning joylashuvini aniqlash so'rovlari ishlamaydi."} />
            <hr />
            <TextContent header={"HTML Geolocationdan foydalanish"}
                text1={"getCurrentPosition() metodi foydalanuvchi joylashuvini qaytarish uchun ishlatiladi."}
                redWords1={["getCurrentPosition()"]}
                text2={"Quyidagi misol foydalanuvchi joylashuvining geografik kenglik va uzunligini qaytaradi:"}
                code2={`<script>
var x = document.getElementById("demo");
function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  } else {
    x.innerHTML = "Geolocation brauzeringiz tomonidan qo'llab-quvvatlanmaydi.";
  }
}

function showPosition(position) {
  x.innerHTML = "Latitude: " + position.coords.latitude +
  "<br>Longitude: " + position.coords.longitude;
}
</script>`}
                path2={"try/442"}
                text3={"Misol izohi:"} />
            <ListText list={[{
                text: "Dastlab geolocation qo'llab-quvvatlanishi tekshiriladi",
            },
            {
                text: "Agar qo'llab-quvvatlansa, getCurrentPosition() metodi ishga tushiriladi. Aks holda, foydalanuvchiga xabarni ko'rsatiladi",
            },
            {
                text: "getCurrentPosition() metodi muvaffaqiyatli bajarilsa, u position obyektini parametrda ko'rsatilgan funktsiya(showPosition)ga qaytaradi.",
            },
            {
                text: "showPosition() funksiyasi geografik kenglik va uzunlikni chiqarib beradi.",
            },]} />
            <p>Yuqoridagi Geolocation misoli juda oddiy  bo'lib, xatoliklarga tekshirish qismi berilmagan.</p>
            <hr />
            <TextContent header={"Xatolar va rad etishlarni boshqarish"}
                text1={"getCurrentPosition() metodining ikkinchi parametri xatolarni boshqarish uchun ishlatiladi. U foydalanuvchining joylashuvini ololmaganda ishga tushiriladigan funksiyani belgilaydi:"}
                redWords1={["getCurrentPosition()"]}
                code1={`<script>
function showError(error) {
  switch(error.code) {
    case error.PERMISSION_DENIED:
      x.innerHTML = "Foydalanuvchi Geolocation so'rovini rad etdi."
      break;
    case error.POSITION_UNAVAILABLE:
      x.innerHTML = "Joylashuv ma'lumotlari mavjud emas."
      break;
    case error.TIMEOUT:
      x.innerHTML = "Foydalanuvchi joylashuvini aniqlash vaqti tugadi."
      break;
    case error.UNKNOWN_ERROR:
      x.innerHTML = "Noma'lum xatolik yuz berdi."
      break;
  }
}
</script>`} path1={"try/443"} />
<hr/>
<TextContent header={"Joylashuvga oid ma'lumotlar"}
                text1={"Ushbu sahifada foydalanuvchining xaritadagi joylashuvini qanday aniqlash mumkinligi ko'rsatilgan."}
                text2={"Geolokatsiya shuningdek, joylashuvga oid ma'lumotlar uchun juda foydali, masalan:"}/>
            <ListText list={[{
                text: "Yangilangan mahalliy ma'lumotlar",
            },
            {
                text: "Foydalanuvchi yaqinidagi uni qiziqtirayotgan joylarni ko'rsatish",
            },
            {
                text: "Ketma-ketlikdagi navigatsiya(GPS)",
            },]} />
            <hr />
            <TextContent header={"getCurrentPosition() metodi qaytaradigan ma'lumotlar"}
                text1={"getCurrentPosition() metodi obyekt qaytaradi. Har doim geografik kenglik, uzunlik va aniqlik xususiyatlari qaytariladi. Shuningdek boshqa xususiyatlarni ham qaytarishi mumkin:"}
                redWords1={["getCurrentPosition()"]} />
            <Table th={["Xususiyat", "Qaytaradi"]} values={[
                { id: 0, first: "coords.latitude", second: "O'nlik sanoq sistemasida geografik kenglik(har doim qaytariladi)", },
                { id: 1, first: "coords.longitude", second: "O'nlik sanoq sistemasida geografik uzunlik(har doim qaytariladi)", },
                { id: 2, first: "coords.accuracy", second: "Joylashuvning aniqligi(har doim qaytariladi)", },
                { id: 3, first: "coords.altitude", second: "O'rtacha dengiz sathidan qancha metr balandligi(mavjud bo'lsa qaytariladi)", },
                { id: 4, first: "coords.altitudeAccuracy", second: "Joylashuv balandligining aniqligi(mavjud bo'lsa qaytariladi)", },
                { id: 5, first: "coords.heading", second: "Shimoldan soat yo'nalishi bo'yicha necha gradusda ekanligi(mavjud bo'lsa qaytariladi)", },
                { id: 6, first: "coords.speed", second: "Tezlik sekundiga metrda (mavjud bo'lsa qaytariladi)", },
                { id: 7, first: "timestamp", second: "Natijaning sanasi/vaqti (mavjud bo'lsa qaytariladi)", },]} />
            <hr />

            <TextContent header={"Geolokatsiya obyektining boshqa qiziqarli metodlari"}
                text1={"Geolocation obyektida yana boshqa qiziqarli metodlar ham mavjud:"} />
            <ListText list={[{
                text: "watchPosition()- foydalanuvchining joriy holatini qaytaradi va foydalanuvchi harakatlanayotganda yangilangan holatini qaytarishda davom etadi (masalan, avtomobildagi GPS).",
                redWords: ["watchPosition()"]
            },
            {
                text: "clearWatch() - watchPosition() metodini to'xtatadi.",
                redWords: ["clearWatch()","watchPosition()"]
            },]} />
            <TextContent
                text1={"Quyida watchPosition() metodga misol keltirilgan. Buni tekshirish uchun GPS qurilmasi kerak(masalan, smartfon):"}
                redWords1={["watchPosition()"]}
                code1={`<script>
var x = document.getElementById("demo");
function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.watchPosition(showPosition);
  } else {
    x.innerHTML = "Geolocation brauzeringiz tomonidan qo'llab-quvvatlanmaydi..";
  }
}
function showPosition(position) {
  x.innerHTML = "Latitude: " + position.coords.latitude +
  "<br>Longitude: " + position.coords.longitude;
}
</script>`}
path1={"try/444"} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="0yySi9vtydQ" />
            <NavButton back='/html/html_youtube' next='/html/html5_draganddrop' />
        </div>)
}

export default HtmlGeolocation;