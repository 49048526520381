import React from 'react';
import Notes from '../../pages/Notes';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import Table from '../../pages/Table';
import YoutubeEmbed from '../YoutubeEmbed';
import Tegs from '../../pages/tegs';
import { Link } from 'react-router-dom';
import CodeContent from '../../../tryit/CodeContent';
import ListText from '../../pages/ListsText';


function BlockInline() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_blocks.asp"} />
            <h2 className='header-tutorial'>HTML Block va Inline Elementlari</h2>
            <NavButton back='/html/html_lists_other' next='/html/html_classes' />
            <hr />
            <p className='text-tutorial-margin'>HTML elementlari default holatda ekranda qanday ko`rinishiga qarab block va inline elementlarga bo`linadi.</p>
            <hr />
            <TextContent header={"Block elementlar."}
                text1={"Block element har doim yangi satrdan boshlanadi va brauzerlar avtomatik ravishda elementdan oldin va keyin biroz bo'sh joy(margin) qo'shadi."}
                text2={"Block element har doim o'zi joylashgan qatorni to'liq egallaydi (iloji boricha chapga va o'ngga cho'ziladi)."}
                text3={"Eng ko'p ishlatiladigan block elementlari: <p> va  <div>"}
                redWords3={['<p>', '<div>']}
                text4={"<p> elementi yordamida matnlar kiritiladi."}
                redWords4={['<p>']} />
            <TextContent
                text1={"Htmlda bo'lim yoki qismlari  <div> elementi yordamida yaratiladi."}
                redWords1={['<div>']} />
            <p className='text-tutorial' style={{ border: "1px solid green", padding: "5px" }}><Tegs tegname={"p"} /> elementi block elementdir.</p>
            <div style={{ border: "1px solid green", padding: "5px", marginBottom: "15px", }}><Tegs tegname={"div"} /> elementi block elementdir.</div>
            <p>HTMLda block elementlar:</p>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Link className='tags__link' to={"/tags/tag_address"}><Tegs tegname={"address"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_article"}><Tegs tegname={"article"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_aside"}><Tegs tegname={"aside"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_blockquote"}><Tegs tegname={"blockquote"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_canvas"}><Tegs tegname={"canvas"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_dd"}><Tegs tegname={"dd"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_div"}><Tegs tegname={"div"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_dl"}><Tegs tegname={"dl"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_dt"}><Tegs tegname={"dt"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_fieldset"}><Tegs tegname={"fieldset"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_figcaption"}><Tegs tegname={"figcaption"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_figure"}><Tegs tegname={"figure"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_footer"}><Tegs tegname={"footer"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_form"}><Tegs tegname={"form"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_hn"}><Tegs tegname={"h1"} />-<Tegs tegname={"h6"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_header"}><Tegs tegname={"header"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_hr"}><Tegs tegname={"hr"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_li"}><Tegs tegname={"li"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_main"}><Tegs tegname={"main"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_nav"}><Tegs tegname={"nav"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_noscript"}><Tegs tegname={"noscript"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_ol"}><Tegs tegname={"ol"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_p"}><Tegs tegname={"p"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_pre"}><Tegs tegname={"pre"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_section"}><Tegs tegname={"section"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_table"}><Tegs tegname={"table"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_tfoot"}><Tegs tegname={"tfoot"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_ul"}><Tegs tegname={"ul"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_video"}><Tegs tegname={"video"} /> </Link>
            </div>
            <hr />

            <TextContent header={"Inline elementlar."}
                text1={"Inline elementlar yangi satrdan boshlanmaydi."}
                text2={"Inline element o'zi uchun kerakli bo'lgan joyni egallaydi."} />
            <p>Bu matn ichida <span style={{ border: "1px solid green", padding: "5px" }}><Tegs tegname={"span"} /> elementi</span> joylashgan.</p>
            <div style={{ border: "1px solid green", padding: "5px", marginBottom: "15px", }}><Tegs tegname={"div"} /> elementi block elementdir.</div>
            <CodeContent props={`<span>Salom dunyo</span>`} pathname={"try/221"} />
            <p>HTMLda inline elementlar:</p>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Link className='tags__link' to={"/tags/tag_a"}><Tegs tegname={"a"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_abbr"}><Tegs tegname={"abbr"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_acronym"}><Tegs tegname={"acronym"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_b"}><Tegs tegname={"b"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_bdo"}><Tegs tegname={"bdo"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_big"}><Tegs tegname={"big"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_br"}><Tegs tegname={"br"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_button"}><Tegs tegname={"button"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_cite"}><Tegs tegname={"cite"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_code"}><Tegs tegname={"code"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_dfn"}><Tegs tegname={"dfn"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_em"}><Tegs tegname={"em"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_i"}><Tegs tegname={"i"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_img"}><Tegs tegname={"img"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_input"}><Tegs tegname={"input"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_kbd"}><Tegs tegname={"kbd"} /></Link>
                <Link className='tags__link' to={"/tags/tag_label"}><Tegs tegname={"label"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_map"}><Tegs tegname={"map"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_object"}><Tegs tegname={"object"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_output"}><Tegs tegname={"output"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_q"}><Tegs tegname={"q"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_samp"}><Tegs tegname={"samp"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_script"}><Tegs tegname={"script"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_select"}><Tegs tegname={"select"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_small"}><Tegs tegname={"small"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_span"}><Tegs tegname={"span"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_strong"}><Tegs tegname={"strong"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_sub"}><Tegs tegname={"sub"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_sup"}><Tegs tegname={"sup"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_textarea"}><Tegs tegname={"textarea"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_time"}><Tegs tegname={"time"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_tt"}><Tegs tegname={"tt"} /> </Link>
                <Link className='tags__link' to={"/tags/tag_var"}><Tegs tegname={"var"} /> </Link>
            </div>
            <Notes type={"Eslatma"} text={"Block element Inline element ichida joylashmaydi!"} />
            <hr />
            <TextContent header={"Block va inline elementlar farqlari."}
                text1={"Keling block va inline elementlarni farqini osonroq tushunish uchun HTML elementlarini idishlarga qiyoslaymiz."}
                text2={"Agar html elementlarini idishlar deb tasavvur qilsak, inline elementlar idish ichidagi narsalarni joylashtirish uchungina yetarli joy egallaydi. Block elementlar bo`lsa idishda qancha narsa boridan qat'iy nazar butun qatorni egallaydi."}
                text3={"Masalan <p> elementi ichida ikkitagina so`z bo`lsayam u butun satrni band qiladi va o`zidan keyin kelgan elementlarni keyingi qatordan boshlanishga majbur qiladi."}
                redWords3={['<p>']}
                text4={"Yana bir muhim farq bu idishlar o`z ichida nimalar saqlay olishida..."}
                redWords4={['<p>']} />
            <p>Block elementlar o`z ichida inline elementlarni, shuningdek, boshqa block elementlarni joylashtirishi ham mumkin.</p>
            <p>Lekin inline element ichida faqat inline element joylasha oladi.<i>(Faqat <Tegs tegname={"a"} /> tegi kichik istisno)</i></p>
            <hr />
            <TextContent header={"<div> elementi"}
                text1={"<div> elementi ko'pincha boshqa HTML elementlari uchun konteyner sifatida ishlatiladi."}
                redWords1={['<div>']}
                text2={"<div> elementi majburiy atributlarga ega emas, lekin style, class va id atributlari eng ko'p ishlatiladi."}
                redWords2={['<div>', 'style', 'class', 'id']}
                text3={"<div> elementiga berilgan CSS stillari <div> ichida joylashgan kontentga ham tegishli bo'ladi:"}
                redWords3={['<div>']}
                code3={`<div style="background-color:black;color:white;padding:20px;">
   <h2>London</h2>
   <p>London - Angliyaning poytaxti. Bu Buyuk Britaniyadagi eng gavjum shahar bo'lib, uning poytaxti 13 milliondan ortiq aholiga ega</p>
</div>`} path3={"try/222"} />
            <hr />

            <TextContent header={"<span> elementi"}
                text1={"<span> elementi matnning bir qismini yoki hujjatning bir qismini belgilash uchun ishlatiladigan ichki konteynerdir."}
                redWords1={['<span>']}
                text2={"<span> elementi majburiy atributlarga ega emas, lekin style, class va id atributlari eng ko'p ishlatiladi."}
                redWords2={['<span>', 'style', 'class', 'id']}
                text3={"<span> elementiga berilgan CSS stillari yordamida matnning bir qismiga stil berish mumkin:"}
                redWords3={['<span>']}
                code3={`<p>Onamning ko'zlari <span style="color:blue;font-weight:bold;">ko'k</span>
va otamning ko'zlari <span style="color:darkolivegreen;font-weight:bold;">qoramtir yashil</span>.</p>`} path3={"try/223"} />
            <hr />
            <TextContent header={"Bo'lim xulosasi"} />
            <ListText list={[{
                text: "Ikkita display qiymati mavjud: block va inline"
            },
            {
                text: "Block element har doim yangi satrdan boshlanadi va har doim o'zi joylashgan satrni to'liq egallaydi."
            },
            {
                text: "Inline elementlar yangi satrdan boshlanmaydi va o'zi uchun kerakli bo'lgan joyni egallaydi."
            },
            {
                text: "<div> block element va ko'pincha boshqa HTML elementlari uchun konteyner sifatida ishlatiladi.",
                redWords: ["<div>"]
            },
            {
                text: "<span> elementi matnning bir qismini yoki hujjatning bir qismini belgilash uchun ishlatiladigan ichki konteynerdir.", redWords: ["<span>"]
            },]} />
            <Table header={"Html teglari"} th={["Teg", "tavsifi"]} values={[
                { first: "<div>", second: "Hujjat bir qismini yoki bo'limini belgilaydi.(block)", path: '/tags/tag_div' },
                { first: "<span>", second: "Hujjat bir qismini belgilaydi.(inline)", path: '/tags/tag_span' },]} />
            <Notes text={"Barcha mavjud HTML teglarining toʻliq roʻyxatini koʻrish uchun bizning"} path="/tags" text1={" HTML teg maʼlumotnomamizga"} text2={"tashrif buyuring."} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="fGzIytYFg0E" />
            <NavButton back='/html/html_lists_other' next='/html/html_classes' />
        </article>)
}

export default BlockInline;