import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';
import pic_video from '../../../../images/pic_video.jpg'


function HtmlMedia() {
    return (
        <div className='info-wrapper'>
                <W3Path path={"https://www.w3schools.com/html/html_media.asp"} />
            <h2 className='header-tutorial'>HTML multimedia</h2>
            <NavButton back='/html/html_svg' next='/html/html_scripts' />
            <hr />
            <p>Tovush, musiqa, videolar, filmlar va animatsiya multimedia deyiladi.</p>
            <hr />
            <TextContent header={"Multimedia nima?"}
                text1={"Multimedia turli xil formatlarda bo'ladi. Eshitishingiz yoki ko'rishingiz mumkin bo'lgan deyarli hamma narsa, masalan, rasmlar, musiqa, ovoz, videolar, yozuvlar, filmlar, animatsiyalar va boshqalar multimedia deyiladi."}
                redWords1={["Multimedia"]}
                text2={"Veb-sahifalarda ko'plab turli xil va har xil formatdagi multimedia elementlari ishlatiladi."}
 redWords2={["multimedia"]}/>
                <hr/>
            <TextContent header={"Brauzerlarda qo'llab-quvvatlanishi"}
                text1={"Birinchi veb-brauzerlar faqat matnni qo'llab-quvvatlagan, bitta rang va bitta shrift bilan cheklangan."}
                text2={"Keyinchalik ranglar, shriftlar, tasvirlar va multimediyalarni qo'llab-quvvatlaydigan brauzerlar paydo bo'ldi!"}/>
                <hr/>
                <TextContent header={"Multimedia formatlari"}
                text1={"Multimedia elementlari(masalan, audio yoki video) media fayllarda saqlanadi."}
                text2={"Fayl turini aniqlashning eng keng tarqalgan usuli bu fayl kengaytmasiga qarashdir."}
                text3={"Multimedia fayllari turli kengaytmali formatlarga ega: .wav, .mp3, .mp4, .mpg, .wmv va .avi."}/>
                <hr/>
            <TextContent header={"Mashhur video formatlari"}/>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <div style={{marginRight: "25px",}}>
                 <img alt="Video formatlari" width="196" height="109" src={pic_video}/>
                </div>
                <div>
                    <p>Bu yerdagi video formatlaridan boshqa juda ko'plab video formatlari mavjud.</p>
                    <p>MP4, WebM va Ogg formatlari HTML tomonidan qo'llab-quvvatlanadi.</p>
                    <p>MP4 formati YouTube tomonidan tavsiya etiladi.</p>
                </div>
            </div>
            <Table th={["Format", "Fayl", "Ta'rifi"]} values={[
        { id: 0, first: "MPEG", second: ".mpg \n.mpeg", third:"MPEG. Harakatlanuvchi rasmlar bo'yicha ekspertlar guruhi(Moving Pictures Expert Group) tomonidan ishlab chiqilgan. Internetda qo'llanilgan birinchi mashhur video formati. Hozirda HTMLda qo'llab-quvvatlanmaydi.",},
        { id: 1, first: "AVI", second: ".avi ", third:"AVI (Audio Video Interleave). Microsoft tomonidan ishlab chiqilgan. Odatda videokameralar va televizor qurilmalarida qo'llaniladi. Windows muhitida yaxshi ishlaydi, lekin veb-brauzerlarda emas.",},
        { id: 2, first: "WMV", second: ".wmv", third:"WMV (Windows Media Video). Microsoft tomonidan ishlab chiqilgan. Odatda videokameralar va televizor qurilmalarida qo'llaniladi. Windows muhitida yaxshi ishlaydi, lekin veb-brauzerlarda emas.",},
        { id: 3, first: "QuickTime", second: ".mov", third:"QuickTime. Apple tomonidan ishlab chiqilgan. Odatda videokameralar va televizor qurilmalarida qo'llaniladi. Apple kompyuterlarida yaxshi ishlaydi, lekin veb-brauzerlarda emas.",},
        { id: 4, first: "RealVideo", second: ".rm \n.ram", third:"RealVideo. Real Media tomonidan ishlab chiqilgan, jonli efir videolarini ko'rish imkoniyati mavjud, ya'ni videoni oldindan yuklab olish talab qilinmaydi. Veb-brauzerlarda ishlamaydi.",},
        { id: 5, first: "Flash", second: ".swf .flv", third:"Flash. Macromedia tomonidan ishlab chiqilgan. Ko'pincha veb-brauzerlarda videoni ko'rish uchun qo'shimcha komponent(plagin) talab qilinadi.",},
        { id: 6, first: "Ogg", second: ".ogg", third:"Teora Ogg. Xiph.Org tashkiloti tomonidan ishlab chiqilgan. HTML tomonidan qo'llab-quvvatlanadi. ",},
        { id: 7, first: "WebM", second: ".webm", third:"WebM. Mozilla, Opera, Adobe va Google tomonidan ishlab chiqilgan. HTML tomonidan qo'llab-quvvatlanadi.",},
        { id: 8, first: "MPEG-4 \n yoki MP4", second: ".mp4", third:"MP4. Moving Pictures Expert Group tomonidan ishlab chiqilgan. Odatda videokameralar va televizor qurilmalarida qo'llaniladi. Barcha brauzerlar tomonidan qo'llab-quvvatlanadi va YouTube tomonidan tavsiya etiladi.",},
        ]} />
                <hr/>
                <Notes type={"Eslatma:"} text={"HTML standartida faqat MP4, WebM va Ogg videolari qo'llab-quvvatlanadi."}/>
                <TextContent header={"Keng qo'llaniluvchi audio formatlari"}
                text1={"MP3 musiqani siqish uchun eng yaxshi formatdir. Hozirda MP3 atamasi raqamli musiqa bilan sinonimga aylandi."}
                text2={"Agar veb-saytingiz musiqalar haqida bo'lsa, MP3 formatini tanlang."}/>
                <Table th={["Format", "Fayl", "Ta'rif"]} values={[
        { id: 0, first: "MIDI", second: ".mid \n.midi", third:"MIDI (Musical Instrument Digital Interface - musiqa asboblari raqamli interfeysi). Sintezatorlar va kompyuter ovoz kartalari kabi barcha elektron musiqa qurilmalari uchun asosiy format hisoblanadi. MIDI fayllari ovozni emas, balki, lekin elektronika tomonidan ijro etilishi mumkin bo'lgan raqamli notalarni o'z ichiga oladi. Barcha kompyuterlar va musiqa qurilmalarida yaxshi ishlaydi, lekin veb-brauzerlarda emas.",},
        { id: 1, first: "RealAudio", second: ".rm \n.ram", third:"RealAudio. Jonli efir audiolarini uzatish imkonini berish uchun Real Media tomonidan ishlab chiqilgan. Veb-brauzerlarda ishlamaydi.",},
        { id: 2, first: "WMA", second: ".wma", third:"WMA (Windows Media Audio). Microsoft tomonidan ishlab chiqilgan. Windows kompyuterlarida yaxshi ishlaydi, lekin veb-brauzerlarda emas.",},
        { id: 3, first: "AAC", second: ".aac", third:"AAC (Advanced Audio Coding - Kengaytirilgan audio kodlash). Apple tomonidan iTunes uchun default format sifatida ishlab chiqilgan. Apple kompyuterlarida yaxshi ishlaydi, lekin veb-brauzerlarda emas.",},
        { id: 4, first: "WAV", second: ".wav", third:"WAV. IBM va Microsoft tomonidan ishlab chiqilgan. Windows, Macintosh va Linux operatsion tizimlarida yaxshi ishlaydi. HTML tomonidan qo'llab-quvvatlanadi.",},
        { id: 5, first: "Ogg", second: ".ogg", third:"Ogg. Xiph.Org tashkiloti tomonidan ishlab chiqilgan. HTML tomonidan qo'llab-quvvatlanadi.",},
        { id: 6, first: "MP3", second: ".mp3", third:"MP3 fayllari aslida MPEG fayllarining ovozli qismidir. MP3 musiqa pleyerlari uchun eng mashhur formatdir. Yuqori sifatini saqlab qolgan holda yaxshi siqiladi(kichik fayllar). Barcha brauzerlar tomonidan qo'llab-quvvatlanadi.",},
        { id: 7, first: "MP4", second: ".mp4", third:"MP4 video formatidir, lekin audio uchun ham foydalanish mumkin. Barcha brauzerlar tomonidan qo'llab-quvvatlanadi.",},
        ]} />
        <Notes type={"Eslatma:"} text={"HTML default holatida faqat  MP3, WAV, and Ogg audiolari qo'llab-quvvatlanadi."}/>

            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="OrLrF7sQeZA" />
            <NavButton back='/html/html_svg' next='/html/html5_video' />
        </div>)
}

export default HtmlMedia;