import React from 'react';
import ListText from '../../../pages/ListsText';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function OtherLists() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_lists_other.asp"} />
      <h2 className='header-tutorial'>HTML boshqa turdagi ro'yxatlari</h2>
      <NavButton back='/html/html_lists_ordered' next='/html/html_blocks' />
      <hr />
      <TextContent text1={"HTML shuningdek tavsif ro'yxatini ham qo'llab-quvvatlaydi."} />
      <hr />
      <TextContent header={"Html tavsif ro'yxati"}
                text1={"Tavsiflar ro'yxati atamalar va har bir atama tavsifidan iborat."}
                text2={"Teg <dl> tavsif ro'yxatini belgilaydi, <dt> teg atamani belgilaydi va <dd> teg har bir atamani tavsiflaydi:"}
                redWords2={["<dl>", "<dt>", "<dd>"]}
                code2={`<dl>
  <dt>Kofe</dt>
  <dd>- qora issiq ichimlik</dd>
  <dt>Sut</dt>
  <dd>- oq rangli ichimlik</dd>
</dl>`} path2={"try/206"} />
      <hr />
      <TextContent header={"Bo'lim xulosasi"} />
      <ListText list={[{
        text: "<dl> - tavsif ro'yxatini belgilaydi.",
        redWords: ["<dl>"]
      },
      {
        text: "<dt> atamani belgilaydi.",
        redWords: ["<dt>"]
      },
      {
        text: "<dd> teg har bir atamani tavsiflaydi.",
        redWords: ["<dd>"]
      },
      ]} />
      <Table header={"Html teglari"} th={["Teg", "tavsifi"]} values={[
        { first: "<ul>", second: "Tartiblanmagan ro'yxatni belgilaydi", path: '/tags/tag_ul' },
        { first: "<ol>", second: "Tartiblangan ro'yxat belgilaydi", path: '/tags/tag_ol' },
        { first: "<li>", second: "Ro'yxat elementini bildiradi", path: '/tags/tag_li' },
        { first: "<dl>", second: "Tavsif ro'yxatini belgilaydi", path: '/tags/tag_dl' },
        { first: "<dt>", second: "Tavsif ro'yxatidagi atamani belgilaydi", path: '/tags/tag_dt' },
        { first: "<dd>", second: "Tavsif ro'yxatidagi atamani tavsiflaydi", path: '/tags/tag_dd' },]} />
         <Notes text={"Barcha mavjud HTML teglarining toʻliq roʻyxatini koʻrish uchun bizning"} path="/tags" text1={" HTML teg maʼlumotnomamizga"} text2={"tashrif buyuring."} />
         <hr/>
      <TextContent header={"Dars videosi:"} />
      <YoutubeEmbed embedId="4iLSjG3PeAw" />
      <NavButton back='/html/html_lists_ordered' next='/html/html_blocks' />
    </article>)
}
export default OtherLists;
