import React from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import './HeroSection.css';

function HeroSection() {
  return (
    <section id='parallax' className='hero-container'>
      <div className='hero-content container'>
        <h2>Web dasturlashni o'rganing</h2>
        <p>O'zbek tilidagi web dasturchilar sayti</p>
        <div className='hero-btns'>
          <Link className='btn btn--large' to='/html'>Boshlash</Link>
        </div>
      </div>
    </section>
  )
}

export default HeroSection;