import React from 'react';
import { Link } from 'react-router-dom';
import './CardsHome.css';
import htmlimg from '../../images/htmlIcon.png';
import cssimg from '../../images/css.png';
import jsimg from '../../images/js.png';



function Cards() {
  return (
    <>
      <div className='cards-home container'>
        <div className='card-in'>
          <h2 className='in-heading'>Dasturlash tillari</h2>
          <p className='in-text'>Har bir mavzu o'quvchi tushunishi uchun sodda tilda tushuntirilgan. Shuningdek, har bir mavzu bo'yicha "Sinab ko'ring" qismida ko'plab misollar berilgan va siz bu misollardan foydalanishingiz, yangi qiymatlar kiritib ko'rib o'z tajribangizni oshirishingiz mumkin. Youtube sahifamizga joylanib borilayotgan videolarimizni ham darslar tartibida tamosha qilishingiz mumkin. Quyidagi bepul  dasturlash tilarini o'rganing</p>
        </div>
      </div >
      <div className='cards-contain'>
        <div className='card__place'>
          <div className='cards-wrap'>
            <div className='the_card'>
              <div className="cards-wrap__front">
                <h2 className="card__header">O'zbek<br /> tilida HTML darsligi</h2>
                <p className="card__text">Hyper Text Markup Language haqida ko'proq bilish</p>
                <img className='card__img' src={htmlimg} alt={"Html logo"} />
              </div>

              <div className='cards-wrap__back'>
                <h3 className='back__header'>HTML - Hypertext Markup Language</h3>
                <p className='back__text'>Internetning WWW xizmati, asosan, veb sahifalarga bog'liq ekan, ular qanday yaratiladi? – degan savol tug'ulishi tabiiy. Veb sahifalar HTML (Hypertext Markup Language – Gipermatnli markerlash tili) tilida yoziladi. HTML – dasturlash tili hisoblanmaydi. Bu tilda hujjat (veb sahifa) tayyorlash uchun Windowsning Bloknot kabi oddiy matn muharriri yetarli.</p>
                <p className='back__text'> HTML o'zining soddaligi bilan mashhur, HTML yordamida siz bemalol o'z veb saytingizni yaratishingiz mumkin. </p>
                <p className='back__text'>HTML veb sahifaning tuzilishini tavsiflaydi. HTML bir qator elementlar(matnlar, rasmlar, video va b.)dan iborat. HTML elementlari brauzerda kontent qanday ko'rsatilishini belgilaydi.HTMLni o'rganish juda oson - bu sizga yoqadi!</p>
                <Link className='button btn btn--large green' to={"/html"}>HTML darslarini boshlash</Link>
              </div>
            </div>
          </div>
        </div>

        <div className='card__place'>
          <div className='cards-wrap'>
            <div className='the_card'>
              <div className="cards-wrap__front">
                <h2 className="card__header">O'zbek<br /> tilida CSS darsligi</h2>
                <p className="card__text">Cascading Style Sheets haqida ko'proq bilish</p>
                <img className='card__img' src={cssimg} alt={"CSS logo"} />
              </div>

              <div className='cards-wrap__back'>
                <h3 className='back__header'>CSS - Cascading Style Sheets</h3>
                <p className='back__text'> CSS elementlarni ekranda, qog'ozda va yoki boshqa medialarda ko'rinishini tasvirlaydi. CSS veb sahifalarga har xil stillar berish uchun ishlatiladi. Hamda bir vaqtning o'zida bir nechta veb sahifalarni dizaynini o'zgartirish mumkin. </p>
                <p className='back__text'> Biror bir veb sahifani turli xil qurilmalarda turli xil ko'rinishini ham ta'minlaydi. CSS file .css formati orqali tashqi xotira saqlab qo'yishingiz va kezi kelganda veb sahifaning HTML kodini o'zgartirmasdan, faqat CSS faylni o'zgartirish orqali veb sahifaga yangidan ko'rinish berishimiz mumkin. Qisqacha qilib aytadigan bo'lsak, HTML faqat veb sahifa kodlarini yozish uchun ishlatiladi, HTML da ham veb sahifaga dizayn bersa bo'ladi, lekin bu juda uzoq jarayon talab qilgani bois, CSS bu vazifani o'z bo'yniga olgan.</p>
                <Link className='button btn btn--large green' to={"/html"}>HTML darslarini boshlash</Link>
              </div>
            </div>
          </div>
        </div>
        <div className='card__place'>
          <div className='cards-wrap'>
            <div className='the_card'>
              <div className="cards-wrap__front">
                <h2 className="card__header">O'zbek<br /> tilida JS darsligi</h2>
                <p className="card__text">JavaScript haqida ko'proq bilish</p>
                <img className='card__img' src={jsimg} alt={"JS logo"} />
              </div>

              <div className='cards-wrap__back'>
                <h3 className='back__header'>JavaScript</h3>
                <p className='back__text'>Javascript, bu Internet uchun katta bo'lmagan xaridor va server ilovalarni yaratishga mo'ljallangan nisbatan oddiy jismga yo'naltirilgan til. Javascript tilida tuzilgan dasturlar HTML hujjatning ichiga joylashtirilib ular bilan birga uzatiladi.</p>
                <p className='back__text'> JavaScriptda yaratilgan dasturlarga misol sifatida foydalanuvchi tomonidan kiritilgan ma'lumotlarni tekshiruvchi, hujjatni ochish yoki yopish vaqtida qandaydir amallarni bajaruvchi dasturlarni keltirish mumkin. Bunday dasturlar foydalanuvchi tomonidan berilgan ko'rsatmalarga — sichqoncha tugmachasini bosilishiga, ma'lumotlarni ekran orqali kiritishiga yoki sichqonchani sahifa bo'ylab siljitilishiga ko'ra ish bajaradi. Bundan tashqari JavaScript dagi dasturlar brauzerning o'zini va hujjatning atributlarini ham boshqarishi mumkin.</p>
                <Link className='button btn btn--large green' to={"/js"}>JS darslarini boshlash</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cards