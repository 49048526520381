import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import ListText from '../../../pages/ListsText';
import BrowserTable from '../../../pages/BrowserTable';
import NoteWhite from '../../../pages/NoteWhite';


function SvgHtml() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html5_svg.asp"} />
            <h2 className='header-tutorial'>HTML SVG grafikasi</h2>
            <NavButton back='/html/html_canvas' next='/html/html_media' />
            <hr />

            <p>SVG vektorga asoslangan grafikalarni XML formatida belgilaydi.</p>
            <hr />
            <TextContent header={"SVG nima?"} />
            <ListText list={[{
                text: "SVG masshtabli vektor grafikasi(Scalable Vector Graphics) degan ma'noni anglatadi",
            },
            {
                text: "SVG veb uchun grafikasida ishlatiladi",
            },
            {
                text: "SVG - bu W3C tavsiyasi",
            },]} />
            <hr />
            <TextContent header={"HTML <svg> elementi"}
                text1={"HTML <svg> elementi SVG grafikalari uchun konteynerdir."}
                redWords1={["<svg>"]}
                text2={"SVGda yo'llar, qutilar, doiralar, matn va grafika tasvirlarini chizishning bir necha usullari mavjud."} />
            <hr />
            <BrowserTable text={"Jadvalda brauzerlarning <svg> elementni to'liq qo'llab-quvvatlaydigan birinchi versiyasi ko'rsatilgan."} element={"<canvas>"} chrome={"4.0"} edge={"9.0"} firefox={"3.0"} safari={"3.2"} opera={"10.1"} />
            <hr />
            <h3>SVG doirasi</h3>
            <div>
                <svg width="100" height="100">
                    <circle cx="50" cy="50" r="40" stroke="green" strokeWidth="4" fill="yellow"></circle>
                </svg>
            </div>
            <TextContent code1={`<!DOCTYPE html>
<html>
<body>

<svg width="100" height="100">
  <circle cx="50" cy="50" r="40" stroke="green" stroke-width="4" fill="yellow" />
</svg>

</body>
</html>`} path1={"try/421"} />
            <hr />
            <h3>SVG to'rtburchak</h3>
            <div>
                <svg width="400" height="100">
                    <rect width="400" height="100" style={{ fill: "rgb(0,0,255)", strokeWidth: "10", stroke: "rgb(0,0,0)" }}></rect>
                </svg>
            </div><br />
            <TextContent code1={`<svg width="400" height="100">
  <rect width="400" height="100" style="fill:rgb(0,0,255);stroke-width:10;stroke:rgb(0,0,0)" />
</svg>`} path1={"try/422"} />
            <hr />
            <h3>SVG yumaloqroq to'rtburchak</h3>
            <div>
                <svg width="300" height="180">
                    <rect x="10" y="10" rx="20" ry="20" width="150" height="150" style={{ fill: "red", stroke: "black", strokeWidth: "5", opacity: "0.5" }}></rect>
                    Kechirasiz, brauzeringiz inline SVG-ni qo'llab-quvvatlamaydi.
                </svg>
            </div>
            <TextContent code1={`<svg width="400" height="180">
  <rect x="50" y="20" rx="20" ry="20" width="150" height="150"
  style="fill:red;stroke:black;stroke-width:5;opacity:0.5" />
</svg>`} path1={"try/423"} />
            <hr />
            <h3>SVG yulduzi</h3>
            <div>
                <svg width="300" height="200">
                    <polygon points="100,10 40,198 190,78 10,78 160,198" style={{ fill: "lime", stroke: "purple", strokeWidth: "5", fillRule: "evenodd" }}></polygon>
                    Kechirasiz, brauzeringiz inline SVG-ni qo'llab-quvvatlamaydi.
                </svg>
            </div>
            <TextContent code1={`<svg width="300" height="200">
<polygon points="100,10 40,198 190,78 10,78 160,198"
style="fill:lime;stroke:purple;stroke-width:5;fill-rule:evenodd;" />
</svg>`} path1={"try/424"} />
            <hr />
            <h3>SVG logotipi</h3>
            <div>
                <svg height="130" width="500">
                    <defs>
                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" style={{ stopColor: "rgb(255,255,0)", stopOpacity: "1" }}></stop>
                            <stop offset="100%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: "1" }}></stop>
                        </linearGradient>
                    </defs>
                    <ellipse cx="100" cy="70" rx="85" ry="55" fill="url(#grad1)"></ellipse>
                    <text fill="#ffffff" fontSize="45" fontFamily="Verdana" x="50" y="86">SVG</text>
                    Kechirasiz, brauzeringiz inline SVG-ni qo'llab-quvvatlamaydi.
                </svg>
            </div>
            <TextContent code1={`<svg height="130" width="500">
  <defs>
    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style="stop-color:rgb(255,255,0);stop-opacity:1" />
      <stop offset="100%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
    </linearGradient>
  </defs>
  <ellipse cx="100" cy="70" rx="85" ry="55" fill="url(#grad1)" />
  <text fill="#ffffff" font-size="45" font-family="Verdana" x="50" y="86">SVG</text>
  Kechirasiz, brauzeringiz inline SVG-ni qo'llab-quvvatlamaydi.
</svg>`} path1={"try/425"} />
            <hr />
            <TextContent header={"SVG va Canvas o'rtasidagi farqlar"}
                text1={"SVG - bu XMLda 2D grafikalarni tavsiflash uchun til. Canvas 2D grafiklarni tezda chizadi(JavaScript bilan)."}
                text2={"SVG XMLga asoslangan, ya'ni har bir element SVG DOM ichida mavjud. Element uchun JavaScript hodisasi boshqaruvchilarini biriktirishingiz mumkin."}
                text3={"SVGda har bir chizilgan shakl obyekt sifatida eslab qolinadi. Agar SVG obyektining atributlari o'zgartirilsa, brauzer shaklni avtomatik ravishda qayta ko'rsatishi mumkin."}
                text4={"Canvas pikselma-piksel tasvirlanadi. Canvasda, grafika chizilganidan so'ng, u brauzer tomonidan unutiladi. Agar uning o'rnini o'zgartirish kerak bo'lsa, butun sahnani, shu jumladan grafik bilan qoplangan bo'lishi mumkin bo'lgan obyektlarni qayta chizish kerak."} />
            <hr />
            <TextContent header={"Canvas va SVG ni solishtirish"}
                text1={"Quyidagi jadvalda Canvas va SVG o'rtasidagi ba'zi muhim farqlar ko'rsatilgan:"} />
            <hr />
            <table className='table-content'>
                <thead>
                    <tr>
                        <th>Canvas</th>
                        <th>SVG</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <ListText list={[{
                                text: "O'zgartirishlarga bog'liq",
                            },
                            {
                                text: "event handler(qayta ishlash)ni qo'llab-quvvatlamaydi",
                            },
                            {
                                text: "Matnni sifatsiz ko'rsatadi",
                            },
                            {
                                text: "Olingan rasmni .png yoki .jpg sifatida saqlashingiz mumkin",
                            },
                            {
                                text: "Grafik intensiv o'yinlar uchun juda mos keladi",
                            },]} />
                        </td>
                        <td>
                            <ListText list={[{
                                text: "O'zgartirishlarga mustaqil",
                            },
                            {
                                text: "event handler(qayta ishlash)ni qo'llab-quvvatlash",
                            },
                            {
                                text: "Yaqinlashtirib ko'rsatish maydonlariga ega ilovalar uchun juda mos(Google Xaritalar)",
                            },
                            {
                                text: "Agar murakkab bo'lsa, sekin ko'rsatiladi(DOMni ko'p ishlatadigan har qanday narsa sekin bo'ladi)",
                            },
                            {
                                text: "O'yin ilovalari uchun mos emas",
                            },]} />
                        </td>
                    </tr>
                </tbody>
                <tfoot></tfoot>
            </table>
            <hr />
            <NoteWhite text={"SVG haqida ko'proq ma'lumot olish uchun"} text1={"SVG qo'llanmamizni"} path={"/graphics/svg_intro"} text2={"o'qing."} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="C0rSWzVVNLk" />
            <NavButton back='/html/html_canvas' next='/html/html_media' />
        </div>)
}

export default SvgHtml;