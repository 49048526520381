import React from 'react';
import NavButton from '../NavButton';
import '../Tutorials.css';
import YoutubeEmbed from '../YoutubeEmbed';
import Collapsible from '../../pages/Collapsible';
import CodeContent from '../../../tryit/CodeContent';
import { Link } from 'react-router-dom';
import Table from '../../pages/Table';
import W3Path from '../../W3Path';

function HtmlFormatting() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_formatting.asp"}/>
      <h2 className='header-tutorial text-tutorial-margin'>HTML matnni formatlash</h2>
      <hr />
      <NavButton back='/html/html_styles' next='/html/html_quotation_elements' />
      <hr />
      <p className='text-tutorial'>Maxsus ma'noga ega bo'lgan matnlarni ifodalash uchun bir necha HTML elementlari ishlatiladi.</p>
      <hr />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper'>
          <p><b>Bu matn qalin shriftda yozilgan</b></p>
          <p><i>Bu matn kursiv</i></p>
          <p>Bu esa<sub> index</sub> va  <sup>daraja</sup></p>
        </div>
        <Link className='button btn btn--large green' to={`try/30`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <h3 className='header-content'>HTML formatlash elementlari</h3>
      <p className='text-tutorial'>Formatlash elementlari matnning maxsus turlarini ifodalash uchun mo'ljallangan:</p>
      <ul className='lists'>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;b&gt;</span>- Qalin matn</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;strong&gt;</span>- Muhim matn</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;i&gt;</span>- Kursiv matn</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;em&gt;</span>- Ta'kidlangan matn</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;mark  &gt;</span>- Belgilangan matn</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;small&gt;</span>- Kichikroq matn</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;del&gt;</span>- O'chirilgan matn</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;ins&gt;</span>-  Kiritilgan matn</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;sub&gt;</span>- index</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>&lt;sup&gt;</span>- daraja</p>
        </li>
      </ul>
      <hr />
      <h3 className='header-content'>HTML &lt;b&gt;va &lt;strong&gt; elementlari</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;b&gt;</span> elementi qo'shimcha ahamiyatga ega bo'lmagan matnlarni qalin qilib yozishda ishlatiladi.</p>
      <CodeContent props={`<p><b>Bu qalin matn.</b></p>`} pathname={`try/31`} />
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;strong&gt;</span>elementi katta ahamiyatga ega bo'lgan matnni ifodalaydi. Kontent ichidagi matn odatda qalin qilib ko'rsatiladi.</p>
      <CodeContent props={`<p><strong>Bu muhim matn!</strong></p>`} pathname={`try/32`} />
      <hr />
      <h3 className='header-content'>HTML &lt;i&gt;va &lt;em&gt; elementlari</h3>
      <p className='text-tutorial'>Veb sahifa eshitish qurilmalari yordamida o'qilayotganda <span className='text-tutorial__red'>&lt;i&gt;</span> elementi yordamida matnning bir qismini muqobil ovoz yoki kayfiyatda ifodalanadi. Kontent ichidagi matn odatda kursiv bilan ko'rsatiladi.</p>
      <p className='text-tutorial'><b>Maslahat: </b><span className='text-tutorial__red'>&lt;i&gt;</span> tegi ko'pincha texnik atama, boshqa tildagi ibora, fikr, kema nomi va hokazolarni ko'rsatish uchun ishlatiladi.</p>
      <CodeContent props={`<p><i>Bu matn kursiv.</i></p>`} pathname={`try/33`} />
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;em&gt;</span>elementi ta'kidlangan matnni ifodalaydi. Element ichidagi matn odatda kursiv bilan ko'rsatiladi.</p>
      <p className='text-tutorial'><b>Maslahat: </b> Veb sahifa eshitish qurilmalari yordamida o'qilayotganda <span className='text-tutorial__red'>&lt;em&gt;</span> element ichidagi so'zlarni ta'kidlab, alohida urg'u bilan o'qiydi.</p>
      <CodeContent props={`<p><em>Bu ta'kidlangan matn  .</em></p>`} pathname={`try/34`} />
      <hr />
      <h3 className='header-content'>HTML &lt;small&gt; elementi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;small&gt;</span> element shrift o'lchami kichikroq matnni ifodalaydi.</p>
      <CodeContent props={`<small>Bu kichikroq matn.</small>`} pathname={`try/35`} />
      <hr />
      <h3 className='header-content'>HTML &lt;mark&gt; elementi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;mark&gt;</span> elementi belgilanishi yoki ta'kidlanishi kerak bo'lgan matnni ifodalaydi:</p>
      <CodeContent props={`<p><mark>Kitob</mark>- bilim manbai.</p>`} pathname={`try/36`} />
      <hr />
      <h3 className='header-content'>HTML &lt;del&gt; elementi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;del&gt;</span> elementi hujjatdan o'chirilgan matnni ifodalaydi. Brauzerlar odatda o'chirilgan matnni ustidan chizilgan holda aks etiradi:</p>
      <CodeContent props={`<p>Men yoqtirgan rang <del>ko'k</del> qizil.</p>`} pathname={`try/37`} />
      <hr />
      <h3 className='header-content'>HTML &lt;ins&gt; elementi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;ins&gt;</span> elementi hujjatga kiritilgan matnni ifodalaydi. Brauzerlar odatda kiritilgan matnning tagiga chizilgan holda aks ettiradi:</p>
      <CodeContent props={`<p>Men yoqtirgan rang <del>ko'k</del> <ins>qizil</ins>.</p>`} pathname={`try/38`} />
      <hr />
      <h3 className='header-content'>HTML &lt;sub&gt; elementi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;sub&gt;</span> elementi index matnini belgilaydi. Index matni oddiy satr yarmidan va ba'zan kichikroq shriftda aks ettiriladi. H<sub>2</sub>O kabi kimyoviy formulalar uchun ishlatish mumkin.</p>
      <CodeContent props={`<p>Bu <sub>indexdagi</sub> matn.</p>`} pathname={`try/39`} />
      <hr />
      <h3 className='header-content'>HTML &lt;sup&gt; elementi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;sup&gt;</span> elementi yuqoridagi matnni ifodalaydi. Daraja matni oddiy satrdan yarim belgi yuqorida ko'rinadi va ba'zan kichikroq shriftda ko'rsatiladi. Bu element yordamida WWW<sup>[1]</sup> kabi izohlarni ifodalash mumkin.</p>
      <CodeContent props={`<p>Bu <sup>darajadagi</sup> matn.</p>`} pathname={`try/40`} />
      <hr />

      <Table th={["Teg", "Tavsif"]} values={[
        { id: 0, first: "<b>", second: "Qalin matn", path: "/tags/tag_b" },
        { id: 1, first: "<del>", second: "O'chirilgan matn", path: "/tags/tag_del" },
        { id: 2, first: "<em>", second: "Ta'kidlangan matn", path: "/tags/tag_em" },
        { id: 3, first: "<i>", second: "Kursiv matn", path: "/tags/tag_i" },
        { id: 4, first: "<ins>", second: "Yangi kiritilgan matn", path: "/tags/tag_ins" },
        { id: 5, first: "<small>", second: "Kichikroq matn", path: "/tags/tag_small" },
        { id: 6, first: "<strong>", second: "Muhim matn", path: "/tags/tag_strong" },
        { id: 7, first: "<sub>", second: "Matnning pastki qismidagi matn", path: "/tags/tag_sub" },
        { id: 8, first: "<sup>", second: "Matnning tepa qismidagi matn", path: "/tags/tag_sup" },
        { id: 9, first: "<mark>", second: "Matnni belgilab ko'rsatish", path: "/tags/tag_mark" },
      ]} />
      <hr />
      <h3 className='header-content'>Dars videosi:</h3>
      <YoutubeEmbed embedId="-CQddfk381E" />
      <Collapsible>
        <p className='text-tutorial'>Quyidagi maket web sahifasi yaratilsin:</p>
        <a href='https://www.figma.com/file/RTq1PEpjXomTCT1mvdnJDl/triangle?node-id=0%3A1' target={'_blank'} rel="noreferrer"> https://www.figma.com/file/RTq1PEpjXomTCT1mvdnJDl/triangle?node-id=0%3A1</a>
      </Collapsible>

      <NavButton back='/html/html_styles' next='/html/html_quotation_elements' />
    </article>)
}

export default HtmlFormatting;