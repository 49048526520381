import React from 'react';
import CodeContent from '../../../../tryit/CodeContent';
import Tegs from '../../../pages/tegs';
import NavButton from '../../NavButton';
import '../../Info.css';
import './Images.css';
import YoutubeEmbed from '../../YoutubeEmbed';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';
import W3Path from '../../../W3Path';
import TextContent from '../../../pages/TextContent';

function Images() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_images.asp"} />
      <h2 className='header-tutorial'>HTML rasmlari</h2>
      <NavButton back='/html/html_links_bookmarks' next='/html/html_images_imagemap' />
      <hr />
      <p className='text-tutorial text-tutorial-margin'>Rasmlar veb-sahifaning dizayni va ko'rinishini yaxshilaydi.</p>
      <hr />
      <div className='images-box'>
        <div className='images im1'></div>
        <div className='images im2'></div>
        <div className='images im3'></div>
      </div>
      <br />
      <CodeContent props={`<img src="chania.jpg" alt="Flowers in Chania" width="460" height="345">`} pathname={`try/93`} />

      <CodeContent props={`<img src="img_girl.jpg" alt="Girl in a jacket" width="500" height="600">`} pathname={`try/92`} />

      <CodeContent props={`<img src="trulli.jpg" alt="Trulli" width="500" height="333">`} pathname={`try/94`} />

      <h3 className='header-content'>HTML rasmlar sintaksisi</h3>
      <p className='text-tutorial'>HTML <Tegs tegname="img" />  tegi rasmni veb-sahifaga joylashtirish uchun ishlatiladi. </p>

      <p className='text-tutorial'>Rasmlar veb-sahifa ichiga joylashtirib qo'yilmaydi, rasmlar veb-sahifalarga biriktirib qo'yiladi. <Tegs tegname="img" />  tegi biriktirilgan rasm uchun saqlash joyini yaratadi. </p>
      <p className='text-tutorial'><Tegs tegname="img" /> bo'sh tegi, unda faqat atributlar mavjud va yopish tegi yo'q. </p>
      <p className='text-tutorial'><Tegs tegname="img" /> tegi ikkita muhum atributga ega:</p>
      <ul className='lists'>
        <li className='item'>
          <p className='text-tutorial'>src - rasm manzilini belgilaydi</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'>alt - rasm haqidagi muqobil matnni belgilaydi</p>
        </li>
      </ul>

      <h3 className='header-content'>src atributi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>src</span> atributi orqali rasm manzili ko'rsatiladi: </p>
      <CodeContent props={`<img src="img_girl.jpg">`} pathname={`try/11`} />
      <p className='text-tutorial'> <span className='text-tutorial__red'>src</span> atributda URL manzilini ko'rsatishning ikki xil yo'li mavjud : </p>
      <p className='text-tutorial'><strong>1. Absolyut URL</strong> - boshqa veb-saytda joylashtirilgan tashqi rasmga havola. Misol: src="https://www.self-study.com/images/img_girl.jpg" .</p>
      <p className='text-tutorial'><strong>Eslatma:</strong> Veb-sahifa yuklanganda, aynan brauzer veb-serverdan rasmni oladi va uni sahifaga joylashtiradi. Shuning uchun, rasm  veb-sahifaga bog'liq aniq bir joyda saqlanishi kerak. Agar brauzer tasvirni topa olmasa, buzilgan havola belgisi va alt ichidagi matn ko'rsatiladi. </p>
      <CodeContent props={`<img src="chania.jpg" alt="Flowers in Chania" width="460" height="345">`} pathname={`try/95`} />
      <hr />
      <h3 className='header-content'>Alt atributi</h3>
      <p className='text-tutorial'> Agar rasm biron sababga ko'ra ko'rinmasa, <Tegs tegname="img " /> tegidagi <span className='text-tutorial__red'>alt</span> atributi ya'ni tasvir uchun alternativ(muqobil) matn aynan shu joyda tasvir borligidan xabar beradi. Rasm ko'rinmay qolishiga ulanishning sekinligi, <span className='text-tutorial__red'>src</span> atributdagi xatolik yoki foydalanuvchi ekranni o'qish vositasidan foydalanganligi sabab bo'lishi mumkin.</p>
      <p className='text-tutorial'><span className='text-tutorial__red'>alt</span> atributning qiymati tasvirni tavsiflashi kerak:</p>
      <CodeContent props={`<img src="chania.jpg" alt="Flowers in Chania" width="460" height="345">`} pathname={`try/95`} />
      <p className='text-tutorial'> Agar brauzer rasmni topa olmasa, <span className='text-tutorial__red'>alt</span> atributning qiymatini ko'rsatadi :</p>
      <CodeContent props={`<img src="wrongname.gif" alt="Flowers in Chania">`} pathname={`try/96`} />

      <Notes type="Maslahat" text="Ekranni oʻqish dasturi HTML kodini oʻqiydi va foydalanuvchiga kontentni “tinglash” imkonini beradi. Ekranni o'qish moslamalari ko'rish qobiliyati zaif yoki o'rganish imkoniyati cheklangan odamlar uchun foydalidir. Ekranni o'qish vositalari rasmning alternativ matnini o'qib eshittiradi. Shuning uchun, alternativ matn rasm mazmunini to'liq aks ettirishi kerak." />
      <hr />
      <h3 className='header-content'>Width, height yoki style</h3>
      <p className='text-tutorial'>HTMLda <span className='text-tutorial__red'>width</span> ,<span className='text-tutorial__red'>height</span> va <span className='text-tutorial__red'>style</span> atributlarining barchasi ishlatiladi. </p>
      <p className='text-tutorial'>Biroq, <span className='text-tutorial__red'>style</span> atributdan foydalanish taklif qilinadi. Bu rasm o'lchamining o'zgartirilishining oldini oladi: </p>
      <CodeContent props={`<!DOCTYPE html>
<html>
<head>
<style>
img {
  width: 100%;
}
</style>
</head>
<body>

<img src="html5.gif" alt="HTML5 Icon" width="128" height="128">

<img src="html5.gif" alt="HTML5 Icon" style="width:128px;height:128px;">

</body>
</html>`} pathname={`try/99`} />
      <hr />
      <h3 className='header-content'>Boshqa papkadagi rasmlar</h3>
      <p className='text-tutorial'>Agar sizning rasmlaringiz boshqa papkada joylashgan bo'lsa, src atributga papka nomini ham kiritishingiz kerak :</p>
      <CodeContent props={`<img src="/image/htmlIcon.png" alt="HTML5 Icon" style="width:128px;height:128px;">`} pathname={`try/100`} />
      <h3 className='header-content'>Boshqa server/veb-saytdagi rasmlar</h3>
      <p className='text-tutorial'>Ba'zi veb-saytlardagi rasmlar boshqa serverda joylashgan bo'ladi.</p>
      <p className='text-tutorial'>Boshqa serverdagi rasmga murojaat qilish uchun src atributida absolyut(to'liq) URL manzilini ko'rsatish kerak:</p>
      <CodeContent props={`<img src="https://www.w3schools.com/images/w3schools_green.jpg" alt="W3Schools.com" style="width:104px;height:142px;">`} pathname={`try/101`} />
      <Notes type="Tashqi tasvirlar bo'yicha eslatmalar: " text="tashqi rasmlar mualliflik huquqi ostida bo'lishi mumkin. Agar siz undan foydalanishga ruxsat olmagan bo'lsangiz, mualliflik huquqi qonunlarini buzgan bo'lishingiz mumkin. Bundan tashqari, siz tashqi rasmlarni nazorat qila olmaysiz; ular to'satdan olib tashlanishi yoki o'zgartirilishi mumkin." />
      <hr />

      <h3 className='header-content'>Animatsiyali rasmlar</h3>
      <p className='text-tutorial'>HTMLda jonlantirilgan GIFlardan ham foydalanish mumkin:</p>
      <CodeContent props={`<img src="programming.gif" alt="Computer man" style="width:48px;height:48px;">`} pathname={`try/102`} />

      <hr />

      <h3 className='header-content'>Rasmli havolalar</h3>
      <p className='text-tutorial'>Rasmni havola sifatida ishlatish uchun <Tegs tegname="img " /> tegni <Tegs tegname="a " /> teg ichiga qo'ying :</p>
      <CodeContent props={`<a href="/html">
<img src="smile.png" alt="HTML tutorial" style="width:42px;height:42px;">
</a>`} pathname={`try/103`} />
      <hr />

      <h3 className='header-content'>Rasm joylashuvini o'zgartirish</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>float</span> yordamida rasm joylashuv joyini ota elementi(ichida shu img elementi joylashgan element)ga nisbatan o'ng tarafga yoki chap tarafga o'zgartirish mumkin.</p>
      <CodeContent props={`
<p>
<img src="smile.png" alt="Smiley face" style="float:right;width:42px;height:42px;">
Rasm joylashuvini o'ng tarafga o'zgartirish:
</p>

<p>
<img src="smile.png" alt="Smiley face" style="float:left;width:42px;height:42px;">
Rasm joylashuvini chap tarafga o'zgartirish
</p>`} pathname={`try/104`} />
      <Table header={"Rasm formatlari"} text={"Barcha brauzerlar(Chrome, Edge, Firefox, Safari, Opera)da  qo'llab-quvvatlanadigan eng keng tarqalgan rasm fayl turlari quyidagicha:"} th={["Qishqartma", "Fayl formati", "Kengaymasi"]} values={[
        { first: "APNG", second: "Animated Portable Network Graphics", third: ".apng" },
        { first: "GIF", second: "Graphics Interchange Format", third: ".gif" },
        { first: "ICO", second: "Microsoft Icon", third: ".ico, .cur" },
        { first: "JPEG", second: "Joint Photographic Expert Group image", third: ".jpg, .jpeg, .jfif, .pjpeg, .pjp" },
        { first: "PNG", second: "	Portable Network Graphics", third: ".png" },
        { first: "SVG", second: "Scalable Vector Graphics", third: ".svg" },
      ]} />
      <h3 className='header-content'>Bo'lim xulosasi
      </h3>
      <ul className='lists'>
        <li className='item'>
          <p className='text-tutorial'>Rasmni veb-sahifaga joylashtirish uchun HTML <Tegs tegname="img" /> elementidan foydalaniladi.</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'> Rasmning URL manzilini ko'rsatish uchun HTML <span className='text-tutorial__red'>&lt;src&gt;</span> atributidan foydalaniladi</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'> Agar rasm biron sababga ko'ra ko'rinmasa,  <span className='text-tutorial__red'>alt</span> atributi ya'ni tasvir uchun alternativ(muqobil) matn aynan shu joyda tasvir borligidan xabar beradi.</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><span className='text-tutorial__red'>width</span> va <span className='text-tutorial__red'>height</span> atributlari yoki CSS <span className='text-tutorial__red'>width</span> va <span className='text-tutorial__red'>height</span> xususiyatlari yordamida rasm o'lchamlari beriladi. </p>
        </li>
        <li className='item'>
          <p className='text-tutorial'> CSS <span className='text-tutorial__red'>float</span> xususiyati yordamida rasmni o'ngga yoki chapga surish mumkin. </p>
        </li>
      </ul>
      <hr />


      <Notes text={"Barcha mavjud HTML teglarining toʻliq roʻyxatini koʻrish uchun bizning"} path="/tags" text1={" HTML teg maʼlumotnomamizga"} text2={"tashrif buyuring."} />

      <TextContent header={"Dars videosi:"} />
      <YoutubeEmbed embedId="kz0YFmpdxkI" />
      <YoutubeEmbed embedId="G57EPdOAH9Y" />
      <NavButton back='/html/html_links_bookmarks' next='/html/html_images_imagemap' />
    </article>
  )
}

export default Images;