import React from 'react';
import { Link } from 'react-router-dom';
import BrowserTable from '../../pages/BrowserTable';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import TagTable from './AttrTable';

function Tagmap() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/tags/tag_map.asp"} />
            <h2 className='header-tutorial'>HTML map tegi</h2>
            <NavButton back='/tags/tag_main' next='/tags/tag_mark' />
            <TextContent
                code1={`<img src="workplace.jpg" alt="Workplace" usemap="#workmap">
        <map name="workmap">
          <area shape="rect" coords="34,44,270,350" alt="Computer" href="computer.htm">
          <area shape="rect" coords="290,172,333,250" alt="Phone" href="phone.htm">
          <area shape="circle" coords="337,300,44" alt="Coffee" href="coffee.htm">
        </map>`}
                path1={"try/105"}
            />
            <hr />
            <TextContent header={"Ta'rifi va qo'llanilishi"}
                text1={"<map> tegi rasm xaritasini yaratish uchun ishlatiladi. Rasm xaritasi bosiladigan maydonlarga ega rasmdir."}
                redWords1={['<map>']}
                text2={" <map> elementning talab qilinadigan name atributi  <img> ning usemap atributi bilan bog'lanib, rasm va xarita o'rtasida munosabat hosil qiladi."}
                redWords2={['<img>', '<map>', 'usemap', 'name']}
                text3={" <map> element rasm xaritasida bosiladigan maydonlarni belgilaydigan bir qancha <area> elementlarini o'z ichiga oladi."}
                redWords3={['<map>', '<area>']}
            />
            <hr />
            <BrowserTable element='<map>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />

            <TagTable arr={['name']} />
            <TextContent header={"Global atributlar"} />
            <p className='text-tutorial'>&lt;map&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
            <hr />
            <TextContent header={"Hodisa atributlari"} />
            <p className='text-tutorial'>&lt;map&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>

            <TextContent header={"Misollar"}
                code1={`<img src="planets.gif" width="145" height="126" alt="Planets" usemap="#planetmap">

     <map name="planetmap">
         <area shape="rect" coords="0,0,82,126" alt="Sun" href="sun.htm">
         <area shape="circle" coords="90,58,3" alt="Mercury" href="mercur.htm">
         <area shape="circle" coords="124,58,8" alt="Venus" href="venus.htm">
     </map>
            `}
                path1={"try/119"}
                code1Text={"Bosiladigan maydonlarga ega boshqa rasm xaritasi:"}
            />
            <TextContent header={"Tegishli sahifalar"} />
            <p className='text-tutorial'>HTML DOM ma'lumotnomasi: <Link className='table-link' to={"/jsref/dom_obj_map"}>Map Obyekti</Link></p>
            <TextContent header={"Default CSS sozlamalari"}
                text1={"Ko'p brauzerlarda <map> elementida quyidagi default stillar berilgan bo'ladi:"}
                redWords1={['<map>']}
                css1={`map {
display: inline;
}`}

            />
            <NavButton back='/tags/tag_main' next='/tags/tag_mark' />
        </article>)
}

export default Tagmap;
