import React from 'react'
import { Link } from 'react-router-dom';

import CodeMirrorContent from './CodeMirrorContent';
import CodeMirrorContentCss from './CodeMirrorContentCss';


function CodeContent(props) {
  return (
    <>
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <p className='text-tutorial'>{props.text}</p>
        <div className='code-wrapper'>
          {props.props ? <CodeMirrorContent value={props.props} /> : <></>}
          {props.css ? <CodeMirrorContentCss value={props.css} /> : <></>}
        </div>
        {props.pathname ? <Link className='button btn btn--large green' to={props.pathname} target={"_blank"}>Sinab ko'rish</Link> : <></>}
      </div>
    </>
  )
}

export default CodeContent;
