import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import ListText from '../../pages/ListsText';
import Notes from '../../pages/Notes';
import Table from '../../pages/Table';


function FilePath() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_filepaths.asp"} />
            <h2 className='header-tutorial'>Html fayl yo'li(fayl path)</h2>
            <NavButton back='/html/html_scripts' next='/html/html_head' />
            <hr />

            <p>Fayl path veb-saytning papka tuzilishidagi faylning joylashishini tavsiflaydi.</p>
            <hr />
            <Table header={"Fayl pathga misollar"} th={["Path", "Tavsif"]} values={[
                { id: 0, first: "<img src='picture.jpg'>", second: "'picture.jpg' fayli joriy sahifa bilan bir xil papkada joylashgan" },
                { id: 1, first: "<img src='images/picture.jpg'>", second: "'picture.jpg' fayli joriy papkadagi 'images' papkasida joylashgan" },
                { id: 2, first: "<img src='/images/picture.jpg'>", second: "'picture.jpg' rasmi ildiz katologda joylashgan 'images' papkasi ichida joylashgan." },
                { id: 3, first: "<img src='../picture.jpg'>", second: "'picture.jpg' fayli joriy papkadan bir daraja yuqori papkada joylashgan." }]} />
            <hr />
            <TextContent header={"HTML fayl yo'llari"}
                text1={"Fayl path veb-saytning papka tuzilishidagi faylning joylashishini tavsiflaydi."}
                text2={"Fayl yo'llari tashqi fayllarga ulanishda ishlatiladi, masalan:"} />
            <ListText list={[{
                text: "Veb-sahifalar"
            },
            {
                text: "Tasvirlar"
            },
            {
                text: "Css stillari"
            },
            {
                text: "JavaScripts"
            },]} />
            <hr />
            <TextContent header={"Absolyut fayl yo'llari"}
                text1={"Absolyut fayl yo'li faylning to'liq URL manzilidir:"}
                code1={`<img src="https://www.w3schools.com/images/picture.jpg" alt="Mountain" style="width:300px">`}
                path1={"try/244"} />
            <Notes text={"<img> tegi quyidagi bobda tushuntirilgan:"} text1={" HTML rasmlari."} path={"/html/html_images"} />
            <hr />
            <TextContent header={"Relyativ fayl yo'llari"}
                text1={"Relyativ fayl yo'li joriy sahifaga nisbatan fayl qayerda joylashganligiga ishora qiladi."}
                text2={"Quyidagi misolda fayl yo'li ildiz katalogida joylashgan 'image' papkasi ichidagi faylga ishora qiladi:"}
                code2={`
<img src="/image/picture.jpg" alt="Mountain" style="width:300px">`}
                path2={"try/245"}
                text3={"Quyidagi misolda fayl yo'li joriy papkada joylashgan 'image' papkasidagi faylga ishora qiladi:"}
                code3={"<img src='image/picture.jpg' alt='Mountain' style='width:300px'>"}
                path3={"try/246"}
                text4={`Quyidagi misolda fayl yo'li joriy jilddan bir daraja yuqori papkada joylashgan 'image' papkasidagi faylga ishora qiladi:`}
                code4={'<img src="../image/picture.jpg" alt="Mountain" style="width:300px">'}
                path4={'try/247'}
            />
            <hr />
            <TextContent header={"Eng yaxshi amaliyot"}
                text1={"Relyativ fayl yo'llaridan foydalanish eng yaxshi amaliyotdir(agar iloji bo'lsa)."}
                text2={"Relyativ fayl yo'llaridan foydalanganda, veb-sahifalaringiz joriy asosiy URL manzilingizga bog'lanmaydi. Barcha havolalar sizning shaxsiy kompyuteringizda (localhost), shuningdek, joriy umumiy domeningizda va kelajakdagi umumiy domeningizda ishlaydi."} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="VnAob6qGxBI" />
            <NavButton back='/html/html_scripts' next='/html/html_head' />
        </article>)
}

export default FilePath;