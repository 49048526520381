import React from 'react';
import NavButton from '../NavButton';
import '../Tutorials.css';
import nodePade from '../../../images/notepad.png';
import saveFile from '../../../images/save.png';
import browserOpen from '../../../images/browser.png';
import preferenceImg from '../../../images/preferences.jpg';
import texteditSave from '../../../images/texteditSave.jpg';
import CodeMirrorContent from '../../../tryit/CodeContent/CodeMirrorContent';
import YoutubeEmbed from '../YoutubeEmbed';
import Collapsible from '../../pages/Collapsible';
import W3Path from '../../W3Path';

function HtmlEditor() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_editors.asp"}/>
      <h2 className='header-tutorial'>HTML muharrirlari</h2>
      <NavButton back='/html/html_intro' next='/html/html_basic' />
      <hr />
      <p className='text-tutorial text-tutorial-margin'>HTMLni o'rganish uchun oddiy matn muharriridan ham foydalansak bo'ladi.</p>
      <hr/>
      <h3 className='header-content'>Notepad(Блокнот) yoki TextEdit yordamida veb sahifa yaratish</h3>
      <p className='text-tutorial'>Professional HTML muharrirlari yordamida veb-sahifalarni yaratish va o'zgartirish mumkin.</p>
      <p className='text-tutorial'><strong>Notepad (PC) yoki TextEdit (Mac)</strong> HTMLni o'rganish uchun foydalanadigan asosiy matn muharrirlari.</p>
      <p className='text-tutorial'>Notepad(Блокнот) yoki TextEdit yordamida birinchi veb-sahifangizni yaratish uchun quyidagi amallarni bajaring...</p>
      <h3 className='header-content'>1-qadam: Notepad(Блокнот)ni ochish (kompyuter uchun)</h3>
      <p className='text-tutorial'><strong>Windows 8 yoki undan keyingi versiyalari uchun Notepad(Блокнот)ni ochishning quyidagi usullari bor:</strong></p>
      <ul className='lists'>
        <li className='item'>
          <p className='text-tutorial'>Qidirib toping. Qidiruv maydoniga <strong>"Notepad"("Блокнот")</strong> ni kiriting va qidiruv natijalaridan Notepad(Блокнот)ni tanlang va oching</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'>Windows ish stoli bo'sh joyiga sichqonchaning o'ng tarafini bosish orqali menyuni oching. Menyuda <strong>"Создать"</strong> ni tanlang va <strong>Notepad(Блокнот)</strong> oching</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><strong>"Start" &gt; "Programs" &gt; "Accessories" &gt; "Notepad"</strong> oching</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><strong>'Windows (Windows yorlig'i)' + 'R'</strong> tugmalarini bosib, <strong>"notepad"</strong> ni yozing, so'ngra <strong>"OK"</strong> ni bosing .</p>
        </li>
      </ul>
      <hr />
      <h3 className='header-content'>1-qadam: TextEdit oching (Mac uchun):</h3>
      <ul className='lists'>
        <li className='item'>
          <p className='text-tutorial'><strong>"Finder" &gt; "Applications" &gt; "TextEdit"</strong>ni oching</p>
        </li >
        <li className='item'>
          <p className='text-tutorial'>Ilova fayllarini to'g'ri saqlash uchun ba'zi o'zgartirishlar kiriting. <strong>"Preferences"  &gt; "Format" &gt;  "Plain Text"</strong> ni tanlang .</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'>Keyin <strong>"Open and Save"</strong> ostida <strong>"Display HTML files as HTML code instead of formatted text"</strong> degan katakchani belgilang.</p>
        </li>
        <li className='item'>
          <p className='text-tutorial'>Keyin kodni joylashtirish uchun yangi hujjatni oching.</p>
        </li>
      </ul>
      <h3 className='header-content'>2-qadam: HTML kodini yozing</h3>
      <p className='text-tutorial'>Quyidagi kodni <strong>"Notepad"("Блокнот")</strong> yoki <strong>"TextEdit"</strong>ga yozing yoki nusxa ko'chiring:</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`<!DOCTYPE html>
<html>
<body>

<h1>Mening birinchi sarlavham</h1>

<p>Mening birinchi matnim</p>

</body>
</html>`} />
        </div>
      </div>
      <img src={nodePade} alt="Notepad ko'rinishi" style={{ height: "286px", width: "427px", margin: "20px" }} />
      <h3 className='header-content'>3-qadam: HTML sahifani saqlang</h3>
      <p className='text-tutorial'>Faylni kompyuteringizga saqlang.</p>
      <p className='text-tutorial'><strong><i>Kompyuter uchun: </i></strong></p>
      <p className='text-tutorial'><strong> Notepad(Блокнот)</strong> menyusida <strong>"File"("Файл") &gt; "Save as"("Сохранить как...")</strong>ni tanlang .</p>
      <p className='text-tutorial'>Faylni <strong>"index.htm"</strong> deb nomlang va <strong>"Encoding"("Кодировка")</strong> ni <strong>UTF-8</strong> ga o'rnating (bu HTML fayllari uchun afzal ko'rilgan kodlash).</p>
      <img src={saveFile} alt="Faylni saqlash" style={{ height: "230px", width: "812px", margin: "20px" }} />
      <p className='text-tutorial'><strong><i>Mac uchun: </i></strong> </p>
      <p className='text-tutorial'> <strong>"TextEdit" &gt; "Preferences" &gt; "Open and Save" </strong>ni tanlang .</p>
      <img src={preferenceImg} alt="Faylni saqlash" style={{ height: "622px", width: "435px", margin: "20px" }} />
      <p className='text-tutorial'>Faylni <strong>"myFirst.html"</strong> deb nomlang va <strong>"Encoding"("Кодировка")</strong>ni <strong>UTF-8</strong> ga o'rnating (bu HTML fayllari uchun afzal ko'rilgan kodlash).</p>
      <img src={texteditSave} alt="Faylni saqlash" style={{ height: "448px", width: "640px", margin: "20px" }} />
      <div className='note-content'>
        <p className='text-tutorial'><strong>Maslahat: </strong> Fayl kengaytmasi sifatida .htm yoki .html dan foydalanishingiz mumkin. Hech qanday farq yo'q; bu ixtiyoriy.</p>
      </div>
      <h3 className='header-content'>4-qadam: Brauzeringizda HTML sahifasini ko'ring</h3>
      <p className='text-tutorial'>Saqlangan HTML faylni brauzeringizda oching (faylni ikki marta bosing yoki sichqonchaning o'ng tugmachasini bosing va <strong>"Open with"</strong> ni tanlang).</p>
      <p className='text-tutorial'>Natija quyidagicha ko'rinadi:</p>
      <img src={browserOpen} alt="Browserda ochish" style={{ height: "312px", width: "504px", margin: "20px" }} />
      <h3 className='header-content'>Dars videosi</h3>
      <YoutubeEmbed embedId="Gu1wNsffs4k" />
      <Collapsible>
      <p className='text-tutorial'><strong>1.</strong>Notepad(Блокнот) ni oching. </p>
        <p className='text-tutorial'><strong>2.</strong> Sahifa sarlavhasini nomini <i>'HTML muharrirlari'</i> deb nomlang</p>
        <p className='text-tutorial'><strong>3.</strong> Sarlavhaga <i>"Html muharrirlari haqida"</i> deb kiriting.</p>
        <p className='text-tutorial'><strong>4.</strong>  Quyidagi matnni kiriting:<i>"Misol uchun Notepad , TextEdit yoki bloknot kabi matn muharriri bilan oddiy Veb sahifalar yaratish mumkin. Oddiy matn muharrirlari yordamida siz yaratilgan veb sahifa ni osonlik bilan o'zgartirish imkoniyatingiz bo'ladi.".</i></p>
        <p className='text-tutorial'><strong>5.</strong> Faylni saqlang va brauzerda oching</p>
      </Collapsible>

      <h3 className='header-content'><strong>Visual Studio Code kod muharriri</strong></h3>
      <p className='text-tutorial'>Visual Studio Code (VS Code) - Windows, Linux va macOS uchun Microsoft tomonidan ishlab chiqilgan kod muharriri.
        U veb va bulutli ilovalarni platformalararo ishlab chiqish uchun "yengil" kod muharriri sanaladi.</p>

      <h3 className='header-content'>Visual Studio Code kod muharririni yuklab olish va o'rnatish videosi</h3>
      <p className='text-tutorial'>Dasturni o'rnatish bu juda oson ish. Dasturni asosiy sahifasidan yuklab olishingiz mumkin.</p>
      <div>
        <span className='text-tutorial'><strong>Dasturning bosh sahifasi: </strong></span>
        <a className='text-tutorial' href='https://code.visualstudio.com/' target='_blank' area-label='vscode' rel="noreferrer">https://code.visualstudio.com/</a>
      </div>
      <YoutubeEmbed embedId="rDy2TDPXJuw" />
      <h3 className='header-content'>Visual Studio Code kod muharriri yordamida Html kodini yozamiz</h3>
      <p className='text-tutorial'> Visual Studio Code dasturida ish faoliyatingizni yengillashtirish uchun bir qancha pluginlar(ya'ni kengaytmalar) mavjud. Ana shunday kengaytmalardan biri <strong>Live Server</strong> - Dasturchiga qulaylik tug'diradi. Ya'ni html da sahifa yaratayotganimizda kod ustiga borib context menyuni ochsak open with live server tugmasi mavjud bo'ladi. Shuni bossak brauzer ochilib kodni har safar o'zgartirganimizda sahifa yangilanadi. Agar o'zgarish mavjud bo'lmasa yangilanmaydi. Ya'ni F5 ni bosib yangilab o'tirish shart emas.</p>

      <YoutubeEmbed embedId="1zSbjdl6nzA" />
      <Collapsible>
      <p className='text-tutorial'><strong>1.</strong>Loyiha uchun 'exercise' nomli fayl yarating va VS Codeda oching </p>
        <p className='text-tutorial'><strong>2.</strong> Sahifa sarlavhasini nomini <i>'VS Code'</i> deb nomlang</p>
        <p className='text-tutorial'><strong>3.</strong> Sarlavhaga <i>"Visual Studio Code dasturi haqida"</i> deb kiriting.</p>
        <p className='text-tutorial'><strong>4.</strong>  Quyidagi matnni kiriting:<i>"Visual Studio Code (VS Code) - Windows, Linux va macOS uchun Microsoft tomonidan ishlab chiqilgan kod muharriri. U veb va bulutli ilovalarni platformalararo ishlab chiqish uchun "yengil" kod muharriri sanaladi. Kodni bosqichma-bosqich ishlab chiqish, Git vositalari, sintaksisni ajratib ko'rsatish, IntelliSense va refaktoring vositalarini o'z ichiga oladi. Unda sozlash uchun keng imkoniyatlar mavjud: foydlanuvchi ko'rinishlari, klaviatura yorliqlari va konfiguratsiya fayllari. Dastur foydalanish uchun bepul, ochiq kodli dasturiy ta'minot sifatida ishlab chiqilgan, lekin tayyor yig'ilishlar xususiy litsenziya ostida tarqatiladi".</i></p>
        <p className='text-tutorial'><strong>5.</strong> Faylni saqlang va brauzerda oching.</p>
      </Collapsible>
      <NavButton back='/html/html_intro' next='/html/html_basic' />
    </article>
  )
}

export default HtmlEditor