import React from 'react';
import { Link } from 'react-router-dom';
import NavButton from '../NavButton';
import '../Info.css';
import CodeContent from '../../../tryit/CodeContent';
import W3Path from '../../W3Path';
import BrowserTable from '../../pages/BrowserTable';

function Tagb() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_b.asp"} />
      <h2 className='header-tutorial'> HTML&lt;b&gt; tegi</h2>
      <NavButton back='/tags/tag_audio' next='/tags/tag_base' />
      <hr />
      <CodeContent props={` <p>Bu oddiy matn.</p>
<p><b>Bu qalin matn.</b></p>`} pathname={`try/31`} text="Matn(muhim bo'lmagan matn)ni qalin qilib yozish:" />
      <h3 className='header-content'>Tavsifi va qo'llanilishi</h3>
      <p className='text-tutorial'> <span className='text-tutorial__red'>&lt;b&gt;</span> elementi unchalik muhim bo'lmagan matnni qalin qilib yozishda ishlatiladi.</p>
      <hr />
      <h3 className='header-content'>Maslahat va eslatmalar</h3>
      <p className='text-tutorial'><b>Eslatma:</b> HTML5 spetsifikatsiyasiga ko'ra, <span className='text-tutorial__red'>&lt;b&gt;</span> tegi boshqa teglar mos kelmagandagina OXIRGI chora sifatida ishlatilishi kerak. Spetsifikatsiyada aytilishicha, sarlavhalar <span className='text-tutorial__red'>&lt;h1&gt;</span> dan <span className='text-tutorial__red'>&lt;h6&gt;</span> teglari bilan belgilanishi kerak, ta'kidlangan matn <span className='text-tutorial__red'>&lt;em&gt;</span> tegi bilan belgilanishi kerak, muhim matn <span className='text-tutorial__red'>&lt;strong&gt;</span> tegi bilan belgilanishi va belgilangan/ta'kidlangan matn <span className='text-tutorial__red'>&lt;mark&gt;</span> tegi bilan belgilanisi kerak.</p>
      <p className='text-tutorial'><b>Maslahat:</b> Qalin matn o'rnatish uchun quyidagi CSS-dan ham foydalanish mumkin: "font-weight: bold;".</p>
      <hr />
      <BrowserTable element='<b>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />
      <h3 className='header-content'>Global atributlar</h3>
      <p className='text-tutorial'>&lt;b&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />

      <h3 className='header-content'>Hodisa atributlari</h3>
      <p className='text-tutorial'>&lt;b&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <h3 className='header-content'>Misollar</h3>
      <CodeContent props={` <p>Bu oddiy matn- <span style="font-weight:bold;">va bu qalin matn</span>.</p>`} pathname={`try/90`} text="CSS stillari yordamida matnni qalin qilish:" />

      <h3 className='header-content'>Tegishli sahifalar</h3>
      <p className='text-tutorial'>HTML mavzulari: <Link className='table-link' to={"/html/html_formatting"}>HTML matn formatlari.</Link></p>
      <p className='text-tutorial'>HTML DOM ma'lumotnomasi: <Link className='table-link' to={"/jsref/dom_obj_b"}>Qalin ob'ekt</Link></p>
      <hr />
      <h3 className='header-content'>Standart CSS sozlamalari</h3>
      <p className='text-tutorial'>Ko'p brauzerlarda quyidagi default holatda quyidagi stillar berilgan bo'ladi:</p>
      <CodeContent css={`b {
  font-weight: bold;
}`} pathname={`try/91`} />
      <hr />
      <NavButton back='/tags/tag_audio' next='/tags/tag_base' />
    </article>
  )
}

export default Tagb;
