import React from 'react';

function W3Path(props) {
  return (
    <>
      <p className='text-tutorial article'> Maqola <a className='table-link' href={props.path} target='_blank' area-label='vscode' rel="noreferrer"> W3School</a>  veb saytidan tarjima qilingan.</p>
    </>
  )
}

export default W3Path;