import React from "react";
import cellphone from "../../images/cellphone.jpg";
function Phone() {
    return (
        <div className="container ">
            <div className="page">
                <h2 className='header-tutorial'>Telefon</h2>
                <img src={cellphone} alt="Cellphone" width={600} />
                <p className='text-tutorial'>
                    Wikipedia
                </p>
                <blockquote cite="https://en.wikipedia.org/wiki/Telephone">
                    <i>Telefon- bu ikki yoki undan ortiq foydalanuvchilarga bir-biridan juda uzoqda bo'lganida to'g'ridan-to'g'ri eshitish uchun suhbat o'tkazish imkonini beruvchi telekommunikatsiya qurilmasi.
                    </i>
                </blockquote>
                <p className='text-tutorial' target={'_blank'} rel="noreferrer">Ko'proq o'qish <a className='text-tutorial text-tutorial__link' href="https://en.wikipedia.org/wiki/Telephone"> https://en.wikipedia.org/wiki/Telephone</a></p>
            </div>
        </div>
    )
}

export default Phone;