import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import ListText from '../../pages/ListsText';
import Notes from '../../pages/Notes';
import Table from '../../pages/Table';


function Enteties() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_entities.asp"} />
            <h2 className='header-tutorial'>HTML obyektlari</h2>
            <NavButton back='/html/html_syntax' next='/html/html_symbols' />
            <hr />
            <p>HTMLda ishlatiladigan belgilardan veb sahifamizda foydalanish uchun bu belgilar HTML belgilar obyekti bilan almashtirilishi kerak.</p>
            <hr />
            <TextContent header={"HTML obyektlari"}
                text1={"Ba'zi belgilar HTMLda kod yozish uchun ishlatiladi."}
                text2={"Agar matningizda (<) kichik yoki (>) katta belgilardan foydalansangiz, brauzer ularni teglar bilan adashtirishi mumkin."}
                text3={"Belgilar obyekti HTMLda ishlatiladigan belgilarni va ayrim belgilarni ko'rsatish uchun ishlatiladi. Belgilar obyekti quyidagi ko'rinishga ega."}
                code3={`&obyekt_nomi;

OR

&#obyekt_nomeri;`}
                text4={"Kichik(<) belgisini ko'rsatish uchun quyidagilarni yozish kerak: &lt; yoki &#60;"} />
            <Notes text={"Belgilar obyektidan foydalanishning afzalligi: obyekt nomini eslab qolish oson.Obyekt nomidan foydalanishning kamchiligi: Brauzerlar barcha obyektlar nomini qo'llab-quvvatlamasligi mumkin, ammo hamma obyektlar nomerini qo'llab-quvvatlaydi."}/>
            <hr />
            <TextContent header={"Ajratmaydigan bo'shliq"}
                text1={"HTMLda ko'p ishlatiladigan obyekt ajratmaydigan bo'shliq belgisidir: &nbsp;"}
                text2={"Ajratmaydigan bo'shliq bilan ajratilgan so'zlar yangi qatorga ajralib tushmaydi."}
                text3={"Ajratmaydigan bo'shliq bilan ajratilgan ikkita so'z bir-biriga birikib turadi(yangi qatorga ajralmaydi). Bu so'zlar ajralish ehtimoli bor bo'lgan hollarda qulaydir."}
                text4={"Misollar:"} />
            <ListText list={[{
                text: "§ 10",
            },
            {
                text: "10 km/soat",
            },
            {
                text: "22:00",
            },
            ]} />
            <TextContent text1={"Ajratmaydigan bo'shliq brauzerlarning HTML sahifalaridagi bo'shliqlarni qisqartirishining oldini olishda foydalaniladi."}
            text2={"Agar siz matningizda 10 ta bo'sh joy yozsangiz, brauzer ulardan 9 tasini olib tashlaydi. Matningizga haqiqiy bo'shliqlarni qo'shish uchun &nbsp; dan foydalaning."}
            redWords2={["&nbsp;"]}/>
            <Notes type={"Maslahat:"} text={"Ajralmaydigan defis ("} text1={"&#8209;"} text2={") yangi qatorga o'tmaydigan defis belgisini (-) belgilash uchun ishlatiladi."} path={"/charsets/ref_utf_punctuation"}/>
            <hr />
            <Table header={"Ba'zi foydali HTML belgilar obyektlari"}  th={["Natija", "Tavsif", "Obyekt nomi", "Obyekt nomeri", "Sinab ko'rish"]} values={[
        { id: 0, first: " ", second: "bo'shliq", third :"&nbsp;", four:"&#160;",tryPath:"try/285" },
        { id: 1, first: "<", second: "kichik belgisi", third :"&lt;", four:"&#60;",tryPath:"try/286" },
        { id: 2, first: ">", second: "katta belgisi", third :"&gt;", four:"	&#62;",tryPath:"try/287" },
        { id: 3, first: "&", second: "ampersand", third :"&amp;", four:"&#38;",tryPath:"try/288" },
        { id: 4, first: '"', second: "qo'shtirnoq belgisi", third :"&quot;", four:"&#34;",tryPath:"try/289" },
        { id: 5, first: "'", second: "apostrof belgisi", third :"&apos;", four:"&#39;",tryPath:"try/290" },
        { id: 6, first: "¢", second: "sent belgisi", third :"&cent;", four:"&#162;",tryPath:"try/291" },
        { id: 7, first: "£", second: "funt belgisi", third :"&pound;", four:"&#163;",tryPath:"try/292" },
        { id: 8, first: "¥", second: "yen belgisi", third :"&yen;", four:"&#165;",tryPath:"try/293" },
        { id: 9, first: "€", second: "yevro belgisi", third :"&euro;", four:"&#8364;",tryPath:"try/294" },
        { id: 10, first: "©", second: "mualliflik huquqi belgisi", third :"&copy;", four:"&#169;",tryPath:"try/295" },
        { id: 11, first: "®", second: "ro'yxatga olingan tovar belgisi", third :"&reg;", four:"&#174;",tryPath:"try/296" },]} />
        <Notes type={"Eslatma:"} text={"Obyekt nomlari katta-kichik harflarga sezgir"}/>
            <hr />
            <TextContent header={"Diakritik belgilarni birlashtirish"}  text1={`Diakritik belgi - satrlar ustiga yoki ostiga, kamdan-kam hollarda ichiga qo'yiladigan, harflarga asoslangan yozuvlarda ishlatiladigan belgilar.`}
                text2={"Qattiq( ̀) va o'tkir( ́) kabi ba'zi diakritik belgilar urg'u deyiladi."}
                text3={"Diakritik belgilar harfning tepasida ham, ostida ham, harf ichida ham, ikki harf orasida ham paydo bo'lishi mumkin."}
                text4={"Diakritik belgilar alfanumerik(harflar, raqamlar va belgilarning birlashtirilgani) belgilar bilan birgalikda sahifada(kodlashda) ishlatiladigan belgilar to'plamida mavjud bo'lmagan belgini yaratish uchun ishlatilishi mumkin. Mana bir nechta misollar:"} />
            <Table th={["Belgi", "Xarakter", "Tuzilishi", "Natija", "Sinab ko'rish"]} values={[
        { id: 0, first: "`", second: "a", third :"a&#768;", four:"à",tryPath:"try/297" },
        { id: 1, first: " ́", second: "a", third :"a&#769;", four:"á",tryPath:"try/298" },
        { id: 2, first: "^", second: "a", third :"a&#770;", four:"â",tryPath:"try/299" },
        { id: 3, first: "~", second: "a", third :"a&#771;", four:"ã",tryPath:"try/300" },
        { id: 4, first: "`", second: "O", third :"O&#768;", four:"Ò",tryPath:"try/301" },
        { id: 5, first: " ́", second: "O", third :"O&#769;", four:"Ó",tryPath:"try/302" },
        { id: 6, first: "^", second: "O", third :"O&#770;", four:"Ô",tryPath:"try/303" },
        { id: 7, first: "~", second: "O", third :"O&#771;", four:"Õ",tryPath:"try/304" },]} />
        <p>Ushbu qo'llanmaning keyingi bobida ko'proq HTML belgilarini ko'rasiz.</p>
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="QzG3UBdnEuc" />
            <NavButton back='/html/html_syntax' next='/html/html_symbols' />
        </div>)
}

export default Enteties;