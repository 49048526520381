import React from 'react';
import { Link } from 'react-router-dom';
import BrowserTable from '../../pages/BrowserTable';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';

function Tagpicture() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/tags/tag_picture.asp"} />
            <h2 className='header-tutorial'>HTML map tegi</h2>
            <NavButton back='/tags/tag_param' next='/tags/tag_pre' />
            <TextContent
                code1={`<picture>
                <source media="(min-width: 650px)" srcset="img_food.jpg">
                <source media="(min-width: 465px)" srcset="img_car.jpg">
                <img src="img_girl.jpg" style="width:auto;">
              </picture>`}
                path1={"try/117"}
                code1Text={"<picture> tegi ishlatilishi"}
            />
            <hr />
            <TextContent header={"Ta'rifi va qo'llanilishi"}
                text1={"<picture> tegi dasturchiga rasmlardan foydalanishda qulaylik yaratadi. <picture> elementidan san'at darajasidagi moslashuvchan dizaynda foydalaniladi. Brauzer oynasi kengligidan kelib chiqqan holda rasm kattalashib yoki kichiklashib ketmasdan chiroyliroq ko'rinishi uchun bir nechta rasm berish mumkin."}
                redWords1={['<picture>']}
                text2={" <picture> elementi ikkita tegdan iborat: bir yoki bir nechta <source> tegi va bitta <img> tegi."}
                redWords2={['<picture>']}
                text3={" Brauzer media so'rovi joriy ko'rish oynasi kengligiga mos keladigan birinchi <source> elementini qidiradi va keyin tegishli rasmni ko'rsatiladi (srcset atributida ko'rsatilgan). <img> elementi <picture> elementining ichida eng oxirida yoziladi, agar source teglaridan hech biri mos kelmasa, zaxira variant sifatida talab qilinadi."}
                redWords3={['<img>', '<source>']}
                text4={`Maslahat: <picture> element <video> va <audio> bilan "bir xil" ishlaydi. Turli xil manbalarni o'rnatiladi va mos keladigan birinchi sourcedan foydalaniladi.`}
                redWords4={['<picture>', '<video>', '<audio>']}
            />
            <hr />
            <BrowserTable text={"Jadvalda elementni to'liq qo'llab-quvvatlaydigan birinchi brauzer versiya raqami ko'rsatilgan."} element='<picture>' chrome={"38.0"} edge={"13.0"} firefox={"38.0"} safari={"9.1"} opera={"25.0"} />

            <TextContent header={"Global atributlar"} />
            <p className='text-tutorial'>&lt;picture&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
            <hr />
            <TextContent header={"Hodisa atributlari"} />
            <p className='text-tutorial'>&lt;picture&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>

            <TextContent header={"Tegishli sahifalar"} />
            <p className='text-tutorial'>HTML mavzulari: <Link className='table-link' to={"/html/html_images_picture"}>HTML picture elementi</Link></p>
            <p className='text-tutorial'>HTML CSS mavzulari: <Link className='table-link' to={"/css/css_rwd_images"}>Responsiv dizayn uchun rasm stillari</Link></p>
            <NavButton back='/tags/tag_param' next='/tags/tag_pre' />
        </article>)
}

export default Tagpicture;
