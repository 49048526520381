import React from 'react';
import { Link } from 'react-router-dom';
import BrowserTable from '../../pages/BrowserTable';
import Notes from '../../pages/Notes';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import TagTable from './AttrTable';

function Tagarea() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/tags/tag_area.asp"} />
            <h2 className='header-tutorial'>HTML area tegi</h2>
            <NavButton back='/tags/tag_applet' next='/tags/tag_article' />
            <TextContent
                code1={`<img src="workplace.jpg" alt="Workplace" usemap="#workmap">
        <map name="workmap">
          <area shape="rect" coords="34,44,270,350" alt="Computer" href="computer.htm">
          <area shape="rect" coords="290,172,333,250" alt="Phone" href="phone.htm">
          <area shape="circle" coords="337,300,44" alt="Coffee" href="coffee.htm">
        </map>`}
                path1={"try/105"}
            />
            <hr />
            <TextContent header={"Ta'rifi va qo'llanilishi"}
                text1={"<area> tegi rasm xaritasida bosiladigan maydonni yaratish uchun ishlatiladi."}
                redWords1={['<area>']}
                text2={" <area> elementi har doim <map> teg ichida joylashadi."}
                redWords2={['<area>', '<map>']}
            />
            <Notes type={"Eslatma:"} text={"<img> elementi usemap atributi yordamida <map> elementining  name atributi  bilan bog'lanadi, rasm va xaritani bir-biriga bog'laydi. "} />
            <hr />
            <BrowserTable element='<area>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />
            <TagTable arr={['alt', 'coords', 'download', 'href', 'hreflang', 'media', 'referrerpolicy', 'rel', 'shape', 'target', 'type']} />
            <TextContent header={"Global atributlar"} />
            <p className='text-tutorial'>&lt;area&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
            <hr />
            <TextContent header={"Hodisa atributlari"} />
            <p className='text-tutorial'>&lt;area&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
            <hr />
            <TextContent header={"Misollar"}
                code1={`<img src="planets.gif" width="145" height="126" alt="Planets" usemap="#planetmap">

                <map name="planetmap">
                  <area shape="rect" coords="0,0,82,126" alt="Sun" href="sun.htm">
                  <area shape="circle" coords="90,58,3" alt="Mercury" href="mercur.htm">
                  <area shape="circle" coords="124,58,8" alt="Venus" href="venus.htm">
                </map>`}
                path1={"try/119"}
                code1Text={"Rasm xaritasidagi bosiladigan maydoni:"}
            />
            <TextContent header={"Tegishli sahifalar"} />
            <p className='text-tutorial'>HTML DOM ma'lumotnomasi: <Link className='table-link' to={"/jsref/dom_obj_area."}>Area Obyekti</Link></p>
            <hr />
            <TextContent header={"Default CSS sozlamalari"}
                text1={"Ko'p brauzerlarda <area> elementida quyidagi default stillar berilgan bo'ladi:"}
                redWords1={['<area>']}
                css1={`area {
 display: none;
}`} />
            <NavButton back='/tags/tag_applet' next='/tags/tag_article' />
        </article>)
}

export default Tagarea;
