import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function CardColor({ datas }) {
  return (
    <div className='cards__wrapper'>
      <ul className='cards__items'>
        {
          datas.map((value) => {
            return (
              <CardItem text={value.hex} key={value.id} label={value.title} path={value.path} fon={value.hex} />
            )
          })
        }
      </ul>
    </div>
  )
}

export default CardColor;