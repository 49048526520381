import React from 'react';

function RedText(props) {
    if (!props.redWords) return (<p className='text-tutorial'>{props.text}</p>)
    let text = props.text;
    const reg = [';', '.', '-', ',', ''];
    let parts = text.split(' ');
    for (const element of props.redWords) {
        for (const re of reg) {
            const element1 = element + re;
            var idx = parts.indexOf(element1);
            if (idx !== -1) parts[idx] = <span style={{ color: "crimson", }} key={idx}>{element1}</span>;
            while (idx !== -1) {
                idx = parts.indexOf(element1, idx + 1);
                parts[idx] = <span style={{ color: "crimson", }} key={idx}>{element1}</span>;
            }
        }
    }

    let i = 0;
    let newParts = [];
    let str = '';
    for (const element of parts) {
        if (typeof element === 'string') {
            str = str + element + ' ';
        }
        else {
            newParts[i] = str;
            str = ' ';
            newParts[i + 1] = element;
            i += 2;
        }

    }
    newParts[i] = str;
    return <p className='text-tutorial'>{newParts}</p>;
}

export default RedText;