import React from 'react';
import { Link } from 'react-router-dom';
import NavButton from '../NavButton';
import '../Info.css';
import CodeContent from '../../../tryit/CodeContent';
import CodeMirrorContent from '../../../tryit/CodeContent/CodeMirrorContent';
import Collapsible from '../../pages/Collapsible';
import YoutubeEmbed from '../YoutubeEmbed';
import W3Path from '../../W3Path';

function HtmlStyles() {
  return (
    <article className='info-wrapper'>
      <W3Path  path={"https://www.w3schools.com/html/html_styles.asp"}/>
      <h2 className='header-tutorial'>HTML Stillari</h2>
      <NavButton back='/html/html_paragraphs' next='/html/html_formatting' />
      <hr />
      <p className='text-tutorial text-tutorial-margin'>HTML <span className='text-tutorial__red'>style</span> atributi elementga rang, shrift, oʻlcham va boshqa shu kabi stillar berish uchun ishlatiladi.</p>
      <hr/>
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper'>
        <p style={{color:'red',}}>Men qizil matnman</p>
<p style={{color:'blue',}}>Men ko'k matnman</p>
<p style={{fontSize:'36px',}}>Men Katta matnman</p>
        </div>
        <Link className='button btn btn--large green' to={`try/23`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr/>
      <h3 className='header-content'>HTML style atributi</h3>
    <p className='text-tutorial'>HTML elementining stillarini o'zgartirish uchun<span className='text-tutorial__red'> style </span> atributidan foydalanish mumkin.</p>
    <p className='text-tutorial'>HTML <span className='text-tutorial__red'> style </span> atributi quyidagi sintaksisga ega:</p>
    <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContent value={`<tegnomi style="xususiyati:qiymati;">`} />
        </div>
      </div>
      <p className='text-tutorial'>Bu yerda <strong>xususiyati-</strong> Css xususiyati, <strong>qiymati-</strong> Css qiymatidir. </p>
      <hr />
      <h3 className='header-content'>Fon rangi</h3>
      <p className='text-tutorial'>CSS <span className='text-tutorial__red'> background-color </span>  xususiyati HTML elementi uchun fon rangini belgilaydi.</p>
      <CodeContent props={`<body style="background-color:powderblue;">

<h1>Bu sarlavha</h1>
<p>Bu matn.</p>

</body>`} pathname={`try/24`} text={`Sahifaning fon rangini koʻk(powderblue) rangga oʻzgartirish:`}/>

<CodeContent props={`<h1 style="background-color:powderblue;">Bu sarlavha</h1>
<p style="background-color:tomato;">Bu matn.</p>`} pathname={`try/25`} text={`Ikki xil element fon rangini o'zgartirish:`}/>
<hr/>
<h3 className='header-content'>Matn rangi</h3>
      <p className='text-tutorial'>CSS <span className='text-tutorial__red'> color </span>  xususiyati HTML elementi uchun matn rangini belgilaydi.</p>
      <CodeContent props={`<h1 style="color:blue;">Bu sarlavha</h1>
<p style="color:red;">Bu matn.</p>
`} pathname={`try/26`} />
<hr/>
<h3 className='header-content'>Shriftlar</h3>
      <p className='text-tutorial'>CSS <span className='text-tutorial__red'> font-family </span>  xususiyati HTML elementi uchun ishlatiladigan shriftni belgilaydi.</p>
      <CodeContent props={`<h1 style="font-family:verdana;">Bu sarlavha</h1>
<p style="font-family:courier;">Bu matn.</p>`} pathname={`try/27`} />
<hr/>
<h3 className='header-content'>Matn o'lchami</h3>
      <p className='text-tutorial'>CSS <span className='text-tutorial__red'> font-size </span>  xususiyati HTML elementi uchun matn o'lchamini belgilaydi.</p>
      <CodeContent props={`<h1 style="font-size:300%;">Bu sarlavha</h1>
<p style="font-size:160%;">Bu matn.</p>
`} pathname={`try/28`} />
<hr/>
<h3 className='header-content'>Matnni tekislash</h3>
      <p className='text-tutorial'>CSS <span className='text-tutorial__red'> text-align </span>  xususiyati HTML elementi gorizontal joylashuvini belgilaydi.</p>
      <CodeContent props={`<h1 style="text-align:center;">O'rtada joylashgan sarlavha</h1>
<p style="text-align:center;">O'rtada yozilgan matn.</p>`} pathname={`try/29`} />
<hr/>
<h3 className='header-content'>Bo'lim xulosasi</h3>
      <ul className='lists'>
        <li className='item'>
        <p className='text-tutorial'>Html elementlariga still berish uchun <span className='text-tutorial__red'> style </span> atributidan foydalaniladi.</p>
        </li>
        <li className='item'>
        <p className='text-tutorial'>Html elementlari fon rangini o'zgartirish uchun <span className='text-tutorial__red'> background-color </span> atributidan foydalaniladi.</p>
        </li>
        <li className='item'>
      <p className='text-tutorial'>Matn rangini o'zgartirish uchun <span className='text-tutorial__red'> color </span> atributidan foydalaniladi.</p>
        </li>
        <li className='item'>
        <p className='text-tutorial'>Matn o'lchamini o'zgartirish uchun <span className='text-tutorial__red'> font-size </span> atributidan foydalaniladi.</p>
        </li>
        <li className='item'>
        <p className='text-tutorial'>Html elementlari shriftini o'zgartirish uchun <span className='text-tutorial__red'> font-family </span> atributidan foydalaniladi.</p>
        </li>
        <li className='item'>
        <p className='text-tutorial'>Matnlarni tekislash uchun<span className='text-tutorial__red'> text-align </span> atributidan foydalaniladi.</p>
        </li>
      </ul>
      <hr/>
      <h3 className='header-content'>Dars videosi:</h3>
      <YoutubeEmbed embedId="aRtjjl_HCt8" />
      <a style={{color: 'var(--green)'}} href="https://www.figma.com/file/EwUR3cWNzPO3baXvAPQOYe/Qubit.2" target={'_blank'} rel="noreferrer"> Web sahifa maketi</a>
      <hr/>
      <Collapsible>
      <p className='text-tutorial'>Quyidagi maket web sahifasi yaratilsin:</p>
      <a href="https://www.figma.com/file/C06R0fNfggrBn7pssKaywB/qubit3?node-id=0%3A1" target={'_blank'} rel="noreferrer">https://www.figma.com/file/C06R0fNfggrBn7pssKaywB/qubit3?node-id=0%3A1</a>
      </Collapsible>

      <NavButton back='/html/html_paragraphs' next='/html/html_formatting' />
      </article>)
      }

    export default HtmlStyles;