import '../../App.css';
import React, { useEffect } from 'react';
import HeroSection from './HeroSection';
import Cards from './Cards';
import Footer from './Footer'

function Home() {
  useEffect(()=>{
  const parallax = document.getElementById("parallax");
  window.addEventListener("scroll", function(){
  let offset =window.pageYOffset;
  parallax.style.backgroundPositionY = offset*0.7 +"px";
})})
  return (
    <div style={{backgroundColor: "#e7e7e7"}}>
      <HeroSection />
      <Cards />
      <Footer />
    </div>
  )
}

export default Home;