import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../pages/ScrollToTop";
import SideBar from "./SideBar";

const htmlTutorial = [{
  title: "HTML HOME",
  id: 0,
  subTutorial: [
    { id: 1, title: "HTML bilan tanishish", path: "html_intro" },
    { id: 2, title: "HTML muharrirlari", path: "html_editor" },
    { id: 3, title: "HTML asosiy misollar", path: "html_basic" },
    { id: 4, title: "HTML elementlari", path: "html_elements" },
    { id: 5, title: "HTML atributlari", path: "html_atributes" },
    { id: 6, title: "HTML sarlavhalari", path: "html_headings" },
    { id: 7, title: "HTML paragraflari", path: "html_paragraphs" },
    { id: 8, title: "HTML stillari", path: "html_styles" },
    { id: 9, title: "HTML formatlash", path: "html_formatting" },
    { id: 10, title: "HTML iqtiboslari", path: "html_quotation_elements" },
    { id: 11, title: "HTML izohlari", path: "html_comments" },
    {
      id: 12, title: "HTML ranglari", subTut: "true",
      subTutorial: [
        { id: 13, title: "Ranglar", path: "html_colors" },
        { id: 14, title: "RGB", path: "html_colors_rgb" },
        { id: 15, title: "HEX", path: "html_colors_hex" },
        { id: 16, title: "HSL", path: "html_colors_hsl" },
      ]
    },
    { id: 17, title: "HTML CSS", path: "html_css" },
    {
      id: 18, title: "HTML havolalar", subTut: "true",
      subTutorial: [
        { id: 19, title: "Havolalar", path: "html_links" },
        { id: 20, title: "Havola ranglari", path: "html_links_colors" },
        { id: 21, title: "Bookmarklar", path: "html_links_bookmarks" },
      ]
    },
    {
      id: 22, title: "HTML tasvirlar", subTut: "true",
      subTutorial: [
        { id: 23, title: "Rasmlar", path: "html_images" },
        { id: 24, title: "Rasmlarda map", path: "html_images_imagemap" },
        { id: 25, title: "Fon rasmlari", path: "html_images_background" },
        { id: 26, title: "Picture elementi", path: "html_images_picture" },
      ]
    },

    { id: 27, title: "HTML Favicon", path: "html_favicon" },
    {
      id: 28, title: "HTML jadvallari", subTut: "true",
      subTutorial: [
        { id: 29, title: "HTML Jadvallari", path: "html_tables" },
        { id: 30, title: "Jadvallar borderi", path: "html_table_borders" },
        { id: 31, title: "Jadval o'lchami", path: "html_table_sizes" },
        { id: 32, title: "Jadval sarlavhasi", path: "html_table_headers" },
        { id: 33, title: "HTML Table Padding&Spacing", path: "html_table_padding_spacing" },
        { id: 34, title: "Colspan & Rowspan", path: "html_table_colspan_rowspan" },
        { id: 35, title: "Jadval stillari", path: "html_table_styling" },
        { id: 36, title: "Jadval Colgroup", path: "html_table_colgroup" },
      ]
    },
    {
      id: 37, title: "HTML ro'yxatlari", subTut: "true",
      subTutorial: [
        { id: 38, title: "Ro'yxatlar", path: "html_lists" },
        { id: 39, title: "Tartiblanmagan ro'yxat", path: "html_lists_unordered" },
        { id: 40, title: "Tartiblangan ro'yxat", path: "html_lists_ordered" },
        { id: 42, title: "Boshqa turdagi ro'yxatlar", path: "html_lists_other" },
      ]
    },
    { id: 43, title: "Block va Inline elementlar", path: "html_blocks" },
    { id: 44, title: "HTML sinflari", path: "html_classes" },
    { id: 45, title: "HTML identifikatori", path: "html_id" },
    { id: 46, title: "HTML Iframe", path: "html_iframe" },
    { id: 47, title: "HTML JavaScript", path: "html_js" },
    { id: 48, title: "HTML Fayl Yo'llari", path: "html_file_path" },
    { id: 49, title: "HTML Head", path: "html_head" },
    { id: 50, title: "HTML maketi", path: "html_layout" },
    { id: 51, title: "Responsiv veb-sahifa", path: "html_responsive" },
    { id: 52, title: "HTML Kompyuter kodi", path: "html_computercode" },
    { id: 53, title: "HTML semantik elementlari", path: "html_semantic" },
    { id: 54, title: "HTMLda to'g'ri va toza kod yozish qo'llanmasi", path: "html_syntax" },
    { id: 55, title: "HTML Obyektlari", path: "html_obects" },
    { id: 56, title: "HTML belgilari", path: "html_symbols" },
    { id: 57, title: "HTML Emojis", path: "html_emoji" },
    { id: 58, title: "HTML belgilar to'plami", path: "html_charset" },
    { id: 59, title: "HTML URL kodlash", path: "html_urlen_code" },
    { id: 60, title: "HTML va XHTML", path: "html_xtml" },]
},
{
  title: "HTML formalari",
  id: 61,
  subTutorial: [
    { id: 62, title: "HTML Formalari", path: "html_forms" },
    { id: 63, title: "HTML Forma atributlari", path: "html_form_atribut" },
    { id: 64, title: "Forma elementlari", path: "html_form_elements" },
    { id: 65, title: "HTML Input turlari", path: "html_input" },
    { id: 66, title: "HTML Input Atributlari", path: "html_input_atributes" },
    { id: 67, title: "HTML Input formasi atributlari", path: "html_input_form" }
  ]
},
{
  title: "HTML grafikasi",
  id: 51,
  subTutorial: [
    { id: 52, title: "HTML Canvas", path: "html_canvas" },
    { id: 53, title: "HTML SVG", path: "html_svg" }
  ]
},
{
  title: "HTML media",
  id: 54,
  subTutorial: [
    { id: 55, title: "HTML media", path: "html_media" },
    { id: 56, title: "HTML video", path: "html5_video" },
    { id: 57, title: "HTML audio", path: "html5_audio" },
    { id: 58, title: "HTML plaginlari", path: "html_object" },
    { id: 59, title: "Youtube", path: "html_youtube" },
  ]
},
{
  title: "HTML APIlari",
  id: 60,
  subTutorial: [
    { id: 61, title: "HTML Geolokatsiya", path: "html5_geolocation" },
    { id: 62, title: "HTML Drag and Drop API", path: "html5_draganddrop" },
    { id: 63, title: "HTML Web Storage API", path: "html5_webstorage" },
    { id: 64, title: "HTML Web Workers API", path: "html5_webworkers" },
    { id: 65, title: "HTML SSE API", path: "html5_serversentevents" },
  ]
},
];

function Html() {
  if (window.location.pathname.indexOf('.htm') === -1) {
    return (
      <div className="tutorial container">
        <SideBar datas={htmlTutorial} />
        <ScrollToTop />
        <Outlet />
      </div>
    )
  }
  return (<>
    <Outlet />
  </>)
}

export default Html;