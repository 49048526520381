import React from 'react';
import RedText from './RedText';

function ListText(props) {

    return (
        <ul className='lists'>
            {props.list.map((value, index) => {
                return (
                    <li key={index} className='item'>
                        {value.text && value.redWords ? <RedText text={value.text} redWords={value.redWords} /> : <p className='text-tutorial'>{value.text}</p>}
                    </li>)
            })}
        </ul>
    )
}

export default ListText;