import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import Notes from '../../../pages/Notes';
import Table from '../../../pages/Table';
import NoteWhite from '../../../pages/NoteWhite';
import BrowserTable from '../../../pages/BrowserTable';


function HtmlAudio() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html5_audio.asp"} />
            <h2 className='header-tutorial'>HTML audio</h2>
            <NavButton back='/html/html5_video' next='/html/html_object' />
            <hr />
            <TextContent
                text1={"HTML <audio> elementi veb-sahifaga audio faylni joylashtirish uchun ishlatiladi."}
                redWords1={["<audio>"]} />
            <hr />
            <TextContent header={"HTML <audio> elementi"}
                text1={"HTMLda audio faylni joylashtirish uchun <audio> elementidan foydalaning:"}
                redWords1={["<audio>"]}
                code1={`<audio controls>
  <source src="wood.ogg" type="audio/ogg">
  <source src="wood.mp3" type="audio/mpeg">
  Brauzeringiz audio elementini qo'llab-quvvatlamaydi.
</audio>`}
                path1={`try/431`} />
            <hr />
            <TextContent header={"Qanday ishlaydi"}
                text1={"controls atributi ijro, to'xtatib turish va ovoz balandligi kabi audio boshqaruvlarini qo'shib beradi."}
                redWords1={["controls"]}
                text2={"<source> elementi brauzer qo'llab-quvvatlashi mumkin bo'lgan muqobil audio fayllarni belgilash imkonini beradi. Brauzer birinchi qo'llab-quvvatlaydigan formatidan foydalanadi."}
                redWords2={["<source>"]}
                text3={"<audio> va </audio> teglari orasidagi matn faqat <audio> elementini qo'llab-quvvatlamaydigan brauzerlarda ko'rsatiladi."}
                redWords3={["<audio>","</audio>"]} />
            <hr />
            <TextContent header={"HTML <audio>da avtomatik ijro"}
                text1={"Audio faylning avtomatik ravishda ijrosi uchun autoplay atributidan foydalaniladi:"}
                redWords1={["autoplay"]}
                code1={`<audio controls autoplay>
  <source src="wood.ogg" type="audio/ogg">
  <source src="wood.mp3" type="audio/mpeg">
Brauzeringiz audio elementini qo'llab-quvvatlamaydi.
</audio>
`} path1={"try/432"} />
            <Notes type={"Eslatma:"} text={"Chrome brauzerlari ko'p hollarda avtomatik ijroga ruxsat bermaydi. Biroq, ovozsiz avtomatik ijroga har doim ruxsat beriladi."} />
            <br />
            <TextContent
                text1={"Audio faylining avtomatik ijrosi boshlanishi uchun muted atributi autoplaydan keyin qo'shiladi (lekin ovozsiz ijro boshlanadi):  "}
                redWords1={["muted", "autoplay"]}
                code1={`<audio controls autoplay muted>
  <source src="horse.ogg" type="audio/ogg">
  <source src="horse.mp3" type="audio/mpeg">
Brauzeringiz audio elementini qo'llab-quvvatlamaydi.
</audio>`} path1={"try/433"} />
            <hr />
            <BrowserTable text={"Quyidagi jadvalda <audio> elementini to'liq qo'llab-quvvatlaydigan birinchi brauzer versiyasi ko'rsatilgan."} element={"<audio>"} chrome={"4.0"} edge={"9.0"} firefox={"3.5"} safari={"4.0"} opera={"10.5"} />
            <hr />
            <Table th={["Brauzer", "MP3", "	WAV", "	OGG"]} header={"HTML audio formatlari"}
                text={"HTMLda uchta qo'llab-quvvatlanadigan audio formatlari mavjud: MP3, WAV va OGG. Turli formatlarning brauzerlarda qo'llab-quvvatlasnishi:  "} values={[
                    { id: 0, first: "Edge", second: "Mavjud", third: "Mavjud*", four: "Mavjud*" },
                    { id: 1, first: "Chrome", second: "Mavjud", third: "Mavjud", four: "Mavjud" },
                    { id: 2, first: "Firefox", second: "Mavjud", third: "Mavjud", four: "Mavjud" },
                    { id: 3, first: "Safari", second: "Mavjud", third: "Mavjud", four: "Mavjud emas" },
                    { id: 4, first: "Opera", second: "Mavjud", third: "Mavjud", four: "Mavjud" },
                ]} />
            <hr />
            <Table th={["Fayl formati", "Media turi",]} header={"HTML Audio - Media turlari"}
                values={[
                    { id: 0, first: "MP3", second: "audio/mpeg", },
                    { id: 1, first: "OGG", second: "audio/ogg", },
                    { id: 2, first: "WAV", second: "audio/wav", },
                ]} />
            <hr />
            <TextContent header={"HTML Audio - metodlar, xususiyatlar va hodisalar"}
                text1={"HTML DOM <audio> elementi uchun metodlar, xususiyatlar va hodisalarni belgilaydi."}
                redWords1={["<audio>"]}
                text2={"Bu esa audiolarni yuklash, ijro etish va pauza qilish, shuningdek, davomiylik va ovoz balandligini belgilash imkonini beradi."}
                text3={"Bundan tashqari, audio ijrosi boshlanganda, to'xtatilganda va hokazolarda sizga xabar beradigan DOM hodisalari mavjud."} />
            <NoteWhite text={"To'liq DOM haqida ma'lumot olish uchun bizning"} text1={"HTML Audio/Video DOM ma'lumotnomamizga"} text2={"o'ting."} path={"/tags/ref_av_dom"} />
            <hr />
            <Table th={["Teg", "Tavsif"]} values={[
                { id: 0, first: "<audio>", second: "Video yoki filmni belgilaydi", path: "/tags/tag_video" },
                { id: 1, first: "<source>", second: "<video> va <audio> kabi media elementlari uchun bir nechta media resurslarini belgilaydi", path: "/tags/tag_source" },
                { id: 2, first: "<track>", second: "Media pleerlarda matn treklarini belgilaydi", path: "/tags/tag_track" }]} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="xWo28IEpIpI" />
            <NavButton back='/html/html5_video' next='/html/html_object' />
        </div>)
}

export default HtmlAudio;