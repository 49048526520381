import React from 'react';
import Table from '../../../pages/Table';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';

function Lists() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_lists.asp"} />
            <h2 className='header-tutorial'>HTML ro'yxatlari</h2>
            <NavButton back='/html/html_table_colgroup' next='/html/html_lists_unordered' />
            <hr />
            <TextContent text1={"HTMLda tartiblanmagan ro'yxat <ul> tegi yordamida kiritiladi."} />
            <hr />
            <TextContent header={"Tartiblanmagan HTML ro'yxati"}
                text1={"Tartiblanmagan ro'yxat <ul> tegi bilan boshlanadi. Har bir ro'yxat elementi <li> tegi bilan boshlanadi."}
                redWords1={["<ul>", "<li>"]}
                text2={"Ro'yxat elementlari default holatda kichik qora doirali marker bilan ajratib ko'rsatiladi:"}
                code2={`<ul>
  <li>Coffee</li>
  <li>Tea</li>
  <li>Milk</li>
</ul>`} path2={"try/204"}
            />
            <hr />
            <TextContent header={"Tartiblangan HTML ro'yxati"}
                text1={"Tartiblangan ro'yxat <ol> tegi bilan boshlanadi. Har bir ro'yxat elementi <li> tegi bilan boshlanadi."}
                redWords1={["<ol>", "<li>"]}
                text2={"Default holatda ro'yxat elementlari raqamlangan bo'ladi:"}
                code2={`<ul>
  <li>Kofe</li>
  <li>Choy</li>
  <li>Sut</li>
</ul>`} path2={"try/205"}
            />
            <hr />
            <TextContent header={"Html tavsif ro'yxati"}
                text1={"HTML shuningdek tavsif ro'yxatini ham qo'llab-quvvatlaydi."}
                text2={"Tavsiflar ro'yxati atamalar va har bir atama tavsifidan iborat."}
                text3={"Tavsif ro'yxatlari <dl> tegi yordamida kiritiladi, atamalar <dt> tegi va atamalar tavsiflari <dd> tegi yordamida kiritiladi:"}
                redWords3={["<dl>", "<dt>", "<dd>"]}
                code3={`<dl>
  <dt>Kofe</dt>
  <dd>- qora issiq ichimlik</dd>
  <dt>Sut</dt>
  <dd>- oq rangli ichimlik</dd>
</dl>`} path3={"try/206"}
            />
            <hr />
            <Table header={"Html teglari"} th={["Teg", "tavsifi"]} values={[
                { first: "<ul>", second: "Tartiblanmagan ro'yxatni belgilaydi", path: '/tags/tag_ul' },
                { first: "<ol>", second: "Tartiblangan ro'yxat belgilaydi", path: '/tags/tag_ol' },
                { first: "<li>", second: "Ro'yxat elementini bildiradi", path: '/tags/tag_li' },
                { first: "<dl>", second: "Tavsif ro'yxatini belgilaydi", path: '/tags/tag_dl' },
                { first: "<dt>", second: "Tavsif ro'yxatidagi atamani belgilaydi", path: '/tags/tag_dt' },
                { first: "<dd>", second: "Tavsif ro'yxatidagi atamani tavsiflaydi", path: '/tags/tag_dd' },]} />
            <TextContent header={"Dars videosi:"} />
            <YoutubeEmbed embedId="cpF1QE_XoR0" />
            <NavButton back='/html/html_table_colgroup' next='/html/html_lists_unordered' />
        </article>)
}
export default Lists;
