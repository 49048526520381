import React from 'react';
import { Link } from 'react-router-dom';
import NavButton from '../../NavButton';
import '../../Info.css';
import CodeContent from '../../../../tryit/CodeContent';
import YoutubeEmbed from '../../YoutubeEmbed';
import Table from '../../../pages/Table';
import Notes from '../../../pages/Notes';
import W3Path from '../../../W3Path';

function LinkColor() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_links_colors.asp"}/>
      <h2 className='header-tutorial'>HTML havola ranglari</h2>
      <NavButton back='/html/html_links' next='/html/html_links_bookmarks' />
      <hr />
      <p className='text-tutorial text-tutorial-margin'>HTMLda tashrif buyurilgan havola, ko'rilmagan havola yoki havola faolligi turli xil rangda ko'rsatiladi.</p>
      <hr />
      <h3 className='header-content'>HTML havola ranglari</h3>
      <p className='text-tutorial'>Odatda, havola quyidagicha ko'rinadi (barcha brauzerlarda): </p>
      <ul className='lists'>
        <li className='item'><p>Ko'rilmagan havola tagiga chizilgan va ko'k rangda bo'ladi.</p></li>
        <li className='item'><p>Tashrif buyurilgan havolaning tagiga chizilgan va binafsha rang bo'ladi</p></li>
        <li className='item'><p>Faol havolaning tagiga chizilgan va qizil rangda bo'ladi.</p></li>
      </ul>
      <p className='text-tutorial'>CSS-dan foydalanib, havola holati ranglarini o'zgartirishingiz mumkin: </p>
      <CodeContent props={`<style>
a:link {
  color: green;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: pink;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}
</style>`} pathname={`try/87`} text="Bu yerda ko'rilmagan havola yashil rangda bo'ladi, tagiga chizilmaydi. Tashrif buyurilgan havola pushti rangda bo'ladi, tagiga chizilmaydi. Faol havola sariq rangda va tagiga chizilgan bo'ladi. Bundan tashqari, sichqonchani havola ustiga olib borilganda (a: hover) u qizil rangga aylanadi va tagiga chiziladi:" />
      <hr />
      <h3 className='header-content'>Button havola</h3>
      <p className='text-tutorial'>Havolani CSS-dan foydalanib, button ko'rinishiga keltirish mumkin: </p>
      <div style={{ padding: "5px", marginBottom: "25px" }}>
        <a className='button__link' href='/html/html_links_colors'>Bu havola</a>
      </div>
      <CodeContent props={`<style>
a:link, a:visited {
  background-color: #f44336;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

a:hover, a:active {
  background-color: red;
}
</style>`} pathname={`try/88`} text="Bu yerda ko'rilmagan havola yashil rangda bo'ladi, tagiga chizilmaydi. Tashrif buyurilgan havola pushti rangda bo'ladi, tagiga chizilmaydi. Faol havola sariq rangda va tagiga chizilgan bo'ladi. Bundan tashqari, sichqonchani havola ustiga surganda (a: hover) u qizil rangga aylanadi va tagiga chiziladi:" />
<Notes text={"CSS haqida ko'proq ma'lumot olish uchun"} path={"/css"} text1={"CSS"} text2={"bo'limiga o'ting"}/>
      <div className='note-content'>
        <p className='text-tutorial'>CSS haqida ko'proq ma'lumot olish uchun
          <Link className='text-tutorial text-tutorial__link' to={'/css'}>CSS </Link>bo'limiga o'ting</p></div>
      <hr />
      <Table th={["Teg", "Tavsif"]} values={[
        { id: 0, first: "a", second: "Giperhavolalar uchun foydalaniladi.", path: "/tags/tag_a" },
      ]} />
      <div className='note-content'>
        <p className='text-tutorial'>  <Link className='text-tutorial text-tutorial__link' to={'/tags'}>HTML teglar maʼlumotnomasidan</Link> barcha mavjud HTML teglarining toʻliq roʻyxatini koʻrishingiz mumkin.
        </p></div>
      <hr />
      <h3 className='header-content'>Dars videosi</h3>
      <YoutubeEmbed embedId="55EPHgeUy0M" />
      <NavButton back='/html/html_links' next='/html/html_links_bookmarks' />
    </article>
  )
}

export default LinkColor;