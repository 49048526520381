import React from 'react';
import Notes from '../../pages/Notes';
import NoteWhite from '../../pages/NoteWhite';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import FavIcon from '../../../images/favIcon.jpg';
import Table from '../../pages/Table';
import YoutubeEmbed from '../YoutubeEmbed';


function Favicon() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_favicon.asp"} />
            <h2 className='header-tutorial'>HTML Favikon</h2>
            <NavButton back='/html/html_images_picture' next='/html/html_tables' />
            <hr />
            <TextContent
                text1={"Favikon - bu brauzer yorlig'ida sahifa sarlavhasi yonida ko'rsatiladigan kichik rasm."} />
            <hr />
            <TextContent header={"Favikondan foydalanish"} />
            <NoteWhite text={"Favikon sifatida o'zingizga yoqqan har qanday rasmdan foydalanishingiz mumkin. Shuningdek, "} text1={"https://www.favicon.cc "} text2={"kabi saytlarda o'z favikoningizni yaratishingiz mumkin ."} path={"https://www.favicon.cc/"} />
            <Notes text={"Favikon kichik rasm bo'lgani uchun u yuqori kontrastli oddiy tasvir bo'lishi kerak."} type={"Maslahat:"} />
            <TextContent
                text1={"Favikon tasviri brauzer yorlig'ida sahifa sarlavhasining chap tomonida ko'rsatiladi, masalan:"} />
            <img src={FavIcon} alt='Facvicon' width={521} height={153} />
            <TextContent
                text1={"Veb-saytingizga favikon qo'shish uchun favikon tasviringizni veb-serveringizning asosiy katalogiga saqlang yoki ildiz katalogida 'images' deb nomlangan papka yarating va favikon tasviringizni ushbu papkaga saqlang. Favikon tasviri  'favicon.ico' umumiy nom bilan saqlanadi."}
                text2={"'index.html' faylingizga <title> elementidan keyin <link> elementini qo'shing:"}
                redWords2={['<link>', '<title>']}
                code2={`<!DOCTYPE html>
<html>
<head>
<title>My Page Title</title>
<link rel="icon" type="image/x-icon" href="/images/favicon.ico">
</head>
<body>

<h1>Bu sarlavha</h1>
<p>Bu matn.</p>

 </body>
</html>`}
                text3={"Endi 'index.html' faylini saqlang va uni brauzeringizga qayta yuklang. Brauzeringiz yorlig'i endi sahifa sarlavhasining chap tomonida favikon rasmini ko'rsatadi."} />
            <Table header={"Favicon file formati qo'llab-quvvatlanishi"}
                text={"Quyidagi jadvalda favikon tasviri uchun fayl formatining qo'llab-quvvatlanishi ko'rsatilgan:"}
                th={["Browser", "ICO", "PNG", "GIF", "JPEG", "SVG"]}
                values={[
                    { first: "Edge", second: "Yes", third: "Yes", four: "Yes", five: "Yes", six: "Yes" },
                    { first: "Chrome", second: "Yes", third: "Yes", four: "Yes", five: "Yes", six: "Yes" },
                    { first: "Firefox", second: "Yes", third: "Yes", four: "Yes", five: "Yes", six: "Yes" },
                    { first: "Opera", second: "Yes", third: "Yes", four: "Yes", five: "Yes", six: "Yes" },
                    { first: "Safari", second: "Yes", third: "Yes", four: "Yes", five: "Yes", six: "Yes" },]}
            />
            <hr />
            <TextContent header={"Bo'lim xulosasi"}
                text1={"Favikonni kiritish uchun HTML <link> elementidan foydalaning"} redWords1={["<link>"]} />
            <hr />
            <Table header={"Html link tegi"} th={["Teg", "Tavsifi"]} values={[
                { id: 0, first: "<link>", second: "HTML dokument va tashqi resurs o'rtasidagi munosabatlarni belgilaydi", path: '/tags/tag_link' },
            ]} />
            <Notes text={"Barcha mavjud HTML teglarining toʻliq roʻyxatini koʻrish uchun bizning"} path="/tags" text1={" HTML teg maʼlumotnomamizga"} text2={"tashrif buyuring."} />

            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="93Uj2ee_iIM" />
            <NavButton back='/html/html_images_picture' next='/html/html_tables' />
        </article>)
}

export default Favicon;