import React from 'react';
import { Link } from 'react-router-dom';
import NavButton from '../NavButton';
import '../Info.css';
import CodeContent from '../../../tryit/CodeContent';
import CodeMirrorContentCss from '../../../tryit/CodeContent/CodeMirrorContentCss';
import W3Path from '../../W3Path';
import BrowserTable from '../../pages/BrowserTable';


function TagStyle() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_style.asp"} />
      <h2 className='header-tutorial'>HTML &lt;style&gt; tegi</h2>
      <NavButton back='/tags/tag_strong' next='/tags/tag_sub' />
      <hr />
      <CodeContent props={` <!DOCTYPE html>
<html>
<body>

<p>Men oddiy matnman</p>
<p style="color:red;">Men qizil matnman</p>
<p style="color:blue;">Men ko'k matnman</p>
<p style="font-size:50px;">Men Katta matnman</p>

</body>
</html>`} pathname={`try/23`} text="HTML hujjatiga oddiy still berishda <style> elementidan foydalanish:" />
      <h3 className='header-content'>Tavsifi va qo'llanilishi</h3>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;style&gt;</span> tegi yordamida Html hujjati stillari(CSS) beriladi.</p>
      <p className='text-tutorial'><span className='text-tutorial__red'>&lt;style&gt;</span> elementi ichida HTML elementi brauzerda qanday ko'rinishi, elementlarga stillar berish orqali o'rnatiladi.</p>
      <hr />
      <h3 className='header-content'>Maslahat va eslatmalar</h3>
      <p className='text-tutorial'><b>Eslatma:</b>Brauzer belgilangan stillarni o'qiganda, HTML hujjatini mana shu stillar ma'lumotlariga muvofiq formatlaydi. Agar bitta  element xususiyati uchun bir nechta turli stillar berilgan bo'lsa, oxirgi berilgan still qiymatidan foydalaniladi!</p>
      <p className='text-tutorial'><b>Maslahat:</b>Tashqi stillarni ulash uchun <Link className='table-link' to={"/tags/tag_link"}>&lt;link&gt;</Link> tegidan foydalaniladi.</p>
      <p className='text-tutorial'><b>Maslahat:</b>Stillar berish(CSS) haqida ko'proq bilish uchun <Link className='table-link' to={"/css"}>&lt;CSS haqida&gt;</Link> o'qing</p>
      <hr />
      <BrowserTable element='<style>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />
      <h3 className='header-content'>Atributlari</h3>
      <table className='table-content'>
        <tbody>
          <tr>
            <th scope="col">Atribut</th>
            <th scope="col">Qiymati</th>
            <th scope="col">Tavsif</th>
          </tr>

          <tr>
            <td><Link className='table-link' to={"/tags/att_style_media"}>&lt;media&gt;</Link></td>
            <td>	media_query</td>
            <td>	Hujjatning turli qurilamalardagi ko'rinishini optimallashtish</td>
          </tr>
          <tr>
            <td><Link className='table-link' to={"/tags/att_style_media"}>&lt;type&gt;</Link></td>
            <td>		text/css</td>
            <td>	&lt;style&gt; tegining media turini belgilaydi</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <h3 className='header-content'>Global atributlar</h3>
      <p className='text-tutorial'>&lt;style&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <h3 className='header-content'>Hodisa atributlari</h3>
      <p className='text-tutorial'>&lt;style&gt; tegi <Link className='table-link' to={"/tags/ref_eventattributes"}>HTML hodisa atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <h3 className='header-content'>Tegishli sahifalar</h3>
      <p className='text-tutorial'>HTML CSS mavzusi: <Link className='table-link' to={"/html/html_css"}>HTML CSS</Link></p>
      <p className='text-tutorial'>CSS mavzulari: <Link className='table-link' to={"/css"}>CSS mavzulari</Link></p>
      <p className='text-tutorial'>HTML DOM ma'lumotnomasi: <Link className='table-link' to={"/jsref/dom_obj_style"}>CSS Obyekti</Link></p>
      <hr />
      <h3 className='header-content'>Standart CSS sozlamalari</h3>
      <p className='text-tutorial'>Ko'p brauzerlarda &lt;style&gt; elementida quyidagi standart stillar berilgan bo'ladi:</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <CodeMirrorContentCss value={`style {
  display: none;
}`} />
        </div>
      </div>
      <hr />
      <NavButton back='/tags/tag_strong' next='/tags/tag_sub' />
    </article>
  )
}

export default TagStyle;
