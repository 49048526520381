import React, { useRef, useState } from 'react';
import './Collapsible.css';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

function Collapsible(props) {
  const [isOpen, setIsOpen] = useState(false);

  const parentRef = useRef();
  return (
    <section className='collapsible'>
      <button className='toggle' onClick={() => setIsOpen(!isOpen)}>
        Uyga vazifa:
        {isOpen ? <BsChevronUp className='collap-icon' /> : <BsChevronDown className='collap-icon' />}
      </button>
      <div className='content-parent'
        ref={parentRef}
        style={
          isOpen
            ? {
              height: parentRef.current.scrollHeight + 'px',
            }
            : {
              height: '0px',
            }
        }
      >
        <div className='code-content'>
          <h3>Sinab ko'ring:</h3>
          <div className='code-wrapper'>{props.children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Collapsible
