import React from 'react';
import { Link } from 'react-router-dom';
import NavButton from '../NavButton';
import '../Info.css';
import CodeContent from '../../../tryit/CodeContent';
import TagTable from './AttrTable';
import W3Path from '../../W3Path';
import BrowserTable from '../../pages/BrowserTable';
import TextContent from '../../pages/TextContent';


function Taga() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/tags/tag_a.asp"} />
      <h2 className='header-tutorial'> HTML&lt;a&gt; tegi</h2>
      <NavButton back='/tags/tag_doctype' next='/tags/tag_abbr' />
      <hr />
      <CodeContent props={` <a href="http://self-study.uz/">a-bu havola</a>`} pathname={`try/5`} text="a tegi yordamida self-study.uz saytiga havola yaratish:" />
      <h3 className='header-content'>Tavsifi va qo'llanilishi</h3>
      <p className='text-tutorial'> <span className='text-tutorial__red'>&lt;a&gt;</span> elementning eng muhim atributi <span className='text-tutorial__red'>href</span> hisoblanadi, u havolaning manzilini ko'rsatadi.</p>
      <p className='text-tutorial'> <i>Havola matni</i> foydalanuvchiga ko'rinadigan qismdir .</p>
      <p className='text-tutorial'> Barcha brauzerlarda havolalar quyidagicha standart ko'rinishga ega:</p>
      <ul className='lists'>
        <li className='item'>Ko'rilmagan havola tagiga chizilgan va ko'k rangda bo'ladi.</li>
        <li className='item'>Tashrif buyurilgan havolaning tagiga chizilgan va binafsha rang bo'ladi.</li>
        <li className='item'>Faol havolaning tagiga chizilgan va qizil rangda bo'ladi.</li>
      </ul>
      <hr />
      <h3 className='header-content'>Maslahat va eslatmalar</h3>
      <p className='text-tutorial'><b>Maslahat:</b> Agar <span className='text-tutorial__red'>&lt;a&gt;</span> tegida <span className='text-tutorial__red'>href</span> atributi bo'lmasa, u faqat giperhavola uchun joy ajratib qo'yadi.</p>
      <p className='text-tutorial'><b>Maslahat:</b> target atributi o'zgartirilmasa, odatda biriktirilgan sahifa joriy brauzer oynasida ko'rsatiladi.</p>
      <p className='text-tutorial'><strong>Maslahat: </strong> Ko'rinishini o'zgartirish uchun havolalarga  CSS stillari berish mumkin:  <Link className='text-tutorial text-tutorial__link' to={'/css/css_link'}>Havola stillari</Link>   <Link className='text-tutorial text-tutorial__link' to={'/css/css3_buttons'}>Button stillari</Link> </p>
      <hr />
      <BrowserTable element='<a>' chrome={"yes"} edge={"yes"} firefox={"yes"} safari={"yes"} opera={"yes"} />

      <TagTable arr={['download', 'href', 'hreflang', 'media', 'ping', 'referrerpolicy', 'rel', 'target', 'type']} />
      <hr />
      <h3 className='header-content'>Global atributlar</h3>
      <p className='text-tutorial'>&lt;html&gt; tegi <Link className='table-link' to={"/tags/ref_standardattributes"}>global atributlar</Link>ni ham qo'llab-quvvatlaydi.</p>
      <hr />
      <h3 className='header-content'>Tegishli sahifalar</h3>
      <p className='text-tutorial'>HTML mavzulari: <Link className='table-link' to={"/tags/ref_standardattributes"}>HTML bilan tanishish</Link></p>
      <p className='text-tutorial'>HTML DOM ma'lumotnomasi: <Link className='table-link' to={"/jsref/dom_obj_html"}>HTML Obyekti</Link></p>
      <hr />
      <TextContent header={"Default CSS sozlamalari"}
        text1={"Ko'p brauzerlarda <a> elementida quyidagi default stillar berilgan bo'ladi:"}
        redWords1={['<a>']}
        css1={`a:link, a:visited {
  color: (internal value);
  text-decoration: underline;
  cursor: auto;
}

a:link:active, a:visited:active {
  color: (internal value);
}`} />
      <hr />
      <NavButton back='/tags/tag_hr' next='/tags/tag_i' />
    </article>
  )
}

export default Taga;
