import React from "react";
import coffee from "../../images/coffeehouse2.jpg";
function Coffee() {
    return (
        <div className="container ">
            <div className="page">
                <h2 className='header-tutorial'>Coffee</h2>
                <img src={coffee} alt="Coffee" width={600} />
                <p className='text-tutorial'>
                    Wikipedia
                </p>
                <blockquote cite="https://en.wikipedia.org/wiki/Coffee">
                    <i>Qahva - bu Coffea o'simligidan olingan rezavorlar urug'lari bo'lgan qovurilgan qahva donalaridan tayyorlangan pishirilgan ichimlik.
                    </i>
                </blockquote>
                <p className='text-tutorial' target={'_blank'} rel="noreferrer">Ko'proq o'qish <a className='text-tutorial text-tutorial__link' href="https://en.wikipedia.org/wiki/Coffee">https://en.wikipedia.org/wiki/Coffee</a></p>
            </div>
        </div>
    )
}

export default Coffee;