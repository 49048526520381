import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import { Link } from 'react-router-dom';
import ListText from '../../pages/ListsText';
import Notes from '../../pages/Notes';


function IdHtml() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_id.asp"} />
            <h2 className='header-tutorial'>Id atributi</h2>
            <NavButton back='/html/html_classes' next='/html/html_iframe' />
            <hr />
            <TextContent
                text1={"HTML id atributi HTML elementi uchun yagona identifikatorni belgilash uchun ishlatiladi."}
                redWords1={["id"]} />
            <p><i>O`bek tilida kam ishlatiladigan tushuncha "identifikator" yoki qisqacha "id" ("aydiy" deb o`qiladi) haqida biroz gaplashsak.
                Identifikator deb ma'lum bir elementlar to`plamida qaysidir elementning yagonaligini ifodalovchi biron qiymatga aytiladi.</i></p>
            <p><i>ID ga oddiy hayotiy misol bu avtomobil davlat raqami. Bir joyda juda ko`p avtomobillar bo`lishi mumkin, lekin bitta davlat raqami faqat birgina avtoga tegishli bo`ladi. Xuddi shu tushunchani HTMLga olib kirsak... Aytaylik bitta HTML sahifada juda ko`p elementlar bor bo`ladi. </i></p>
            <p><i>Ular ichida aynan sizga kerak elementni ajratib ko`rsatish uchun shu elementning id attributiga yagona, takrorlanmas qiymat berasiz.</i></p>
            <TextContent
                text1={"Masalan, html ichida bir necha <p> tegi bilan belgilangan paragraflar bor. Shular ichida aynan qaysidir paragrafni ajratib olish uchun unga id berish mumkin:"}
                   text2={"< p id='paragraf1' >...</p><p id='paragraf2' >...</p>."}
                   redWords2={["id='paragraf1'", "id='paragraf2'"]}
                text3={"Mana ID berdik. Endi shu paragraflardan birontasi ustida amal bajarmoqchi bo`lsak(stil berish yoki javascript orqali paragraf ichidagi qiymatini o`zgartirish), bemalol berilgan id dan foydalansak bo`ladi. Shu haqida batafsil mavzu davomida tushunib olasiz."}
            redWords1={["id"]} />
            <p>HTMLda bir xil identifikatorga ega bir nechta element bo'lishi mumkin emas.</p>
            <hr />
            <TextContent header={"Id atributini ishlatish."}
                text1={"HTML id atributi HTML elementi uchun yagona identifikatorni belgilaydi. id atributining qiymati HTML hujjatida yagona bo'lishi kerak."}
                redWords1={["id"]}
                text2={"Id atributiga yordamida elementga muayyan stillar berishimiz mumkin. Bundan tashqari, u JavaScript tomonidan ma'lum bir identifikatorga ega elementga kirish va uni boshqarish uchun ishlatiladi."}
                redWords2={["id"]}
                text3={"Id sintaksisi: id nomidan oldin xeshteg belgisini(#) yoziladi va jingalak qavslar{} ichida CSS xususiyatlari beriladi."}
                redWords3={["id"]}
                text4={"Quyidagi misolda 'myHeader' id nomiga ega <h1> elementga head qismidagi #myHeader stili beriladi:"}
                redWords4={["<h1>", "'myHeader'", "#myHeader"]}
                code4={`<!DOCTYPE html>
<html>
<head>
<style>
#myHeader {
  background-color: lightblue;
  color: black;
  padding: 40px;
  text-align: center;
}
</style>
</head>
<body>

<h1 id="myHeader">Mening sarlavham</h1>

</body>
</html>
`} path4={"try/230"} />
            <div className='note-content'>
                <p><strong>Eslatma:</strong> ID nomi katta-kichik harflarga sezgir!</p>
                <p><strong>Eslatma:</strong>Identifikator nomi kamida bitta belgidan iborat bo'lishi kerak, raqam bilan boshlanmasligi va bo'sh joylar(bo'shliqlar, tabulyatsiya va boshqalar) bo'lmasligi kerak.</p>
            </div>
            <hr />
            <TextContent header={"Class va ID o'rtasidagi farq"}
                text1={"Class nomi bir nechta HTML elementlari tomonidan ishlatilishi mumkin, id nomi esa sahifadagi faqat bitta HTML elementi tomonidan ishlatilishi kerak:"}
                code1={`<style>
/* Style the element with the id "myHeader" */
#myHeader {
  background-color: lightblue;
  color: black;
  padding: 40px;
  text-align: center;
}

/* Style all elements with the class name "city" */
.city {
  background-color: tomato;
  color: white;
  padding: 10px;
}
</style>

<!-- yagona idiga ega element-->
<h1 id="myHeader">Mening shaxrim</h1>

<!-- Bir xil classga ega elementlar -->
<h2 class="city">London</h2>
<p>London - Angliya poytaxti</p>

<h2 class="city">Parij</h2>
<p>Parij - Fransiya poytaxti.</p>

<h2 class="city">Tokiyo</h2>
<p>Tokiyo -Yaponiya poytaxti.</p>`} path1={"try/231"} />
            <Notes text={" CSS haqida ko'proq ma'lumotni"} path={"/css"} text1={"CSS bo'limidan"} text2={" olishingiz mumkin."} />
            <hr />
            <h3 className='header-content'>Xatcho'plar<small>(<Link className='text-tutorial text-tutorial__link' to={'/html/html_links_bookmarks'}>batafsil o'qish</Link>)</small></h3>
            <TextContent
                text1={"HTML xatcho'plari foydalanuvchiga veb-sahifaning muayyan qismlariga o'tish imkonini beradi."}
                text2={"Juda uzun veb sahifalarda xatcho'plardan foydalanish qulaylik tug'diradi."}
                text3={"Avval xatcho'p yaratiladi, keyin havola bilan birlashtiriladi. Havola bosilganda, sahifaning xatcho'p joylashgan qismi ochiladi."} />
            <TextContent header={"Misol"}
                text1={"Birinchi, id atributi bilan xatcho'p yaratiladi:"}
                code1={`<h2 id="C4">4-bo'lim</h2>`}
                text2={"Keyin, xuddi shu sahifadagi xatcho'pga havola('4-bo'limga o'tish') birlashtiriladi:"}
                code2={`<a href="#C4">4-bo'limga o'tish</a>`}
                path2={'try/89'}
                text3={"Yoki, boshqa sahifadagi xatcho'pga havola('4-bo'limga o'tish') birlashtiriladi:"}
                code3={"<a href='html_demo.html#C4'>4-bo'limga o'tish</a>"} />
            <hr />
            <TextContent header={"JavaScriptda id atributidan foydalanish"}
                text1={"Id atributi yordamida ma'lum elementlar bilan JavaScriptda muayyan vazifalarni bajarish uchun ham ishlatilishi mumkin."}
                redWords1={['Id']}
                text2={"JavaScriptda muayyan identifikatorga ega elementlarga getElementById() metodi yordamida kirishi mumkin:"}
                redWords2={['getElementById()']}
                code3={`<script>
function displayResult() {
  document.getElementById("myHeader").innerHTML = "Kuningiz yaxshi o'tsin!";
}
</script>`} path3={"try/232"} />
            <div className='note-content'>
                <p><strong>Maslahat:</strong> JavaScript haqida ko'proq ma'lumotni<Link className='text-tutorial text-tutorial__link' to={'/html/html_scripts'}>HTML JavaScript </Link> yoki <Link className='text-tutorial text-tutorial__link' to={'/js'}>JavaScript mavzulari </Link>dan olishingiz mumkin.</p>
            </div>
            <TextContent header={"Bo'lim xulosasi"} />
            <ListText list={[{
                text: "Id atributi HTML elementi uchun yagona identifikatorni belgilash uchun ishlatiladi.",
                redWords: ["Id"]
            },
            {
                text: "Id atributining qiymati HTML hujjatida yagona bo'lishi kerak.",
                redWords: ["Id"]
            },
            {
                text: "Id atributi CSS va JavaScriptda ma'lum bir elementga stil berish va tanlash uchun ishlatiladi",
                redWords: ["Id"]
            },
            {
                text: "Id atributi katta-kichik harflarga sezgir",
                redWords: ["Id"]
            },
            {
                text: "id atribut HTML xatcho'plarini yaratish uchun ham ishlatiladi", redWords: ["id"]
            },
            {
                text: "JavaScriptda muayyan identifikatorga ega elementlarga getElementById() metodi yordamida kirishi mumkin.", redWords: ["getElementById()"]
            },]} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="X9jpHOcuXOU" />
            <NavButton back='/html/html_classes' next='/html/html_iframe' />
        </article>)
}

export default IdHtml;