import React from 'react';
import { Link } from 'react-router-dom';


function CardItem(props) {
  return (
    <>
      <li className='card__item' style={{ backgroundColor: `${props.fon}`, color: `${props.fon < '#7CFC00' ? '#fff' : '#000'}` }}>
        <h2 className='card__item__header'>
          {
            props.path ? <Link className="card__item__link" to={props.path}>{props.label}</Link> : <Link style={{ color: `${props.fon < '#7CFC00' ? '#fff' : '#000'}` }} className="card__item__link" to={props.label} target="_blank" rel="noopener noreferrer" >{window.location.pathname === '/colors/colors_hex' ? props.text : props.label}</Link>
          }
        </h2>

        <p className='card__item__text'>{window.location.pathname === '/colors/colors_hex' ? props.label : props.text}</p>
      </li>
    </>
  )
}

export default CardItem