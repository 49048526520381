import React from 'react';
import Notes from '../../../pages/Notes';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import pictureEx from '../../../../images/picture_example.jpg';
import Table from '../../../pages/Table';
import YoutubeEmbed from '../../YoutubeEmbed';



function Picture() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_images_picture.asp"} />
      <h2 className='header-tutorial'>HTML picture elementi</h2>
      <NavButton back='/html/html_images_background' next='/html/html_favicon' />
      <TextContent
        text1={"HTML <picture> elementi turli qurilmalar yoki ekran o'lchamlari uchun turli xil rasmlarni ko'rsatish imkonini beradi."} />
      <img src={pictureEx} alt='' width={837} height={327} style={{ maxWidth: '100%' }} />
      <hr />
      <TextContent header={"HTML <picture> elementi"}
        text1={"HTML <picture> elementi dasturchilarga rasm resurslarini belgilashda ko'proq moslashuvchanlikni beradi."}
        redWords1={['<picture>']}
        text2={"<picture> elementi bir yoki bir nechta <source> elementlarini o'z ichiga oladi, ularning har biri  srcset  atributi orqali turli xil rasmga ishora qiladi . Shu tarzda brauzer joriy ko'rinishga va qurilmaga eng mos keladigan rasmni tanlashi mumkin."}
        redWords2={['<source>', ' srcset', '<picture>']}
        text3={"Har bir <source> elementi mos rasmni belgilaydigan media atributiga ega."}
        redWords3={['<source>', 'media']}
        code3={`<picture>
        <source media="(min-width: 650px)" srcset="img_food.jpg">
        <source media="(min-width: 465px)" srcset="img_car.jpg">
        <img src="img_girl.jpg">
</picture>`}
        path3={"try/117"}
        code3Text={"Turli xil ekran o'lchamlari uchun turli xil tasvirlarni ko'rsatish:"}
      />

      <Notes type="Eslatma" text="Har doim <img> elementni oxirgi <picture> elementi sifatida belgilang .  Brauzerlar tomonidan <picture> elementi qo'llab-quvvatlanmasa yoki <source> teglarining hech biri mos kelmasa <img> elementi ishlatiladi." />
      <hr />

      <TextContent header={"HTML <picture> elementidan foydalanish"}
        text1={"<picture> elementining ikkita asosiy maqsadi bor:"}
        redWords1={['<picture>']} />
      <h4 className='header-content' style={{ fontSize: '24px' }}>1. Rasm o'lchami</h4>
      <TextContent
        text1={"Kichik ekran va qurilmalarga katta rasm faylini yuklash shart emas. Brauzer <source> atributi qiymatlariga mos keladigan birinchi elementdan foydalanadi va qolganlarini e'tiborsiz qoldiradi."}
        redWords1={['<source>']} />
      <h4 className='header-content' style={{ fontSize: '24px' }}>2. Formatni qo'llab-quvvatlash</h4>
      <TextContent
        text1={"Ba'zi brauzerlar yoki qurilmalar barcha rasm formatlarini qo'llab-quvvatlamasligi mumkin. <picture> elementidan foydalanib , barcha rasmlarning formatlarini  qo'shish mumkin va brauzer o'zi qo'llab-quvvatlaydigan birinchi formatdan foydalanadi va qolgan elementlarni e'tiborsiz qoldiradi."}
        redWords1={['<picture>']}
        code3={`<picture>
        <source srcset="img_avatar.png">
        <source srcset="img_girl.jpg">
        <img src="img_beatles.gif" alt="Beatles" style="width:auto;">
      </picture>`}
        path3={"try/118"}
        code3Text={"Brauzer o'zi qo'llab-quvvatlaydigan birinchi rasm formatidan foydalanadi:"}
      />
      <Table header={"Html teglari"} th={["Qishqartma", "Kengaymasi"]} values={[
        { id: 0, first: "<img>", second: "Rasm joylashtirish uchun ishlatiladi", path: '/tags/tag_img' },
        { id: 1, first: "<map>", second: "Rasm xartiasi yaratish uchun ishlatiladi", path: '/tags/tag_map' },
        { id: 2, first: "<area>", second: "Bosiladigan maydonni yaratish uchun ishlatiladi", path: '/tags/tag_area' },
        { id: 3, first: "<picture>", second: "Bir nechta rasm resurslari uchun konteynerni belgilaydi", path: '/tags/tag_picture' },
      ]} />
      <Notes text={"Barcha mavjud HTML teglarining toʻliq roʻyxatini koʻrish uchun bizning"} path="/tags" text1={" HTML teg maʼlumotnomamizga"} text2={"tashrif buyuring."} />
      <TextContent header={"Dars videosi:"} />
      <YoutubeEmbed embedId="7B3hiVKJOqM" />
      <NavButton back='/html/html_images_background' next='/html/html_favicon' />
    </article >
  )
}
export default Picture;