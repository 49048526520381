import React from "react";
import frenchfood from "../../images/frenchfood.jpg";
function Croissant() {
    return (
        <div className="container ">
            <div className="page">
                <h2 className='header-tutorial'>Kruasan</h2>
                <img src={frenchfood} alt="Computer" width={450} height={675} />
                <p className='text-tutorial'>
                    Wikipedia </p>
                <blockquote cite="https://en.wikipedia.org/wiki/Computer">
                    <i>Kruasan - avstriya kelib chiqishi bo'lgan, sariyog'li, loyqa, viennoiseri pishirig'i bo'lib, uning tarixiy yarim oy shakli uchun nomlangan.
                    </i>
                </blockquote>
                <p className='text-tutorial' target={'_blank'} rel="noreferrer">Ko'proq o'qish <a className='text-tutorial text-tutorial__link' href="https://en.wikipedia.org/wiki/Croissant">https://en.wikipedia.org/wiki/Croissant</a></p>
            </div>
        </div>
    )
}

export default Croissant;