import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import Notes from '../../../pages/Notes';
import ListText from '../../../pages/ListsText';



function Plagins() {
    return (
        <div className='info-wrapper'>
                <W3Path path={"https://www.w3schools.com/html/html_object.asp"} />
            <h2 className='header-tutorial'>HTML plaginlari</h2>
            <NavButton back='/html/html5_audio' next='/html/html_youtube' />
            <hr />
            <p>Plaginlar brauzerning standart funksiyalarini kengaytiradigan kompyuter dasturlaridir.</p>
            <hr />
            <TextContent header={"Plaginlar"}
                text1={"Plaginlar turli maqsadlarda foydalanishga mo'ljallangan:"}/>
                            <ListText list={[{
                text: "Java appletlarini ishga tushirish uchun",
            },
            {
                text: "Microsoft ActiveX boshqaruvlarini ishga tushirish uchun",
            },
            {
                text: "Flash filmlarni ko'rsatish uchun",
            },
            {
                text: "Xaritalarni ko'rsatish uchun",
            },
            {
                text: "Viruslarni tekshirish uchun",
            },
            {
                text: "Bank identifikatorini tekshirish uchun",
            },]} />
                <hr/>
                <div className='note-content' style={{backgroundColor: "#ffdddd"}}>
                    <h3>Ogohlantirish!</h3>
                    <p>Hozirda ko'pgina brauzerlar Java appletlari va plaginlarini qo'llab-quvvatlamaydi.</p>
                    <br/>
                    <p>ActiveX boshqaruvlari endi hech qanday brauzerda qo'llab-quvvatlanmaydi.</p>
                    <br/>
                    <p>Shockwave Flash ham zamonaviy brauzerlarda qo'llab-quvvatlanmaydi.</p>
                    <br/>
                </div>
                <hr/>
            <TextContent header={"<object> elementi"}
                text1={"<object> elementi barcha brauzerlar tomonidan qo'llab-quvvatlanadi."}
                redWords1={["<object>"]}
                text2={"<object> elementi HTML hujjatiga jopylashtiriladigan obyektni belgilaydi."}
                redWords2={["<object>"]}
                text3={"<object> elementi veb-sahifalarga plaginlar(masalan, Java appletlari, PDF reader va Flash Playerlar)ni joylashtirish uchun mo'ljallangan, shuningdek, HTMLni HTMLga kiritish uchun ham foydalanish mumkin:"}
                code3={`<object width="100%" height="500px" data="snippet.html"></object>`}
                path3={`try/434`}
                text4={"Yoki rasmlarni kiritamiz:"}
                code4={`<object data="audi.jpeg"></object>`}
                path4={`try/435`}/>
                <hr/>
                <TextContent header={"<embed> elementi"}
                text1={"<embed> elementi barcha asosiy brauzerlarda qo'llab-quvvatlanadi."}
                redWords1={["<embed>"]}
                text2={"<embed> elementi ham HTML hujjatiga joylashtiriladigan obyektni belgilaydi."}
                redWords2={["<embed>"]}
                text3={"Veb brauzerlar <embed> elementini ancha vaqtdan buyon qo'llab-quvvatlab keladi. Biroq, u HTML5 dan oldin HTML spetsifikatsiyasining bir qismi bo'lmagan."}
                code3={`<embed src="audi.jpeg">`}
                path3={`try/436`}/>
                <Notes text={"<embed> elementi yopilgan tegi yo'q. Unda muqobil matn bo'lishi mumkin emas."}/>
                <br/>
            <TextContent
                text1={"<embed> elementi HTMLni HTMLga kiritish uchun ham ishlatilishi mumkin:"}
                redWords1={["<embed>"]}
                code1={`<embed width="100%" height="500px" src="snippet.html">`}
                path1={`try/437`}/>
                <hr/>
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="aFE-5-qnyTU" />
            <NavButton back='/html/html5_audio' next='/html/html_youtube' />
        </div>)
}

export default Plagins;