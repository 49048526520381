import React from 'react';
import '../Info.css';
import NavButton from '../NavButton';
import CodeContent from '../../../tryit/CodeContent';
import YoutubeEmbed from '../YoutubeEmbed';
import Collapsible from '../../pages/Collapsible';
import Tegs from '../../pages/tegs';
import Table from '../../pages/Table';
import W3Path from '../../W3Path';

function HtmlInfo() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_intro.asp"} />
      <h2 className='header-tutorial'>HTML bilan tanishish</h2>
      <NavButton back='/html' next='/html/html_editor' />
      <hr />
      <p className='text-tutorial text-tutorial-margin'>HTML veb-sahifalarni yaratish uchun standart belgilash tilidir.</p>
      <hr />
      <h3 className='header-content'>HTML nima?</h3>
      <ul className='lists'>
        <li className='item'><p>HTML{`(`}Hyper Text Markup Language{`)`} - gipermatnli belgilash tili degan ma'noni anglatadi</p></li>
        <li className='item'><p>HTML veb-sahifalarni yaratish uchun standart belgilash tilidir</p></li>
        <li className='item'><p>HTML veb-sahifaning tuzilishini tavsiflaydi</p></li>
        <li className='item'><p>HTML bir qator elementlar(matnlar, rasmlar, video va b.)dan iborat</p></li>
        <li className='item'><p>HTML elementlari brauzerga kontentni qanday ko'rsatishni aytadi</p></li>
        <li className='item'><p>HTML matn qismining mantiqiy rolini belgilaydi {`(`}matn sarlavhasi, paragraf, roʻyxat va hokazo{`)`}</p></li>
      </ul>
      <h3 className='header-content'>Oddiy HTML hujjati</h3>
      <CodeContent props={`<!DOCTYPE html>
<html>
<head>
<title>Sahifa sarlavhasi</title>
</head>
<body>

<h1>Mening birinchi sarlavham</h1>
<p>Mening birinchi xatboshim.</p>

</body>
</html>
    `} pathname={`try/2`} />
      <h3 className='header-content'>Yuqoridagi misolni ko'rib chiqamiz</h3>
      <ul className='lists'>
        <li className='item'>
          <p className='text-tutorial'><Tegs tegname="!DOCTYPE html " />deklaratsiyasi ushbu hujjat HTML5 hujjati ekanligini bildiradi </p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><Tegs tegname="html " />elementi HTML tilidagi hujjatning boshlanishini bildiradi. </p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><Tegs tegname="head " />elementida Html sahifasi haqidagi meta ma'lumotlar joylashadi </p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><Tegs tegname="title " />elementi HTML sahifasining nomini belgilaydi (u brauzerning sarlavha satrida yoki sahifa yorlig'ida ko'rsatiladi) </p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><Tegs tegname="body " />elementi hujjatning tanasini belgilaydi va sarlavhalar, paragraflar, rasmlar, giperhavolalar, jadvallar, ro'yxatlar va boshqalar kabi barcha ko'rinadigan tarkiblar uchun konteynerdir. </p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><Tegs tegname="h1 " />elementi katta sarlavhani belgilaydi. </p>
        </li>
        <li className='item'>
          <p className='text-tutorial'><Tegs tegname="p " />elementi xatboshini belgilaydi </p>
        </li>
      </ul>
      <h3 className='header-content'>HTML elementi nima?</h3>
      <p className='text-tutorial'>HTML elementi boshlang'ich teg, kontent va yakuniy tegdan iborat:</p>
      <p className='text-tutorial'>
        <Tegs tegname="tagname " /> Bu yerda kontent...<Tegs tegname="/tagname " /></p>
      <p className='text-tutorial'>Boshlang'ich tegdan yakuniy teggacha bo'lgan narsalar HTML elementi deyiladi:</p>
      <p className='text-tutorial'>
        <Tegs tegname="h1 " /> Mening birinchi sarlavham<Tegs tegname="/h1 " /></p>
      <p className='text-tutorial'>
        <Tegs tegname="p " /> Mening birinchi xatboshim.<Tegs tegname="/p " /></p>
      <table className='table-content'>
        <tbody>
          <tr>
            <th scope="col">Ochilgan teg(boshlanish qismi)</th>
            <th scope="col">Element tarkibi</th>
            <th scope="col">Yopilgan teg(tugash qismi)</th>
          </tr>

          <tr>
            <td><Tegs tegname="h1 " /></td>
            <td>Mening birinchi sarlavham</td>
            <td><Tegs tegname="/h1 " /></td>
          </tr>

          <tr>
            <td><Tegs tegname="p " /></td>
            <td>Mening birinchi xatboshim</td>
            <td><Tegs tegname="/p " /></td>
          </tr>

          <tr>
            <td><Tegs tegname="br " /></td>
            <td>yo'q</td>
            <td>yo'q</td>
          </tr>
        </tbody>
      </table>

      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma: </strong>Ayrim Html elementlari faqat ochilgan tegdan ya'ni toq tegdan iborat bo'ladi.</p>
      </div>
      <h3 className='header-content'>Dars videosi</h3>
      <YoutubeEmbed embedId="p1oZ_T4dGbY" />
      <Collapsible>
        <p className='text-tutorial'><strong>1.</strong> Sahifa sarlavhasini nomini <i>'Htmlni o'rganish'</i> deb nomlang</p>
        <p className='text-tutorial'><strong>2.</strong>  Quyidagi matnni kiriting:<i>"HTML(Hyper Text Markup Language) - gipermatnli belgilash tili degan ma'noni anglatadi".</i></p>
        <p className='text-tutorial'><strong>3.</strong> Sarlavhaga <i>"Html haqida"</i> deb kiriting.</p>
      </Collapsible>
      <h3 className='header-content'>Veb brauzerlar</h3>
      <p className='text-tutorial'><strong>Brauzer (Chrome, Edge, Firefox, Safari)</strong> - bu Internetga kirish uchun ishlatiladigan dastur. Web-saytlarga kirish va ular ichidagi maqolalarni o'qish, multimediya ko'rish, bir saytdan boshqasiga bog'lanish, bir sahifadan boshqasiga kirish, chop etish, elektron xabarlarni yuborish va qabul qilish va boshqa amallarni bajarish imkonini beradi. </p>
      <h3 className='header-content'>Chrome brauzerini yuklab olish</h3>
      <YoutubeEmbed embedId="SS401EBmMik" />
      <div className='note-content'>
        <p className='text-tutorial'><strong>Chrome</strong> brauzerini qanday yuklab olish haqidagi videodan foydalanib Chromni yuklab oling va komputeringizga o'rnating. Darslarimiz davomida shu brauzerdan foydalanamiz.
          Ushbu brauzerning eng muhim afzalligi uning tezligi. Google Chrome sinxronlashtirish kabi ajoyib xususiyatga ega, ya'ni bir qurilmada saqlab qo'ygan ma'lumotlaringizdan boshqa qurilmalaringizda ham foydalanishingiz   mumkin.</p>
      </div>
      <h3 className='header-content'>HTML sahifasi tuzilishi</h3>
      <p className='text-tutorial'>Quyida HTML sahifasining vizual tuzilishi keltirilgan:</p>
      <div className='struc-content struc-inner'>
        <Tegs tegname="html " />
        <div className='struc-inner'>
          <Tegs tegname="head " />
          <div className='struc-inner'>
            <p className='text-tutorial text-tutorial-margin'>
              <Tegs tegname="title " /> Sahifa Sarlavhasi<Tegs tegname="/title " /></p>
          </div>
          <Tegs tegname="/head " />
        </div>
        <div className='struc-inner'>
          <Tegs tegname="body " />
          <div className='struc-inner struc-white'>
            <div className='struc-inner'>
              <p className='text-tutorial text-tutorial-margin'>
                <Tegs tegname="h1 " /> Bu sarlavha<Tegs tegname="/h1 " />
              </p>
            </div>
            <div className='struc-inner'>
              <p className='text-tutorial text-tutorial-margin'>
                <Tegs tegname="p " /> Bu xatboshi.<Tegs tegname="/p " />
              </p>
            </div>
            <div className='struc-inner'>
              <p className='text-tutorial text-tutorial-margin'>
                <Tegs tegname="p " /> Bu boshqa xatboshi.<Tegs tegname="/p " />
              </p>
            </div>
          </div>
          <Tegs tegname="/body " />
        </div>
        <Tegs tegname="/html " />
      </div>

      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma:</strong> <Tegs tegname="body " /> bo'limi tarkibidagi(yuqoridagi oq maydon) elementlar brauzerda ko'rsatiladi.  <Tegs tegname="title " /> elementi ichidagi tarkib brauzerning sarlavha satrida yoki sahifa yorlig'ida ko'rsatiladi.</p>
      </div>
      <Table header={"HTML tarixi"} text={"World Wide Web yaratilganidan boshlab hozirgacha, HTMLning ko'plab versiyalari mavjud:"} th={["Yili", "Versiyasi",]} values={[
        { id: 0, first: "1989", second: "Tim Berners-Lee www ni yaratdi" },
        { id: 1, first: "1991", second: "Tim Berners-Lee HTMLni yaratdi" },
        { id: 2, first: "1993", second: "Dave Raggett HTML+ ni loyihasini ishlab chiqdi" },
        { id: 3, first: "1995", second: "JHTML ishchi guruhi HTML 2.0 loyihasi ishlab chiqadi" },
        { id: 4, first: "1997", second: "W3C tavsiyasi: HTML 3.2" },
        { id: 5, first: "1999", second: "W3C tavsiyasi: HTML 4.01 " },
        { id: 6, first: "2000", second: "W3C tavsiyasi: XHTML 1.0" },
        { id: 7, first: "2008", second: "WHATWG HTML5 biringchi ommaviy loyihasi ishlab chiqildi " },
        { id: 8, first: "2012", second: "HATWG HTML5 standartlashtirildi " },
        { id: 9, first: "2014", second: "W3C tavsiyasi: HTML5 " },
      ]} />

      <div className='note-content'>
        <p className='text-tutorial'><strong>Eslatma:</strong> Ushbu qo'llanma eng so'nggi HTML5 standartiga amal qiladi.</p>
      </div>

      <NavButton back='/html' next='/html/html_editor' />
    </article>
  )
}

export default HtmlInfo;