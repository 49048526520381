import React, { useEffect, useState, useRef, createContext, Fragment } from "react";
import { saveAs } from "file-saver";
import './css/global.css';
import { storageAvailable, generateFileName } from "./utils";
import Header from "./companents/Header";
import Editor from "./companents/Editor";
import Grid from "./companents/Grid";
import Preview from "./companents/Preview";
import ExampleObj from './exampleObj';
const htmlCodeKey = "codeapp:htmlCode";
const cssCodeKey = "codeapp:cssCode";
const jsCodeKey = "codeapp:jsCode";

export function getInitialCode(key, fallbackCodeGenerator) {
  return fallbackCodeGenerator;
}

function TryIt(props) {
  const example = ExampleObj(props);
  const generateHtmlCode = example[0];
  const generateCssCode = example[1];
  const generateJsCode = example[2];

  const updateEditor = () => {
    setHtmlCode(generateHtmlCode);
    setCssCode(generateCssCode);
    setJsCode(generateJsCode);
  }

  const initialValues = {
    htmlCode: getInitialCode(htmlCodeKey, generateHtmlCode),
    cssCode: getInitialCode(cssCodeKey, generateCssCode),
    jsCode: getInitialCode(jsCodeKey, generateJsCode),
  };

  const previewReloadDelay = 3000;

  const [htmlCode, setHtmlCode] = useState(initialValues.htmlCode);
  const [cssCode, setCssCode] = useState(initialValues.cssCode);
  const [jsCode, setJsCode] = useState(initialValues.jsCode);


  const htmlCodeEditorRef = useRef(null);
  const cssCodeEditorRef = useRef(null);
  const jsCodeEditorRef = useRef(null);
  const timeoutRef = useRef(null);

  const UserContext = createContext();
  const [click, setclick] = useState(true);
  const [themeChange, setThemeChange] = useState(false);

  const handleClick = () => setclick(!click);
  const handleThemeChange = () => setThemeChange(!themeChange);

  function updatePreview() {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setHtmlCode(htmlCodeEditorRef.current.editor.getValue());
      setCssCode(cssCodeEditorRef.current.editor.getValue());
      setJsCode(jsCodeEditorRef.current.editor.getValue());
    }, previewReloadDelay);
  }

  function handleChange() {
    updatePreview();
  }

  function handleDownload(event, downloadButtonRef) {
    const htmlCode2Save = localStorage.getItem(htmlCodeKey);
    const cssCode2Save = localStorage.getItem(cssCodeKey);
    const jsCode2Save = localStorage.getItem(jsCodeKey);

    const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <style>${cssCode2Save}</style>
      </head>
      <body>${htmlCode2Save}
      <script>${jsCode2Save}</script>
      </body>
    </html>`;

    try {
      var isFileSaverSupported = !!new Blob();
    } catch (error) {
      console.error("This browser doesn't support file saving.\n", error);
    }

    if (isFileSaverSupported) {
      const fileName = generateFileName();
      const blob = new Blob([content], {
        type: "text/html;charset=utf-8",
      });
      saveAs.saveAs(blob, fileName);
    }
  }

  useEffect(() => {
    if (storageAvailable("localStorage")) {
      localStorage.setItem(htmlCodeKey, htmlCode);
      localStorage.setItem(cssCodeKey, cssCode);
      localStorage.setItem(jsCodeKey, jsCode);
    }
  }, [htmlCode, cssCode, jsCode]);

  const htmlEditor = (
    <Editor
      mode="text/html"
      autofocus={true}
      onChange={handleChange}
      value={htmlCode}
      ref={htmlCodeEditorRef}
      theme={(themeChange) ? "monokai" : "3024-day"}
    />
  );

  const cssEditor = (
    <Editor
      mode="css"
      onChange={handleChange}
      value={cssCode}
      ref={cssCodeEditorRef}
      theme={(themeChange) ? "monokai" : "3024-day"}
    />
  );

  const jsEditor = (
    <Editor
      mode="javascript"
      onChange={handleChange}
      value={jsCode}
      ref={jsCodeEditorRef}
      theme={(themeChange) ? "monokai" : "3024-day"}
    />
  );


  const preview = (
    <Preview cssCode={cssCode} htmlCode={htmlCode} jsCode={jsCode} />
  );

  return (
    <Fragment>
      <UserContext.Provider value={{ click }} >
        <Header onDownload={handleDownload} handleClick={handleClick} click={click} handleThemeChange={handleThemeChange} update={updateEditor} />
        <Grid
          htmlEditor={htmlEditor}
          cssEditor={cssEditor}
          jsEditor={jsEditor}
          preview={preview}
          click={click}
        />
      </UserContext.Provider>
    </Fragment>
  );
}

export default TryIt;
