import React from 'react';
import ColorLists from './colorList';
import ColorTable from './ColorTable';

function TryItColor(props) {
    var result = ColorLists.find(item => item.title === props.path);
    return (
        <div className='info-wrapper' style={{ textAlign: 'center' }}>
            <h2 className='header-tutorial'>{result.title} </h2>
            <h2 className='header-tutorial'>{result.hex} </h2>
            <h2 className='header-tutorial'>{result.rgb} </h2>
            <p className='text-tutorial'>Quyidagi jadvalning fon rangi: {result.title} va turli rangdagi matn bilan birga ko'rsatilgan.</p>
            <hr />
            <ColorTable values={ColorLists} bgColor={result.title} />
        </div>
    )
}

export default TryItColor;