import React from 'react';
import { UnControlled as CodeMirror } from "react-codemirror2";
// import CodeMirror from '@uiw/react-codemirror';
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/css/css";
import "codemirror/mode/javascript/javascript";
import "codemirror/addon/selection/active-line";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/scroll/simplescrollbars";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/javascript-hint";
import "codemirror/addon/hint/html-hint";
import "codemirror/addon/hint/css-hint";

function CodeMirrorContent({ value }) {
  return (
    <>
      <CodeMirror
        value={value}
        options={{
          theme: '3024-day',
          mode: 'text/html',
          lineWrapping: true,
        }}
      />
    </>
  )
}

export default CodeMirrorContent
