import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import { Link } from 'react-router-dom';
import ListText from '../../pages/ListsText';


function ClassHtml() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_classes.asp"} />
            <h2 className='header-tutorial'>Class atributi</h2>
            <NavButton back='/html/html_blocks' next='/html/html_id' />
            <hr />
            <p> Ba'zida HTML elementlarini turli guruhlarga ajratib JavaScript code orqali ular ustida amallar bajarishga yoki CSS orqali stillar berishga ehtiyoj tug`iladi. </p>
            <TextContent
                text1={"HTML da elementlarni guruhlarga ajratib berish vazifasini class attributi amalga oshiradi. Bu qanday ishlaydi va nega kerak kabi savollarga yaxshiroq javob topish uchun darsda keltirilgan kodlarni mustaqil sinab ko`rishni tavsiya etamiz."}
                redWords1={["class"]} />
            <hr />
            <TextContent header={"Class atributini ishlatish."}
                text1={"class atributi ko'pincha CSS stillaridagi class nomini ko'rsatish uchun ishlatiladi. Bundan tashqari, u JavaScript tomonidan muayyan class nomiga ega elementlarga kirish va ularni boshqarish uchun ishlatilishi mumkin."}
                redWords1={["class"]}
                text2={"Quyidagi misolda class atributi 'city' qiymatiga ega uchta <div> elementi berilgan. Ya'ni, mana shu <div> elementlariga head qismidagi .city stili berilishini bildiradi."}
                redWords2={["class", "div", ".city"]}
                code2={`<!DOCTYPE html>
<html>
<head>
<style>
.city {
  background-color: tomato;
  color: white;
  border: 2px solid black;
  margin: 20px;
  padding: 20px;
}
</style>
</head>
<body>

<div class="city">
<h2>London</h2>
<p>London - Angilya poytaxti.</p>
</div>

<div class="city">
<h2>Parij</h2>
<p>Parij - Fransiya poytaxti.</p>
</div>

<div class="city">
<h2>Tokiyo</h2>
<p>Tokiyo - Yaponiya poytaxti</p>
</div>

</body>
</html>
`} path2={"try/224"} />
            <TextContent
                text1={"Keyingi misolda class atributining qiymati 'note' bo'lgan ikkita <span> elementi berilgan. Shuning uchun bu <span> elementlariga head qismidagi .note stillari beriladi."}
                redWords1={['<span>', 'class', "'note'", '.note', 'head']}
                code1={`<!DOCTYPE html>
<html>
<head>
<style>
.note {
  font-size: 120%;
  color: red;
}
</style>
</head>
<body>

<h1>My <span class="note">Muhim</span> Sarlavha</h1>
<p>Bu juda <span class="note">muhim</span> matn.</p>

</body>
</html>
`} path1={"try/225"} />
            <div className='note-content'>
                <p><strong>Maslahat:</strong> Har qanday HTML elementida class atributini ishlatish mumkin.</p>
                <p><strong>Eslatma:</strong> Class nomi katta-kichik harflarga sezgir!</p>
                <p><strong>Maslahat:</strong> CSS haqida ko'proq ma'lumotni<Link className='text-tutorial text-tutorial__link' to={'/css'}>CSS qo'llanmamizdan </Link> olishingiz mumkin.</p>
            </div>
            <hr />
            <TextContent header={"class sintaksisi"}
                text1={"class yaratish uchun nuqta(.) belgisi, undan keyin class nomi yoziladi. Keyin, jingalak qavslar ichida CSS xususiyatlari yoziladi{}:"}
                code1={`<!DOCTYPE html>
<html>
<head>
<style>
.city {
  background-color: tomato;
  color: white;
  padding: 10px;
}
</style>
</head>
<body>

<h2>class attributi</h2>
<p>"city" nomili class Css stilini qo'llash:</p>

<h2 class="city">London</h2>
<p>London - Angilya poytaxti</p>

<h2 class="city">Parij</h2>
<p>Parij - Fransiya poytaxti/p>

<h2 class="city">Tokiyo</h2>
<p>Tokiyo - Yaponiya poytaxti</p>

</body>
</html>`} path1={"try/226"} code1Text={"city nomli class"} />
            <hr />

            <TextContent header={"Bir nechta classlar"}
                text1={"HTML elementlariga bir vaqtning o'zida bir nechta classlar berilishi mumkin."}
                text2={"Buning uchun class nomlari bo'sh joy bilan ajratib yoziladi, masalan, <div class='city main'>. Elementga belgilangan barcha classlarga muvofiq stil beriladi."}
                text3={"Quyidagi misolda birinchi <h2> elementiga ham city classi, ham main classi berilganligi uchun CSS stillari ikkala classga mos ravishda o'zgaradi:"}
                redWords3={['<h2>', 'main', 'city']}
                code3={`<h2 class="city main">London</h2>
<h2 class="city">Parij</h2>
<h2 class="city">Tokiyo</h2>`} path3={"try/227"} />
            <hr />
            <TextContent header={"Turli xil element classlari"}
                text1={"HTML turli xil elementlariga ham bir xil class berish mumkin."}
                text2={"Quyidagi misolda <h2> va <p> elementlariga 'city' classi berilgani uchun bir xil stil beriladi:"}
                redWords2={['<h2>', '<p>', 'city']}
                code3={`<h2 class="city">Parij</h2>
<p class="city">Parij - Fransiya poytaxti.</p>`} path3={"try/228"} />
            <hr />
            <TextContent header={"JavaScriptda class atributidan foydalanish."}
                text1={"Elementlar class nomi JavaScriptda muayyan vazifalarni bajarish uchun ham ishlatilishi mumkin."}
                text2={"JavaScriptda muayyan class nomiga ega elementlarga getElementsByClassName() metodi yordamida kirishi mumkin:"}
                redWords2={['getElementsByClassName()']}
                code3={`<script>
function myFunction() {
  var x = document.getElementsByClassName("city");
  for (var i = 0; i < x.length; i++) {
    x[i].style.display = "none";
  }
}
</script>`} path3={"try/229"} />
            <div className='note-content'>
                <p>Yuqoridagi misoldagi kodni tushunmasangiz, tashvishlanmang.</p>
                <p><strong>Maslahat:</strong> JavaScript haqida ko'proq ma'lumotni<Link className='text-tutorial text-tutorial__link' to={'/html/html_scripts'}>HTML JavaScript </Link> yoki <Link className='text-tutorial text-tutorial__link' to={'/js'}>JavaScript mavzulari </Link>dan olishingiz mumkin.</p>
            </div>
            <TextContent header={"Bo'lim xulosasi"} />
            <ListText list={[{
                text: "Html elementi class atributiga bir va undan ortiq class nomlarini berish mumkin. ",
                redWords: ["class"]
            },
            {
                text: "Classlar Css va JavaScript yordamida muayyan elementni tanlash va elementga kirish uchun ishlatiladi."
            },
            {
                text: "Class atributidan istalgan HTML elementida foydalanish mumkin.",
                redWords: ["class"]
            },
            {
                text: "Class nomi katta-kichik harflarga sezgir",
            },
            {
                text: "Turli HTML elementlariga bir xil class berish mumkin.", redWords: ["class"]
            },
            {
                text: "JavaScriptda muayyan class nomiga ega elementlarga getElementsByClassName() metodi yordamida kirishi mumkin", redWords: ["getElementsByClassName()"]
            },]} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="mI2mXyXMdZc" />
            <NavButton back='/html/html_blocks' next='/html/html_id' />
        </article>)
}

export default ClassHtml;