import React from 'react';
import CodeContent from '../../../tryit/CodeContent';
import Collapsible from '../../pages/Collapsible';
import Tegs from '../../pages/tegs';
import W3Path from '../../W3Path';
import '../Info.css';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';

function HtmlBasic() {
  return (
    <article className='info-wrapper'>
      <W3Path path={"https://www.w3schools.com/html/html_basic.asp"}/>
      <h2 className='header-tutorial'>HTML asosiy misollar</h2>
      <NavButton back='/html/html_editor' next='/html/html_elements' />
      <hr />
      <h3 className='header-content'>HTML hujjatlari</h3>
      <p className='text-tutorial'>Barcha HTML hujjatlari amaldagi hujjat turi haqidagi deklaratsiyadan boshlanishi kerak: <Tegs tegname="!DOCTYPE html " />.</p>
      <p className='text-tutorial'>HTML hujjatining o'zi  <Tegs tegname="html " /> tegi bilan boshlanadi va  <Tegs tegname="/html " /> tegi bilan tugaydi.</p>
      <p className='text-tutorial'>HTML hujjatining ko'rinadigan qismi <Tegs tegname="body " /> va <Tegs tegname="/body " /> teglari orasida joylashgan bo'ladi.</p>
      <hr />
      <CodeContent props={`<!DOCTYPE html>
<html>
<head>
<title>Sahifa sarlavhasi</title>
</head>
<body>

<h1>Mening birinchi sarlavham</h1>
<p>Mening birinchi xatboshim.</p>

</body>
</html>
    `} pathname={`try/2`} />
      <hr />
      <h3 className='header-content'><Tegs tegname="!DOCTYPE " /> Deklaratsiyasi</h3>
      <p className='text-tutorial'><Tegs tegname="!DOCTYPE " /> deklaratsiyasi amaldagi hujjat turini ifodalaydi va brauzerlarga veb-sahifalarni to'g'ri ko'rsatishga yordam beradi.</p>
      <p className='text-tutorial'>U faqat bir marta, sahifaning yuqori qismida (har qanday HTML teglaridan oldin) yozilishi kerak.</p>
      <p className='text-tutorial'><Tegs tegname="!DOCTYPE " /> deklaratsiyasi katta/kichik harflarga sezgir emas. Barcha holatlar qabul qilinadi.</p>
      <p className='text-tutorial'><Tegs tegname="!DOCTYPE " />HTML5 uchun deklaratsiya :</p>
      <div className='code-content'>
        <div className='code-wrapper'>
          <Tegs tegname="!DOCTYPE html" />
        </div>
      </div>
      <hr/>
      <h3 className='header-content'>HTML sarlavhalari</h3>
      <p className='text-tutorial'>HTML sarlavhalari <Tegs tegname="h1 " />dan <Tegs tegname="h6 " />gacha teglar bilan belgilanadi .</p>
      <p className='text-tutorial'><Tegs tegname="h1 " />mantiqiy ma'nosi yuqoriroq sarlavhani belgilaydi. <Tegs tegname="h6 " />mantiqiy ma'nosi kamroq sarlavhani belgilaydi: </p>
      <CodeContent props={`<h1>Bu sarlavha 1</h1>
<h2>Bu sarlavha 2</h2>
<h3>Bu sarlavha 3</h3>
`} pathname={`try/3`}/>
<hr/>
<h3 className='header-content'>HTML xatboshi(paragraf)</h3>
<p className='text-tutorial'>HTML paragraflari <Tegs tegname="p " />teg bilan belgilanadi: </p>
<CodeContent props={`<p>Bu xatboshi</p>
<p>Bu boshqa xatboshi</p>
`} pathname={`try/4`}/>
<hr/>
<h3 className='header-content'>HTML havolalari(linklari)</h3>
<p className='text-tutorial'>HTML havolalari <Tegs tegname="a " />teg bilan belgilanadi: </p>
<CodeContent props={`<a href="http://self-study.uz/">a-bu havola</a>
`} pathname={`try/5`}/>
<p className='text-tutorial'>Havolaning manzili <span style={{color:'red',}}>href</span> atributda ko'rsatilgan.  </p>
<p className='text-tutorial'>Atributlar HTML elementlari haqida qo'shimcha ma'lumot berish uchun ishlatiladi.  </p>
<p className='text-tutorial'>Atributlar haqida keyingi bobda ko'proq bilib olasiz.</p>
<hr/>
<h3 className='header-content'>HTML rasmlar</h3>
<p className='text-tutorial'>HTML rasmlar <Tegs tegname="img " />teg bilan belgilanadi: </p>
<p className='text-tutorial'><span style={{color:'red',}}>( src)</span> - rasm nomi va joylashgan joyga yo'l ko'rsatiladi.  Agar brauzer yoki sahifani ochuvchi dastur rasmlarni ochish imkoniga ega bo'lmagan taqdirda ushbu <span style={{color:'red',}}>( alt)</span> atributidan so'ng kiritilgan matn aynan shu joyda rasm borligidan xabar beradi.  <span style={{color:'red',}}>width</span> va <span style={{color:'red',}}>height</span> atributlariga rasm o'lchamlari kiritiladi.</p>
<CodeContent props={`<img src="https://yt3.ggpht.com/JqhCR8X5zboindr5wD5jLOk0bX2gPX3dRHFSAK7sAwcqDF7SLZFFB2BsTEe6HN6_wEQSGHyq0g=s600-c-k-c0x00ffffff-no-rj-rp-mo" alt="VS Code" width="128" height="72">
`} pathname={`try/6`}/>
<hr/>
<h3 className='header-content'>HTML manba kodini ko'rish:
</h3>
<p className='text-tutorial'>HTML sahifasida sichqonchaning o'ng tugmasini bosing va  "View Page Source"  (Chromeda) yoki "View Source" (Edgeda) yoki boshqa brauzerlarda shunga o'xshash bandni tanlang(yoki 'ctrl+u'). Bu sahifaning HTML manba kodini o'z ichiga olgan oynani ochadi. </p>
<h3 className='header-content'>HTML elementini tekshirish:
</h3>
<p className='text-tutorial'>Elementni (yoki bo'sh maydonni) sichqonchaning o'ng tugmasi bilan bosing va qanday elementlardan iboratligini ko'rish uchun "Inspect" yoki "Inspect Element" ni tanlang (siz HTML va CSS-ni ko'rasiz). Bundan tashqari, ochilgan elementlar yoki stillar panelida HTML yoki CSSni tahrirlashingiz ham mumkin. </p>
<hr/>
<h3 className='header-content'>Figma.com saytiga registratsiya qilish:</h3>
<YoutubeEmbed embedId="8KiTm6fXqPQ" />
<p className='text-tutorial'>Quyidagi maket asosida web sahifasi yaratildi:</p>
      <a style={{color: 'var(--green)'}} href='https://www.figma.com/file/PHIIYSrcX6ghwb4rRF9S8I/SimpleCofeMaket?node-id=0%3A1' target={'_blank'} rel="noreferrer"> Web sahifa maketi</a>
<hr/>
<h3 className='header-content'>Dars videosi:</h3>
<YoutubeEmbed embedId="Iw9OzKdFQGc" />
      <Collapsible>
      <p className='text-tutorial'>Quyidagi maket web sahifasi yaratilsin:</p>
      <a href='https://www.figma.com/file/jnru903F0pfOhMuD7D5c3N/6-mavzu.-Uyga-vazifa?node-id=0%3A1' target={'_blank'} rel="noreferrer"> https://www.figma.com/file/jnru903F0pfOhMuD7D5c3N/6-mavzu.-Uyga-vazifa?node-id=0%3A1</a>
      </Collapsible>

      <NavButton back='/html/html_editor' next='/html/html_elements' />
    </article>
  )
}

export default HtmlBasic