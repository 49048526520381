import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import { Link } from 'react-router-dom';
import ListText from '../../pages/ListsText';
import Notes from '../../pages/Notes';
import Table from '../../pages/Table';
import NoteWhite from '../../pages/NoteWhite';


function Charset() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_charset.asp"} />
            <h2 className='header-tutorial'>HTML belgilar to'plami</h2>
            <NavButton back='/html/html_emoji' next='/html/html_urlen_code' />
            <hr />
            <p>Ba'zan klaviaturagi harflar, raqamlar va turli belgilardan tashqari boshqa belgilarni ham HTML sahifasida ishlatishimizga to'g'ri keladi. Ammo brauzer bu belgilarni tanimasligi mumkin.</p>
            <p> Sahifamizda foydalanilgan belgilar to'g'ri ko'rsatilishi uchun veb-brauzer qaysi belgilar to'plamidan foydalanishini bilishi kerak. Belgilar to'plamida belgilar soni chegaralangan, shuning uchun biz ishlatadigan belgi belgilar to'plami tarkibiga kiradimi, yo'qmi bilishimiz kerak.</p>
            <p>Belgilar to'plamlari ASCII dan UTF-8 gacha belgilar soni ko'payib borgan. Quyida belgilar to'plamlari bilan tanishamiz. Shuni eslatib o'tish kerakki, ular hozir ham ishlatiladi.</p>
            <hr />
            <TextContent header={"ASCII dan UTF-8 gacha"}
                text1={"ASCII belgilarni birinchi standart belgilar to'plami bo'lgan. ASCII Internetda ishlatilishi mumkin bo'lgan 128 xil belgi mavjud, bular: raqamlar (0-9), ingliz harflari (A-Z) va ba'zi maxsus belgilar ! $ + - ( ) @ < > kabi."}
                text2={"ISO-8859-1 HTML 4 uchun default belgilar to'plami edi. Bu belgilar to'plamidi 256 xil belgi qo'llab-quvvatlangan. HTML 4 ham UTF-8 ni qo'llab-quvvatlagan."}
                text3={"ANSI(Windows-1252) dastlabki Windows belgilar to'plami bo'lgan. ANSI ham ISO-8859-1 bilan bir xil, faqat ANSIda 32 ta qo'shimcha belgilar mavjud edi."}
                text4={"HTML5 ning o'ziga xosliklaridan biri dasturchilarga dunyodagi deyarli barcha belgilar va simvollarni qamrab oluvchi UTF-8 belgilar to'plamidan foydalanish imkonini berishidir!"} />
            <hr />
            <TextContent header={"HTML belgilar to'plami atributi"}
                text1={"HTML sahifani to'g'ri ko'rsatish uchun veb-brauzer sahifada ishlatiladigan belgilar to'plamini tanishi kerak."}
                text2={"Belgilar to'plamini <meta> teg yordamida e'lon qilinadi:"}
                redWords2={["<meta>"]}
                code2={`<meta charset="UTF-8">`} />
            <hr />
            <Table header={"Belgilar to'plami o'rtasidagi farqlar"} text={"Quyidagi jadvalda yuqorida tavsiflangan belgilar to'plami o'rtasidagi farqlar ko'rsatilgan:"} th={["Nomer", "ASCII", "ANSI", "8859", "UTF-8", "Tavsif"]} values={[
                { id: 0, first: "32", second: " ", third: " ", four: " ", five: " ", six: "bo'sh joy", },
                { id: 1, first: "33", second: "!", third: "!", four: "!", five: "!", six: "undov belgisi", },
                { id: 2, first: "34", second: '"', third: '"', four: '"', five: '"', six: "qo'shtirnoq", },
                { id: 3, first: "35", second: "#", third: "#", four: "#", five: "#", six: "panjara belgisi", },
                { id: 4, first: "36", second: "$", third: "$", four: "$", five: "$", six: "dollar belgisi", },
                { id: 5, first: "37", second: "%", third: "%", four: "%", five: "%", six: "foiz belgisi", },
                { id: 6, first: "38", second: "&", third: "&", four: "&", five: "&", six: "ampersand", },
                { id: 7, first: "39", second: "'", third: "'", four: "'", five: "'", six: "apostrof", },
                { id: 8, first: "40", second: "(", third: "(", four: "(", five: "(", six: "chap qavs", },
                { id: 9, first: "41", second: ")", third: ")", four: ")", five: ")", six: "o'ng qavs", },
                { id: 10, first: "42", second: "*", third: "*", four: "*", five: "*", six: "yulduzcha", },
                { id: 11, first: "43", second: "+", third: "+", four: "+", five: "+", six: "qo'shish belgisi", },
                { id: 12, first: "44", second: ",", third: ",", four: ",", five: ",", six: "vergul", },
                { id: 13, first: "45", second: "-", third: "-", four: "-", five: "-", six: "chiziqcha yoki ayiruv belgisi", },
                { id: 14, first: "46", second: ".", third: ".", four: ".", five: ".", six: "nuqta", },
                { id: 15, first: "47", second: "/", third: "/", four: "/", five: "/", six: "o'ng tomonga yotiq chiziq", },
                { id: 16, first: "48", second: "0", third: "0", four: "0", five: "0", six: "nol raqami", },
                { id: 17, first: "49", second: "1", third: "1", four: "1", five: "1", six: "bir raqami", },
                { id: 18, first: "50", second: "2", third: "2", four: "2", five: "2", six: "ikki raqami", },
                { id: 19, first: "51", second: "3", third: "3", four: "3", five: "3", six: "uch raqami", },
                { id: 20, first: "52", second: "4", third: "4", four: "4", five: "4", six: "to'rt raqami", },
                { id: 21, first: "53", second: "5", third: "5", four: "5", five: "5", six: "besh raqami", },
                { id: 22, first: "54", second: "6", third: "6", four: "6", five: "6", six: "olti raqami", },
                { id: 23, first: "55", second: "7", third: "7", four: "7", five: "7", six: "yetti raqami", },
                { id: 24, first: "56", second: "8", third: "8", four: "8", five: "8", six: "sakkiz raqami", },
                { id: 25, first: "57", second: "9", third: "9", four: "9", five: "9", six: "to'qqiz raqami", },
                { id: 26, first: "58", second: ":", third: ":", four: ":", five: ":", six: "ikki nuqta", },
                { id: 27, first: "59", second: ";", third: ";", four: ";", five: ";", six: "nuqta-vergul", },
                { id: 28, first: "60", second: "<", third: "<", four: "<", five: "<", six: "kichik belgisi", },
                { id: 29, first: "61", second: "=", third: "=", four: "=", five: "=", six: "tenglik belgisi", },
                { id: 30, first: "62", second: ">", third: ">", four: ">", five: ">", six: "katta belgisi", },
                { id: 31, first: "63", second: "?", third: "?", four: "?", five: "?", six: "so'roq belgisi", },
                { id: 32, first: "64", second: "@", third: "@", four: "@", five: "@", six: "kuchukcha belgisi", },
                { id: 33, first: "65", second: "A", third: "A", four: "A", five: "A", six: "Lotin katta A harfi", },
                { id: 34, first: "66", second: "B", third: "B", four: "B", five: "B", six: "Lotin katta B harfi", },
                { id: 35, first: "67", second: "C", third: "C", four: "C", five: "C", six: "Lotin katta C harfi", },
                { id: 36, first: "68", second: "D", third: "D", four: "D", five: "D", six: "Lotin katta D harfi", },
                { id: 37, first: "69", second: "E", third: "E", four: "E", five: "E", six: "Lotin katta E harfi", },
                { id: 38, first: "70", second: "F", third: "F", four: "F", five: "F", six: "Lotin katta F harfi", },
                { id: 39, first: "71", second: "G", third: "G", four: "G", five: "G", six: "Lotin katta G harfi", },
                { id: 40, first: "72", second: "H", third: "H", four: "H", five: "H", six: "Lotin katta H harfi", },
                { id: 41, first: "73", second: "I", third: "I", four: "I", five: "I", six: "Lotin katta I harfi", },
                { id: 42, first: "74", second: "J", third: "J", four: "J", five: "J", six: "Lotin katta J harfi", },
                { id: 43, first: "75", second: "K", third: "K", four: "K", five: "K", six: "Lotin katta K harfi", },
                { id: 44, first: "76", second: "L", third: "L", four: "L", five: "L", six: "Lotin katta L harfi", },
                { id: 45, first: "77", second: "M", third: "M", four: "M", five: "M", six: "Lotin katta M harfi", },
                { id: 46, first: "78", second: "N", third: "N", four: "N", five: "N", six: "Lotin katta N harfi", },
                { id: 47, first: "79", second: "O", third: "O", four: "O", five: "O", six: "Lotin katta O harfi", },
                { id: 48, first: "80", second: "P", third: "P", four: "P", five: "P", six: "Lotin katta P harfi", },
                { id: 49, first: "81", second: "Q", third: "Q", four: "Q", five: "Q", six: "Lotin katta Q harfi", },
                { id: 50, first: "82", second: "R", third: "R", four: "R", five: "R", six: "Lotin katta R harfi", },
                { id: 51, first: "83", second: "S", third: "S", four: "S", five: "S", six: "Lotin katta S harfi", },
                { id: 52, first: "84", second: "T", third: "T", four: "T", five: "T", six: "Lotin katta T harfi", },
                { id: 53, first: "85", second: "U", third: "U", four: "U", five: "U", six: "Lotin katta U harfi", },
                { id: 54, first: "86", second: "V", third: "V", four: "V", five: "V", six: "Lotin katta V harfi", },
                { id: 55, first: "87", second: "W", third: "W", four: "W", five: "W", six: "Lotin katta W harfi", },
                { id: 56, first: "88", second: "X", third: "X", four: "X", five: "X", six: "Lotin katta  X harfi", },
                { id: 57, first: "89", second: "Y", third: "Y", four: "Y", five: "Y", six: "Lotin katta Y harfi", },
                { id: 58, first: "90", second: "Z", third: "Z", four: "Z", five: "Z", six: "Lotin katta Z harfi", },
                { id: 59, first: "91", second: "[", third: "[", four: "[", five: "[", six: "chap kvadrat qavs", },
                { id: 60, first: "92", second: "\\", third: "\\", four: "\\", five: "\\", six: "chap tomonga yotiq chiziq", },
                { id: 61, first: "93", second: "]", third: "]", four: "]", five: "]", six: "o'ng kvadrat qavs", },
                { id: 62, first: "94", second: "^", third: "^", four: "^", five: "^", six: "sirkumfleks aksent", },
                { id: 63, first: "95", second: "_", third: "_", four: "_", five: "_", six: "tag chiziq", },
                { id: 64, first: "96", second: "`", third: "`", four: "`", five: "`", six: "urg'u", },
                { id: 65, first: "97", second: "a", third: "a", four: "a", five: "a", six: "Lotin kichik a harfi", },
                { id: 66, first: "98", second: "b", third: "b", four: "b", five: "b", six: "Lotin kichik b harfi", },
                { id: 67, first: "99", second: "c", third: "c", four: "c", five: "c", six: "Lotin kichik c harfi", },
                { id: 68, first: "100", second: "d", third: "d", four: "d", five: "d", six: "Lotin kichik d harfi", },
                { id: 69, first: "101", second: "e", third: "e", four: "e", five: "e", six: "Lotin kichik e harfi", },
                { id: 70, first: "102", second: "f", third: "f", four: "f", five: "f", six: "Lotin kichik f harfi", },
                { id: 71, first: "103", second: "g", third: "g", four: "g", five: "g", six: "Lotin kichik g harfi", },
                { id: 72, first: "104", second: "h", third: "h", four: "h", five: "h", six: "Lotin kichik h harfi", },
                { id: 73, first: "105", second: "i", third: "i", four: "i", five: "i", six: "Lotin kichik i harfi", },
                { id: 74, first: "106", second: "j", third: "j", four: "j", five: "j", six: "Lotin kichik j harfi", },
                { id: 75, first: "107", second: "k", third: "k", four: "k", five: "k", six: "Lotin kichik k harfi", },
                { id: 76, first: "108", second: "l", third: "l", four: "l", five: "l", six: "Lotin kichik l harfi", },
                { id: 77, first: "109", second: "m", third: "m", four: "m", five: "m", six: "Lotin kichik m harfi", },
                { id: 78, first: "110", second: "n", third: "n", four: "n", five: "n", six: "Lotin kichik n harfi", },
                { id: 79, first: "111", second: "o", third: "o", four: "o", five: "o", six: "Lotin kichik o harfi", },
                { id: 80, first: "112", second: "p", third: "p", four: "p", five: "p", six: "Lotin kichik p harfi", },
                { id: 81, first: "113", second: "q", third: "q", four: "q", five: "q", six: "Lotin kichik q harfi", },
                { id: 82, first: "114", second: "r", third: "r", four: "r", five: "r", six: "Lotin kichik r harfi", },
                { id: 83, first: "115", second: "s", third: "s", four: "s", five: "s", six: "Lotin kichik s harfi", },
                { id: 84, first: "116", second: "t", third: "t", four: "t", five: "t", six: "Lotin kichik t harfi", },
                { id: 85, first: "117", second: "u", third: "u", four: "u", five: "u", six: "Lotin kichik u harfi", },
                { id: 86, first: "118", second: "v", third: "v", four: "v", five: "v", six: "Lotin kichik v harfi", },
                { id: 87, first: "119", second: "w", third: "w", four: "w", five: "w", six: "Lotin kichik w harfi", },
                { id: 88, first: "120", second: "x", third: "x", four: "x", five: "x", six: "Lotin kichik x harfi", },
                { id: 89, first: "121", second: "y", third: "y", four: "y", five: "y", six: "Lotin kichik y harfi", },
                { id: 90, first: "122", second: "z", third: "z", four: "z", five: "z", six: "Lotin kichik z harfi", },
                { id: 91, first: "123", second: "{", third: "{", four: "{", five: "{", six: "chap jingalak qavs", },
                { id: 91, first: "124", second: "|", third: "|", four: "|", five: "|", six: "vertikal chiziq", },
                { id: 93, first: "125", second: "}", third: "}", four: "}", five: "}", six: "o'ng jingalak qavs", },
                { id: 94, first: "126", second: "~", third: "~", four: "~", five: "~", six: "tilda", },
                { id: 95, first: "127", second: "DEL", third: "\xa0", four: "\xa0", five: "\xa0", six: "\xa0", },
                { id: 96, first: "128", second: "\xa0", third: "€", four: "\xa0", five: "\xa0", six: "evro belgisi", },
                { id: 97, first: "129", second: "\xa0", third: "", four: "", five: "", six: "ISHLATILMAGAN", },
                { id: 98, first: "130", second: "\xa0", third: "‚", four: "\xa0", five: "\xa0", six: "bittalik pastki qo'shtirnoq belgisi", },
                { id: 99, first: "131", second: "\xa0", third: "ƒ", four: "\xa0", five: "\xa0", six: "Qayrilgan lotincha kichik f harfi", },
                { id: 100, first: "132", second: "\xa0", third: "„", four: "\xa0", five: "\xa0", six: "pastki qo'shtirnoq belgisi", },
                { id: 101, first: "133", second: "\xa0", third: "…", four: "\xa0", five: "\xa0", six: "uch nuqta", },
                { id: 102, first: "134", second: "\xa0", third: "†", four: "\xa0", five: "\xa0", six: "xanjar", },
                { id: 103, first: "135", second: "\xa0", third: "‡", four: "\xa0", five: "\xa0", six: "qo'sh xanjar", },
                { id: 104, first: "136", second: "\xa0", third: "ˆ", four: "\xa0", five: "\xa0", six: "o'zgartiruvchi harf sirkumfleks urg'u", },
                { id: 105, first: "137", second: "\xa0", third: "‰", four: "\xa0", five: "\xa0", six: "promille belgisi", },
                { id: 106, first: "138", second: "\xa0", third: "Š", four: "\xa0", five: "\xa0", six: "Karonli lotin katta S harfi", },
                { id: 107, first: "139", second: "\xa0", third: "‹", four: "\xa0", five: "\xa0", six: "bitta chapga qaragan burchakli tirnoq belgisi", },
                { id: 108, first: "140", second: "\xa0", third: "Œ", four: "\xa0", five: "\xa0", six: "Katta lotin OE harflar ligaturasi", },
                { id: 109, first: "141", second: "\xa0", third: "", four: "", five: "", six: "Ishlatilmaydi", },
                { id: 110, first: "142", second: "\xa0", third: "Ž", four: "\xa0", five: "\xa0", six: "Karonli katta lotin Z harfi", },
                { id: 111, first: "143", second: "\xa0", third: "", four: "", five: "", six: "Ishlatilmaydi", },
                { id: 112, first: "144", second: "\xa0", third: "", four: "", five: "", six: "Ishlatilmaydi", },
                { id: 113, first: "145", second: "\xa0", third: "‘", four: "\xa0", five: "\xa0", six: "chap bittali qo'shtirnoq belgisi", },
                { id: 114, first: "146", second: "\xa0", third: "’", four: "\xa0", five: "\xa0", six: "o'ngdagi bittali qo'shtirnoq belgisi", },
                { id: 115, first: "147", second: "\xa0", third: "“", four: "\xa0", five: "\xa0", six: "chap qo'shtirnoq belgisi", },
                { id: 116, first: "148", second: "\xa0", third: "”", four: "\xa0", five: "\xa0", six: "o'ng qo'shtirnoq belgisi", },
                { id: 117, first: "149", second: "\xa0", third: "•", four: "\xa0", five: "\xa0", six: "doiracha belgisi", },
                { id: 118, first: "150", second: "\xa0", third: "–", four: "\xa0", five: "\xa0", six: "chiziqcha", },
                { id: 119, first: "151", second: "\xa0", third: "—", four: "\xa0", five: "\xa0", six: "tire", },
                { id: 120, first: "152", second: "\xa0", third: "˜", four: "\xa0", five: "\xa0", six: "kichik tilda", },
                { id: 121, first: "153", second: "\xa0", third: "™", four: "\xa0", five: "\xa0", six: "savdo belgisi belgisi", },
                { id: 122, first: "154", second: "\xa0", third: "š", four: "\xa0", five: "\xa0", six: "Karonli kichik lotin s harfi", },
                { id: 123, first: "155", second: "\xa0", third: "›", four: "\xa0", five: "\xa0", six: "bitta o'ngga qaragan burchakli tirnoq belgisi", },
                { id: 124, first: "156", second: "\xa0", third: "œ", four: "\xa0", five: "\xa0", six: "kichik lotin OE harflar ligaturasi", },
                { id: 215, first: "157", second: "\xa0", third: "", four: "", five: "", six: "Ishlatilmaydi", },
                { id: 126, first: "158", second: "\xa0", third: "ž", four: "\xa0", five: "\xa0", six: "Karonli kichik lotin z harfi", },
                { id: 127, first: "159", second: "\xa0", third: "Ÿ", four: "\xa0", five: "\xa0", six: "Diyerezisli katta lotin Y harfi", },
                { id: 128, first: "160", second: "\xa0", third: "\xa0", four: "\xa0", five: "\xa0", six: "ajratmaydigan bo'sh joy", },
                { id: 129, first: "161", second: "\xa0", third: "¡", four: "¡", five: "¡", six: "teskari undov belgisi", },
                { id: 130, first: "162", second: "\xa0", third: "¢", four: "¢", five: "¢", six: "sent belgisi", },
                { id: 131, first: "163", second: "\xa0", third: "£", four: "£", five: "£", six: "funt belgisi", },
                { id: 132, first: "164", second: "\xa0", third: "¤", four: "¤", five: "¤", six: "valyuta belgisi", },
                { id: 133, first: "165", second: "\xa0", third: "¥", four: "¥", five: "¥", six: "yen belgisi", },
                { id: 134, first: "166", second: "\xa0", third: "¦", four: "¦", five: "¦", six: "bo'lingan chiziq", },
                { id: 135, first: "167", second: "\xa0", third: "§", four: "§", five: "§", six: "bo'lim belgisi", },
                { id: 136, first: "168", second: "\xa0", third: "¨", four: "¨", five: "¨", six: "diaerez", },
                { id: 137, first: "169", second: "\xa0", third: "©", four: "©", five: "©", six: "mualliflik huquqi belgisi", },
                { id: 138, first: "170", second: "\xa0", third: "ª", four: "ª", five: "ª", six: "feminine ordinal indicator", },
                { id: 139, first: "171", second: "\xa0", third: "«", four: "«", five: "«", six: "chapga qaragan ikki burchakli tirnoq belgisi", },
                { id: 140, first: "172", second: "\xa0", third: "¬", four: "¬", five: "¬", six: "Inkor qilish", },
                { id: 141, first: "173", second: "\xa0", third: "\0", four: "\0", five: "\0", six: "yumshoq chiziqcha", },
                { id: 142, first: "174", second: "\xa0", third: "®", four: "®", five: "®", six: "ro'yxatdan o'tganlik belgi", },
                { id: 143, first: "175", second: "\xa0", third: "¯", four: "¯", five: "¯", six: "Tepa chiziq", },
                { id: 144, first: "176", second: "\xa0", third: "°", four: "°", five: "°", six: "daraja belgisi", },
                { id: 145, first: "177", second: "\xa0", third: "±", four: "±", five: "±", six: "plus-minus belgisi", },
                { id: 146, first: "178", second: "\xa0", third: "²", four: "²", five: "²", six: "kvadrat belgisi", },
                { id: 147, first: "179", second: "\xa0", third: "³", four: "³", five: "³", six: "kub belgisi", },
                { id: 148, first: "180", second: "\xa0", third: "´", four: "´", five: "´", six: "o'tkir urg'u", },
                { id: 149, first: "181", second: "\xa0", third: "µ", four: "µ", five: "µ", six: "mikro belgisi", },
                { id: 150, first: "182", second: "\xa0", third: "¶", four: "¶", five: "¶", six: "yangi paragraf belgisi", },
                { id: 151, first: "183", second: "\xa0", third: "·", four: "·", five: "·", six: "o'rta nuqta", },
                { id: 152, first: "184", second: "\xa0", third: "¸", four: "¸", five: "¸", six: "sedilla", },
                { id: 153, first: "185", second: "\xa0", third: "¹", four: "¹", five: "¹", six: "darajadagi 1", },
                { id: 154, first: "186", second: "\xa0", third: "º", four: "º", five: "º", six: "erkak tartib ko'rsatkichi", },
                { id: 155, first: "187", second: "\xa0", third: "»", four: "»", five: "»", six: "o'ngga qaragan ikki burchakli tirnoq belgisi", },
                { id: 156, first: "188", second: "\xa0", third: "¼", four: "¼", five: "¼", six: "chorakning oddiy kasr belgisi", },
                { id: 157, first: "189", second: "\xa0", third: "½", four: "½", five: "½", six: "yarimning oddiy kasr belgisi", },
                { id: 158, first: "190", second: "\xa0", third: "¾", four: "¾", five: "¾", six: "to'rtdan uchning oddiy kasr belgisi", },
                { id: 159, first: "191", second: "\xa0", third: "¿", four: "¿", five: "¿", six: "teskari savol belgisi", },
                { id: 160, first: "192", second: "\xa0", third: "À", four: "À", five: "À", six: "naqshli lotin bosh harfi A", },
                { id: 161, first: "193", second: "\xa0", third: "Á", four: "Á", five: "Á", six: "urg'uli lotin katta A harfi", },
                { id: 162, first: "194", second: "\xa0", third: "Â", four: "Â", five: "Â", six: "sirkumfleks lotin katta A harfi", },
                { id: 163, first: "195", second: "\xa0", third: "Ã", four: "Ã", five: "Ã", six: "tildali lotin katta A harfi", },
                { id: 164, first: "196", second: "\xa0", third: "Ä", four: "Ä", five: "Ä", six: "diaerezli lotin katta A harfi", },
                { id: 165, first: "197", second: "\xa0", third: "Å", four: "Å", five: "Å", six: "tepasida aylanali lotin katta A harfi", },
                { id: 166, first: "198", second: "\xa0", third: "Æ", four: "Æ", five: "Æ", six: "lotin katta AE harflari", },
                { id: 167, first: "199", second: "\xa0", third: "Ç", four: "Ç", five: "Ç", six: "sedillali lotin katta A harfi", },
                { id: 168, first: "200", second: "\xa0", third: "È", four: "È", five: "È", six: "naqshli lotin katta E harfi", },
                { id: 169, first: "201", second: "\xa0", third: "É", four: "É", five: "É", six: "o'tkir urg'u lotin katta E harfi", },
                { id: 170, first: "202", second: "\xa0", third: "Ê", four: "Ê", five: "Ê", six: "sirkumfleks lotin katta E harfi", },
                { id: 171, first: "203", second: "\xa0", third: "Ë", four: "Ë", five: "Ë", six: "diaerezli lotin katta E harfi", },
                { id: 172, first: "204", second: "\xa0", third: "Ì", four: "Ì", five: "Ì", six: "naqshli lotin katta I harfi", },
                { id: 173, first: "205", second: "\xa0", third: "Í", four: "Í", five: "Í", six: "o'tkir urg'u lotin katta I harfi", },
                { id: 174, first: "206", second: "\xa0", third: "Î", four: "Î", five: "Î", six: "sirkumfleks lotin katta I harfi", },
                { id: 175, first: "207", second: "\xa0", third: "Ï", four: "Ï", five: "Ï", six: "diaerezli lotin katta I harfi", },
                { id: 176, first: "208", second: "\xa0", third: "Ð", four: "Ð", five: "Ð", six: "Lotin bosh harfi Eth", },
                { id: 177, first: "209", second: "\xa0", third: "Ñ", four: "Ñ", five: "Ñ", six: "tildali lotin katta N harfi", },
                { id: 178, first: "210", second: "\xa0", third: "Ò", four: "Ò", five: "Ò", six: "naqshli lotin katta O harfi", },
                { id: 179, first: "211", second: "\xa0", third: "Ó", four: "Ó", five: "Ó", six: "o'tkir urg'u lotin katta O harfi", },
                { id: 180, first: "212", second: "\xa0", third: "Ô", four: "Ô", five: "Ô", six: "sirkumfleks lotin katta O harfi", },
                { id: 181, first: "213", second: "\xa0", third: "Õ", four: "Õ", five: "Õ", six: "tildali lotin katta O harfi", },
                { id: 182, first: "214", second: "\xa0", third: "Ö", four: "Ö", five: "Ö", six: "diaerezli lotin katta O harfi", },
                { id: 183, first: "215", second: "\xa0", third: "×", four: "×", five: "×", six: "ko'paytirish belgisi", },
                { id: 184, first: "216", second: "\xa0", third: "Ø ", four: "Ø  ", five: "Ø", six: "chizilgan lotin katta O harfi", },
                { id: 185, first: "217", second: "\xa0", third: "Ù", four: "Ù", five: "Ù", six: "naqshli lotin katta U harfi", },
                { id: 186, first: "218", second: "\xa0", third: "Ú", four: "Ú", five: "Ú", six: "o'tkir urg'u lotin katta U harfi", },
                { id: 187, first: "219", second: "\xa0", third: "Û", four: "Û", five: "Û", six: "sirkumfleks lotin katta U harfi", },
                { id: 188, first: "220", second: "\xa0", third: "Ü", four: "Ü", five: "Ü", six: "diaerezli lotin katta U harfi", },
                { id: 189, first: "221", second: "\xa0", third: "Ý", four: "Ý", five: "Ý", six: "o'tkir urg'u lotin katta Y harfi", },
                { id: 190, first: "222", second: "\xa0", third: "Þ", four: "Þ", five: "Þ", six: "Lotin bosh harfi Thorn", },
                { id: 191, first: "223", second: "\xa0", third: "ß", four: "ß", five: "ß", six: "Lotin kichik harfi keskin s", },
                { id: 191, first: "224", second: "\xa0", third: "à", four: "à", five: "à", six: "naqshli lotin kichik a harfi", },
                { id: 193, first: "225", second: "\xa0", third: "á", four: "á", five: "á", six: "o'tkir urg'u lotin kichik a harfi", },
                { id: 194, first: "226", second: "\xa0", third: "â", four: "â", five: "â", six: "sirkumfleks lotin kichik a harfi", },
                { id: 195, first: "227", second: "\xa0", third: "ã", four: "ã", five: "ã", six: "tildali lotin kichik a harfi", },
                { id: 196, first: "228", second: "\xa0", third: "ä", four: "ä", five: "ä", six: "diaerezli lotin kichik a harfi", },
                { id: 197, first: "229", second: "\xa0", third: "å", four: "å", five: "å", six: "tepasida aylanali lotin kichik a harfi", },
                { id: 198, first: "230", second: "\xa0", third: "æ", four: "æ", five: "æ", six: "Lotin kichik ae harfi", },
                { id: 199, first: "231", second: "\xa0", third: "ç", four: "ç", five: "ç", six: "Sedillali lotincha kichik c harfi", },
                { id: 200, first: "232", second: "\xa0", third: "è", four: "è", five: "è", six: "naqshli lotin kichik e harfi", },
                { id: 201, first: "233", second: "\xa0", third: "é", four: "é", five: "é", six: "o'tkir urg'u lotin kichik e harfi", },
                { id: 202, first: "234", second: "\xa0", third: "ê", four: "ê", five: "ê", six: "sirkumfleks lotin kichik e harfi", },
                { id: 203, first: "235", second: "\xa0", third: "ë", four: "ë", five: "ë", six: "diaerezli lotin kichik e harfi", },
                { id: 204, first: "236", second: "\xa0", third: "ì", four: "ì", five: "ì", six: "naqshli lotin kichik i harfi", },
                { id: 205, first: "237", second: "\xa0", third: "í", four: "í", five: "í", six: "o'tkir urg'u lotin kichik i harfi", },
                { id: 206, first: "238", second: "\xa0", third: "î", four: "î", five: "î", six: "sirkumfleks lotin kichik i harfi", },
                { id: 207, first: "239", second: "\xa0", third: "ï", four: "ï", five: "ï", six: "diaerezli lotin kichik i harfi", },
                { id: 208, first: "240", second: "\xa0", third: "ð", four: "ð", five: "ð", six: "Lotin kichik harfi et", },
                { id: 209, first: "241", second: "\xa0", third: "ñ", four: "ñ", five: "ñ", six: "tildali lotin kichik n harfi", },
                { id: 210, first: "242", second: "\xa0", third: "ò", four: "ò", five: "ò", six: "naqshli lotin kichik o harfi", },
                { id: 211, first: "243", second: "\xa0", third: "ó", four: "ó", five: "ó", six: "o'tkir urg'u lotin kichik o harfi", },
                { id: 212, first: "244", second: "\xa0", third: "ô", four: "ô", five: "ô", six: "sirkumfleks lotin kichik o harfi", },
                { id: 213, first: "245", second: "\xa0", third: "õ", four: "õ", five: "õ", six: "tildali lotin kichik o harfi", },
                { id: 214, first: "246", second: "\xa0", third: "ö", four: "ö", five: "ö", six: "diaerezli lotin kichik o harfi", },
                { id: 215, first: "247", second: "\xa0", third: "÷", four: "÷", five: "÷", six: "bo'linish belgisi", },
                { id: 216, first: "248", second: "\xa0", third: "ø", four: "ø", five: "ø", six: "chizilgan lotin kichik o harfi", },
                { id: 217, first: "249", second: "\xa0", third: "ù", four: "ù", five: "ù", six: "naqshli lotin kichik u harfi", },
                { id: 218, first: "250", second: "\xa0", third: "ú", four: "ú", five: "ú", six: "o'tkir urg'u lotin kichik u harfi", },
                { id: 219, first: "251", second: "\xa0", third: "û", four: "û", five: "û", six: "sirkumfleks lotin kichik u harfi", },
                { id: 220, first: "252", second: "\xa0", third: "ü", four: "ü", five: "ü", six: "diaerezli lotin kichik u harfi", },
                { id: 221, first: "253", second: "\xa0", third: "ý", four: "ý", five: "ý", six: "o'tkir urg'u lotin kichik y harfi", },
                { id: 222, first: "254", second: "\xa0", third: "þ", four: "þ", five: "þ", six: "Lotincha kichik harf  thorn", },
                { id: 223, first: "255", second: "\xa0", third: "ÿ", four: "ÿ", five: "ÿ", six: "diaerezli lotin kichik y harfi", },

            ]} />
            <hr />
            <TextContent header={"ASCII belgilar to'plami"}
                text1={"ASCII belgilar to'plami boshqaruv belgilari uchun 0 dan 31 gacha(va 127 ham) qiymatlaridan foydalanadi."}
                text2={"ASCII belgilar to'plami 32 dan 126 gacha bo'lgan qiymatlar esa harflar, raqamlar va belgilar uchun foydalaniladi."}
                text3={"ASCII belgilar to'plami 128 dan 255 gacha bo'lgan qiymatlari mavjud emas."} />
            <hr />
            <TextContent header={"ANSI belgilar to'plami (Windows-1252)"}
                text1={"ANSI belgilar to'plami 0 dan 127 gacha bo'lgan qiymatlar uchun ASCII belgilar to'plami bilan bir xil belgilarga ega."}
                text2={"ANSI belgilar to'plami 128 dan 159 gacha bo'lgan qiymatlar uchun xususiy belgilar to'plamiga ega."}
                text3={"ANSI belgilar to'plami 160 dan 255 gacha bo'lgan qiymatlar uchun UTF-8 belgilar to'plami bilan bir xil belgilarga ega."} />
            <hr />
            <TextContent header={"ISO-8859-1 belgilar to'plami"}
                text1={"ISO-8859-1 belgilar to'plami 0 dan 127 gacha bo'lgan qiymatlar uchun ASCII bilan bir xil belgilarga ega."}
                text2={"ISO-8859-1 belgilar to'plami 128 dan 159 gacha bo'lgan qiymatlarni ishlatmaydi."}
                text3={"ISO-8859-1 belgilar to'plami 160 dan 255 gacha bo'lgan qiymatlar uchun UTF-8 bilan bir xil belgilarga ega."} />
            <hr />
            <TextContent header={"UTF-8 belgilar to'plami"}
                text1={"UTF-8 belgilar to'plami 0 dan 127 gacha bo'lgan qiymatlar uchun ASCII belgilar to'plami bilan bir xil belgilarga ega."}
                text2={"UTF-8 belgilar to'plami belgilar to'plami 128 dan 159 gacha bo'lgan qiymatlarni ishlatmaydi. "}
                text3={"UTF-8 belgilar to'plami 160 dan 255 gacha bo'lgan qiymatlar uchun ANSI belgilar to'plami va 8859-1 belgilar to'plami bilan bir xil belgilarga ega."}
                text4={"UTF-8 belgilar to'plamida 256 qiymatidan keyin 10 000 dan ortiq turli belgilar mavjud."} />
            <NoteWhite text1={"UTF-8 belgilar to'plamidagi ba'zi emoji belgilari"} path={"/charsets/ref_emoji"} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="vC0Myynq3gs" />
            <NavButton back='/html/html_emoji' next='/html/html_urlen_code' />
        </div>)
}

export default Charset;