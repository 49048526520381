
const AttrLists = [
    { id: 0, title: "accept", path: "/tags/att_accept", belong: ["	<input>"], value: "", meaning: "Server qabul qiladigan fayllar turlarini belgilaydi (faqat type='file' uchun)" },
    { id: 1, title: "accept-charset", path: "", belong: "", value: "", meaning: "" },
    { id: 2, title: "accesskey", path: "", belong: "", value: "", meaning: "" },
    { id: 3, title: "action", path: "", belong: "", value: "", meaning: "" },
    { id: 4, title: "align", path: "", belong: "", value: "", meaning: "" },
    { id: 5, title: "alt", path: "/tags/att_alt", belong: ['<area>', '<img>', '<input>'], value: ["matn"], meaning: "Asl element ko'rsatilmaganda muqobil matnni belgilaydi" },
    { id: 6, title: "async", path: "", belong: "", value: "", meaning: "" },
    { id: 7, title: "autocomplete", path: "", belong: "", value: "", meaning: "" },
    { id: 8, title: "autofocus", path: "", belong: "", value: "", meaning: "" },
    { id: 9, title: "autoplay", path: "", belong: "", value: "", meaning: "" },
    { id: 10, title: "bgcolor", path: "", belong: "", value: "", meaning: "" },
    { id: 11, title: "border", path: "", belong: "", value: "", meaning: "" },
    { id: 12, title: "charset", path: "", belong: "", value: "", meaning: "" },
    { id: 13, title: "checked", path: "", belong: "", value: "", meaning: "" },
    { id: 14, title: "cite", path: "", belong: "", value: "", meaning: "" },
    { id: 15, title: "class", path: "", belong: "", value: "", meaning: "" },
    { id: 16, title: "color", path: "", belong: "", value: "", meaning: "" },
    { id: 17, title: "cols", path: "", belong: "", value: "", meaning: "" },
    { id: 18, title: "colspan", path: "/tags/att_th_colspan", belong: ['<td>', '<th>'], value: ["son"], meaning: "Sarlavha katagiga joylashishi kerak bo'lgan ustunlar sonini belgilaydi" },
    { id: 19, title: "content", path: "", belong: "", value: "", meaning: "" },
    { id: 20, title: "contenteditable", path: "", belong: "", value: "", meaning: "" },
    { id: 21, title: "controls", path: "", belong: "", value: [""], meaning: "" },
    { id: 22, title: "coords", path: "/tags/att_area_coords", belong: ["<area>"], value: ["koordinatalar"], meaning: "Hududning koordinatalarini belgilaydi" },
    { id: 23, title: "data", path: "", belong: "", value: "", meaning: "" },
    { id: 24, title: "data-*", path: "", belong: "", value: "", meaning: "" },
    { id: 25, title: "datetime", path: "", belong: "", value: "", meaning: "" },
    { id: 26, title: "default", path: "", belong: "", value: "", meaning: "" },
    { id: 27, title: "defer", path: "", belong: "", value: "", meaning: "" },
    { id: 28, title: "dir", path: "", belong: "", value: "", meaning: "" },
    { id: 29, title: "dirname", path: "", belong: "", value: "", meaning: "" },
    { id: 30, title: "disabled", path: "", belong: "", value: "", meaning: "" },
    { id: 31, title: "download", path: "/tags/att_download", belong: ["<a>", "<area>"], value: ["Fayl nomi",], meaning: "Foydalanuvchi giperhavolani bosganida faylni yuklab olish amalga oshiriladi." },
    { id: 32, title: "draggable", path: "", belong: "", value: "", meaning: "" },
    { id: 33, title: "enctype", path: "", belong: "", value: "", meaning: "" },
    { id: 34, title: "for", path: "", belong: "", value: "", meaning: "" },
    { id: 35, title: "form", path: "", belong: "", value: "", meaning: "" },
    { id: 36, title: "formaction", path: "", belong: "", value: "", meaning: "" },
    { id: 37, title: "headers", path: "/tags/att_th_headers", belong: ['<td>', '<th>'], value: ["header_id"], meaning: "Ustun bog'langan bir yoki bir nechta sarlavha ustunlarini belgilaydi" },
    { id: 38, title: "height", path: "/tags/att_img_height", belong: ['<canvas>', '<embed>', '<iframe>', '<img>', '<input>', '<object>', '<video>'], value: ["pikselda"], meaning: "Rasm balandligini belgilaydi" },
    { id: 39, title: "hidden", path: "", belong: "", value: "", meaning: "" },
    { id: 40, title: "high", path: "", belong: [''], value: [""], meaning: "" },
    { id: 41, title: "href", path: "/tags/att_link_href", belong: ["<a>", "<area>", "<base>", "<link>"], value: ["URL"], meaning: "Biriktirilgan hujjat manzilini belgilaydi." },
    { id: 42, title: "hreflang", path: "/tags/att_link_hreflang", belong: ["<a>", "<area>", "<link>"], value: ["language_code"], meaning: "Biriktirilgan hujjat matn tilini belgilayd" },
    { id: 43, title: "http-equiv", path: "", belong: "", value: "", meaning: "" },
    { id: 44, title: "id", path: "", belong: "", value: "", meaning: "" },
    { id: 45, title: "ismap", path: "/tags/att_ismap", belong: ["<img>"], value: ["ismap"], meaning: "Rasmni server tomonidagi rasm xaritasi sifatida belgilaydi" },
    { id: 46, title: "kind", path: "", belong: "", value: "", meaning: "" },
    { id: 47, title: "label", path: "", belong: "", value: "", meaning: "" },
    { id: 48, title: "lang", path: "", belong: "", value: "", meaning: "" },
    { id: 49, title: "list", path: "", belong: "", value: "", meaning: "" },
    { id: 50, title: "loop", path: "", belong: "", value: "", meaning: "" },
    { id: 51, title: "low", path: "", belong: "", value: "", meaning: "" },
    { id: 52, title: "max", path: "", belong: "", value: "", meaning: "" },
    { id: 53, title: "maxlength", path: "", belong: "", value: "", meaning: "" },
    { id: 54, title: "media", path: "/tags/att_style_media", belong: ["<a>", "<area>", "<link>", "<source>", "<style>"], value: ["media_query"], meaning: "Hujjatning turli qurilamalardagi ko'rinishini optimallashtish" },
    { id: 55, title: "method", path: "", belong: "", value: "", meaning: "" },
    { id: 56, title: "min", path: "", belong: "", value: "", meaning: "" },
    { id: 57, title: "multiple", path: "", belong: "", value: "", meaning: "" },
    { id: 58, title: "muted", path: "", belong: "", value: "", meaning: "" },
    { id: 59, title: "name", path: "/tags/att_map_name", belong: ['<button>', '<fieldset>', '<form>', '<iframe>', '<input>', '<map>', '<meta>', '<object>', '<output>', '<param>', '<select>', '<textarea>'], value: ["mapname"], meaning: "Majburiy. Rasm xaritasining nomini belgilaydi" },
    { id: 60, title: "novalidate", path: "", belong: "", value: "", meaning: "" },
    { id: 61, title: "onabort", path: "", belong: "", value: "", meaning: "" },
    { id: 62, title: "onafterprint", path: "", belong: "", value: "", meaning: "" },
    { id: 63, title: "onbeforeprint", path: "", belong: "", value: "", meaning: "" },
    { id: 64, title: "onbeforeunload", path: "", belong: "", value: "", meaning: "" },
    { id: 65, title: "onblur", path: "", belong: "", value: "", meaning: "" },
    { id: 66, title: "oncanplay", path: "", belong: "", value: "", meaning: "" },
    { id: 67, title: "oncanplaythrough", path: "", belong: "", value: "", meaning: "" },
    { id: 67, title: "onchange", path: "", belong: "", value: "", meaning: "" },
    { id: 68, title: "onclick", path: "", belong: "", value: "", meaning: "" },
    { id: 69, title: "oncontextmenu", path: "", belong: "", value: "", meaning: "" },
    { id: 70, title: "oncopy", path: "", belong: "", value: "", meaning: "" },
    { id: 71, title: "oncuechange", path: "", belong: "", value: "", meaning: "" },
    { id: 72, title: "oncut", path: "", belong: "", value: "", meaning: "" },
    { id: 73, title: "ondblclick", path: "", belong: "", value: "", meaning: "" },
    { id: 74, title: "ondrag", path: "", belong: "", value: "", meaning: "" },
    { id: 75, title: "ondragend", path: "", belong: "", value: "", meaning: "" },
    { id: 76, title: "ondragenter", path: "", belong: "", value: "", meaning: "" },
    { id: 77, title: "ondragleave", path: "", belong: "", value: "", meaning: "" },
    { id: 78, title: "ondragover", path: "", belong: "", value: "", meaning: "" },
    { id: 79, title: "ondragstart", path: "", belong: "", value: "", meaning: "" },
    { id: 80, title: "ondrop", path: "", belong: "", value: "", meaning: "" },
    { id: 81, title: "ondurationchange", path: "", belong: "", value: "", meaning: "" },
    { id: 82, title: "onemptied", path: "", belong: "", value: "", meaning: "" },
    { id: 83, title: "onended", path: "", belong: "", value: "", meaning: "" },
    { id: 84, title: "onerror", path: "", belong: "", value: "", meaning: "" },
    { id: 85, title: "onfocus", path: "", belong: "", value: "", meaning: "" },
    { id: 86, title: "onhashchange", path: "", belong: "", value: "", meaning: "" },
    { id: 87, title: "oninput", path: "", belong: "", value: "", meaning: "" },
    { id: 88, title: "oninvalid", path: "", belong: "", value: "", meaning: "" },
    { id: 89, title: "onkeydown", path: "", belong: "", value: "", meaning: "" },
    { id: 90, title: "onkeypress", path: "", belong: "", value: "", meaning: "" },
    { id: 91, title: "onkeyup", path: "", belong: "", value: "", meaning: "" },
    { id: 92, title: "onload", path: "", belong: "", value: "", meaning: "" },
    { id: 93, title: "onloadeddata", path: "", belong: "", value: "", meaning: "" },
    { id: 94, title: "onloadedmetadata", path: "", belong: "", value: "", meaning: "" },
    { id: 95, title: "onloadstart", path: "", belong: "", value: "", meaning: "" },
    { id: 96, title: "onmousedown", path: "", belong: "", value: "", meaning: "" },
    { id: 97, title: "onmousemove", path: "", belong: "", value: "", meaning: "" },
    { id: 98, title: "onmouseout", path: "", belong: "", value: "", meaning: "" },
    { id: 99, title: "onmouseover", path: "", belong: "", value: "", meaning: "" },
    { id: 100, title: "onmouseup", path: "", belong: "", value: "", meaning: "" },
    { id: 101, title: "onmousewheel", path: "", belong: "", value: "", meaning: "" },
    { id: 102, title: "onoffline", path: "", belong: "", value: "", meaning: "" },
    { id: 103, title: "ononline", path: "", belong: "", value: "", meaning: "" },
    { id: 104, title: "onpagehide", path: "", belong: "", value: "", meaning: "" },
    { id: 105, title: "onpageshow", path: "", belong: "", value: "", meaning: "" },
    { id: 106, title: "onpaste", path: "", belong: "", value: "", meaning: "" },
    { id: 107, title: "onpause", path: "", belong: "", value: "", meaning: "" },
    { id: 108, title: "onplay", path: "", belong: "", value: "", meaning: "" },
    { id: 109, title: "onplaying", path: "", belong: "", value: "", meaning: "" },
    { id: 110, title: "onpopstate", path: "", belong: "", value: "", meaning: "" },
    { id: 111, title: "onprogress", path: "", belong: "", value: "", meaning: "" },
    { id: 112, title: "onratechange", path: "", belong: "", value: "", meaning: "" },
    { id: 113, title: "onreset", path: "", belong: "", value: "", meaning: "" },
    { id: 114, title: "onresize", path: "", belong: "", value: "", meaning: "" },
    { id: 115, title: "onscroll", path: "", belong: "", value: "", meaning: "" },
    { id: 116, title: "onsearch", path: "", belong: "", value: "", meaning: "" },
    { id: 117, title: "onseeked", path: "", belong: "", value: "", meaning: "" },
    { id: 118, title: "onseeking", path: "", belong: "", value: "", meaning: "" },
    { id: 119, title: "onselect", path: "", belong: "", value: "", meaning: "" },
    { id: 120, title: "onstalled", path: "", belong: "", value: "", meaning: "" },
    { id: 121, title: "onstorage", path: "", belong: "", value: "", meaning: "" },
    { id: 122, title: "onsubmit", path: "", belong: "", value: "", meaning: "" },
    { id: 123, title: "onsuspend", path: "", belong: "", value: "", meaning: "" },
    { id: 124, title: "ontimeupdate", path: "", belong: "", value: "", meaning: "" },
    { id: 125, title: "ontoggle", path: "", belong: "", value: "", meaning: "" },
    { id: 126, title: "onunload", path: "", belong: "", value: "", meaning: "" },
    { id: 127, title: "onvolumechange", path: "", belong: "", value: "", meaning: "" },
    { id: 128, title: "onwaiting", path: "", belong: "", value: "", meaning: "" },
    { id: 129, title: "onwheel", path: "", belong: "", value: "", meaning: "" },
    { id: 130, title: "open", path: "", belong: "", value: "", meaning: "" },
    { id: 131, title: "optimum", path: "", belong: "", value: "", meaning: "" },
    { id: 132, title: "pattern", path: "", belong: "", value: "", meaning: "" },
    { id: 133, title: "placeholder", path: "", belong: "", value: "", meaning: "" },
    { id: 134, title: "poster", path: "", belong: "", value: "", meaning: "" },
    { id: 135, title: "preload", path: "", belong: "", value: "", meaning: "" },
    { id: 136, title: "readonly", path: "", belong: "", value: "", meaning: "" },
    { id: 137, title: "rel", path: "/tags/att_link_rel", belong: ["<a>", "<area>", "<form>", "<link>"], value: ["alternate", "author", "dns-prefetch", "help", "icon", "license", "next", "pingback", "preconnect", "prefetch", "preload", "prerender", "prev", "search", "stylesheet"], meaning: "Majburiy. Joriy hujjat va biriktirilgan hujjat o'rtasidagi munosabatni belgilaydi" },
    { id: 138, title: "required", path: "", belong: "", value: "", meaning: "" },
    { id: 139, title: "reversed", path: "", belong: "", value: "", meaning: "" },
    { id: 140, title: "rows", path: "", belong: "", value: "", meaning: "" },
    { id: 141, title: "rowspan", path: "/tags/att_th_rowspan", belong: ['<td>', '<th>'], value: ["son",], meaning: "Sarlavha yacheykasi bo'lishi kerak bo'lgan qatorlar sonini belgilaydi." },
    { id: 142, title: "sandbox", path: "", belong: "", value: "", meaning: "" },
    { id: 143, title: "scope", path: "/tags/att_scope", belong: ['	<th>'], value: ['col', 'colgroup', 'row', 'rowgroup'], meaning: "Sarlavha yacheykasi ustun, satr yoki ustunlar yoki satrlar guruhi uchun sarlavha ekanligini belgilaydi" },
    { id: 144, title: "selected", path: "", belong: "", value: "", meaning: "" },
    { id: 145, title: "shape", path: "/tags/att_area_shape", belong: ["<area>"], value: ["default", "rect", "circle", "poly"], meaning: "Maydonning shaklini belgilaydi" },
    { id: 146, title: "size", path: "", belong: "", value: "", meaning: "" },
    { id: 147, title: "sizes", path: "/tags/att_link_sizes", belong: ["<img>", "<link>", "<source>"], value: ["Height x Width", "any"], meaning: "Biriktirilgan resurs o'lchamini belgilaydi. Faqat rel='icon' uchun" },
    { id: 148, title: "span", path: "/tags/att_colgroup_span", belong: ["	<col>", "<colgroup>"], value: ["son"], meaning: "Ustunlar guruhini qamrab oladigan ustunlar sonini belgilaydi" },
    { id: 149, title: "spellcheck", path: "", belong: "", value: "", meaning: "" },
    { id: 150, title: "src", path: "/tags/att_img_src.", belong: ['<audio>', '<embed>', '<iframe>', '<img>', '<input>', '<script>', '<source>', '<track>', '<video>'], value: ["URL"], meaning: "Rasm manzilini belgilaydi" },
    { id: 151, title: "srcdoc", path: "", belong: "", value: [""], meaning: "" },
    { id: 152, title: "srclang", path: "", belong: "", value: "", meaning: "" },
    { id: 153, title: "srcset", path: "", belong: ['<img>', '<source>'], value: ["URL-ro'yhati"], meaning: "Turli vaziyatlarda foydalanish uchun rasm fayllari ro'yxatini belgilaydi" },
    { id: 154, title: "start", path: "", belong: "", value: "", meaning: "" },
    { id: 155, title: "step", path: "", belong: "", value: "", meaning: "" },
    { id: 156, title: "style", path: "", belong: "", value: "", meaning: "" },
    { id: 157, title: "tabindex", path: "", belong: "", value: "", meaning: "" },
    { id: 158, title: "target", path: "/tags/att_a_target", belong: ["<a>", "<area>", "<base>", "<form>"], value: ["_blank", "_parent", "_self", "_top"], meaning: "Biriktirilgan hujjatni qayerda ochishni belgilaydi" },
    { id: 159, title: "title", path: "/tags/att_title", belong: ["Global Attributes"], value: [""], meaning: "Element haqida qo'shimcha ma'lumot beradi." },
    { id: 160, title: "translate", path: "", belong: "", value: "", meaning: "" },
    { id: 161, title: "type", path: "/tags/att_type", belong: ["<a>", "<button>", "<embed>", "<input>", "<link>", "<menu>", "<object>", "<script>", "<source>", "<style>"], value: ["	media_type"], meaning: "Element turini belgilaydi" },
    { id: 162, title: "usemap", path: "/tags/att_img_usemap", belong: ['<img>', '<object>'], value: ["#mapname"], meaning: "Rasmni dasturchi tomonidan rasm xaritasi sifatida belgilaydi" },
    { id: 163, title: "value", path: "", belong: "", value: "", meaning: "" },
    { id: 164, title: "width", path: "/tags/att_img_width", belong: "", value: ["pikselda"], meaning: "Rasmning kengligini belgilaydi" },
    { id: 165, title: "wrap", path: "", belong: "", value: "", meaning: "" },
    //stop Attributs
    { id: 166, title: "referrerpolicy", path: "/tags/att_a_referrepolicy", belong: "", value: ["no-referrer", "no-referrer-when-downgrade", "origin", "origin-when-cross-origin", "same-origin", "strict-origin-when-cross-origin", "unsafe-url"], meaning: "Resursni olishda qaysi havoladan foydalanish kerakligini belgilaydi" },
    { id: 167, title: "xmlns", path: "/tags/att_html_xmlns", belong: ['<canvas>', '<embed>', '<iframe>', '<img>', '<input>', '<object>', '<video>'], value: ["http://www.w3.org/1999/xhtml"], meaning: "XML maydoni atributi (agar kontentingiz XHTMLga mos kelishi kerak bo'lsa)" },
    { id: 167, title: "abbr", path: "/tags/att_th_abbr", belong: [], value: ["matn"], meaning: "Sarlavha katagidagi tarkibning qisqartirilgan versiyasini belgilaydi" },
]

export default AttrLists;