import React from 'react';
import TextContent from '../../pages/TextContent';
import W3Path from '../../W3Path';
import NavButton from '../NavButton';
import YoutubeEmbed from '../YoutubeEmbed';
import ListText from '../../pages/ListsText';
import Table from '../../pages/Table';


function IFrame() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_id.asp"} />
            <h2 className='header-tutorial'>Iframe</h2>
            <NavButton back='/html/html_id' next='/html/html_scripts' />
            <hr />

            <p> Freymlar veb-sahifa ichida yana bitta veb-sahifani ko'rsatish uchun ishlatiladi.</p>
            <hr />
            <div style={{ width: "100%", height: "350px", }}>
                <iframe src="/html" title="HTML qo'llanma" height="350px" width="99%"></iframe>
            </div>

            <hr />
            <TextContent header={"Iframe sintaksisi"}
                text1={"Ichki freymlarni yaratish uchun <iframe> tegidan foydalaniladi."}
                redWords1={["<iframe>"]}
                text2={"Joriy veb sahifaga boshqa veb sahifani joylashtirish uchun ham freymlardan foydalaniladi."}
                code2={`<iframe src="url" title="tavsifi"></iframe>`}
                text4={"Maslahat:  <iframe> ga har doim title atributini berish tavsiya qilinadi. Bu ekranni o'qish qurilmalari tomonidan iframe tarkibi nima ekanligini o'qish uchun ishlatiladi."}
                redWords4={["title", "<iframe>"]} />
            <hr />
            <TextContent header={"Freymga width va height berish"}
                text1={"Freym o'lchamlarini berish uchun width va height atributlari ishlatiladi."}
                redWords1={["width", "height"]}
                text2={"Default holatda width va height o'lchamlari pikselda berilgan bo'ladi."}
                code2={`<iframe src="/html" height="200" width="300" title="Iframe Example"></iframe>`}
                path2={"try/233"}
                text4={"Yoki style atributi yordamida width va height xususiyatlarini o'zgartirish mumkin."}
                redWords4={["style", "width", "height"]}
                code4={`<iframe src="demo.htm" style="height:200px;width:300px" title="Iframe Example"></iframe>`}
                path4={`"try/234`} />
            <hr />
            <TextContent header={"Freym borderini olib tashlash"}
                text1={"Default holatda freym atrofida border bo'ldi."}
                text2={"Borderni olib tashlash uchun style atributi yordamida border xususiyatidan foydalaniladi."}
                redWords2={["style", "border"]}
                code2={`<iframe src="demo.htm" style="border:none;" title="Iframe Example"></iframe>`}
                path2={"try/235"}
                text4={"CSS yordamida freym borderining o'lchamini, stilini va rangini ham o'zgartirish mumkin:"}
                code4={`<iframe src="demo_iframe.htm" style="border:2px solid red;" title="Iframe Example"></iframe>`}
                path4={`try/236`} />
            <hr />
            <TextContent header={"Havola va freym"}
                text1={"Freym havola bosilganda ochilishi kerak bo'lgan sahifa uchun maxsus joy sifatida ishlatilishi mumkin."}
                text2={"Buning uchun havolaning target atributi orqali freymdagi name atributiga murojaat qilishi kerak"}
                redWords4={["target", "name"]}
                code2={`<iframe src="demo_iframe.htm" name="iframe_a" title="Iframe Example"></iframe>

<p><a href="https://www.w3schools.com" target="iframe_a">W3Schools.com</a></p>`}
path2={"try/237"}/>
            <hr />

            <TextContent header={"Bo'lim xulosasi"} />
            <ListText list={[{
                text: "Ichki freymlarni yaratish uchun <iframe> tegidan foydalaniladi.",
                redWords: ["<iframe>"]
            },
            {
                text: "src atributiga freymda ochilishi kerak bo'lgan sahifa URL manzili kiritiladi.",
                redWords: ["src"]
            },
            {
                text: "Har doim title atributi kiritib ketish tavsiya qilinadi.(Ekranni o'qish qurilmalari uchun.)",
                redWords: ["title"]
            },
            {
                text: "Freym o'lchamlarini berish uchun width va height atributlari ishlatiladi.",
                redWords: ["width", "height"]
            },
            {
                text: "Freym atrofidagi borderni olib tashlash uchun border:none; dan foydalaniladi.",
                redWords: ["border:none;"]
            },]} />
            <hr />
            <Table th={["Teg", "Tavsif"]} values={[
                { id: 0, first: "<iframe>", second: "Ichki freymlarni hosil qilish uchun ishlatiladi.", path: "/tags/tag_iframe" }]} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="AJ16pQF9ux8" />
            <NavButton back='/html/html_id' next='/html/html_scripts' />
        </article>)
}

export default IFrame;