import React from 'react';
import NavButton from '../NavButton';
import '../Tutorials.css';
import YoutubeEmbed from '../YoutubeEmbed';
import Collapsible from '../../pages/Collapsible';
import CodeContent from '../../../tryit/CodeContent';
import { Link } from 'react-router-dom';
import Tegs from '../../pages/tegs';
import Table from '../../pages/Table';
import W3Path from '../../W3Path';

function HtmlQuatation() {
  return (
    <article className='info-wrapper'>
      <W3Path  path={"https://www.w3schools.com/html/html_quotation_elements.asp"}/>
      <h2 className='header-tutorial text-tutorial-margin'>HTML iqtibos va akronim elementlari</h2>
      <hr />
      <NavButton back='/html/html_formatting' next='/html/html_comments' />
      <hr />
      <p className='text-tutorial'>Bu bobda biz HTML <Tegs tegname="blockquote" />, <Tegs tegname="q" />, <Tegs tegname="abbr" />, <Tegs tegname="address" />, <Tegs tegname="cite" />, va <Tegs tegname="bdo" /> elementlarini ko'rib chiqamiz.</p>
      <hr />
      <div className='code-content'>
        <h4 className='header-code'>Misol</h4>
        <div className='code-wrapper'>
          <p>Bu yerda WWF veb-saytidan iqtibos:</p>
          <blockquote cite="http://www.worldwildlife.org/who/index.html">
            50 yil davomida WWF tabiatni himoya qilib keladi.
            Tabiatni muhofaza qilish bo‘yicha dunyodagi yetakchi tashkilot,
            WWF 100 ta mamlakat tomonidan qo'llab-quvvatlanadi,
            Qo'shma Shtatlarda 1,2 million va
            dunyo bo'ylab 5 millionga yaqin a'zo.
          </blockquote>
        </div>
        <Link className='button btn btn--large green' to={`try/41`} target={"_blank"}>Sinab ko'rish</Link>
      </div>
      <hr />
      <h3 className='header-content'>Iqtiboslar uchun HTML &lt;blockquote&gt; dan foydalanish</h3>
      <p className='text-tutorial'>HTML <Tegs tegname="blockquote" />elementi boshqa manbadan keltirilgan bo'limni ifodalaydi.</p>
      <p className='text-tutorial'>Brauzerda <Tegs tegname="blockquote" /> elementi yangi satr boshidan yoziladi.</p>
      <CodeContent props={`<blockquote cite="http://www.worldwildlife.org/who/index.html">
Taxminan 60 yil davomida WWF tabiatni himoya qilib keladi. Tabiatni muhofaza qilish bo'yicha dunyoning yetakchi tashkiloti WWF 100 ta mamlakatda ishlaydi va uni Qo'shma Shtatlarda bir milliondan ortiq va butun dunyo bo'ylab besh millionga yaqin a'zolar qo'llab-quvvatlaydi.
</blockquote>`} pathname={`try/42`} />
      <hr />
      <h3 className='header-content'>Ko'chirma gaplar uchun HTML &lt;q&gt; dan foydalanish</h3>
      <p className='text-tutorial'>HTML <Tegs tegname="q" />elementi ko'chirma gaplarni ifodalaydi.</p>
      <p className='text-tutorial'>Brauzerda ko'chirma gaplar odatda qo'shtirnoq ichida yoziladi.</p>
      <CodeContent props={`<p>WWF maqsadi: <q>Odamlar tabiat bilan uyg'unlikda yashaydigan kelajakni qurish.</q></p>`} pathname={`try/43`} />
      <hr />
      <h3 className='header-content'>Qisqartmalar uchun HTML &lt;abbr&gt; dan foydalanish</h3>
      <p className='text-tutorial'>HTML <Tegs tegname="abbr" /> tegi "HTML", "CSS","ASAP", "ATM" kabi qisqartmalarni ifodalaydi.</p>
      <p className='text-tutorial'>Qisqartmalarni belgilash brauzerlar, tarjima tizimlari va qidiruv tizimlari uchun foydali ma'lumotlarni berishi mumkin.</p>
      <p className='text-tutorial'><b>Maslahat:</b> Sichqonchani element ustida olib borganingizda qisqartma tavsifini ko'rsatish uchun title atributidan foydalaning. </p>
      <CodeContent props={`<p><abbr title="Jahon sog'liqni saqlash tashkiloti">JSST</abbr> 1948 yilda tashkil etilgan.</p>`} pathname={`try/44`} />
      <hr />
      <h3 className='header-content'>Aloqa ma'lumotlari uchun HTML &lt;address&gt; dan foydalanish</h3>
      <p className='text-tutorial'>HTML <Tegs tegname="address" />tegi yordamida hujjat yoki maqola muallifi/egasi bilan bog'lanish uchun kerakli ma'lumotlar ko'rsatiladi.</p>
      <p className='text-tutorial'>Aloqa ma'lumotlari elektron pochta manzili, URL manzili, jismoniy manzil, telefon raqami, ijtimoiy tarmoq tutqichi va boshqalar bo'lishi mumkin.</p>
      <p className='text-tutorial'><Tegs tegname="address" /> elementidagi matn odatda kursiv bilan ko'rsatiladi va brauzerlar har doim elementdan oldin va keyin bo'sh qatorlarni qo'shadi.</p>
      <CodeContent props={`<address>
Jon Doe tomonidan yozilgan.<br>
Tashrif buyuring:<br>
Misol.com<br>
Box 564, Disneylend<br>
AQSH
</address>`} pathname={`try/45`} />
      <hr />
      <h3 className='header-content'>Ijodiy ish nomi uchun HTML &lt;cite&gt; dan foydalanish</h3>
      <p className='text-tutorial'>HTML <Tegs tegname="cite" />tegi ijodiy ish (masalan, kitob, she'r, qo'shiq, kino, rasm, haykal va boshqalar) nomini belgilaydi.</p>
      <p className='text-tutorial'><b>Eslatma:</b>Shaxsning ismi ijodiy ish nomi hisoblanmaydi.</p>
      <p className='text-tutorial'><Tegs tegname="cite" />elementidagi matn odatda kursiv bilan ko'rsatiladi .</p>
      <CodeContent props={`<p><cite>Qichqiriq</cite>, Edvard Munch. 1893 yilda chizilgan.</p>`} pathname={`try/46`} />
      <hr />
      <h3 className='header-content'>Matnlarni teskari yozish</h3>
      <p className='text-tutorial'>BDO(Bi-Directional Override) yozuv yo'nalishini bekor qilishni anglatadi.</p>
      <p className='text-tutorial'>HTML <Tegs tegname="bdo" />tegi joriy matn teskari yozish uchun ishlatiladi:</p>
      <CodeContent props={`<bdo dir="rtl">Ushbu qator o'ngdan chapga yoziladi</bdo>`} pathname={`try/47`} />
      <hr />
      <h3 className='header-content'>Dars videosi:</h3>
      <YoutubeEmbed embedId="ot-PRRsyVjI" />
      <hr />
      <Collapsible>
        <p className='text-tutorial'>Quyidagi veb saytdan iqtibos keltirib Nobel Prize veb sahifasi yaratilsin:</p>
        <a href="https://www.nobelprize.org/nobel-prize-concert-2022/" target={'_blank'} rel="noreferrer"> Nobel Prize veb sayt manzili</a>
      </Collapsible>

      <Table th={["Teg", "Tavsif"]} values={[
        { id: 0, first: "<abbr>", second: "Qisqartalar uchun ishlatiladi", path: "/tags/tag_abbr" },
        { id: 1, first: "<address>", second: "Maqola muallifi/egasi bilan bog'lanish uchun kerakli ma'lumotlar ko'rsatiladi", path: "t/tags/tag_address" },
        { id: 2, first: "<bdo>", second: "Matnni teskari yozish chun ishlatiladi ", path: "/tags/tag_bdo" },
        { id: 3, first: "<blockquote>", second: "Iqtiboslar va iqtibos elementlarini ifodalaydi", path: "/tags/tag_blockquote" },
        { id: 4, first: "<cite>", second: "Ijodiy ish nomi uchun ishlatiladi", path: "/tags/tag_cite" },
        { id: 5, first: "<q>", second: "Ko'chirma gaplar uchun ishlatiladi.", path: "/tags/tag_q" }
      ]} />
      <NavButton back='/html/html_formatting' next='/html/html_comments' />
    </article>
  )
}

export default HtmlQuatation;