import React, { useEffect, useState } from "react";

function ColorChange(props) {
    const [valueRed, SetValueRed] = useState(255);
    const [valueGreen, SetValueGreen] = useState(99);
    const [valueBlue, SetValueBlue] = useState(71);
    const [valueAlpha, SetValueAlpha] = useState(props.alpha * 10)
    const [styleDiv, SetStyleDiv] = useState('');
    const getBackgroundSize = (value, max) => {
        return {
            backgroundSize: `${(value * 100) / max}% 100%`,
        };
    };

    const hexValue = (value) => {
        if (value < 16) return `0${Math.abs(value).toString(16)}`
        return Math.abs(value).toString(16)
    }

    useEffect(() => {
        const changeColorHex = () => { SetStyleDiv(`#${hexValue(valueRed) + hexValue(valueGreen) + hexValue(valueBlue)}`) }

        const changeColor = () => {
            valueAlpha ? SetStyleDiv(`rgba(${valueRed},${valueGreen},${valueBlue},${valueAlpha / 10})`) : SetStyleDiv(`rgb(${valueRed},${valueGreen},${valueBlue})`)
        }
        if (props.hex) changeColorHex();
        else changeColor();
    }, [valueRed, valueGreen, valueBlue, valueAlpha]);

    return (
        <div style={{ textAlign: "center", width: "70%", marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>
            <div id="rgbresult" style={{ lineHeight: "80px", maxWidth: "70%", textAlign: "center", marginLeft: "auto", marginRight: "auto", backgroundColor: `${styleDiv}` }}>
                <p id="rgbresulttext" style={{ fontSize: "120%", fontWeight: "bold", color: "rgb(255, 255, 255)", }}>{styleDiv}</p>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                <div>
                    <p style={{ color: "red", }}>RED(qizil)</p>
                    <input
                        type="range"
                        min="0"
                        max="255"
                        onChange={(e) => SetValueRed(e.target.value)}
                        style={getBackgroundSize(valueRed, 255)}
                        value={valueRed}
                    />

                    <div id="valRed2" xstyle="background-color:red;border-radius:5px;color:white;opacity:0.8">{props.hex ? hexValue(valueRed) : valueRed}</div>
                </div>
                <div>
                    <p style={{ color: "green", }}>GREEN(yashil)</p>
                    <input
                        type="range"
                        min="0"
                        max="255"
                        onChange={(e) => SetValueGreen(e.target.value)}
                        style={getBackgroundSize(valueGreen, 255)}
                        value={valueGreen}
                    />
                    <div id="valGreen2" xstyle="background-color:#00ff00;border-radius:5px;opacity:0.8">{props.hex ? hexValue(valueGreen) : valueGreen}</div>
                </div>
                <div>
                    <p style={{ color: "blue", }}>BLUE(ko'k)</p>
                    <input
                        type="range"
                        min="0"
                        max="255"
                        onChange={(e) => SetValueBlue(e.target.value)}
                        style={getBackgroundSize(valueBlue, 255)}
                        value={valueBlue}
                    />
                    <div id="valBlue2" xstyle="background-color:blue;border-radius:5px;color:white;opacity:0.8">{props.hex ? hexValue(valueBlue) : valueBlue}</div>
                </div>
                {valueAlpha ? <div>
                    <p style={{ color: "blue", }}>Alpha(shaffoflik)</p>
                    <input
                        type="range"
                        min="0"
                        max="10"
                        onChange={(e) => SetValueAlpha(e.target.value)}
                        style={getBackgroundSize(valueAlpha, 10)}
                        value={valueAlpha}
                    />
                    <div id="valAlpha2" xstyle="background-color:blue;border-radius:5px;color:white;opacity:0.8">{valueAlpha / 10}</div>
                </div> : <></>}
            </div>
        </div>
    )
}
export default ColorChange;