import React from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import Notes from '../../../pages/Notes';
import ListText from '../../../pages/ListsText';

function YouTube() {
    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_youtube.asp"} />
            <h2 className='header-tutorial'>HTML YouTube videolari</h2>
            <NavButton back='/html/html5_audio' next='/html/html5_geolocation' />
            <hr />
            <p>HTMLda videolarni joylashning eng oson yo'li YouTubedan foydalanishdir.</p>
            <hr />
            <TextContent header={"Video formatlari qiyinchilik keltirib chiqaryaptimi?"}
                text1={"Videolarni turli formatlarga o'tkazish qiyinchilik tug'diradi va ko'p vaqt talab qilishi mumkin."}
                text2={"Eng oson yechim - veb-sahifada YouTube videolarini joylashdir."} />
            <hr />
            <TextContent header={"YouTube video identifikatori"}
                text1={"Videoni saqlaganingizda (yoki videoni qo'yganingizda) YouTube identifikatorni(masalan, tgbNymZ7vqY) ko'rsatadi."}
                text2={"HTML kodida ushbu identifikatordan foydalanib videoga murojaat qilish mumkin."} />
            <hr />
            <TextContent header={"HTMLda YouTube videosini joylash"}
                text1={"Videoni veb-sahifaga joylash uchun quyidagilarni bajaring:"} />
            <ListText list={[{
                text: "Videoni YouTubega yuklang",
            },
            {
                text: "Video identifikatorini aniqlang",
            },
            {
                text: "Veb-sahifangizda <iframe> elementini yozing", redWords: ["<iframe>"]
            },
            {
                text: "src atributiga videoning URL manzilini ko'rsating", redWords: ["src"]
            },
            {
                text: "Pleyer o'lchamini belgilash uchun width va height atributlaridan foydalaning ", redWords: ["width", "height"]
            },
            {
                text: "URL manziliga boshqa qo'shimcha parametrlarni ham qo'shing (pastda ko'rsatilgan)",
            },]} />
            <TextContent
                code1={`<iframe width="420" height="315"
src="https://www.youtube.com/embed/tgbNymZ7vqY">
</iframe>`}
                path1={"try/438"}
            />
            <hr />
            <TextContent header={"YouTube Autoplay + Mute"}
                text1={"YouTube URL manziliga autoplay=1 ni qo'shish orqali foydalanuvchi sahifaga tashrif buyurganida video avtomatik ravishda boshlanishiga ruxsat berish mumkin. Biroq, videoni avtomatik ravishda boshlash tashrif buyuruvchilar uchun yoqimli bo'lmasligi mumkin!"}
                redWords1={["autoplay=1"]} />

            <Notes type={"Eslatma:"} text={"Chrome brauzerlari ko'p hollarda autoplayga ruxsat bermaydi. Biroq, ovozsiz autoplayga har doim ruxsat beriladi."} />
            <br/>
            <TextContent
                text1={"Video avtomatik boshlanishi uchun(lekin ovozi o'chirilgan) autoplay=1 dan keyin mute=1 ni qo'shing."}
                redWords1={["mute=1", "autoplay=1"]}
                code1={`<iframe width="420" height="315"
src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1">
</iframe>`} path1={"try/439"} />
            <hr />
            <TextContent header={"YouTube pleylist"}
                text1={"Qo'yiladigan videolar ro'yxati vergul bilan ajratiladi(asl URL manzilidan keyin)."} />
            <hr />
            <TextContent header={"YouTube Loop"}
                text1={"Video takrorlanishi uchun loop=1 ni qo'shing ."}
                redWords1={["loop=1"]}
                text2={"0 qiymati (default holatda): Video faqat bir marta ijro etiladi."}
                text3={"1 qiymati: video takrorlanadi(cheksiz)."}
                code3={`<iframe width="420" height="315"
src="https://www.youtube.com/embed/tgbNymZ7vqY?playlist=tgbNymZ7vqY&loop=1">
</iframe>`} path3={"try/440"} />
            <hr />
            <TextContent header={"YouTube Controls"}
                text1={"Video pleyerda boshqaruv elementlarini ko'rsatmaslik uchun controls=0 qo'shing."}
                redWords1={["controls=0"]}
                text2={"0 qiymati: Pleyer boshqaruvi ko'rsatilmaydi."}
                text3={"1 qiymati(default): Pleyer boshqaruvi ko'rsatiladi."}
                code3={`<iframe width="420" height="315"
src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0">
</iframe>`} path3={"try/441"} />
            <hr />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="fP7JqAKNWBw" />
            <NavButton back='/html/html5_audio' next='/html/html5_geolocation' />
        </div>)
}

export default YouTube;