import React, {useState } from 'react';
import TextContent from '../../../pages/TextContent';
import W3Path from '../../../W3Path';
import NavButton from '../../NavButton';
import YoutubeEmbed from '../../YoutubeEmbed';
import { Link } from 'react-router-dom';
import Notes from '../../../pages/Notes';
import NoteWhite from '../../../pages/NoteWhite';
import Table from '../../../pages/Table';
import BrowserTable from '../../../pages/BrowserTable';


function HtmlVideo() {
    const [widthVid,SetWidthVid]=useState(420);
    const playPause = () => {
        var myVideo = document.getElementById("video2");
        if (myVideo.paused)
            myVideo.play();
        else
            myVideo.pause();
    };

    const makeBig = () => {
        SetWidthVid(600);
    }

    const makeSmall = () => {
    SetWidthVid(320);
    }

    const makeNormal = () => {
        SetWidthVid(480);
    }

    return (
        <div className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html5_video.asp"} />
            <h2 className='header-tutorial'>HTML Video</h2>
            <NavButton back='/html/html_media' next='/html/html5_audio' />
            <hr />
            <TextContent text1={"HTML <video> elementi veb-sahifada videoni ko'rsatish uchun ishlatiladi."}
                redWords1={["<video>"]} />
            <hr />
            <div className='code-content'>
                <h4 className='header-code'>Misol</h4>
                <p>Video <a href="https://peach.blender.org/" target={"_blank"} rel="noreferrer">Big Buck Bunny</a> dan olingan</p>
                <div className='code-wrapper'>
                    <video width="400" controls>
                        <source src="/mov_bbb.mp4" type="video/mp4" />
                        <source src="/mov_bbb.ogg" type="video/ogg" />
                        Kechirasiz, brauzeringiz video elementini qo'llab-quvvatlamaydi.
                    </video>   </div>
                <Link className='button btn btn--large green' to={"try/426"} target={"_blank"}>Sinab ko'rish</Link>
            </div>
            <hr />
            <TextContent header={"HTML <video> elementi"}
                text1={"HTMLda videoni ko'rsatish uchun <video> elementidan foydalaniladi:"}
                redWords1={["<video>"]}
                code1={`<video width="320" height="240" controls>
  <source src="mov.mp4" type="video/mp4">
  <source src="mov.ogg" type="video/ogg">
Kechirasiz, brauzeringiz video elementini qo'llab-quvvatlamaydi.
</video>`}
                path1={"try/427"} />
            <hr />
            <TextContent header={"Qanday ishlaydi"}
                text1={"controls atributi videoni qo'yish, to'xtatib turish va ovoz balandligi kabi video boshqaruvlarini qo'shadi."}
                redWords1={["controls"]}
                text2={"Har doim width va height atributlarini kiritish kerak. Agar width va height o'rnatilmagan bo'lsa, video yuklanayotganda sahifa miltillashi mumkin."}
                redWords2={["width", "height"]}
                text3={"<source> elementi brauzer tanlashi mumkin bo'lgan muqobil video fayllarini belgilash imkonini beradi. Brauzer birinchi qo'llab-quvvatlaydigan formatidan foydalanadi."}
                redWords3={["<source>"]}
                text4={"<video> va </video> teglari orasidagi matn faqat <video> elementini qo'llab-quvvatlamaydigan brauzerlarda ko'rsatiladi."}
                redWords4={["<video>", "</video>"]} />
            <hr />
            <TextContent header={"HTML <video> Autoplay"}
                text1={"autoplay atributidan foydalanilsa video avtomatik ravishda boshlanadi:"}
                redWords1={["autoplay"]}
                code1={`<video width="320" height="240" autoplay>
  <source src="mov.mp4" type="video/mp4">
  <source src="mov.ogg" type="video/ogg">
Kechirasiz, brauzeringiz video elementini qo'llab-quvvatlamaydi.
</video>`} path1={"try/428"} />
            <Notes type={"Eslatma:"} text={"Chrome brauzerlari ko'p hollarda autoplayga ruxsat bermaydi. Biroq, ovozsiz autoplayga har doim ruxsat beriladi."} />
            <br/>
            <TextContent
                text1={"Video avtomatik ovozsiz boshlanishi uchun autoplaydan keyin muted atributi qo'shiladi: "}
                redWords1={["muted", "autoplay"]}
                code1={`<video width="320" height="240" autoplay muted>
  <source src="mov.mp4" type="video/mp4">
  <source src="mov.ogg" type="video/ogg">
Kechirasiz, brauzeringiz video elementini qo'llab-quvvatlamaydi.
</video>`} path1={"try/429"} />
            <hr />
            <BrowserTable text={"Jadvalda <video> elementini to'liq qo'llab-quvvatlaydigan birinchi brauzer versiyasi ko'rsatilgan."} element={"<video>"} chrome={"4.0"} edge={"9.0"} firefox={"3.5"} safari={"4.0"} opera={"10.5"} />
            <hr />
            <Table th={["Brauzer", "MP4", "	WebM", "Ogg"]} header={"HTML video formatlari"}
                text={"Uchta qo'llab-quvvatlanadigan video formatlari mavjud: MP4, WebM va Ogg. Turli formatlarning brauzerlar tomonidan qo'llab-quvvatlanishi:"} values={[
                    { id: 0, first: "Edge", second: "Mavjud", third: "Mavjud", four: "Mavjud" },
                    { id: 1, first: "Chrome", second: "Mavjud", third: "Mavjud", four: "Mavjud" },
                    { id: 2, first: "Firefox", second: "Mavjud", third: "Mavjud", four: "Mavjud" },
                    { id: 3, first: "Safari", second: "Mavjud", third: "Mavjud", four: "Mavjud emas" },
                    { id: 4, first: "Opera", second: "Mavjud", third: "Mavjud", four: "Mavjud" },
                ]} />
            <hr />
            <Table th={["Fayl formati", "Media turi",]} header={"HTML video - Media turlari"}
                values={[
                    { id: 0, first: "MP4", second: "video/mp4", },
                    { id: 1, first: "WebM", second: "video/webm", },
                    { id: 2, first: "Ogg", second: "video/ogg", },
                ]} />
            <hr />
            <TextContent header={"HTML video elementi metodlar, xususiyatlar va hodisalari"}
                text1={"HTML DOM <video> elementi uchun metodlar, xususiyatlar va hodisalarni belgilaydi. "}
                redWords1={["<video>"]}
                text2={"Bu esa videolarni yuklash, qo'yish va to'xtatib turish, shuningdek, davomiylik va ovoz balandligini sozlash imkonini beradi."}
                text3={"Shuningdek, video boshlanganida, to'xtatilganda va hokazolarda xabardor qiladigan DOM hodisalari mavjud."} />
            <div className='code-content'>
                <h4 className='header-code'>Misol</h4>
                <p>Video <a href="https://peach.blender.org/" target={"_blank"} rel="noreferrer">Big Buck Bunny</a> dan olingan</p>
                <div className='code-wrapper' style={{height: "405px", textAlign: "center"}}>
                    <button onClick={playPause}>Play/Pause</button>
                    <button onClick={makeBig}>Big</button>
                    <button onClick={makeSmall}>Small</button>
                    <button onClick={makeNormal}>Normal</button>
                    <br/>
                    <br/>
                    <video id='video2' width={widthVid}>
                        <source src="/mov_bbb.mp4" type="video/mp4" />
                        <source src="/mov_bbb.ogg" type="video/ogg" />
                        Kechirasiz, brauzeringiz video elementini qo'llab-quvvatlamaydi.
                    </video>   </div>
                <Link className='button btn btn--large green' to={"try/430"} target={"_blank"}>Sinab ko'rish</Link>
            </div>
            <NoteWhite text={"To'liq DOM haqida ma'lumot olish uchun bizning"} text1={"HTML Audio/Video DOM ma'lumotnomamizga"} text2={"o'ting."} path={"/tags/ref_av_dom"} />
            <hr />
            <Table th={["Teg", "Tavsif"]} values={[
                { id: 0, first: "<video>", second: "Video yoki filmni belgilaydi", path: "/tags/tag_video" },
                { id: 1, first: "<source>", second: "<video> va <audio> kabi media elementlari uchun bir nechta media resurslarini belgilaydi", path: "/tags/tag_source" },
                { id: 2, first: "<track>", second: "Media pleerlarda matn treklarini belgilaydi", path: "/tags/tag_track" }]} />
            <h3 className='header-content'>Dars videosi:</h3>
            <YoutubeEmbed embedId="BlLWrGHKkjs" />
            <NavButton back='/html/html_media' next='/html/html5_audio' />
        </div>)
}

export default HtmlVideo;