import React from 'react';
import CodeContent from '../../../../tryit/CodeContent';
import NavButton from '../../NavButton';
import '../../Info.css';
import CodeMirrorContent from '../../../../tryit/CodeContent/CodeMirrorContent';
import YoutubeEmbed from '../../YoutubeEmbed';
import Table from '../../../pages/Table';
import Notes from '../../../pages/Notes';
import W3Path from '../../../W3Path';


function LinkBookmark() {
    return (
        <article className='info-wrapper'>
            <W3Path path={"https://www.w3schools.com/html/html_links_bookmarks.asp"} />
            <h2 className='header-tutorial'>HTML havolalari - xatcho'plar yaratish</h2>
            <NavButton back='/html/html_links_colors' next='/html/html_images' />
            <hr />
            <p className='text-tutorial text-tutorial-margin'>Foydalanuvchilar veb-sahifaning muayyan qismlariga o'tishini osonlashtirish uchun HTML havolalari yordamida xatcho'plar yaratish mumkin.</p>
            <hr />
            <h3 className='header-content'>HTMLda xatcho'p yaratish</h3>
            <p className='text-tutorial'>Agar veb-sahifa juda uzun bo'lsa, xatcho'plar veb-sahifadan foydalanishni osonlashtiradi. </p>
            <p className='text-tutorial'> Avval xatcho'p yaratiladi, so'ngra uni havolaga qo'shiladi.</p>
            <p className='text-tutorial'> Havola bosilganda, sahifaning xatcho'p joylashgan pastdagi yoki yuqoridagi  joyi ko'rsatiladi.</p>
            <h3 className='header-content'>Misol</h3>
            <p className='text-tutorial'>Xatcho'p yaratish uchun <span className='text-tutorial__red'>&lt;id&gt;</span> atributidan foydalaniladi: </p>
            <div className='code-content'>
                <div className='code-wrapper'>
                    <CodeMirrorContent value={`<h2 id="C4">Chapter 4</h2>`} />
                </div>
            </div>
            <p className='text-tutorial'> Keyin, xuddi shu sahifadagi havola href atributiga xatcho'p qiymati beriladi. ("4-bo'limga o'tish"):</p>
            <CodeContent props={`<a href="#C4">4-bo'limga o'tish</a>`} pathname={`try/89`} />
            <p className='text-tutorial'>Bundan tashqari, boshqa sahifadagi xatcho'p uchun havola yaratish mumkin: </p>
            <div className='code-content'>
                <div className='code-wrapper'>
                    <CodeMirrorContent value={`<a href="html_demo.html#C4">4-b'limga o'tish</a>`} />
                </div>
            </div>
            <hr />
            <h3 className='header-content'>Bo'lim xulosasi</h3>
            <ul className='lists'>
                <li className='item'><p>Sahifadagi xatcho‘plarni aniqlash uchun <span className='text-tutorial__red'>&lt;id&gt;</span> atributdan (id=" qiymat ") foydalaniladi</p></li>
                <li className='item'><p>Xatcho'pga ulanish uchun <span className='text-tutorial__red'>&lt;href&gt;</span>(href="# qiymat ")atributdan foydalaniladi .</p></li>
            </ul>
            <hr />

            <Table th={["Teg", "Tavsif"]} values={[
                { id: 0, first: "a", second: "Giperhavolalar uchun foydalaniladi.", path: "/tags/tag_a" },
            ]} />
            <Notes path={"/tags"} text1="HTML teglar maʼlumotnomasidan" text2={"barcha mavjud HTML teglarining toʻliq roʻyxatini koʻrishingiz mumkin."} />
            <hr />
            <h3 className='header-content'>Dars videosi</h3>
            <YoutubeEmbed embedId="QnquBotY6IA" />
            <NavButton back='/html/html_links_colors' next='/html/html_images' />
        </article>
    )
}

export default LinkBookmark;