
const example = [
  {
    id: 1, exhtml: `
    <head>
    <title>Sahifa sarlavhasi</title>
    </head>
    <body>
    <h1>Bu sarlavha</h1>
    <p>Bu matn.</p>

    </body>`, excss: ``, exjs: ``
  },
  {
    id: 2, exhtml: `
<head>
<title>Sahifa sarlavhasi</title>
</head>
<body>

<h1>Mening birinchi sarlavham</h1>
<p>Mening birinchi xatboshim</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 3, exhtml: `
  <body>

  <h1>Bu sarlavha 1</h1>
  <h2>Bu sarlavha 2</h2>
  <h3>Bu sarlavha 3</h3>
  <h4>Bu sarlavha 4</h4>
  <h5>Bu sarlavha 5</h5>
  <h6>Bu sarlavha 6</h6>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 4, exhtml: `
<body>

<p>Bu xatboshi</p>
<p>Bu boshqa xatboshi</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 5, exhtml: `
<body>

<h2>HTML havolalari</h2>
<p>HTML havolalari a tegi bilan belgilanadi:</p>

<a href="http://self-study.uz/">a-bu havola</a>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 6, exhtml: `
<body>

<h2>HTML Rasmlar</h2>
<p>HTML rasmlari img tegi bilan belgilanadi.</p>

<img src="https://yt3.ggpht.com/JqhCR8X5zboindr5wD5jLOk0bX2gPX3dRHFSAK7sAwcqDF7SLZFFB2BsTEe6HN6_wEQSGHyq0g=s600-c-k-c0x00ffffff-no-rj-rp-mo" alt="VS Code" width="300" height="300">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 7, exhtml: `
<body>

<h1>Mening birinchi sarlavham</h1>
<p>Mening birinchi xatboshim</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 8, exhtml: `
<body>

<p>Bu xatboshi
<p>Bu ham xatboshi

</body>`, excss: ``, exjs: ``
  },
  {
    id: 9, exhtml: `
<body>

<p>Mana bu  <br> matn keyingi satrga tushirildi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 10, exhtml: `
<body>

<h2>href atributi</h2>

<p>Htmlda giperhavola uchun a tegidan foydalaniladi. href atributida havolaning URL manzili ko'rsatiladi.</p>

<a href="https://self-study.uz">self-study.uz saytiga o'tish</a>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 11, exhtml: `
<body>

<h2>src atributi</h2>
<p>img tegi tasvir(rasm)ni HTML sahifasiga joylashtirish uchun ishlatiladi. src atributi orqali rasm manzili ko'rsatiladi: </p>

<img src="img_girl.jpg" width="500" height="600">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 12, exhtml: `
<body>

<h2>src atributi</h2>
<p>img tegi tasvir(rasm)ni HTML sahifasiga joylashtirish uchun ishlatiladi. src atributi orqali rasm manzili ko'rsatiladi: </p>

<img src="img_girl.jpg" width="500" height="600">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 13, exhtml: `
<body>

<h2>src atributi</h2>
<p>img tegi tasvir(rasm)ni HTML sahifasiga joylashtirish uchun ishlatiladi. src atributi orqali rasm manzili ko'rsatiladi: </p>

<img src="img_typo.jpg" alt="Girl with a jacket">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 14, exhtml: `
<body>

<h2>style atributi</h2>
<p>style atributi elementga stillar berish uchun ishlatiladi, misol uchun elementga rang beramiz:</p>

<p style="color:red;">Bu qizil rangli matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 15, exhtml: `
<body>

<h2 title="Men sarlavhaman">title atributi</h2>

<p title="Men maslahatchiman">Element ustiga sichqonchani bosganingizda title atributining qiymati maslahat sifatida ko'rsatiladi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 16, exhtml: `
<body>

<h1>Self-Study haqida</h1>

<p title=Self-Study sayti haqida>
Agar qiymatda bo'sh joylar mavjud bo'lsa, atribut qiymatini qo'shtirnoqlarsiz yozib bo'lmaydi.
</p>

<p><b>
Yuqoridagi matn ustiga sichqonchani olib kelsangiz,
brauzeringiz faqat sarlavhadagi birinchi so'zni ko'rsatadi.
</b></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 17, exhtml: `
<body>

<h2>Bittali yoki ikkitali qo'shtirnoq?</h2>
<p>Ba'zi hollarda, atribut qiymatining o'zi ikkitali qo'shtirnoqlarni o'z ichiga olgan bo'lsa, bitta qo'shtirnoqdan foydalanish kerak bo'ladi:</p>
<p>Effektni ko'rish uchun sichqonchani quyidagi paragraflar ustiga olib boring:</p>

<p title='John "ShotGun" Nelson'>John ikkitali qo'shtirnoq bilan</p>
<p title="John 'ShotGun' Nelson">John bittali qo'shtirnoq bilan</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 18, exhtml: `
<body>

<h1 style="font-size:60px;">Sarlavha 1</h1>

<p>Style atributi yordamida shrift o'lchamini ham o'zgartirsa bo'ladi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 19, exhtml: `
<body>

<p>
Html kodi ichida
bu paragraf
bir necha qator
qilib yozilgan, ammo
brauzer boshqacha qilib
ko'rsatadi.
</p>

<p>
Html kodi ichida
bu paragraf
bir     necha bo'sh     joy qoldirilib
yozilgan, ammo
brauzer      boshqacha qilib
ko'rsatadi.
</p>

<p>
Bu paragraf qatorlar soni brauzer oynasining o'chamiga bog'liq va brauzer oynasi o'lchami o'zgartirilsa qatorlar soni o'zgaradi.
</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 20, exhtml: `
<body>

<h1>Bu birinchi darajali sarlavha</h1>
<p>Bu matn</p>
<hr>

<h2>Bu ikkinchi darajali sarlavha</h2>
<p>Bu boshqa matn</p>
<hr>

<h2>Bu ikkinchi darajali sarlavha</h2>
<p>Bu boshqa matn</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 21, exhtml: `
<body>

<p>HTMLda qo'shimcha bo'shliq va qatorlar o'chiriladi:</p>

<p>

 Yolg'izim, yagonam deymi,

Topingan koshonam deymi,

O'zing meni ulug'lardan,

Ulug'imsan vatanim...

</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 22, exhtml: `
<body>

<p>HTMLda qo'shimcha bo'shliq va qatorlar saqlab qolinadi:</p>

<pre>

 Yolg'izim, yagonam deymi,

Topingan koshonam deymi,

O'zing meni ulug'lardan,

Ulug'imsan vatanim...

</pre>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 23, exhtml: `
<body>

<p>Men oddiy matnman</p>
<p style="color:red;">Men qizil matnman</p>
<p style="color:blue;">Men ko'k matnman</p>
<p style="font-size:50px;">Men Katta matnman</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 24, exhtml: `
<body style="background-color:powderblue;">

<h1>Bu sarlavha</h1>
<p>Bu matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 25, exhtml: `
<body>

<h1 style="background-color:powderblue;">Bu sarlavha</h1>
<p style="background-color:tomato;">Bu matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 26, exhtml: `
<body>

<h1 style="color:blue;">Bu sarlavha</h1>
<p style="color:red;">Bu matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 27, exhtml: `
<body>

<h1 style="font-family:verdana;">Bu sarlavha</h1>
<p style="font-family:courier;">Bu matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 28, exhtml: `
<body>

<h1 style="font-size:300%;">Bu sarlavha</h1>
<p style="font-size:160%;">Bu matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 29, exhtml: `
<body>

<h1 style="text-align:center;">O'rtada joylashgan sarlavha</h1>
<p style="text-align:center;">O'rtada yozilgan matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 30, exhtml: `
<body>

<p><b>Bu matn qalin shriftda yozilgan</b></p>
<p><i>Bu matn kursiv</i></p>
<p>Bu esa<sub> index</sub> va  <sup>daraja</sup></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 31, exhtml: `
<body>

<p>Bu oddiy matn.</p>

<p><b>Bu qalin matn.</b></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 32, exhtml: `
<body>

<p>Bu oddiy matn.</p>

<p><strong>Bu muhim matn!</strong></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 33, exhtml: `
<body>

<p>Bu oddiy matn.</p>

<p><i>Bu matn kursiv.</i></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 34, exhtml: `
<body>

<p>Bu oddiy matn.</p>

<p><em>Bu ta'kidlangan matn.</em></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 35, exhtml: `
<body>

<p>Bu oddiy matn.</p>

<p><small>Bu kichikroq matn.</small></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 36, exhtml: `
<body>

<p><mark>Kitob</mark>- bilim manbai.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 37, exhtml: `
<body>

<p>Men yoqtirgan rang <del>ko'k</del> qizil.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 38, exhtml: `
<body>

<p>Men yoqtirgan rang <del>ko'k</del> <ins>qizil</ins>.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 39, exhtml: `
<body>

<p>Bu <sub>indexdagi</sub> matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 40, exhtml: `
<body>

<p>Bu <sup>darajadagi</sup> matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 41, exhtml: `
<body>

<p>Bu yerda WWF veb-saytidan iqtibos:</p>
<blockquote cite="http://www.worldwildlife.org/who/index.html">
50 yil davomida WWF tabiatni himoya qilib keladi.
Tabiatni muhofaza qilish bo'yicha dunyodagi yetakchi tashkilot,
WWF 100 ta mamlakat tomonidan qo'llab-quvvatlanadi,
Qo'shma Shtatlarda 1,2 million va
dunyo bo'ylab 5 millionga yaqin a'zo.
</blockquote>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 42, exhtml: `
<body>

<p>Brauzerlar iqtiboslarni satr boshidan yozadi.</p>

<blockquote cite="http://www.worldwildlife.org/who/index.html">
Taxminan 60 yil davomida WWF tabiatni himoya qilib keladi. Tabiatni muhofaza qilish bo'yicha dunyoning yetakchi tashkiloti WWF 100 ta mamlakatda ishlaydi va uni Qo'shma Shtatlarda bir milliondan ortiq va butun dunyo bo'ylab besh millionga yaqin a'zolar qo'llab-quvvatlaydi.
</blockquote>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 43, exhtml: `
<body>

<p>Brauzerlar odatda q elementi atrofida qo'shtirnoq qo'yadi.</p>

<p>WWF maqsadi: <q>Odamlar tabiat bilan uyg'unlikda yashaydigan kelajakni qurish.</q></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 44, exhtml: `
<body>

<p><abbr title="Jahon sog'liqni saqlash tashkiloti">JSST</abbr> 1948 yilda tashkil etilgan.</p>

<p>Qisqartmalarni belgilash brauzerlar, tarjima tizimlari va qidiruv tizimlari uchun foydali ma'lumotlarni berishi mumkin.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 45, exhtml: `
<body>

<p>HTML manzil elementi hujjat yoki maqolaning aloqa ma'lumotlarini (muallif/egasini) belgilaydi.</p>

<address>
Jon Doe tomonidan yozilgan.<br>
Tashrif buyuring:<br>
Misol.com<br>
Box 564, Disneylend<br>
AQSH
</address>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 46, exhtml: `
<body>

<p>HTML cite elementi asar nomini belgilaydi.</p>
<p>Brauzerlar odatda cite elementlarini kursiv bilan ko'rsatadi.</p>

<img src="https://www.w3schools.com/html/img_the_scream.jpg" width="220" height="277" alt="Qichqiriq">
<p><cite>Qichqiriq</cite>, Edvard Munch. 1893 yilda chizilgan.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 47, exhtml: `
<body>

<p>Agar brauzeringiz bdoni qo'llab-quvvatlasa, keyingi qator o'ngdan chapga (rtl) yoziladi:</p>

<bdo dir="rtl">Ushbu qator o'ngdan chapga yoziladi</bdo>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 48, exhtml: `
<body>

<!-- Bu izoh -->
<p>Bu matn.</p>
<!-- Izohlar brauzerda ko'rinmaydi. -->

</body>`, excss: ``, exjs: ``
  },
  {
    id: 49, exhtml: `
<body>

<p>Bu xatboshi.</p>

<!-- <p>Bu boshqa xatboshi. </p> -->

<p>Bu ham xatboshi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 50, exhtml: `
<body>

<p>Bu xatboshi.</p>
<!--
<p>Bu rasmga qarang:</p>
<img border="0" src="trulli.jpg" alt="Trulli">
-->
<p>Bu ham xatboshi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 51, exhtml: `
<body>

<p>Bu <!-- ajoyib --> xatboshi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 52, exhtml: `
<body>

<h1 style="background-color:Tomato;">Tomato</h1>
<h1 style="background-color:Orange;">Orange</h1>
<h1 style="background-color:DodgerBlue;">DodgerBlue</h1>
<h1 style="background-color:MediumSeaGreen;">MediumSeaGreen</h1>
<h1 style="background-color:Gray;">Gray</h1>
<h1 style="background-color:SlateBlue;">SlateBlue</h1>
<h1 style="background-color:Violet;">Violet</h1>
<h1 style="background-color:LightGray;">LightGray</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 53, exhtml: `
<body>

<h1 style="background-color:DodgerBlue;">Salom Dunyo</h1>

<p style="background-color:Tomato;">
Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 54, exhtml: `
<body>

<h3 style="color:Tomato;">Salom Dunyo</h3>

<p style="color:DodgerBlue;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>

<p style="color:MediumSeaGreen;">Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 55, exhtml: `
<body>

<h1 style="border: 2px solid Tomato;">Salom Dunyo</h1>

<h1 style="border: 2px solid DodgerBlue;">Salom Dunyo</h1>

<h1 style="border: 2px solid Violet;">Salom Dunyo</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 56, exhtml: `
<body>

<p>Same as color name "Tomato":</p>

<h1 style="background-color:rgb(255, 99, 71);">rgb(255, 99, 71)</h1>
<h1 style="background-color:#ff6347;">#ff6347</h1>
<h1 style="background-color:hsl(9, 100%, 64%);">hsl(9, 100%, 64%)</h1>

<p>Same as color name "Tomato", but 50% transparent:</p>
<h1 style="background-color:rgba(255, 99, 71, 0.5);">rgba(255, 99, 71, 0.5)</h1>
<h1 style="background-color:hsla(9, 100%, 64%, 0.5);">hsla(9, 100%, 64%, 0.5)</h1>

<p>In addition to the predefined color names, colors can be specified using RGB, HEX, HSL, or even transparent colors using RGBA or HSLA color values.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 57, exhtml: `
<body>

<h1 style="background-color:rgb(255, 0, 0);">rgb(255, 0, 0)</h1>
<h1 style="background-color:rgb(0, 0, 255);">rgb(0, 0, 255)</h1>
<h1 style="background-color:rgb(60, 179, 113);">rgb(60, 179, 113)</h1>
<h1 style="background-color:rgb(238, 130, 238);">rgb(238, 130, 238)</h1>
<h1 style="background-color:rgb(255, 165, 0);">rgb(255, 165, 0)</h1>
<h1 style="background-color:rgb(106, 90, 205);">rgb(106, 90, 205)</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 58, exhtml: `
<body>

<h1 style="background-color:rgb(60, 60, 60);">rgb(60, 60, 60)</h1>
<h1 style="background-color:rgb(100, 100, 100);">rgb(100, 100, 100)</h1>
<h1 style="background-color:rgb(140, 140, 140);">rgb(140, 140, 140)</h1>
<h1 style="background-color:rgb(180, 180, 180);">rgb(180, 180, 180)</h1>
<h1 style="background-color:rgb(200, 200, 200);">rgb(200, 200, 200)</h1>
<h1 style="background-color:rgb(240, 240, 240);">rgb(240, 240, 240)</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 59, exhtml: `
<body>

<h1 style="background-color:rgba(255, 99, 71, 0);">rgba(255, 99, 71, 0)</h1>
<h1 style="background-color:rgba(255, 99, 71, 0.2);">rgba(255, 99, 71, 0.2)</h1>
<h1 style="background-color:rgba(255, 99, 71, 0.4);">rgba(255, 99, 71, 0.4)</h1>
<h1 style="background-color:rgba(255, 99, 71, 0.6);">rgba(255, 99, 71, 0.6)</h1>
<h1 style="background-color:rgba(255, 99, 71, 0.8);">rgba(255, 99, 71, 0.8)</h1>
<h1 style="background-color:rgba(255, 99, 71, 1);">rgba(255, 99, 71, 1)</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 60, exhtml: `
<body>

<h1 style="background-color:#ff0000;">#ff0000</h1>
<h1 style="background-color:#0000ff;">#0000ff</h1>
<h1 style="background-color:#3cb371;">#3cb371</h1>
<h1 style="background-color:#ee82ee;">#ee82ee</h1>
<h1 style="background-color:#ffa500;">#ffa500</h1>
<h1 style="background-color:#6a5acd;">#6a5acd</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 61, exhtml: `
<body>

<h1 style="background-color:#404040;">#404040</h1>
<h1 style="background-color:#686868;">#686868</h1>
<h1 style="background-color:#a0a0a0;">#a0a0a0</h1>
<h1 style="background-color:#bebebe;">#bebebe</h1>
<h1 style="background-color:#dcdcdc;">#dcdcdc</h1>
<h1 style="background-color:#f8f8f8;">#f8f8f8</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 62, exhtml: `
<body>

<h1 style="background-color:hsl(0, 100%, 50%);">hsl(0, 100%, 50%)</h1>
<h1 style="background-color:hsl(240, 100%, 50%);">hsl(240, 100%, 50%)</h1>
<h1 style="background-color:hsl(147, 50%, 47%);">hsl(147, 50%, 47%)</h1>
<h1 style="background-color:hsl(300, 76%, 72%);">hsl(300, 76%, 72%)</h1>
<h1 style="background-color:hsl(39, 100%, 50%);">hsl(39, 100%, 50%)</h1>
<h1 style="background-color:hsl(248, 53%, 58%);">hsl(248, 53%, 58%)</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 63, exhtml: `
<body>

<h1 style="background-color:hsl(0, 100%, 50%);">hsl(0, 100%, 50%)</h1>
<h1 style="background-color:hsl(0, 80%, 50%);">hsl(0, 80%, 50%)</h1>
<h1 style="background-color:hsl(0, 60%, 50%);">hsl(0, 60%, 50%)</h1>
<h1 style="background-color:hsl(0, 40%, 50%);">hsl(0, 40%, 50%)</h1>
<h1 style="background-color:hsl(0, 20%, 50%);">hsl(0, 20%, 50%)</h1>
<h1 style="background-color:hsl(0, 0%, 50%);">hsl(0, 0%, 50%)</h1>

<p> HSL ranglarida kamroq to'yinganlik kamroq rangni anglatadi. 0% butunlay kulrang.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 64, exhtml: `
<body>

<h1 style="background-color:hsl(0, 100%, 0%);">hsl(0, 100%, 0%)</h1>
<h1 style="background-color:hsl(0, 100%, 25%);">hsl(0, 100%, 25%)</h1>
<h1 style="background-color:hsl(0, 100%, 50%);">hsl(0, 100%, 50%)</h1>
<h1 style="background-color:hsl(0, 100%, 75%);">hsl(0, 100%, 75%)</h1>
<h1 style="background-color:hsl(0, 100%, 90%);">hsl(0, 100%, 90%)</h1>
<h1 style="background-color:hsl(0, 100%, 100%);">hsl(0, 100%, 100%)</h1>

<p>HSL ranglarida 0% ochlik qora, 100 yorug'lik esa oq degan ma'noni anglatadi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 65, exhtml: `
<body>

<h1 style="background-color:hsl(0, 0%, 20%);">hsl(0, 0%, 20%)</h1>
<h1 style="background-color:hsl(0, 0%, 30%);">hsl(0, 0%, 30%)</h1>
<h1 style="background-color:hsl(0, 0%, 40%);">hsl(0, 0%, 40%)</h1>
<h1 style="background-color:hsl(0, 0%, 60%);">hsl(0, 0%, 60%)</h1>
<h1 style="background-color:hsl(0, 0%, 70%);">hsl(0, 0%, 70%)</h1>
<h1 style="background-color:hsl(0, 0%, 90%);">hsl(0, 0%, 90%)</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 66, exhtml: `
<body>

<h1 style="background-color:hsla(9, 100%, 64%, 0);">hsla(9, 100%, 64%, 0)</h1>
<h1 style="background-color:hsla(9, 100%, 64%, 0.2);">hsla(9, 100%, 64%, 0.2)</h1>
<h1 style="background-color:hsla(9, 100%, 64%, 0.4);">hsla(9, 100%, 64%, 0.4)</h1>
<h1 style="background-color:hsla(9, 100%, 64%, 0.6);">hsla(9, 100%, 64%, 0.6)</h1>
<h1 style="background-color:hsla(9, 100%, 64%, 0.8);">hsla(9, 100%, 64%, 0.8)</h1>
<h1 style="background-color:hsla(9, 100%, 64%, 1);">hsla(9, 100%, 64%, 1)</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 67, exhtml: `
<body>

<h1 style="color:blue;">Ko'k sarlavha</h1>

<p style="color:red;">Qizil rangli matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 68, exhtml: `
<head>
<style>
body {background-color: powderblue;}
h1   {color: blue;}
p    {color: red;}
</style>
</head>
<body>

<h1>Bu sarlavha</h1>
<p>Bu esa matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 69, exhtml: `
<head>
  <link rel="stylesheet" href="styles.css">
</head>
<body>

<h1>Bu sarlavha</h1>
<p>Bu es matn.</p>

</body>`, excss: `body {
  background-color: powderblue;
}
h1 {
  color: blue;
}
p {
  color: red;
}`, exjs: ``
  },
  {
    id: 70, exhtml: `
<head>
<style>
h1 {
  color: blue;
  font-family: verdana;
  font-size: 300%;

}
p  {
  color: red;
  font-family: courier;
  font-size: 160%;
}
</style>
</head>
<body>

<h1>Bu sarlavha</h1>
<p>Bu matn</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 71, exhtml: `
<head>
<style>
p {
  border: 2px solid powderblue;
}
</style>
</head>
<body>

<h1>Bu sarlavha</h1>

<p>Birinchi matn.</p>
<p>Ikkinchi matn.</p>
<p>Oxirgi matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 72, exhtml: `
<head>
<style>
p {
  border: 2px solid powderblue;
  padding: 30px;
}
</style>
</head>
<body>

<h1>Bu sarlavha</h1>

<p>Bu birinchi matn.</p>
<p>Bu ikkinchi matn.</p>
<p>Bu oxirgi matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 73, exhtml: `
<head>
<style>
p {
  border: 2px solid powderblue;
  margin: 50px;
}
</style>
</head>
<body>
<h1>Bu sarlavha</h1>

<p>Bu birinchi matn.</p>
<p>Bu ikkinchi matn.</p>
<p>Bu oxirgi matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 74, exhtml: `
<head>
  <link rel="stylesheet" href="https://self-study.uz/html/styles.css">
</head>
<body>

<h1>Bu sarlavha</h1>

<p>Bu matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 75, exhtml: `
<head>
  <link rel="stylesheet" href="/html/styles.css">
</head>
<body>

<h1>Bu sarlavha</h1>

<p>Bu matn.</p>

</body>`, excss: `body {
  background-color: powderblue;
}
h1 {
  color: blue;
}
p {
  color: red;
}`, exjs: ``
  },
  {
    id: 76, exhtml: `
<head>
<link rel="stylesheet" href="styles.css">
</head>
<body>

<h1>Bu sarlavha</h1>

<p>Bu matn.</p>

</body>`, excss: `body {
  background-color: powderblue;
}
h1 {
  color: blue;
}
p {
  color: red;
}`, exjs: ``
  },
  {
    id: 77, exhtml: `
<head>
  <link rel="stylesheet" href="styles.css">
</head>
<body>

<h1>Salom dunyo!</h1>

<h2>Menga tashqi stillar link orqali biriktirilgan.</h2>

<p>Menga ham!</p>

</body>`, excss: `
body {
  background-color: lightblue;
}

h1,h2 {
  color: white;
  text-align: center;
}

p {
  font-family: verdana;
  font-size: 20px;
}`, exjs: ``
  },
  {
    id: 78, exhtml: `
<body>

<h2>Target atributi</h2>

<a href="https://self-study.uz/" target="_blank">self-study.uzga o'tish!</a>

<p> target="_blank" qiymati berilsa, hujjatni yangi oyna yoki tabda ochadi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 79, exhtml: `
<body>

<h2>Absolyut URL</h2>
<p><a href="https://self-study.uz/">Self-study sayti</a></p>
<p><a href="https://www.google.com/">Google</a></p>

<h2>Relyativ URL</h2>
<p><a href="/html/html_css">Css mavzusi</a></p>
<p><a href="/html/html_images">Rasmlar mavzusi</a></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 80, exhtml: `
<body>

<h2>Rasmli havola</h2>

<p>Rasmli havolani bosing</p>

<a href="/html"><img src="smile.png" alt="HTML mavzulari" style="width:42px;height:42px;"></a>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 81, exhtml: `
<body>

<h2>Elektron pochta manziliga havola</h2>

<p>Foydalanuvchining elektron pochta dasturini ochadigan havola yaratish uchun href atributi ichida mailto: foydalaning (yangi elektron pochta xabarini yuborish uchun)</p>

<p><a href="mailto:someone@example.com">Send email</a></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 82, exhtml: `
<body>

<h2>Havola tugmasi</h2>

<p>Html mavzulari tugmasi bosilsin:</p>

<button onclick="document.location='/html'">HTML mavzulari</button>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 83, exhtml: `
<body>

<h2>Havola title atributi</h2>
<p>title atribut element haqida qo'shimcha ma'lumotni belradi. Ma'lumot ko'pincha sichqonchani element ustida harakatlantirganda ko'rinadi.</p>
<a href="https://self-study.uz/html" title="Self-study sayti HTML bo'limiga o'tish">Self-study saytiga o'tish</a>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 84, exhtml: `
<body>

<h2>Tashqi murojaat</h2>

<p>Ushbu misolda veb-sahifaga ulanish uchun to'liq URL ishlatildi:</p>
<p><a href="https://self-study.uz/html/">HTML mavzulari</a></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 85, exhtml: `
<body>

<h2>Tashqi murojaat</h2>

<p>Ushbu misol joriy veb-saytdagi html papkasida joylashgan sahifaga havola qiladi:</p>

<p><a href="/html/html_intro">HTML mavzulari</a></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 86, exhtml: `
<body>

<h2>Tashqi murojaat</h2>

<p>Joriy sahifa bilan bir xil papkada joylashgan sahifaga havola: </p>

<p><a href="/html">Html mavzulari</a></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 87, exhtml: `
<head>
<style>
a:link {
  color: green;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: pink;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}
a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}
</style>
</head>
<body>

<h2>Link Colors</h2>

<p>You can change the default colors of links</p>

<a href="/html" target="_blank">HTML</a>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 88, exhtml: `
<head>
<style>
a:link, a:visited {
  background-color: #f44336;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

a:hover, a:active {
  background-color: red;
}
</style>
</head>
<body>

<h2>Link Button</h2>
<p>A link styled as a button:</p>
<a href="/html" target="_blank">Bu havola</a>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 89, exhtml: `
<body>

<p><a href="#C4">4-bo'limga o'tish</a></p>
<p><a href="#C10">10-bo'limga o'tish</a></p>

<h2>1-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>2-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>3-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2 id="C4">4-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>5-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>6-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>7-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>8-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>9-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2 id="C10">10-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>11-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>12-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>13-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>14-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>15-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>16-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>17-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>18-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>19-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>20-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>21-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>22-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

<h2>23-bo'lim</h2>
<p>Bu misol tariqasida berilgan matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 90, exhtml: `
<body>

<h1>CSS stillari yordamida matnni qalin qilish</h1>

<p>Bu oddiy matn- <span style="font-weight:bold;">va bu qalin matn</span>.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 91, exhtml: `
<head>
<style>
b {
  font-weight: bold;
}
</style>
</head>
<body>

<p>B elementi quyidagicha ko'rinadi:</p>

<p><b>Bu qalin matn.</b></p>

<p>Css yordamida default holati o'zgartirildi.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 92, exhtml: `
<body>

<h2>HTML rasmlari</h2>
<img src="img_girl.jpg" alt="Girl in a jacket" width="500" height="600">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 93, exhtml: `
<body>

<h2>HTMLrasmlari</h2>
<img src="chania.jpg" alt="Flowers in Chania" width="460" height="345">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 94, exhtml: `
<body>

<h2>HTML rasmlari</h2>
<img src="trulli.jpg" alt="Trulli" width="500" height="333">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 95, exhtml: `
<body>

<h2>Alternativ matn</h2>

<p>Alt atributi rasm mazmunini aks ettirishi kerak, shunda tasvirni ko'ra olmaydigan foydalanuvchilar tasvirda nima borligini tushunishadi:</p>

<img src="chania.jpg" alt="Flowers in Chania" width="460" height="345">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 96, exhtml: `
<body>

<p> Agar brauzer rasmni topa olmasa, alternativ matn ko'rsatiladi :</p>

<img src="wrongname.gif" alt="Flowers in Chania" width: 200 height: 200>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 97, exhtml: `
<body>

<h2>Rasm o'lchami</h2>

<p>Bu yerda tasvirning kengligi va balandligini belgilash uchun style atributidan foydalanamiz:</p>

<img src="img_girl.jpg" alt="Girl in a jacket" style="width:500px;height:600px;">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 98, exhtml: `
<body>

<h2>Rasm o'lchami</h2>

<p>Bu yerda biz kenglik va balandlik atributlari yordamida tasvirning kengligi va balandligini belgilaymiz:</p>

<img src="img_girl.jpg" alt="Girl in a jacket" width="500" height="600">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 99, exhtml: `
<head>
<style>
/* Ushbu still barcha tasvirlarning kengligini 100% ga o'rnatadi: */
img {
  width: 100%;
}
</style>
</head>
<body>

<h2>Width, height yoki style</h2>

<p>Birinchi rasmda width atributidan foydalanildi (128 pikselga o'rnatiladi), lekin head qismidagi style uni bekor qiladi va kenglikni 100% ga o'rnatadi.</p>

<img src="htmlIcon.png" alt="HTML5 Icon" width="128" height="128">

<p>Ikkinchi rasm kenglikni 128 pikselga o'rnatish uchun style atributidan foydalanadi, bu head qismidagi style tomonidan bekor qilinmaydi:</p>

<img src="htmlIcon.png" alt="HTML5 Icon" style="width:128px;height:128px;">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 100, exhtml: `
<body>

<h2>Boshqa papkadagi rasmlar</h2>
<p>Agar sizning rasmlaringiz boshqa papkada joylashgan bo'lsa, src atributga jild nomini ham kiritishingiz kerak:</p>

<img src="/image/htmlIcon.png" alt="HTML5 Icon" style="width:128px;height:128px;">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 101, exhtml: `
<body>

<h2>Boshqa serverdagi rasm</h2>

<img src="https://www.w3schools.com/images/w3schools_green.jpg" alt="W3Schools.com" style="width:104px;height:142px;">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 102, exhtml: `
<body>

<h2>Animated Images</h2>

<p>HTML allows moving images:</p>

<img src="programming.gif" alt="Computer man" style="width:48px;height:48px;">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 103, exhtml: `
<body>

<h2>Rasmli havola</h2>

<p>Rasmli havolani bosing.</p>

<a href="/html">
<img src="smile.png" alt="HTML tutorial" style="width:42px;height:42px;">
</a>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 104, exhtml: `
<body>

<h2>Rasm joylashuvini o'zgartirish</h2>
<p><strong>Rasm joylashuvini o'ng tarafga o'zgartirish:</strong></p>

<p>
<img src="smile.png" alt="Smiley face" style="float:right;width:42px;height:42px;">
Bu matn joylashuvi o'zgaruvchi rasm bilan birga yozilgan.Bu matn joylashuvi o'zgaruvchi rasm bilan birga yozilgan.Bu matn joylashuvi o'zgaruvchi rasm bilan birga yozilgan.
</p>

<p><strong>Rasm joylashuvini chap tarafga o'zgartirish:</strong></p>
<p>
<img src="smile.png" alt="Smiley face" style="float:left;width:42px;height:42px;">
Bu matn joylashuvi o'zgaruvchi rasm bilan birga yozilgan.Bu matn joylashuvi o'zgaruvchi rasm bilan birga yozilgan.Bu matn joylashuvi o'zgaruvchi rasm bilan birga yozilgan.
</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 105, exhtml: `
<body>

<h2>Rasm xaritalari</h2>
<p>Yangi sahifaga o'tish va mavzu haqida ko'proq o'qish uchun kompyuter, telefon yoki qahva ustiga bosing</p>

<img src="workplace.jpg" alt="Workplace" usemap="#workmap" width="400" height="379">

<map name="workmap">
  <area shape="rect" coords="34,44,270,350" alt="Computer" href="computer.htm">
  <area shape="rect" coords="290,172,333,250" alt="Phone" href="phone.htm">
  <area shape="circle" coords="337,300,44" alt="Cup of coffee" href="coffee.htm">
</map>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 106, exhtml: `
<body>

<h2>Rasm xaritalari</h2>
<p>Yangi sahifaga o'tish va mavzu haqida ko'proq o'qish uchun kompyuter ustiga bosing</p>

<img src="workplace.jpg" alt="Workplace" usemap="#workmap" width="400" height="379">

<map name="workmap">
  <area shape="rect" coords="34,44,270,350" alt="Computer" href="computer.htm">
</map>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 107, exhtml: `
<body>

<h2>Rasm xaritalari</h2>
<p>Yangi sahifaga o'tish va mavzu haqida ko'proq o'qish uchun qahva ustiga bosing</p>


<img src="workplace.jpg" alt="Workplace" usemap="#workmap" width="400" height="379">

<map name="workmap">
  <area shape="circle" coords="337,300,44" alt="Cup of coffee" href="coffee.htm">
</map>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 108, exhtml: `
<body>

<h2>Rasm xaritalari</h2>
<p>Yangi sahifaga o'tish va mavzu haqida ko'proq o'qish uchun kruvasan ustiga bosing:</p>

<img src="frenchfood.jpg" alt="French Food" usemap="#foodmap" width="450" height="675">

<map name="foodmap">
  <area shape="poly" coords="140,121,181,116,204,160,204,222,191,270,140,329,85,355,58,352,37,322,40,259,103,161,128,147" alt="Croissant" href="croissant.htm">
</map>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 109, exhtml: `
<body>

<h2>Image Maps</h2>

<p>Click on the cup of coffee to execute a JavaScript function:</p>

<img src="workplace.jpg" alt="Workplace" usemap="#workmap" width="400" height="379">

<map name="workmap">
  <area shape="circle" coords="337,300,44" href="coffee.htm" onclick="myFunction()">
</map>

<script>
function myFunction() {
  alert("You clicked the coffee cup!");
}
</script>

</body>
`, excss: ``, exjs: ``
  },

  {
    id: 110, exhtml: `
<body>

<h2>Orqa-fon rasmi</h2>

<p>p elementi uchun orqa-fon rasmi:</p>

<p style="background-image: url('img_girl.jpg');">
Har qanday ko'rinadigan HTML elementi uchun.<br>
fon rasmlarini belgilashingiz mumkin<br>
Ushbu misolda p element uchun <br>
orqa-fon rasmi berilgan.<br>
Odatda, orqa-fon rasmi<br>
elementdan kichikroq bo'lsa<br>
 takrorlanadi<br>
 (brauzer oynasi<br>
  oʻlchamini oʻzgartirib koʻring)
</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 111, exhtml: `
<head>
<style>
p {
  background-image: url('img_girl.jpg');
}
</style>
</head>
<body>

<h2>Background Image</h2>

<p>Har qanday ko'rinadigan HTML elementi uchun.<br>
fon rasmlarini belgilashingiz mumkin<br>
Ushbu misolda p element uchun <br>
orqa-fon rasmi berilgan.<br>
Odatda, orqa-fon rasmi<br>
elementdan kichikroq bo'lsa<br>
 takrorlanadi<br>
 (brauzer oynasi<br>
  oʻlchamini oʻzgartirib koʻring)</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 112, exhtml: `
<head>
<style>
body {
  background-image: url('img_girl.jpg');
}
</style>
</head>
<body>

<h2>Orqa-fon rasmi</h2>

<p>Agar orqa-fon rasmi elementdan kichikroq bo'lsa, rasm elementning oxirigacha gorizontal va vertikal ravishda takrorlanadi.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 113, exhtml: `
<head>
<style>
body {
  background-image: url('img_girl.jpg');
  background-repeat: no-repeat;
}
</style>
</head>
<body>

<h2>Orqa-fon rasmi takrorlanmasligi</h2>

<p>Orqa-fon rasmi takrorlanmasligi uchun background-repeat xususiyatiga "no-repeat" qiymati beriladi.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 114, exhtml: `
<head>
<style>
body {
  background-image: url('img_girl.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>
</head>
<body>

<h2>Orqa-fon qoplanishi</h2>

<p>Orqa-fon rasmi butun elementni qoplab olishi uchun  background-size xususiyatiga cover qiymati berish kerak.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 115, exhtml: `
<head>
<style>
body {
  background-image: url('img_girl.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}
</style>
</head>
<body>

<h2>Background Stretch</h2>

<p>Set the background-size property to "100% 100%" and the background image will be stretched to cover the entire element, in this case the body element.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 116, exhtml: `
<head>
<style>
body {
  background-image: url('img_girl.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}
</style>
</head>
<body>

<h2>Orqa-fon rasmi cho'zilishi</h2>

<p>Brauzer oynasining o'lchamini o'zgartirib ko'ring va siz tasvirning cho'zilishini ko'rasiz, lekin har doim butun elementni qamrab oladi.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 117, exhtml: `
<head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>

<h2>picture elementi</h2>

<picture>
  <source media="(min-width: 650px)" srcset="img_food.jpg">
  <source media="(min-width: 465px)" srcset="img_car.jpg">
  <img src="img_girl.jpg" style="width:auto;">
</picture>

<p>Turli xil ko'rish oynasi o'lchamlarida yuklangan rasmning turli versiyalarini ko'rish uchun brauzer hajmini o'zgartiring.
Brauzer media so'rovi foydalanuvchining joriy ko'rish maydoni kengligiga mos keladigan birinchi source elementini qidiradi,
va srcset atributida belgilangan rasmni oladi.</p>

<p>Img elementi picture elementining oxirgi pastki tegi sifatida berish talab qilinadi.
Img elementi rasm elementini qo'llab-quvvatlamaydigan yoki manba teglaridan hech biri mos kelmagan brauzerlar uchun  muvofiqlikni ta'minlash uchun ishlatiladi.
</p>

<p><strong>Eslatma:</strong> Rasm elementi IE12 va undan oldingi versiyalarda yoki Safari 9.0 va undan oldingi versiyalarida qo'llab-quvvatlanmaydi.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 118, exhtml: `
<head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>

<h2>picture Element</h2>

<picture>
  <source srcset="img_avatar.png">
  <source srcset="img_girl.jpg">
  <img src="img_beatles.gif" alt="Beatles" style="width:auto;">
</picture>

<p>Ba'zi brauzerlar yoki qurilmalar barcha rasm formatlarini qo'llab-quvvatlamasligi mumkin.</p>

<p>brauzer o'zi tan olgan birinchi formatdan foydalanadi va qolgan elementlarni e'tiborsiz qoldiradi.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 119, exhtml: `
<body>

<h1>map and area elementlari</h1>

<p>Quyosh va planetalarni bosing:</p>

<img src="planets.gif" width="145" height="126" alt="Planets" usemap="#planetmap">

<map name="planetmap">
  <area shape="rect" coords="0,0,82,126" alt="Sun" href="sun.htm">
  <area shape="circle" coords="90,58,3" alt="Mercury" href="mercur.htm">
  <area shape="circle" coords="124,58,8" alt="Venus" href="venus.htm">
</map>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 120, exhtml: `
<body>

<h1>Rasmni tekislash (CSS bilan)</h1>

<h2>vertical-align: bottom</h2>
<p>Bu matn. <img src="smile.png" alt="Smiley face" width="42" height="42" style="vertical-align:bottom"> Bu matn. Bu matn. Bu matn.</p>

<h2>vertical-align: middle</h2>
<p>Bu matn. <img src="smile.png" alt="Smiley face" width="42" height="42" style="vertical-align:middle"> Bu matn. Bu matn. Bu matn.</p>

<h2>vertical-align: top</h2>
<p>Bu matn. <img src="smile.png" alt="Smiley face" width="42" height="42" style="vertical-align:top"> Bu matn. Bu matn. Bu matn.</p>

<h2>float: right</h2>
<p>Bu matn. <img src="smile.png" alt="Smiley face" width="42" height="42" style="float:right">Bu matn. Bu matn. Bu matn.</p>

<h2>float: left</h2>
<p>Bu matn. <img src="smile.png" alt="Smiley face" width="42" height="42" style="float:left">Bu matn. Bu matn. Bu matn.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 121, exhtml: `
<body>

<img src="smile.png" alt="Smiley face" width="42" height="42" style="border:5px solid black">

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 122, exhtml: `
<body>

<h1>CSS yordamida margin qo'shish</h1>

<h2>Marginsiz rasm</h2>
<p><img src="smile.png" alt="Smiley face" width="42" height="42"> Bu matn. Bu matn. Bu matn.</p>

<h2>Chap va o'ngdan marginli rasm</h2>
<p><img src="smile.png" alt="Smiley face" width="42" height="42" style="vertical-align:middle;margin:0px 50px">Bu matn. Bu matn. Bu matn.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 123, exhtml: `
<body>

<h1>CSS yordamida margin berish</h1>

<h2>Marginsiz rasm</h2>
<p><img src="smile.png" alt="Smiley face" width="42" height="42"> Bu matn. Bu matn. Bu matn.</p>

<h2>Tepadan va pastdan margin berilgan rasm</h2>
<p><img src="smile.png" alt="Smiley face" width="42" height="42" style="vertical-align:middle;margin:50px 0px">Bu matn. Bu matn. Bu matn.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 124, exhtml: `
<body>

<h1>CSS yordamida margin berish</h1>

<h2>Marginsiz rasm</h2>
<p><img src="smile.png" alt="Smiley face" width="42" height="42"> Bu matn. Bu matn. Bu matn.</p>

<h2>Tepadan va pastdan margin berilgan rasm</h2>
<p><img src="smile.png" alt="Smiley face" width="42" height="42" style="vertical-align:middle;margin:50px 0px">Bu matn. Bu matn. Bu matn.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 125, exhtml: `
<head>
<style>
img {
  display: inline-block;
}
</style>
</head>
<body>

<p>img elementi quyidagicha ko'rinadi:</p>

<p>Bu matn <img src="smile.png" alt="Smiley face" width="42" height="42"> Bu matn.</p>

<p>Default stillarini o'zgartirib ko'ring.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 126, exhtml: `
<head>
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
</head>
<body>

<h2>HTML Jadvali</h2>

<table>
  <tr>
    <th>Kompaniya</th>
    <th>Kontakt</th>
    <th>Mamlakat</th>
  </tr>
  <tr>
    <td>Alfreds Futterkiste</td>
    <td>Maria Anders</td>
    <td>Germaniya</td>
  </tr>
  <tr>
    <td>Centro comercial Moctezuma</td>
    <td>Francisco Chang</td>
    <td>Meksika</td>
  </tr>
  <tr>
    <td>Ernst Handel</td>
    <td>Roland Mendel</td>
    <td>Avstriya</td>
  </tr>
  <tr>
    <td>Island Trading</td>
    <td>Helen Bennett</td>
    <td>Buyuk Britaniya</td>
  </tr>
  <tr>
    <td>Laughing Bacchus Winecellars</td>
    <td>Yoshi Tannamuri</td>
    <td>Kanada</td>
  </tr>
  <tr>
    <td>Magazzini Alimentari Riuniti</td>
    <td>Giovanni Rovelli</td>
    <td>Italiya</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 127, exhtml: `
<style>
table, th, td {
  border:1px solid black;
}
</style>
<body>

<h2>Oddiy Html jadvali</h2>

<table style="width:100%">
  <tr>
    <th>Kompaniya</th>
    <th>Kontakt</th>
    <th>Mamlakat</th>
  </tr>
  <tr>
    <td>Alfreds Futterkiste</td>
    <td>Maria Anders</td>
    <td>Germaniya</td>
  </tr>
  <tr>
    <td>Centro comercial Moctezuma</td>
    <td>Francisco Chang</td>
    <td>Meksika</td>
  </tr>
</table>

<p>Tushunarli bo'lishi uchun jadvalga border berilgan.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 128, exhtml: `
<style>
table, th, td {
  border:1px solid black;
}
</style>
<body>

<h2>TD elementi katakni anglatadi.</h2>

<table style="width:100%">
  <tr>
    <td>Emil</td>
    <td>Tobias</td>
    <td>Linus</td>
  </tr>
</table>

<p>Tushunarli bo'lishi uchun jadvalga border berilgan.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 129, exhtml: `
<style>
table, th, td {
  border:1px solid black;
}
</style>
<body>

<h2>TD elementi katakni anglatadi.</h2>

<table style="width:100%">
  <tr>
    <td>Emil</td>
    <td>Tobias</td>
    <td>Linus</td>
  </tr>
<tr>
  <td>16</td>
  <td>14</td>
  <td>10</td>
</tr>
</table>

<p>Tushunarli bo'lishi uchun jadvalga border berilgan.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 130, exhtml: `
<style>
table, th, td {
  border:1px solid black;
}
</style>
<body>

<h2>TD elementi katakni anglatadi.</h2>

<table style="width:100%">
<tr>
<th>Shaxs 1</th>
<th>Shaxs 2</th>
<th>Shaxs 3</th>
</tr>
  <tr>
    <td>Emil</td>
    <td>Tobias</td>
    <td>Linus</td>
  </tr>
<tr>
  <td>16</td>
  <td>14</td>
  <td>10</td>
</tr>
</table>

<p>Tushunarli bo'lishi uchun jadvalga border berilgan.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 131, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>table elementi</h1>

<table>
  <tr>
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
  <td>Yanvar</td>
  <td>$100</td>
</tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 132, exhtml: `
<head>
<style>
table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
}
</style>
</head>
<body>

<table>
<tr>
<th>Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 133, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h1>Collapse borderga ega jadval</h1>

<table>
<tr>
<th>Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 134, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Jadvalni o'ng tomonga joylashtirish(CSS bilan):</h1>

<table style="float:right">
<tr>
<th>Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>
<p>Bu matn jadval yonida joylashgan. Bu matn jadval yonida joylashgan. Bu matn jadval yonida joylashgan.</p>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 135, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
table.center {
  margin-left: auto;
  margin-right: auto;
}
</style>
</head>
<body>

<h1>Jadvalni markazga joylashtirish(CSS bilan):</h1>
<p>Jadvalni markazga joylashtirish(CSS bilan) uchun margin-right va margin-left ga auto qiymati beriladi:</p>

<table class="center">
<tr>
<th>Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 136, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Jadval orqa-foniga rang berish</h1>

<table style="background-color:#00FF00">
<tr>
<th>Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 137, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 10px;
}
</style>
</head>
<body>

<h1>Jadvalga padding berish:</h1>

<table>
<tr>
<th>Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 138, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Jadvalga width berish</h1>

<table style="width:400px">
<tr>
<th>Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 139, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Jadval sarlavhalari</h1>

<h2>Gorizontal sarlavha:</h2>
<table>
  <tr>
    <th>Ism</th>
    <th>Email</th>
    <th>Telefon</th>
  </tr>
  <tr>
    <td>John Doe</td>
    <td>john.doe@example.com</td>
    <td>123-45-678</td>
  </tr>
</table>

<h2>Vertikal sarlavha:</h2>
<table>
  <tr>
    <th>Ism:</th>
    <td>John Doe</td>
  </tr>
  <tr>
    <th>Email:</th>
    <td>john.doe@example.com</td>
  </tr>
  <tr>
    <th>Telefon:</th>
    <td>123-45-678</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 140, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>caption elementi</h1>

<table>
  <caption>Oylik jamg'armalar</caption>
  <tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
  </tr>
  <tr>
  <td>Yanvar</td>
  <td>$100</td>
  </tr>
    <tr>
      <td>Fevral</td>
      <td>$80</td>
    </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 141, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Colspan va rowspan</h1>

<h3>Bir yecheykada 2 ta ustun:</h3>
<table>
  <tr>
    <th>Ism</th>
    <th>Email</th>
    <th colspan="2">Telefon</th>
  </tr>
  <tr>
    <td>John Doe</td>
    <td>john.doe@example.com</td>
    <td>123-45-678</td>
    <td>212-00-546</td>
  </tr>
</table>

<h3>Cell that spans two rows:</h3>
<table>
  <tr>
    <th>Ism:</th>
    <td>John Doe</td>
  </tr>
  <tr>
    <th>Email:</th>
    <td>john.doe@example.com</td>
  </tr>
  <tr>
    <th rowspan="2">Telefon:</th>
    <td>123-45-678</td>
  </tr>
  <tr>
    <td>212-00-546</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 142, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>th elementi</h1>

<p>th elementi jadvaldagi sarlavha katakchasini belgilaydi:</p>

<table>
<tr>
<th>Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 143, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>th ichidagi tarkibni chap tomonga tekislash</h1>

<table style="width:100%">
  <tr>
    <th style="text-align:left">Oylar</th>
    <th style="text-align:left">Jamg'arma</th>
  </tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 144, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Jadval sarlavhasi katakchasiga fon rangini qanday qo'shish mumkin (CSS bilan)</h1>

<table>
  <tr>
    <th style="background-color:#FF0000">Oylar</th>
    <th style="background-color:#00FF00">Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 145, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Jadval sarlavhasi katakchasi balandligini o'rnatish(CSS bilan)</h1>

<table>
  <tr>
    <th style="height:100px">Oylar</th>
    <th style="height:100px">Jamg'arma</th>
  </tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 146, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>th white-space ga nowrap qiymat berish. Matnlar bir qatorda yoziladi.</h1>

<table>
  <tr>
    <th>Oylar</th>
    <th style="white-space:nowrap">Jamg'arma yangi mashina uchun edi.(Yaqin orada yangisini sotib olaman.)</th>
  </tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
  </tr>
</table>

<p>nowrap berilmagan holati:</p>
<table>
  <tr>
    <th>Oylar</th>
    <th>Jamg'arma yangi mashina uchun edi.(Yaqin orada yangisini sotib olaman.)</th>
  </tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
  </tr>
</table>

<p><b>Eslatma:</b> Brauzer oynasi o'lchamini o'zgartirib farqini ko'ring.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 147, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>th ichidagi kontentni vertikal tekislash</h1>

<table style="width:50%;">
  <tr style="height:100px">
    <th style="vertical-align:bottom">Oylar</th>
    <th style="vertical-align:bottom">Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 148, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>th width qiymatini foizda berish</h1>

<table style="width:100%">
  <tr>
    <th style="width:70%">Oylar</th>
    <th style="width:30%">Jamg'arma</th>
  </tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 149, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Jadval sarlavhasi</h1>

<h2>Gorizontal sarlavhalar:</h2>
<table>
  <tr>
    <th>Ism</th>
    <th>Email</th>
    <th>Telefon</th>
  </tr>
  <tr>
    <td>John Doe</td>
    <td>john.doe@example.com</td>
    <td>123-45-678</td>
  </tr>
</table>

<h2>Vertikal sarlavhalar:</h2>
<table>
  <tr>
    <th>Ism:</th>
    <td>John Doe</td>
  </tr>
  <tr>
    <th>Email:</th>
    <td>john.doe@example.com</td>
  </tr>
  <tr>
    <th>Telefon:</th>
    <td>123-45-678</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 150, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>caption elementi</h1>

<table>
  <caption>Oylik jamg'arma</caption>
  <tr>
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td>$50</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 151, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Colspan va rowspan</h1>

<h3>Ikkita ustunli yecheyka:</h3>
<table>
  <tr>
    <th>Ism</th>
    <th>Email</th>
    <th colspan="2">Telefon</th>
  </tr>
  <tr>
    <td>John Doe</td>
    <td>john.doe@example.com</td>
    <td>123-45-678</td>
    <td>212-00-546</td>
  </tr>
</table>

<h3>Ikkita qatorli yecheyka:</h3>
<table>
  <tr>
    <th>Ism:</th>
    <td>John Doe</td>
  </tr>
  <tr>
    <th>Email:</th>
    <td>john.doe@example.com</td>
  </tr>
  <tr>
    <th rowspan="2">Telefon:</th>
    <td>123-45-678</td>
  </tr>
  <tr>
    <td>212-00-546</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 152, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>tr elementi</h1>

<p>tr elementi jadvaldagi qatorni belgilaydi:</p>

<table>
<tr>
<th>Oylar</th>
<th>Jamg'arma</th>
</tr>
<tr>
<td>Yanvar</td>
<td>$100</td>
</tr>
<tr>
<td>Fevral</td>
<td>$80</td>
</tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 153, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>2-qatorni o'ng tomonga tekislash</h1>

<table style="width:100%">
  <tr>
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr style="text-align:right">
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 154, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Birinchi qator rangini o'zgartirish</h1>

<table>
  <tr style="background-color:#FF0000">
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 155, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Qatorlar ichidagi tarkibni vertikal tekislash</h1>

<table style="height:200px">
  <tr style="vertical-align:top">
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr style="vertical-align:bottom">
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 156, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>td element</h1>

<p>td elementi ustunni belgilaydi:</p>

<table>
  <tr>
    <td>Katak A</td>
    <td>Katak B</td>
  </tr>
  <tr>
    <td>Katak C</td>
    <td>Katak D</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 157, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>td ichidagi tarkibni tekislash</h1>

<table style="width:100%">
  <tr>
    <th>Oylar</th>
    <th>Jamarg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td style="text-align:right">$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td style="text-align:right">$80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 158, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>td elementiga fon rangini berish</h1>

<table>
  <tr>
    <th>Oylar</th>
    <th>Jamarg'arma</th>
  </tr>
  <tr>
    <td style="background-color:#FF0000">Yanvar</td>
    <td style="background-color:#00FF00">$100</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 159, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Jadval ustunining balandligini o'rnatish</h1>

<table>
  <tr>
    <th>Oylar</th>
    <th>Jamarg'arma</th>
  </tr>
  <tr>
    <td style="height:100px">Yanvar</td>
    <td style="height:100px">$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 160, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Jadval katakchasidagi so'zlarni keyingi qatorga tushmaydigan qilish(CSS bilan):</h1>

<table>
  <tr>
    <th>Matn</th>
  </tr>
  <tr>
    <td style="white-space:nowrap">Hech qachon biror narsani tushuntirish uchun zarur bo'lgan ob'ektlar sonini ko'paytirmang</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 161, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>td ichidagi tarkibni vertikal tekislash </h1>

<table style="width:50%;">
  <tr>
    <th>Oylar</th>
    <th>Jamarg'arma</th>
  </tr>
  <tr style="height:100px">
    <td style="vertical-align:bottom">Yanvar</td>
    <td style="vertical-align:bottom">$100</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 162, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>Jadval katakchasining kengligini foizda o'rnatish</h1>

<table style="width:100%">
  <tr>
    <th>Oylar</th>
    <th>Jamarg'arma</th>
  </tr>
  <tr>
    <td style="width:70%">Yanvar</td>
    <td style="width:30%">$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 163, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h2>Jadval borderi</h2>

<p>Css border xususiyati yordamida jadvalga border beriladi.</p>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 164, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>Borderlar birlashishi</h2>
<p>Ikki tomonlama borderlarga ega bo'lmaslik uchun CSS border-collapse xususiyatiga collapse qiymati beriladi.</p>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 165, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid white;
  border-collapse: collapse;
}
th, td {
  background-color: #96D4D4;
}
</style>
</head>
<body>

<h2>Jadvalning ko'rinmas borderi</h2>

<p>Har bir katakning fon rangini o'rnatib, borderga oq rang berilsa (hujjat foni bilan bir xil), border ko'rinmas chegaraga o'xshab ko'rinadi</p>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 166, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-radius: 10px;
}
</style>
</head>
<body>

<h2>Aylanasimon border</h2>

<p>border-radius xususiyati yordamida border burchaklariga aylanasimon shakl berish mumkin.</p>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 167, exhtml: `
<head>
<style>
th, td {
  border: 1px solid black;
  border-radius: 10px;
}
</style>
</head>
<body>

<h2>Aylanasimon border</h2>

<p>Jadval atrofidagi borderni olib tashlash mumkin.</p>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 168, exhtml: `
<head>
<style>
th, td {
  border-style: dotted;
}
</style>
</head>
<body>

<h2>Nuqtali border</h2>

<p>border-style xususiyati yordamida border stillini o'zgartirish mumkin</p>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 169, exhtml: `
<head>
<style>
th, td {
  border-style:solid;
  border-color: #96D4D4;
}
</style>
</head>
<body>

<h2>Border rangini o'zgartirish</h2>

<p>border-color xususiyati yordamida border rangini o'zgartirish mumkin.</p>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 170, exhtml: `
<style>
table, th, td {
  border:1px solid black;
  border-collapse: collapse;
}
</style>
<body>

<h2>Jadval kengligini 100%</h2>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr style="height:200px">
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 171, exhtml: `
<style>
table, th, td {
  border:1px solid black;
  border-collapse: collapse;
}
</style>
<body>

<h2>Birinchi ustunning kengligi 70% </h2>

<table style="width:100%">
  <tr>
    <th style="width:70%">Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 172, exhtml: `
<style>
table, th, td {
  border:1px solid black;
  border-collapse: collapse;
}
</style>
<body>

<h2>Set the height of the second row to 200 pixels</h2>

<table style="width:100%">
  <tr>
  <th>Familiya</th>
  <th>Ism</th>
  <th>Yosh</th>
  </tr>
  <tr style="height:200px">
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 173, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 15px;
}
</style>
</head>
<body>

<h2>Yecheyka paddingi</h2>
<p>Yecheyka paddingi- yecheyka chekkasi va yecheyka ichiki tarkibi orasidagi bo'shliq.</p>

<table style="width:100%">
  <tr>
  <th>Familiya</th>
  <th>Ism</th>
  <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

<p><strong>Maslahat:</strong> Padding qiymatini 5px ga o'zgartirib ko'ring.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 174, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 40px;
}
</style>
</head>
<body>

<h2>Tepadan, pastdan, chapdan va o'ngdan padding berish </h2>
<p>Kontentning to'rtala tomoniga har xil padding qiymatini berishimiz mumkin.</p>

<table style="width:100%">
  <tr>
  <th>Familiya</th>
  <th>Ism</th>
  <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 175, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
table {
  border-spacing: 30px;
}
</style>
</head>
<body>

<h2>Kataklar orasidagi bo'shliq</h2>
<p>Kataklar orasidagi bo'shliqning qiymatini o'zgartirib ko'ring:</p>

<table style="width:100%">
  <tr>
  <th>Familiya</th>
  <th>Ism</th>
  <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
  <tr>
    <td>John</td>
    <td>Doe</td>
    <td>80</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 176, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>Ikki ustunni birlashtirish</h2>
<p>Bir nechta ustunlarni birlashtirish uchun colspan atributdan foydalaniladi</p>

<table style="width:100%">
  <tr>
    <th colspan="2">Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>43</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>57</td>
  </tr>
</table>
</body>`, excss: ``, exjs: ``
  },
  {
    id: 177, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>Ikki qatorni birlashtirish</h2>
<p>Bir nechta satrlar bo'ylab kataklarni birlashtirish uchun rowspan atributdan foydalaniladi.</p>

<table style="width:100%">
  <tr>
    <th>Ism</th>
    <td>Jill</td>
  </tr>
  <tr>
    <th rowspan="2">Telefon</th>
    <td>555-1234</td>
  </tr>
  <tr>
    <td>555-8745</td>
  </tr>
</table>
</body>`, excss: ``, exjs: ``
  },
  {
    id: 178, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>Jadval sarlavhasi</h2>

<p>Har bir th element jadval sarlavhasini ifodalaydi</p>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 179, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>Jadvalning vertikal sarlavhasi</h2>

<p>Birinchi ustunni jadval sarlavhalari sifatida ishlatish uchun har bir qatordagi birinchi katakchani th element sifatida belgilang:</p>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <td>Jill</td>
    <td>Eve</td>
  </tr>
  <tr>
    <th>Ism</th>
    <td>Smith</td>
    <td>Jackson</td>
  </tr>
  <tr>
    <th>Yosh</th>
    <td>50</td>
    <td>94</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 180, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th {
  text-align: left;
}
</style>
</head>
<body>

<h2>Sarlavhalarni chap tomonga tekislash</h2>

<p>Jadval sarlavhalarini chap tomonga tekislash uchun CSS text-align xususiyatidan foydalaning.</p>

<table style="width:100%">
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 181, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>Ikki ustunli sarlavha</h2>

<p>Bir necha ustundan iborat sarlavha uchun colspan atributi foydalaniladi.</p>

<table style="width:100%">
  <tr>
    <th colspan="2">Ism</th>
    <th>Yosh</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
</table>
</body>`, excss: ``, exjs: ``
  },
  {
    id: 182, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 5px;
  text-align: left;
}
</style>
</head>
<body>

<h2>Capion</h2>
<p>Jadvalga umumiy sarlavha qo'shish uchun caption tegidan foydalaniladi.</p>

<table style="width:100%">
  <caption>Oylik jamg'armalar</caption>
  <tr>
    <th>Oy</th>
    <th>Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td>$50</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 183, exhtml: `
<head>
<style>
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #D6EEEE;
}
</style>
</head>
<body>

<h2>Zebra ko'rinishidagi jadval</h2>
<p>Jadval juft qatorlariga still berish uchun :nth-child(even) selektordan quyidagi tarzda foydalaniladi:</p>

<table>
  <tr>
  <th>Familiya</th>
  <th>Ism</th>
  <th>Fond</th>
  </tr>
  <tr>
  <td>Peter</td>
  <td>Griffin</td>
  <td>$100</td>
  </tr>
  <tr>
  <td>Lois</td>
  <td>Griffin</td>
  <td>$150</td>
  </tr>
  <tr>
  <td>Joe</td>
  <td>Swanson</td>
  <td>$300</td>
  </tr>
  <tr>
  <td>Cleveland</td>
  <td>Brown</td>
  <td>$250</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 184, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

th:nth-child(even),td:nth-child(even) {
  background-color: #D6EEEE;
}
</style>
</head>
<body>

<h2>Yo'l yo'l jadval</h2>
<p>Vertikal zebra stilli uchun qator o'rniga juft yoki toq ustunga still beriladi:</p>

<table style="width:100%">
  <tr>
    <th>Du</th>
    <th>Se</th>
    <th>Chor</th>
    <th>Pay</th>
    <th>Ju</th>
    <th>Shan</th>
    <th>Yak</th>
  </tr>
  <tr>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
  </tr>
  <tr>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
  </tr>
  <tr>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
  </tr>
  <tr>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 185, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: rgba(150, 212, 212, 0.4);
}

th:nth-child(even),td:nth-child(even) {
  background-color: rgba(150, 212, 212, 0.4);
}
</style>
</head>
<body>

<h2>Vertikal va gorizontal zebra chiziqlarini birlashtirish</h2>
<p>Juft qator va ustunda zebra chiziqlarini hosil qilish mumkin.</p>

<table style="width:100%">
  <tr>
    <th>MON</th>
    <th>TUE</th>
    <th>WED</th>
    <th>THU</th>
    <th>FRI</th>
    <th>SAT</th>
    <th>SUN</th>
  </tr>
  <tr>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
  </tr>
  <tr>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
  </tr>
  <tr>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
  </tr>
  <tr>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 186, exhtml: `
<head>
<style>
table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  border-bottom: 1px solid #ddd;
}
</style>
</head>
<body>

<h2>Jadval border chiziqlari</h2>
<p>Gorizontal chiziqli jadval uchun barcha tr elementi border-bottom xususiyatiga qiymat beriladi:</p>

<table>
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
  <th>Jamg'arma</th>
  </tr>
  <tr>
    <td>Peter</td>
    <td>Griffin</td>
    <td>$100</td>
  </tr>
  <tr>
    <td>Lois</td>
    <td>Griffin</td>
    <td>$150</td>
  </tr>
  <tr>
    <td>Joe</td>
    <td>Swanson</td>
    <td>$300</td>
  </tr>
  <tr>
    <td>Cleveland</td>
    <td>Brown</td>
    <td>$250</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 187, exhtml: `
<head>
<style>
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #DDD;
}

tr:hover {background-color: #D6EEEE;}
</style>
</head>
<body>

<h2>Jadvaldagi hover effecti</h2>
<p>Jadval qatorlari ustiga sichqoncha olib borilganda mana shu qatorni ajratib ko'rsatish uchun tr:hover selektordan foydalaniladi:</p>

<table>
  <tr>
    <th>Familiya</th>
    <th>Ism</th>
    <th>Font</th>
  </tr>
  <tr>
    <td>Peter</td>
    <td>Griffin</td>
    <td>$100</td>
  </tr>
  <tr>
    <td>Lois</td>
    <td>Griffin</td>
    <td>$150</td>
  </tr>
  <tr>
    <td>Joe</td>
    <td>Swanson</td>
    <td>$300</td>
  </tr>
  <tr>
    <td>Cleveland</td>
    <td>Brown</td>
    <td>$250</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 188, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>Colgroup</h2>
<p>colgroup va col elementlari yordamida 2 ta ustunga stillar berildi:</p>

<table style="width: 100%;">
<colgroup>
  <col span="2" style="background-color: #D6EEEE">
</colgroup>
<tr>
<th>DU</th>
<th>SE</th>
<th>CHOR</th>
<th>PAY</th>
<th>CHOR</th>
<th>PAY</th>
<th>JUM</th>
</tr>
<tr>
<td>1</td>
<td>2</td>
<td>3</td>
<td>4</td>
<td>5</td>
<td>6</td>
<td>7</td>
</tr>
<tr>
<td>8</td>
<td>9</td>
<td>10</td>
<td>11</td>
<td>12</td>
<td>13</td>
<td>14</td>
</tr>
<tr>
<td>15</td>
<td>16</td>
<td>17</td>
<td>18</td>
<td>19</td>
<td>20</td>
<td>21</td>
</tr>
<tr>
<td>22</td>
<td>23</td>
<td>24</td>
<td>25</td>
<td>26</td>
<td>27</td>
<td>28</td>
</tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 189, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>Bir nechta col elementi</h2>
<p>colgroup ichida bir necha col elementidan foydalanish mumkin:</p>

<table style="width: 100%;">
  <colgroup>
    <col span="2" style="background-color: #D6EEEE">
    <col span="3" style="background-color: pink">
  </colgroup>
<tr>
<th>DU</th>
<th>SE</th>
<th>CHOR</th>
<th>PAY</th>
<th>JU</th>
<th>SHAN</th>
<th>YAK</th>
</tr>
<tr>
<td>1</td>
<td>2</td>
<td>3</td>
<td>4</td>
<td>5</td>
<td>6</td>
<td>7</td>
</tr>
<tr>
<td>8</td>
<td>9</td>
<td>10</td>
<td>11</td>
<td>12</td>
<td>13</td>
<td>14</td>
</tr>
<tr>
<td>15</td>
<td>16</td>
<td>17</td>
<td>18</td>
<td>19</td>
<td>20</td>
<td>21</td>
</tr>
<tr>
<td>22</td>
<td>23</td>
<td>24</td>
<td>25</td>
<td>26</td>
<td>27</td>
<td>28</td>
</tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 190, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>Empty Colgroups</h2>
<p>Add "empty" col elements that represents the columns before the columns you want to style:</p>

<table style="width: 100%;">
<colgroup>
  <col span="3">
  <col span="2" style="background-color: pink">
</colgroup>
<tr>
<th>MON</th>
<th>TUE</th>
<th>WED</th>
<th>THU</th>
<th>FRI</th>
<th>SAT</th>
<th>SUN</th>
</tr>
<tr>
<td>1</td>
<td>2</td>
<td>3</td>
<td>4</td>
<td>5</td>
<td>6</td>
<td>7</td>
</tr>
<tr>
<td>8</td>
<td>9</td>
<td>10</td>
<td>11</td>
<td>12</td>
<td>13</td>
<td>14</td>
</tr>
<tr>
<td>15</td>
<td>16</td>
<td>17</td>
<td>18</td>
<td>19</td>
<td>20</td>
<td>21</td>
</tr>
<tr>
<td>22</td>
<td>23</td>
<td>24</td>
<td>25</td>
<td>26</td>
<td>27</td>
<td>28</td>
</tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 191, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>Ustunlarni ko'rinmas qilish</h2>
<p>visibility: collapse xususiyati bilan ustunlarni  ko'rinmas qilish mumkin.</p>

<table style="width: 100%;">
<colgroup>
    <col span="2">
    <col span="3" style="visibility: collapse">
  </colgroup>
<tr>
<th>DU</th>
<th>SE</th>
<th>CHOR</th>
<th>PAY</th>
<th>JU</th>
<th>SHA</th>
<th>YAK</th>
</tr>
<tr>
<td>1</td>
<td>2</td>
<td>3</td>
<td>4</td>
<td>5</td>
<td>6</td>
<td>7</td>
</tr>
<tr>
<td>8</td>
<td>9</td>
<td>10</td>
<td>11</td>
<td>12</td>
<td>13</td>
<td>14</td>
</tr>
<tr>
<td>15</td>
<td>16</td>
<td>17</td>
<td>18</td>
<td>19</td>
<td>20</td>
<td>21</td>
</tr>
<tr>
<td>22</td>
<td>23</td>
<td>24</td>
<td>25</td>
<td>26</td>
<td>27</td>
<td>28</td>
</tr>
</table>

<p><b>Eslatma:</b>Safari brauzerlarida jadval ustunlarini ko'rinmas qilib bo'lmasligi mumkin.</p>
</body>`, excss: ``, exjs: ``
  },
  {
    id: 192, exhtml: `
<head>
<style>
table, th, td {
    border: 1px solid black;
}
</style>
</head>
<body>

<table>
<caption style="text-align:right">Mening jamg'armam</caption>
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
  <td>Yanvar</td>
  <td>$100</td>
</tr>
</table>
<br>

<table>
<caption style="caption-side:bottom">Mening jamg'armam</caption>
<tr>
  <th>Oylar</th>
  <th>Jamg'arma</th>
</tr>
<tr>
  <td>Yanvar</td>
  <td>$100</td>
</tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 193, exhtml: `
<head>
<style>
table,th,td {
  border:1px solid black;
}

caption {
  display: table-caption;
  text-align: center;
}
</style>
</head>
<body>

<p>caption elementi quyidagicha ko'rinadi:</p>

<table>
  <caption>Oylik jamg'arma</caption>
  <tr>
    <th>Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td>$50</td>
  </tr>
</table>

<p>Css xususiyatlarini o'zgartirib ko'ring.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 194, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<h1>colgroup elementi</h1>

<table>
  <colgroup>
    <col span="2" style="background-color:red">
    <col style="background-color:yellow">
  </colgroup>
  <tr>
    <th>Nomer</th>
    <th>Mavzu</th>
    <th>Narxi</th>
  </tr>
  <tr>
    <td>3476896</td>
    <td>Mening birinchi HTML kodim</td>
    <td>$53</td>
  </tr>
  <tr>
    <td>5869207</td>
    <td>Mening birinchi Css kodim</td>
    <td>$49</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 195, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}

colgroup {
  display: table-column-group;
}
</style>
</head>
<body>

<p>Colgroup elementi ko'rinishiga qarang:</p>

<table>
  <colgroup>
    <col span="2" style="background-color:red">
    <col style="background-color:yellow">
  </colgroup>
  <tr>
  <th>Nomer</th>
  <th>Mavzu</th>
  <th>Narxi</th>
</tr>
<tr>
  <td>3476896</td>
  <td>Mening birinchi HTML kodim</td>
  <td>$53</td>
</tr>
<tr>
  <td>5869207</td>
  <td>Mening birinchi Css kodim</td>
  <td>$49</td>
  </tr>
</table>

<p>Css xususiyatlarini o'zgartirib ko'ring.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 196, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<table style="width:100%">
  <tr>
  <th>Nomer</th>
  <th>Mavzu</th>
  <th>Narxi</th>
  </tr>
  <tr>
    <td>3476896</td>
    <td>Mening birinchi Html kodim</td>
    <td style="text-align:right">$53</td>
  </tr>
  <tr>
    <td>2489604</td>
    <td>Mening birinchi Css kodim</td>
    <td style="text-align:right">$47</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 197, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<table style="height:200px">
  <tr>
    <th>Oylar</th>
    <th style="vertical-align:bottom">Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td style="vertical-align:bottom">$100</td>
  </tr>
</table>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 198, exhtml: `
<head>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
</head>
<body>

<table>
  <tr>
    <th style="width:200px">Oylar</th>
    <th>Jamg'arma</th>
  </tr>
  <tr>
    <td>Yanvar</td>
    <td>$100</td>
  </tr>
  <tr>
    <td>Fevral</td>
    <td>$80</td>
  </tr>
</table>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 199, exhtml: `
  <head>
  <style>
  table, th, td {
    border: 1px solid black;
  }

  col {
    display: table-column;
  }
  </style>
  </head>
  <body>

  <p>col elementining ko'rinishi</p>

  <table>
    <colgroup>
      <col span="2" style="background-color:red">
      <col style="background-color:yellow">
    </colgroup>
    <tr>
    <th>Nomer</th>
    <th>Mavzu</th>
    <th>Narxi</th>
  </tr>
  <tr>
    <td>3476896</td>
    <td>Mening birinchi HTML kodim</td>
    <td>$53</td>
  </tr>
  <tr>
    <td>5869207</td>
    <td>Mening birinchi Css kodim</td>
    <td>$49</td>
    </tr>
  </table>

  <p>Css xususiyatlarini o'zgartirib ko'ring.</p>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 200, exhtml: `
  <head>
  <style>
  table, th, td {
    border: 1px solid black;
  }
  </style>
  </head>
  <body>

  <h1>thead, tbody va tfoot elementlari</h1>

  <table>
    <thead>
      <tr>
        <th>Oylar</th>
        <th>Jamg'arma</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Yanvar</td>
        <td>$100</td>
      </tr>
      <tr>
        <td>Fevral</td>
        <td>$80</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>Sum</td>
        <td>$180</td>
      </tr>
    </tfoot>
  </table>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 201, exhtml: `
  <head>
  <style>
  thead {color:green;}
  tbody {color:blue;}
  tfoot {color:red;}

  table, th, td {
    border: 1px solid black;
  }
  </style>
  </head>
  <body>

  <h1>thead, tbody va tfoot elementlariga still berish</h1>

  <table>
    <thead>
      <tr>
        <th>Oylar</th>
        <th>Jamg'arma</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Yanvar</td>
        <td>$100</td>
      </tr>
      <tr>
        <td>Fevral</td>
        <td>$80</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>Sum</td>
        <td>$180</td>
      </tr>
    </tfoot>
  </table>

  </body>
  `, excss: ``, exjs: ``
  },
  {
    id: 202, exhtml: `
  <head>
  <style>
  table, th, td {
    border: 1px solid black;
  }
  </style>
  </head>
  <body>

  <h1>thead ichidagi tarkibni chap tomondan tekislash</h1>

  <table style="width:100%">
    <thead style="text-align:left">
      <tr>
        <th>Oylar</th>
        <th>Jamg'arma</th>
      </tr>
    </thead>
    <tbody>
     <tr>
      <td>Yanvar</td>
      <td>$100</td>
     </tr>
     <tr>
      <td>Fevral</td>
      <td>$80</td>
     </tr>
    </tbody>
  </table>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 203, exhtml: `
  <head>
  <style>
  table, th, td {
    border: 1px solid black;
  }
  </style>
  </head>
  <body>

  <h1>thead ichidagi kontentni vertikal tekislash</h1>

  <table style="width:50%">
    <thead style="vertical-align:bottom">
      <tr style="height:100px">
        <th>Oylar</th>
        <th>Jamg'arma</th>
      </tr>
    </thead>
    <tbody>
     <tr>
      <td>Yanvar</td>
      <td>$100</td>
     </tr>
     <tr>
      <td>Fevral</td>
      <td>$80</td>
     </tr>
    </tbody>
  </table>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 204, exhtml: `
  <body>

  <h2>Tartiblanmagan HTML ro'yxati</h2>

  <ul>
    <li>Kofe</li>
    <li>Choy</li>
    <li>Sut</li>
  </ul>

  </body>
`, excss: ``, exjs: ``
  },
  {
    id: 205, exhtml: `
  <body>

  <h2>Tartiblangan HTML ro'yxati</h2>

  <ol>
    <li>Kofe</li>
    <li>Choy</li>
    <li>Sut</li>
  </ol>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 206, exhtml: `
  <body>

  <h2>Html tavsif ro'yxati</h2>

  <dl>
    <dt>Kofe</dt>
    <dd>- qora issiq ichimlik</dd>
    <dt>Sut</dt>
    <dd>- oq rangli ichimlik</dd>
  </dl>

  </body>
  `, excss: ``, exjs: ``
  },
  {
    id: 207, exhtml: `
  <body>

  <h2>Disc qiymati</h2>

  <ul style="list-style-type:disc;">
    <li>Kofe</li>
    <li>Choy</li>
    <li>Sut</li>
  </ul>

  </body>
  `, excss: ``, exjs: ``
  },
  {
    id: 208, exhtml: `
  <body>

  <h2>Circle qiymati</h2>

  <ul style="list-style-type:circle;">
    <li>Kofe</li>
    <li>Choy</li>
    <li>Sut</li>
  </ul>

  </body>
  `, excss: ``, exjs: ``
  },
  {
    id: 209, exhtml: `
  <body>

  <h2>Square qiymati</h2>

  <ul style="list-style-type:square;">
      <li>Kofe</li>
      <li>Choy</li>
      <li>Sut</li>
  </ul>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 210, exhtml: `
  <body>

  <h2>Markersiz</h2>

  <ul style="list-style-type:none;">
     <li>Kofe</li>
     <li>Choy</li>
     <li>Sut</li>
  </ul>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 211, exhtml: `
  <body>

  <h2>Ichki HTML ro'yxatlari</h2>
  <p>Ro'yxatlarni ichma-ich joylashtirish mumkin (ro'yxat ichidagi ro'yxat):</p>

  <ul>
    <li>Kofe</li>
    <li>Choy
      <ul>
        <li>Qora choy</li>
        <li>Ko'k choy</li>
      </ul>
    </li>
    <li>Sut</li>
  </ul>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 212, exhtml: `
  <head>
  <style>
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333333;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
  }

  li a:hover {
    background-color: #111111;
  }
  </style>
  </head>
  <body>

  <h2>Navigatisiya menyusi</h2>
  <p>Bu misolda gorizontal ro'yxat ya'ni navigatsiya menyusi yaratildi:</p>

  <ul>
    <li><a href="#home">Bosh sahifa</a></li>
    <li><a href="#news">Yangiliklar</a></li>
    <li><a href="#contact">Kontakt</a></li>
    <li><a href="#about">Biz haqimizda</a></li>
  </ul>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 213, exhtml: `
  <body>

<h2>Raqamlar bilan tartiblangan ro'yxat</h2>

<ol type="1">
   <li>Kofe</li>
   <li>Choy</li>
   <li>Sut</li>
</ol>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 214, exhtml: `
  <body>

  <h2>Katta harflar bilan tartiblangan ro'yxat</h2>

  <ol type="A">
     <li>Kofe</li>
     <li>Choy</li>
     <li>Sut</li>
  </ol>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 215, exhtml: `
  <body>

  <h2>Kichik harflar bilan tartiblangan ro'yxat</h2>

  <ol type="a">
     <li>Kofe</li>
     <li>Choy</li>
     <li>Sut</li>
  </ol>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 216, exhtml: `
  <body>

  <h2>Katta rim raqamlari bilan tartiblangan ro'yxat</h2>

  <ol type="I">
     <li>Kofe</li>
     <li>Choy</li>
     <li>Sut</li>
  </ol>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 217, exhtml: `
  <body>

  <h2>Kichik rim raqamlari bilan tartiblangan ro'yxat</h2>

  <ol type="i">
     <li>Kofe</li>
     <li>Choy</li>
     <li>Sut</li>
  </ol>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 218, exhtml: `
  <body>

<h2>start attributi</h2>
<p>Default holatda tartiblangan ro'yxat 1 dan raqamlashni boshlaydi. start atributi yordamida ko'rsatilgan raqamdan boshlab raqamlash mumkin:</p>

<ol start="50">
   <li>Kofe</li>
   <li>Choy</li>
   <li>Sut</li>
</ol>

<ol type="I" start="50">
    <li>Kofe</li>
    <li>Choy</li>
    <li>Sut</li>
</ol>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 219, exhtml: `
  <body>

<h2>Ichma-ich joylashgan ro'yxat</h2>
<p>Ro'yxatlarni ichma-ich joylashtirish mumkin(ro'yxat ichidagi ro'yxat):</p>

<ol>
  <li>Kofe</li>
  <li>Choy
    <ol>
      <li>Qora choy</li>
      <li>Ko'k choy</li>
    </ol>
  </li>
  <li>Sut</li>
</ol>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 220, exhtml: `
  <body>

  <p style="border: 1px solid black">Salom Dunyo</p>
  <div style="border: 1px solid black">Salom Dunyo</div>

  <p>p va div elementlari block elementlar hisoblanadi, shuning uchun ular har doim o'zi joylashgan satrni to'liq egallaydi (iloji boricha chapga va o'ngga cho'ziladi).</p>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 221, exhtml: `
  <body>

<p>Bu matn ichida <span style="border: 1px solid black">Salom dunyo</span> span elementi joylashgan.</p>

<p>span Inline element bo'lgani uchun yangi satrdan boshlanmaydi va o'zi uchun kerakli bo'lgan joyni egallaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 222, exhtml: `
  <body>

<div style="background-color:black;color:white;padding:20px;">
  <h2>London</h2>
  <p>London - Angliyaning poytaxti. Bu Buyuk Britaniyadagi eng gavjum shahar bo'lib, uning poytaxti 13 milliondan ortiq aholiga ega</p>
  <p>Temza daryosi bo'yida joylashgan London ikki ming yil davomida yirik aholi punkti bo'lib kelgan, uning tarixi rimliklar tomonidan asos solingan va uni Londinium deb atagan.</p>
</div>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 223, exhtml: `
  <body>

<h1>span elementi</h1>

<p>Onamning ko'zlari <span style="color:blue;font-weight:bold;">ko'k</span> va otamning ko'zlari <span style="color:darkolivegreen;font-weight:bold;">qoramtir yashil</span>.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 224, exhtml: `
  <head>
<style>
.city {
  background-color: tomato;
  color: white;
  border: 2px solid black;
  margin: 20px;
  padding: 20px;
}
</style>
</head>
<body>

<div class="city">
<h2>London</h2>
<p>London - Angilya poytaxti.</p>
</div>

<div class="city">
<h2>Parij</h2>
<p>Parij - Fransiya poytaxti.</p>
</div>

<div class="city">
<h2>Tokiyo</h2>
<p>Tokiyo - Yaponiya poytaxti</p>
</div>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 225, exhtml: `
  <head>
<style>
.note {
  font-size: 120%;
  color: red;
}
</style>
</head>
<body>

<h1>My <span class="note">Muhim</span> Sarlavha</h1>
<p>Bu juda <span class="note">muhim</span> matn.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 226, exhtml: `
  <head>
<style>
.city {
  background-color: tomato;
  color: white;
  padding: 10px;
}
</style>
</head>
<body>

<h2>class attributi</h2>
<p>"city" nomli sinf Css stilini qo'llash:</p>

<h2 class="city">London</h2>
<p>London - Angilya poytaxti</p>

<h2 class="city">Parij</h2>
<p>Parij - Fransiya poytaxti</p>

<h2 class="city">Tokyo</h2>
<p>Tokiyo - Yaponiya poytaxti</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 227, exhtml: `
  <head>
<style>
.city {
  background-color: tomato;
  color: white;
  padding: 10px;
}

.main {
  text-align: center;
}
</style>
</head>
<body>

<h2>Bir nechta sinflar</h2>
<p>Quyidagi misolda birinchi h2 elementi ham city sinfiga, ham main sinfiga tegishli bo'lib, ikkala sinfning ham CSS stillari beriladi</p>

<h2 class="city main">London</h2>
<h2 class="city">Parij</h2>
<h2 class="city">Tokiyo</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 228, exhtml: `
  <head>
<style>
.city {
  background-color: tomato;
  color: white;
  padding: 10px;
}
</style>
</head>
<body>

<h2>Turli xil element classlari</h2>

<p>HTML turli xil elementlariga ham bir xil class berish mumkin va ularga bir xil stil beriladi.</p>

<h2 class="city">Parij</h2>
<p class="city">Parij - Fransiya poytaxti.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 229, exhtml: `
  <body>

<h2>JavaScriptda class atributidan foydalanish</h2>
<p>Button tugmasi bosilsa hamma "city" nomli elementlar g'oyib bo'ladi:</p>

<button onclick="myFunction()">Elementlarni ko'rsatmaslik</button>

<h2 class="city">London</h2>
<p>London Angilya poytaxti</p>

<h2 class="city">Parij</h2>
<p>Parij Fransiya poytaxti.</p>

<h2 class="city">Tokiyo</h2>
<p>Tokiyo Yaponiya poytaxti.</p>

<script>
function myFunction() {
  var x = document.getElementsByClassName("city");
  for (var i = 0; i < x.length; i++) {
    x[i].style.display = "none";
  }
}
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 230, exhtml: `
  <head>
<style>
#myHeader {
  background-color: lightblue;
  color: black;
  padding: 40px;
  text-align: center;
}
</style>
</head>
<body>

<h2>id atributi</h2>
<p>Elementga "myHeader" idsi yordamida Css stillarini berish :</p>

<h1 id="myHeader">Mening sarlavham</h1>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 231, exhtml: `
  <head>
<style>
/* Style the element with the id "myHeader" */
#myHeader {
  background-color: lightblue;
  color: black;
  padding: 40px;
  text-align: center;
}

/* Style all elements with the class name "city" */
.city {
  background-color: tomato;
  color: white;
  padding: 10px;
}
</style>
</head>
<body>

<h2>Class va ID o'rtasidagi farq</h2>
<p>Class nomi bir nechta HTML elementlari tomonidan ishlatilishi mumkin, id nomi esa sahifadagi faqat bitta HTML elementi tomonidan ishlatilishi kerak:</p>

<!-- yagona idiga ega element-->
<h1 id="myHeader">Mening shaxrim</h1>

<!-- Bir xil classga ega elementlar -->
<h2 class="city">London</h2>
<p>London - Angliya poytaxti</p>

<h2 class="city">Parij</h2>
<p>Parij - Fransiya poytaxti.</p>

<h2 class="city">Tokiyo</h2>
<p>Tokiyo -Yaponiya poytaxti.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 232, exhtml: `
  <body>

<h2>JavaScriptda id atributidan foydalanish</h2>
<p>JavaScript muayyan identifikatorga ega elementlarga getElementById() metodi yordamida kirishi mumkin:</p>

<h1 id="myHeader">Salom dunyo!</h1>
<button onclick="displayResult()">Matnni o'zgartirish</button>

<script>
function displayResult() {
  document.getElementById("myHeader").innerHTML = "Kuningiz yaxshi o'tsin!";
}
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 233, exhtml: `
<body>

<h2>Freymlar</h2>
<p>Freym o'lchamlarini berish uchun width va height atributlari ishlatiladi.</p>

<iframe src="demo.htm" height="200" width="300" title="Iframe Example"></iframe>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 234, exhtml: `
  <body>

<h2>Freymlar</h2>
<p>Yoki style atributi yordamida width va height xususiyatlarini o'zgartirish mumkin:</p>

<iframe src="demo.htm" style="height:200px;width:300px" title="Iframe Example"></iframe>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 235, exhtml: `
<body>

<h2>Borderni olib tashlash</h2>
<p>Borderni olib tashlash uchun style atributi yordamida border xususiyatidan foydalaniladi:</p>

<iframe src="demo.htm" style="border:none;" title="Iframe Example"></iframe>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 236, exhtml: `
  <body>

<h2>Border xususiyatini o'zgartirish</h2>
<p>CSS yordamida siz freym borderining o'lchamini, stilini va rangini ham o'zgartirishingiz mumkin:</p>

<iframe src="demo.htm" style="border:2px solid red;" title="Iframe Example"></iframe>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 237, exhtml: `
  <body>

<h2>Havola va freym</h2>

<iframe src="demo.htm" name="iframe_a" height="300px" width="100%" title="Iframe Example"></iframe>

<p><a href="https://self-study.uz" target="iframe_a">Self-study.uz</a></p>

<p>Havola bosilganda self-study.uz sayti freym ichida ochiladi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 238, exhtml: `
  <body>

<h1>Mening birinchi JavaScript dasturim</h1>

<button type="button"
onclick="document.getElementById('demo').innerHTML = Date()">
Sana va vaqtni bilish uchun bosing.</button>

<p id="demo"></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 239, exhtml: `
  <body>

<h2>JavaScript yordamida matnni o'zgartirish</h2>
<p>Quyidagi misolda id="demo" ga ega element ichiga JavaScript yordamida "Salom JavaScript" deb yozamiz.</p>

<p id="demo"></p>

<script>
document.getElementById("demo").innerHTML = "Salom JavaScript!";
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 240, exhtml: `
  <body>

<h1>Mening JavaScriptda yozgan kodim</h1>

<p>JavaScript yordamida element ichidagi kontentni o'zgartirish mumkin:</p>

<button type="button" onclick="myFunction()">O'zgartirish</button>

<p id="demo">Bu ko'rinib turibdi.</p>

<script>
function myFunction() {
  document.getElementById("demo").innerHTML = "Salom JavaScript!";
}
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 241, exhtml: `
  <body>

<h1>Mening JavaScriptda yozgan kodim</h1>

<p id="demo">JavaScript yordamida element stilini o'zgartirish mumkin:</p>

<script>
function myFunction() {
  document.getElementById("demo").style.fontSize = "25px";
  document.getElementById("demo").style.color = "red";
  document.getElementById("demo").style.backgroundColor = "yellow";
}
</script>

<button type="button" onclick="myFunction()">O'zgartirish!</button>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 242, exhtml: `<body>

  <h1>Mening JavaScriptda yozgan kodim</h1>
  <p>JavaScript yordamida img elementi src qiymatini o'zgartirish mumkin:</p>

  <script>
  function light(sw) {
    var pic;
    if (sw == 0) {
      pic = "/pic_bulboff.gif"
    } else {
      pic = "/pic_bulbon.gif"
    }
    document.getElementById('myImage').src = pic;
  }
  </script>

  <img id="myImage" src="/pic_bulboff.gif" width="100" height="180">

  <p>
  <button type="button" onclick="light(1)">Light On</button>
  <button type="button" onclick="light(0)">Light Off</button>
  </p>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 243, exhtml: `
  <body>

<p id="demo"></p>

<script>
document.getElementById("demo").innerHTML = "Salom JavaScript!";
</script>

<noscript>Kechirasiz, brauzer JavaScriptni qo'llab-quvvatlamaydi!</noscript>

<p>Brauzer JavaScriptni qo'llab-quvvatlamasa noscript ichidagi yozuv ko'rinadi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 244, exhtml: `
  <body>

  <h2>To'liq URL manzili</h2>
  <img src="https://www.w3schools.com/images/picture.jpg" alt="Mountain" style="width:300px">

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 245, exhtml: `
  <body>

<h2>Relativ fayl yo'li</h2>
<img src="/image/picture.jpg" alt="Mountain" style="width:300px">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 246, exhtml: `
  <body>

<h2>Relativ fayl yo'li</h2>
<img src="image/picture.jpg" alt="Mountain" style="width:300px">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 247, exhtml: `
  <body>

<h2>Relativ fayl yo'li</h2>
<img src="../image/picture.jpg" alt="Mountain" style="width:300px">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 248, exhtml: `
  <head>
  <title>Ma'noli sarlavha</title>
</head>
<body>

<p>Body ichidagi kontent brauzer oynasida ko'rinib turadi.</p>
<p>Title ichidagi matn esa brauzer yorlig'ida, sevimli sahifalar qismida va qidiruv natijalarida ko'rinib turadi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 249, exhtml: `
  <head>
  <title>Bu sahifa sarlavhasi</title>
  <style>
    body {background-color: powderblue;}
    h1 {color: red;}
    p {color: blue;}
  </style>
</head>
<body>

<h1>Bu sarlavha</h1>
<p>BU esa matn</p>

<p>Body ichidagi kontent brauzer oynasida ko'rinib turadi.</p>
<p>Title ichidagi matn esa brauzer yorlig'ida, sevimli sahifalar qismida va qidiruv natijalarida ko'rinib turadi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 250, exhtml: `
  <head>
  <title>Sahifa sarlavhasi</title>
  <link rel="stylesheet" href="mystyle.css">
</head>
<body>

<h1>Bu sarlavha</h1>
<p>BU esa matn</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 251, exhtml: `
  <head>
  <meta charset="UTF-8">
  <meta name="description" content="Free Web tutorials">
  <meta name="keywords" content="HTML, CSS, JavaScript">
  <meta name="author" content="John Doe">
</head>
<body>

<p>Hamma meta-ma'lumotlar head qismida joylashadi va ekranda ko'rinmaydi</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 252, exhtml: `
  <head>
  <title>Sahifa sarlavhasi</title>
  <script>
  function myFunction() {
    document.getElementById("demo").innerHTML = "Salom JavaScript!";
  }
  </script>
</head>
<body>

<h1>Mening veb sahifam</h1>
<p id="demo">Paragraf</p>
<button type="button" onclick="myFunction()">Sinab ko'rish</button>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 253, exhtml: `
  <head>
  <base href="https://www.w3schools.com/" target="_blank">
</head>
<body>

<h1>base elementi</h1>

<p><img src="images/stickman.gif" width="24" height="39" alt="Stickman"> - E'tibor bering, biz faqat rasm uchun nisbiy manzilni ko'rsatdik. Bosh bo'limda asosiy URL manzilini ko'rsatganimiz sababli, brauzer tasvirni "https://self-study.uz/images/stickman.gif" manzilidan qidiradi.</p>

<p><a href="tags/tag_base.asp">HTML base tag</a> - E'tibor bering, havola hech qanday target="_blank" atributiga ega bo'lmasa ham, yangi oynada ochiladi. Buning sababi, asosiy elementning target atributi "_blank" ga o'rnatilgan.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 254, exhtml: `
  <head>
<title>CSS Template</title>
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<style>
* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* Style the header */
header {
  background-color: #666;
  padding: 30px;
  text-align: center;
  font-size: 35px;
  color: white;
}

/* Create two columns/boxes that floats next to each other */
nav {
  float: left;
  width: 30%;
  height: 300px; /* only for demonstration, should be removed */
  background: #ccc;
  padding: 20px;
}

/* Style the list inside the menu */
nav ul {
  list-style-type: none;
  padding: 0;
}

article {
  float: left;
  padding: 20px;
  width: 70%;
  background-color: #f1f1f1;
  height: 300px; /* only for demonstration, should be removed */
}

/* Clear floats after the columns */
section::after {
  content: "";
  display: table;
  clear: both;
}

/* Style the footer */
footer {
  background-color: #777;
  padding: 10px;
  text-align: center;
  color: white;
}

/* Responsive layout - makes the two columns/boxes stack on top of each other instead of next to each other, on small screens */
@media (max-width: 600px) {
  nav, article {
    width: 100%;
    height: auto;
  }
}
</style>
</head>
<body>

<h2>CSS Float</h2>
<p>Ushbu misolda biz sarlavha, ikkita ustun va footer yaratdik. Kichikroq ekranlarda ustunlar bir-birining ustiga joylashadi.</p>
<p>Effektni ko'rish uchun brauzer oynasining o'lchamini o'zgartiring (bu haqda keyingi bobimizda - HTML Responsive bo'limida bilib olasiz.)</p>

<header>
  <h2>Shaharlar</h2>
</header>

<section>
  <nav>
    <ul>
      <li><a href="#">London</a></li>
      <li><a href="#">Parij</a></li>
      <li><a href="#">Tokiyo</a></li>
    </ul>
  </nav>

  <article>
    <h1>London</h1>
    <p>London - Angliyaning poytaxti. Bu Buyuk Britaniyadagi eng gavjum shahar bo'lib, uning poytaxti 13 milliondan ortiq aholiga ega.</p>
    <p>Temza daryosi bo'yida joylashgan London ikki ming yil davomida yirik aholi punkti bo'lib kelgan, uning tarixi rimliklar tomonidan asos solingan va uni Londinium deb atagan.</p>
  </article>
</section>

<footer>
  <p>Footer</p>
</footer>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 255, exhtml: `
<head>
<title>CSS Template</title>
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<style>
* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* headerga stil berish */
header {
  background-color: #666;
  padding: 30px;
  text-align: center;
  font-size: 35px;
  color: white;
}

/* Konteyner */
section {
  display: -webkit-flex;
  display: flex;
}

/* Navigatsiya menyusi stili */
nav {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #ccc;
  padding: 20px;
}

/* Nav ichidagi ul ga stil berish */
nav ul {
  list-style-type: none;
  padding: 0;
}

/* Kontentga stil berish */
article {
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
  background-color: #f1f1f1;
  padding: 10px;
}

/* Footerga stil berish */
footer {
  background-color: #777;
  padding: 10px;
  text-align: center;
  color: white;
}

/* Moslashuvchanlik - menyu va tarkibni (bo'lim ichidagi) yonma-yon emas, balki bir-birining ustiga joylashtirish imkonini beradi.*/
@media (max-width: 600px) {
  section {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}
</style>
</head>
<body>

<h2>CSS flex Maketi</h2>
<p>Ushbu misolda sarlavha, ikkita ustun va footer yaratilgan. Kichikroq ekranlarda ustunlar bir-birining ustiga joylashadi.</p>
<p>Effektni ko'rish uchun brauzer oynasining o'lchamini o'zgartiring.</p>
<p><strong>Eslatma: </strong>Flexbox Internet Explorer 10 va undan oldingi versiyalarida qo'llab-quvvatlanmaydi.</p>

<header>
  <h2>Cities</h2>
</header>

<section>
  <nav>
    <ul>
      <li><a href="#">London</a></li>
      <li><a href="#">Parij</a></li>
      <li><a href="#">Tokiyo</a></li>
    </ul>
  </nav>

  <article>
  <h1>London</h1>
  <p>London - Angliyaning poytaxti. Bu Buyuk Britaniyadagi eng gavjum shahar bo'lib, uning poytaxti 13 milliondan ortiq aholiga ega.</p>
  <p>Temza daryosi bo'yida joylashgan London ikki ming yil davomida yirik aholi punkti bo'lib kelgan, uning tarixi rimliklar tomonidan asos solingan va uni Londinium deb atagan.</p>
</article>
</section>

<footer>
  <p>Footer</p>
</footer>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 256, exhtml: `
  <head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<style>
* {
  box-sizing: border-box;
}
.menu {
  float: left;
  width: 20%;
}
.menuitem {
  padding: 8px;
  margin-top: 7px;
  border-bottom: 1px solid #f1f1f1;
}
.main {
  float: left;
  width: 60%;
  padding: 0 20px;
  overflow: hidden;
}
.right {
  background-color: lightblue;
  float: left;
  width: 20%;
  padding: 10px 15px;
  margin-top: 7px;
}

@media only screen and (max-width:800px) {
  /* Planshetlar uchun: */
  .main {
    width: 80%;
    padding: 0;
  }
  .right {
    width: 100%;
  }
}
@media only screen and (max-width:500px) {
  /* Mobil telefonlar uchun: */
  .menu, .main, .right {
    width: 100%;
  }
}
</style>
</head>
<body style="font-family:Verdana;">

<div style="background-color:#f1f1f1;padding:15px;">
  <h1>Cinque Terre</h1>
  <h3>Brauzer oynasining o'lchamini o'zgartirib ko'ring!</h3>
</div>

<div style="overflow:auto">
  <div class="menu">
    <div class="menuitem">Piyoda yurish</div>
    <div class="menuitem">Transport</div>
    <div class="menuitem">Tarix</div>
    <div class="menuitem">Rasmlar</div>
  </div>

  <div class="main">
    <h2>Piyoda yurish</h2>
    <p>Monterossodan Riomaggioregacha bo'lgan piyoda sizga taxminan ikki soat vaqt ketadi, ob-havo sharoiti va jismoniy quvvatingizga qarab bir soat ko'proq yurishingiz mumkin.</p>
    <img src="img_5terre.jpg" style="width:100%">
  </div>

  <div class="right">
    <h2>Nima?</h2>
    <p>Cinque Terre beshta qishloqni o'z ichiga oladi: Monterosso, Vernazza, Corniglia, Manarola va Riomaggiore.</p>
    <h2>Qayerda?</h2>
    <p>Italiya Rivierasining shimoli-g'arbiy qismida, La Spezia shahridan shimolda.</p>
    <h2>Narxi?</h2>
    <p>Piyoda yurish bepul!</p>
  </div>
</div>


<div style="background-color:#f1f1f1;text-align:center;padding:10px;margin-top:7px;font-size:12px;">Kontent o'lchamini qanday  o'zgartirishini ko'rish uchun brauzer oynasining o'lchamini o'zgartiring.</div>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 257, exhtml: `
  <head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>

<h2>Ko'rish oynasini sozlash</h2>
<p>Bu misolda faqat responsiv veb-sayt uchun meta elementidan qanday foydalanish ko'rsatilgan.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 258, exhtml: `
  <head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>

<h2>Responsiv rasm</h2>
<p>CSS width xususiyati foiz qiymatida o'rnatilgan bo'lsa, brauzer oynasining o'lchamini o'zgartirganda tasvir kattalashadi yoki  kichiklashadi. Effektni ko'rish uchun brauzer oynasining o'lchamini o'zgartiring.</p>

<img src="img_girl.jpg" style="width:100%;">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 259, exhtml: `
  <head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>

<h2>Responsiv Tasvir</h2>
<p>"max-width:100%" tasvirning asl o'lchamidan kattaroq bo'lishining oldini oladi. Biroq, agar siz brauzer oynasini kichikroq qilsangiz, tasvir hali ham kichrayadi.</p>
<p>Effektni ko'rish uchun brauzer oynasining o'lchamini o'zgartiring.</p>

<img src="img_girl.jpg" style="max-width:100%;height:auto;">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 260, exhtml: `
  <head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>

<h2>Brauzer kengligiga qarab turli rasmlarni ko'rsatish</h2>
<p>Brauzer kengligi o'lchamini o'zgartiring va rasm 600px va 1500pxlarda o'zgaradi.</p>

<picture>
  <source srcset="img_smallflower.jpg" media="(max-width: 600px)">
  <source srcset="img_flowers.jpg" media="(max-width: 1500px)">
  <source srcset="flowers.jpg">
  <img src="img_flowers.jpg" alt="Flowers" style="width:auto;">
</picture>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 261, exhtml: `
  <head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>

<h1 style="font-size:10vw;">Responsiv Matn</h1>

<p style="font-size:5vw;">Matn o'lchami qanday o'lchamini ko'rish uchun brauzer oynasining o'lchamini o'zgartiring.</p>

<p style="font-size:5vw;">Matn o'lchamini belgilashda "vw" birligidan foydalaning. 10vw o'lchamni ko'rish oynasi kengligining 10% ga o'rnatadi.</p>

<p>Viewport - bu brauzer oynasining o'lchamidir. 1vw = ko'rish maydoni kengligining 1%. Agar ko'rish maydoni 50 sm kengligida bo'lsa, 1vw 0,5 smga teng.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 262, exhtml: `
  <head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<style>
* {
  box-sizing: border-box;
}

.left {
  background-color: #2196F3;
  padding: 20px;
  float: left;
  width: 20%; /* Default holatda width 20% ga teng */
}

.main {
  background-color: #f1f1f1;
  padding: 20px;
  float: left;
  width: 60%; /* Default holatda width 60% ga teng*/
}

.right {
  background-color: #04AA6D;
  padding: 20px;
  float: left;
  width: 20%; /* Default holatda width 20% ga teng*/
}

/*  O'zgarish nuqtasi 800px qilish uchun media querydan foydalaniladi: */
@media screen and (max-width: 800px) {
  .left, .main, .right {
    width: 100%; /* Ko'rish oynasi 800px va undan kichik bo'lganda width 100%ga teng. */
  }
}
</style>
</head>
<body>

<h2>Media Querie</h2>
<p>Brauzer oynasi o'lchamini o'zgartiring.</p>

<p>Brauzer o'lchamini 800pxgacha o'zgartirib o'zgarishni ko'ring:</p>

<div class="left">
  <p>Left Menu</p>
</div>

<div class="main">
  <p>Main Content</p>
</div>

<div class="right">
  <p>Right Content</p>
</div>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 263, exhtml: `
<head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<style>
* {
  box-sizing: border-box;
}

.menu {
  float: left;
  width: 20%;
  text-align: center;
}

.menu a {
  background-color: #e5e5e5;
  padding: 8px;
  margin-top: 7px;
  display: block;
  width: 100%;
  color: black;
}

.main {
  float: left;
  width: 60%;
  padding: 0 20px;
}

.right {
  background-color: #e5e5e5;
  float: left;
  width: 20%;
  padding: 15px;
  margin-top: 7px;
  text-align: center;
}

@media only screen and (max-width: 620px) {
  /* For mobile phones: */
  .menu, .main, .right {
    width: 100%;
  }
}
</style>
</head>
<body style="font-family:Verdana;color:#aaaaaa;">

<div style="background-color:#e5e5e5;padding:15px;text-align:center;">
  <h1>Salom dunyo</h1>
</div>

<div style="overflow:auto">
  <div class="menu">
    <a href="#">Havola 1</a>
    <a href="#">Havola 2</a>
    <a href="#">Havola 3</a>
    <a href="#">Havola 4</a>
  </div>

  <div class="main">
    <h2>Asosiy matn</h2>
    <p>Ko'rish oynasi kengligini o'zgartirib ko'ring va kontentlar qanday joylashishini ko'ring.</p>
  </div>

  <div class="right">
    <h2>Haqida</h2>
    <p>Self-study.uz da responsiv veb-sahifalarni qanday yaratishni o'zbek tilida o'rganing.</p>
  </div>
</div>

<div style="background-color:#e5e5e5;text-align:center;padding:10px;margin-top:7px;">self-study.uz</div>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 264, exhtml: `
  <head>
<title>W3.CSS</title>
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
</head>
<body>

<div class="w3-container w3-green">
  <h1>W3School Demosi</h1>
  <p>Ushbu responsiv sahifa o'lchamini o'zgartiring!</p>
</div>

<div class="w3-row-padding">
  <div class="w3-third">
    <h2>London</h2>
    <p>London - Angliya poytaxti.</p>
    <p>Bu Buyuk Britaniyadagi eng gavjum shahar bo'lib, uning poytaxti 13 milliondan ortiq aholiga ega.</p>
  </div>

  <div class="w3-third">
    <h2>Parij</h2>
    <p>Paris is the capital of France.</p>
    <p>Parij hududi Yevropadagi eng yirik aholi markazlaridan biri, 12 milliondan ortiq aholiga ega.</p>
  </div>

  <div class="w3-third">
    <h2>Tokio</h2>
    <p>Tokio - Yaponiya poytaxti.</p>
    <p>Shahar Katta Tokio hududida joylashgan
    va dunyodagi eng gavjum metropoliten hudud.</p>
  </div>
</div>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 265, exhtml: `
  <head>
  <title>Bootstrap Example</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
  </head>
<body>

<div class="container">
  <div class="jumbotron">
    <h1>Mening birinchi Bootstrap sahifam</h1>
    <p>Sahifa o'lchamini o'zgartirib effektni ko'ring!</p>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <h3>Ustun 1</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>
    </div>
    <div class="col-sm-4">
      <h3>Ustun 2</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>
    </div>
    <div class="col-sm-4">
      <h3>Ustun 3</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>
    </div>
  </div>
</div>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 266, exhtml: `
  <body>

<h2>Kompyuter kodi</h2>
<p>Dasturlash kodi:</p>

<code>
x = 5;
y = 6;
z = x + y;
</code>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 267, exhtml: `
  <body>

<h2>kbd Elementi</h2>

<p>kbd  elementi klaviatura belgilarini kiritish uchun ishlatiladi.</p>

<p>Hujjatni saqlash uchun  <kbd>Ctrl + S</kbd> ni bosing</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 268, exhtml: `
  <body>

<h2>samp Elementi</h2>

<p>samp elementi kompyuter dasturi najijasini ajratib ko'rsatish uchun ishlatiladi.</p>

<p>Mening kompyuterimdagi xabar:</p>
<p><samp>File not found.<br>Press F1 to continue</samp></p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 269, exhtml: `
  <body>

  <h2>code Elementi</h2>
  <p>Dasturlash doir misol:</p>

  <code>
  x = 5;
  y = 6;
  z = x + y;
  </code>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 270, exhtml: `
  <body>

<h2>var Elementi</h2>

<p>Uchburchakning maydoni: 1/2 x <var>b</var> x <var>h</var>, bu yerda <var>b</var> - asos, and <var>h</var> - vertikal balandlik.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 271, exhtml: `
  <body>

<p>code elementi qo'shimcha bo'sh joylarni o'chiradi va yangi qatordan yozilgan bo'lsa ham hamma kodlarni ketma-ket yozib ketadi.</p>
<p>Buni tuzatish uchun pre elementini code element ichiga yozish kerak:</p>
<pre>
<code>
x = 5;
y = 6;
z = x + y;
</code>
</pre>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 272, exhtml: `
  <body>

<section>
  <h1>WWF</h1>
  <p>Butunjahon tabiatni muhofaza qilish jamg'armasi (WWF) atrof-muhitni muhofaza qilish, tadqiq qilish va qayta tiklash masalalari bilan shug'ullanuvchi xalqaro tashkilot bo'lib, ilgari Butunjahon yovvoyi tabiat fondi deb nomlangan. WWF 1961 yilda tashkil etilgan.</p>
</section>

<section>
  <h1>WWFning Panda belgisi</h1>
  <p>Panda WWF ramziga aylandi. WWFning mashhur panda logotipi WWF tashkil etilgan yili Pekin hayvonot bog'idan London hayvonot bog'iga ko'chirilgan Chi Chi ismli pandadan kelib chiqqan</p>
</section>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 273, exhtml: `
  <body>

<h1>Article elementi</h1>

<article>
  <h2>Google Chrome</h2>
  <p> Google Chrome — Google tomonidan ishlab chiqilgan, 2008 yilda chiqarilgan veb-brauzer. Chrome — bugungi kunda dunyodagi eng mashhur veb-brauzer!</p>
</article>

<article>
  <h2>Mozilla Firefox</h2>
  <p>Mozilla Firefox — Mozilla tomonidan ishlab chiqilgan ochiq manbali veb-brauzer. Firefox 2018 yil yanvar oyidan beri eng mashhur ikkinchi veb-brauzer bo'ldi.</p>
</article>

<article>
  <h2>Microsoft Edge</h2>
  <p>Microsoft Edge - bu Microsoft tomonidan ishlab chiqilgan veb-brauzer, 2015 yilda chiqarilgan. Microsoft Edge Internet Explorer o'rnini egalladi.</p>
</article>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 274, exhtml: `
  <head>
<style>
.all-browsers {
  margin: 0;
  padding: 5px;
  background-color: lightgray;
}

.all-browsers > h1, .browser {
  margin: 10px;
  padding: 5px;
}

.browser {
  background: white;
}

.browser > h2, p {
  margin: 4px;
  font-size: 90%;
}
</style>
</head>
<body>

<article class="all-browsers">
  <h1>Mashhur brauzerlar</h1>
  <article class="browser">
    <h2>Google Chrome</h2>
    <p> Google Chrome — Google tomonidan ishlab chiqilgan, 2008 yilda chiqarilgan veb-brauzer. Chrome — bugungi kunda dunyodagi eng mashhur veb-brauzer!</p>
  </article>
  <article class="browser">
    <h2>Mozilla Firefox</h2>
    <p>Mozilla Firefox — Mozilla tomonidan ishlab chiqilgan ochiq manbali veb-brauzer. Firefox 2018 yil yanvar oyidan beri eng mashhur ikkinchi veb-brauzer bo'ldi.</p>
  </article>
  <article class="browser">
    <h2>Microsoft Edge</h2>
    <p>Microsoft Edge - bu Microsoft tomonidan ishlab chiqilgan veb-brauzer, 2015 yilda chiqarilgan. Microsoft Edge Internet Explorer o'rnini egalladi.</p>
  </article>
</article>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 275, exhtml: `
  <body>

<article>
  <header>
    <h1>WWF nima qiladi?</h1>
    <p>WWF missiyasi:</p>
  </header>
  <p>WWF missiyasi sayyoramizning tabiiy muhiti degradatsiyasini to'xtatish va insonlar tabiat bilan uyg'unlikda yashaydigan kelajakni qurishdan iborat.</p>
</article>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 276, exhtml: `
  <body>

<footer>
  <p>Muallif: Xege Refsnes</p>
  <p><a href="mailto:hege@example.com">hege@example.com</a></p>
</footer>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 277, exhtml: `
  <body>

<nav>
  <a href="/html/">HTML</a> |
  <a href="/css/">CSS</a> |
  <a href="/js/">JavaScript</a> |
  <a href="/jquery/">jQuery</a>
</nav>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 278, exhtml: `
  <body>

<p>Men va oilam shu yozda Epcot markaziga tashrif buyurdik. Ob-havo yaxshi edi va Epcot ajoyib edi! Men oilam bilan yozni ajoyib o'tkazdim!</p>

<aside>
  <h4>Epcot markazi</h4>
  <p>Epcot - bu Walt Disney World Resortda qiziqarli attraksionlar, xalqaro pavilonlar, mukofotga sazovor bo'lgan otashinlar va mavsumiy maxsus tadbirlarni o'z ichiga olgan maxsus park.</p>
</aside>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 279, exhtml: `
  <head>
<style>
aside {
  width: 30%;
  padding-left: 15px;
  margin-left: 15px;
  float: right;
  font-style: italic;
  background-color: lightgray;
}
</style>
</head>
<body>

<p>Men va oilam shu yozda Epcot markaziga tashrif buyurdik. Ob-havo yaxshi edi va Epcot ajoyib edi! Men oilam bilan yozni ajoyib o'tkazdim!</p>

<aside>
  <h4>Epcot markazi</h4>
  <p>Epcot - bu Walt Disney World Resortda qiziqarli attraksionlar, xalqaro pavilonlar, mukofotga sazovor bo'lgan otashinlar va mavsumiy maxsus tadbirlarni o'z ichiga olgan maxsus park.</p>
</aside>

<p>Men va oilam shu yozda Epcot markaziga tashrif buyurdik. Ob-havo yaxshi edi va Epcot ajoyib edi! Men oilam bilan yozni ajoyib o'tkazdim!</p>
<p>Men va oilam shu yozda Epcot markaziga tashrif buyurdik. Ob-havo yaxshi edi va Epcot ajoyib edi! Men oilam bilan yozni ajoyib o'tkazdim!</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 280, exhtml: `
  <body>

<h2>Tashrif buyuriladigan joylar</h2>

<p>Puglianing eng mashhur diqqatga sazovor joyi - bu YuNESKOning Jahon merosi ro'yxatiga kiritilgan Alberobello atrofida topilgan noyob konus shaklidagi uylar (Trulli).</p>

<figure>
  <img src="trulli.jpg" alt="Trulli" style="width:100%">
  <figcaption>1-rasm - Trulli, Puglia, Italiya.</figcaption>
</figure>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 281, exhtml: `<!DOCTYPE html>
  <head>
    <title>Bu sahifa sarlavhasi</title>
  </head>

  <h1>Bu sarlavha</h1>
  <p>Bu matn</p>`, excss: ``, exjs: ``
  },
  {
    id: 282, exhtml: `
  <title>Bu sahifa sarlavhasi</title>
<body>

<h1>Bu sarlavha</h1>
<p>Bu matn</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 283, exhtml: `<!DOCTYPE html>
<html lang="en-us">
<head>
  <title>Bu sahifa sarlavhasi</title>
</head>
<body>

<h1>Bu sarlavha</h1>
<p>Bu matn.</p>

</body>
</html>`, excss: ``, exjs: ``
  },
  {
    id: 284, exhtml: `
  <body>

<p id="Demo">Bu matn 1.</p>
<p id="demo">Bu matn 2.</p>

<script>
//Faqat matn 2 qayta yoziladi.
document.getElementById("demo").innerHTML = "SALOM.";
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 285, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Bo'shliq belgisi: &nbsp;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 286, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Kichik belgisi: &lt;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 287, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Katta belgisi: &gt;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 288, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Ampersand belgisi: &amp;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 289, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Qo'shtirnoq belgisi: &quot;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 290, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Apostrof belgisi: &apos;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 291, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Sent belgisi: &cent;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 292, exhtml: `
  <body>

  <h1>HTML obyektiga misol</h1>

  <h2>Funt belgisi: &pound;</h2>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 293, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Yen belgisi: &yen;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 294, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Yevro belgisi: &euro;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 295, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Mualliflik huquqi belgisi: &copy;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 296, exhtml: `
  <body>

<h1>HTML obyektiga misol</h1>

<h2>Ro'yxatga olingan tovar belgisi: &reg;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 297, exhtml: `
  <body>

<h1>HTML maxsus belgilarga misol</h1>

<h2>Qattiq urg'u bilan a: a&#768;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 298, exhtml: `
  <body>

<h1>HTML maxsus belgilarga misol</h1>

<h2>O'tkir urg'u bilan a: a&#769;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 299, exhtml: `
  <body>

<h1>HTML maxsus belgilarga misol</h1>

<h2>Sirkumfleks aksentli a: a&#770;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 300, exhtml: `
  <body>

  <h1>HTML maxsus belgilarga misol</h1>

  <h2>Tildali a: a&#771;</h2>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 301, exhtml: `
  <body>

  <h1>HTML maxsus belgilarga misol</h1>

  <h2>Qattiq urg'uga ega bo'lgan o: o&#768;</h2>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 302, exhtml: `
  <body>

<h1>HTML maxsus belgilarga misol</h1>

<h2>O'tkir urg'uli o: o&#769;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 303, exhtml: `
  <body>

<h1>HTML maxsus belgilarga misol</h1>

<h2>Sirkumfleks aksentli o: o&#770;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 304, exhtml: `
  <body>

<h1>HTML maxsus belgilarga misol</h1>

<h2>Tildali o: o&#771;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 305, exhtml: `
  <body>

<h1>Matematik belgilarga misollar</h1>

<h2>Umumiylik kvantoriga misol: &forall;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 306, exhtml: `
  <body>

<h1>Matematik belgilarga misollar</h1>

<h2>Qisman differensiallik belgisi: &part;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 307, exhtml: `
  <body>

<h1>Matematik belgilarga misollar</h1>

<h2>Mavjudlik kvantoriga misol: &exist;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 308, exhtml: `
  <body>

<h1>Matematik belgilarga misollar</h1>

<h2>Bo'sh to'plamga misol: &empty;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 309, exhtml: `
  <body>

<h1>Matematik belgilarga misollar</h1>

<h2>Nabla belgisi: &nabla;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 310, exhtml: `
  <body>

<h1>Matematik belgilarga misollar</h1>

<h2>Tegishlilik belgisiga misol: &isin;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 311, exhtml: `
  <body>

<h1>Matematik belgilarga misollar</h1>

<h2>Tegishli emaslik belgisiga misol: &notin;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 312, exhtml: `
  <body>

<h1>Matematik belgilarga misollar</h1>

<h2>Kamida bittasi bor: &ni;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 313, exhtml: `
  <body>

<h1>Matematik belgilarga misollar</h1>

<h2>Ko'paytirish belgisi – faktorial: &prod;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 314, exhtml: `
  <body>

<h1>Matematik belgilarga misollar</h1>

<h2>Yig'indi belgisi - summa: &sum;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 315, exhtml: `<body>

  <h1>HTML obyektiga misollar</h1>

  <h2>Savdo belgisi: &trade;</h2>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 316, exhtml: `
  <body>

<h1>HTML obyektiga misollar</h1>

<h2>Yo'nalish chapga belgisi: &larr;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 317, exhtml: `
  <body>

<h1>HTML obyektiga misollar</h1>

<h2>Yo'nalish yuqoriga belgisi: &uarr;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 318, exhtml: `
  <body>

<h1>HTML obyektiga misollar</h1>

<h2>Yo'nalish o'ngga belgisi: &rarr;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 319, exhtml: `
  <body>

<h1>HTML obyektiga misollar</h1>

<h2>Yo'nalish quyiga belgisi: &darr;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 320, exhtml: `
  <body>

<h1>HTML obyektiga misollar</h1>

<h2>"Cho'qqilar yoki qarg'a belgisi: &spades;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 321, exhtml: `
  <body>

<h1>HTML obyektiga misollar</h1>

<h2>Chillak belgisi: &clubs;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 322, exhtml: `
  <body>

<h1>HTML obyektiga misollar</h1>

<h2>Yurak belgisi: &hearts;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 323, exhtml: `
  <body>

<h1>HTML obyektiga misollar</h1>

<h2>G'ishtin belgisi: &diams;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 324, exhtml: `
  <body>

  <p>&euro; obyekt nomi bilan ko'rsatish</p>
  <p>&#8364; o'nlik qiymat bilan ko'rsatish</p>
  <p>&#x20AC; oltilik qiymat bilan ko'rsatish</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 325, exhtml: `
  <body>

<h1>Greek Letter Example</h1>

<h2>YUNONCHA KATTA ALPHA HARFI: &Alpha;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 326, exhtml: `
  <body>

<h1>Greek Letter Example</h1>

<h2>YUNONCHA KATTA BETA HARFI: &Beta;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 327, exhtml: `
  <body>

<h1>Greek Letter Example</h1>

<h2>YUNONCHA KATTA GAMMA HARFI: &Gamma;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 328, exhtml: `
  <body>

<h1>Greek Letter Example</h1>

<h2>YUNONCHA KATTA DELTA HARFI: &Delta;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 329, exhtml: `
  <body>

<h1>Greek Letter Example</h1>

<h2>YUNONCHA KATTA EPSILON HARFI: &Epsilon;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 330, exhtml: `
  <body>

<h1>Greek Letter Example</h1>

<h2>YUNONCHA KATTA ZETA HARFI: &Zeta;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 331, exhtml: `
  <head>
<meta charset="UTF-8">
</head>
<body>

<p>Men bunday ko'rinaman: A B C</p>
<p>Men bunday ko'rinaman: &#65; &#66; &#67;</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 332, exhtml: `
  <head>
<meta charset="UTF-8">
</head>
<body>

<h1>Mening birinchi Emoji belgim</h1>

<p>&#128512;</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 333, exhtml: `
  <head>
<meta charset="UTF-8">
</head>
<body>

<h1>Emojis o'lchamini o'zgartirish</h1>

<p style="font-size:48px">
&#128512; &#128516; &#128525; &#128151;
</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 334, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128507;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 335, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128508;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 336, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128509;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 337, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128510;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 338, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128511;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 339, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128512;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 340, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128513;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 341, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128514;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 342, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128515;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 343, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128516;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 344, exhtml: `
  <body>

<h1>HTML Emoji misoli</h1>

<h2>&#128517;</h2>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 345, exhtml: `
  <body>

<h2>HTML formasi</h2>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
</form>

<p> "Yuborish" button bosilsa "https://www.w3schools.com/action_page.php" nomli sahifaga ma'lumotlar yuboriladi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 346, exhtml: `
  <body>

<h2>Matn uchun input maydoni</h2>

<form>
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe">
</form>

<p>Formaning o'zi ko'rinmaydi.</p>

<p>Shuni ham yodda tutingki, kiritish maydonining default kengligi 20 belgidan iborat.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 347, exhtml: `
  <body>

<h2>Radio Button</h2>

<p>Siz uchun sevimli veb dasturlash tilini tanlang:</p>

<form>
  <input type="radio" id="html" name="fav_language" value="HTML">
  <label for="html">HTML</label><br>
  <input type="radio" id="css" name="fav_language" value="CSS">
  <label for="css">CSS</label><br>
  <input type="radio" id="javascript" name="fav_language" value="JavaScript">
  <label for="javascript">JavaScript</label>
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 348, exhtml: `
  <body>

<h2>Checkboxes</h2>
<p><strong>input type="checkbox"</strong> checkboxni belgilaydi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
  <label for="vehicle1"> Mening velosipedim bor</label><br>
  <input type="checkbox" id="vehicle2" name="vehicle2" value="Car">
  <label for="vehicle2"> Mening mashinam bor</label><br>
  <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat">
  <label for="vehicle3"> Mening qayig'im bor.</label><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 349, exhtml: `
  <body>

<h2>name attributi</h2>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" value="John"><br><br>
  <input type="submit" value="Yuborish">
</form>

<p> "Yuborish" button bosilsa "https://www.w3schools.com/action_page.php" nomli sahifaga ma'lumotlar yuboriladi.</p>

<p>name atributi yo'qligi uchun "Ism" maydonidagi ma'lumotlar yuborilmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 350, exhtml: `
  <body>

<h2>target atributi</h2>

<p>Agar bu forma yuborish tugmasi bosilsa natija boshqa brauzer oynasida hosil bo'ladi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 351, exhtml: `
  <body>

<h2>method Attribute</h2>

<p>Forma ma'lumotlari GET metodi yordamida yuboriladi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank" method="get">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
</form>

<p>Yuborganingizdan so'ng, forma qiymatlari yangi brauzer yorlig'ining manzil satrida ko'rinadi</p>
</body>`, excss: ``, exjs: ``
  },
  {
    id: 352, exhtml: `
  <body>

<h2>method Attribute</h2>

<p>Forma ma'lumotlari POST metodi yordamida yuboriladi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank" method="post">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
</form>

<p>Yuborganingizdan so'ng, GET usulidan farqli o'laroq, shakl qiymatlari yangi brauzer yorlig'ining manzil satrida ko'rinmasligiga e'tibor bering.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 353, exhtml: `
  <body>

<h1>autocomplete attributi</h1>

<p>Formani to'ldiring va yuboring, so'ng sahifani qayta yuklang, formani yana to'ldirishni boshlang - va avtomatik to'ldirish qanday ishlashini ko'ring.</p>

<p>Keyin autocomplete qiymatini "off"ga o'zgartirib ko'ring.</p>

<form action="https://www.w3schools.com/action_page.php" autocomplete="on" target="_blank">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="email">Email:</label>
  <input type="text" id="email" name="email"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 354, exhtml: `
  <body>

<h1>novalidate attributi</h1>

<p>Novalidate atributi forma ma'lumotlari yuborilganda tekshirilmasligini bildiradi:</p>

<form action="https://www.w3schools.com/action_page.php" novalidate target="_blank">
  <label for="email">Emailingizni kiriting:</label>
  <input type="email" id="email" name="email"><br><br>
  <input type="submit" value="Yuborish">
</form>

<p><strong>Eslatma:</strong> novalidate atributi Safari 10 (yoki undan oldingi)da qo'llab-quvvatlanmaydi..</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 355, exhtml: `
  <body>

<h2>input Element</h2>

<form action="https://www.w3schools.com/action_page.php" target="_blank" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 356, exhtml: `
  <body>

<h2>select elementi</h2>

<p>select elementi ochiladigan ro'yxatni belgilaydi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="cars">Mashina tanlang:</label>
  <select id="cars" name="cars">
    <option value="volvo">Volvo</option>
    <option value="saab">Saab</option>
    <option value="fiat">Fiat</option>
    <option value="audi">Audi</option>
  </select>
  <input type="submit">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 357, exhtml: `
  <body>

<h2>Tanlangan Option</h2>

<p>selected atributi yordamida variantni tanlab qo'yishimiz kerak:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="cars">Mashina tanlang:</label>
  <select id="cars" name="cars">
    <option value="volvo">Volvo</option>
    <option value="saab">Saab</option>
    <option value="fiat" selected>Fiat</option>
    <option value="audi">Audi</option>
  </select>
  <input type="submit">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 358, exhtml: `
  <body>

<h2>Ko'rinib turadigan qiymatlar</h2>

<p>size atributidan ko'rinib turadigan qiymatlar sonini belgilash uchun foydalaniladi</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="cars">Mashina tanlang:</label>
  <select id="cars" name="cars" size="3">
    <option value="volvo">Volvo</option>
    <option value="saab">Saab</option>
    <option value="fiat">Fiat</option>
    <option value="audi">Audi</option>
  </select><br><br>
  <input type="submit">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 359, exhtml: `
  <body>

<h2>Bir nechta tanlovga ruxsat berish</h2>

<p>Foydalanuvchiga bir nechta qiymatlarni tanlashga ruxsat berish uchun multiple atributidan foydalaniladi</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="cars">Mashina tanlang:</label>
  <select id="cars" name="cars" size="4" multiple>
    <option value="volvo">Volvo</option>
    <option value="saab">Saab</option>
    <option value="fiat">Fiat</option>
    <option value="audi">Audi</option>
  </select><br><br>
  <input type="submit">
</form>

<p>Hold down the Ctrl (windows) / Command (Mac) button to select multiple options.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 360, exhtml: `
  <body>

<h2>Textarea</h2>
<p>textarea elementi ko'p qatorli ma'lumot kiritish maydonini(matn maydoni) belgilaydi</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <textarea name="message" rows="10" cols="30">Bu joyga yozish mumkin.</textarea>
  <br><br>
  <input type="submit">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 361, exhtml: `
  <body>

<h2>Textareaga stil berish</h2>

<p>CSSdan foydalanib ham textareaning o'lchamini belgilashingiz mumkin:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <textarea name="message" style="width:200px; height:600px;">Bu joyga matn yozsa bo'ladi.</textarea>
  <br>
  <input type="submit">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 362, exhtml: `
  <body>

<h2>button elementi</h2>

<button type="button" onclick="alert('Salom dunyo!')">Bosish!</button>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 363, exhtml: `
  <body>

<h2>Fieldset yordamida guruhlash</h2>

<p>fieldset elementi formadagi bir-biriga bog'liq ma'lumotlarni guruhlash uchun ishlatiladi. legend elementi fieldset elementi  uchun sarlavhani belgilaydi</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <fieldset>
    <legend>Personalia:</legend>
    <label for="fname">Ism:</label><br>
    <input type="text" id="fname" name="fname" value="John"><br>
    <label for="lname">Familiya:</label><br>
    <input type="text" id="lname" name="lname" value="Doe"><br><br>
    <input type="submit" value="Yuborish">
  </fieldset>
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 364, exhtml: `
  <body>

<h2>datalist elementi</h2>

<p>datalist element input elementi uchun oldindan belgilangan variantlar ro'yxatini belgilaydi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <input list="browsers" name="browser">
  <datalist id="browsers">
    <option value="Internet Explorer">
    <option value="Firefox">
    <option value="Chrome">
    <option value="Opera">
    <option value="Safari">
  </datalist>
  <input type="submit">
</form>

<p><b>Eslatma:</b> Safarining oldingi 12.1 versiyasida datalist tegi qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 365, exhtml: `
  <body>

<h2>output elementi</h2>
<p>output elementi hisoblash natijasini ifodalaydi</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank"
oninput="x.value=parseInt(a.value)+parseInt(b.value)">
  0
  <input type="range" id="a" name="a" value="50">
  100 +
  <input type="number" id="b" name="b" value="50">
  =
  <output name="x" for="a b"></output>
  <br><br>
  <input type="submit">
</form>

<p>Bu misolda ikki xil turdagi inputlar berilgan va bu inputlar qiymati o'zgartirilsa yig'indisi ko'rsatiladi. Asosiy e'tibor berishimiz kerak bo'lgan qism natija qismi!</p>

<p><strong>Eslatma:</strong>Edge oldingi 13-versiyasida output elementi qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 366, exhtml: `
  <body>

<h2>Matn maydoni</h2>
<p><strong>input type="text"</strong> bir qatorli matn kiritish maydonini belgilaydi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="submit" value="Yuborish">
</form>

<p>Forma elementining o'zi ko'rinmaydi</p>
<p>Default holatda input 20 ta o'rtacha belgi o'lchamida bo'ladi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 367, exhtml: `
  <body>

<h2>Parol maydoni</h2>

<p><strong>input type="password"</strong> parol maydonini belgilaydi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="username">Foydalanuvchi:</label><br>
  <input type="text" id="username" name="username"><br>
  <label for="pwd">Parol:</label><br>
  <input type="password" id="pwd" name="pwd"><br><br>
  <input type="submit" value="Yuborish">
</form>

<p>Parol maydonidagi belgilar yashirin ko'rinishda bo'ladi(yulduzcha yoki doira shaklida ko'rsatiladi)
</body>`, excss: ``, exjs: ``
  },
  {
    id: 368, exhtml: `
  <body>

<h2>Yuborish Button</h2>

<p><strong>input type="submit"</strong> forma ma'lumotlarini forma ishlov beruvchisi(form-handler)ga yuborish tugmachasini belgilaydi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
</form>

<p>Yuborish tugmasi bosilganda forma ma'lumotlari "https://www.w3schools.com/action_page.php" ga yuboriladi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 369, exhtml: `
  <body>

  <form action="https://www.w3schools.com/action_page.php" target="_blank">
    <label for="fname">Ism:</label><br>
    <input type="text" id="fname" name="fname" value="John"><br>
    <label for="lname">Familiya:</label><br>
    <input type="text" id="lname" name="lname" value="Doe"><br><br>
    <input type="submit">
  </form>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 370, exhtml: `
  <body>

<h2>Reset Button</h2>

<p><strong>input type="reset"</strong> barcha forma qiymatlarini default qiymatlariga qaytaradigan qayta yuklash tugmachasini belgilaydi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
  <input type="reset">
</form>

<p>If you change the input values and then click the "Reset" button, the form-data will be reset to the default values.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 371, exhtml: `
  <body>

  <h2>Radio Buttons</h2>

  <p><strong>input type="radio"</strong> radio(tanlanuvchi) tugmani belgilaydi.</p>

  <p>Yoqtirgan veb dasturlash tilini tanlang:</p>
  <form action="https://www.w3schools.com/action_page.php" target="_blank">
     <input type="radio" id="html" name="fav_language" value="HTML">
     <label for="html">HTML</label><br>
     <input type="radio" id="css" name="fav_language" value="CSS">
     <label for="css">CSS</label><br>
     <input type="radio" id="javascript" name="fav_language" value="JavaScript">
     <label for="javascript">JavaScript</label><br><br>
    <input type="submit" value="Yuborish">
  </form>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 372, exhtml: `
  <body>

<h2>Checkbox</h2>
<p><strong>input type="checkbox"</strong> belgilash katakchasi(Checkbox)ni belgilaydi</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <input type="checkbox" id="vehicle1" name="vehicle1" value="Velosiped">
  <label for="vehicle1"> Mening velosipedim bor</label><br>
  <input type="checkbox" id="vehicle2" name="vehicle2" value="Mashina">
  <label for="vehicle2">Mening mashinam bor</label><br>
  <input type="checkbox" id="vehicle3" name="vehicle3" value="Qayiq">
  <label for="vehicle3"> Mening qayig'im bor</label><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 373, exhtml: `
  <body>

  <h2>Radio Button</h2>

  <p><strong>input type="radio"</strong> button tugmasini belgilaydi.</p>

  <p>Yoqtirgan veb dasturlash tilini tanlang:</p>
  <form action="https://www.w3schools.com/action_page.php" target="_blank">
     <input type="radio" id="html" name="fav_language" value="HTML">
     <label for="html">HTML</label><br>
     <input type="radio" id="css" name="fav_language" value="CSS">
     <label for="css">CSS</label><br>
     <input type="radio" id="javascript" name="fav_language" value="JavaScript">
     <label for="javascript">JavaScript</label><br><br>
    <input type="submit" value="Yuborish">
  </form>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 374, exhtml: `
  <body>

<h2>Button Inputi</h2>

<input type="button" onclick="alert('Salom dunyo!')" value="Button tugmasini bosish!">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 375, exhtml: `
  <body>

<h2>Rang tanlash</h2>

<p><strong>input type="color"</strong> rang tanlash maydoni uchun ishlatiladi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="favcolor">Yoqtirgan rangingizni tanlang:</label>
  <input type="color" id="favcolor" name="favcolor" value="#ff0000">
  <input type="submit" value="Yuborish">
</form>

<p><b>Eslatma:</b> type="color" Internet Explorer 11 yoki Safari 9.1 (yoki oldingi versiyalarida) qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 376, exhtml: `
  <body>

<h2>Sana kiritish maydoni</h2>

<p><strong>input type="date"</strong> sanani kiritish maydonlari uchun ishlatiladi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="birthday">Tug'ilgan kun:</label>
  <input type="date" id="birthday" name="birthday">
  <input type="submit" value="Yuborish">
</form>

<p><strong>Eslatma:</strong> type="date" Internet Explorer 11 yoki oldingi Safari 14.1 da qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 377, exhtml: `
  <body>

<h2>Sana maydoni cheklovlari</h2>

<p>sanalarga cheklovlar qo'shish uchun min va max atributlaridan foydalanishingiz mumkin:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="datemin">2000-01-01 dan keyingi sanani kiriting:</label>
  <input type="date" id="datemin" name="datemin" min="2000-01-02"><br><br>

  <label for="datemax">1980-01-01 dan oldingi sanani kiriting:</label>
  <input type="date" id="datemax" name="datemax" max="1979-12-31"><br><br>

  <input type="submit" value="Yuborish">
</form>

<p><strong>Eslatma:</strong> type="date" Internet Explorer 11 yoki oldingi Safari 14.1 da qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 378, exhtml: `
  <body>

<h2>Mahalliy sana vaqtini kiritish maydoni</h2>

<p><strong>input type="datetime-local"</strong> vaqt mintaqasi bo'lmagan sana va vaqtni kiritish maydonini belgilaydi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="birthdaytime">Tug'ilgan kun(sana va vaqt):</label>
  <input type="datetime-local" id="birthdaytime" name="birthdaytime">
  <input type="submit" value="Yuborish">
</form>

<p><strong>Eslatma:</strong> type="datetime-local" Internet Explorer 11 yoki oldingi Safari 14.1 da qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 379, exhtml: `
  <body>

<h2>Email maydoni</h2>

<p><strong>input type="email"</strong> elektron pochta manzili kiritish maydonlari uchun ishlatiladi.:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="email">Sizning elektron pochta manzilingiz:</label>
  <input type="email" id="email" name="email">
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 380, exhtml: `
  <body>

<h2>Yuborish tugmasini rasm bilan ko'rsatish</h2>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism: </label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya: </label>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="image" src="/img_submit.gif" alt="Yuborish" width="48" height="48">
</form>

<p><b>Eslatma:</b>input type="image" tasvir tugmasini bosilgan nuqtasi X va Y koordinatalarini yuboradi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 381, exhtml: `
  <body>

<h1>Faylni yuklash</h1>

<p>Faylni yuklash uchun faylni tanlash maydonini ko'rsating:</p>
<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="myfile">Faylni tanlang:</label>
  <input type="file" id="myfile" name="myfile"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 382, exhtml: `
  <body>

<h1>yashirin kiritish maydoni (HTML kodi orqali ko'rinadi)</h1>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <input type="hidden" id="custId" name="custId" value="3487">
  <input type="submit" value="Yuborish">
</form>

<p><strong>Eslatma:</strong>Yashirin maydon foydalanuvchiga ko'rsatilmaydi, lekin boshqa forma ma'lumotlari bilan birga yuboriladi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 383, exhtml: `
  <body>

<h2>Month Field</h2>

<p><strong>input type="month"</strong> foydalanuvchiga oy va yilni tanlash imkonini beradi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="bdaymonth">Tug'ilgan kun (oy va yil):</label>
  <input type="month" id="bdaymonth" name="bdaymonth">
  <input type="submit" value="Yuborish">
</form>

<p><strong>Eslatma:</strong> type="month" Firefox, Safari yoki Internet Explorer 11 da qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 384, exhtml: `
  <body>

<h2>Raqam kiritish maydonini</h2>

<p><strong>input type="number"</strong> raqam kiritish maydonini belgilaydi</p>

<p>min va max atributlari yordamida qanday raqamlar qabul qilinishiga cheklovlar o'rnatishingiz mumkin:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="quantity">Qiymat(1 va 5 oralig'ida):</label>
  <input type="number" id="quantity" name="quantity" min="1" max="5">
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 385, exhtml: `
  <body>

<h2>Sonlar oralig'i</h2>

<p>Brauzer qo'llab-quvvatlashiga qarab, ruxsat etilgan qadamlar kiritish maydonida qo'llaniladi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="quantity">Qiymati:</label>
  <input type="number" id="quantity" name="quantity" min="0" max="100" step="10" value="30">
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 386, exhtml: `
  <body>

<h2>Range maydoni</h2>

<p>Brauzer qo'llab-quvvatlashiga qarab: "range" input turi slayder boshqaruvi sifatida ko'rsatilishi mumkin.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="vol">Miqdori(0 va 50 oralig'ida):</label>
  <input type="range" id="vol" name="vol" min="0" max="50">
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 387, exhtml: `
  <body>

<h2>Qidiruv maydoni</h2>
<p><strong>input type="search"</strong> qidiruv maydonlari uchun ishlatiladi(qidiruv maydoni odatdagi matn maydoni kabi ishlaydi):</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="gsearch">Googledan qidirish:</label>
  <input type="search" id="gsearch" name="gsearch">
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 388, exhtml: `
  <body>

<h2>Telephone Field</h2>

<p><strong>input type="tel"</strong> telefon raqami input maydoni uchun ishlatiladi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="phone">Telefon nomerini kiriting:</label><br><br>
  <input type="tel" id="phone" name="phone" placeholder="97-123-45-67" pattern="[0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{2}" required><br><br>
  <small>Formati:97-123-45-678</small><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 389, exhtml: `
  <body>

  <h1>Vaqtni belgilash</h1>

  <p><strong>input type="time"</strong> foydalanuvchiga vaqtni tanlash imkonini beradi (vaqt zonasi yo'q):</p>

  <p>Brauzer uni qo'llab-quvvatlasa, input maydoni tanlanganda vaqt tanlagichi paydo bo'ladi.</p>

  <form action="https://www.w3schools.com/action_page.php" target="_blank">
    <label for="appt">Vaqtni belgilang:</label>
    <input type="time" id="appt" name="appt">
    <input type="submit" value="Yuborish">
  </form>

  <p><strong>Eslatma:</strong> type="time" Internet Explorer 11 yoki oldingi Safari 14.1 da qo'llab-quvvatlanmaydi.</p>

  </body>`, excss: ``, exjs: ``
  },
  {
    id: 390, exhtml: `
  <body>

<h1>URL maydoni</h1>

<p><strong>input type="url"</strong> URL manzilini kiritish maydonlari uchun ishlatiladi.:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="homepage">Bosh sahifani qo'shish:</label>
  <input type="url" id="homepage" name="homepage">
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 391, exhtml: `
  <body>

<h1>Inputning hafta kiritish turi</h1>

<p><strong>input type="week"</strong> foydalanuvchiga hafta va yilni tanlash imkonini beradi.</p>

<p>Brauzer qo'llab-quvvatlashiga qarab, sanani tanlagich input maydonida paydo bo'lishi mumkin.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="week">Haftani tanlang::</label>
  <input type="week" id="week" name="week">
  <input type="submit" value="Yuborish">
</form>

<p><strong>Eslatma:</strong> type="week" Firefox, Safari yoki Internet Explorer 11 da qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 392, exhtml: `
  <body>

<h1>value atributi</h1>

<p>value atributi input maydoni uchun boshlang'ich qiymatni belgilaydi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 393, exhtml: `
  <body>

<h1>readonly atributi</h1>

<p>readonly atributi input maydoni faqat o'qish uchun ekanligini bildiradi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John" readonly><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 394, exhtml: `
  <body>

<h1>input disabled atributi</h1>

<p>disabled atributi input maydonini o'chirib qo'yish kerakligini bildiradi(foydalanib ham, uni bosib ham bo'lmaydi.)</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" value="John" disabled><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 395, exhtml: `
  <body>

<h1>input size atributi</h1>

<p>size atributi input maydoni kengligini ya'ni, input maydoniga kiritilgan ma'lumot belgilarining nechtasi ko'rinishi kerakligini belgilaydi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" size="50"><br>
  <label for="pin">PIN:</label><br>
  <input type="text" id="pin" name="pin" size="4"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 396, exhtml: `
  <body>

<h1>input maxlength atributi</h1>

<p>maxlength atributi input maydonida ruxsat etilgan belgilarning maksimal sonini belgilaydi:</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" size="50"><br>
  <label for="pin">PIN:</label><br>
  <input type="text" id="pin" name="pin" maxlength="4" size="4"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 397, exhtml: `
  <body>

<h1>input min and max atributis</h1>

<p>min and max atributlari input maydoni uchun minimal va maksimal qiymatlarni belgilaydi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="datemax">1980-01-01 dan oldingi sana kiritilsin:</label>
  <input type="date" id="datemax" name="datemax" max="1979-12-31"><br><br>

  <label for="datemin">2000-01-01 dan keyingi sana kiritilsin:</label>
  <input type="date" id="datemin" name="datemin" min="2000-01-02"><br><br>

  <label for="quantity">Qiymati (1 va 5 oralig'ida):</label>
  <input type="number" id="quantity" name="quantity" min="1" max="5"><br><br>

  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 398, exhtml: `
  <body>

<h1>input multiple atributi</h1>

<p>multiple atributi foydalanuvchiga input maydoniga bir nechta qiymat kiritish mumkinligini bildiradi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="files">Fayllarni tanlang:</label>
  <input type="file" id="files" name="files" multiple><br><br>
  <input type="submit" value="Yuborish">
</form>

<p>Bir nechta faylni tanlash uchun CTRL yoki SHIFT dan foydalaning.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 399, exhtml: `
  <body>

<h1>input pattern atributi</h1>

<p>pattern atributi forma yuborilganda input maydoni qiymati shunga ko'ra tekshiriladigan muntazam ifodani(qolipni) bildiradi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="country_code">Mamlakat kodi:</label>
  <input type="text" id="country_code" name="country_code" pattern="[A-Za-z]{3}" title="Uch harfli mamlakat kodi"><br><br>
  <input type="submit" value="Yuborish">
</form>

<p><strong>Eslatma:</strong> pattern atributi Safari 10 (yoki undan oldingi) versiyasida qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 400, exhtml: `
  <body>

<h1>input placeholder atributi</h1>

<p>placeholder atributi input maydonining kiritilishi kerak bo'lgan qiymatni tavsiflovchi qisqa maslahatni bildiradi</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="phone">Telefon raqamini kiriting:</label>
  <input type="tel" id="phone" name="phone" placeholder="123-45-678" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 401, exhtml: `
  <body>

<h1>input required atributi</h1>

<p>required atributi formani yuborishdan oldin input maydonini to'ldirish kerakligini bildiradi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="username">Foydalanuvchi:</label>
  <input type="text" id="username" name="username" required>
  <input type="submit" value="Yuborish">
</form>

<p><strong>Eslatma:</strong> required atributi Safari oldingi 10.1 versiyasida qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 402, exhtml: `
  <body>

<h1>input step atributi</h1>

<p>step atributi input maydoni uchun raqamlar qadamini belgilaydi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="points">Hisob:</label>
  <input type="number" id="points" name="points" step="3">
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 403, exhtml: `
  <body>

<h1>input autofocus atributi</h1>

<p>autofocus atributi sahifa yuklanganda input maydoni avtomatik ravishda fokuslanishi kerakligini bildiradi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label><br>
  <input type="text" id="fname" name="fname" autofocus><br>
  <label for="lname">Familiya:</label><br>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 404, exhtml: `
  <body>

<h1>height va width atributlari</h1>

<p>Input height va width atributlari input type='image' elementining balandligi va kengligini belgilaydi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="image" src="/img_submit.gif" alt="Yuborish" width="48" height="48">
</form>

<p><b>Eslatma:</b> Input type='image' tasvir tugmachasi bosilgan nuqtasi X va Y koordinatalarini yuboradi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 405, exhtml: `
  <body>

<h1>Input list atributi</h1>

<p>Input list atributi input elementi uchun oldindan belgilangan variantlarni o'z ichiga olgan datalist elementiga  ishora qiladi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <input list="browsers" name="browser">
  <datalist id="browsers">
    <option value="Internet Explorer">
    <option value="Firefox">
    <option value="Chrome">
    <option value="Opera">
    <option value="Safari">
  </datalist>
  <input type="submit" value="Yuborish">
</form>

<p><b>Eslatma:</b>datalist tegi Safari 12.0 (yoki undan oldingi) versiyasida qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 406, exhtml: `
  <body>

<h1>The autocomplete attribute</h1>

<p>autocomplete atributi input maydonida avtomatik to'ldirish yoqilgan yoki yo'qligini belgilaydi.</p>

<p>Formani to'ldiring va yuboring, so'ng avtoto'ldirish qanday ishlashini ko'rish uchun sahifani qayta yuklang.</p>

<p>autocomplete forma uchun "yoqilgan", elektron pochta maydoni uchun "o'chirilgan"!</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank" autocomplete="on">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <label for="email">Email:</label>
  <input type="email" id="email" name="email" autocomplete="off"><br><br>
  <input type="submit" value="Yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 407, exhtml: `
  <body>

<h1>Inputning form atributi</h1>

<p>Inputning form atributi input elementi tegishli  formani belgilaydi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank" id="form1">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <input type="submit" value="Yuborish">
</form>

<p>Quyidagi "Familiya" maydoni forma elementidan tashqarida, ammo formaning bir qismidir.</p>

<label for="lname">Familiya:</label>
<input type="text" id="lname" name="lname" form="form1">

</body>`, excss: ``, exjs: ``
  },
  {
    id: 408, exhtml: `
  <body>

<h1>Input formaction atributi</h1>

<p>Input formaction atributi forma yuborilganda ma'lumotlarni qayta ishlovchi faylning URL manzilini belgilaydi.</p>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="submit" value="Yuborish">
  <input type="submit" formaction="https://www.w3schools.com/action_page2.php" value="Admin sifatida yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 409, exhtml: `
  <body>

<h1>input formenctype atributi</h1>

<p>Input formenctype atributi forma ma'lumotlari yuborilganda qanday kodlanishi kerakligini belgilaydi.</p>

<form action="https://www.w3schools.com/action_page_binary.asp" method="post" target="_blank">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <input type="submit" value="Yuborish">
  <input type="submit" formenctype="multipart/form-data" value="Multipart/form-data sifatida yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 410, exhtml: `
  <body>

<h1>Formmethod atributi</h1>

<p>Input formmethod atributi URL manziliga forma ma'lumotlarini yuborish uchun HTTP metodini belgilaydi.</p>

<form action="https://www.w3schools.com/action_page.php" method="get" target="_blank">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="submit" value="GET dan foydalanib yuborish">
  <input type="submit" formmethod="post" value="POST dan foydalanib yuborish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 411, exhtml: `
  <body>

<h1>Formtarget atributi</h1>

<p>Input formtarget atributi forma yuborilgandan so'ng olingan natija qayerda ko'rsatilishini ko'rsatadigan nom yoki kalit so'zni belgilaydi.</p>

<form action="/action_page2.htm">
  <label for="fname">Ism:</label>
  <input type="text" id="fname" name="fname"><br><br>
  <label for="lname">Familiya:</label>
  <input type="text" id="lname" name="lname"><br><br>
  <input type="submit" value="Yuborish">
  <input type="submit" formtarget="_blank" value="Natijani yangi window/tab oynasida ko'rish">
</form>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 412, exhtml: `
  <body>

<h1>Formnovalidate atributi</h1>

<form action="https://www.w3schools.com/action_page.php" target="_blank">
  <label for="email">Emailingizni kiriting:</label>
  <input type="email" id="email" name="email" required><br><br>
  <input type="submit" value="Yuborish">
  <input type="submit" formnovalidate="formnovalidate" value="Validatsiyasiz yuborish">
</form>

<p><strong>Eslatma:</strong> formnovalidate input atributi Safari 10 (yoki undan oldingi) versiyasida qo'llab-quvvatlanmaydi.</p>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 413, exhtml: `
  <body>

<canvas id="myCanvas" width="200" height="100" style="border:1px solid #000000;">
Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.
</canvas>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 414, exhtml: `
  <body>

<canvas id="myCanvas" width="200" height="100" style="border:1px solid #d3d3d3;">
Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.</canvas>

<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
ctx.moveTo(0,0);
ctx.lineTo(200,100);
ctx.stroke();
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 415, exhtml: `
  <body>

<canvas id="myCanvas" width="200" height="100" style="border:1px solid #d3d3d3;">
Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.</canvas>

<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
ctx.beginPath();
ctx.arc(95,50,40,0,2*Math.PI);
ctx.stroke();
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 416, exhtml: `
  <body>

<canvas id="myCanvas" width="200" height="100" style="border:1px solid #d3d3d3;">
Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.</canvas>

<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
ctx.font = "30px Arial";
ctx.fillText("Hello World",10,50);
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 417, exhtml: `
  <body>

<canvas id="myCanvas" width="200" height="100" style="border:1px solid #d3d3d3;">
Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.</canvas>

<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
ctx.font = "30px Arial";
ctx.strokeText("Hello World",10,50);
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 418, exhtml: `
  <body>

<canvas id="myCanvas" width="200" height="100" style="border:1px solid #d3d3d3;">
Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.</canvas>

<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
// Create gradient
var grd = ctx.createLinearGradient(0,0,200,0);
grd.addColorStop(0,"red");
grd.addColorStop(1,"white");
// Fill with gradient
ctx.fillStyle = grd;
ctx.fillRect(10,10,150,80);
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 419, exhtml: `
  <body>

<canvas id="myCanvas" width="200" height="100" style="border:1px solid #d3d3d3;">
Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.</canvas>

<script>
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");

// Create gradient
var grd = ctx.createRadialGradient(75,50,5,90,60,100);
grd.addColorStop(0,"red");
grd.addColorStop(1,"white");

// Fill with gradient
ctx.fillStyle = grd;
ctx.fillRect(10,10,150,80);
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 420, exhtml: `
  <body>

<p>Ishlatiladigan rasm:</p>
<img id="scream" src="/img_the_scream.jpg" alt="The Scream" width="220" height="277">

<p>Canvasda ko'rish:</p>
<canvas id="myCanvas" width="250" height="300"
style="border:1px solid #d3d3d3;">
Sizning brauzeringiz HTML Canvas tegini qo'llab-quvvatlamaydi.</canvas>

<p><button onclick="myCanvas()">Sinab ko'rish</button></p>

<script>
function myCanvas() {
  var c = document.getElementById("myCanvas");
  var ctx = c.getContext("2d");
  var img = document.getElementById("scream");
  ctx.drawImage(img,10,10);
}
</script>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 421, exhtml: `
  <body>

<svg width="100" height="100">
  <circle cx="50" cy="50" r="40"
  stroke="green" stroke-width="4" fill="yellow" />
  Kechirasiz, brauzeringiz inline SVG-ni qo'llab-quvvatlamaydi.
</svg>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 422, exhtml: `
  <body>

<svg width="400" height="100">
  <rect width="400" height="100"
  style="fill:rgb(0,0,255);stroke-width:10;stroke:rgb(0,0,0)" />
Kechirasiz, brauzeringiz inline SVG-ni qo'llab-quvvatlamaydi.
</svg>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 423, exhtml: `
  <body>

<svg width="400" height="180">
  <rect x="50" y="20" rx="20" ry="20" width="150" height="150"
  style="fill:red;stroke:black;stroke-width:5;opacity:0.5" />
Kechirasiz, brauzeringiz inline SVG-ni qo'llab-quvvatlamaydi.
</svg>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 424, exhtml: `
  <body>

<svg width="300" height="200">
  <polygon points="100,10 40,198 190,78 10,78 160,198"
  style="fill:lime;stroke:purple;stroke-width:5;fill-rule:evenodd;" />
Kechirasiz, brauzeringiz inline SVG-ni qo'llab-quvvatlamaydi.
</svg>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 425, exhtml: `
  <body>

<svg height="130" width="500">
  <defs>
    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%"
      style="stop-color:rgb(255,255,0);stop-opacity:1" />
      <stop offset="100%"
      style="stop-color:rgb(255,0,0);stop-opacity:1" />
    </linearGradient>
  </defs>
  <ellipse cx="100" cy="70" rx="85" ry="55" fill="url(#grad1)" />
  <text fill="#ffffff" font-size="45" font-family="Verdana"
  x="50" y="86">SVG</text>
Kechirasiz, brauzeringiz inline SVG-ni qo'llab-quvvatlamaydi.
</svg>

</body>`, excss: ``, exjs: ``
  },
  {
    id: 426, exhtml: `
  <body>

  <video width="400" controls>
    <source src="/mov_bbb.mp4" type="video/mp4">
    <source src="/mov_bbb.ogg" type="video/ogg">
  Kechirasiz, brauzeringiz video elementini qo'llab-quvvatlamaydi.
  </video>

  <p>Video <a href="https://peach.blender.org/" target="blank">Big Buck Bunny</a> dan olingan</p>
</body> `, excss: ``, exjs: ``
  },
  {
    id: 427, exhtml: `
<body>

<video width="320" height="240" controls>
  <source src="/movie.mp4" type="video/mp4">
  <source src="/movie.ogg" type="video/ogg">
  Kechirasiz, brauzeringiz video elementini qo'llab-quvvatlamaydi.
</video>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 428, exhtml: `
<body>

<video width="320" height="240" autoplay>
  <source src="/movie.mp4" type="video/mp4">
  <source src="/movie.ogg" type="video/ogg">
  Kechirasiz, brauzeringiz video elementini qo'llab-quvvatlamaydi.
</video>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 429, exhtml: `
<body>

<video width="320" height="240" autoplay muted>
  <source src="/movie.mp4" type="video/mp4">
  <source src="/movie.ogg" type="video/ogg">
  Kechirasiz, brauzeringiz video elementini qo'llab-quvvatlamaydi.
</video>

</body>
`, excss: ``, exjs: ``
  },
  {
    id: 430, exhtml: `
  <body>

<div style="text-align:center">
  <button onclick="playPause()">Play/Pause</button>
  <button onclick="makeBig()">Big</button>
  <button onclick="makeSmall()">Small</button>
  <button onclick="makeNormal()">Normal</button>
  <br><br>
  <video id="video1" width="420">
    <source src="/mov_bbb.mp4" type="video/mp4">
    <source src="/mov_bbb.ogg" type="video/ogg">
    Kechirasiz, brauzeringiz video elementini qo'llab-quvvatlamaydi.
  </video>
  <p>Video <a href="https://peach.blender.org/" target="blank">Big Buck Bunny</a> dan olingan</p>
</div>

<script>
var myVideo = document.getElementById("video1");

function playPause() {
  if (myVideo.paused)
    myVideo.play();
  else
    myVideo.pause();
}

function makeBig() {
    myVideo.width = 560;
}

function makeSmall() {
    myVideo.width = 320;
}

function makeNormal() {
    myVideo.width = 420;
}
</script>


</body> `, excss: ``, exjs: ``
  },
  { id: 431, exhtml: `
  <body>

<audio controls>
  <source src="/wood.ogg" type="audio/ogg">
  <source src="/wood.mp3" type="audio/mpeg">
Sizning brauzeringiz audio elementini qo'llab-quvvatlamaydi!
</audio>

</body>`, excss: ``, exjs: `` },
  { id: 432, exhtml: `
  <body>

  <audio controls autoplay>
    <source src="/wood.ogg" type="audio/ogg">
    <source src="/wood.mp3" type="audio/mpeg">
    Sizning brauzeringiz audio elementini qo'llab-quvvatlamaydi!
</audio>

</body>`, excss: ``, exjs: `` },
  { id: 433, exhtml: `
  <body>

  <audio controls autoplay muted>
    <source src="/wood.ogg" type="audio/ogg">
    <source src="/wood.mp3" type="audio/mpeg">
  Sizning brauzeringiz audio elementini qo'llab-quvvatlamaydi!
  </audio>

  </body>`, excss: ``, exjs: `` },
  { id: 434, exhtml: `
  <body>

     <object width="100%" height="500px" data="/snippet.html"></object>

</body>`, excss: ``, exjs: `` },
  { id: 435, exhtml: `
  <body>

    <object data="/audi.jpeg"></object>

</body>`, excss: ``, exjs: `` },
  { id: 436, exhtml: `
  <body>

    <embed src="/audi.jpeg">

</body>`, excss: ``, exjs: `` },
  { id: 437, exhtml: `
  <body>

   <embed width="100%" height="500px" src="/snippet.html">

</body>`, excss: ``, exjs: `` },
  { id: 438, exhtml: `
  <body>

   <iframe width="420" height="345" src="https://www.youtube.com/embed/tgbNymZ7vqY">
   </iframe>

</body>`, excss: ``, exjs: `` },
  { id: 439, exhtml: `
  <body>

   <iframe width="420" height="345" src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1">
   </iframe>

</body>`, excss: ``, exjs: `` },
  { id: 440, exhtml: `
  <body>

   <iframe width="420" height="345" src="https://www.youtube.com/embed/tgbNymZ7vqY?playlist=tgbNymZ7vqY&loop=1">
   </iframe>

</body>`, excss: ``, exjs: `` },
  { id: 441, exhtml: `
  <body>

   <iframe width="420" height="345" src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0">
   </iframe>

</body>`, excss: ``, exjs: `` },
  { id: 442, exhtml: `
  <body>

<p>Koordinatalaringizni olish uchun button tugmasini bosing.</p>

<button onclick="getLocation()">Sinab ko'rish</button>

<p id="demo"></p>

<script>
var x = document.getElementById("demo");

function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  } else {
    x.innerHTML = "Geolocation brauzeringiz tomonidan qo'llab-quvvatlanmaydi.";
  }
}

function showPosition(position) {
  x.innerHTML = "Latitude: " + position.coords.latitude +
  "<br>Longitude: " + position.coords.longitude;
}
</script>

</body>`, excss: ``, exjs: `` },
  { id: 443, exhtml: `
  <body>

<p>Koordinatalaringizni olish uchun button tugmasini bosing.</p>

<button onclick="getLocation()">Sinab ko'rish</button>

<p id="demo"></p>

<script>
var x = document.getElementById("demo");

function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition, showError);
  } else {
    x.innerHTML = "Geolocation brauzeringiz tomonidan qo'llab-quvvatlanmaydi.";
  }
}

function showPosition(position) {
  x.innerHTML = "Latitude: " + position.coords.latitude +
  "<br>Longitude: " + position.coords.longitude;
}

function showError(error) {
  switch(error.code) {
    case error.PERMISSION_DENIED:
      x.innerHTML = "Foydalanuvchi Geolocation so'rovini rad etdi."
      break;
    case error.POSITION_UNAVAILABLE:
      x.innerHTML = "Joylashuv ma'lumotlari mavjud emas."
      break;
    case error.TIMEOUT:
      x.innerHTML = "Foydalanuvchi joylashuvini aniqlash vaqti tugadi."
      break;
    case error.UNKNOWN_ERROR:
      x.innerHTML = "Noma'lum xatolik yuz berdi."
      break;
  }
}
</script>

</body>`, excss: ``, exjs: `` },
  { id: 444, exhtml: `
  <body>

<p>Koordinatalaringizni olish uchun button tugmasini bosing.</p>

<button onclick="getLocation()">Sinab ko'rish</button>

<p id="demo"></p>

<script>
var x = document.getElementById("demo");

function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.watchPosition(showPosition);
  } else {
    x.innerHTML = "Geolocation brauzeringiz tomonidan qo'llab-quvvatlanmaydi.";
  }
}

function showPosition(position) {
    x.innerHTML="Latitude: " + position.coords.latitude +
    "<br>Longitude: " + position.coords.longitude;
}
</script>

</body>`, excss: ``, exjs: `` },
  { id: 445, exhtml: `
  <head>
<style>
#div1 {
  width: 350px;
  height: 70px;
  padding: 10px;
  border: 1px solid #aaaaaa;
}
</style>
<script>
function allowDrop(ev) {
  ev.preventDefault();
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function drop(ev) {
  ev.preventDefault();
  var data = ev.dataTransfer.getData("text");
  ev.target.appendChild(document.getElementById(data));
}
</script>
</head>
<body>

<p>Self-study logotipini to'rtburchak ichiga olib keling:</p>

<div id="div1" ondrop="drop(event)" ondragover="allowDrop(event)"></div>
<br>
<img id="drag1" src="selfstudylogo.svg" draggable="true" ondragstart="drag(event)" width="336" height="69">

</body>`, excss: ``, exjs: `` },
  { id: 446, exhtml: `
  <body>

<div id="result"></div>

<script>
// Check browser support
if (typeof(Storage) !== "undefined") {
  // Store
  localStorage.setItem("lastname", "Smith");
  // Retrieve
  document.getElementById("result").innerHTML = localStorage.getItem("lastname");
} else {
  document.getElementById("result").innerHTML = "Sorry, your browser does not support Web Storage...";
}
</script>

</body>`, excss: ``, exjs: `` },
  { id: 447, exhtml: `
  <head>
<script>
function clickCounter() {
  if (typeof(Storage) !== "undefined") {
    if (localStorage.clickcount) {
      localStorage.clickcount = Number(localStorage.clickcount)+1;
    } else {
      localStorage.clickcount = 1;
    }
    document.getElementById("result").innerHTML = "You have clicked the button " + localStorage.clickcount + " time(s).";
  } else {
    document.getElementById("result").innerHTML = "Sorry, your browser does not support web storage...";
  }
}
</script>
</head>
<body>

<p><button onclick="clickCounter()" type="button">Click me!</button></p>
<div id="result"></div>
<p>Click the button to see the counter increase.</p>
<p>Close the browser tab (or window), and try again, and the counter will continue to count (is not reset).</p>

</body>`, excss: ``, exjs: `` },
  { id: 448, exhtml: `
  <head>
<script>
function clickCounter() {
  if (typeof(Storage) !== "undefined") {
    if (sessionStorage.clickcount) {
      sessionStorage.clickcount = Number(sessionStorage.clickcount)+1;
    } else {
      sessionStorage.clickcount = 1;
    }
    document.getElementById("result").innerHTML = "You have clicked the button " + sessionStorage.clickcount + " time(s) in this session.";
  } else {
    document.getElementById("result").innerHTML = "Sorry, your browser does not support web storage...";
  }
}
</script>
</head>
<body>

<p><button onclick="clickCounter()" type="button">Click me!</button></p>
<div id="result"></div>
<p>Click the button to see the counter increase.</p>
<p>Close the browser tab (or window), and try again, and the counter is reset.</p>

</body>`, excss: ``, exjs: `` },
  { id: 449, exhtml: `
  <body>

<p>Count numbers: <output id="result"></output></p>
<button onclick="startWorker()">Start Worker</button>
<button onclick="stopWorker()">Stop Worker</button>

<p><strong>Note:</strong> Internet Explorer 9 and earlier versions do not support Web Workers.</p>

<script>
var w;

function startWorker() {
  if(typeof(Worker) !== "undefined") {
    if(typeof(w) == "undefined") {
      w = new Worker("demo_workers.js");
    }
    w.onmessage = function(event) {
      document.getElementById("result").innerHTML = event.data;
    };
  } else {
    document.getElementById("result").innerHTML = "Sorry, your browser does not support Web Workers...";
  }
}

function stopWorker() {
  w.terminate();
  w = undefined;
}
</script>

</body>`, excss: ``, exjs: `` },
  { id: 450, exhtml: `
  <body>

<h1>Getting server updates</h1>
<div id="result"></div>

<script>
if(typeof(EventSource) !== "undefined") {
  var source = new EventSource("demo_sse.php");
  source.onmessage = function(event) {
    document.getElementById("result").innerHTML += event.data + "<br>";
  };
} else {
  document.getElementById("result").innerHTML = "Sorry, your browser does not support server-sent events...";
}
</script>

</body>`, excss: ``, exjs: `` },
  { id: 451, exhtml: `<head>
  <style>
  #div1, #div2 {
    float: left;
    width: 100px;
    height: 35px;
    margin: 10px;
    padding: 10px;
    border: 1px solid black;
  }
  </style>
  <script>
  function allowDrop(ev) {
    ev.preventDefault();
  }

  function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }

  function drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
  }
  </script>
  </head>
  <body>

  <h2>Drag va Drop</h2>
  <p>Ikkita div elementida rasmni biridan ikkinchisiga olib o'ting</p>

  <div id="div1" ondrop="drop(event)" ondragover="allowDrop(event)">
    <img src="selfstudylogo.svg" draggable="true" ondragstart="drag(event)" id="drag1" width="88" height="31">
  </div>

  <div id="div2" ondrop="drop(event)" ondragover="allowDrop(event)"></div>

  </body>`, excss: ``, exjs: `` },
  { id: 452, exhtml: ``, excss: ``, exjs: `` },
  { id: 453, exhtml: ``, excss: ``, exjs: `` },
  { id: 454, exhtml: ``, excss: ``, exjs: `` },
  { id: 455, exhtml: ``, excss: ``, exjs: `` },
  { id: 456, exhtml: ``, excss: ``, exjs: `` },

  // Don't write <!DOCTYPE html>,<html> va</html> when you write example.
];

export default function exampleObj(props) {
  let generateHtmlCode = ``;
  if (props.props === '281' || props.props === '283') {
    generateHtmlCode = example[props.props - 1].exhtml;
  }
  else {
    generateHtmlCode = `<!--Kiritilgan kod 3 sekundda generatsiya qilinadi-->
  <!DOCTYPE html>
  <html>`+ example[props.props - 1].exhtml + `
  </html>`;
  }
  const generateCssCode = example[props.props - 1].excss;
  const generateJsCode = example[props.props - 1].exjs;
  return [generateHtmlCode, generateCssCode, generateJsCode];
}